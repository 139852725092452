import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {IwGridColumn} from '@app/sam-base/models';
import {Gefacele} from '@app/sam-base/models/invoices/gefacele';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import _ from 'lodash';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-facture-detail',
    templateUrl: './tab-facture-detail.component.html',
    standalone: false
})
export class TabFactureDetailComponent extends BasePartialFormComponent<Gefachdr> implements OnInit {

    public readonly type = Gefacele;
    public faceleList: Gefacele[] = [];
    public facele?: Gefacele;
    public manualInvColumns = this.getManualInvColumns();

    constructor(private readonly _formHandler: FormHandlerService<SalaryForm>) {
        super();
    }

    public get facType(): string {
        return this.getFormValue('facType') ?? '';
    }

    public get canAdd(): boolean {
        return !this.isReadonly && this.facType !== 'PF' && !this.getFormValue('cptaRef')
    }

    public get canEdit(): boolean {
        return !this.isReadonly && this.facType !== 'PF' && !this.getFormValue('cptaRef')
    }

    public get canDelete(): boolean {
        return !this.isReadonly && this.facType !== 'PF' && !this.getFormValue('cptaRef') && !!this.facele;
    }

    public ngOnInit() {
        this.faceleList = this.getFormValue('faceleList') ?? [] as Gefacele[];
        this.subscribeValueChange('faceleList', faceleList => {
            this.faceleList = [...faceleList as Gefacele[]];
            const mntBrut = this.faceleList.reduce((total, line) => total + (line.montant ?? 0), 0);
            this.setFormValue('mntBrut', mntBrut);
            console.log('faceleList changed', this.faceleList);
        });
    }

    public addElem(): void {
        const gefachdr = this.formGroup?.getRawValue()!;
        const newFacele = new Gefacele();
        newFacele.facId = gefachdr.facId;
        const faceleListFormGroup = this.getFormControl('faceleList')!;
        const onOKCallback: (facele: Gefacele) => void = (facele: Gefacele) => {
            gefachdr.faceleList.push(facele);
            faceleListFormGroup.patchValue(gefachdr.faceleList);
        }
        this._formHandler.showFormDialog(SalaryForm.FaceleNew, {
            gefachdr: gefachdr,
            gefacele: newFacele,
            onOKCallback: onOKCallback
        });
    }

    public editElem(): void {
        const gefachdr = this.formGroup?.getRawValue()!;
        const faceleToEdit = this.facele;
        const gefaceleIndex = gefachdr.faceleList.indexOf(this.facele);
        const faceleListFormGroup = this.getFormControl('faceleList')!;
        const onOKCallback: (facele: Gefacele) => void = (facele: Gefacele) => {
            gefachdr.faceleList[gefaceleIndex] = _.merge(gefachdr.faceleList[gefaceleIndex], facele);
            faceleListFormGroup.patchValue(gefachdr.faceleList);
        }
        this._formHandler.showFormDialog(SalaryForm.FaceleEdit, {
            gefachdr: gefachdr,
            gefacele: faceleToEdit,
            onOKCallback: onOKCallback
        });
    }

    public async deleteElem(): Promise<void> {
        const gefaceleList: Gefacele[] = this.getFormControl('faceleList')
            ?.getRawValue();
        const gefaceleIndex = gefaceleList.indexOf(this.facele!);
        gefaceleList.splice(gefaceleIndex, 1);
        this.getFormControl('faceleList')
            ?.setValue(gefaceleList);
    }

    public getSelectedRow(event?: Gefacele[]) {
        if (event) {
            this.facele = event[0];
        }
    }

    /* Columns to show in  table */
    private getManualInvColumns(): IwGridColumn<Gefacele>[] {
        return [
            {
                prop: 'libelle',
                name: 'libelle',
                index: 0
            },
            {
                prop: 'nqte',
                name: 'nqte',
                align: 'right',
                index: 1,
                type: 'mnt'
            },
            {
                prop: 'unite',
                name: 'unite',
                index: 2
            },
            {
                prop: 'prixUnite',
                name: 'prix',
                align: 'right',
                index: 3,
                type: 'mnt'
            },
            {
                prop: 'montant',
                name: 'montant',
                align: 'right',
                index: 4,
                type: 'mnt'
            },
            {
                prop: 'compte',
                name: 'compte',
                index: 5
            },
            {
                prop: 'secId',
                name: 'sec_id',
                index: 6
            }];
    }
}
