<form [formGroup]="formGroup">

    <iw-smtpconfig-dropdown [isDisabled]="isReadonly"
                            [label]="'smtp_config' | translate"
                            class="iw-input"
                            formControlName="smtpConfigId"></iw-smtpconfig-dropdown>

    <iw-textfield [isDisabled]="isReadonly"
                  [label]="'emailInvoice' | translate"
                  class="iw-input"
                  formControlName="emailInvoice"></iw-textfield>

    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Facture standard'"
                          [report]="'FAC'"
                          class="iw-input"
                          formControlName="facfrxfa"></iw-template-dropdown>
    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Facture par email'"
                          [report]="'FAC'"
                          class="iw-input"
                          formControlName="facfrxeml"></iw-template-dropdown>
    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Facture tempo'"
                          [report]="'FAC'"
                          class="iw-input"
                          formControlName="facfrxpt"></iw-template-dropdown>
    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Facture fixe'"
                          [report]="'FAC'"
                          class="iw-input"
                          formControlName="facfrxpf"></iw-template-dropdown>
    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Note de crédit'"
                          [report]="'FAC'"
                          class="iw-input"
                          formControlName="ncfrx"></iw-template-dropdown>
    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Facture Yellowbill'"
                          [report]="'FAC'"
                          class="iw-input"
                          formControlName="facfrxyb"></iw-template-dropdown>
    <iw-template-dropdown [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Rappel'"
                          [report]="'RPL'"
                          class="iw-input"
                          formControlName="rplfrx"></iw-template-dropdown>
</form>
