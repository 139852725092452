import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, IwEventHubService} from '@app/sam-base/core';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Gecli} from '@app/sam-base/models/invoices';
import {Sapar} from '@app/sam-base/models/salary';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Ppcli} from '@sam-base/models/placement';
import {Subject, takeUntil} from 'rxjs';
import {clientTabsFormControls} from './client-tabs-formcontrols';

@Component({
    templateUrl: './client.component.html',
    standalone: false
})
export class ClientComponent extends BaseTabFormComponent<Gecli> implements OnInit {

    public sapar?: Sapar;
    public subscriptions = new Subject();
    private cliStatus?: number;

    constructor(store: IwStoreService, private readonly _events: IwEventHubService<string>,
                private readonly _entityNavService: EntityNavigationService) {
        super(store);
    }

    public PLACEMENT_USER_ROLES = [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW];

    public get gecli() {
        return this.getFormData();
    }

    public get cliId() {
        return this.getFormValue('cliId') || '';
    }

    public get clientClass() {
        let cliStatus = this.getFormValue('clistatus');
        if (cliStatus === 0) {
            cliStatus = '0';
        } else if (cliStatus === 1) {
            cliStatus = '1';
        } else if (cliStatus === 2) {
            cliStatus = '2';
        } else if (cliStatus === 3) {
            cliStatus = '3';
        } else {
            cliStatus = 'd';
        }
        return 'cli-color-' + cliStatus;
    }

    public ngOnInit() {

        this._events.forType<string>('status_cliId')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                this.cliStatus = Number(e.payload);
            });
    }

    public getTabsValues(): TabFormControl<Gecli>[] {
        return clientTabsFormControls;
    }

    public openPpcli() {
        this._entityNavService
            .navigateToEntityForm(Ppcli, this.getFormValue('cliId'));
    }

    protected getValidationType() {
        return Gecli;
    }

    protected getFormControlNames(): FormKeys<Gecli> {
        return this.getTabsFormControls(clientTabsFormControls);
    }
}
