import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {FormHandlerService, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {GepaihdrService} from '@app/sam-base/core/services/gepaihdr.service';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {Gecli} from '@app/sam-base/models/invoices';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {Gepaiele} from '@sam-base/models/invoices/gepaiele';
import {Subject, takeUntil} from 'rxjs';
import {SalaryForm} from '../../salary.forms';


@Component({
    templateUrl: './encaissement-side-menu.component.html',
    standalone: false
})
export class EncaissementSideMenuComponent extends BaseSideFormComponent<Gepaihdr> implements AfterViewInit, OnInit, OnDestroy {

    public gepaihdr?: Gepaihdr;
    public paiementSolde: number | undefined;
    public subscriptions = new Subject();
    public selectedGepaiele?: Gepaiele;
    private readonly _gecliRestClient: RestEntityClient<Gecli>;

    constructor(private readonly _store: IwStoreService, private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _gepaihdrService: GepaihdrService,
                private _eventHub: IwEventHubService<EncaissementEvents>,
                _restService: RestApiService) {
        super();
        this._gecliRestClient = _restService.getEntityClient(Gecli);
    }

    public ngOnInit(): void {
        this._eventHub.forType(EncaissementEvents.elementSelected)
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data) => {
                this.selectedGepaiele = data.payload as Gepaiele;
            })

    }

    public ngOnDestroy(): void {
    }

    public calculate() {

        this._store
            .globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.gepaihdr = data;
            });

        this._formHandler.showFormDialog(SalaryForm.PaiementCalculatebtn, {
            payId: this.gepaihdr?.payId,
            cliId: this.gepaihdr?.cliId
        });
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.gepaihdr = data;
                this.paiementSolde = this.gepaihdr?.solde;
            });
    }

    public attribuer() {
        if (this.gepaihdr?.cliId) {
            this._gecliRestClient.getById(this.gepaihdr?.cliId ?? '')
                .subscribe((gecli) => {
                    const data = {
                        mpaId: this.gepaihdr?.mpaId,
                        datePay: this.gepaihdr?.datePay,
                        montant: this.gepaihdr?.montant,
                        cliId: this.gepaihdr?.cliId,
                        payId: this.gepaihdr?.payId,
                        cliNom: gecli.nom
                    };
                    this._formHandler.showFormDialog(SalaryForm.PaiementAttribuition, data);
                });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const data = {
                mpaId: this.gepaihdr?.mpaId,
                datePay: this.gepaihdr?.datePay,
                montant: this.gepaihdr?.montant,
                payId: this.gepaihdr?.payId
            };
            this._formHandler.showFormDialog(SalaryForm.PaiementAttribuitionManyCli, data);
        }
    }

    public desattribuer() {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._gepaihdrService.desattribuer(this.gepaihdr!.payId!, 'noCli')
            .subscribe(() => {
                this._eventHub.emit(EncaissementEvents.disociate);
                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
            });
    }

    public desattribuerCli() {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._gepaihdrService.desattribuerCli(this.gepaihdr!.payId!, this.selectedGepaiele!.cliId!)
            .subscribe(() => {
                this._eventHub.emit(EncaissementEvents.disociate);
                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
            });
    }
}
