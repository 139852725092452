import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {GlobalFormState, IwStoreService, ModalService} from '@app/sam-base/core';
import {Ppacattest} from '@app/sam-base/models/placement';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import FileSaver from 'file-saver';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PpacattestService} from '../../services/ppacattest.service';
import {PdfOptions} from './ppacattest.model';

@Component({
    templateUrl: './ppacattest-sidemenu.component.html',
    standalone: false
})
export class PpacattestSidemenuComponent extends BaseSideFormComponent<Ppacattest> implements OnInit, OnDestroy {
    public readonly = true;

    public pdfOptions = PdfOptions;
    public pdf = '';
    public empId = '';

    private _acaId?: string;
    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService,
                private readonly _acattestService: PpacattestService,
                private _modalService: ModalService) {
        super();
    }

    public ngOnInit() {
        this.pdf = PdfOptions.internationalFR;
        this._store.globalForm(this.uuid)
            .state
            .pipe(takeUntil(this.subscription))
            .subscribe((res: GlobalFormState<unknown>) => {
                this.readonly = res.editMode === 'read';
            });

        if (!this.uuid) {
            return;
        }
        this._store.globalForm<Ppacattest>(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe(ppacatt => {
                this._acaId = ppacatt?.acaId;
                this.empId = ppacatt?.empId || '';
            });
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    public doPrint(): void {
        this._acattestService
            .getPrint(this.empId, this.pdf.split(' ')[0], this.pdf.split(' ')[1])
            .subscribe((doc: Blob) => {
                FileSaver.saveAs(doc, 'acattest-' + this._acaId);
            });
    }

    public sendByMail() {
        const ids: string[] = [];
        ids.push(this._acaId! + '_' + this.pdf || '');

        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.ACA_EMAIL,
            entityIds: ids
        });
    }
}
