import {PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';

@IwRestEntity('print-request-view', '')
export class PrintRequestView {
    @IwColumn({
        index: 0,
    }) @IwRestEntityId()
    public id?: number;
    public type?: PrintRequestType;
    public ageId?: string;
    public entityId?: string;
    public description?: string
    public recipient?: string;
    public address?: string;
    public postalCode?: string;
    public city?: string;
    public country?: string;
    public approved?: boolean;
    public downloaded?: boolean;
    public downloadedAt?: string;
    public docPath?: string;
    public docId?: string;
    public docName?: string;
}