import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, RestApiService, RestEntityClient, TabFormControl} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Ppagi, Ppemp} from '@app/sam-base/models/placement';

import {aginterTabsFormControls} from './aginter-tabs-formcontrols';

@Component({
    templateUrl: './aginter.component.html',
    standalone: false
})
export class AginterComponent extends BaseTabFormComponent<Ppagi> implements OnInit {

    public empName = '';
    public mois = '';
    private tabValues?: TabFormControl<Ppagi>[];
    private _rest: RestEntityClient<Ppemp>;

    constructor(store: IwStoreService, public readonly restService: RestApiService,
                private readonly _entityNavService: EntityNavigationService) {
        super(store);
        this._rest = restService.getEntityClient(Ppemp);
    }

    public get empId() {
        return this.getFormValue('empId');
    }

    public ngOnInit() {
        this.subscribeValueChange('empId', () => {
            const empId = this.getFormValue('empId');
            if (!empId) {
                return;
            }
            this._rest
                .getById(empId)
                .subscribe((emp: Ppemp) => this.empName = `${emp.nom} ${emp.prenom}`);
        });
        this.subscribeValueChange('salId', () => {
            const dateCreat = this.getFormValue('salId');
            if (!dateCreat) {
                return;
            }
            this.mois = this.getMonth(this.getFormValue('salId'));
        });
    }

    public getTabsValues(): TabFormControl<Ppagi>[] {
        if (!this.tabValues) {
            return aginterTabsFormControls;
        }
        return this.tabValues;
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    protected getValidationType() {
        return Ppagi;
    }

    protected getFormControlNames(): FormKeys<Ppagi> {
        return this.getTabsFormControls(aginterTabsFormControls);
    }

    private getMonth(salId: string): string {
        const salIdMonth = Number(salId.slice(4)) < 10 ? '0' + Number(salId.slice(4)) : Number(salId.slice(4));
        const date = `${salId
            .slice(0, 4)}-${salIdMonth}-10`;
        this.mois = `${IwDateHelper
            .dateMonthString(date)} ${salId
            .slice(0, 4)}`;
        return this.mois.charAt(0)
            .toUpperCase() + this.mois.slice(1);
    }
}
