import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys, ModalComponent} from '@app/sam-base/models';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../../../environments/environment';

@Component({
    selector: 'iw-tab-saemp-search', templateUrl: './tab-saemp-search.component.html',
    standalone: false
})
export class TabSaempSearchComponent extends BaseFormComponent<Saempc> implements ModalComponent<Saempc | undefined, ModalMessageOptions>, OnDestroy {

    @Output() public pressOk: EventEmitter<Saempc> = new EventEmitter();

    @Output() public pressCancel: EventEmitter<void> = new EventEmitter();

    public tauxImpotLookUpOptions: ComboboxItem<number>[] = [{name: ' ', value: 0}, {
        name: this._translate.instant('annuel'), value: 1
    }, {
        name: this._translate.instant('combo_mensuel'), value: 2
    }, {
        name: this._translate.instant('combo_horaire'), value: 3
    }];
    private saempc: Saempc | undefined;
    private _ok?: (result?: Saempc) => void;
    private _cancel?: (error?: any) => void;
    private _sub: Subscription = new Subscription();

    constructor(private readonly _translate: TranslateService, private readonly http: HttpClient, private _toast: ToastService) {
        super();
    }

    public setData(data: any): Promise<void> {
        this.saempc = data;
        this.fillFormData(data);
        return data;
    }

    public onPressOk() {
        if (this._ok) {
            this._ok(this.saempc);
        }
        this.pressOk.emit();
    }

    public onPressCancel() {
        if (this._cancel) {
            this._cancel();
        }
        this.pressCancel.emit();
    }

    public registerOk(action: (result?: Saempc) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public calculateTaux(event: any) {
        const mode = event.value.value;
        const montant = this.saempc?.salannimp ?? 0;
        const empId = this.saempc?.empId ?? '';

        this._sub = this.http
            .get<number>(environment.backendURL + 'saempc/calculatetaux/' + mode, {
                params: {['montant']: montant.toString(), ['empId']: empId}
            })
            .subscribe({
                next: (res) => {
                    this.setFormValue('tauxImp', res);
                    if (this.saempc) {
                        this.saempc.tauxImp = res;
                    }
                }, error: (err) => {
                    this._toast.error(err);
                }
            });
    }

    public ngOnDestroy(): void {
        this._sub.unsubscribe();
        super.ngOnDestroy();
    }

    protected getFormControlNames(): FormKeys<Saempc> {
        return ['cantontrav', 'baremImp', 'salannimp', 'tauxImp'];
    }
}
