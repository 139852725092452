import {Component, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwTableComponent, SortInfo} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {EntityDelete, FormHandlerService, IwEventHubService, IwStoreService, ModalService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SadedtxService} from '@app/sam-base/core/services/sadedtx.service';

import {SapardedService} from '@app/sam-base/core/services/saparded.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {Sadedtx} from '@app/sam-base/models/salary/sadedtx';
import {Sadedtxage} from '@app/sam-base/models/salary/sadedtxage';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SalaryForm} from '../../salary.forms';

@Component({
    templateUrl: './sadedtx.component.html',
    standalone: false
})
export class SadedtxComponent extends BaseStoreFormComponent<Sadedtx> implements OnInit, OnDestroy {

    @ViewChild('sadedtxConfigGrid') public sadedtxGrid?: IwTableComponent<Sadedtx>;
    @ViewChild('sadedtxageGrid') public sadedtxageGrid?: IwTableComponent<Sadedtxage>;
    public sortProp?: keyof Sadedtx = 'txCode';
    public sortPropDir?: 'asc' | 'desc' = 'desc';
    public sapar?: Sapar;
    public sadedtx?: Sadedtx;
    public type: Type<Sadedtx> = Sadedtx;
    public sadedtxage = Sadedtxage;
    public sadedtxageColumns = this.getSadedtxageColumns();
    public query: RestQueryParam<Sadedtx, string>[] = [];
    public listDedCode: string[] = [
        'CAF',
        'LPP',
        'LAA',
        'IJM'];
    public sadedtxageQuery: RestQueryParam<Sadedtxage, string>[] = [];
    public selectedSadedtxageRow?: Sadedtxage;
    public assuranceOptions: ComboboxItem<string>[] = [];
    public formState: FormState | undefined;
    public selectedRow?: Sadedtx;
    public readonly = true;
    public canModify = false;
    public newEntryMode = false;
    public assCodeVal = '';
    private _isSaving = false;
    private _baseUrl = ' ';
    private readonly _restSadedtxClient: RestEntityClient<Sadedtx>;
    private _subscripts = new Subject();

    constructor(store: IwStoreService, rest: RestApiService, private readonly _parametersService: ParametersService,
                private readonly _translate: TranslateService, private readonly _modalService: ModalService,
                private _formHandler: FormHandlerService<SalaryForm>, private readonly sadedtxService: SadedtxService,
                private readonly _events: IwEventHubService<string>, public readonly sapardedService: SapardedService) {
        super(store);
        this._baseUrl = environment.backendURL;
        this._restSadedtxClient = rest.getEntityClient(Sadedtx);
    }

    public get dedCode(): string {
        return this.getData('dedCode') || '';
    }

    public get annee() {
        return this.getData('annee') || '';
    }

    public get assCode() {
        return this.getData('assCode') || '';
    }

    public get assurance(): string {
        return this.getData('assurance') || '';
    }

    public get tauxmode() {
        return this.getData('tauxmode') || '';
    }

    public get isTauxMode2() {
        return this.getData('tauxmode') === 2;
    }

    public get isTauxMode3() {
        return this.getData('tauxmode') === 3;
    }

    public get isCAF() {
        return this.dedCode === 'CAF';
    }

    public get isIJMLAC() {
        return this.dedCode === 'IJM' || this.dedCode === 'LAC';
    }

    public get showAssurance(): boolean {
        return this.listDedCode.includes(this.dedCode);
    }

    public ngOnInit() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => (this.sapar = sapar));
        this.setQuery();
        this.setSortByModif();

        this.setAssuranceOptions();

        this._events.forType('sadedtxage_updated')
            .pipe(takeUntil(this._subscripts))
            .subscribe((e) => {
                this.sadedtxageGrid?.refresh();
            });

        this.updateFormValidation();
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    public getSelectedRow(event: Sadedtx[]) {
        this.readonly = true;
        if (this._isSaving) {
            this._isSaving = false;
            return;
        }
        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }
        this.assCodeVal = event[0].assCode ?? '';
        this.selectedRow = event[0];
        this.fillFormData(event[0]);

        this.setSadedtxageQuery();
    }

    public createNewEntry() {
        this.selectedRow = {};
        this.readonly = false;
        this.newEntryMode = true;

        if (this.dedCode === 'LAA') {
            // The second character, which is a number, is handled at the saemp deduction level
            this.formGroup.controls['txCode']
                .setValidators([
                    Validators.pattern('^([A-Za-z]{1})$'),
                    Validators.required]);
        } else if (this.dedCode === 'LPP') {
            this.formGroup.controls['txCode']
                .setValidators([
                    Validators.pattern('^([A-Za-z0-9]{1,8})$'),
                    Validators.required]);
        } else if (this.dedCode === 'LAC' || this.dedCode === 'IJM') {
            this.formGroup.controls['txCode']
                .setValidators([
                    Validators.pattern('^([A-Za-z0-9]{1}[0-9]{1})$'),
                    Validators.required]);
        } else {
            this.formGroup.controls['txCode'].setValidators(Validators.required);
        }
        this.clearForm();
    }

    public clearForm() {
        super.clearForm();
        // Handle issue with undefined
        this.setFormValue('txCode', '');
        this.setFormValue('titre', '');
    }

    public modifyEntry(): void {
        if (this.selectedRow) {
            this.readonly = false;
            this.newEntryMode = false;
        }
    }

    public saveEntry(): void {
        this.setFormValues();
        const formData = this.getFormData();
        const updatedData = this._restSadedtxClient.construct({
            ...this.selectedRow, ...formData
        });

        this.readonly = true;
        this._isSaving = true;

        if (this.newEntryMode) {
            this.sadedtxService.createNewEntry(updatedData)
                .subscribe(() => {
                    this.sadedtxGrid?.refresh();
                });
            this.newEntryMode = false;
        } else {
            this.sadedtxService
                .saveById(this.getFormValue('id'), updatedData)
                .subscribe(() => {
                    this.sadedtxGrid?.refresh();
                });
        }
        this.setSortByModif();
    }

    public async removeEntry() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('supprimerEnregisrement')],
            showCancel: true,
            title: this._translate.instant('sadedtx_form_title'),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            if (this.selectedRow && this.selectedRow.txCode) {
                this.sadedtxService
                    .deleteById(this.selectedRow?.id)
                    .subscribe(() => {
                        this.sadedtxGrid?.refresh();
                    });
            }
        } catch (error) {
        }
    }

    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.readonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }


    public setSortInfo(sortInfo: SortInfo<Sadedtx>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    public getSadedtxageRow(event: Sadedtxage[]) {
        if (!event.length) {
            this.selectedSadedtxageRow = undefined;
            return;
        }
        this.selectedSadedtxageRow = event[0];
    }

    public openSadedtxageDialog() {

        this._formHandler.showFormDialog(SalaryForm.Sadedtxage, {
            annee: this.selectedRow?.annee,
            dedCode: this.dedCode,
            assCode: this.assCodeVal,
            txCode: this.selectedRow?.txCode,
            entryMode: 'add'
        });

    }

    public changeSadedtxage() {
        this._formHandler.showFormDialog(SalaryForm.Sadedtxage, {
            annee: this.selectedRow?.annee,
            dedCode: this.selectedRow?.dedCode,
            assCode: this.selectedRow?.assCode,
            txCode: this.selectedRow?.txCode,
            entryMode: 'edit',
            sadedtxage: {
                annee: this.selectedRow?.annee,
                dedCode: this.selectedRow?.dedCode,
                assCode: this.selectedRow?.assCode,
                txCode: this.selectedRow?.txCode, ...this.selectedSadedtxageRow
            }
        });
    }

    // eslint-disable-next-line complexity
    public removeSadedtxage() {

        if (this.selectedSadedtxageRow?.annee && this.selectedSadedtxageRow?.dedCode && this.selectedSadedtxageRow?.assCode && this.selectedSadedtxageRow?.txCode && this.uuid) {
            this._store.dispatch(new EntityDelete(Sadedtxage, this.selectedSadedtxageRow?.txageId ?? ''));
        }
        this.selectedSadedtxageRow = undefined;
        this.sadedtxageGrid?.refresh();
    }

    protected getFormControlNames(): FormKeys<Sadedtx> {
        return [
            'id',
            'annee',
            'dedCode',
            [
                'txCode',
                new UntypedFormControl(undefined, Validators.required)],
            'assCode',
            'titre',
            'txcot',
            'txcotH',
            'txcotF',
            'txcoe',
            'txcoeH',
            'txcoeF',
            'salmin',
            'agemin',
            'agemnt',
            'salmax',
            'agemax',
            'txCodeSwissdec'];
    }

    protected getValidationType() {
        return Sadedtx;
    }

    protected validateFields(e: Sadedtx): boolean {
        return super.validateFields(e);
    }

    private setAssuranceOptions() {
        if (this.listDedCode.includes(this.dedCode)) {
            this.assuranceOptions = [];
            this.sapardedService.getAssuranceByAnnee(this.dedCode)
                .subscribe((data: string[]) => {
                    // Add undefined option
                    this.assuranceOptions.push({
                        name: this._translate.instant('combo_no_option'),
                        value: ''
                    });
                    data.forEach((e) => {
                        this.assuranceOptions.push({
                            name: e,
                            value: e
                        });
                    });
                });
        } else {
            this.assCodeVal = this.assurance;
        }
    }

    private getSadedtxageColumns(): IwGridColumn<Sadedtxage>[] {
        return [
            {
                prop: 'agemin',
                type: 'number',
                name: this._translate.instant('agemin'),
                index: 0
            },
            {
                prop: 'agemax',
                type: 'number',
                name: this._translate.instant('agemax'),
                index: 1
            },
            {
                prop: 'agemnt',
                type: 'number',
                name: this._translate.instant('montant'),
                index: 2
            }];
    }

    private setSortByModif() {
        this.sortProp = 'txCode';
        this.sortPropDir = 'asc';
    }

    private setQuery() {
        if (typeof this.annee === 'string' && typeof this.dedCode === 'string') {
            this.query = [
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'annee',
                    value: this.annee
                },
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'dedCode',
                    value: this.dedCode
                }];
        }
    }

    private setSadedtxageQuery() {
        this.sadedtxageQuery = [
            {
                prop: 'annee',
                operation: RestQueryOperation.Equals,
                value: this.selectedRow?.annee || ''
            },
            {
                prop: 'dedCode',
                operation: RestQueryOperation.Equals,
                value: this.selectedRow?.dedCode || ''
            },
            {
                prop: 'txCode',
                operation: RestQueryOperation.Equals,
                value: this.selectedRow?.txCode || ''
            }];
    }

    private setFormValues() {
        this.setFormValue('annee', this.annee);
        this.setFormValue('dedCode', this.dedCode);
        this.setFormValue('assCode', this.assCodeVal);
    }

    private updateFormValidation() {
        if (this.isTauxMode2) {
            this.getFormControl('txcot')
                ?.setValidators(Validators.required);
            this.getFormControl('txcoe')
                ?.setValidators(Validators.required);
        }
        if (this.isTauxMode3) {
            this.getFormControl('txcotH')
                ?.setValidators(Validators.required);
            this.getFormControl('txcoeH')
                ?.setValidators(Validators.required);
            this.getFormControl('txcotF')
                ?.setValidators(Validators.required);
            this.getFormControl('txcoeF')
                ?.setValidators(Validators.required);
        }
    }
}
