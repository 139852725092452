import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {EntityCreate, EntityUpdate, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryParam} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Agetime} from '@app/sam-base/models/salary/agetime';

@Component({
    templateUrl: './agetime-config.component.html',
    standalone: false
})
export class AgetimeComponent extends BaseStoreFormComponent<Agetime> implements OnInit {

    public query: RestQueryParam<Agetime, string>[] = [];
    private readonly _restAgetimeClient: RestEntityClient<Agetime>;

    constructor(store: IwStoreService, private _restService: RestApiService, private readonly _events: IwEventHubService<string>) {
        super(store);

        this._restAgetimeClient = this._restService.getEntityClient(Agetime);
    }

    public get ageId(): string {
        return this.getData('ageId', true) || '';
    }

    public get entryMode(): 'add' | 'edit' {
        return this.getData('entryMode', true) || 'edit';
    }

    public get agetime(): Agetime | undefined {
        return this.getData('agetime', true) || undefined;
    }

    public ngOnInit() {
        if (this.entryMode === 'edit' && !!this.agetime) {
            this.fillFormData(this.agetime);
        }
    }


    public save() {
        const formData = this.getFormData();
        const updatedData = this._restAgetimeClient
            .construct({...{ageId: this.ageId, agetId: this.agetime?.agetId}, ...formData});

        if (this.entryMode === 'add') {
            this._store.dispatch(new EntityCreate(updatedData));
        } else {
            this._store.dispatch(new EntityUpdate(updatedData));
        }

        this._events.emit('agetime_updated');
        this.destroyForm();
    }

    public destroyForm() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Agetime> {
        return ['hparsem', 'lecparsem'];
    }

}
