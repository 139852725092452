import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base/base-side-form.component';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {Sags} from '@app/sam-base/models/salary';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {ParametersService} from '@core/services/parameters.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './genre-salaire-side-menu.component.html',
    standalone: false
})
export class GenreSalaireSideMenuComponent extends BaseSideFormComponent<Sags> implements OnInit, OnDestroy {

    public sags?: Sags;
    public sapar?: Sapar;
    private subscriptions = new Subject();

    constructor(private _store: IwStoreService, private _parametersService: ParametersService) {
        super();
    }

    public get dateCreat() {
        return IwDateHelper.dateIsoString(this.sags?.dateCreat);
    }

    public get dateModif() {
        return IwDateHelper.dateIsoString(this.sags?.dateModif);
    }

    public ngOnInit() {
        this._parametersService.getCurrentSapar()
            .subscribe(sapar => this.sapar = sapar);

        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.sags = data;
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }
}
