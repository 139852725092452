import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {Sapar} from '@app/sam-base/models/salary';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sancsmot} from '@app/sam-base/models/salary/sancsmot';
import {lastValueFrom} from 'rxjs';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saemp-ncs', templateUrl: './tab-saemp-ncs.component.html',
    standalone: false
})
export class TabSaempNcsComponent extends BasePartialFormComponent<Saempc> {
    @Input() public sapar?: Sapar;

    constructor(private readonly _formHandlerService: FormHandlerService<SalaryForm>, private readonly _restApiService: RestApiService) {
        super();
    }

    public get saempId() {
        return this.getFormValue<string>('empId');
    }

    public get contactNcsId() {
        return this.getFormValue<string>('contactNcsId');
    }

    public get currentYear() {
        return this.sapar !== undefined ? this.sapar.annee : '';
    }

    public async avancee() {
        let isNew = false;

        if (!this.saempId || !this.currentYear) {
            return;
        }

        const sancsmotId = `${this.saempId},${this.currentYear}`;
        try {
            const sancsmot = await lastValueFrom(this._restApiService.getEntityClient(Sancsmot)
                .getById(sancsmotId));
        } catch (err: any) {
            if (err['status'] === 404) {
                isNew = true;
            }
        }

        this._formHandlerService.showFormDialog(SalaryForm.Sancsmot, {csEmpId: this.saempId}, (s) => ({
            ...s, entityId: sancsmotId ?? '', isNew
        }));
    }
}
