<div class="row">
    <div class="col-xs-10"
         style="height: 200px;">
        <iw-rest-grid #teamConfigGrid
                      (selected)="getSelectedRow($event)"
                      (sortInfo)="setSortInfo($event)"
                      [autoFormClick]="false"
                      [defaultSelected]="true"
                      [hasGridColumnMenu]="false"
                      [queryStatements]="query"
                      [selectedData]="selectedData"
                      [sortDir]="sortPropDir"
                      [sortProp]="sortProp"
                      [type]="type"></iw-rest-grid>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_new' | translate"
                   id="new"></iw-button>
        <iw-button (press)="modifyEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_edit' | translate"
                   id="modify"></iw-button>
        <iw-button (press)="removeEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_delete' | translate"
                   id="remove"></iw-button>
        <iw-button (press)="saveEntry()"
                   [isDisabled]="isReadonly"
                   [text]="'sidemenu_save' | translate"
                   id="save"></iw-button>
        <iw-button (press)="cancelEditionMode()"
                   [isDisabled]="isReadonly"
                   [text]="'sidemenu_cancel' | translate"
                   id="cancel"></iw-button>
    </div>

</div>
<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-xs-10">
            <iw-textfield #txtName
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'libelle' | translate"
                          class="iw-input col-xs-9"
                          formControlName="name"></iw-textfield>

        </div>
    </div>
    <div class="row">
        <div class="iw-form-group-label">{{ 'Members' | translate }}</div>
    </div>
    <div class="row">
        <iw-table (columnSort)="onSort($event)"
                  [columns]="columnsMember"
                  [data]="members"
                  [defaultSelected]="true"
                  [isDisabled]="false"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="true"
                  [selectionMode]="'checkbox'"
                  [virtualScroll]="true"
                  class="col-xs-10 iw-grid"
                  style="height: 250px"></iw-table>

    </div>
    <div class="row iw-form-group-label">{{ 'conseillers' | translate }}</div>
    <div class="row">
        <iw-table [columns]="columnsConseillers"
                  [data]="conseillers"
                  [defaultSelected]="false"
                  [isDisabled]="false"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="false"
                  [virtualScroll]="true"
                  class="col-xs-10 iw-grid"
                  style="height: 100px"></iw-table>
    </div>
    <div class="row col-xs-offset-10 col-xs-2 no-padding"></div>
    <div class="row">
        <iw-button #Closebutton
                   (press)="closeDialog()"
                   [text]="'fermer' | translate"
                   class="iw-button col-xs-12 flex-end"></iw-button>
    </div>
</form>