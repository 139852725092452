import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommunicationType} from '@core/mail/mail.models';
import {
    Operation,
    OperationDetail,
    OperationStatus,
    OperationType
} from '@modules/sam-main/admin/models/operation.model';
import {PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {OperationService} from '@modules/sam-main/admin/services/operation-service';
import {TranslateService} from '@ngx-translate/core';
import {EntityNavigationService} from '@sam-base/core';
import {IwGridColumn, RowClickEvent} from '@sam-base/models';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {Saemsview} from '@sam-base/models/salary/saemsview';
import {interval, Subject, takeWhile} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';


@Component({
    selector: 'iw-operation-detail',
    templateUrl: './operation-detail.component.html',
    standalone: false
})
export class OperationDetailComponent implements OnInit, OnDestroy {
    public columns = this.getColumns();
    public operationType = OperationType;
    public subOperationSelected?: Operation;
    protected readonly OperationType = OperationType;
    private intervalDuration: number = 5000;  // 5 seconds
    private subscriptions = new Subject<string>();

    constructor(private _operationService: OperationService,
                private _navigationService: EntityNavigationService,
                private readonly _translateService: TranslateService) {
    }

    private _meterValue = [
        {
            id: 'completed',
            label: 'Terminé',
            color: '#008000FF',
            value: 1,
            icon: 'pi pi-check'
        },
        {
            id: 'error',
            label: 'En erreur',
            color: '#a00101',
            value: 1,
            icon: 'pi pi-exclamation-triangle'
        },
        {
            id: 'pending',
            label: 'Pending',
            color: '#C0C0C0',
            value: 1,
            icon: 'pi pi-clock'
        }
    ];

    public get meterValue() {
        return this._meterValue;
    }

    public get showResult(): boolean {
        return this.operation?.status === OperationStatus.COMPLETED || this.operation?.status === OperationStatus.FAILED;
    }

    private _operationDetail?: OperationDetail;

    public get operationDetail(): OperationDetail | undefined {
        return this._operationDetail;
    }

    @Input()
    public set operationDetail(detail: OperationDetail) {
        this._operationDetail = detail;
        this.updateMeterValue();
        this.initIntervalStatusChecker();
    }

    public get operation(): Operation {
        return this.operationDetail?.operation ?? {};
    }

    public get operationErrorMessage(): string {
        return `<ul>${this.operation?.error?.errors.map(e => `<li>${this._translateService.instant(e.code, e.params)}</li>`).join('')}</ul>`
    }

    public get subOperationErrorMessage(): string {
        return `<ul>${this.subOperationSelected?.error?.errors.map(e => `<li>${this._translateService.instant(e.code, e.params)}</li>`).join('')}</ul>`
    }

    public get communicationType(): CommunicationType | undefined {
        switch (this.operation.type) {
            case OperationType.SEND_SALARY:
                return CommunicationType.SAEMS_BS;
            case OperationType.EMAIL_YEAR_SALARIES:
                return CommunicationType.SAEMP_BS_CURYEAR;
            case OperationType.SEND_INVOICE_REMINDER:
                return CommunicationType.GERPLHDR_RPL;
            case OperationType.SEND_INVOICE:
                return CommunicationType.GEFACHDR_FAC;
            default:
                return undefined;
        }
    }

    public get printRequestType(): PrintRequestType | undefined {
        switch (this.operation.type) {
            case OperationType.SEND_SALARY:
                return PrintRequestType.SALARY;
            case OperationType.SEND_INVOICE_REMINDER:
                return PrintRequestType.INVOICE_REMINDER;
            case OperationType.SEND_INVOICE:
                return PrintRequestType.INVOICE;
            default:
                return undefined;
        }
    }

    ngOnInit(): void {
    }

    onOperationDetailClicked(subOp: Operation) {
        if (this.subOperationSelected?.id === subOp.id) {
            this.subOperationSelected = undefined;
        } else {
            this.subOperationSelected = subOp;
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.next("stop");
        this.subscriptions.complete();
    }

    onRowDoubleClick(rowClickEvent: RowClickEvent<Operation>) {
        const row = rowClickEvent.row;
        console.log('row.entityId', row.entityId);
        switch (row.type) {
            case OperationType.SEND_SALARY:
            case OperationType.PUBLISH_SALARY:
            case OperationType.CALCULATE_SALARY:
                this._navigationService.navigateToEntityForm(Saemsview, row.entityId!, undefined, 'read');
                break;
            case OperationType.PUBLISH_INVOICE:
                this._navigationService.navigateToEntityForm(Gefacliview, row.entityId!, undefined, 'read');
                break;
        }
    }

    public retryOperation() {
        this._operationService.retryOperation(this.operation.id!).subscribe(
            (operationDetail) => {
                this.operationDetail = operationDetail;
            }
        )
    }

    private getColumns(): IwGridColumn<Operation>[] {
        return [
            {
                prop: 'id',
                name: 'id',
                colorMapper: (row: Operation) => {
                    if (row.status === OperationStatus.COMPLETED) {
                        return "#008000";
                    }
                    if (row.status === OperationStatus.FAILED) {
                        return '#a00101'
                    }
                    return "#C0C0C0";
                }
                ,
            },
            {
                prop: 'type',
                type: 'enum',
                enumPrefix: 'operation.type'
            },
            {
                prop: 'entityDescription',
                type: 'string'
            },
            {
                prop: 'status',
                type: 'enum',
                enumPrefix: 'operation.status',
            },
            {
                prop: 'dueAt',
                type: 'dateTime',
            },
            {
                prop: 'submittedBy',
                type: 'string',
            },
            {
                prop: 'submittedAt',
                type: 'dateTime',
            },
            {
                prop: 'startedAt',
                type: 'dateTime',
            },
            {
                prop: 'endedAt',
                type: 'dateTime',
            },
        ];
    }

    private initIntervalStatusChecker() {
        if (this.operation.id && ![
            OperationStatus.COMPLETED,
            OperationStatus.NEW,
            OperationStatus.FAILED].includes(this.operation?.status!)) {
            interval(this.intervalDuration).pipe(
                takeUntil(this.subscriptions),
                switchMap(() => this._operationService.getOperationDetails(this.operation.id!)),
                takeWhile(operationDetail => ![
                    OperationStatus.COMPLETED,
                    OperationStatus.FAILED].includes(operationDetail?.operation?.status!), true)
            ).subscribe(
                (operationDetail) => {
                    this._operationDetail = operationDetail;
                    this.updateMeterValue();
                });
        }
    }

    private updateMeterValue() {
        const errorNb = this.operationDetail?.subOperations?.filter(e => e.status === OperationStatus.FAILED)?.length ?? 0;
        const completedNb = this.operationDetail?.subOperations?.filter(e => e.status === OperationStatus.COMPLETED)?.length ?? 0;
        const pendingNb = this.operationDetail?.subOperations?.filter(e => e.status === OperationStatus.PENDING)?.length ?? 0;
        const pendingMeter = this._meterValue.find(e => e.id === 'pending');
        if (pendingMeter) {
            pendingMeter.value = pendingNb;
        }
        const completedMeter = this._meterValue.find(e => e.id === 'completed');
        if (completedMeter) {
            completedMeter.value = completedNb;
        }
        const errorMeter = this._meterValue.find(e => e.id === 'error');
        if (errorMeter) {
            errorMeter.value = errorNb;
        }
        this._meterValue = [...this._meterValue];
    }
}