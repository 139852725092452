import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Type} from '@angular/core';
import {EsQueryStatement} from '@app/sam-base/core/rest-api';
import {IwGridColumn, RowClickEvent, TableSelectionMode} from '@app/sam-base/models';
import {GridType} from '@app/sam-base/models/grid-type.model';
import {Ppcli} from '@app/sam-base/models/placement';
import {Subject} from 'rxjs';

@Component({
    templateUrl: './bulk-entity-select.component.html',
    standalone: false
})
export class BulkEntitySelectComponent<T> implements OnInit, OnDestroy {

    /** Selected items in table */
    @Output() public selectedEvent = new EventEmitter<T[]>();
    public columns: IwGridColumn<T>[] = [];
    /** Entity to load in table */
    public entity: Type<T> = <any>Ppcli;
    public selectMode: TableSelectionMode = 'checkbox';
    public gridType: GridType = GridType.select;
    public entityName = '';
    private subscriptions = new Subject();

    constructor() {
    }

    private _statements: EsQueryStatement<T>[] = [];

    public get statements() {
        return this._statements;
    }

    @Input()
    public set statements(v: EsQueryStatement<T>[]) {
        this._statements = v;
    }

    public get hasStatements() {
        return this._statements && this._statements.length > 0;
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public clear() {
    }

    public onRowDoubleClick(event: RowClickEvent<T>) {
        if (event && event.row) {
            this.dispatchSelection([event.row]);
        }
    }

    onSelect(selectedEntities: T[]) {
        this.selectedEvent.emit(selectedEntities);
    }

    private dispatchSelection(s: T[]) {
    }
}
