import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Ppcli} from '@app/sam-base/models/placement';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const ppcliTabsFormControls: TabFormControl<Ppcli>[] = [
    {
        formControls: [
            'cliId',
            'clistatustext',
            'prefixe',
            'dateCreat',
            'dateModif',
            'clistatus',
            'ccliStatus',
            'cclistatu2',
            'cclistatu3',
            'firstMis',
            'lastMis',
            'dateDelet',
            'dtAnyModif',
            'faceleord',
            'facadrId',
            'paicondId',
            'kchkNo',
            'ccemail',
            'ijEmail',
            'ifac2ijmod',
            'geoLat',
            'noteBo',
            'categids',
            'lexpfac2ij',
            'modData',
            'modCnt',
            'noteFo',
            'cliadrId',
            'nomailing',
            'missalmode',
            'modecreat',
            'mfm5def',
            'cmavfrx',
            'kchkNote',
            'syncflag',
            'evafrx',
            'miscoeff',
            'aptids',
            'ccavfrx',
            'nbemp',
            'allowsms',
            'cliattId',
            'kchkUser',
            'misfrais',
            'pointData',
            'lexp2ij',
            'ijRapnote',
            'userId',
            'hpautarmod',
            'categories',
            'misfacmode',
            'geoLon',
            'clitarcct',
            'userCreat',
            'userDelet',
            'userModif',
            'ppcon',
            'ppagence',
            'nomconseiller']
    },
    {
        title: 'general',
        formControls: [
            'noGestion',
            'nom',
            'adresse1',
            'adresse2',
            'adresse3',
            'npa',
            'lieu',
            'pays',
            'regions',
            'tel1',
            'natel',
            'fax',
            'email',
            'www',
            'ageId',
            'cliintype',
            'cliIndex',
            'usertag',
            'kredchk',
            'kchkdate',
            'kchkrating',
            'conId']
    },
    {
        title: 'autreTab',
        formControls: [
            'modefactu',
            'facnpmode',
            'clitarifmd',
            'clitarifmd',
            'clitarmd6f',
            'hpaufacmod',
            'hpausaimod',
            'shsupmode',
            'formejuri',
            'langue',
            'cdeclilieu',
            'lhhmm',
            'forcecha',
            'rapnoref',
            'horaire',
            'ccfrx',
            'cctids',
            'cctoblig',
            'escompte',
            'cmfrx',
            'cmfrx']
    },
    {
        title: 'contact',
        formControls: []
    },
    {
        title: 'adresse',
        formControls: ['ccadrId']
    },
    {
        title: 'notes',
        formControls: [
            'remarques',
            'cliData',
            'xmlData']
    },
    {
        title: 'suivi',
        formControls: ['cliId']
    },
    {
        title: 'chantier',
        formControls: []
    },
    {
        title: 'matching',
        formControls: []
    },
    {
        title: 'docs',
        formControls: []
    },
    {
        title: 'mission',
        formControls: [
            'misnotecli',
            'misnoteemp',
            'misnoteint',
            'heusaimode']
    },
    {
        title: 'pointeuseIJ',
        formControls: [
            'lpointeuse',
            'pointtype',
            'pointclino',
            'pointMode',
            'lexpmis2Ij',
            'lexpfac2ij',
            'ijRapSm',
            'icc2ijmod',
            'ijData',
            'ijTsReq',
            'ijRap1M']
    }];
