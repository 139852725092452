import {Component, Input, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwTableComponent} from '@app/sam-base/components';
import {EntityDelete, IwStoreService, ModalService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ComboboxItem} from '@app/sam-base/models';
import {Gecliadr} from '@app/sam-base/models/invoices/gecliadr';
import {Ppcli} from '@app/sam-base/models/placement';
import {GecliadrComponent} from '../../gecliadr/gecliadr.component';
import {Gecliadropts} from '../../gecliadr/gecliadr.options';


@Component({
    selector: 'iw-tab-address',
    templateUrl: './tab-client-address.component.html',
    styleUrls: ['./tab-client-address.component.scss'],
    standalone: false
})
export class TabClientAddressComponent extends BasePartialFormComponent<Ppcli> {

    public type: Type<Gecliadr> = Gecliadr;

    public fullAdresse = '';

    @Input() public isReadonly = true;

    @Input() public uuid?: string;
    @ViewChild('gecliadrGrid', {static: true}) public addrGrid?: IwTableComponent<Gecliadr>;
    public cliadrId?: string;
    public ccadrId?: string;
    public query: RestQueryParam<Gecliadr, string>[] = [];
    public ccadrIdOpts: ComboboxItem<string>[] = [];
    /**
     * Var to see if row is selected
     */
    public isSelected = false;
    public selectedRowData?: Gecliadr;

    constructor(private readonly _modalService: ModalService, private readonly _store: IwStoreService) {
        super();
    }

    private _entityId?: string;

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string | undefined) {
        this.setEntityId(v);
        this.filterById(v);
    }

    public get selectedData() {
        if (!this.selectedRowData) {
            return undefined;
        }
        return {name: 'cliadrId', value: this.selectedRowData.cliadrId};
    }

    /**
     * Function to select a row
     *
     * @param event - Gecliadr[]
     */
    public onSelectedRow(event: Gecliadr[]) {
        if (!event.length) {
            this.fullAdresse = '';
            this.isSelected = false;
            return;
        }
        this.isSelected = true;
        this.selectedRowData = event[0];
        this.fullAdresse = this.buildAdresse(event[0]);
        this.cliadrId = (event[0] && event[0].cliadrId) || '';
    }

    /**
     * Function to build full addresse
     *
     * @param p - Gecliadr
     */
    // eslint-disable-next-line complexity
    public buildAdresse(p: Gecliadr): string {
        if (!p) {
            return '';
        }

        let fullAdresse = '';

        if (p.prefixe) {
            fullAdresse += `${p.prefixe}\n`;
        }
        if (p.nom) {
            fullAdresse += `${p.nom}\n`;
        }
        if (p.adresse1) {
            fullAdresse += `${p.adresse1}\n`;
        }
        if (p.adresse2) {
            fullAdresse += `${p.adresse2}\n`;
        }
        if (p.adresse3) {
            fullAdresse += `${p.adresse3}\n`;
        }
        if (p.npa || p.lieu) {
            fullAdresse += `${p.npa} ${p.lieu}\n`;
        }

        return fullAdresse;
    }

    /**
     * Function to open Gecliadr form to add new addresse
     */
    public async openAddresseClient() {
        const options: Gecliadropts = {
            cliadrId: '', cliId: this.entityId, cliFormUuid: this.uuid
        };

        try {
            await this._modalService.showModal(GecliadrComponent, options);
            if (this.addrGrid) {
                this.addrGrid.refresh();
            }
        } catch (error) {
        }
    }

    /**
     * Function to open Gecliadr form to edit one addresse
     */
    public async editAddresseClient() {
        const options: Gecliadropts = {
            cliadrId: this.cliadrId, cliId: this.entityId, cliFormUuid: this.uuid
        };

        try {
            await this._modalService.showModal(GecliadrComponent, options);
            if (this.addrGrid) {
                this.addrGrid.refresh();
            }
        } catch (error) {
        }
    }

    /**
     * Function to delete adresse
     */
    public removeAddresseClient() {
        if (this.cliadrId) {
            this._store.dispatch(new EntityDelete(Gecliadr, this.cliadrId));
        }
    }

    public setDropdownOptions(addresses: Gecliadr[]) {
        this.ccadrIdOpts = addresses.map((cliadr: Gecliadr) => ({
            name: cliadr.adrlib ?? '', value: cliadr.cliadrId ?? ''
        }));
    }

    public setDefaultAddress() {
        if (this.selectedRowData) {
            this.setFormValue('cliadrId', this.selectedRowData.cliadrId);
            this.setFormValue('prefixe', this.selectedRowData.prefixe);
            this.setFormValue('nom', this.selectedRowData.nom);
            this.setFormValue('adresse1', this.selectedRowData.adresse1);
            this.setFormValue('adresse2', this.selectedRowData.adresse2);
            this.setFormValue('adresse3', this.selectedRowData.adresse3);
            this.setFormValue('npa', this.selectedRowData.npa);
            this.setFormValue('lieu', this.selectedRowData.lieu);
        }
    }

    private setEntityId(v?: string) {
        if (v && this._entityId !== v) {
            this._entityId = v;
        }
    }

    private filterById(cliId?: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'cliId', value: cliId || '_'
        }];
    }
}
