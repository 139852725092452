import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {IwGridColumn, ModalComponent, RowClickEvent} from '@app/sam-base/models';
import {DocumentManagementService} from '@core/document-management/document-management.service';
import {CommunicationType} from '@core/mail/mail.models';
import {MailService} from '@core/mail/mail.service';
import {EmailListOptions} from '@modules/sam-main/admin/components/emails/email-list-options';
import {EmailView} from '@modules/sam-main/admin/models/email-view.model';
import {IwRestGridComponent} from '@sam-base/components';
import {EntityStatusMap} from '@sam-base/components/iw-grid-column-config/entity-status-map';
import {EntityNavigationService, RestQueryOperation, RestQueryParam, SearchEntityStatusOperator} from '@sam-base/core';

@Component({
    templateUrl: './email-list.component.html',
    standalone: false
})
export class EmailListComponent<T> implements ModalComponent<string, EmailListOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public loading = false;
    @ViewChild('printRequestGrid') public grid?: IwRestGridComponent<EmailView>;
    public query: RestQueryParam<EmailView, string>[] = [];
    public columnsToShow: IwGridColumn<EmailView>[] = [
        {
            name: 'id',
            prop: 'id',
            type: 'number',
            hidden: true
        },
        {
            name: 'type',
            prop: 'communicationType',
            enumPrefix: 'email.communication_type',
            valueFormat: 'email_communication_type',
            type: 'enum'
        },
        {
            name: 'sujet',
            prop: 'subject',
            type: 'string'
        },
        {
            name: 'mail_sender',
            prop: 'replyTo',
            type: 'string'
        },
        {
            name: 'mail_recipients',
            prop: 'recipients',
            type: 'string'
        },
        {
            name: 'mail_cc_recipients',
            prop: 'ccRecipients',
            type: 'string'
        },
        {
            name: 'mail_bcc_recipients',
            prop: 'bccRecipients',
            type: 'string'
        },
        {
            name: 'attachments',
            prop: 'attachments',
            type: 'boolean'
        },
        {
            name: 'entityId',
            prop: 'entityId',
            type: 'string',
            hidden: true
        },
        {
            name: 'status',
            prop: 'status',
            type: 'enum',
            enumPrefix: 'email.status',
        },
        {
            name: 'error',
            prop: 'error',
            type: 'warning'
        },
        {
            name: 'dateCreat',
            prop: 'dateCreat',
            type: 'dateTime'
        },
        {
            name: 'dateModif',
            prop: 'dateModif',
            type: 'dateTime'
        },
        {
            name: 'dateDelet',
            prop: 'dateDelet',
            type: 'dateTime',
            hidden: true
        },
        {
            name: 'dateTrack',
            prop: 'dateTrack',
            type: 'dateTime',
            hidden: true
        },
    ];
    public origin?: string;
    public currentMail?: EmailView;
    protected readonly emailViewType = EmailView;
    private _ok?: (result: string) => void;
    private _cancel?: (result?: undefined) => void;

    constructor(private readonly _mailService: MailService,
                private _navigationService: EntityNavigationService,
                public readonly _documentService: DocumentManagementService) {
    }

    private _communicationType?: CommunicationType;

    public set communicationType(value: CommunicationType | undefined) {
        this._communicationType = value;
        if (value === undefined) {
            this.query = [];
            return;
        }
        const column = this.columnsToShow.find(e => e.prop === 'communicationType');
        if (column) {
            column.statusQuery = EntityStatusMap.getFilterBooleanConfig('email_communication_type', [this._communicationType]);
            column.filterOperator = SearchEntityStatusOperator.In;
            column.indexed = false;
        }
        this.query = [
            {
                operation: RestQueryOperation.In,
                prop: 'communicationType',
                value: EntityStatusMap.getFilterStringConfig('email_communication_type', [this._communicationType])
            }
        ];
    }

    private _scopeIds?: string[];

    public set scopeIds(ids: string[] | undefined) {
        this._scopeIds = ids;
        if (this._scopeIds === undefined) {
            return;
        }
        let idQuery = this.query.find(q => q.prop === 'id');
        if (!idQuery) {
            idQuery = {
                operation: RestQueryOperation.In,
                prop: 'id',
                value: this._scopeIds
            };
            this.query.push(idQuery);
        } else {
            idQuery.operation = RestQueryOperation.In;
            idQuery.prop = 'id';
            idQuery.value = this._scopeIds;
        }
    }

    public setData(data: EmailListOptions): Promise<void> {
        this.communicationType = data.type;
        this.scopeIds = data.ids;
        this.origin = data.origin
        return Promise.resolve();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onBeforeHide() {
        if (this._ok) {
            this._ok('');
        }
    }

    // Method to handle the download
    public downloadAttachment(att: string): void {
        // Call your existing method to download the file
        this._documentService.downloadDocumentByPath(att, this.getFileName(att));
    }

    // Method to extract filename from the path
    public getFileName(att: string): string {
        return att.split('/').pop() || att;
    }

    public ngOnInit(): void {
        // Initialization logic here
    }

    public onRowDoubleClick($event: RowClickEvent<EmailView>) {

    }

    public onSelection($event: EmailView[]) {
        this.currentMail = $event[0];
    }
}