<form [formGroup]="formGroup"
      class="row">

    <div class="row col-xs-12 padding-top-10">
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'numero' | translate"
                      [ngClass]="factureColorClass"
                      class="iw-input col-xs-3 col-no-padding"
                      formControlName="facNo"></iw-textfield>
        <iw-textfield [isDisabled]="disabled"
                      class="iw-input col-xs-2"
                      formControlName="dateFac"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      class="iw-input col-xs-1"
                      formControlName="facOrnc"></iw-textfield>
        <iw-checkbox [isDisabled]="true"
                     class="iw-input col-xs-1"
                     formControlName="modified"></iw-checkbox>
        <iw-agency-combo-box [isDisabled]="true"
                             [labelAlign]="'left'"
                             [label]="'agence' | translate"
                             [size]="'small'"
                             class="iw-input col-xs-3"
                             formControlName="ageId"></iw-agency-combo-box>
        <iw-textfield [isDisabled]="true"
                      class="iw-input col-xs-1 col-no-padding"
                      formControlName="cptaRef"></iw-textfield>
        <iw-button #decomptaBtn
                   (press)="decomptabiliser()"
                   *iwAccessGroups="FACTURE_COMPTA_ROLES"
                   [isDisabled]="isEmptyCptaRef"
                   [text]="'D'"
                   class="iw-button col-xs-0"></iw-button>
    </div>
    <div class="row col-xs-12">
        <iw-textfield [isDisabled]="!isWriteMode"
                      [labelAlign]="'left'"
                      [label]="'client' | translate"
                      [value]="clientName"
                      class="iw-input col-xs-7 col-no-padding"></iw-textfield>
        <iw-textfield [isDisabled]="!isWriteMode"
                      [value]="clientId"
                      class="col-xs-2"></iw-textfield>

        <iw-button #cliBtn
                   (press)="openClient()"
                   *iwAccessGroups="DEBITOR_ROLES"
                   class="iw-button col-xs-1 flex-end no-padding"
                   iconClass="fas fa-link"></iw-button>
    </div>
</form>
<iw-tab [(selectedTab)]="selectedTab"
        [disabledTabs]="disabledTabList"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">

    <iw-tab-facture-qr *ngIf="selectedTab === tabList[3]"
                       [formGroup]="formGroup"></iw-tab-facture-qr>

    <iw-tab-facture-entete *ngIf="selectedTab === tabList[0]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-facture-entete>

    <iw-tab-facture-adresse *ngIf="selectedTab === tabList[2]"
                            [formGroup]="formGroup"
                            [isReadonly]="!isWriteMode"></iw-tab-facture-adresse>

    <iw-tab-facture-detail *ngIf="selectedTab === tabList[1]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-facture-detail>
</iw-tab>
