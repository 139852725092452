import {Component, OnDestroy} from '@angular/core';
import {HEADER_MENU_ENTRIES} from '@app/layout/configs/menus';
import {SIDE_MENU_ITEMS} from '@app/layout/configs/side-menu';
import {IwEventHubService} from '@app/sam-base/core/events';
import {LayoutEvents} from '@app/sam-base/layout/events/events.models';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

import {IwStoreService} from '@sam-base/core/store/iw-store.service';
import packageInfo from 'package.json';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'iw-layout',
    templateUrl: './layout.component.html',
    standalone: false
})
export class LayoutComponent implements OnDestroy {
    public open = false;

    public interijobActive = false;
    public activeMenu?: LayoutEvents;
    // eslint-disable-next-line
    public readonly SlidePanelEvent = LayoutEvents.TOGGLE_SLIDE_PANEL;
    public menuEntries = HEADER_MENU_ENTRIES;
    public sideMenuItems = SIDE_MENU_ITEMS;
    public readonly appVersion = packageInfo.version;
    private _subs = new Subject();

    constructor(private _events: IwEventHubService<LayoutEvents>, private _store: IwStoreService,
                private readonly _translate: TranslateService) {
        this._events.events.subscribe(e => {
            if (e.type === LayoutEvents.TOGGLE_MENU) {
                this.open = !this.open;
            }
        });
        this.translateMenuEntries();

        // Listen to language changes
        this._translate.onLangChange.pipe(takeUntil(this._subs)).subscribe((event: LangChangeEvent) => {
            if (event.lang) {
                this.translateMenuEntries();
            }
        });

        this._store.getLicenseOption('interijobwebaccess')
            .subscribe((active: boolean) => {
                this.interijobActive = active;
                let needToRefresh = false;
                /** Enables IJ configuration menu if interijob is active in license */
                this.menuEntries.forEach(entry => {
                    entry.items?.forEach(items => {
                        items.forEach(item => {
                            item?.items?.forEach(subItem => {
                                if (subItem.id === 'ij-config') {
                                    subItem.disabled = !this.interijobActive;
                                    // Force update on setter
                                    needToRefresh = true;
                                }
                            })
                        })
                    })
                });
                if (needToRefresh) {
                    this.menuEntries = [...this.menuEntries];
                }
            });
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    private translateMenuEntries() {
        this.menuEntries.forEach(entry => {
            if (entry.labelKey) {
                entry.label = this._translate.instant(entry.labelKey);
            }
            entry.items?.forEach(items => {
                items.forEach(item => {
                    if (item.labelKey) {
                        item.label = this._translate.instant(item.labelKey);
                    }
                    item.items?.forEach(subItem => {
                        if (subItem.labelKey) {
                            subItem.label = this._translate.instant(subItem.labelKey);
                        }
                    })
                })
            })
        });
        this.menuEntries = [...this.menuEntries];
        this.sideMenuItems.forEach(entry => {
            if (entry.labelKey) {
                entry.label = this._translate.instant(entry.labelKey);
                entry.title = this._translate.instant(entry.labelKey);
            }
            entry.items?.forEach(item => {
                if (item.labelKey) {
                    item.label = this._translate.instant(item.labelKey);
                }
            })
        })
        this.sideMenuItems = [...this.sideMenuItems];
    }

}
