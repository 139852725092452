import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {TemplateService} from '@modules/sam-main/placement/components/gestion-modeles/template.service';
import {TranslateService} from '@ngx-translate/core';
import {Template, TemplateModule} from '@sam-base/models/placement';
import {saveAs} from 'file-saver';
import {FileUpload} from 'primeng/fileupload';

@Component({
    templateUrl: './gestion-modeles.component.html',
    standalone: false
})
export class GestionModelesComponent extends BaseFormComponent<Template> implements OnInit {

    public type = Template;
    public isReadonly = true;
    public pdtId = 0;
    public selectedRow: Template = {};
    public newEntryMode = false;
    public entityRef = '';
    public currFile: string | Blob = '';
    public reportTypes: ComboboxItem<string>[] = [];

    public isSaving = false;

    @ViewChild('gestionTemplateGrid', {static: true}) public tempGrid?: IwRestGridComponent<Template>;

    @ViewChild('fileUploader', {static: true}) public fileUploader?: FileUpload;

    query: RestQueryParam<Template, any>[] = [];

    /** Dummy query created to bypass filter that requires some sort of params */

    constructor(private readonly _store: IwStoreService,
                private readonly _toastService: ToastService,
                private readonly _translateService: TranslateService,
                private readonly _templateService: TemplateService) {
        super();
    }

    public get module(): TemplateModule {
        return this.getData('module') as TemplateModule;
    }

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {
            name: 'id',
            value: this.selectedRow.id
        };
    }

    public get fileNameTooLong(): boolean {
        const fileNameControl = this.getFormControl('filename');
        if (fileNameControl?.valid) return false;
        return fileNameControl?.errors?.maxlength;
    }

    ngOnInit(): void {
        this.query = [
            {
                prop: 'id',
                operation: RestQueryOperation.NotEquals,
                value: 0
            },
            {
                prop: 'module',
                operation: RestQueryOperation.Equals,
                value: this.module
            }];
        this.loadReportTypes();
    }

    /**
     * Function to get selected row
     *
     * @param event Template[]
     * @returns void
     */
    public getSelectedRow(event: Template[]): void {
        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.selectedRow = {};
            this.clearForm();
            return;
        }

        this.pdtId = (event[0] && event[0].id) || 0;
        this.selectedRow = event[0];
        this.fileUploader?.clear();
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new Template entry
     *
     * @returns void
     */
    public createNewEntry(): void {
        this.selectedRow = {};
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /*
     * Called when a file is added on file uploader
     */
    public uploadDocument(file: any): void {
        const reader = new FileReader();
        if (file.currentFiles.length) {
            reader.readAsDataURL(file.currentFiles[0]);
            reader.onload = () => {
                this.setFormValue('filename', file.currentFiles[0].name);
                this.currFile = file.currentFiles[0];
            };
            reader.onerror = () => {
                this._toastService.warning('template_upload_error');
            };
        } else {
            this._toastService.warning('invalidFile');
        }
    }

    /*
     * Called when a file is removed on file uploader
     */
    public fileUnselected() {
        this.setFormValue('content', undefined);
    }

    public removeEntry() {
        if (this.selectedRow && this.selectedRow.id) {
            this._templateService.deleteTemplate(this.module, this.selectedRow.id).subscribe(() => {
                this.refreshGrid();
            });
        }
    }

    /**
     * Function to save changes on existing or new entry
     * send the request in form-data
     *
     * @returns void
     */
    public saveEntry(): void {
        const formData: FormData = new FormData();
        formData.append('file', this.currFile);
        formData.append('name', this.getFormValue('name'));
        formData.append('type', this.getFormValue('report'));
        formData.append('filename', this.getFormValue('filename'));
        this.isReadonly = true;
        this.clearForm();

        this.isSaving = true;

        if (this.newEntryMode) {
            this._templateService.saveTemplate(this.module, formData)
                .subscribe({
                    next: () => {
                        this.newEntryMode = false;
                        this.refreshGrid();
                    },
                    error: (err: HttpErrorResponse) => this.throwError(err)
                });
        } else {
            this._templateService
                .updateTemplate(this.module, this.selectedRow.id || 0, formData)
                .subscribe({
                    next: () => {
                        this.newEntryMode = false;
                        this.refreshGrid();
                    },
                    error: (err: HttpErrorResponse) => this.throwError(err)
                });
        }
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry(): void {
        if (this.selectedRow && this.selectedRow.id) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode(): void {
        this.fillFormData(this.selectedRow);
        this.isReadonly = true;
        this.newEntryMode = false;
        this.fileUploader?.clear();
    }

    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    /**
     * Downloads the currently uploaded template
     */
    public downloadTemplate() {
        if (!this.selectedRow.id) {
            return;
        }
        this._templateService
            .getTemplateFile(this.module, this.selectedRow.id)
            .subscribe((template) => {
                const name = this.getFormValue('filename');
                const blobData = new Blob([template], {
                    type: 'applicaton/octet-stream'
                });
                return saveAs(blobData, name);
            });
    }

    public validateFields(e?: Template): boolean {
        return super.validateFields(e);
    }

    protected getValidationType() {
        return Template;
    }

    protected getFormControlNames(): FormKeys<Template> {
        return [
            'id',
            'name',
            'filename',
            'report',
            'content'];
    }

    /**
     * Function to refresh display grid after insert/upload
     */
    private refreshGrid(): void {
        if (!this.tempGrid) {
            return;
        }
        this.tempGrid.refresh();
        if (!this.fileUploader) {
            return;
        }
        this.fileUploader.clear();
    }

    private throwError(error: HttpErrorResponse) {
        if (error.status === 409) {
            this._toastService.error('template_name_exists');
        } else {
            this._toastService.error('error_save_template');
        }
    }

    private loadReportTypes() {
        this.reportTypes = [
            {
                name: '-------',
                value: ''
            }];
        this._templateService.getReportsByModule(this.module).subscribe(reports => {
            reports.forEach(report => {
                this.reportTypes.push({
                    value: report,
                    name: this._translateService.instant('enum.report.' + report)
                });
            })
        })
    }
}
