import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommunicationType} from '@core/mail/mail.models';
import {EmailListOptions} from '@modules/sam-main/admin/components/emails/email-list-options';
import {EmailListComponent} from '@modules/sam-main/admin/components/emails/email-list.component';
import {
    PrintRequestManagerComponent
} from '@modules/sam-main/admin/components/print-request/print-request-manager.component';
import {
    PrintRequestManagerOptions
} from '@modules/sam-main/admin/components/print-request/print-request-manager.options';
import {OperationDetail} from '@modules/sam-main/admin/models/operation.model';
import {PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@sam-base/core';
import {Subject} from 'rxjs';

@Component({
    selector: 'iw-operation-detail-communication-result-viewer',
    templateUrl: './send-entity-operation-detail-result-viewer.component.html',
    standalone: false
})
export class SendEntityOperationDetailResultViewer implements OnInit, OnDestroy {

    @Input()
    public communicationType?: CommunicationType;
    @Input()
    public printRequestType?: PrintRequestType;
    @Input()
    public operationDetail?: OperationDetail;
    private subscriptions = new Subject();

    constructor(private readonly _modalService: ModalService,
                private readonly _translateService: TranslateService) {
    }

    public get nbOfMail(): number {
        const flatMap = this.extractEntityIds(this.operationDetail, 'EMAIL');
        return flatMap?.length || 0;
    }

    public get nbOfPrintRequest(): number {
        const flatMap = this.extractEntityIds(this.operationDetail, 'PRINT_REQUEST');
        return flatMap?.length || 0;
    }

    public get originMail(): string {
        switch (this.communicationType) {
            case CommunicationType.GEFACHDR_FAC:
                return 'email_list_component.origin.send_invoice';
            case CommunicationType.GERPLHDR_RPL:
                return 'print_request.origin.send_invoice_reminder';
            case CommunicationType.SAEMS_BS:
                return 'email_list_component.origin.send_salary';
            case CommunicationType.SAEMP_BS_CURYEAR:
                return 'email_list_component.origin.send_salary';
            default:
                return '';
        }
    }

    public get originPrintRequest(): string {
        switch (this.printRequestType) {
            case PrintRequestType.INVOICE:
                return 'print_request.origin.send_invoice';
            case PrintRequestType.INVOICE_REMINDER:
                return 'print_request.origin.send_invoice_reminder';
            case PrintRequestType.SALARY:
                return 'print_request.origin.send_salary';
            default:
                return '';
        }
    }


    public ngOnInit() {
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public openMail() {
        const options: EmailListOptions = {
            type: this.communicationType,
            ids: this.extractEntityIds(this.operationDetail, 'EMAIL'),
            origin: this._translateService.instant(this.originMail, {operationId: this.operationDetail?.operation?.id})
        };
        this._modalService.showModal(EmailListComponent, options);
    }

    public openPrintRequest() {
        const options: PrintRequestManagerOptions = {
            type: this.printRequestType,
            ids: this.extractEntityIds(this.operationDetail, 'PRINT_REQUEST'),
            origin: this._translateService.instant(this.originPrintRequest, {operationId: this.operationDetail?.operation?.id})
        };
        this._modalService.showModal(PrintRequestManagerComponent, options);
    }

    private extractEntityIds(operationDetail: OperationDetail | undefined, type: string): string[] {
        if (!operationDetail) {
            return [];
        }
        return operationDetail.subOperations?.flatMap(subOp =>
            subOp.result?.idsPerEntityMap?.[type] || []
        ) || [];
    }
}
