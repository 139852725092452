import {Component, Input, ViewChild} from '@angular/core';
import {
    DocumentTypeManagementComponent
} from '@modules/sam-main/placement/components/employee/tab-ppemp-docs/document-type-management/document-type-management.component';
import {DocumentEntityRef} from '@sam-base/models/placement/document-entity-ref';

@Component({
    selector: 'iw-tab-client-docs',
    templateUrl: './tab-client-docs.component.html',
    standalone: false
})
export class TabClientDocsComponent {

    public entityRef: DocumentEntityRef = DocumentEntityRef.Debiteur;
    @Input() public isReadonly = true;

    @ViewChild('docTypeGrid') public docTypeGrid?: DocumentTypeManagementComponent;

    private _entityId = '';

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string) {
        this._entityId = v;
    }


    // onRefreshGrid() {
    //     setTimeout(() => {
    //         this.docTypeGrid?.onValidateDocuments();
    //     }, 500);
    // }
}
