import {Component} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';

@Component({
    templateUrl: './sasx4dec-side-menu.component.html',
    standalone: false
})
export class Sasx4decSideMenuComponent extends BaseSideFormComponent<Sasx4dec> {

    constructor() {
        super();
    }
}
