import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';

/**
 * Check with the following regex if there are any repeated formcontrols
 * (\b\w+\b)(?=[\s\S]*\b\1\b)
 * You should declare the same formcontrol in different tabs if used there
 */
export const sasx4decTabsFormControls: TabFormControl<Sasx4dec>[] = [
    {
        title: 'general',
        formControls: [
            'sxId',
            'sxType',
            'decstatus',
            'sxType',
            'annee',
            'salId',
            'sxMode',
            'sxJobkey',
            'sxDeclid',
            'sxXml',
            'declrespid',
            'nbemp'
        ]
    },
    {
        title: 'viewgen',
        formControls: []
    }];
