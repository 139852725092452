import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {ComboboxItem, FormKeys, ModalComponent} from '@app/sam-base/models';
import {Attachment, CommunicationType, EmailRequest, MailConfigResponse} from '@core/mail/mail.models';
import {MailService} from '@core/mail/mail.service';
import {ToastService} from '@sam-base/core/toast';

import {
    EmailContact,
    MailComposerData,
    MailComposerFormModel
} from 'src/app/modules/sam-main/placement/components/mail-composer/mail-composer-models';

@Component({
    templateUrl: './mail-composer.component.html',
    styleUrls: ['./mail-composer.component.scss'],
    standalone: false
})
export class MailComposerComponent extends BaseFormComponent<MailComposerFormModel> implements OnInit, ModalComponent<void, MailComposerData> {


    public config?: MailConfigResponse;
    public destinations: EmailContact[] = [];
    public destinationsCC: EmailContact[] = [];
    public replyToOptions: ComboboxItem<EmailContact>[] = [];
    public body?: string = '';
    public attachments: Attachment[] = [];
    public isLoading = false;
    private mailComposerData?: MailComposerData;
    private _onOk?: (result: void) => void;
    private _onCancel?: (error?: any) => void;

    public constructor(private readonly _mailService: MailService, private readonly _toastService: ToastService) {
        super();
    }

    public get canSend(): boolean {
        return this.formGroup.valid;
    }

    public get communicationType(): CommunicationType | undefined {
        return this.mailComposerData?.communicationType;
    }

    public get entityIds(): string[] {
        return this.mailComposerData?.entityIds ?? [];
    }

    public get signature(): string {
        return this.getFormValue('signature') ?? ''
    }

    public get suiviAllowed(): boolean {
        return this.config?.suiviAllowed ?? false;
    }

    public registerOk(action: (result: void) => void): void {
        this._onOk = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._onCancel = action;
    }

    public ngOnInit(): void {
        if (this.communicationType) {
            this.isLoading = true;
            this._mailService.getEmailConfig(this.entityIds, this.communicationType).subscribe({
                next: config => {
                    this.isLoading = false;
                    console.log('config', config);
                    this.config = config;

                    this.applyConfigToForm(config);
                },
                error: err => {
                    this.isLoading = false;
                }
            })
        } else {
            this._toastService.warning('Missing communicationType. This should not happen.');
            this.closeForm();
        }
    }

    public setData(data: MailComposerData): Promise<void> {
        this.mailComposerData = data;
        return Promise.resolve();
    }

    public matchSourceName = (e: EmailContact) => `${e.displayName} (${e.email})`;

    /**
     * Remove option selected from multiselect component
     *
     * @param values string[]
     * @param v string
     */
    public matchSelectedEntries(values: EmailContact[], v: EmailContact): boolean {
        return (values
            .filter(e => e.email?.toLowerCase() === v.email?.toLowerCase()
                && e.displayName?.toLowerCase() === v.displayName?.toLowerCase()
                && e.entityId === v.entityId)?.length ?? 0) > 0;
    }


    public closeForm() {
        if (this._onCancel) {
            this._onCancel();
        }
    }

    public onSelectedFromListDestination($event: (EmailContact)[]) {
        this.destinations = $event;
    }

    public onSelectedFromListDestinationCC($event: (EmailContact)[]) {
        this.destinationsCC = $event;
    }

    public onSendClick() {
        const request: EmailRequest = {
            smtpConfigId: this.config?.smtpConfigId || 0,
            entityIds: this.entityIds,
            replyTo: this.getFormValue('replyTo')?.email,
            to: this.destinations,
            bcc: this.destinations,
            cc: this.destinationsCC,
            subject: this.getFormValue('subject'),
            body: this.getFormValue('body'),
            communicationType: this.communicationType!,
            htmlSignature: this.signature,
            attachments: this.attachments,
            sendSuivi: this.getFormValue('generateSuivi') ?? false
        }
        this._mailService.sendEmailRequest(request).subscribe({
            next: () => {
                this._toastService.success('email_sent');
                this.closeForm()
            },
            error: () => {
                this._toastService.error('send_email_error');
            }
        });
    }

    public setBody(body: string) {
        this.body = body;
        this.setFormValue('body', body);
    }

    public addAttachment(files: File[]) {
        if (files) {
            // todo a single call for multi file ?
            files.forEach(file => {
                this._mailService.uploadAttachment(file).subscribe(response => {
                    this.attachments.push(response);
                })
            })
        }
    }

    public removeAttachment(att: Attachment) {
        this.attachments = this.attachments.filter(a => a.resourcePath !== att.resourcePath);
        // todo delete the attachment uploaded in the storage
    }

    protected getFormControlNames(): FormKeys<MailComposerFormModel> {
        return [
            'to',
            'bcc',
            'msgJoin',
            [
                'body',
                new UntypedFormControl('', [Validators.required])],
            'generateSuivi',
            [
                'subject',
                new UntypedFormControl('', [Validators.required])],
            'signature',
            [
                'replyTo',
                new UntypedFormControl('', [Validators.required])],
            'signaturePreview',
            'dateRappel'];
    }

    private applyConfigToForm(config: MailConfigResponse) {
        this.replyToOptions.length = 0;
        this.replyToOptions = config.replyTo?.map((item: EmailContact) => ({
            name: `${item.displayName} (${item.email})`,
            value: item
        }));
        console.log('config', config);
        this.body = config.body || '';
        this.destinations = config.bcc?.filter(bcc => bcc.primary);
        this.attachments = config.attachments || [];
        const form: MailComposerFormModel = {
            replyTo: this.replyToOptions[0].value,
            signature: config.signature,
            subject: config.subject
        };
        this.fillFormData(form);
    }
}
