import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {RestEntityClient, RestQueryParam, TabFormControl} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {FormKeys} from '@app/sam-base/models';
import {
    sasx5institutionTabsFormControls
} from '@modules/sam-main/admin/components/swissdec/institution/sasx5institution-tabs-formcontrols';
import {DialogResponse} from '@sam-base/models/admin/dialog-response.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {Subject} from 'rxjs';

@Component({
    templateUrl: './swissdec-institution.component.html',
    standalone: false
})
export class SwissdecInstitutionComponent extends BaseTabFormComponent<Sasx5institution> implements OnInit, OnDestroy {

    public getDeclarationStatusDisabled = false;
    public closeDisabled = false;
    public isUpdate = false;
    public delete = false;
    public isLoading = false;
    public institutions: Sasx5institution[] = [];
    public dialogResponse: DialogResponse | undefined;
    public query: RestQueryParam<Sasx5institution, any>[] = [];
    public tabValues?: TabFormControl<Sasx5institution>[];
    private subscriptions = new Subject();
    private readonly _sasx5institutionRest: RestEntityClient<Sasx5institution>;
    private _institution: Sasx5institution = {};

    constructor(store: IwStoreService, private readonly _restService: RestApiService,
                private readonly refreshFormMessageService: MessageFormRefreshService) {
        super(store);
        this._sasx5institutionRest = _restService.getEntityClient(Sasx5institution);
        this.tabValues = getDeepCopy(sasx5institutionTabsFormControls);
    }

    public get xml() {
        return this.getFormValue('xmlReceived');
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public get id() {
        return this.getFormValue('id') || '';
    }

    public get isFormFilled(): boolean {
        const body = this.createPayload();
        // Todo better handle this
        return true;
    }

    public get processFlow(): SxProcessFlow {
        return this.getFormValue('processFlow');
    }

    public get domain(): string {
        return this.getFormValue('domain');
    }

    public get xmlReceived(): boolean {
        return this.getFormValue('xmlReceived');
    }

    public ngOnInit() {
        const institution: Sasx5institution | undefined = this.getData('row');
        if (institution) {
            this.isUpdate = true;
            this._institution = institution;
            this.fillFormData(this._institution);
            // this.setFormValue('id', institution.id);
            // this._sasx5institutionRest.getById(institution.id || '').subscribe(inst => {
            //   this._institution = inst;
            //   this.fillFormData(this._institution);
            // });
        }

        this.subscribeValueChange<string>('id', e => {
            this.showHideTabs();
        });
        this.showHideTabs();
    }

    public ngOnDestroy() {
        this.refreshFormMessageService.sendMessage('sasx5dec.refresh.institutions');
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getTabsValues(): TabFormControl<Sasx5institution>[] {
        if (!this.tabValues) {
            return sasx5institutionTabsFormControls;
        }
        return this.tabValues;
    }

    public onInstitutionUpdated($event: Sasx5institution) {
        this.fillFormData($event);
        this.refreshFormMessageService.sendMessage('institution.refresh');
    }

    protected getFormControlNames(): FormKeys<Sasx5institution> {
        return [
            'id',
            'domain',
            'cantonImp',
            'state',
            'xmlReceived',
            'gestion',
            'institutionCode',
            'processed',
            'userMessage',
            'completionUrl',
            'login',
            'password',
            'declarationId',
            'assNocli',
            'assNocai',
            'assNocta',
            'processFlow',
            'notifications',
            'expectedAvailability',
            'resultJson',
            'dialogJson'];
    }

    protected getValidationType() {
        return Sasx5institution;
    }

    private setEntityId(v: string) {
        if (v !== this.id) {
            this.setFormValue('id', v);
        }
    }

    private createDeclarationFromData(decData: Sasx5institution) {
        const body: Sasx5institution = new Sasx5institution();

        body.id = decData.id;
        body.domain = decData.domain;
        body.cantonImp = decData.cantonImp;
        body.state = decData.state;
        body.xmlReceived = decData.xmlReceived;
        body.gestion = decData.gestion;
        body.institutionCode = decData.institutionCode;
        body.processed = decData.processed;
        // body.userMessage = decData.userMessage;
        body.completionUrl = decData.completionUrl;
        body.login = decData.login;
        body.password = decData.password;
        body.declarationId = decData.declarationId;

        return body;
    }

    private createPayload() {
        const body: Sasx5institution = new Sasx5institution();

        body.id = this.getFormValue('id');
        body.xmlReceived = this.getFormValue('xmlReceived');

        return body;
    }

    private showHideTabs() {
        const controls = this.tabValues ?? getDeepCopy(sasx5institutionTabsFormControls);
        controls.forEach((control: TabFormControl<Sasx5institution>) => {
            if (control.title === 'dialog') {
                control.show = [
                    SxProcessFlow.DIALOG_REQUESTING,
                    SxProcessFlow.DIALOG_FILLING,
                    SxProcessFlow.DIALOG_PROCESSING]
                    .includes(this.getFormValue('processFlow')) || !!this.getFormValue('dialogJson');
            }
        });
        this.refreshTabList();
    }
}
