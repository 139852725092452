import * as AdminRoles from '@sam-base/core/auth/access-rules/administration-menu';
import * as SideMenuRoles from '@sam-base/core/auth/access-rules/side-menu';
import * as StatisticsRoles from '@sam-base/core/auth/access-rules/statistics-menu';
import {GestionModules, PLACEMENT_MODULES} from '@sam-base/core/auth/enums/gestion-modules';
import {LayoutPlacementNavigationEvent, LayoutSalaryNavigationEvent, LayoutSubNavigationEvent} from '@sam-base/layout';
import {MenuItem} from '@sam-base/models';

export const SIDE_MENU_ITEMS: MenuItem[] = [
    {
        label: 'placement',
        labelKey: 'placement',
        styleClass: 'iw-side-menu-subtitle',
        accessRoles: SideMenuRoles.placementMenuRoles(),
        modules: PLACEMENT_MODULES,
    },
    {
        icon: 'fas fa-th',
        tooltip: 'layout_menu_label_dashboard',
        label: 'layout_menu_label_dashboard',
        labelKey: 'layout_menu_label_dashboard',
        event: LayoutPlacementNavigationEvent.DASHBOARD,
        accessRoles: SideMenuRoles.dashboardRoles(),
        modules: PLACEMENT_MODULES,
    },
    {
        event: '',
        tooltip: '',
        separator: true,
        accessRoles: AdminRoles.saisirRapportheures(),
        modules: PLACEMENT_MODULES,
    },
    {
        icon: 'fas fa-pen',
        tooltip: 'releves_heures',
        label: 'releves_heures',
        labelKey: 'releves_heures',
        event: LayoutSubNavigationEvent.RAPPORTS,
        accessRoles: AdminRoles.saisirRapportheures(),
        modules: PLACEMENT_MODULES,
    },
    {
        event: '',
        tooltip: '',
        separator: true,
        accessRoles: SideMenuRoles.employeeRoles(),
        modules: PLACEMENT_MODULES,
    },
    {
        icon: 'fas fa-address-card',
        label: 'layout_menu_label_employ',
        labelKey: 'layout_menu_label_employ',
        tooltip: 'layout_menu_label_employ',
        event: LayoutPlacementNavigationEvent.EMPLOY,
        modules: PLACEMENT_MODULES,
        accessRoles: SideMenuRoles.employeeRoles(),
    },
    {
        label: 'acomptes',
        labelKey: 'acomptes',
        tooltip: 'acomptes',
        icon: 'fas fa-hand-holding-usd',
        event: LayoutSubNavigationEvent.ACOMPTES,
        accessRoles: AdminRoles.acomptesRoles(),
        modules: PLACEMENT_MODULES,
        id: 'acomptes'
    },
    {
        event: '',
        tooltip: '',
        separator: true,
        modules: PLACEMENT_MODULES,
        accessRoles: SideMenuRoles.clientRoles(),
    },
    {
        icon: 'fas fa-user-tie',
        label: 'layout_menu_label_client',
        labelKey: 'layout_menu_label_client',
        tooltip: 'layout_menu_label_client',
        event: LayoutPlacementNavigationEvent.CLIENT,
        modules: PLACEMENT_MODULES,
        accessRoles: SideMenuRoles.clientRoles(),
    },
    {
        event: '',
        tooltip: '',
        separator: true,
        modules: PLACEMENT_MODULES,
        accessRoles: SideMenuRoles.missionRoles(),
    },
    {
        icon: 'fas fa-road',
        label: 'layout_menu_label_assignment',
        labelKey: 'layout_menu_label_assignment',
        tooltip: 'layout_menu_label_assignment',
        event: LayoutPlacementNavigationEvent.ASSIGNMENTS,
        modules: PLACEMENT_MODULES,
        accessRoles: SideMenuRoles.missionRoles(),
    },
    {
        label: 'rapports_saisis',
        labelKey: 'rapports_saisis',
        tooltip: 'rapports_saisis',
        icon: 'fas fa-user-clock',
        event: LayoutSubNavigationEvent.RAPPORTS_SAISIS,
        modules: PLACEMENT_MODULES,
        accessRoles: AdminRoles.rapportsSaisisRoles(),
    },
    {
        label: 'indemnites',
        labelKey: 'indemnites',
        tooltip: 'indemnites',
        icon: 'fas fa-hand-holding-medical',
        event: LayoutSubNavigationEvent.INDEMNITES,
        modules: PLACEMENT_MODULES,
        accessRoles: AdminRoles.indemnitesRoles(),
        id: 'indemnites'
    },
    {
        event: '',
        tooltip: '',
        separator: true,
        modules: PLACEMENT_MODULES,
        accessRoles: SideMenuRoles.placementfixeRoles(),
    },
    {
        label: 'salaires',
        labelKey: 'salaires',
        styleClass: 'iw-side-menu-subtitle',
        accessRoles: SideMenuRoles.salaireMenuRoles(),
    },
    {
        icon: 'fas fa-address-card',
        label: 'layout_menu_label_salaries',
        labelKey: 'layout_menu_label_salaries',
        tooltip: 'layout_menu_label_salaries',
        event: LayoutSalaryNavigationEvent.EMPLOYEE,
        accessRoles: SideMenuRoles.salariesRoles(),
    },

    {
        icon: 'fas fa-dollar-sign',
        label: 'ficheSalaire',
        labelKey: 'ficheSalaire',
        tooltip: 'ficheSalaire',
        event: LayoutSalaryNavigationEvent.FICHE_SALAIRE,
        accessRoles: SideMenuRoles.salaireMenuRoles(),
    },
    {
        icon: 'fas fa-wallet',
        label: 'salary_paiement',
        labelKey: 'salary_paiement',
        tooltip: 'salary_paiement',
        event: LayoutSalaryNavigationEvent.PAIEMENT,
        accessRoles: SideMenuRoles.paiementsMenuRoles(),
    },
    {
        event: '',
        tooltip: '',
        separator: true,
        accessRoles: SideMenuRoles.dashboardRoles(),
    },
    {
        label: 'factures',
        labelKey: 'factures',
        styleClass: 'iw-side-menu-subtitle',
        accessRoles: StatisticsRoles.statEtatRecouvrement(),
        modules: [GestionModules.INVOICE],
    },
    {
        icon: 'fas fa-person-circle-exclamation',
        label: 'layout_menu_label_debiteur',
        labelKey: 'layout_menu_label_debiteur',
        tooltip: 'layout_menu_label_debiteur',
        event: LayoutSalaryNavigationEvent.CLIENT,
        accessRoles: SideMenuRoles.debitorMenuRoles(),
        modules: [GestionModules.INVOICE],
    },
    {
        icon: 'fas fa-money-bill-transfer',
        label: 'factureNC',
        labelKey: 'factureNC',
        tooltip: 'factureNC',
        event: LayoutSalaryNavigationEvent.FACTURE,
        accessRoles: SideMenuRoles.factureMenuRoles(),
        modules: [GestionModules.INVOICE],
    },
    {
        icon: 'fas fa-cash-register',
        label: 'encaissement',
        labelKey: 'encaissement',
        tooltip: 'encaissement',
        event: LayoutSalaryNavigationEvent.ENCAISSEMENT,
        accessRoles: SideMenuRoles.eancaissementsMenuRoles(),
        modules: [GestionModules.INVOICE],
    }]