<form [formGroup]="formGroup"
      class="row spaceForm">

    <div class="form-host row col-xs-6">
        <iw-enum-dropdown #cboPolitesse
                          [inputEnum]="cpolitesse"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'formule' | translate"
                          class="iw-input col-xs-12 col-no-padding"
                          formControlName="cpolitesse"></iw-enum-dropdown>

        <iw-textfield #txtNom
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'nom' | translate"
                      class="iw-input col-xs-12 col-no-padding"
                      formControlName="nom"
                      id="txt-nom"></iw-textfield>

        <iw-textfield #txtPrenom
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'prenom' | translate"
                      class="iw-input col-xs-12 col-no-padding"
                      formControlName="prenom"
                      id="txt-prenom"></iw-textfield>

        <iw-textfield #txtAdresse1
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'adresse' | translate"
                      class="iw-input col-xs-12 col-no-padding"
                      formControlName="adresse1"
                      id="txt-adressse"></iw-textfield>

        <iw-textfield #txtAdresse2
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="' '"
                      class="iw-input col-xs-12 col-no-padding"
                      formControlName="adresse2"></iw-textfield>

        <iw-pays-dropdown #cboPays
                          [isDisabled]="isReadonly"
                          [labelAlign]="'left'"
                          [label]="'pays' | translate"
                          [width]="'100%'"
                          class="iw-input col-xs-12 col-no-padding"
                          formControlName="pays"
                          id="cbo-pays"></iw-pays-dropdown>

        <div class="iw-label col-xs-3 flex-center col-no-padding">{{ 'npaLieu' | translate }}</div>
        <iw-npa-lieu [formGroup]="formGroup"
                     [isDisabled]="isReadonly || !pays"
                     [labelNpa]="''"
                     [lieuFormControlName]="'lieu'"
                     [npaFormControlName]="'npa'"
                     [pays]="pays"
                     class="iw-input col-xs-9 col-no-padding"></iw-npa-lieu>


        <iw-button #btnCopyAdr2Clipboard
                   (press)="addToClipboard('adresse')"
                   [isDisabled]="!isReadonly"
                   [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1"
                   iconClass="fas fa-copy"></iw-button>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-textfield #txtTel1
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'telephones' | translate"
                      class="iw-input col-xs-11 col-no-padding"
                      formControlName="tel1"></iw-textfield>

        <iw-textfield #txtTel2
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="' '"
                      class="iw-input col-xs-11 col-no-padding"
                      formControlName="tel2"></iw-textfield>

        <iw-phone-textfield #txtNatel
                            [isDisabled]="isReadonly"
                            [labelAlign]="'left'"
                            [label]="'natel' | translate"
                            class="iw-input col-xs-9 col-no-padding"
                            formControlName="natel"
                            id="txt-natel"></iw-phone-textfield>

        <iw-checkbox #chkAllowSms
                     [isDisabled]="isReadonly"
                     [label]="'sms' | translate"
                     class="iw-input col-xs-1 col-no-padding"
                     formControlName="allowsms"></iw-checkbox>

        <iw-textfield #txtUrgence
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'urgence' | translate"
                      class="iw-input col-xs-11 col-no-padding"
                      formControlName="urgence"></iw-textfield>

        <iw-email-textfield #txtemail
                            [isDisabled]="isReadonly"
                            [labelAlign]="'left'"
                            [label]="'eMail' | translate"
                            class="iw-input col-xs-10 col-no-padding"
                            formControlName="email"
                            id="txt-email"></iw-email-textfield>

        <iw-button #btnCopyAdr2Clipboard
                   (press)="addToClipboard('email')"
                   [isDisabled]="!isReadonly"
                   [tooltip]="'copierDans' | translate"
                   class="iw-button"
                   iconClass="fas fa-copy"></iw-button>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-textfield #txtNoavs13
                      (controlFocus)="!$event ? checkAVSNumber() : ''"
                      (valueChange)="checkAVSNumber()"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'noAvsAncienNouveau' | translate"
                      [mask]="avsMask"
                      class="iw-input col-xs-4 col-no-padding"
                      formControlName="noavs13"></iw-textfield>


        <iw-button #btnCopyNoAVS2ClipBoard
                   (press)="addToClipboard('noavs13')"
                   [isDisabled]="!isReadonly"
                   [tooltip]="'copierDans' | translate"
                   class="iw-button col-xs-1 without-label col-no-padding"
                   iconClass="fas fa-copy"></iw-button>

        <iw-date-picker #txtDatenaiss
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'dateNaissance' | translate"
                        class="iw-input col-xs-4 col-no-padding"
                        formControlName="datenaiss"
                        id="txt-datenaiss"></iw-date-picker>

        <iw-textfield #txtAge
                      [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'age' | translate"
                      [value]="ageFromDateOfBirthday()"
                      class="iw-input col-xs-2 col-no-padding"></iw-textfield>
    </div>

    <div class="row col-xs-6 separator-left">
        <iw-agency-combo-box #cboAge_id
                             [isDisabled]="true"
                             [labelAlign]="'top'"
                             [label]="'agence' | translate"
                             class="iw-input col-xs-6 padding-left-0"
                             formControlName="ageId"></iw-agency-combo-box>

        <iw-lookups-dropdown #cboEmpInType
                             [isDisabled]="isReadonly"
                             [labelAlign]="'top'"
                             [label]="'mode' | translate"
                             [lkname]="'EMPINTYPE'"
                             class="iw-input col-xs-6 padding-right-0"
                             formControlName="empintype"></iw-lookups-dropdown>

        <iw-date-picker #txtDateDossie
                        [isDisabled]="!isNew"
                        [labelAlign]="'top'"
                        [label]="'dateDossier' | translate"
                        class="iw-input col-xs-4 col-no-padding"
                        formControlName="datedossie"></iw-date-picker>

        <iw-conseiller-dropdown #cboCon_id
                                [isDisabled]="isReadonly"
                                [labelAlign]="'top'"
                                [label]="'conseiller' | translate"
                                class="iw-input col-xs-8 col-no-padding"
                                formControlName="conId"
                                id="cbo-conid"></iw-conseiller-dropdown>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-enum-dropdown #cboTypejob
                          [inputEnum]="typeEmploi"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'typeDemploi' | translate"
                          class="iw-input col-xs-12 col-no-padding"
                          formControlName="typejob"></iw-enum-dropdown>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-date-picker #txtDateccadre
                        [isDisabled]="isReadonly"
                        [labelAlign]="'left'"
                        [label]="'contratCadre' | translate"
                        class="iw-input col-xs-12 col-no-padding"
                        formControlName="dateccadre"></iw-date-picker>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-date-picker #txtFirst_mis
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'1EreMission' | translate"
                        class="iw-input col-xs-6 no-padding-left"
                        formControlName="firstMis"></iw-date-picker>

        <iw-date-picker #txtLast_Mis
                        [isDisabled]="isReadonly"
                        [labelAlign]="'top'"
                        [label]="'derniereMission' | translate"
                        class="iw-input col-xs-6 no-padding-right"
                        formControlName="lastMis"></iw-date-picker>

        <iw-textfield #txtMis_id
                      [isDisabled]="isReadonly"
                      class="iw-input col-xs-4 col-no-padding"
                      formControlName="misId"></iw-textfield>

        <iw-textfield #txtOpeMisCli
                      [isDisabled]="isReadonly"
                      [(value)]="clientNom"
                      class="iw-input col-xs-8 col-no-padding"></iw-textfield>

        <div class="col-xs-12 separator-top no-padding"></div>

        <iw-button #btnFixe
                   (press)="openPlacefixGrid()"
                   [isDisabled]="!isReadonly"
                   [text]="'fixe' | translate"
                   class="iw-button col-xs-2 without-label col-no-padding"></iw-button>

        <iw-date-picker #txtfirst_act
                        [isDisabled]="true"
                        [labelAlign]="'top'"
                        [label]="'1EreAction' | translate"
                        class="iw-input col-xs-5 col-no-padding"
                        formControlName="firstAct"></iw-date-picker>

        <iw-date-picker #txtlast_act
                        [isDisabled]="true"
                        [labelAlign]="'top'"
                        [label]="'derniereAction' | translate"
                        class="iw-input col-xs-5 col-no-padding"
                        formControlName="lastAct"></iw-date-picker>

        <iw-textfield #txtCdf_id
                      (ngModelChange)="cdfIdChangeCheck()"
                      [isDisabled]="true"
                      class="iw-input col-xs-2 col-no-padding"
                      formControlName="cdfId"></iw-textfield>

        <iw-textfield #txtLastCdfCli
                      [isDisabled]="true"
                      [value]="cliNom"
                      class="iw-input col-xs-10 col-no-padding"></iw-textfield>
    </div>

</form>
