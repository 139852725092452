import {Component, OnDestroy, OnInit} from '@angular/core';
import {SalaryForm} from '@app/modules/sam-main/salary/salary.forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {EntityCreate, FormHandlerService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import * as entityActions from '@app/sam-base/core/store/actions/entity.actions';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Saempded} from '@app/sam-base/models/salary/saempded';
import {ParametersService} from '@core/services/parameters.service';
import {Actions, ofType} from '@ngrx/effects';


import {Subject, takeUntil} from 'rxjs';
import {DeductionCreate, DeductionType} from './deduction-create.model';


@Component({
    templateUrl: './deduction-create.component.html',
    standalone: false
})
export class DeductionCreateComponent extends BaseFormComponent<DeductionCreate> implements OnInit, OnDestroy {

    public ded?: string;
    public deductionType = DeductionType;
    private readonly _restSaempdedClient: RestEntityClient<Saempded>;
    private subscriptions = new Subject();

    constructor(private readonly _store: IwStoreService, private readonly _restService: RestApiService, private readonly _formHandler: FormHandlerService<SalaryForm>, private readonly _actions: Actions, private readonly _parametersService: ParametersService) {
        super();
        this._restSaempdedClient = this._restService.getEntityClient(Saempded);
    }

    public get empId(): string {
        return this.getData('empId') || '';
    }

    public get parentUUID(): string {
        return this.getData('parentUUID') || '';
    }

    public ngOnInit(): void {
        this.subscribeToSaveEffect();
    }

    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public openCreationForm() {
        const updatedData = this._restSaempdedClient
            .construct({empId: this.empId, dedCode: this.ded, soumiscode: 1});
        this._store.dispatch(new EntityCreate(updatedData));
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    protected getFormControlNames(): FormKeys<DeductionCreate> {
        return [];
    }

    private subscribeToSaveEffect() {
        this._actions.pipe(takeUntil(this.subscriptions), ofType(entityActions.ENTITY_CREATE_SUCCESS))
            .subscribe((e: any) => {
                if (e.entity.$entity === 'saempded') {
                    this._parametersService.getCurrentSapar()
                        .subscribe((sapar) => {
                            this._formHandler.showFormDialog(SalaryForm.EmployeeDeductionInfo, {
                                empId: this.empId,
                                edId: e.entity.edId,
                                dedCode: e.entity.dedCode,
                                annee: sapar.annee,
                                soumis: e.entity.soumiscodetext,
                                parentUUID: this.parentUUID,
                                readOnly: false
                            });
                            this._store.dispatch(new actions.DestroyForm(this.uuid));
                        });
                }
            });
    }

}
