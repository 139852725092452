import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {
    Sasx4decSideMenuComponent
} from '@modules/sam-main/admin/components/swissdec4/side-menu/sasx4dec-side-menu.component';
import {Swissdec4Component} from '@modules/sam-main/admin/components/swissdec4/swissdec4.component';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';

export const SASX4DEC_DECLARATION: FormDescription<Sasx4dec> = {
    form: Swissdec4Component,
    sideMenu: Sasx4decSideMenuComponent,
    state: {
        ...buildDefaultState(Sasx4dec),
        diagTitle: 'sasx5dec_form_title',
        diagShowSideMenu: true,
        diagShowStatus: true,
        diagSideMenu: {
            showCancel: true,
            showClose: true,
            showEdit: false,
            showList: false,
            showPrint: false,
            showDelete: false
        },
        editMode: 'read',
        useNavigation: false,
        readonly: false,
        patch: false,
        width: 1600
    }
};

