import {Component, Input} from '@angular/core';
import {DocumentEntityRef} from '@app/sam-base/models/placement/document-entity-ref';

@Component({
    selector: 'iw-tab-ppmis-docs', templateUrl: 'tab-ppmis-docs.component.html',
    standalone: false
})
export class TabPpmisDocsComponent {
    @Input() public isReadonly = true;

    public entityRef: DocumentEntityRef = DocumentEntityRef.Mission;

    @Input()
    public set misId(v: string) {
        this._misId = v;
    }

    public get misId() {
        return this._misId;
    }

    private _misId = '';

    constructor() {
    }
}
