import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwEventHubService} from '@app/sam-base/core';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {FormKeys} from '@app/sam-base/models/form-keys.model';
import {GepaihdrCalc} from '@app/sam-base/models/invoices/gepaihdrCalc';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';


@Component({
    templateUrl: './encaissement-calculatebtn-form.component.html',
    standalone: false
})
export class EncaissementCaculateBtnFormComponent extends BaseStoreFormComponent<GepaihdrCalc> implements OnInit {

    constructor(protected readonly _store: IwStoreService, private _encaissementService: EncaissementService,
                private _eventHub: IwEventHubService<EncaissementEvents>) {
        super(_store);
    }

    public get payId(): string {
        return this.getData('payId') || '';
    }

    public get cliId(): string {
        return this.getData('cliId') || '';
    }

    public ngOnInit(): void {
        // true by default
        this.setFormValue('plRecalcPaicli', true);
    }

    public calculate() {
        if (!this.payId) {
            return;
        }

        const plRecalcPaicli = this.getFormValue('plRecalcPaicli');
        const plDelZeroPaicli = this.getFormValue('plDelZeroPaicli');

        const data: GepaihdrCalc = {
            payId: this.payId,
            cliId: this.cliId,
            plDelZeroPaicli,
            plRecalcPaicli
        };
        this._encaissementService
            .calculate(data)
            .subscribe(() => {
                this._eventHub.emit(EncaissementEvents.reloadPaiement);
                this.close();
            });
    }

    public close() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    public isCalculerBtnEnable() {
        return !!this.getFormValue('plRecalcPaicli');
    }


    protected getFormControlNames(): FormKeys<GepaihdrCalc> {
        return [
            'plDelZeroPaicli',
            'plRecalcPaicli'];
    }
}
