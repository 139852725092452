import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {SxModeLite} from '@sam-base/models/admin/sx-mode.enum';
import {Subject} from 'rxjs';

/**
 * Tab General of SASX5DEC
 */
@Component({
    selector: 'iw-tab-sasx4dec-general',
    templateUrl: './tab-sasx4dec-general.component.html',
    standalone: false
})
export class TabSasx4decGeneralComponent extends BasePartialFormComponent<Sasx4dec> implements OnInit, OnDestroy {
    public readonly optionsSxModeLite = SxModeLite;
    @Input() public isNew = false;
    protected readonly SamUserRole = SamUserRole;
    private subscriptions = new Subject();

    constructor() {
        super();
    }

    public get entityId() {
        const id = this.getFormValue('sxId');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public ngOnInit() {
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

}
