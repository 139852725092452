import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwTableComponent, SortInfo} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwStoreService, ModalService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SadeddaService} from '@app/sam-base/core/services/sadedda.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {Sadedda} from '@app/sam-base/models/salary/sadedda';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';

@Component({
    templateUrl: './sadedda.component.html',
    standalone: false
})
export class SadeddaComponent extends BaseStoreFormComponent<Sadedda> implements OnInit {

    @ViewChild('sadeddaTable') public sadeddaTable?: IwTableComponent<Sadedda>;
    public sortProp?: keyof Sadedda = 'txCode';
    public sortPropDir?: 'asc' | 'desc' = 'asc';
    public type: Type<Sadedda> = Sadedda;
    public query: RestQueryParam<Sadedda, string>[] = [];
    public sapar?: Sapar;
    public formState: FormState | undefined;
    public selectedRow?: Sadedda;
    public readonly = true;
    public canModify = false;
    public newEntryMode = false;
    public isSaving = false;
    public soumis = false;
    public filterH = false;
    public filterF = false;
    public soumisOptions: ComboboxItem<number>[] = [{name: ' ', value: 0}, {
        name: this._translate.instant('nonSoumis'), value: 1
    }, {name: this._translate.instant('soumis'), value: 2}, {name: this._translate.instant('soumisPRV'), value: 3}];
    private _baseUrl = ' ';
    private readonly _restSadeddaClient: RestEntityClient<Sadedda>;

    constructor(store: IwStoreService, rest: RestApiService, private _parametersService: ParametersService, private _translate: TranslateService, private readonly _modalService: ModalService, private readonly sadeddaService: SadeddaService) {
        super(store);
        this._baseUrl = environment.backendURL;
        this._restSadeddaClient = rest.getEntityClient(Sadedda);
    }

    public get dedCode(): string {
        return this.getData('dedCode') || '';
    }

    public get annee(): string {
        return this.getData('annee') || '';
    }

    public get tauxauto() {
        return this.getData('tauxauto') || '';
    }

    public get enableAge(): boolean {
        return !this.readonly && (this.tauxauto === 2 || this.tauxauto === 3);
    }

    public get enableCat(): boolean {
        return (!this.readonly && (this.tauxauto === 7 || this.tauxauto === 8 || this.tauxauto === 9));
    }

    public get enableSoumisDrop(): boolean {
        return !this.readonly && (this.getFormValue('soumis') as number) > 0;
    }

    public get showFilters(): boolean {
        return this.readonly;
    }

    // eslint-disable-next-line complexity
    public get tauxautoLabel() {
        switch (this.tauxauto) {
            case 2: {
                return this._translate.instant('sexeAge');
            }
            case 3: {
                return this._translate.instant('sexeAgeRevolu');
            }
            case 4: {
                return this._translate.instant('cantonTravail');
            }
            case 5: {
                return this._translate.instant('cantonHabitation');
            }
            case 6: {
                return this._translate.instant('cantonIS');
            }
            case 7: {
                return this._translate.instant('cat1');
            }
            case 8: {
                return this._translate.instant('cat2');
            }
            case 9: {
                return this._translate.instant('cat3');
            }
        }
    }

    public ngOnInit() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => (this.sapar = sapar));
        this.setQuery();
    }

    public setSoumis(event: boolean) {
        this.soumis = event;
    }

    public setFilterH(event: boolean) {
        this.filterH = event;
        this.filterF = false;
        this.setQuery();
    }

    public setFilterF(event: boolean) {
        this.filterF = event;
        this.filterH = false;
        this.setQuery();
    }

    public getSelectedRow(event: Sadedda[]) {
        this.readonly = true;
        if (this.isSaving) {
            this.isSaving = false;
            return;
        }
        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }
        this.selectedRow = event[0];
        this.fillFormData(event[0]);
    }

    public createNewEntry() {
        this.selectedRow = {};
        this.readonly = false;
        this.newEntryMode = true;
        this.clearForm();
    }

    public modifyEntry(): void {
        if (this.selectedRow) {
            this.readonly = false;
            this.newEntryMode = false;
        }
    }

    public saveEntry(): void {
        this.setFormValues();
        const formData = this.getFormData();
        const updatedData = this._restSadeddaClient.construct({
            ...this.selectedRow, ...formData
        });

        this.readonly = true;
        this.isSaving = true;

        if (this.newEntryMode) {
            this.sadeddaService.createNewEntry(updatedData)
                .subscribe(() => {
                    this.sadeddaTable?.refresh();
                });
            this.newEntryMode = false;
        } else {
            this.sadeddaService
                .saveByAnneeAndDedCodeAndTxCode(this.getFormValue('annee'), this.getFormValue('dedCode'), this.getFormValue('txCode'), updatedData)
                .subscribe(() => {
                    this.sadeddaTable?.refresh();
                });
        }
        this.setSortByModif();
    }

    public async removeEntry() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('supprimerEnregisrement')],
            showCancel: true,
            title: this._translate.instant('sadedda_form_title'),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            if (this.selectedRow && this.selectedRow.txCode) {
                this.sadeddaService
                    .deleteByAnneeAndDedCodeAndTxCode(this.getFormValue('annee'), this.getFormValue('dedCode'), this.getFormValue('txCode'))
                    .subscribe(() => {
                        this.sadeddaTable?.refresh();
                    });
            }
        } catch (error) {
        }
    }

    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.readonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Sadedda>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getFormControlNames(): FormKeys<Sadedda> {
        return ['annee', 'dedCode', ['txCode', new UntypedFormControl(undefined, Validators.required)], 'imode', 'sexe', 'ageDe', 'ageA', 'canton', 'cid', 'soumis', 'soumiscode'];
    }

    protected getValidationType() {
        return Sadedda;
    }

    protected validateFields(e: Sadedda): boolean {
        return super.validateFields(e);
    }

    private setSortByModif() {
        this.sortProp = 'txCode';
        this.sortPropDir = 'desc';
    }

    private setFormValues() {
        this.setFormValue('annee', this.annee);
        this.setFormValue('dedCode', this.dedCode);
        if (this.getFormValue('soumis')) {
            this.setFormValue('soumis', 1);
        } else {
            this.setFormValue('soumis', 0);
        }
    }

    private setQuery() {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'annee', value: this.annee
        }, {
            operation: RestQueryOperation.Equals, prop: 'dedCode', value: this.dedCode
        }];
        if (this.filterF) {
            this.query.push({
                operation: RestQueryOperation.Equals, prop: 'sexe', value: 'F'
            });
        }
        if (this.filterH) {
            this.query.push({
                operation: RestQueryOperation.Equals, prop: 'sexe', value: 'H'
            });
        }
    }
}
