import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {
    ContextMenuEvent,
    EntityNavigationService,
    FormHandlerService,
    IwEventHubService,
    IwStoreService,
    ModalService,
    RestApiService,
    RestEntityClient
} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, IwGridColumn, MenuItem, RowClickEvent, sortGridList, TableSortEvent} from '@app/sam-base/models';
import {
    Ppemp,
    Ppoptsal,
    Ppper,
    Salary,
    SalaryGenerateRequest,
    SalaryInitRequest,
    SalaryProcess,
    SalaryProcessDetails,
    SalarySalbufStatus,
    SalaryTimeMode,
    SalBuffer,
    SalBufferMenuEvent
} from '@app/sam-base/models/placement';
import {PeriodeService} from '@modules/sam-main/placement/services/periode.service';
import {SalaryProcessService} from '@modules/sam-main/placement/services/salary-process.service';
import {TranslateService} from '@ngx-translate/core';
import {IwContextMenuComponent} from '@sam-base/components';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Saemsview} from '@sam-base/models/salary/saemsview';
import {ProfileService} from '@shared/profile/profile.service';
import {
    ModalSalaryCountComponent
} from '@shared/widgets/modal-components/modal-salary-count/modal-salary-count.component';
import {ModalSalaryCountOptions} from '@shared/widgets/modal-components/modal-salary-count/modal-salary-count.options';
import {
    ModalSalarySessionComponent
} from '@shared/widgets/modal-components/modal-salary-session/modal-salary-session.component';
import {
    ModalSalarySessionOptions
} from '@shared/widgets/modal-components/modal-salary-session/modal-salary-session.options';
import {toLower} from 'lodash';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {PlacementForm} from '../../placement.forms';
import {ModeLpp} from './salary.model';

function salBufferStatusColorMapper(row: SalBuffer): string {
    // if (row.) {
    //     return "#008000";
    // }
    if (row.error) {
        return '#a00101'
    }
    if (row.status === SalarySalbufStatus.GENERATED) {
        return '#0189bf';
    }
    if (row.status === SalarySalbufStatus.CALCULATED) {
        return '#10a503';
    }

    return "#C0C0C0";
}


@Component({
    templateUrl: './salary.component.html',
    standalone: false
})
export class SalaryComponent extends BaseStoreFormComponent<Salary> implements OnInit, OnDestroy {


    public canEditSession = false;
    public salaryProcessId: number | undefined;
    public lppmode = '';
    public txtWarning = this._translate.instant('salary_txt_warning');
    public withCct = 'CCT: ';
    public lforceTxt = this._translate.instant('normal');
    public columnsHourly = this.getColumnsHourly();
    public employes: Salary[] = [];
    public originalSalariesBuffer: SalBuffer[] = [];
    public salariesBuffer: SalBuffer[] = [];
    public nbSalBuf = 0;
    public nbSalBufSelected = 0;
    public nbsalBufTxt = '';
    public selectedSal: SalBuffer[] = [];
    public isEmpFilter = false;
    public subscription?: Subscription;
    public salaryTimeModeEnum = SalaryTimeMode;
    public salaryTimeMode = SalaryTimeMode.HOURS;
    // Default background color of textfield periode
    public backgroundPerColor = 'per-color-2';
    public backgroundForceColor = '';
    public periode?: Ppper;
    public salaryProcesses: SalaryProcess[] = [];
    // }
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public contextMenuData?: ContextMenuEvent<SalBuffer>;
    //         });
    public contextMenuItems: MenuItem[] = this.buildContextMenu()
    public salBufferClicked?: SalBuffer;
    private readonly _restClient: RestEntityClient<Ppoptsal>;
    private userId?: string;
    private tableSortEvent?: TableSortEvent<SalBuffer>;
    private subscriptions = new Subject();

    private filterService = new FilterService<SalBuffer>();

    constructor(store: IwStoreService, private readonly _http: HttpClient, protected readonly _rest: RestApiService,
                private readonly _modalService: ModalService, protected readonly _translate: TranslateService,
                protected readonly _salaryProcessService: SalaryProcessService,
                private readonly _forms: FormHandlerService<PlacementForm>,
                private readonly _toastService: ToastService,
                private readonly _profileService: ProfileService,
                private _navigationService: EntityNavigationService,
                private readonly _eventService: IwEventHubService<SalBufferMenuEvent>,
                private readonly _periodeService: PeriodeService) {
        super(store);
        this._restClient = _rest.getEntityClient(Ppoptsal);
        this.setActivePeriod();
        this._eventService.forType(SalBufferMenuEvent.FICHE_SAL)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<SalBuffer>;
                payload.selected.forEach(e => {
                    let ficheSalId = e.empId + "_" + this.salId;
                    if (ficheSalId) {
                        this._navigationService.navigateToEntityForm(Saemsview, ficheSalId, undefined, 'read');
                    }
                });
            })
        this._eventService.forType(SalBufferMenuEvent.EMPLOYE)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<SalBuffer>;
                payload.selected.forEach(e => {
                    this._navigationService.navigateToEntityForm(Ppemp, e.empId, undefined, 'read');
                });
            })
    }

    public get salBufferClickedFormattedErrorMessage(): string {
        return `<ul>${this.salBufferClicked?.error?.errors.map(e => `<li>${this._translate.instant(e.code, e.params)}</li>`).join('')}</ul>`
    }

    public get clientLabel(): string {
        return `${this._translate
            .instant('salary_active')} ${this.periode?.texte}`;
    }

    public get salId(): string {
        return this.periode?.salId || '';
    }

    public get initEnabled() {
        return this.salaryTimeMode;
    }

    public get canGenerate() {
        return this.nbSalBufSelected !== 0 && this.canEditSession;
    }

    public get canCancelGeneration() {
        return this.nbSalBufSelected !== 0 && this.canEditSession;
    }


    public get canClean() {
        return this.canEditSession;
    }

    public get canCalculate() {
        return this.nbSalBufSelected !== 0 && this.canEditSession;
    }

    public get nbSalBuffSelectedCount(): string {
        return `${this.selectedSal?.length} / ${this.originalSalariesBuffer?.length}`;
    }

    public get nbSalBuffErrorCount(): string {
        return `${this.originalSalariesBuffer?.filter(s => s.error).length} / ${this.originalSalariesBuffer?.length}`;
    }


    public async onContextMenu(event: ContextMenuEvent<SalBuffer>) {
        this.contextMenuData = event;
        if (this.contextMenuData.selected[0].status == SalarySalbufStatus.GENERATED || this.contextMenuData.selected[0].status == SalarySalbufStatus.CALCULATED)
            this.contextMenuItems = this.buildContextMenu();
        else
            this.contextMenuItems = this.buildContextMenuWithoutFicheSal()
        await this.showContextMenu(event.event);
    }

    public ngOnInit() {
        this._profileService.loadProfile()
            .subscribe(userProfile => this.userId = userProfile.userId);
        this._restClient.getById('0')
            .subscribe((opt: Ppoptsal) => {
                this.lppmode = this.getlppmodeLabel(opt.lppmode || 9);
                this.withCct += opt.lcct ? 'Oui' : 'Non';
            });
        this.getSalaryProcessList(true);
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getSalaryProcessList(openForUser?: boolean) {
        this._salaryProcessService.listSalaryProcess().subscribe(processes => {
            this.salaryProcesses = processes;
            if (openForUser) {
                // find first process owned by current user, and if present load it
                let salaryProcesId = this.salaryProcesses.find(process => process.owner === this.userId)?.id;
                this.getSalaryProcess(salaryProcesId)
            }
        });
    }

    /**
     * Function to init the salaries
     */
    public onSalaryModeChange(): void {
    }

    public initSalaries(): void {
        this.openSalaryCountMod(this.salaryTimeMode, false);
    }

    /**
     * Function to generate the salaries
     */
    public async generate(): Promise<void> {
        let prepareRequest: SalaryGenerateRequest = {
            empIds: this.selectedSal.map(salBuf => salBuf.empId),
            salaryProcessId: this.salaryProcessId!
        };
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._salaryProcessService.generateSalary(prepareRequest)
            .subscribe({
                    next: (e: SalaryProcessDetails) => {
                        this.applySalaryProcessDetails(e, false);
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    },
                    error: err => {
                        this._toastService.warning('failedProccess')
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    }
                },
            );
    }

    public async cancelGeneration(): Promise<void> {
        let prepareRequest: SalaryGenerateRequest = {
            empIds: this.selectedSal.map(salBuf => salBuf.empId),
            salaryProcessId: this.salaryProcessId!
        };
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._salaryProcessService.cancelGenerationSalary(prepareRequest)
            .subscribe({
                    next: (e: SalaryProcessDetails) => {
                        this.applySalaryProcessDetails(e, false);
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    },
                    error: err => {
                        this._toastService.warning('failedProccess')
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    }
                },
            );
    }


    /**
     * Function to generate the salaries
     */
    public calculate() {
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        let calculateRequest: SalaryGenerateRequest = {
            empIds: this.selectedSal.map(salBuf => salBuf.empId),
            salaryProcessId: this.salaryProcessId!
        };
        this._salaryProcessService.calculateSalary(calculateRequest).subscribe({
            next: (e) => {
                this.applySalaryProcessDetails(e, false);
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
            },
            error: err => {
                this._toastService.warning('failedProccess')
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
            }
        });
    }


    /**
     * Function called when 'lforce' is true - when we need
     * to force the table values
     */
    public force(): void {
        // this.initSalariesForce(true);
    }

    /**
     * Function to update the selected rows
     */
    public updateSalbufSel(ev: SalBuffer[]): void {
        this.nbSalBufSelected = ev.length;
        this.selectedSal = ev;
        this.setNbSalbufSelected(this.employes);
    }

    public deleteProcess(): void {
        if (this.salaryProcessId) {
            this._salaryProcessService.delete(this.salaryProcessId!).subscribe(() => {
                this.clearSalaryProcessDetails();
                this.getSalaryProcessList(false);
            });
        }
    }

    /**
     * Function called when button 'forcer' is pressed
     * to check if force or init function is called
     */
    public pressForce() {
        this.lforceTxt = this._translate.instant('force');
        this.backgroundForceColor = 'force-color-2';
        this.isEmpFilter = true;
        this.openSalaryCountMod(this.salaryTimeMode, true);
        //This.force();
    }

    /**
     * Function to open form regarding Paramètres
     */
    public openParameters(): void {
        this._forms.showFormDialog(PlacementForm.SalaryParameters, undefined, s => ({
            ...s,
            entityId: '0'
        }));
    }

    /**
     * Close form
     */
    public async closeDialog() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('quitSalaryPrep')],
            showCancel: true,
            title: this._translate.instant('salary_form_title')
        };
        try {
            if (this.salaryProcessId && this.canEditSession) {
                await this._modalService
                    .showModal(ModalMessageComponent, options);
                this.deleteProcess();
            }
            this._store.dispatch(new actions.DestroyForm(this.uuid));
        } catch (error) {
        }
    }

    public async openSalaryProcessList() {
        const options: ModalSalarySessionOptions = {
            showCancel: false,
            title: this._translate.instant('salary_process_sessions_title'),
        };
        try {
            let sessionId = await this._modalService.showModal(ModalSalarySessionComponent, options);
            // if no client ids, then nothing to do
            if (!sessionId) {
                return;
            }
            this.getSalaryProcess(sessionId);
        } catch (error) {
        }

    }

    public openFicheSal(event: RowClickEvent<SalBuffer>) {
        let ficheSalId = event.row.empId + "_" + this.salId;
        if (ficheSalId) {
            this._navigationService.navigateToEntityForm(Saemsview, ficheSalId, undefined, 'read');
        }
    }

    onSort($event?: TableSortEvent<SalBuffer>) {
        this.tableSortEvent = $event;
        if (!$event) return;
        this.salariesBuffer = [...sortGridList(this.salariesBuffer, $event)];
    }

    public onSalBufferClicked(salBuffer: SalBuffer) {
        if (this.salBufferClicked?.id === salBuffer.id) {
            this.salBufferClicked = undefined;
        } else {
            this.salBufferClicked = salBuffer;
        }
    }

    public saveSalaryProcessAndQuit() {
        // technically, nothing to save as every action (contrary to invoice process) is saving the salbuffers
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public onApplyFilter($event: IwGridColumn<SalBuffer>) {
        this.filterService.addFilter($event);
        this.salariesBuffer = this.filterService.applyFilters(this.originalSalariesBuffer);
    }

    protected getFormControlNames(): FormKeys<Salary> {
        return [
            'salaryProcessId',
            'sessionOwner'
        ];
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }

    private async hideContextMenu() {
        if (this.menu) {
            await this.menu.hide();
        }
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(SalBufferMenuEvent).forEach((event) => {
            menu.push(
                {
                    label: toLower(event),
                    event: event,
                    contextMenuVisibleMode: 'all'
                },
            );
        });
        return menu;
    }

    private getSalaryProcess(sessionId: number | undefined) {
        if (!sessionId) return;
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._salaryProcessService.getSalaryProcessDetails(sessionId).subscribe(processDetails => {
            this.applySalaryProcessDetails(processDetails)
            this._store.dispatch(new actions.SetLoading(this.uuid, false));
        }, error => {
            this._store.dispatch(new actions.SetLoading(this.uuid, false));
        });
    }

    private async openSalaryCountMod(timeMode: SalaryTimeMode, force: boolean) {
        const options: ModalSalaryCountOptions = {
            showCancel: false,
            title: this._translate.instant('salary_form_title'),
            salaryProcessType: timeMode,
            salId: this.periode!.salId!,
            force
        };

        try {
            let empIds = await this._modalService.showModal(ModalSalaryCountComponent, options);
            // if no client ids, then nothing to do
            if (empIds.length === 0) {
                return;
            }
            console.log('empIds', empIds);
            this._store.dispatch(new actions.SetLoading(this.uuid, true));
            let initRequest: SalaryInitRequest = {
                salId: this.periode!.salId!,
                salaryProcessType: timeMode,
                empIds: empIds,
                force
            }
            this._salaryProcessService.initSalaryProcess(initRequest)
                .subscribe(async (salaryProcessDetails: SalaryProcessDetails) => {
                    console.log('salaryProcessDetails', salaryProcessDetails);
                    this.applySalaryProcessDetails(salaryProcessDetails);
                    this.salaryProcesses.push(salaryProcessDetails.salaryProcess);
                    // }
                }, async (err: HttpErrorResponse) => {
                    if (err.status === 423) {
                        this._toastService.warning('system_locked');
                    }
                    const msg = err.status === 423 ? this._translate.instant('error_call_salary') : this._translate.instant('error_init');
                    const optionsLocked: ModalMessageOptions = {
                        message: [],
                        showCancel: false,
                        title: this._translate.instant('salary_process'),
                        confirmMessage: msg,
                        alertsMessage: this._translate.instant('alertsmsg'),
                        okDisabled: false
                    };
                    try {
                        await this._modalService
                            .showModal(ModalMessageComponent, optionsLocked);
                        this._store
                            .dispatch(new actions.DestroyForm(this.uuid));
                    } catch (error) {
                    }
                    this._store
                        .dispatch(new actions.SetLoading(this.uuid, false));
                });


        } catch (error) {
        }
    }

    private applySalaryProcessDetails(salaryProcessDetail: SalaryProcessDetails, resetSelection: boolean = true) {
        if (!salaryProcessDetail?.salaryProcess) return;
        this.canEditSession = salaryProcessDetail.salaryProcess.owner === this.userId;
        this.salaryProcessId = salaryProcessDetail.salaryProcess.id;
        this.salaryTimeMode = salaryProcessDetail.salaryProcess.type;
        this.originalSalariesBuffer = salaryProcessDetail.salBuffers;
        this.salariesBuffer = salaryProcessDetail.salBuffers;
        // this.fillFormData(salaryProcessDetail);
        this.setFormValue('sessionOwner', salaryProcessDetail.salaryProcess.owner);
        if (resetSelection) {
            this.resetTableSelection();
        }
        this.onSort(this.tableSortEvent);
        this._store.dispatch(new actions.SetLoading(this.uuid, false));

    }

    private clearSalaryProcessDetails() {
        this.canEditSession = false;
        this.salaryProcessId = undefined;
        this.salaryTimeMode = SalaryTimeMode.HOURS
        this.originalSalariesBuffer = [];
        this.salariesBuffer = [];
        // this.fillFormData(salaryProcessDetail);
        this.setFormValue('sessionOwner', undefined);
        this.resetTableSelection();
    }

    /**
     * Set number of selected sal / total sal
     *
     * @param salBuf selected clients
     */
    private setNbSalbufSelected(salBuf: Salary[]): void {
        this.nbSalBuf = salBuf.length;
        this.nbsalBufTxt = this.nbSalBufSelected + ' / ' + this.nbSalBuf;
    }

    /**
     * Columns to show in salary table
     */
    private getColumnsHourly(): IwGridColumn<SalBuffer>[] {
        return [
            {
                prop: 'nom',
                name: 'nom',
                type: 'string',
                colorMapper: (row: SalBuffer) => {
                    return salBufferStatusColorMapper(row);
                },
                index: 0
            },
            {
                prop: 'nbmis',
                name: 'nbMissions',
                type: 'number',
                index: 1
            },
            {
                prop: 'nbrap',
                name: '',
                index: 2,
                type: 'number'
            },
            {
                prop: 'mntrapheu',
                name: 'mntrapheu',
                index: 3,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mntrapind',
                name: 'mntrapind',
                index: 4,
                type: 'mnt',
                align: 'right'
            },
            {
                index: 5,
                prop: 'mntaco',
                name: 'mntaco',
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'nbhlpp',
                name: 'nbhlpp',
                index: 6,
                type: 'mnt',
                align: 'right'

            },
            {
                index: 7,
                prop: 'mntind',
                name: 'mntind',
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'ageId',
                name: 'ageId',
                type: 'string',
                index: 8
            },
            {
                prop: 'secId',
                name: 'secId',
                type: 'string',
                index: 9
            },
            {
                prop: 'ageanni',
                name: 'ageanni',
                type: 'number',
                index: 10
            },
            {
                prop: 'empId',
                name: 'empId',
                type: 'string',
                index: 11
            },
            {
                prop: 'timeMode',
                type: 'enum',
                name: 'timeMode',
                index: 12
            },
            {
                prop: 'status',
                type: 'enum',
                name: 'status',
                index: 13
            },
            {
                prop: 'error',
                type: 'warning',
                name: 'error',
                index: 14
            },
        ];
    }

    private getlppmodeLabel(lppmode: number): string {
        return this._translate.instant(ModeLpp[lppmode]);
    }

    private setActivePeriod(): void {
        this._periodeService.getActivePeriod()
            .subscribe((per: Ppper) => {
                this.periode = per;
            });
    }

    private resetTableSelection() {
        this.updateSalbufSel([]);
    }

    private buildContextMenuWithoutFicheSal() {
        const menu: MenuItem[] = [];
        Object.values(SalBufferMenuEvent).forEach((event) => {
            if (event != SalBufferMenuEvent.FICHE_SAL)
                menu.push(
                    {
                        label: toLower(event),
                        event: event,
                        contextMenuVisibleMode: 'all'
                    },
                );
        });
        return menu;
    }
}
