import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {ContextMenuEvent, FormHandlerService, IwEventHubService} from '@app/sam-base/core';
import {IwGridColumn, MenuItem, RowClickEvent} from '@app/sam-base/models';
import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {IwContextMenuComponent} from '@sam-base/components';
import {ToastService} from '@sam-base/core/toast';
import {EmployeeDeclaration} from '@sam-base/models/admin/employee-declaration.model';

import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Subject, takeUntil} from 'rxjs';


export enum Sasx5InstitutionMenuEvent {
    AhvProofOfInsuranceReport = 'AhvProofOfInsuranceReport',
    IncompleteDeclarationToggle = 'IncompleteDeclarationToggle',
}


/**
 * Tab General of Swissdec Declaration
 */
@Component({
    selector: 'iw-tab-sasx5dec-institutions',
    templateUrl: './tab-sasx5dec-institutions.component.html',
    standalone: false
})
export class TabSasx5decInstitutionsComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {


    @Input() public isNew = false;
    @Input() public isReadonly = true;


    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public contextMenuData?: ContextMenuEvent<Sasx5institution>;
    public contextMenuItems: MenuItem[] = this.buildContextMenu()
    public originalInstitutions: Sasx5institution[] = [];
    public filteredInstitutions: Sasx5institution[] = [];
    public institutionGridColumns: IwGridColumn<Sasx5institution>[] = [
        {
            name: 'gestion',
            prop: 'gestion',
            type: 'string',
            hidden: true
        },
        {
            name: 'domain',
            prop: 'domain',
            type: 'string',
            colorMapper: (row: Sasx5institution) => {
                if (row.processFlow === SxProcessFlow.DONE) {
                    return '#10a503';
                }
                return "#C0C0C0";
            },
        },
        {
            name: 'canton',
            prop: 'cantonImp',
            type: 'string',
        },
        {
            name: 'status',
            prop: 'processFlow',
            type: 'enum',
            enumPrefix: 'processFlow'
        },
        {
            name: 'assNocai',
            prop: 'assNocai',
            type: 'string'
        },
        {
            name: 'reference',
            prop: 'institutionIdref',
            type: 'string'
        },
        {
            name: 'assurance',
            prop: 'assNom',
            type: 'string'
        },
        {
            name: 'included',
            prop: 'processed',
            type: 'boolean'
        },
        {
            name: 'incompleteDeclaration',
            prop: 'incompleteDeclaration',
            type: 'warning'
        },
    ];
    private _selectedInstitutions: EmployeeDeclaration[] = [];
    private filterService = new FilterService<Sasx5institution>();

    private subscriptions = new Subject();
    private _removeInstitutionProcessing = false;

    constructor(private readonly _swissdecService: SwissdecService,
                private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly _toast: ToastService,
                private readonly _http: HttpClient,
                private readonly _translate: TranslateService,
                private readonly _eventMenu: IwEventHubService<Sasx5InstitutionMenuEvent>,
                private readonly messageService: MessageFormRefreshService) {
        super();
        this._eventMenu.forType(Sasx5InstitutionMenuEvent.AhvProofOfInsuranceReport)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<EmployeeDeclaration>;
                payload.selected.forEach(e => {
                    this._swissdecService.viewgenAvsProofOfInsurance(e.declarationId!, e.id!).subscribe(res => {
                    });
                });
            });
        this._eventMenu.forType(Sasx5InstitutionMenuEvent.IncompleteDeclarationToggle)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<EmployeeDeclaration>;
                payload.selected.forEach(e => {
                    this._swissdecService.toggleIncompleteDeclaration(e.declarationId!, e.id!).subscribe(res => {
                        this.loadInstitutions();
                    });
                });
            });
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get canEditInstitutions() {
        return [
            SxStatus.GET_STATUS_ERROR,
            SxStatus.INIT,
            SxStatus.INIT_ERROR].indexOf(this.getFormValue('status') || SxStatus.INIT) === -1;
    }

    public get removeInstitutionDisabled(): boolean {
        return this._removeInstitutionProcessing || this._selectedInstitutions?.length === 0
            || [
                SxStatus.INIT,
                SxStatus.INIT_ERROR].indexOf(this.getFormValue('status') || SxStatus.INIT) === -1;
    }

    public ngOnInit() {
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'sasx5dec.refresh.institutions' === message) {
                    this.loadInstitutions()
                }
            });
        this.loadInstitutions();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public onRowDoubleClick(event: RowClickEvent<Sasx5institution>) {
        if (!this.canEditInstitutions) {
            return;
        }
        if (event && event.row.id) {
            this._formHandler
                .showFormDialog(AdminForm.SwissdecDeclarationInstitution, event, s => ({
                    ...s,
                    entityId: event.row.id || '',
                    diagModal: true,
                    width: 900,
                    height: 900
                }));
        }
    }

    public onApplyFilter($event: IwGridColumn<Sasx5institution>) {
        this.filterService.addFilter($event);
        this.filteredInstitutions = this.filterService.applyFilters(this.originalInstitutions);
    }

    public toggleInstitutionProcessing() {
        this._removeInstitutionProcessing = true;
        return this._http.put<Sasx5institution[]>(environment.backendURL + `swissdec/sasx5dec/${this.entityId}/toggle-process-institutions`, this._selectedInstitutions.map(emp => emp.id))
            .subscribe({
                next: sasx5dec => {
                    this._removeInstitutionProcessing = false;
                    this._selectedInstitutions = [];
                    this.loadInstitutions();
                    this._toast.success('declaration_update_success');
                },
                error: err => {
                    this._toast.error('declaration_update_error');
                    this._removeInstitutionProcessing = false;
                }
            });
    }

    setSelected($event: Sasx5institution[]) {
        this._selectedInstitutions = $event;
    }

    public async onContextMenu(event: ContextMenuEvent<Sasx5institution>) {
        this.contextMenuData = event;
        this.contextMenuItems.forEach(item => {
            item.visible = true;
        });
        if (event.selected.length > 1) {
            return;
        }
        event.selected.forEach(e => {
            // if one of the selected element is not of domain AVS, we do not show the avs proof menu
            if (e.domain !== 'AVS') {
                this.contextMenuItems.forEach(item => {
                    if (item.event === Sasx5InstitutionMenuEvent.AhvProofOfInsuranceReport) {
                        item.visible = false;
                    }
                })
            }
            // if one of the selected element is not one of domain [LAA, LAC, IJM], we do not show the toggle incomplete declaration
            if (e.domain != "LAA" && e.domain != "LAC" && e.domain != "IJM") {
                this.contextMenuItems.forEach(item => {
                    if (item.event === Sasx5InstitutionMenuEvent.IncompleteDeclarationToggle) {
                        item.visible = false;
                    }
                })
            }
            this.contextMenuItems.filter(item => item.event === Sasx5InstitutionMenuEvent.IncompleteDeclarationToggle).forEach(item => {
                if (e.incompleteDeclaration) {
                    item.label = this._translate.instant(`${Sasx5InstitutionMenuEvent.IncompleteDeclarationToggle}.mark_as_complete`);
                } else {
                    item.label = this._translate.instant(`${Sasx5InstitutionMenuEvent.IncompleteDeclarationToggle}.mark_as_incomplete`);
                }
            });

        });
        await this.showContextMenu(event.event);
    }

    private loadInstitutions() {
        this._swissdecService.loadInstitutions(this.getFormValue('id') || '').subscribe((institutions: Sasx5institution[]) => {
            this.originalInstitutions = institutions;
            this.filteredInstitutions = institutions;
        });
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(Sasx5InstitutionMenuEvent).forEach((event) => {
            menu.push(
                {
                    label: event,
                    event: event,
                    contextMenuVisibleMode: 'single'
                },
            );
        });
        return menu;
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }
}
