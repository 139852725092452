import {Component, EventEmitter, OnInit} from '@angular/core';
import {IwGridColumn, ModalComponent, RowClickEvent, sortGridList, TableSortEvent} from '@app/sam-base/models';
import {
    ModalOperationDetailComponent
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/modal-operation-detail.component';
import {
    ModalOperationDetailOptions
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/operation-modal.options';
import {ModalOperationOptions} from '@modules/sam-main/admin/components/operation/operation-modal.options';
import {Operation} from '@modules/sam-main/admin/models/operation.model';
import {OperationService} from '@modules/sam-main/admin/services/operation-service';
import {TranslateService} from '@ngx-translate/core';
import {AgencyHandlerService, ModalService, RestApiService} from '@sam-base/core';
import {ToastService} from '@sam-base/core/toast';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {ProfileService} from '@shared/profile/profile.service';


@Component({
    templateUrl: './modal-operation.component.html',
    standalone: false
})
export class ModalOperationComponent implements ModalComponent<string, ModalOperationOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public originalTableData: Operation[] = [];
    public filteredTableData: Operation[] = [];
    public columns = this.getColumns();
    public loading = false;
    private _ok?: (result: string) => void;
    private _cancel?: (result?: undefined) => void;
    private tableSortEvent?: TableSortEvent<Operation>;
    private filterService = new FilterService<Operation>();

    constructor(private _translate: TranslateService,
                private _toastService: ToastService,
                private readonly _profileService: ProfileService,
                private readonly _restService: RestApiService,
                private readonly _agency: AgencyHandlerService,
                private readonly _modalService: ModalService,
                private readonly _operationService: OperationService) {
    }

    ngOnInit(): void {
        this._operationService.gelAll().subscribe({
            next: (operations) => {
                this.originalTableData = operations;
                this.filteredTableData = operations;
            }
        })
    }

    setData(data: ModalOperationOptions): Promise<void> {
        return Promise.resolve();
    }


    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onOperationClicked($event: RowClickEvent<Operation>) {
        if (!$event.row.id) return;
        const options: ModalOperationDetailOptions = {
            showCancel: true,
            title: this._translate.instant('operations_modal_title'),
            width: 1500,
            height: 900,
            operationId: $event.row.id
        };

        this._modalService.showModal(ModalOperationDetailComponent, options);
    }

    public onSort($event?: TableSortEvent<Operation>) {
        this.tableSortEvent = $event;
        this._onSort();
    }

    public onApplyFilter($event: IwGridColumn<Operation>) {
        this.filterService.addFilter($event);
        this._onApplyFilter();
    }

    private _onApplyFilter() {
        this.filteredTableData = this.filterService.applyFilters(this.originalTableData);
    }


    private getColumns(): IwGridColumn<Operation>[] {
        return [
            {
                prop: 'id',
                name: 'id',
            },
            {
                prop: 'type',
                type: 'enum',
                enumPrefix: 'operation.type'
            },
            {
                name: 'description',
                prop: 'entityDescription',
                type: 'string'
            },
            {
                prop: 'status',
                type: 'enum',
                enumPrefix: 'operation.status',
            },
            {
                prop: 'dueAt',
                name: 'dueAt',
                type: 'dateTime',
            },
            {
                prop: 'submittedBy',
                name: 'submittedBy',
                type: 'string',
            },
            {
                prop: 'submittedAt',
                name: 'submittedAt',
                type: 'dateTime',
            },
            {
                prop: 'startedAt',
                name: 'startedAt',
                type: 'dateTime',
            },
            {
                prop: 'endedAt',
                name: 'endedAt',
                type: 'dateTime',
            },
        ];
    }

    private _onSort() {
        if (!this.tableSortEvent) return;
        this.filteredTableData = [...sortGridList(this.filteredTableData, this.tableSortEvent)];
    }

}
