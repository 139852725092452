import {Component, OnInit} from '@angular/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ParametersService} from '@core/services/parameters.service';
import {BaseFormComponent} from '@sam-base/base';
import {IwStoreService} from '@sam-base/core';
import {ToastService} from '@sam-base/core/toast';
import {FormKeys} from '@sam-base/models/form-keys.model';
import {lastValueFrom, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {InterijobService} from '../../services/interijob.service';
import {IJConfig} from './interijob-config.model';

@Component({
    templateUrl: './interijob-config.component.html',
    standalone: false
})
export class InterijobConfigComponent extends BaseFormComponent<IJConfig> implements OnInit {

    public isReadonly = true;
    public isLoading = false;


    constructor(private _store: IwStoreService, private _paramService: ParametersService, private _toast: ToastService,
                private _ijService: InterijobService) {
        super();
    }

    public ngOnInit() {
        this.getInterijobData();
    }

    public modifyEntry() {
        this.isReadonly = false;
    }

    public saveEntry() {

        this.isLoading = true;

        const data = this.getFormData();

        if (!data) {
            return;
        }

        data.IJPT = data.IJPT === true ? '1' : '0';
        data.IJSIG = data.IJSIG === true ? '1' : '0';

        this._ijService.checkIjParams(this.getFormValue('IJSOCPWD'), this.getFormValue('IJSOCNAME'), this.getFormValue('IJURL'))
            .subscribe((value: string) => {
                this._toast.success('ij_200');
                this._paramService.saveData('ijData', data)
                    .pipe(catchError(() => {
                        this.isLoading = false;
                        return of(undefined);
                    }))
                    .subscribe(() => {
                        this.getInterijobData();
                        this.isReadonly = true;
                        this.isLoading = false;
                    });
            }, err => {
                if (err.status === 401) {
                    this._toast.error('ij_401', err);
                } else if (err.status === 403) {
                    this._toast.error('ij_403', err);
                } else if (err.status === 404) {
                    this._toast.error('ij_404', err);
                }
                this.isLoading = false;
            });

    }

    public cancelEditionMode() {
        this.isReadonly = true;

        this.getInterijobData();
    }


    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<IJConfig> {
        return [
            'IJSOCNAME',
            'IJSOCPWD',
            'IJURL',
            'IJPT',
            'IJSIG',
            'IJFACEXP'];
    }

    private async getInterijobData() {
        this.isLoading = true;

        const ijData = await lastValueFrom(this._paramService.getDataText<IJConfig>('ijData'));

        ijData.IJPT = ijData.IJPT === '1';
        ijData.IJSIG = ijData.IJSIG === '1';

        this.fillFormData(ijData);

        this.isLoading = false;
    }

}
