export enum GestionModules {
    SALARY = 'S',
    PLACEMENT_TEMP = 'PT',
    PLACEMENT_FIX = 'PF',
    PLACEMENT_MENSUEL = 'PM',
    INVOICE = 'D',
    COMPTABILITY = 'C',
    SUPPLIER = 'O'
}

export const PLACEMENT_MODULES = [
    GestionModules.PLACEMENT_FIX,
    GestionModules.PLACEMENT_MENSUEL,
    GestionModules.PLACEMENT_TEMP]
