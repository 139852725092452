import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {ParametersService} from '@app/core/services/parameters.service';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwTableComponent} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {IwEventHubService, IwStoreService, ModalService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {GesaexeService} from '@app/sam-base/core/services/gesaexe.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {IwGridColumn} from '@app/sam-base/models/components';
import {Gesaexe} from '@app/sam-base/models/invoices/gesaexe';
import {GesaexeView} from '@app/sam-base/models/invoices/gesaexeview';
import {BulkManagerComponent} from '@modules/sam-main/admin/components/bulk/bulk-manager.component';
import {BulkManagerOptions} from '@modules/sam-main/admin/components/bulk/bulk-manager.options';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';

@Component({
    templateUrl: './selec-exercice.component.html',
    standalone: false
})
export class GesaexeComponent extends BaseFormComponent<Gesaexe> implements OnInit {
    @ViewChild('gesaexeTable') public gesaexeTable?: IwTableComponent<GesaexeView>;
    public type: Type<GesaexeView> = GesaexeView;
    public formState: FormState | undefined;
    public selectedRow?: GesaexeView;
    public readonly = true;
    public isSaving = false;
    public maxCannee?: string;
    public nextCannee?: string;
    public columns = this.getGesaexeColumns();

    private readonly _restGesaexeClient: RestEntityClient<GesaexeView>;

    constructor(private _store: IwStoreService, rest: RestApiService, private _translate: TranslateService,
                private readonly _modalService: ModalService, private readonly gesaexeService: GesaexeService,
                private readonly _parametersService: ParametersService,
                private _eventStore: IwEventHubService<string>) {
        super();
        this._restGesaexeClient = rest.getEntityClient(GesaexeView);
    }

    public ngOnInit() {
        this.getMaxCannee();
    }

    public getMaxCannee() {
        this.gesaexeService.getMaxCannee()
            .subscribe((year) => {
                this.maxCannee = year;
                if (year) {
                    this.nextCannee = (parseInt(year, 10) + 1).toString();
                }
            });
    }

    public getSelectedRow(event: GesaexeView[]) {
        this.readonly = true;
        if (this.isSaving) {
            this.isSaving = false;
            return;
        }
        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }
        this.selectedRow = event[0];
        this.fillFormData(event[0]);
    }

    public selectionnerEntry() {
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this.gesaexeService.selectEntry(this.selectedRow?.cannee || '')
            .subscribe(async (_) => {
                this.gesaexeTable?.refresh();
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
                await lastValueFrom(this._parametersService.getCurrentSapar(true));
                this._eventStore.emit('active_exercise');
            });
    }

    public async createNewEntry() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('creerUnAnnee') + ' ' + this.nextCannee + ' ?'],
            showCancel: true,
            title: this._translate.instant('gesaexe_form_title'),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.setValues();
        } catch (error) {
        }
    }

    public setValues() {
        const gesaexe = new GesaexeView();
        gesaexe.cannee = this.nextCannee;

        const updatedData = this._restGesaexeClient.construct({
            ...gesaexe
        });
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this.gesaexeService.createNewEntry(updatedData)
            .subscribe(() => {
                this.getMaxCannee();
                this.gesaexeTable?.refresh();
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
            });
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public bsYearByMail() {
        const id = this.selectedRow?.cannee || '';
        const options: BulkManagerOptions = {
            type: OperationType.EMAIL_YEAR_SALARIES,
            year: id,
            force: true
        };

        this._modalService.showModal(BulkManagerComponent, options);
    }

    private getGesaexeColumns(): IwGridColumn<Gesaexe>[] {
        return [
            {
                prop: 'cannee',
                name: this._translate.instant('annee'),
                width: 50,
                type: 'activePer'
            },
            {
                prop: 'larc',
                name: this._translate.instant('status'),
                width: 50,
                type: 'boolean'
            }];
    }
}
