import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    BulkEntitySelectComponent
} from '@modules/sam-main/admin/components/bulk/bulk-entity-select/bulk-entity-select.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {EsQueryStatement, Term} from '@sam-base/core';
import {GerplhdrView} from '@sam-base/models/invoices/gerplhdrview';

@Component({
    selector: 'iw-bulk-entity-select-gerplhdr',
    templateUrl: './bulk-entity-select.component.html',
    standalone: false
})
export class BulkEntitySelectGerplhdrComponent extends BulkEntitySelectComponent<GerplhdrView> implements OnInit, OnDestroy {
    public entity: Type<GerplhdrView> = <any>GerplhdrView;

    @Input()
    public operationType?: OperationType;
    @Input()
    public force: boolean = false;

    constructor(private readonly http: HttpClient) {
        super();
    }

    public ngOnInit() {
        this.buildQueryStatements();
        this.setCustomColumns();
    }

    private buildQueryStatements() {
        const list: EsQueryStatement<GerplhdrView>[] = [];
        list.push(EsQueryStatement.fromTerm(<Term<GerplhdrView>>{type: 'gerplhdrview'}, 'filter'));
        list.push(EsQueryStatement.fromTerm(<Term<GerplhdrView>>{sendingStatus: 'READY'}, 'must'));
        this.statements = list;
    }

    private setCustomColumns() {
        this.columns = [
            {
                prop: 'rplhdrId',
                name: 'rplhdrId',
                type: 'number'
            },
            {
                name: 'cliNom',
                prop: 'cliNom',
                type: 'string'
            },
            {
                name: 'norappel',
                prop: 'noRappel',
                type: 'number',
                align: 'right'
            },
            {
                name: 'solde',
                prop: 'solde',
                type: 'number',
                align: 'right'
            },
            {
                name: 'dateRpl',
                prop: 'dateRpl',
                type: 'date'
            },
            {
                name: 'sendingStatus',
                prop: 'sendingStatus',
                type: 'enum',
                enumPrefix: 'gerplhdr.sendingStatus'
            }
        ];
    }
}
