<div class="flex flex-v flex-evenly">
    <div class="row margin-top-10 flex flex-h flex-between">
        <iw-button #Standardbutton2
                   (press)="openSendSMSForm()"
                   *iwAccessGroups="clientCommunicationRoles"
                   [tooltip]="'envoyerSms' | translate"
                   class="iw-button no-padding"
                   iconClass="fas fa-comment-dots"></iw-button>
        <iw-button #btnSendMail
                   (press)="openEmailForm()"
                   *iwAccessGroups="clientCommunicationRoles"
                   class="iw-button no-padding"
                   iconClass="fas fa-envelope"></iw-button>
    </div>
    <div class="row margin-top-20">
        <iw-button #btnOpenClose
                   (press)="changeCliStatus()"
                   *iwAccessGroups="clientSituationRoles"
                   [text]="'oF' | translate"
                   [tooltip]="'open_close_c' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <ng-container *iwAccessGroups="clientBlockRoles">
            <iw-button #btnBlockCli
                       (press)="toggleBlockClient()"
                       *ngIf="ppcli && ppcli.clistatus !== 3"
                       [text]="'block_entity' | translate"
                       [tooltip]="'block_entity' | translate"
                       class="btn-block"></iw-button>
        </ng-container>

        <ng-container *iwAccessGroups="clientUnblockRoles">
            <iw-button #btnUbnblockCli
                       (press)="toggleBlockClient()"
                       *ngIf="ppcli && ppcli.clistatus === 3"
                       [text]="'unblock_entity' | translate"
                       [tooltip]="'unblock_entity' | translate"
                       class="btn-block"></iw-button>
        </ng-container>
    </div>

    <div class="row margin-top-10">
        <iw-button #btnCreatMis
                   (press)="createMission()"
                   *iwAccessGroups="buttonRolesCreateMission"
                   [text]="'createMission' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnMis
                   (press)="openMissions()"
                   *iwAccessGroups="clientOpenMissionCommandRoles"
                   [text]="'missions' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnPlf
                   (press)="openPlacefixGrid()"
                   *iwAccessGroups="clientOpenMissionCommandRoles"
                   [text]="'fixe' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnCdes
                   (press)="openCommandesTempo()"
                   *iwAccessGroups="clientOpenMissionCommandRoles"
                   [text]="'ppcli_contextmenu_orders' | translate"
                   class="btn-block"></iw-button>
    </div>


    <div class="row">
        <iw-button #btnCdes
                   (press)="openCommandesFixe()"
                   *iwAccessGroups="clientOpenMissionCommandRoles"
                   [text]="'ppcli_contextmenu_orders_fixe' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnPrintDossier
                   (press)="openDossier()"
                   *iwAccessGroups="clientPrintRoles"
                   [text]="'dossier' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row margin-top-20">
        <iw-button #btnStats
                   (press)="openStats()"
                   *iwAccessGroups="clientSituationRoles"
                   [text]="'stat' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnSyncDebiteur
                   (press)="syncDebiteur()"
                   *iwAccessGroups="clientSiteDebitRoles"
                   [text]="'syncDebiteur' | translate"
                   class="btn-block"></iw-button>
    </div>

    <div class="row">
        <iw-button #btnEmpHeu
                   (press)="openHeures()"
                   *iwAccessGroups="clientSiteDebitRoles"
                   [text]="'h' | translate"
                   [tooltip]="'heuresTravaillees' | translate"
                   class="btn-block"></iw-button>
    </div>
    <div class="row">
        <iw-button #btnEmpHeu
                   (press)="openSituation()"
                   *iwAccessGroups="clientSituationRoles"
                   [text]="'situation' | translate"
                   class="btn-block"></iw-button>
    </div>
</div>
