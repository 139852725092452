import {Component, OnDestroy, OnInit, Type} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {SidemenuState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, FormKeys, ModalComponent} from '@app/sam-base/models';
import {Ppcdf, Ppcli, Ppclicdfs, Ppcon, Ppemp, Ppplf, Ppqua} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {EntityNavigationService, IwEventHubService, ModalService, PpconService, RestApiService} from '@sam-base/core';
import {
    ModalRapportInvoiceComponent
} from '@shared/widgets/modal-components/modal-rapport-invoice/modal-rapport-invoice.component';
import {
    ModalRapportDataOptions
} from '@shared/widgets/modal-components/modal-rapport-invoice/modal-rapport-invoice.options';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {map} from 'rxjs/operators';

import {MatchingService} from '../../services/matching.service';
import {ReportsService} from '../../services/reports.service';

@Component({
    templateUrl: './placfixe.component.html',
    standalone: false
})
export class PlacfixeComponent extends BaseStoreFormComponent<Ppplf> implements OnInit, OnDestroy {

    public type: Type<any> = Ppcon;
    public entityEmploye = Ppemp;
    public entityClient = Ppcli;
    public readonly listOptionQualif: ModalSelectTableOption<Ppqua, string>;

    constructor(store: IwStoreService, private readonly _translate: TranslateService,
                private _reportsService: ReportsService, private readonly _modalService: ModalService,
                private _entityNavService: EntityNavigationService, private readonly _events: IwEventHubService<string>,
                private readonly _restService: RestApiService, private readonly _matchingService: MatchingService,
                private _ppconService: PpconService) {
        super(store);
        this.listOptionQualif = {
            columns: [{prop: 'qualif'}],
            map: (e: Ppqua) => e.quaId || '',
            selection: 'single',
            fetchAction: this.profFetchAction
        };
    }

    public get facId(): string | undefined {
        return this.getFormValue('facId');
    }

    public get ppcdf(): Ppcdf | undefined {
        return this.getData('ppcdf');
    }

    public get empId(): string | undefined {
        return this.getData('empId');
    }

    public profFetchAction = () => this._restService.getEntityClient(Ppqua)
        .getRefData()
        .pipe(map((qualifs) => qualifs.filter(ppqua => ppqua.active)
            .sort((a, b) => this.sortQualif(a, b))));

    public ngOnInit() {
        if (this.ppcdf) {
            this.fillFields();
        }
        if (this.isNew) {
            this._ppconService.fillMainConseiller(this.getFormControl('conId'));
        }
    }

    public ngOnDestroy() {
        this._events.emit('placf_closed', this.getFormValue('plfId'));
    }

    public openInvoicePreview(): void {
        alert("TODO");
        // this._store.dispatch(new actions.SetLoading(this.uuid || '', true));
        // this._reportsService.openInvoice(this.getFormValue('facId') || '')
        //     .subscribe((resp: OpenPreviewResponse) => {
        //         this.getInvoiceUuid(resp);
        //     });
    }

    public openCommande(): void {
        this._entityNavService
            .navigateToEntityForm(Ppclicdfs, this.getFormValue('cdfId') || '', undefined, 'read');
    }

    public async openQualifSelection() {
        try {
            if (!this.listOptionQualif) {
                return;
            }
            const comp: Type<ModalComponent<string[], Ppqua>> = <any>IwModalSelectTableComponent;
            try {
                const data = await this._modalService
                    .showModal<string[], Ppqua>(comp, <any>this.listOptionQualif);
                this.setQualifName(data[0]);
            } catch {
            }

        } catch (err) {
        }
    }

    protected getFormControlNames(): FormKeys<Ppplf> {
        return [
            'plfId',
            'cdfId',
            'facNo',
            'facDate',
            'ctaCliId',
            'cliadrId',
            'ctaEmpId',
            'ctaDate',
            'ageId',
            'secId',
            'conId',
            'conId2',
            'conid2Pc',
            'ctaFct',
            'ctaDebut',
            'ctaSal',
            'ctaHon',
            'ctaMnt',
            'ctaRemcli',
            'ctaRem',
            'facId',
            'plffrx',
            'attention'];
    }

    protected getSideMenuState(): SidemenuState {
        return {
            navigationButtons: true,
            close: true,
            create: true,
            delete: true,
            edit: true,
            filters: true,
            orderBySelect: true,
            print: true
        };
    }

    protected getOrderByOptions(): ComboboxItem<keyof Ppplf>[] {
        return [
            {
                name: this._translate.instant('placement_option'),
                value: 'plfId'
            },
            {
                name: this._translate.instant('date_option'),
                value: 'ctaDate'
            },
            {
                name: this._translate.instant('order_option'),
                value: 'cdfId'
            },
            {
                name: this._translate.instant('employee_option'),
                value: 'ctaEmpId'
            },
            {
                name: this._translate.instant('client_option'),
                value: 'ctaCliId'
            },
            {
                name: this._translate.instant('recording_option'),
                value: 'plfId'
            }];
    }

    protected getValidationType() {
        return Ppplf;
    }

    protected validateFields(e: Ppplf): boolean {
        return super.validateFields(e);
    }

    protected validateReadonlyStatus(e: Ppplf) {
        const status = e.plfstatus || 0;
        return (status === 2) && !!e.plfId;
    }

    private async openRapportModal(elem: any, type: string) {
        const options: ModalRapportDataOptions = {
            showCancel: true,
            title: this._translate.instant('invoices_form_title'),
            width: 1100,
            height: 800,
            obj: elem
        };
        this._store.dispatch(new actions.SetLoading(this.uuid || '', false));
        if (type === 'i') {
            await this._modalService.showModal(ModalRapportInvoiceComponent, options);
        }
    }

    private fillFields(): void {
        this.setFormValue('ctaCliId', this.ppcdf?.cliId);
        this.setFormValue('ctaDate', this.ppcdf?.dateCdf);
        this.setFormValue('ageId', this.ppcdf?.ageId);
        this.setFormValue('secId', this.ppcdf?.secId);
        this.setFormValue('conId', this.ppcdf?.conId);
        this.setFormValue('ctaFct', this.setQualifName(this.ppcdf?.qualif));
        this.setFormValue('ctaSal', this.ppcdf?.salaire);
        this.setFormValue('plffrx', this.ppcdf?.cdffrx);
        this.setFormValue('cdfId', this.ppcdf?.cdfId);
        this.setFormValue('ctaEmpId', this.empId);
    }

    private setQualifName(val?: string) {
        if (!val) {
            return;
        }
        this._matchingService
            .getProfessionNames(val)
            .subscribe(e => {
                if (e.length === 0) {
                    this.setFormValue('ctaFct', val);
                } else {
                    this.setFormValue('ctaFct', e[0] || '');
                }
            });
    }

    private sortQualif(a: Ppqua, b: Ppqua) {
        return (a.qualif || '') < (b.qualif || '') ? -1 : 1;
    }

}
