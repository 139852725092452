import {showGridDetailsAccessRoles} from '@app/sam-base/core/auth/access-rules/entity-forms';
/** Form list will be deprecated by placement.forms.ts */
import {FormComponentMetadata} from '@app/sam-base/models';
import {Ppcli, Ppclicdes, Ppclimis, Ppemp} from '@app/sam-base/models/placement';
import {PaymentComponent} from '@modules/sam-main/placement/components/acompte/paiement/payment.component';
import {AgenceConfigComponent} from '@modules/sam-main/placement/components/agence-config/agence-config.component';
import {
    AgetimeComponent
} from '@modules/sam-main/placement/components/agence-config/agetime-config/agetime-config.component';
import {
    TabPpcdeFixeDocComponent
} from '@modules/sam-main/placement/components/cdefixe/tab-ppcdefixedoc/tab-ppcdefixedoc.component';
import {
    TabPpcdetempoPageDocComponent
} from '@modules/sam-main/placement/components/cdetempo/tab-ppcdetempo-doc/tab-ppcdetempo-doc.component';
import {EmpsituComponent} from '@modules/sam-main/placement/components/empsitu/empsitu.component';
import {LookupsComponent} from '@modules/sam-main/placement/components/lookups/lookups.component';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {Ppclicdfs} from '@sam-base/models/placement/ppclicdfs';
import {EntityDetailClientComponent} from '@shared/widgets/iw-components/entity-details/entity-detail-client.component';
import {
    EntityDetailCommandFixeComponent
} from '@shared/widgets/iw-components/entity-details/entity-detail-command-fixe.component';
import {
    EntityDetailCommandComponent
} from '@shared/widgets/iw-components/entity-details/entity-detail-command.component';
import {
    EntityDetailEmployeeComponent
} from '@shared/widgets/iw-components/entity-details/entity-detail-employee.component';
import {
    EntityDetailMissionComponent
} from '@shared/widgets/iw-components/entity-details/entity-detail-mission.component';
import {
    ModalInvoiceCountComponent
} from '@shared/widgets/modal-components/modal-invoice-count/modal-invoice-count.component';
import {
    ModalInvoiceSessionComponent
} from '@shared/widgets/modal-components/modal-invoice-session/modal-invoice-session.component';
import {
    ModalSalaryCountComponent
} from '@shared/widgets/modal-components/modal-salary-count/modal-salary-count.component';
import {
    ModalSalaryLogNGComponent
} from '@shared/widgets/modal-components/modal-salary-log-ng/modal-salary-log-ng.component';
import {
    ModalSalarySessionComponent
} from '@shared/widgets/modal-components/modal-salary-session/modal-salary-session.component';
import {
    TabCctRemarquesComponent
} from 'src/app/modules/sam-main/placement/components/cct-config/tab-mission/tab-cct-remarques.component';
import {
    CdffactComponent,
    EmployeeComponent,
    MissionsComponent,
    PlacfixeComponent,
    PpcliattComponent,
    PpclichagrdComponent,
    PpclifltComponent,
    PpclistatsComponent
} from './components';
import {AcompteComponent} from './components/acompte/acompte.component';
import {AutoacoComponent} from './components/acompte/autoaco/autoaco.component';
import {AcompteSidemenuComponent} from './components/acompte/ppacosaid/ppacosaid-sidemenu.component';
import {PpacosaidComponent} from './components/acompte/ppacosaid/ppacosaid.component';
import {AginterNewComponent} from './components/aginter/aginter-new/aginter-new.component';
import {AginterSidemenuComponent} from './components/aginter/aginter-sidemenu.component';
import {AginterComponent} from './components/aginter/aginter.component';
import {Tab1114AginterComponent} from './components/aginter/tab-1114-aginter/tab-1114-aginter.component';
import {Tab14AginterComponent} from './components/aginter/tab-14-aginter/tab-14-aginter.component';
import {Tab1518AginterComponent} from './components/aginter/tab-1518-aginter/tab-1518-aginter.component';
import {Tab57AginterComponent} from './components/aginter/tab-57-aginter/tab-57-aginter.component';
import {Tab810AginterComponent} from './components/aginter/tab-810-aginter/tab-810-aginter.component';
import {TabRemarquesAginterComponent} from './components/aginter/tab-remarques-aginter/tab-remarques-aginter.component';
import {
    CalendarJoursFeriesComponent
} from './components/calendar-jours-feries-config/calendar-jours-feries-config.component';
import {CctConfigComponent} from './components/cct-config/cct-config.component';
import {TabCctAutreComponent} from './components/cct-config/tab-cct-autre/tab-cct-autre.component';
import {TabCctExecutionComponent} from './components/cct-config/tab-cct-execution/tab-cct-execution.component';
import {TabCctFormationComponent} from './components/cct-config/tab-cct-formation/tab-cct-formation.component';
import {TabPgmComponent} from './components/cct-config/tab-pgm/tab-pgm.component';
import {TabRaComponent} from './components/cct-config/tab-ra/tab-ra.component';
import {CdefixeSideMenuComponent} from './components/cdefixe/cdefixe-side-menu.component';
import {CdefixeComponent} from './components/cdefixe/cdefixe.component';
import {CdfNewComponent} from './components/cdefixe/cdfnew/cdfnew.component';
import {
    TabPpcdefixeDescremarquesComponent
} from './components/cdefixe/tab-ppcdefixe-descremarques/tab-ppcdefixe-descremarques.component';
import {TabPpcdefixeGeneralComponent} from './components/cdefixe/tab-ppcdefixe-general/tab-ppcdefixe-general.component';
import {TabPpcdefixeMatchComponent} from './components/cdefixe/tab-ppcdefixe-match/tab-ppcdefixe-match.component';
import {
    TabPpcdeFixePlacementComponent
} from './components/cdefixe/tab-ppcdefixe-placement/tab-ppcdefixe-placement.component';
import {TabPpcdefixeSuiviComponent} from './components/cdefixe/tab-ppcdefixesuivi/tab-ppcdefixesuivi.component';
import {CdeNewComponent} from './components/cdetempo/cdenew/cdenew.component';
import {CdetempoSideMenuComponent} from './components/cdetempo/cdetempo-side-menu.component';
import {CdetempoComponent} from './components/cdetempo/cdetempo.component';
import {TabPpcdetempoAutreComponent} from './components/cdetempo/tab-ppcdetempo-autre/tab-ppcdetempo-autre.component';
import {
    TabPpcdetempoDescremarquesComponent
} from './components/cdetempo/tab-ppcdetempo-descremarques/tab-ppcdetempo-descremarques.component';
import {
    TabPpcdetempoGeneralComponent
} from './components/cdetempo/tab-ppcdetempo-general/tab-ppcdetempo-general.component';
import {TabPpcdetempoMatchComponent} from './components/cdetempo/tab-ppcdetempo-match/tab-ppcdetempo-match.component';
import {TabPpcdeMissionsComponent} from './components/cdetempo/tab-ppcdetempo-missions/tab-ppcde-missions.component';
import {
    TabPpcdetempoPageSuiviComponent
} from './components/cdetempo/tab-ppcdetempo-suivi/tab-ppcdetempo-suivi.component';
import {PpconComponent} from './components/conseillers/ppcon.component';
import {TabPpempGeneralComponent, TabPpempSituationComponent} from './components/employee';
import {EmployeSideMenuComponent} from './components/employee/employe-side-menu.component';
import {
    EmployeeCreationModeComponent
} from './components/employee/employee-creation-mode/employee-creation-mode.component';
import {PpempCandidateNewComponent} from './components/employee/ppemp-candidate-new/ppemp-candidate-new.component';
import {
    TabPpempDispSouhaitComponent
} from './components/employee/tab-ppemp-disp-souhait/tab-ppemp-disp-souhait.component';
import {
    DocumentTypeManagementComponent
} from './components/employee/tab-ppemp-docs/document-type-management/document-type-management.component';
import {TabPpempDocsComponent} from './components/employee/tab-ppemp-docs/tab-ppemp-docs.component';
import {TabPpempExperienceComponent} from './components/employee/tab-ppemp-experience/tab-ppemp-experience.component';
import {TabPpempLanguesComponent} from './components/employee/tab-ppemp-langues/tab-ppemp-langues.component';
import {TabPpempMatchingComponent} from './components/employee/tab-ppemp-matching/tab-ppemp-matching.component';
import {TabPpempNotesComponent} from './components/employee/tab-ppemp-notes/tab-ppemp-notes.component';
import {
    SalairePaiementComponent
} from './components/employee/tab-ppemp-paiement/salaire-paiement/salaire-paiement.component';
import {TabPpempPaiementComponent} from './components/employee/tab-ppemp-paiement/tab-ppemp-paiement.component';
import {TabPpempReferencesComponent} from './components/employee/tab-ppemp-references/tab-ppemp-references.component';
import {TabPpempSuiviComponent} from './components/employee/tab-ppemp-suivi/tab-ppemp-suivi.component';
import {GenresIndemnitesComponent} from './components/genres-indemnites/genres-indemnites.component';
import {GenretravConfigComponent} from './components/genretrav-config/genretrav-config.component';
import {GestionModelesComponent} from './components/gestion-modeles/gestion-modeles.component';
import {InterijobConfigComponent} from './components/interijob-config/interijob-config.component';
import {InvoicesParametersComponent} from './components/invoices/invoices-parameters/invoices-parameters.component';
import {InvoicesComponent} from './components/invoices/invoices.component';
import {KeywordConfigComponent} from './components/keyword-config/keyword-config.component';
import {MisindComponent} from './components/misind/misind.component';
import {MisnewComponent} from './components/missions/misnew/misnew.component';
import {MissionsSidemenuComponent} from './components/missions/missions-sidemenu.component';
import {TabPpmisAutreComponent} from './components/missions/tab-ppmis-autre/tab-ppmis-autre.component';
import {TabPpmisDocsComponent} from './components/missions/tab-ppmis-docs/tab-ppmis-docs.component';
import {TabPpmisGeneralComponent} from './components/missions/tab-ppmis-general/tab-ppmis-general.component';
import {TabPpmisIjComponent} from './components/missions/tab-ppmis-ij/tab-ppmis-ij.component';
import {MisheuspcComponent} from './components/missions/tab-ppmis-ind/misheuspc/misheuspc.component';
import {TabMisIndComponent} from './components/missions/tab-ppmis-ind/tab-ppmis-ind.component';
import {TabPpmisNotesComponent} from './components/missions/tab-ppmis-notes/tab-ppmis-notes.component';
import {TabPpmisRapportComponent} from './components/missions/tab-ppmis-rapport/tab-ppmis-rapport.component';
import {
    MisFactureHoursComponent
} from './components/missions/tab-ppmis-salary-tarif/components/mis-fac-hours/mis-fac-hours.component';
import {
    MisFactureMonthComponent
} from './components/missions/tab-ppmis-salary-tarif/components/mis-fac-month/mis-fac-month.component';
import {
    MisSalaryHoursComponent
} from './components/missions/tab-ppmis-salary-tarif/components/mis-salary-hours/mis-salary-hours.component';
import {
    MisSalaryMonthComponent
} from './components/missions/tab-ppmis-salary-tarif/components/mis-salary-month/mis-salary-month.component';
import {
    TabMisSalaryTarifComponent
} from './components/missions/tab-ppmis-salary-tarif/tab-ppmis-salary-tarif.component';
import {TabPpmisSuiviComponent} from './components/missions/tab-ppmis-suivi/tab-ppmis-suivi.component';
import {ParametresGenerauxComponent} from './components/parametres-generaux/parametresGeneraux.component';
import {
    TabPpparAccomptesComponent
} from './components/parametres-generaux/tab-pppar-accomptes/tab-pppar-accomptes.component';
import {TabPpparClientComponent} from './components/parametres-generaux/tab-pppar-client/tab-pppar-client.component';
import {
    TabPpparCommandeComponent
} from './components/parametres-generaux/tab-pppar-commande/tab-pppar-commande.component';
import {TabPpparEmployeComponent} from './components/parametres-generaux/tab-pppar-employe/tab-pppar-employe.component';
import {TabPpparFromatsComponent} from './components/parametres-generaux/tab-pppar-formats/tab-pppar-formats.component';
import {TabPpparGeneralComponent} from './components/parametres-generaux/tab-pppar-general/tab-pppar-general.component';
import {TabPpparRatingComponent} from './components/parametres-generaux/tab-pppar-rating/tab-pppar-rating.component';
import {
    TabPpparSalaire1Component
} from './components/parametres-generaux/tab-pppar-salaire1/tab-pppar-salaire1.component';
import {
    TabPpparSalaire2Component
} from './components/parametres-generaux/tab-pppar-salaire2/tab-pppar-salaire2.component';
import {
    TabPpparSignatureComponent
} from './components/parametres-generaux/tab-pppar-signature/tab-pppar-signature.component';
import {PeriodManagementComponent} from './components/period-management/period-management.component';
import {PpacattestNewComponent} from './components/ppacattest/ppacattest-new/ppacattest-new.component';
import {PpacattestSidemenuComponent} from './components/ppacattest/ppacattest-sidemenu.component';
import {PpacattestComponent} from './components/ppacattest/ppacattest.component';
import {TabDerniereMissionComponent} from './components/ppacattest/tab-derniere-mission/tab-derniere-mission.component';
import {
    TabPpacattesAnnesComponent
} from './components/ppacattest/tab-ppacattest-annees/tab-ppacattest-annees.component';
import {
    TabPpacattesHistoriqueMissionComponent
} from './components/ppacattest/tab-ppacattest-historiqueMission/tab-historiqueMission.component';
import {
    TabPpacattestPeriodesComponent
} from './components/ppacattest/tab-ppacattest-periodes/tab-ppacattest-periodes.component';
import {TabPremiereMissionComponent} from './components/ppacattest/tab-premiere-mission/tab-premiere-mission.component';
import {
    PpcliComponent,
    TabAddressComponent,
    TabAutreComponent,
    TabChantierComponent,
    TabContactsComponent,
    TabDocComponent,
    TabGeneralComponent,
    TabMatchingComponent,
    TabMissionsComponent,
    TabNotesComponent,
    TabPointeuseComponent,
    TabSuiviComponent
} from './components/ppcli';
import {PpcliSideMenuComponent} from './components/ppcli/ppcli-side-menu.component';
import {PpdoctypeComponent} from './components/ppdoctype/ppdoctype.component';
import {PpempenfComponent} from './components/ppempenf/ppempenf.component';
import {PpgrhConfigComponent} from './components/ppgrh-config/ppgrh-config.component';
import {Ppheusaid2Component} from './components/ppheusaid2/ppheusaid2.component';
import {PpindemniteComponent} from './components/ppheusaid2/ppindemnite/ppindemnite.component';
import {PpindsaidComponent} from './components/ppindsaid/ppindsaid.component';
import {PpjfcalcComponent} from './components/ppjfcalc/ppjfcalc.component';
import {Ppmismdl2Component} from './components/ppmismdl2/ppmismdl2.component';
import {
    IwTabPpopttxtPage1Component,
    IwTabPpopttxtPage2Component,
    IwTabPpopttxtPage3Component,
    IwTabPpopttxtPage4Component,
    IwTabPpopttxtPage5Component,
    IwTabPpopttxtPage6Component,
    IwTabPpopttxtPage7Component,
    IwTabPpopttxtPage8Component,
    IwTabPpopttxtPage9Component,
    PpopttxtComponent
} from './components/ppopttxt';
import {ProfessionsConfigComponent} from './components/professions-config/professions-config.component';
import {RapportSidemenuComponent} from './components/rapport/rapport-sidemenu.component';
import {RapportComponent} from './components/rapport/rapport.component';
import {TabRapportHeuresComponent} from './components/rapport/tab-rapport-heures/tab-rapport-heures.component';
import {
    TabRapportIndemnitesComponent
} from './components/rapport/tab-rapport-indemnites/tab-rapport-indemnites.component';
import {TabRapportNotesComponent} from './components/rapport/tab-rapport-notes/tab-rapport-notes.component';
import {TabRapportRecapComponent} from './components/rapport/tab-rapport-recap/tab-rapport-recap.component';
import {ListRapportsComponent} from './components/rapports/list-rapports/list-rapports.component';
import {PpheusaisComponent} from './components/rapports/list-rapports/ppheusais/ppheusais.component';
import {RapportsComponent} from './components/rapports/rapports.component';
import {WeekNavigationComponent} from './components/rapports/week-navigation/week-navigation.component';
import {SalaryParametersComponent} from './components/salary/salary-parameters/salary-parameters.component';
import {
    TabSalaryAutreComponent
} from './components/salary/salary-parameters/tab-salary-autre/tab-salary-autre.component';
import {
    TabSalaryDeductionsComponent
} from './components/salary/salary-parameters/tab-salary-deductions/tab-salary-deductions.component';
import {
    TabSalaryGenresalComponent
} from './components/salary/salary-parameters/tab-salary-genresal/tab-salary-genresal.component';
import {
    TabSalaryProvisionsComponent
} from './components/salary/salary-parameters/tab-salary-provisions/tab-salary-provisions.component';
import {SalaryComponent} from './components/salary/salary.component';
import {SecteurConfigComponent} from './components/secteur-config/secteur-config.component';
import {IwSendSMSComponent} from './components/send-sms/iw-send-sms-form/iw-send-sms-form.component';
import {IwSendSMSModalComponent} from './components/send-sms/iw-send-sms-modal/iw-send-sms-modal.component';
import {SuiviComponent} from './components/suivi/suivi.component';
import {UserRolesConfigComponent} from './components/user-roles-config/user-roles-config.component';
import {EntityDetail} from './models/entity-detail.model';

export const PLACEMENT_COMPONENT_LIST = [
    TabPpempSituationComponent,
    TabPpempSuiviComponent,
    TabMisSalaryTarifComponent,
    MisSalaryHoursComponent];

/** List of components that can be loaded at runtime */
export const PLACEMENT_FORMS_ENTRY_COMPONENTS = [
    PlacfixeComponent,
    AgetimeComponent,
    AcompteComponent,
    AcompteSidemenuComponent,
    PpacosaidComponent,
    PpindsaidComponent,
    MisindComponent,
    CdffactComponent,
    PpclistatsComponent,
    PpcliattComponent,
    PpclifltComponent,
    PpclichagrdComponent,
    PpdoctypeComponent,
    ProfessionsConfigComponent,
    GenretravConfigComponent,
    PpgrhConfigComponent,
    AgenceConfigComponent,
    MailComposerComponent,
    PpcliComponent,
    PpconComponent,
    EmployeeComponent,
    TabPpempGeneralComponent,
    TabPpempDispSouhaitComponent,
    TabPpempExperienceComponent,
    TabPpempNotesComponent,
    TabPpempDocsComponent,
    TabPpempMatchingComponent,
    TabPpempPaiementComponent,
    SalairePaiementComponent,
    TabPpempReferencesComponent,
    TabPpempLanguesComponent,
    DocumentTypeManagementComponent,
    PeriodManagementComponent,
    TabGeneralComponent,
    TabAutreComponent,
    TabContactsComponent,
    TabAddressComponent,
    TabNotesComponent,
    TabSuiviComponent,
    TabMissionsComponent,
    TabPointeuseComponent,
    TabChantierComponent,
    TabMatchingComponent,
    TabDocComponent,
    IwSendSMSComponent,
    IwSendSMSModalComponent,
    PpempenfComponent,
    SuiviComponent,
    PpcliSideMenuComponent,
    CdetempoSideMenuComponent,
    EmployeSideMenuComponent,
    MissionsComponent,
    TabPpmisGeneralComponent,
    TabPpmisNotesComponent,
    TabPpmisAutreComponent,
    MissionsSidemenuComponent,
    TabPpmisDocsComponent,
    TabPpmisIjComponent,
    TabPpmisSuiviComponent,
    TabPpmisRapportComponent,
    Ppmismdl2Component,
    Ppheusaid2Component,
    PpindemniteComponent,
    RapportsComponent,
    WeekNavigationComponent,
    ListRapportsComponent,
    MisnewComponent,
    PaymentComponent,
    AutoacoComponent,
    PpheusaisComponent,
    MisFactureHoursComponent,
    MisSalaryMonthComponent,
    MisFactureMonthComponent,
    MisheuspcComponent,
    TabMisIndComponent,
    CdetempoComponent,
    TabPpcdetempoAutreComponent,
    TabPpcdetempoGeneralComponent,
    TabPpcdetempoDescremarquesComponent,
    TabPpcdetempoMatchComponent,
    TabPpcdetempoPageSuiviComponent,
    TabPpcdeMissionsComponent,
    TabPpcdetempoPageDocComponent,
    PpopttxtComponent,
    IwTabPpopttxtPage1Component,
    IwTabPpopttxtPage2Component,
    IwTabPpopttxtPage3Component,
    IwTabPpopttxtPage4Component,
    IwTabPpopttxtPage5Component,
    IwTabPpopttxtPage6Component,
    IwTabPpopttxtPage7Component,
    IwTabPpopttxtPage8Component,
    IwTabPpopttxtPage9Component,
    ParametresGenerauxComponent,
    TabPpparGeneralComponent,
    TabPpparClientComponent,
    TabPpparAccomptesComponent,
    TabPpparSalaire1Component,
    TabPpparCommandeComponent,
    TabPpparEmployeComponent,
    TabPpparFromatsComponent,
    TabPpparSalaire2Component,
    TabPpparSignatureComponent,
    CdeNewComponent,
    PpjfcalcComponent,
    LookupsComponent,
    GenresIndemnitesComponent,
    CctConfigComponent,
    TabRaComponent,
    TabPgmComponent,
    TabCctRemarquesComponent,
    TabCctFormationComponent,
    TabCctExecutionComponent,
    TabCctAutreComponent,
    InvoicesComponent,
    InvoicesParametersComponent,
    KeywordConfigComponent,
    SecteurConfigComponent,
    TabRapportRecapComponent,
    TabRapportHeuresComponent,
    TabRapportIndemnitesComponent,
    TabRapportNotesComponent,
    RapportComponent,
    RapportSidemenuComponent,
    SalaryComponent,
    SalaryParametersComponent,
    TabSalaryGenresalComponent,
    TabSalaryDeductionsComponent,
    TabSalaryProvisionsComponent,
    TabSalaryAutreComponent,
    AginterComponent,
    AginterNewComponent,
    Tab14AginterComponent,
    Tab57AginterComponent,
    Tab810AginterComponent,
    Tab1114AginterComponent,
    Tab1518AginterComponent,
    AginterSidemenuComponent,
    TabRemarquesAginterComponent,
    GestionModelesComponent,
    PpacattestComponent,
    TabPpacattesAnnesComponent,
    TabPpacattestPeriodesComponent,
    TabPpacattesHistoriqueMissionComponent,
    TabDerniereMissionComponent,
    PpacattestSidemenuComponent,
    PpacattestNewComponent,
    TabPremiereMissionComponent,
    UserRolesConfigComponent,
    InterijobConfigComponent,
    ModalSalaryCountComponent,
    ModalInvoiceCountComponent,
    ModalInvoiceSessionComponent,
    ModalSalarySessionComponent,
    TabPpcdefixeGeneralComponent,
    CdefixeComponent,
    CdefixeSideMenuComponent,
    TabPpcdefixeDescremarquesComponent,
    TabPpcdefixeMatchComponent,
    TabPpcdeFixePlacementComponent,
    TabPpcdefixeSuiviComponent,
    TabPpcdeFixeDocComponent,
    CdfNewComponent,
    CalendarJoursFeriesComponent,
    EmployeeCreationModeComponent,
    PpempCandidateNewComponent,
    TabPpparRatingComponent,
    ModalSalaryLogNGComponent,
    EmpsituComponent];

// ########################################################
// #################### DEPRECATED ########################
// ########################################################
// ########################################################

/**
 * Interface that list possible components,
 * used to validations at compile time
 */
export interface PlacementComponents {
    ppplf: FormComponentMetadata;
    cdffact: FormComponentMetadata;
    ppclistats: FormComponentMetadata;
    ppcliatt: FormComponentMetadata;
    ppcliflt: FormComponentMetadata;
    ppcli: FormComponentMetadata;
    ppemp: FormComponentMetadata;
    ppcdetempo: FormComponentMetadata;
    ppclichagrd: FormComponentMetadata;
    ppaco: FormComponentMetadata;
    ppmis: FormComponentMetadata;
    ppcctConfig: FormComponentMetadata;
    ppmismdl2: FormComponentMetadata;
    ppdoctype: FormComponentMetadata;
    ppquaConfig: FormComponentMetadata;
    ppgrhConfig: FormComponentMetadata;
    ppagenceConfig: FormComponentMetadata;
    ppcon: FormComponentMetadata;
    aginter: FormComponentMetadata;
    userRolesConfig: FormComponentMetadata;
    ijConfig: FormComponentMetadata;
    ppacattest: FormComponentMetadata;
}

/** Object that lists Component classes to be loaded at runtime */
export const PLACEMENT_COMPONENTS: PlacementComponents = {
    ppplf: {
        type: PlacfixeComponent,
        title: 'Placement fixe'
    },
    cdffact: {type: CdffactComponent},
    ppclistats: {type: PpclistatsComponent},
    ppcliatt: {type: PpcliattComponent},
    ppcliflt: {type: PpclifltComponent},
    ppcli: {type: PpcliComponent},
    ppemp: {type: EmployeeComponent},
    ppcdetempo: {type: CdetempoComponent},
    ppclichagrd: {type: PpclichagrdComponent},
    ppaco: {type: AcompteComponent},
    ppmis: {type: MissionsComponent},
    ppmismdl2: {type: Ppmismdl2Component},
    ppdoctype: {type: PpdoctypeComponent},
    ppcon: {type: PpconComponent},
    ppquaConfig: {type: ProfessionsConfigComponent},
    ppgrhConfig: {type: PpgrhConfigComponent},
    ppagenceConfig: {type: AgenceConfigComponent},
    ppcctConfig: {type: CctConfigComponent},
    aginter: {type: AginterComponent},
    ijConfig: {type: InterijobConfigComponent},
    userRolesConfig: {type: UserRolesConfigComponent},
    ppacattest: {type: PpacattestComponent}
};

export const PLACEMENT_ENTITY_DETAIL: EntityDetail[] = [
    {
        entity: Ppcli,
        component: EntityDetailClientComponent,
        accessRoles: showGridDetailsAccessRoles('ppcli')
    },
    {
        entity: Ppemp,
        component: EntityDetailEmployeeComponent,
        accessRoles: showGridDetailsAccessRoles('ppemp')
    },
    {
        entity: Ppclimis,
        component: EntityDetailMissionComponent,
        accessRoles: showGridDetailsAccessRoles('ppclimis')
    },
    {
        entity: Ppclicdes,
        component: EntityDetailCommandComponent,
        accessRoles: showGridDetailsAccessRoles('ppclicdes')
    },
    {
        entity: Ppclicdfs,
        component: EntityDetailCommandFixeComponent,
        accessRoles: showGridDetailsAccessRoles('ppclicdfs')
    }];
