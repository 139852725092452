import {Component, Input, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {DEBUG, ERROR, FormHandlerService, ModalService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {SaempService} from '@app/sam-base/core/services/saemp.service';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {SaisdefService} from '@app/sam-base/core/services/saisdef.service';
import {ComboboxItem, Npa} from '@app/sam-base/models';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {TranslateService} from '@ngx-translate/core';
import {SalaryForm} from '../../../salary.forms';
import {Recapissd4Component} from './recapissd4/recapissd4.component';
import {ImpotEnum, ModCalcEnum} from './saemp-impot.enums';
import {TabSaempSearchComponent} from './tab-saemp-search.component';

@Component({
    selector: 'iw-tab-saemp-impot',
    templateUrl: './tab-saemp-impot.component.html',
    standalone: false
})
export class TabSaempImpotComponent extends BasePartialFormComponent<Saempc> implements OnInit {

    @Input() public sapar?: Sapar;
    public habOfsnr = '';
    public barc3 = '';
    public exclude = false;
    public baremOptions: ComboboxItem<string>[] = [];
    public noIsOptions: ComboboxItem<string>[] = [
        {
            name: '-----',
            value: ''
        },
        {
            name: this._translate
                .instant(`Droit d'entrée au conseil d'administration,
      résidence à l'étranger, sans impôt ecclésiastique`),
            value: 'HEN'
        },
        {
            name: this._translate
                .instant(`Honoraires CA, résidence à l'étranger, avec taxe d'église`),
            value: 'HEY'
        },
        {
            name: this._translate
                .instant(`Participations de collaborateur,
      résidence à l'étranger, sans impôt ecclésiastique`),
            value: 'MEN'
        },
        {
            name: this._translate
                .instant(`Participations de collaborateur,
      résidence à l'étranger, avec impôt ecclésiastique`),
            value: 'MEY'
        },
        {
            name: this._translate
                .instant(`Non soumis à la retenue à la source sans l'impôt ecclésiastique`),
            value: 'NON'
        },
        {
            name: this._translate
                .instant(`Non soumis à la retenue à la source avec l'impôt ecclésiastique`),
            value: 'NOY'
        },
        {
            name: this._translate
                .instant(`Pour un accord spécial avec la France`),
            value: 'SFN'
        },
        {
            name: this._translate
                .instant('Catégorie ouverte dynamique en texte libre ou avec des numéros'),
            value: 'CatergoryOpen'
        }];
    public impotOptions: ComboboxItem<number>[] = [
        {
            name: ' ',
            value: ImpotEnum.notSelected
        },
        {
            name: this._translate.instant('nonsoumis'),
            value: ImpotEnum.nonSoumis
        },
        {
            name: this._translate.instant('soumis'),
            value: ImpotEnum.soumis
        },
        {
            name: this._translate.instant('soumisFrontalier'),
            value: ImpotEnum.soumisFrontalier
        }];
    public modeCalcOptions: ComboboxItem<number>[] = [
        {
            name: ' ',
            value: 0
        },
        {
            name: this._translate.instant('selonTaux'),
            value: ModCalcEnum.selonTaux
        },
        {
            name: this._translate.instant('autoMensuel'),
            value: ModCalcEnum.autoMensuel
        },
        {
            name: this._translate.instant('autoHorarire'),
            value: ModCalcEnum.autoHorarire
        },
        {
            name: this._translate.instant('autoAnnuel'),
            value: ModCalcEnum.autoAnnuel
        },
        {
            name: this._translate.instant('autoMoisJour'),
            value: ModCalcEnum.autoMoisJour
        },
        {
            name: this._translate.instant('autoMoisNbJour'),
            value: ModCalcEnum.autoMoisNbJour
        },
        {
            name: this._translate.instant('swissdecAnnuel'),
            value: ModCalcEnum.swissdecAnnuel
        },
        {
            name: this._translate.instant('swissdecMensuel'),
            value: ModCalcEnum.swissdecMensuel
        }];
    public empSalIdOptions: ComboboxItem<string>[] = [];

    constructor(private _translate: TranslateService, private _restService: RestApiService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _modalService: ModalService, private _saempService: SaempService,
                private _saemsService: SaemsService, private readonly _saisdefService: SaisdefService) {
        super();
    }

    public get baremHint(): string {
        const barem = this.getFormValue('baremImp') as string;
        const baremImp = barem?.substring(0, 1);
        const baremImpChildren = barem?.substring(1, 2);
        return this._translate.instant(`impot.barem.hint.${baremImp}`, {nbChild: baremImpChildren});
    }

    public get taxIdInvalid(): boolean {
        return this.getFormControl('taxId')?.invalid || false;
    }

    public get taxIdInvalidMessage(): string {
        const taxIdControl = this.getFormControl('taxId');
        if (taxIdControl?.hasError('invalidItalianTaxId')) {
            return 'invalidItalianTaxId';
        }
        return '';
    }

    public get empId() {
        return this.getFormValue<string>('empId');
    }

    public get pays() {
        return this.getFormValue<string>('pays');
    }

    public get isSwissCountry(): boolean {
        return this.pays === 'CH';
    }

    public get enableDrops(): boolean {
        return this.isReadonly || 2 !== this.getFormValue('codeImp');
    }

    public get enableTaux(): boolean {
        return !(2 === this.getFormValue('codeImp') && 1 === this.getFormValue('modcalcimp'));
    }

    public get enableTxImp(): boolean {
        return !(2 !== this.getFormValue('codeImp') && 1 !== this.getFormValue('modcalcimp') && 2003 > Number(this.sapar?.annee));
    }

    public get enableNoIsCodeDropdown(): boolean {
        return !this.isReadonly && 1 !== this.getFormValue('codeImp');
    }

    public get isSoumisImpot(): boolean {
        return this.getFormValue('codeImp') === 2;
    }

    public get enableIsCatOpen(): boolean {
        return this.enableNoIsCodeDropdown && this.getFormValue('noIsCode') === 'CatergoryOpen';
    }

    public get noIsCode(): string {
        return this.getFormValue('noIsCode') || '';
    }

    public get isBaremEditable(): boolean {
        return !this.isReadonly && this.cantonEnabled;
    }

    public get cantonEnabled(): boolean {
        return this.getFormValue('codeImp') !== 1;
    }

    public ngOnInit() {
        const pays = this.getFormValue('pays');
        if (pays === 'CH') {
            this.searchNpa(pays);
        } else {
            this.habOfsnr = (pays ?? '') + ' \\ EX';
        }
        this.exclude = this.getFormValue('emcData') === 'true';
        this.getSalIdByEmpId(this.empId ?? '');
        this.subscribeValueChange('empId', (empId: string | undefined) => {
            this.getSalIdByEmpId(empId ?? '');
        });
        this.getIsBaremesByCanton(this.getFormValue('cantonimp') ?? '');
        this.subscribeValueChange('cantonimp', (val?: string) => {
            this.getIsBaremesByCanton(val ?? '');
        });
    }

    public changeValidators() {
        if (this.enableIsCatOpen) {
            this.formGroup?.controls['noIsCatOpen'].setValidators(Validators.required);
            this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        } else {
            this.formGroup?.controls['noIsCatOpen'].clearValidators();
            this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        }
        this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        // Get the 2 first characters of the noIsCode and set it to the baremImp value
        const noIsCode = this.getFormValue('noIsCode') as string;
        if (noIsCode) {
            this.setFormValue('baremImp', noIsCode.substring(0, 2));
            this.setFormValue('impathee', noIsCode.substring(2, 3)
                ?.toUpperCase() === 'N' ? true : false);
            this.formGroup?.controls['baremImp'].updateValueAndValidity();
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            this.isSoumisImpot ? this.formGroup?.controls['baremImp'].setValidators(Validators.required) : this.formGroup?.controls['baremImp'].clearValidators();
            this.formGroup?.controls['baremImp'].updateValueAndValidity();
        }
    }

    // eslint-disable-next-line complexity
    public checkValidation(event: number) {
        if (event === 2) {
            this.barc3 = 'IS' + this.getFormValue('cantonimp') + ':' + this.getFormValue('baremImp') + (this.getFormValue('impathee') ? 'N' : 'Y') + ':' + this.getFormValue('modcalcimp');
        }
        if (this.enableIsCatOpen) {
            this.formGroup?.controls['noIsCatOpen'].setValidators(Validators.required);
            this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        } else {
            this.formGroup?.controls['noIsCatOpen'].clearValidators();
            this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        }
    }

    // eslint-disable-next-line complexity
    public onCodeImpChange(event: number) {
        if (event === ImpotEnum.soumis || event === ImpotEnum.soumisFrontalier) {
            this.barc3 = 'IS' + this.getFormValue('cantonimp') + ':' + this.getFormValue('baremImp') + (this.getFormValue('impathee') ? 'N' : 'Y') + ':' + this.getFormValue('modcalcimp');
            if (!this.noIsCode) {
                this.formGroup?.controls['baremImp'].setValidators(Validators.required);
                this.formGroup?.controls['cantonimp'].setValidators(Validators.required);
                this.formGroup?.controls['baremImp'].setValidators(Validators.required);
            } else {
                this.formGroup?.controls['baremImp'].clearValidators();
            }
            this.formGroup?.controls['baremImp'].updateValueAndValidity();
        }
        if (this.enableIsCatOpen) {
            this.formGroup?.controls['noIsCatOpen'].setValidators(Validators.required);
            this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        } else {
            this.formGroup?.controls['noIsCatOpen'].clearValidators();
            this.formGroup?.controls['noIsCatOpen'].updateValueAndValidity();
        }
        if (event === ImpotEnum.nonSoumis) {
            this.formGroup?.get('baremImp')
                ?.setValue('');
            this.formGroup?.get('noIsCode')
                ?.disable();
            this.formGroup?.get('noIsCode')
                ?.setValue('');
        } else {
            this.formGroup?.get('noIsCode')
                ?.enable();
        }

        // Handle canton habitation validators
        {
            if (event === ImpotEnum.soumis) {
                this.formGroup?.controls['cantonhab'].setValidators(Validators.required);
            } else {
                this.formGroup?.controls['cantonhab'].clearValidators();
            }
            this.formGroup?.controls['cantonhab'].updateValueAndValidity();
        }
    }

    public setExclude(event: boolean) {
        this.setFormValue('emcData', event);
    }

    public openBtnSD4() {
        if (!this.empId) {
            return;
        }
        this._formHandler.showFormDialog(SalaryForm.ParametresSD4, {
            empId: this.empId,
            annne: this.sapar?.annee
        });
    }

    public async openSearch() {
        try {
            const result: Saempc | undefined = await this._modalService.showModal(TabSaempSearchComponent, this.getFormData());

            this.setFormValue('tauxImp', result?.tauxImp);
        } catch {
            return;
        }
    }

    public calculateMaj() {
        if (this.empId && this.sapar?.annee) {
            this._saempService.getMajISDM(this.empId, this.sapar?.annee)
                .subscribe({
                    next: () => DEBUG('Success doing the procedure majISDMois'),
                    error: () => ERROR('Error calling the procedure majISDMois')
                });
        }
    }

    public async openRecapissd4() {
        try {
            await this._modalService.showModal(Recapissd4Component, {
                empId: this.empId,
                annee: this.sapar?.annee
            });
        } catch (error) {
            DEBUG('Gscpt modal closed');
        }
    }

    private searchNpa(pays: string) {
        const npa: string | undefined = this.getFormValue('npa');
        if (npa) {
            this._restService
                .getEntityClient(Npa)
                .getById(npa)
                .subscribe({
                    next: (npaData) => this.setHabOfsnr(pays, npaData),
                    error: () => ERROR('The npa doesn\'t exist')
                });
        }
    }

    private setHabOfsnr(pays: string, npa?: Npa) {
        if (npa) {
            this.habOfsnr = pays + ' \\ ' + this.getFormValue('cantonhab') + ' \\ ' + this.getOfsnr(npa);
        } else {
            this.habOfsnr = '';
        }
    }

    private getIsBaremesByCanton(txCode: string) {
        this.baremOptions.length = 0;
        this._saisdefService.getIsBaremesByCanton(txCode)
            .subscribe((data: string) => {
                if (data) {
                    const dataInfo = data.split(',');
                    dataInfo.forEach((element) => {
                        this.baremOptions.push({
                            name: element,
                            value: element
                        });
                    });
                }
            });
    }

    private getSalIdByEmpId(empId: string) {
        this.empSalIdOptions = [];
        this._saemsService.getSalIdsByEmpId(empId)
            .subscribe((salIds: string[]) => {
                if (salIds !== undefined) {
                    if (salIds.length > 0) {
                        this.empSalIdOptions.push({
                            name: ' ',
                            value: ''
                        });
                    }
                    salIds.forEach((salId) => {
                        this.empSalIdOptions.push({
                            name: salId,
                            value: salId
                        });
                    });
                }
            });
    }

    private getOfsnr(npa: Npa): string {
        const ofsnr: number | undefined = this.getFormValue('ofsnr');
        return ofsnr && ofsnr !== 0 ? ofsnr.toString() : npa.ofsnr?.toString() ?? '';
    }
}

function strToCbp(lst: string[]): ComboboxItem<string>[] {
    return lst.map((e) => <ComboboxItem<string>>{
        value: e,
        name: e
    });
}
