import {Component, Input, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {FormHandlerService, IwStoreService, ModalService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SaempService} from '@app/sam-base/core/services/saemp.service';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {SaepService, SaepSubscription} from '@app/sam-base/core/services/saep.service';
import {IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {Saemp} from '@app/sam-base/models/salary/saemp';
import {Saems} from '@app/sam-base/models/salary/saems';
import {SaepView} from '@app/sam-base/models/salary/saepview';
import {TranslateService} from '@ngx-translate/core';
import {PayType} from '@sam-base/models/salary/payment-type-enum';
import {Subject, takeUntil} from 'rxjs';

import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saep-paiment',
    templateUrl: './tab-saep-paiment.component.html',
    standalone: false
})
export class TabSaepPaimentComponent extends BasePartialFormComponent<Saems> implements OnInit, OnDestroy {


    @ViewChild('saepGrid') public saepGrid?: IwRestGridComponent<SaepView>;
    public type: Type<SaepView> = SaepView;
    public columns = this.getColumns();
    public selectedRow?: SaepView;
    public query: RestQueryParam<SaepView, string>[] = [];

    @Input() public isReadonly = true;
    public isSaving = false;
    public newEntryMode = false;

    @Input() public uuid?: string;

    @Input() public saemp?: Saemp;

    public empId?: string;
    public saems: Saems | undefined;
    public subscriptions = new Subject();

    private readonly _restSaemsClient: RestEntityClient<Saems>;

    constructor(private _translate: TranslateService, private readonly _saemsService: SaemsService,
                private readonly _modalService: ModalService, private _restService: RestApiService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _store: IwStoreService,
                private readonly _saempService: SaempService, private readonly _saepService: SaepService) {
        super();
        this._restSaemsClient = this._restService.getEntityClient(Saems);
    }

    public get canEditSaep(): boolean {
        return this.selectedRow?.payType != PayType.dta || (this.selectedRow?.payType === PayType.dta && !this.selectedRow?.paid) || false;
    }

    public get canDelete(): boolean {
        return this.selectedRow?.payType != PayType.dta && !this.selectedRow?.paid;
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public ngOnInit(): void {
        this.setQuery();
        this.subscribeValueChange('id', () => this.setQuery());
        this.getSaempPayType();
        this._saepService.saepSavedSub
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: SaepSubscription) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.saepGrid?.refresh();
                }
            });
    }

    public getSelectedRow(event: SaepView[]) {
        this.selectedRow = event[0];
    }

    public createNewEntry() {
        this.selectedRow = {};
        this.newEntryMode = true;
        if (this.empId) {
            this._formHandler.showFormDialog(SalaryForm.FichePaiement, {
                epId: '',
                empId: this.getFormValue('empId'),
                salId: this.getFormValue('salId'),
                parentUuid: this.uuid,
            }, s => ({
                ...s,
                editMode: 'edit',
                isNew: true,
            }));
        }
    }

    public modifyEntry(): void {

        if (this.empId) {
            this.newEntryMode = false;
            if (this.selectedRow) {
                if (this.selectedRow.epId) {
                    this.openSaep(this.selectedRow);
                }
            }
        }
    }

    public removeEntry(): void {
        this._saemsService.saveSaemsCalculated(this.selectedRow?.empId, this.saems?.salId, false);

        if (this.selectedRow?.epId) {
            this._saepService.deleteEntry(this.selectedRow?.epId)
                .subscribe({
                    next: () => {
                        this._saepService.saepSavedSub.next({
                            uuid: this.uuid || '',
                            saved: true
                        });
                    }
                });
        }
    }

    public onPaiementDoubleClick($event: RowClickEvent<SaepView>) {
        if (!$event.row.epId) return;
        this.openSaep($event.row);
    }

    private openSaep(saep: SaepView) {
        this._formHandler.showFormDialog(SalaryForm.FichePaiement, {
            epId: saep.epId,
            parentUuid: this.uuid
        }, s => ({
            ...s,
            editMode: 'read',
            isNew: false,
            entityId: saep.epId!,
            readonly: this.isReadonly,
        }));
    }

    private getSaempPayType() {
        this.empId = this.getFormValue('empId');
        if (this.empId) {
            this._saempService.getSaempPayTypeByEmpId(this.empId);
        }
    }

    private getColumns(): IwGridColumn<SaepView>[] {
        return [
            {
                prop: 'datePmnt',
                name: this._translate.instant('date'),
                index: 0
            },
            {
                prop: 'paid',
                type: 'boolean',
                name: this._translate.instant('paye'),
                index: 1
            },
            {
                prop: 'montant',
                name: this._translate.instant('montant'),
                index: 2,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'payType',
                name: 'Ty',
                index: 3
            },
            {
                prop: 'paiement',
                name: this._translate.instant('paiement'),
                index: 4
            },
            {
                prop: 'texte',
                name: this._translate.instant('Texte'),
                index: 5
            },
            {
                prop: 'origine',
                name: 'O',
                index: 6
            },
            {
                prop: 'parentId',
                name: this._translate.instant('parent'),
                index: 7
            },
            {
                prop: 'epId',
                name: this._translate.instant('id'),
                index: 8
            }];
    }

    private setQuery() {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: this.getFormValue('empId') || ''
            },
            {
                operation: RestQueryOperation.Equals,
                prop: 'salId',
                value: this.getFormValue('salId') || ''
            }];
    }
}
