import {HttpClient} from '@angular/common/http';

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ToastService} from '@app/sam-base/core/toast';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';
import {environment} from '@root/environments/environment';
import {IwStoreService} from '@sam-base/core';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {AnswerType} from '@sam-base/models/admin/answer-type.enum';
import {DialogResponseState} from '@sam-base/models/admin/dialog-response-state.enum';
import {DialogResponse} from '@sam-base/models/admin/dialog-response.model';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {SxNotificationBundle} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxProcessFlow} from '@sam-base/models/admin/sx-process-flow.enum';
import {Subject} from 'rxjs';

/**
 * Tab Dialog of swissdec institution in version 5
 */
@Component({
    selector: 'iw-tab-sasx5institution-dialog',
    templateUrl: './tab-sasx5institution-dialog.component.html',
    standalone: false
})
export class TabSasx5institutionDialogComponent extends BasePartialFormComponent<Sasx5institution> implements OnInit, OnDestroy {

    @Input() public uuid = '';
    @Output() public institutionUpdated = new EventEmitter<Sasx5institution>();
    public ANSWER_TYPE = AnswerType;
    public DIALOG_RESPONSE_STATE = DialogResponseState;
    public yesNoOptions: RadioItem<string | boolean>[] = [
        {
            label: 'yes',
            value: 'YES'
        },
        {
            label: 'no',
            value: 'NO'
        },
        {
            label: 'unknown',
            value: 'UNKNOWN'
        }
    ];
    protected readonly DialogResponseState = DialogResponseState;
    protected readonly AnswerType = AnswerType;
    protected readonly SamUserRole = SamUserRole;
    protected readonly SxProcessFlow = SxProcessFlow;
    private readonly _sasx5institutionRest: RestEntityClient<Sasx5institution>;
    private subscriptions = new Subject();

    constructor(private readonly _restService: RestApiService, private readonly _toast: ToastService,
                private readonly _store: IwStoreService,
                // private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _http: HttpClient) {
        super();
        this._sasx5institutionRest = _restService.getEntityClient(Sasx5institution);
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get hasNotificationToShow(): boolean {
        const notifications = this.notifications;
        return this.hasErrors(notifications) || this.hasWarnings(notifications) || this.hasInfos(notifications);
    }

    public get notifications(): SxNotificationBundle | undefined {
        return this.getFormValue('notifications');
    }

    public get getDialogDisabled() {
        return this.getFormValue('processFlow') !== SxProcessFlow.DIALOG_REQUESTING;
    }

    public get processFlow(): SxProcessFlow | undefined {
        return this.getFormValue('processFlow');
    }

    public get isSaveDialogDisabled() {
        return this.getFormValue('processFlow') === SxProcessFlow.DIALOG_FILLING;
    }

    public get dialogResponse(): DialogResponse | undefined {
        return this.getFormValue('dialogJson');
    }

    public get isReplyDialogDisabled() {
        // eslint-disable-next-line max-len
        const sections = this.dialogResponse?.dialogMessages?.flatMap((dialogMessage) => dialogMessage.sections);
        // console.log('Sections:', sections);
        const paragraphs = sections?.flatMap((section) => section?.paragraphs);
        // console.log('Paragraphs:', paragraphs);
        // eslint-disable-next-line max-len
        const mandatoryFieldsMissing = paragraphs?.filter((paragraph) => !!paragraph && !paragraph.readonly && !paragraph.optional && paragraph.type !== AnswerType.BOOLEAN && paragraph.type !== AnswerType.LABEL && !paragraph.value);
        // console.log('Mandatory fields missing:', mandatoryFieldsMissing);
        const disableReplyState = this.getFormValue('processFlow') !== SxProcessFlow.DIALOG_FILLING || paragraphs?.length === 0 // if no answer to respond to
            || mandatoryFieldsMissing?.length || 0 > 0;
        // console.log('Disable reply state:', disableReplyState);
        return disableReplyState;
    }

    public get isPollDialogDisabled() {
        return this.getFormValue('processFlow') !== SxProcessFlow.DIALOG_PROCESSING;
    }

    public ngOnInit() {
        this.getDialogMessage();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getDialogMessage(forced: boolean = false) {
        this._store.dispatch(new SetLoading(this.uuid, true));
        return this._http.get<Sasx5institution>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/get_dialog`, {params: {forced}})
            .subscribe(institution => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this.institutionUpdated.emit(institution);
            }, (err) => {
                this._store.dispatch(new SetLoading(this.uuid, false));
            });

    }

    public saveDialogMessage() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        return this._http.put<Sasx5institution>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/dialog`, this.dialogResponse)
            .subscribe(institution => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this._toast.success('save_dialog_success');
                this.institutionUpdated.emit(institution);
            }, (err) => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this._toast.error('save_dialog_error');
            });
    }

    public replyDialog() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        return this._http.post<Sasx5institution>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/reply_dialog`, this.dialogResponse)
            .subscribe(inst => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this.institutionUpdated.emit(inst);
                if (inst?.dialogJson?.state === DialogResponseState.ERROR) {
                    this._toast.error("swissdec.reply_dialog_error");
                }
            }, (err) => {
                this._store.dispatch(new SetLoading(this.uuid, false));
            });
    }

    public pollDialog() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        return this._http.post<Sasx5institution>(environment.backendURL + `swissdec/institutions/${(this.entityId)}/poll_dialog`, null)
            .subscribe(inst => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this.institutionUpdated.emit(inst);
            }, (err) => {
                this._store.dispatch(new SetLoading(this.uuid, false));
            });
    }

    private hasErrors(notifications: SxNotificationBundle | undefined): boolean {
        return (notifications?.errors?.length || 0) > 0;
    }

    private hasWarnings(notifications: SxNotificationBundle | undefined): boolean {
        return (notifications?.warnings?.length || 0) > 0;
    }

    private hasInfos(notifications: SxNotificationBundle | undefined): boolean {
        return (notifications?.infos?.length || 0) > 0;
    }
}
