import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService} from '@app/sam-base/core';
import {RestQueryParam} from '@app/sam-base/core/rest-api';
import {GepaicondService} from '@app/sam-base/core/services/gepaicond.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Gepaicond} from '@app/sam-base/models/invoices/gepaicond';

@Component({
    templateUrl: './gepaicond.component.html',
    standalone: false
})
export class GepaicondComponent extends BaseFormComponent<Gepaicond> implements OnInit {

    @ViewChild('gepaicondGrid') public gepaicondGrid?: IwRestGridComponent<Gepaicond>;
    public type: Type<Gepaicond> = Gepaicond;
    public query: RestQueryParam<Gepaicond, string | string>[] = [];
    public tableData: Gepaicond[] = [];
    public selectedElement?: Gepaicond | undefined;

    public paiCond = '';
    public titre = '';
    public nbjours = '';
    public paiCdText = '';

    public tableColumns: IwGridColumn<Gepaicond>[] = [
        {
            prop: 'paiCondId',
            name: 'code',
            index: 0
        },
        {
            prop: 'libelle',
            name: 'titre',
            index: 1
        },
        {
            prop: 'nbjours',
            name: 'NBJours',
            index: 2,
            decimals: 2,
            type: 'mnt'
        },
        {
            prop: 'paiCdText',
            name: 'text',
            index: 3,
            hidden: true
        }];

    constructor(private _gepaicondService: GepaicondService, protected readonly _store: IwStoreService) {
        super();
    }

    private _isNew = false;

    public get isNew(): boolean {
        return this._isNew;
    }

    private _isEditing = false;

    public get isEditing(): boolean {
        return this._isEditing;
    }

    public get isSelected(): boolean {
        return this.selectedElement !== undefined;
    }

    public ngOnInit(): void {
        this.loadGepaicondTable();
    }

    public newEntry() {
        this._isEditing = true;
        this._isNew = true;
        this.selectedElement = undefined;
        this.setFormValue('paiCondId', '');
        this.setFormValue('libelle', '');
        this.setFormValue('nbjours', '');
        this.setFormValue('paiCdText', '');
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public selectedChanged(gepaicond: Gepaicond[]) {
        if (gepaicond.length > 0) {
            this.selectedElement = gepaicond[0];
            this.setFormValue('paiCondId', gepaicond[0].paiCondId);
            this.setFormValue('libelle', gepaicond[0].libelle);
            this.setFormValue('nbjours', gepaicond[0].nbjours);
            this.setFormValue('paiCdText', gepaicond[0].paiCdText);
        } else {
            this.selectedElement = undefined;
        }
    }

    public saveEntry() {
        this._gepaicondService.save(this.getFormData())
            .subscribe({
                next: () => this.loadGepaicondTable()
            });
        this._isEditing = false;
        this.setFormValue('paiCondId', '');
        this.setFormValue('libelle', '');
        this.setFormValue('nbjours', '');
        this.setFormValue('paiCdText', '');
    }

    public cancelEditionMode() {
        this._isEditing = false;
        this._isNew = false;
    }

    public editEntry() {
        this._isEditing = true;
    }

    public deleteEntry() {
        if (this.selectedElement) {
            this._gepaicondService.delete(this.selectedElement.paiCondId)
                .subscribe({
                    next: () => this.loadGepaicondTable()
                });
        }
    }

    protected getFormControlNames(): FormKeys<Gepaicond> {
        return [
            'paiCondId',
            'libelle',
            'nbjours',
            'paiCdText'];
    }

    private loadGepaicondTable() {
        this.selectedElement = undefined;
        this._gepaicondService.getAll()
            .subscribe({
                next: (data: Gepaicond[]) => this.tableData = data
            });
    }

    private refreshGrid() {
        this._isNew = false;
        this._isEditing = false;
        this.gepaicondGrid?.refresh();
        this.selectedElement = undefined;
    }

}
