import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwTextFieldComponent} from '@app/sam-base/components/iw-textfield/iw-textfield.component';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {Ppmis} from '@app/sam-base/models/placement';
import {
    MissionCalcApplyCoeffResponse, MissionCalcCCTSup, MissionCalcTarifResponse, SalaryDeduction
} from '@app/sam-base/models/placement/mission-calculations-result.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {ModalService} from '@sam-base/core';
import {RestEntityClient} from '@sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@sam-base/core/rest-api/rest-api.service';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './mis-fac-month.component.html', selector: 'iw-mis-fact-month',
    standalone: false
})
export class MisFactureMonthComponent extends BasePartialFormComponent<Ppmis> implements OnInit, AfterViewInit, OnDestroy {

    public get cctId() {
        return this.getFormValue<string>('cctId');
    }

    @ViewChild('txtSupCCT') public txtSupCCT?: IwTextFieldComponent;

    @ViewChild('txtTarifTotal') public txtTarifTotal?: IwTextFieldComponent;

    @Output() public calculating: EventEmitter<boolean> = new EventEmitter();

    public subscriptions = new Subject();
    private _ppmisRestClient: RestEntityClient<Ppmis>;

    constructor(private readonly _calcService: MissionCalculationsService, private readonly _restClient: RestApiService, private readonly _modalService: ModalService, private readonly _translate: TranslateService) {
        super();
        this._ppmisRestClient = _restClient.getEntityClient(Ppmis);
    }

    public ngOnInit() {
        this.subscribeCalcTotal();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            this.subscribeCalculateCCTSup();
            this.initForm();
            this.subscribeValueChange('misId', () => this.initForm());
            this.subscribeValueChange('clitarifra', () => this.calculateTotal());
        }, 500);
    }


    public onCalcCCT() {
        if (this.isReadonly) {
            return;
        }
        if (!this.cctId) {
            return;
        }
        if (!this.txtSupCCT) {
            return;
        }
        const val = Number(this.txtSupCCT.getValue()) || 0;
        this.setFormValue('clitarifra', val);
    }

    public triggerReadyToCalculate() {
        if (this.isReadonly) {
            return;
        }
        this.setFormValue('isCalculating', true);
    }


    public calculateTotal() {
        if (this.isReadonly) {
            return;
        }
        if (!this.formGroup) {
            return;
        }

        setTimeout(async () => {
            const res = await lastValueFrom(this.getCalculationTotal());
            if (typeof res !== 'boolean') {
                this.applyCalculations(res);
            }
        }, 500);
    }

    protected setSupCCT(val?: string | number, changeCliTarif = true) {
        if (this.txtSupCCT) {
            const value = typeof val === 'number' ? '' + val : val;
            this.txtSupCCT.setValue(value);
        }
        if (this.getFormValue('clitarifra') !== 0 && changeCliTarif) {
            this.setFormValue('clitarifra', val);
        }
    }

    protected setTarifTotal(val?: string | number) {
        if (this.txtTarifTotal) {
            const value = typeof val === 'number' ? '' + val : val;
            this.txtTarifTotal.value = value;
        }
    }

    private initForm() {
        this.initialCalc();
        this.calculateCCTSup();
    }

    private subscribeCalcTotal() {
        this.subscribeValueChange('misId', () => this.initialCalc(), 200);
    }

    private initialCalc() {
        setTimeout(() => {
            this.getCalculationTotal()
                .subscribe(val => {
                    if (typeof val !== 'boolean') {
                        this.setTarifTotal(val.clitariftotal);
                    }
                    this.calculating.emit(false);
                });
        }, 0);
    }

    private getCalculationTotal() {
        this.calculating.emit(true);
        if (!this.txtTarifTotal) {
            return of(false);
        }

        const value: number = Number(this.getFormValue<string>('clitarif')) || 0;
        const cct: number = Number(this.getFormValue<string>('clitarifra')) || 0;
        const total: number = Number(this.txtTarifTotal.getValue()) || 0;

        return this._calcService
            .calculateInvoice(value, cct, total, this.getAllDeductionFields())
            .pipe(catchError(err => {

                const msg = [this._translate.instant('calculation_fail_message_1'), this._translate.instant('calculation_fail_message_2'), this._translate.instant('calculation_fail_message_3'), this._translate.instant('calculation_fail_message_4')];

                const optionsCalcError: ModalMessageOptions = {
                    message: msg,
                    showCancel: false,
                    title: this._translate.instant('topmenu_salaries'),
                    confirmMessage: this._translate.instant('calculation_fail_message'),
                    okDisabled: false,
                    multiline: true
                };
                this._modalService
                    .showModal(ModalMessageComponent, optionsCalcError);

                const invMonthGroup: string[] = ['clitarifra', 'clitarif'];

                const misId = this.getFormValue<string>('misId');
                if (!misId) {
                    return of(false);
                }
                this._ppmisRestClient
                    .getById(misId)
                    .subscribe(ppmis => {
                        const formGroup = this.formGroup?.controls as object;
                        Object.keys(formGroup)
                            .forEach(key => {
                                if (invMonthGroup.includes(key)) {
                                    this.setFormValue(key as keyof Ppmis, ppmis[key as keyof Ppmis]);
                                }
                            });
                        this.txtTarifTotal?.setValue(((ppmis.clitarif || 0) + (ppmis.clitarifra || 0)).toString());
                    });
                this.calculating.emit(false);

                return of(false);
            }));
    }

    // eslint-disable-next-line complexity
    private calculateCCTSup() {
        const salGlobal: number | undefined = this.getFormValue('salGlobal');
        const cctRa: boolean | undefined = this.getFormValue('cctRa');
        const cctEx: boolean | undefined = this.getFormValue('cctEx');
        const cctFc: boolean | undefined = this.getFormValue('cctFc');
        const cctId: string | undefined = this.getFormValue('cctId');

        if (salGlobal !== undefined && cctRa !== undefined && cctEx !== undefined && cctFc !== undefined && cctId !== undefined) {
            const data: MissionCalcCCTSup = {
                salGlobal, cctRa, cctEx, cctFc, cctId
            };
            this._calcService.calculateCCTSup(data)
                .subscribe(value => this.setSupCCT(value, false));
        }
    }

    private subscribeCalculateCCTSup() {
        this._calcService.supCttCalculation
            .pipe(takeUntil(this.subscriptions))
            .subscribe(value => {
                if (value.misId === this.getFormValue('misId')) {
                    this.setSupCCT(value.supCct, false);
                }
            });
    }

    // eslint-disable-next-line complexity
    private getAllDeductionFields(): SalaryDeduction {
        return {
            ageId: this.getFormValue('ageId') || '',
            empId: this.getFormValue('empId') || '',
            codepgm: this.getFormValue('codepgm') || '',
            codecna: this.getFormValue('codecna') || '',
            cctRa: this.getFormValue('cctRa') || false,
            cctEx: this.getFormValue('cctEx') || false,
            cctFc: this.getFormValue('cctFc') || false,
            mntFrais1: this.getFormValue('mntFrais1') || 0,
            mntFrais2: this.getFormValue('mntFrais2') || 0,
            cct: this.getFormValue('cctId') || '',
            misId: this.getFormValue('misId') || '',
            salGlobal: this.getFormValue('salGlobal') || 0,
            missalmode: this.getFormValue('missalmode') || 1
        };
    }

    private applyCalculations(result: MissionCalcTarifResponse | MissionCalcApplyCoeffResponse) {
        if (!this.formGroup) {
            this.calculating.emit(false);
            return;
        }

        this.applyFormControls(result);
        this.setTarifTotal(result.clitariftotal);
        this.calculating.emit(false);
    }

    private applyFormControls(result: any) {
        for (const k of Object.keys(result)) {
            if (this._calcService.canApplyFormcontrol(k, result, this.formGroup)) {
                if (k === 'clitarifra') {
                    this._calcService.supCttCalculation.next({
                        misId: this.getFormValue('misId') ?? '', supCct: result[k]
                    });
                } else {
                    this.setFormValue(<any>k, result[k]);
                }
            }
        }
    }
}
