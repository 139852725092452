import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestClient} from '@app/sam-base/core/rest-api/core';
import {Template, TemplateModule} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';
import {Observable} from 'rxjs';


@Injectable()
export class TemplateService extends RestClient<any> {

    constructor(public readonly _http: HttpClient) {
        super(_http);
    }

    /**
     * Create template
     */
    public saveTemplate(module: TemplateModule, formData: any): Observable<Template> {
        return this.POST<Template>(formData, undefined, `template/${module}`);
    }

    /**
     * Update template
     */
    public updateTemplate(module: TemplateModule, id: number, formData: any): Observable<Template> {
        return this.PUT<Template>(formData, undefined, `template/${module}/${id}`);
    }

    public deleteTemplate(module: TemplateModule, id: number): Observable<Template> {
        return this.DELETE<Template>(undefined, `template/${module}/${id}`);
    }

    /**
     * Get template file
     */
    public getTemplateFile(module: TemplateModule, id: number): Observable<ArrayBuffer> {
        return this._http.get<ArrayBuffer>(environment.backendURL + `template/${module}/${id}/file`, {responseType: 'arraybuffer' as 'json'});
    }

    public getReportsByModule(module: TemplateModule): Observable<string[]> {
        return this._http.get<string[]>(environment.backendURL + `template/reports/${module}`);
    }

}
