import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {IwGridColumn} from '@app/sam-base/models';
import {Ppind, Pprap} from '@app/sam-base/models/placement';

/**
 * Tab Employe of parametres Generaux - SAM-4837
 */
@Component({
    selector: 'iw-tab-rapport-indemnites', templateUrl: './tab-rapport-indemnites.component.html',
    standalone: false
})
export class TabRapportIndemnitesComponent extends BasePartialFormComponent<Pprap> implements OnInit {
    public type = Ppind;
    public query: RestQueryParam<Ppind, string>[] = [];
    public columns = this.getIndColumns();

    constructor() {
        super();
    }

    public ngOnInit() {
        if (this.getFormValue('rapId')) {
            this.setIndemnitesQuery();
        }
        this.subscribeValueChange('rapId', () => {
            this.setIndemnitesQuery();
        });
    }

    private setIndemnitesQuery() {
        this.query = [{
            prop: 'rapId', operation: RestQueryOperation.Equals, value: this.getFormValue('rapId') ?? ''
        }, {
            prop: 'indType', operation: RestQueryOperation.Equals, value: '1'
        }];
    }

    private getIndColumns(): IwGridColumn<Ppind>[] {
        return [{
            prop: 'libelle', name: 'libelle', index: 0
        }, {
            prop: 'qte', name: 'qte', index: 1
        }, {
            prop: 'mntemp', name: 'mnt_emp', index: 2, decimals: 2
        }, {
            prop: 'mntcli', name: 'mntcli', index: 3, decimals: 2
        }, {
            prop: 'totemp', name: 'totEmp', index: 4, decimals: 2
        }, {
            prop: 'totcli', name: 'totCli', index: 5, decimals: 2
        }, {
            prop: 'griId', name: 'code', index: 6
        }];
    }
}
