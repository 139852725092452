import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {FormHandlerService, GridProfile} from '@app/sam-base/core';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {RadioItem} from '@sam-base/models/components/radio-item.model';
import {Ppemp} from '@sam-base/models/placement';

import {PlacementForm} from '../../../placement.forms';
import {EmployeeCreationMode, EmployeeCreationType} from './employee-creation-mode.model';

@Component({
    templateUrl: './employee-creation-mode.component.html',
    standalone: false
})
export class EmployeeCreationModeComponent extends BaseFormComponent<EmployeeCreationMode> {

    public get profile(): GridProfile<Ppemp> | undefined {
        return this.getData('profile');
    }

    public radioListValue?: EmployeeCreationType;

    public employeeTypeOptions: RadioItem<string>[] = this.iniatializeRadioOptions();

    constructor(private _entityNavService: EntityNavigationService, private _store: IwStoreService, private _formHandler: FormHandlerService<PlacementForm>) {
        super();
    }

    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public openCreationForm() {
        this.closeDialog();
        if (this.radioListValue === 'normal') {
            this._entityNavService.navigateToNew(Ppemp, this.profile);
        } else {
            this._formHandler.showFormDialog(PlacementForm.PpempCandidateCreate, {profile: this.profile});
        }
    }

    protected getFormControlNames(): FormKeys<EmployeeCreationMode> {
        return [];
    }

    private iniatializeRadioOptions() {
        this.radioListValue = 'candidate';

        return [{
            label: 'ppemp_create_candidate', value: 'candidate', checked: true
        }, {
            label: 'ppemp_create_normal', value: 'normal', checked: false
        }];
    }
}
