import {Component, OnDestroy} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BaseFormComponent} from '@sam-base/base';
import {SamComponentsModule, SortInfo} from '@sam-base/components';
import {
    EntityCreate,
    EntityDelete,
    EntityUpdate,
    IwStoreService,
    RestApiService,
    RestEntityClient,
    RestQueryOperation,
    RestQueryParam
} from '@sam-base/core';
import {TeamService} from '@sam-base/core/services/team.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {FormKeys, IwGridColumn, sortGridList, TableSortEvent} from '@sam-base/models';
import {SamUser} from '@sam-base/models/admin/sam-user';
import {Team} from '@sam-base/models/admin/team.model';
import {Ppcon} from '@sam-base/models/placement';
import {FormComponentsModule} from '@shared/widgets/form-components/form-components.module';

@Component({
    imports: [
        FormComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        SamComponentsModule,
        TranslateModule
    ],
    templateUrl: './user-teams-config.component.html'
})
export class UserTeamsConfigComponent extends BaseFormComponent<Team> implements OnDestroy {

    public type = Team;
    public selectedRow?: Team;
    public isReadonly = true;
    public newEntryMode = false;
    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Team, string>[] = [
        {
            prop: 'teamId',
            operation: RestQueryOperation.NotEquals,
            value: '0'
        }];
    public sortProp?: keyof Team;
    public sortPropDir?: 'asc' | 'desc';
    public columnsMember = this.getColumnsMember();
    public members: SamUser[] = [];
    public columnsConseillers = this.getColumnsConseillers();
    public conseillers: Ppcon[] = [];
    private _isSaving = false;
    private _teamId = 0;
    private readonly _restTeamClient: RestEntityClient<Team>;
    private tableSortEvent?: TableSortEvent<SamUser>;

    constructor(rest: RestApiService, private _store: IwStoreService,
                private _toastService: ToastService,
                private _teamService: TeamService,
                private _translate: TranslateService) {
        super();
        this._restTeamClient = rest.getEntityClient(Team);
    }

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {
            name: 'teamId',
            value: this.selectedRow.teamId
        };
    }

    public getSelectedRow(event: Team[]) {
        this.isReadonly = true;

        if (this._isSaving) {
            // Prevents clearing of the selectedRow
            this._isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this._teamId = (event[0]?.teamId) ?? 0;
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);

        // load sam-users linked to that team
        this._teamService.getTeamMembers(this.selectedRow?.teamId!).subscribe({
            next: users =>
                this.members = users,
            error: err => this._toastService.error('error_team_get_members')
        })

        // load conseillers linked to that team
        this._teamService.getConseillers(this.selectedRow?.teamId!).subscribe({
            next: conseillers =>
                this.conseillers = conseillers,
            error: err => this._toastService.error('error_team_get_conseillers')
        })
    }

    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
        this.members = [];
    }

    public modifyEntry() {
        if (this.selectedRow?.teamId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restTeamClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        this._isSaving = true;

        if (this.newEntryMode) {
            this._store.dispatch(new EntityCreate(updatedData));
        } else {
            this._store.dispatch(new EntityUpdate(updatedData));
        }
        this.newEntryMode = false;
    }

    public removeEntry() {
        if (this.selectedRow?.teamId) {
            this._store.dispatch(new EntityDelete(Team, this._teamId));
        }
    }

    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Team>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    public onSort($event?: TableSortEvent<SamUser>) {
        this.tableSortEvent = $event;
        if (!$event) return;
        this.members = [...sortGridList(this.members, $event)];
    }

    protected getValidationType() {
        return Team;
    }

    protected getFormControlNames(): FormKeys<Team> {
        return [
            'teamId',
            'name'
        ];
    }

    private getColumnsMember(): IwGridColumn<SamUser>[] {
        return [
            {
                prop: 'username',
                name: 'utilisateur',
                type: 'string',
                index: 0
            },
            {
                prop: 'firstName',
                name: 'prenom',
                type: 'string',
                index: 1
            },
            {
                prop: 'lastName',
                name: 'nom',
                type: 'string',
                index: 2,
            },
            {
                prop: 'email',
                name: 'email',
                type: 'string',
                index: 3,
            }
        ];
    }

    private getColumnsConseillers(): IwGridColumn<Ppcon>[] {
        return [
            {
                prop: 'conId',
                name: 'id',
                type: 'string',
            },
            {
                prop: 'samuserId',
                name: 'utilisateur',
                type: 'string',
            },
            {
                prop: 'prenom',
                name: 'prenom',
                type: 'string',
            },
            {
                prop: 'nom',
                name: 'nom',
                type: 'string',
            },
            {
                prop: 'ageId',
                name: 'agence',
                type: 'string',
            },
        ];
    }
}
