<form [formGroup]="formGroup"
      class="row spaceForm">
    <div class="form-host row col-xs-12">
        <iw-textfield #drpPeriod
                      [isDisabled]="true"
                      [labelAlign]="'right'"
                      [label]="'combo_periode_salaire' | translate"
                      class="iw-input col-xs-9 no-padding"
                      formControlName="salId"></iw-textfield>
        <ng-template #sxModeLite>
            <iw-textfield #drpType
                          [isDisabled]="true"
                          [labelAlign]="'right'"
                          [label]="'combo_sx_mode' | translate"
                          class="iw-input col-xs-9 no-padding"
                          formControlName="sxMode"></iw-textfield>
        </ng-template>
        <hr>
        <iw-textfield
                [isDisabled]="true"
                [labelAlign]="'left'"
                [label]="'id' | translate"
                class="iw-input col-xs-9 no-padding"
                formControlName="sxId"></iw-textfield>
        <iw-textfield
                [isDisabled]="true"
                [labelAlign]="'left'"
                [label]="'nombre_d_employes' | translate"
                class="iw-input col-xs-9 no-padding"
                formControlName="nbemp"></iw-textfield>
        <iw-textfield #txtSalId
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'salId' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="salId"></iw-textfield>
        <iw-textfield #txtSxJobkey
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'sxJobkey' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="sxJobkey"></iw-textfield>
        <iw-textfield #txtSxDeclid
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'sxDeclid' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="sxDeclid"></iw-textfield>
        <iw-textfield #txtDeclrespid
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'declrespid' | translate"
                      class="iw-input no-padding col-xs-9"
                      formControlName="declrespid"></iw-textfield>
    </div>
</form>
