import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, IwStoreService, TabFormControl} from '@app/sam-base/core';
import {GecliService} from '@app/sam-base/core/services/gecli.service';
import {FormKeys} from '@app/sam-base/models';
import {Gecli} from '@app/sam-base/models/invoices';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import {MessageFormRefreshService} from '@modules/sam-main/subjects/message-service-subject.service';
import {accountingRoles, debitorRoles} from '@sam-base/core/auth/access-rules/facture-roles';
import {GecomptaService} from '@sam-base/core/services/gecompta.service';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {Gecliview} from '@sam-base/models/invoices/gecliview';
import {Gecompta} from '@sam-base/models/invoices/gecompta';
import {GefacStatus} from '@sam-base/models/invoices/gefacliview';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {factureNCTabsFormControls} from './facture-nc-tabs-formcontrols';

@Component({
    templateUrl: './facture-nc.component.html',
    standalone: false
})
export class FactureNCComponent extends BaseTabFormComponent<Gefachdr> implements OnInit {
    public gefachdr?: Gefachdr;
    public gecli?: Gecli;
    public clientName?: string;
    public clientId?: string;
    public disabled = true;
    public gecompta?: Gecompta;
    disabledTabList: string[] = [];
    public FACTURE_COMPTA_ROLES: string[] = accountingRoles();
    public DEBITOR_ROLES: string[] = debitorRoles();
    private subscriptions = new Subject();
    private entityModifiable = false;

    constructor(store: IwStoreService, private readonly _gecliService: GecliService,
                private readonly messageService: MessageFormRefreshService,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _gecomptaService: GecomptaService) {
        super(store);
    }

    public get facId() {
        return this.getFormValue('facId') || '';
    }


    public get factureColorClass(): string {
        let factureData = this.getFormData();
        if (factureData?.facStatus === GefacStatus.OPEN) {
            if (!factureData.nbOfPrint && !factureData.nbOfMail) {
                return "facture-color-open";
            } else {
                return "facture-color-open-and-sent"
            }
        } else if (factureData?.facStatus === GefacStatus.CLOSE) {
            return "facture-color-closed";
        }
        return '';
    }

    public get isEmptyCptaRef() {
        return this.getFormValue('cptaRef') === '';
    }

    public get isCreditNote(): boolean {
        return this.getFormValue('facOrnc') === 'N';
    }


    public getTabsValues(): TabFormControl<Gefachdr>[] {
        return factureNCTabsFormControls;
    }

    public getFormControlNames(): FormKeys<Gefachdr> {
        return this.getTabsFormControls(factureNCTabsFormControls);
    }

    public ngOnInit(): void {

        this._store.globalForm(this.uuid)
            .state
            .pipe(takeUntil(this.subscriptions))
            .subscribe((state: any) => {
                this.disabledTabList = []
                if (state.editMode === 'edit') {
                    this.disabledTabList.push('qr');
                }
            });

        this.canUpdate();
        this.messageService.getMessage()
            .pipe(takeUntil(this.subscriptions))
            .subscribe(message => {
                if (message && 'facture-nc' === message) {
                    this.refreshEntity();
                }
            });
        this.subscribeValueChange('facId', (val) => {
            if (val !== undefined) {
                this.canUpdate();
                this.setFormValue('creating', false);
                this.refreshEntity();
                if (this.isCreditNote) {
                    this.disabledTabList?.push('qr');
                } else {
                    // remove qr from disabledTabList if exist
                    this.disabledTabList?.splice(this.disabledTabList.indexOf('qr'), 1);
                }
            }
        });
    }

    public openClient() {
        this._entityNavService
            .navigateToEntityForm(Gecliview, this.getFormValue('cliId'));
    }

    public decomptabiliser() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        this.gecompta = new Gecompta();
        this.gecompta.facId = this.facId;
        this.gecompta.dateDe = this.getFormValue('dateCpta');
        this.gecompta.dateA = this.getFormValue('dateCpta');
        this.gecompta.fac = true;
        this.gecompta.pay = false;
        this.gecompta.prepareResult = undefined;
        this._gecomptaService.cancel(this.gecompta)
            .subscribe({
                next: gecompta => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    this.setFormValue('cptaRef', '');
                    this.gecompta = gecompta;
                },
                error: err => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                }
            });
    }

    protected validateReadonlyStatus(e: Gefachdr) {
        return !this.entityModifiable && !!e.facId;
    }

    private refreshEntity() {
        this._gecliService.getGecliByCliId(this.getFormValue('cliId'))
            .subscribe((data: Gecli) => {
                this.gecli = data;
                this.clientName = data.nom;
                this.clientId = data.cliId;
            });
    }

    private canUpdate() {
        let cptaRefValue = this.getFormValue('cptaRef');
        this.entityModifiable = !cptaRefValue;
        this.triggerValidation();
    }
}
