<p-blockUI [blocked]="tableLoading"
           [target]="grdInd">
    <div [ngClass]="'form-spinner pi pi-spinner'"></div>
</p-blockUI>
<div class="row">
    <div class="col-xs-2">
        <iw-button #new
                   (press)="createNewEntry()"
                   [isDisabled]="!canAdd"
                   [text]="'sidemenu_new' | translate"
                   class="iw-button"></iw-button>
    </div>
    <div class="col-xs-2">
        <iw-button #edit
                   (press)="modifyEntry()"
                   [isDisabled]="!canEdit"
                   [text]="'sidemenu_edit' | translate"
                   class="iw-button"></iw-button>
    </div>
    <div class="col-xs-2">
        <iw-button #delete
                   (press)="deleteEntry()"
                   [isDisabled]="!canDelete"
                   [text]="'sidemenu_delete' | translate"
                   class="iw-button"
                   style="margin-block-start: auto"></iw-button>
    </div>
    <div class="col-xs-2">
        <iw-button #payer
                   (press)="openPayerProv()"
                   [isDisabled]="!canPaiProv"
                   [text]="'payer_prov' | translate"
                   class="iw-button"></iw-button>
    </div>
    <div class="col-xs-2">
        <iw-button #jours
                   (press)="openJourIs()"
                   [isDisabled]="isJourISDisabled || !selectedRow || isReadonly"
                   [text]="'combo_jour_is' | translate"
                   class="iw-button"></iw-button>
    </div>
    <div class="col-xs-2">
        <iw-checkbox
                (valueChange)="populateTableSaise($event)"
                [label]="'saisie' | translate"></iw-checkbox>
    </div>
</div>
<div #tableFicheSal
     class="row">
    <iw-table #grdInd
              (selectedChange)="getSelectedRow($event)"
              [allowHiddenColumns]="true"
              [columnResize]="true"
              [columns]="columns"
              [data]="tableElements"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="true"
              [isSortIconEnable]="false"
              class="col-xs-12 iw-grid"
              customClass="fichesal-element"
              style="height: 50vh;"></iw-table>

</div>
<div class="row">
    <div class="col-xs-1">
        <iw-textfield #txtEs_id
                      [isDisabled]="true"
                      [value]="selectedRow?.esId"
                      class="iw-input"
                      textAlign="center"></iw-textfield>
    </div>
    <div class="row col-xs-10 col-xs-offset-1">
        <iw-textfield #prBase
                      [isDisabled]="true"
                      [value]="getValue('prBase')"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield #unite
                      [isDisabled]="true"
                      [value]="selectedRow?.unite"
                      class="unite"
                      textAlign="center"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      class="operator"
                      textAlign="center"
                      value="x"></iw-textfield>

        <iw-textfield #prTaux
                      [isDisabled]="true"
                      [value]="getValue('prTaux')"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      class="operator"
                      textAlign="center"
                      value="="></iw-textfield>

        <iw-textfield #base
                      [isDisabled]="true"
                      [value]="getValue('ctmpBase')"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      class="operator"
                      textAlign="center"
                      value="x"></iw-textfield>

        <iw-textfield #text6
                      [isDisabled]="true"
                      [value]="getValue('realTaux')"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield [isDisabled]="true"
                      class="operator"
                      textAlign="center"
                      value="="></iw-textfield>

        <iw-textfield #txtMontant
                      [isDisabled]="true"
                      [tooltipPosition]="'top'"
                      [tooltip]="'montant' | translate"
                      [value]="getValue('ctmpMnt')"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield #txtParent
                      [isDisabled]="true"
                      [tooltipPosition]="'top'"
                      [tooltip]="'parent' | translate"
                      [value]="selectedRow?.parent"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield #txtParentType
                      [isDisabled]="true"
                      [tooltipPosition]="'top'"
                      [tooltip]="'typeParent' | translate"
                      [value]="selectedRow?.parentType"
                      class="operator"
                      textAlign="center"></iw-textfield>

        <iw-textfield #txtTexte
                      [isDisabled]="true"
                      [tooltipPosition]="'bottom'"
                      [tooltip]="'textSup' | translate"
                      [value]="selectedRow?.libelle"
                      class="texte"
                      textAlign="center"></iw-textfield>

        <iw-textfield #origine
                      [isDisabled]="true"
                      [tooltipPosition]="'bottom'"
                      [tooltip]="'origineDesc' | translate"
                      [value]="selectedRow?.origine"
                      class="other"
                      textAlign="center"></iw-textfield>

        <iw-textfield #gsCtype
                      [isDisabled]="true"
                      [tooltipPosition]="'bottom'"
                      [tooltip]="'typeGs' | translate"
                      [value]="selectedRow?.gsCtype"
                      class="other"
                      textAlign="center"></iw-textfield>

        <iw-textfield #txtDebit
                      [isDisabled]="true"
                      [tooltipPosition]="'bottom'"
                      [tooltip]="'debit' | translate"
                      [value]="selectedRow?.cptdebit"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield #txtCredit
                      [isDisabled]="true"
                      [tooltipPosition]="'bottom'"
                      [tooltip]="'credit' | translate"
                      [value]="selectedRow?.cptcredit"
                      class="values"
                      textAlign="center"></iw-textfield>

        <iw-textfield #txtCentre
                      [isDisabled]="true"
                      [tooltipPosition]="'bottom'"
                      [tooltip]="'centre' | translate"
                      class="centre"
                      textAlign="center"></iw-textfield>
    </div>
</div>
