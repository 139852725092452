import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api/models';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {getAgeByDateOfBirth} from '@app/sam-base/helpers/date';
import {FormKeys} from '@app/sam-base/models';
import {Saenf} from '@app/sam-base/models/common';
import {IwEventHubService} from '@sam-base/core';
import {SaenfService} from '@sam-base/core/services/saenf.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/** Component developed in: https://samredesign.atlassian.net/browse/SAM-2396 */

@Component({
    templateUrl: './ppempenf.component.html',
    standalone: false
})
export class PpempenfComponent extends BaseStoreFormComponent<Saenf> implements OnInit, OnDestroy {
    public entity = Saenf;

    public query: RestQueryParam<Saenf, any>[] = [];
    public age = 0;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private _saenfService: SaenfService,
                private readonly _events: IwEventHubService<string>) {
        super(store);
        this.isNewChanged
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e: boolean) => this.onIsNewChanged(e));
        this.formValueChange
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => this.calcAge());
    }

    public get isValid() {
        const prenom = this.getFormValue('prenom') || '';
        const nom = this.getFormValue('nom') || '';

        return prenom.length > 0 && nom.length > 0;
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get empNom() {
        return this.getData('nom') || '';
    }

    public onClose() {
        if (this.empId) {
            this._saenfService.updateSaenf(this.empId);
            this._events.emit('enf_closed');
        }
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public ngOnInit() {
        const empId = this.empId;
        if (typeof empId === 'string') {
            this.query = [
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'empId',
                    value: empId
                }];
        }
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    protected getFormControlNames(): FormKeys<Saenf> {
        return [
            'nom',
            [
                'prenom',
                new UntypedFormControl(undefined, Validators.required)],
            'datenaiss',
            'acharge',
            'allocation',
            'empId',
            'noavs13'];
    }

    protected onIsNewChanged(isNew: boolean) {
        if (this.empId && isNew) {
            this.setFormValue('empId', this.empId);
            this.updateEmpNom();
        }
    }

    protected validateReadonlyStatus(e: Saenf) {
        return (!e.enfId || e.enfId.length === 0) && !this.isNew;
    }

    private updateEmpNom() {
        const nom = this.getFormValue('nom');
        if (!nom && this.empNom && this.isNew) {
            this.setFormValue('nom', this.empNom);
        }
    }

    private calcAge() {
        const birth = <string | null>this.getFormValue('datenaiss');

        if (birth) {
            this.age = getAgeByDateOfBirth(birth);
        } else {
            this.age = 0;
        }
    }
}
