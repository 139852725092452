import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {EntityNavigationService, IwEventHubService, PpconService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {
    Ppcde,
    Ppcdf,
    Ppcli,
    Ppcliatt,
    Ppclicdes,
    Ppclicdfs,
    Ppclimis,
    Ppemp,
    Ppsuivi
} from '@app/sam-base/models/placement';
import {ProfileService} from '@shared/profile/profile.service';
import {UserProfile} from '@shared/profile/user-profile.model';

@Component({
    templateUrl: './suivi.component.html',
    standalone: false
})
export class SuiviComponent extends BaseStoreFormComponent<Ppsuivi> implements OnInit {

    public entityEmploye = Ppemp;
    public entityClient = Ppcli;
    public entityMis = Ppclimis;
    public entityCde = Ppclicdes;
    public entityCdf = Ppclicdfs;
    public hasClient = false;
    public hasEmploye = false;
    public hasMission = false;
    public hasCommandeTempo = false;
    public hasCommandeFixe = false;
    public userId = '';

    constructor(store: IwStoreService, private readonly _entityService: EntityNavigationService,
                private readonly _eventHub: IwEventHubService<string>, private readonly _profileService: ProfileService,
                private _ppconService: PpconService) {
        super(store);
    }

    public get isRappeler() {
        const val = this.getFormData();
        return val ? val.rappelflag : false;
    }

    public get action() {
        return this.getData('action');
    }

    public ngOnInit() {
        if (this.isNew) {
            this.initForm();
        }
        this.subscribeValueChange('cliId', () => this.hasCli());
        this.subscribeValueChange('empId', () => this.hasEmp());
        this.subscribeValueChange('misId', () => this.hasMis());
        this.subscribeValueChange('cdeId', () => this.hasCde());
        this.subscribeValueChange('cdfId', () => this.hasCdf());
        this._profileService.loadProfile()
            .subscribe(async (user: UserProfile) => {
                this.userId = user?.userId ?? '';
                this.setFormValue('userId', this.userId);
                if (this.isNew) {
                    this._ppconService.getMainCons(user, this.getFormControl('conId'));
                }
            });
    }

    public forwardDateBy1() {
        this.forwardDateByWeek(1);
    }

    public forwardDateBy4() {
        this.forwardDateByWeek(4);
    }

    public forwardDateBy12() {
        this.forwardDateByWeek(12);
    }

    public onFormChange() {
        const rappelflag = this.getFormValue('rappelflag');
        const date = this.getFormValue('daterappel');
        if (!rappelflag && !!date) {
            // eslint-disable-next-line no-null/no-null
            this.setFormValue('daterappel', undefined);
            return;
        }

        super.onFormChange();
    }

    public openEmploye() {
        const _ = undefined;
        this._entityService.navigateToEntityForm(Ppemp, this.getFormValue('empId'), _, _, true);
    }

    public openClient() {
        const _ = undefined;
        this._entityService.navigateToEntityForm(Ppcli, this.getFormValue('cliId'), _, _, true);
    }

    public openMission() {
        const _ = undefined;
        this._entityService.navigateToEntityForm(Ppclimis, this.getFormValue('misId'), _, _, true);
    }

    public openCde() {
        const _ = undefined;
        this._entityService.navigateToEntityForm(Ppcde, this.getFormValue('cdeId'), _, _, true);
    }

    public openCdf() {
        const _ = undefined;
        this._entityService.navigateToEntityForm(Ppcdf, this.getFormValue('cdfId'), _, _, true);
    }

    public hasCli() {
        this.hasClient = this.getFormValue('cliId') !== '';
        if (this.hasClient) {
            this.setFormValue('suitype', 'C');
        }
    }

    public hasEmp() {
        this.hasEmploye = this.getFormValue('empId') !== '';
        if (this.hasEmploye) {
            this.setFormValue('suitype', 'E');
        }
    }

    public hasMis() {
        this.hasMission = this.getFormValue('misId') !== '';
        if (this.hasMission) {
            this.setFormValue('suitype', 'M');
        }
    }

    public hasCde() {
        this.hasCommandeTempo = this.getFormValue('cdeId') !== '';
        if (this.hasCommandeTempo) {
            this.setFormValue('suitype', 'T');
        }
    }

    public hasCdf() {
        this.hasCommandeFixe = this.getFormValue('cdfId') !== '';
        if (this.hasCommandeFixe) {
            this.setFormValue('suitype', 'T');
        }
    }

    /** Filter to use when showing list of ppcliatt */
    public resultFilters: (e: Ppcliatt) => boolean = (e) => e.cliId === this.getFormValue('cliId');

    protected getValidationType() {
        return Ppsuivi;
    }

    protected getFormControlNames(): FormKeys<Ppsuivi> {
        return [
            'dateaction',
            'userId',
            'action',
            'cliattId',
            'notes',
            'rappelflag',
            'daterappel',
            'rappeldone',
            'conId',
            'empId',
            'cliId',
            'misId',
            'cdeId',
            'suitype',
            'cdfId'];
    }

    protected validateFields(e: Ppsuivi) {
        let isValid = super.validateFields(e);

        const rappelflag = this.getFormValue('rappelflag');
        if (rappelflag && isValid) {
            isValid = !!this.getFormValue('daterappel');
        }

        return isValid;
    }

    private initForm() {
        this.setFormValue('dateaction', IwDateHelper.dateIsoString(IwDateHelper.dateIso()));
        this.setFormValue('cliId', this.getData('cliId'));
        this.setFormValue('suitype', this.getData('suitype'));
        this.setFormValue('empId', this.getData('empId'));
        this.setFormValue('misId', this.getData('misId'));
        this.setFormValue('cdeId', this.getData('cdeId'));
        this.setFormValue('cdfId', this.getData('cdfId'));
        this.setFormValue('notes', this.getData('notes') || '');
    }

    private forwardDateByWeek(numOfWeek: number) {
        let value: string | undefined = this.getFormValue('daterappel');
        if (typeof value !== 'string' || !value) {
            value = (new Date()).toISOString()
                .substr(0, 10); // YYYY-MM-DD
        }

        const m = IwDateHelper.addToDate(numOfWeek, 'weeks', value);
        this.setFormValue('daterappel', IwDateHelper.dateIsoString(m));
    }

}
