import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PpempService} from '@app/modules/sam-main/placement/services/ppemp.service';
import {IwRestGridComponent} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {isRestEntity, IwStoreService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {EntityDelete} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/entity.actions';
import {RowClickEvent} from '@app/sam-base/models';
import {Ppempdoc, Ppempdocview, Ppwxdocs} from '@app/sam-base/models/placement';
import {DocumentManagementService} from '@core/document-management/document-management.service';
import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {DocTypeEditComponent} from '@shared/widgets/modal-components/doc-type-edit/doc-type-edit.component';
import {Subscription} from 'rxjs';

@Component({
    selector: 'iw-doc-type-management',
    templateUrl: './document-type-management.component.html',
    styleUrls: ['./document-type-management.component.scss'],
    standalone: false
})
export class DocumentTypeManagementComponent implements OnInit, OnDestroy {

    @Input() public isReadonly = true;
    public type = Ppempdocview;
    public query: RestQueryParam<Ppempdocview, string>[] = [
        {
            operation: RestQueryOperation.Equals,
            prop: 'empId',
            value: '_'
        }];
    public selectedDocument: Ppempdocview[] = [];
    @ViewChild('docGrid') public docGrid?: IwRestGridComponent<Ppempdocview>;
    private docStreamSub: Subscription = new Subscription();

    constructor(private _store: IwStoreService, private _ppempService: PpempService, private _updates: Actions,
                private _translate: TranslateService, private _modalService: ModalService,
                private _docManagementService: DocumentManagementService) {
    }

    private _empId?: string;

    public get empId(): string | undefined {
        return this._empId;
    }

    @Input()
    public set empId(value: string | undefined) {
        this._empId = value;
        this.setQuery(value);
    }

    public get selectedDocTypeId(): string | undefined {
        return (this.selectedDocument[0] && this.selectedDocument[0].empdocId);
    }

    public get selectedDocType(): string | undefined {
        return (this.selectedDocument[0] && this.selectedDocument[0].empdoctype);
    }

    public ngOnInit() {
        this.docStreamSub = this._docManagementService.documentUpdateStream
            .subscribe(doc => this.canReloadGrid(doc));

        this.onValidateDocuments();
    }

    public ngOnDestroy() {
        this.docStreamSub.unsubscribe();
    }

    public onRowSelect(event: Ppempdocview[]) {
        this.selectedDocument = event;
    }

    public onValidateDocuments() {
        if (!this.empId) {
            return;
        }
        this._ppempService.validateEmployeeDocuments(this.empId)
            .subscribe(() => this.docGrid?.refresh());
    }

    public async deleteDoctype() {
        const selectedId = this.selectedDocTypeId;
        if (!selectedId) {
            return;
        }

        const options: ModalMessageOptions = {
            message: [],
            confirmMessage: `${this._translate.instant('deleteDocument')} ${this.selectedDocType}?`,
            showCancel: true,
            title: this._translate.instant('supprimer')
        };
        try {
            await this._modalService
                .showModal(ModalMessageComponent, options);
            this.subscribeToDeleteEffect(selectedId);
            this._store.dispatch(new EntityDelete(Ppempdoc, selectedId));
        } catch (error) {
        }
    }

    public async editDocumentType(doc?: RowClickEvent<Ppempdocview>) {
        const docId = doc?.row ? doc.row.empdocId : this.selectedDocTypeId;
        if (!docId || this.isReadonly) {
            return;
        }
        try {
            const editedDoc: Ppempdoc = await this._modalService.showModal(DocTypeEditComponent, {docId});
            this.saveDocumentType(docId, editedDoc);
        } catch (error) {
        }
    }

    public refreshGrid(): void {
        this.docGrid?.refresh();
    }

    private saveDocumentType(docId: string, doc: Ppempdoc) {
        this.subscribeToSaveEffect(docId);
        this._store.dispatch(new actions.EntityUpdate(doc));
    }

    private subscribeToSaveEffect(selectedId: string) {
        const sub = this._updates.pipe(ofType(actions.ENTITY_UPDATE_SUCCESS))
            .subscribe((action: actions.EntityUpdateSuccess<Ppempdoc>) => {
                if (this.isDoctypeUpdated(selectedId, action.entity)) {
                    this.docGrid?.refresh();
                }
                sub.unsubscribe();
            });
    }

    private subscribeToDeleteEffect(selectedId: string) {
        const sub = this._updates.pipe(ofType(actions.ENTITY_DELETE_SUCCESS))
            .subscribe((action: actions.EntityDelete<Ppempdoc>) => {
                if (this.isDoctypeDeleted(selectedId, action)) {
                    this.docGrid?.refresh();
                }
                sub.unsubscribe();
            });
    }

    private isDoctypeDeleted(selectedId: string, action: actions.EntityDelete<Ppempdoc>): boolean {
        return action.entityType === Ppempdoc && action.entityId === selectedId;
    }

    private isDoctypeUpdated(selectedId: string, doc: Ppempdoc): boolean {
        return isRestEntity(doc) && doc.empdocId === selectedId;
    }

    private setQuery(empId?: string) {
        if (!empId) {
            return;
        }
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: empId
            }];
    }

    private canReloadGrid(doc: Ppwxdocs) {
        if (doc.parentid === this.empId && doc.partype === 'E') {
            this.docGrid?.refresh();
        }
    }

}
