<div class="row">
    <div [formGroup]="formGroup"
         class="col-xs-9">
        <div class="row">
            <iw-date-picker [label]="'Période'"
                            class="iw-input"
                            formControlName="dateDe"></iw-date-picker>
            <iw-date-picker [label]="' '"
                            class="iw-input bottom-xs"
                            formControlName="dateA"></iw-date-picker>
        </div>

        <div class="row">
            <div class="col-xs-12 no-padding padding-bottom-10">
                <label>Options</label>
            </div>
            <div class="row">
                <iw-checkbox [isDisabled]="true"
                             [label]="'Factures'"
                             class="iw-input col-xs-12"
                             formControlName="fac"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'Paiements'"
                             class="iw-input col-xs-12"
                             formControlName="pay"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'Factures: séparer débit crédit'"
                             class="iw-input col-xs-12"
                             formControlName="facDebCre"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'Factures à zéro'"
                             class="iw-input col-xs-12"
                             formControlName="facZero"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'Provisionner les ristournes'"
                             class="iw-input col-xs-12"
                             formControlName="prvRist"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'Comptes auxiliaires'"
                             class="iw-input col-xs-12"
                             formControlName="withCompteAux"></iw-checkbox>
            </div>
        </div>
        <div class="row">
            <iw-enum-dropdown [inputEnum]="ModeRegroup"
                              [isDisabled]="true"
                              [label]="'Regroupement'"
                              class="iw-input col-xs-12"
                              formControlName="modeRegoup"></iw-enum-dropdown>

        </div>
        <div class="row">
            <iw-enum-dropdown [inputEnum]="ModeAgence"
                              [isDisabled]="true"
                              [label]="'Mode Agence'"
                              class="iw-input col-xs-12"
                              formControlName="modeAgence"></iw-enum-dropdown>
        </div>

        <div class="row">
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'Factures'"
                          class="iw-input col-xs-4 text-align-right"
                          formControlName="nbEcrFac"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'Paiements'"
                          class="iw-input col-xs-4 text-align-right"
                          formControlName="nbEcrPay"></iw-textfield>
            <iw-textfield [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'Erreurs'"
                          class="iw-input col-xs-4 text-align-right"
                          formControlName="nbErreurs"></iw-textfield>
        </div>

        <div *ngIf="errors?.length > 0"
             class="row">
            <iw-table (rowDoubleClick)="openErrorEntity($event)"
                      [columns]="columnsError"
                      [data]="errors"
                      [defaultSelected]="false"
                      [isDisabled]="false"
                      [isFilterEnable]="false"
                      [isGridColumnMenuEnable]="false"
                      [isSortIconEnable]="false"
                      [virtualScroll]="true"
                      class="iw-grid col-xs-12 no-padding"
                      style="height: 250px"></iw-table>

        </div>

        <div class="row">
            <iw-textfield-compte [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'Compte Débiteurs'"
                                 class="iw-input col-xs"
                                 formControlName="cptDeb"></iw-textfield-compte>

            <iw-textfield-compte [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'Compte Rabais'"
                                 class="iw-input col-xs"
                                 formControlName="cptRab"></iw-textfield-compte>
        </div>
        <div class="row">
            <iw-textfield-compte [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'Compte Frais'"
                                 class="iw-input col-xs"
                                 formControlName="cptFrais"></iw-textfield-compte>

            <iw-textfield-compte [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'Compte Ristournes'"
                                 class="iw-input col-xs"
                                 formControlName="cptRist"></iw-textfield-compte>
        </div>
        <div class="row">
            <iw-textfield-compte [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'Compte Prov./Rist. (y-1)'"
                                 class="iw-input col-xs"
                                 formControlName="cptRist1"></iw-textfield-compte>

            <iw-textfield-compte [isDisabled]="true"
                                 [labelAlign]="'top'"
                                 [label]="'Compte Prov./Rist.'"
                                 class="iw-input col-xs"
                                 formControlName="cptPrvRist"></iw-textfield-compte>
        </div>

    </div>
    <div class="col-xs-3 col">
        <div class="row margin-bottom-10">
            <iw-button (press)="transfer()"
                       [text]="'comptabiliser' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row margin-bottom-10">
            <iw-button (press)="decomptabiliser()"
                       [text]="'decomptabiliser' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="closeDialog()"
                       [text]="'Fermer'"
                       class="iw-button btn-block"></iw-button>
        </div>
    </div>
</div>
