export interface PaymentRequest {
    dateExec?: string;
    cpainavis?: string;
    chrgbr?: string;
    salId?: string;
    paymentRequestType?: PaymentRequestType;
    selMpaId?: string;
    amountsSum?: number;
    sapaimodeId?: string;
    libelle?: string;
    ids?: (string | undefined)[];
}

export interface PaymentsToPayResponse {
    fileName?: string;
    file?: string;
}

export enum PaymentRequestType {
    ACOMPTE = 'ACOMPTE',
    SALAIRE = 'SALAIRE'
}