import {HttpClient} from '@angular/common/http';

import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {AgencyHandlerService, ModalService} from '@app/sam-base/core';
import {ComboboxItem, ModalComponent, TT6Ges} from '@app/sam-base/models';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {TranslateService} from '@ngx-translate/core';
import {SamUserRole} from '@sam-base/models/admin/sam-user';

import {Sasx5dec, SxNotification} from '@sam-base/models/admin/sasx5dec.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxMode, SxModeLite} from '@sam-base/models/admin/sx-mode.enum';
import {SxMonthlyType} from '@sam-base/models/admin/sx-monthly-type.enum';
import {SxStatus} from '@sam-base/models/admin/sx-status.enum';
import {SxType} from '@sam-base/models/admin/sx-type.enum';
import {SxYearlyType} from '@sam-base/models/admin/sx-yearly-type.enum';
import {Saparass} from '@sam-base/models/placement/saparass';
import {ProfileService} from '@shared/profile/profile.service';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

function strToCbp(lst: string[]): ComboboxItem<string>[] {
    return lst.map(e => (<ComboboxItem<string>>{
        value: e,
        name: e
    }));
}

/**
 * Tab General of SASX5DEC
 */
@Component({
    selector: 'iw-tab-sasx5dec-general',
    templateUrl: './tab-sasx5dec-general.component.html',
    standalone: false
})
export class TabSasx5decGeneralComponent extends BasePartialFormComponent<Sasx5dec> implements OnInit, OnDestroy {
    public gestionSuggestions: ComboboxItem<string>[] = [];
    public anneesSuggestions: ComboboxItem<string>[] = [];
    public readonly gestionListOption: ModalSelectTableOption<ComboboxItem<string>, string>;
    public readonly optionsSxModeLite = SxModeLite;
    public readonly optionsSxMode = SxMode;
    public readonly optionsSxMonthlyType = SxMonthlyType;
    public readonly optionsSxYearlyType = SxYearlyType;
    @Input() public isNew = false;
    public sxNotifications: SxNotification[] = [];
    public optionPeriode: ComboboxItem<string>[] = [];
    public substitutionsCandidates?: ComboboxItem<string>[];
    protected readonly SamUserRole = SamUserRole;
    private subscriptions = new Subject();

    constructor(private readonly _http: HttpClient, private readonly _translate: TranslateService,
                private readonly _modalService: ModalService,
                private readonly _swissdecService: SwissdecService,
                private readonly swissdecService: SwissdecService,
                private _profileService: ProfileService,
                private readonly _agencyHandlerService: AgencyHandlerService) {
        super();
        this.gestionListOption = {
            columns: [{prop: 'name'}],
            map: (e: ComboboxItem<string>) => e.value || '',
            selection: 'single'
        };

    }

    public get isGodMode(): boolean {
        return this._profileService.checkUserPermission([SamUserRole.SAM_ADMIN_GOD_MODE])
    }

    public get entityId() {
        const id = this.getFormValue('id');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get gestion() {
        const gestion = this.getFormValue('gestion');
        if (typeof gestion === 'string') {
            return gestion;
        }
        return undefined;
    }

    public get annee() {
        return this.getFormValue('annee');
    }

    public get xmlSent(): string | undefined {
        return this.getFormValue('xmlSent');
    }

    public get xmlReceived(): string | undefined {
        return this.getFormValue('xmlReceived');
    }

    public get isMonthlyType(): boolean {
        return this.getFormValue('sxType') === SxType.MONTHLY;
    }

    public get isYearlyType(): boolean {
        return this.getFormValue('sxType') === SxType.ANNUALLY;
    }

    public get isSubstitution() {
        return this.getFormValue('isSubstitution');
    }

    public get isAtSourceType(): boolean {
        return this.getFormValue('sxMonthlyType') === SxMonthlyType.AT_SOURCE;
    }

    public get isSubstitutionPossible(): boolean {
        return this.isYearlyType || (this.isMonthlyType && !this.isAtSourceType)
    }

    public get isDeclarationInInitState() {
        return this.getFormValue('status') === SxStatus.INIT;
    }

    public ngOnInit() {
        if (this.gestion) {
            this.loadAnneesExercice(this.gestion);
        }
        if (this.isNew) {
            this._agencyHandlerService.getGestions()
                .subscribe((gests: TT6Ges[]) => {
                    this.gestionSuggestions = gests.map(gestion => {
                        return {
                            name: gestion.libgestion!,
                            value: gestion.nogestion!,
                        }
                    });
                });
            this.subscribeValueChange('gestion', (gestion) => this.resetDataOnGestionChange(gestion + ''));
            this.subscribeValueChange('annee', (annee) => {
                this.resetDataOnAnneeChange(annee);
                if (this.isYearlyType && !this.isGodMode) {
                    this.setFormValue('month', '12');
                }
            });
            this.subscribeValueChange('sxYearlyType', (gestion) => this.loadInstitutions());

            this.swissdecService.getCandidatesForSubstitutions(this.getFormValue('sxType') as SxType).subscribe({
                next: candidates => {
                    this.substitutionsCandidates = candidates.map(candidate => {
                        return {
                            name: `${candidate.salId} - (${candidate.sxType} / ${candidate.gestion}) - ${candidate.sxDeclid}`,
                            value: candidate.sxDeclid!,
                        }
                    })
                }
            });


        } else {
            this.getDropDownOptions(this.getFormValue('annee') || '');
        }
        // this.subscribeValueChange('notifications', (notifications) => this.parseNotifications(notifications as string));
        this.subscribeValueChange('sxMonthlyType', (monthlyType) => {
            if (monthlyType === SxMonthlyType.AT_SOURCE) {
                this.setFormValue('isSubstitution', false);
            }
        })
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public async onBtnGestionClick() {
        if (!this.gestionSuggestions) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<string[], ComboboxItem<string>[]>> = <any>IwModalSelectTableComponent;
        try {
            this.gestionListOption.fetchAction = () => of(this.gestionSuggestions);
            const data = await this._modalService
                .showModal<string[], ComboboxItem<string>[]>(comp, <any>this.gestionListOption);
            this.setFormValue('gestion', data[0]);
        } catch {
        }
    }

    public async onBtnAnneeClick() {
        if (!this.anneesSuggestions) {
            return;
        }
        // eslint-disable-next-line max-len
        const comp: Type<ModalComponent<string[], ComboboxItem<string>[]>> = <any>IwModalSelectTableComponent;
        try {
            this.gestionListOption.fetchAction = () => of(this.anneesSuggestions);
            const data = await this._modalService
                .showModal<string[], ComboboxItem<string>[]>(comp, <any>this.gestionListOption);
            this.setFormValue('annee', data[0]);
        } catch {
        }
    }

    public getDropDownOptions(annee: string) {
        this.optionPeriode = [
            {
                name: `${this._translate.instant('month-short-1')} ${annee}`,
                value: '1'
            },
            {
                name: `${this._translate.instant('month-short-2')} ${annee}`,
                value: '2'
            },
            {
                name: `${this._translate.instant('month-short-3')} ${annee}`,
                value: '3'
            },
            {
                name: `${this._translate.instant('month-short-4')} ${annee}`,
                value: '4'
            },
            {
                name: `${this._translate.instant('month-short-5')} ${annee}`,
                value: '5'
            },
            {
                name: `${this._translate.instant('month-short-6')} ${annee}`,
                value: '6'
            },
            {
                name: `${this._translate.instant('month-short-7')} ${annee}`,
                value: '7'
            },
            {
                name: `${this._translate.instant('month-short-8')} ${annee}`,
                value: '8'
            },
            {
                name: `${this._translate.instant('month-short-9')} ${annee}`,
                value: '9'
            },
            {
                name: `${this._translate.instant('month-short-10')} ${annee}`,
                value: '10'
            },
            {
                name: `${this._translate.instant('month-short-11')} ${annee}`,
                value: '11'
            },
            {
                name: `${this._translate.instant('month-short-12')} ${annee}`,
                value: '12'
            }];
    }

    private loadAnneesExercice(gestion: string) {
        if (this.isNew) {
            this.getAnnees(gestion)
                .subscribe((annees: string[]) => {
                    this.anneesSuggestions = strToCbp(annees);
                });
        }
    }

    private getAnnees(gestion: string): Observable<string[]> {
        return this._swissdecService.getExerciseForGestion(gestion).pipe(map(result => result.map(e => e.cannee || '')));
    }

    private resetDataOnGestionChange(gestion: string) {
        this.setFormValue('annee', undefined);
        this.setFormValue('month', undefined);
        this.loadAnneesExercice(gestion);
        this.loadInstitutions()
    }

    private resetDataOnAnneeChange(annee: unknown) {
        this.getDropDownOptions(annee as string);
        this.loadInstitutions();
    }


    private convertSaparassToInstitution(saparass: Saparass): Sasx5institution {
        const institution: Sasx5institution = new Sasx5institution();
        institution.domain = saparass.dedCode;
        institution.institutionCode = saparass.assCode;
        institution.institutionIdref = saparass.assIdref;
        institution.assNocli = saparass.assNocli;
        institution.assNocai = saparass.assNocai;
        institution.assNocta = saparass.assNocta;
        institution.assNom = saparass.assNom;
        return institution;
    }

    private loadInstitutions() {
        const isAnnualStatement = this.getFormValue('sxType') === SxType.ANNUALLY && this.getFormValue('sxYearlyType') === SxYearlyType.ANNUAL_STATEMENT;
        if (!isAnnualStatement || !this.isNew) {
            return;
        }
        const gestion: string = this.getFormValue('gestion') ?? '';
        const annee: string = this.getFormValue('annee') ?? '';
        this.setFormValue('institutions', undefined);
        if (!gestion || !annee) {
            return;
        }
        this._swissdecService.getInsurancesForGestionAndYear(gestion, annee).subscribe(insurances => {
            this.setFormValue('institutions', insurances.map(insurance => this.convertSaparassToInstitution(insurance)));
        });
    }
}
