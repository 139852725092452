import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {EntityNavigationService, IwStoreService, ModalService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Pprap} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {
    employeeCommunicationAccessRoles,
    rapportCalculateDataAccessRoles
} from '@sam-base/core/auth/access-rules/employee-form';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ReportsService} from '../../services/reports.service';

@Component({
    templateUrl: './rapport-sidemenu.component.html',
    standalone: false
})
export class RapportSidemenuComponent extends BaseSideFormComponent<Pprap> implements OnInit, OnDestroy {

    public pprap?: Pprap;
    private subscription = new Subject();

    constructor(private readonly store: IwStoreService, private readonly _entityNavService: EntityNavigationService,
                private _reportsService: ReportsService, private _translate: TranslateService,
                private _modalService: ModalService) {
        super();
    }

    public rapportCalculateRoles: SamUserRole[] = rapportCalculateDataAccessRoles();

    public get hasSal() {
        return this.pprap?.sent2Sal ? 'S' : '';
    }

    public get hasFac() {
        return this.pprap?.sent2Fac ? 'F' : '';
    }

    public ngOnInit() {
        this.store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.pprap = data;
            });
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    public async calculateRaportData() {
        if (!this.pprap) {
            return;
        }
        const options: ModalMessageOptions = {
            message: [],
            showCancel: true,
            title: this._translate.instant('rapport'),
            confirmMessage: this._translate.instant('calcTotals'),
            alertsMessage: '',
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.store.dispatch(new actions.SetLoading(this.uuid, true));
            await this._reportsService.calculateTotals(this.pprap);
            this.store.dispatch(new actions.LoadEntity(this.uuid));
        } catch (err) {
            this.store.dispatch(new actions.SetLoading(this.uuid, false));
        }
    }
}
