import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {
    EntityCreate,
    EntityDelete,
    EntityUpdate,
    IwStoreService,
    RestApiService,
    RestEntityClient,
    RestQueryOperation,
    RestQueryParam
} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppkw} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './keyword-config.component.html',
    standalone: false
})
export class KeywordConfigComponent extends BaseFormComponent<Ppkw> {
    public type = Ppkw;

    public isReadonly = true;

    public selectedRow?: Ppkw;

    public kwId = '';

    public sortProp?: keyof Ppkw;
    public sortPropDir?: 'asc' | 'desc';

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'kwId', value: this.selectedRow.kwId};
    }

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppkw, string>[] = [{
        prop: 'kwId', operation: RestQueryOperation.NotEquals, value: '_'
    }];

    private _isSaving = false;
    private _newEntryMode = false;

    private readonly _restPpkwClient: RestEntityClient<Ppkw>;

    constructor(rest: RestApiService, private _store: IwStoreService) {
        super();
        this._restPpkwClient = rest.getEntityClient(Ppkw);
    }

    /**
     * Function to get selected row
     *
     * @param event Ppkw[]
     * @returns void
     */
    public getSelectedRow(event: Ppkw[]) {
        this.isReadonly = true;

        if (this._isSaving) {
            // Prevents clearing of the selectedRow
            this._isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.kwId = (event[0] && event[0].kwId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppkw entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this._newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpkwClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        this._isSaving = true;

        if (this._newEntryMode) {
            this.setSortByModif();
            this._store.dispatch(new EntityCreate(updatedData));
            this._newEntryMode = false;

            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this._newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.kwId) {
            this.isReadonly = false;
            this._newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this.selectedRow && this.selectedRow.kwId) {
            this._store.dispatch(new EntityDelete(Ppkw, this.kwId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this._newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppkw>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppkw;
    }

    protected getFormControlNames(): FormKeys<Ppkw> {
        return ['kwId', 'kw'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }
}
