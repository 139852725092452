import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppgrt} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './genretrav-config.component.html',
    standalone: false
})
export class GenretravConfigComponent extends BaseFormComponent<Ppgrt> {

    public type = Ppgrt;
    public isReadonly = true;
    public newEntryMode = false;

    public grtId = '';
    public selectedRow?: Ppgrt;
    public isSaving = false;

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'grtId', value: this.selectedRow.grtId};
    }

    public sortProp?: keyof Ppgrt;
    public sortPropDir?: 'asc' | 'desc';

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppgrt, string>[] = [{
        prop: 'grtId', operation: RestQueryOperation.NotEquals, value: '_'
    }];

    private readonly _restPpgrtClient: RestEntityClient<Ppgrt>;

    constructor(rest: RestApiService, private _store: IwStoreService) {
        super();
        this._restPpgrtClient = rest.getEntityClient(Ppgrt);
    }

    /**
     * Function to get selected row
     *
     * @param event Ppgrt[]
     * @returns void
     */
    public getSelectedRow(event: Ppgrt[]) {
        this.isReadonly = true;

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.grtId = (event[0] && event[0].grtId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppgrt entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpgrtClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        this.isSaving = true;

        if (this.newEntryMode) {
            this.setSortByModif();
            this._store.dispatch(new EntityCreate(updatedData));
            this.newEntryMode = false;

            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.grtId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    public removeEntry() {
        if (this.selectedRow && this.selectedRow.grtId) {
            this._store.dispatch(new EntityDelete(Ppgrt, this.grtId));
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppgrt>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppgrt;
    }

    protected getFormControlNames(): FormKeys<Ppgrt> {
        return ['grtId', 'genretrav'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }
}
