import {Component, Input, OnDestroy, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService, IwEventHubService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Lookups} from '@app/sam-base/models/common';
import {Ppcde, Ppekw, Ppkw, ScheduleEvent} from '@app/sam-base/models/placement';
import {PlacementEvent} from '@sam-base/models/placement/events.models';
import {
    LookupsSelectionComponent
} from '@shared/widgets/modal-components/lookups-selection/lookups-selection.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../../placement.forms';
import {MatchingService} from '../../../services/matching.service';
import {PpkwMatchingComponent} from '../../ppkw-matching/ppkw-matching.component';

@Component({
    selector: 'iw-tab-ppcdetempo-match',
    templateUrl: './tab-ppcdetempo-match.component.html',
    standalone: false
})
export class TabPpcdetempoMatchComponent extends BasePartialFormComponent<Ppcde> implements OnDestroy {

    @ViewChild('kwMatching', {static: true}) public kwMatching?: PpkwMatchingComponent;
    public matchSource: Type<any> = Ppkw;
    public matchTarget: Type<any> = Ppekw;
    public regionsToShow = '';
    public regions: Lookups[] = [];
    public query: RestQueryParam<ScheduleEvent, any>[] = [];
    @Input() public formId = '';
    private _entiyId = '';
    private subscriptions = new Subject();

    constructor(private readonly _modalService: ModalService, private readonly _matchingService: MatchingService,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private _eventHubService: IwEventHubService<string>) {
        super();

        this._matchingService.scheduleEventData
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                if (e.uuid === this.formId) {
                    this.setFormValue('needSummary', e.data);
                }
            });
    }

    public get entityId(): string {
        return this._entiyId;
    }

    @Input()
    public set entityId(v: string) {
        this._entiyId = v;
        if (!v) {
            return;
        }
        this.buildQuery();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public async navigateToPpempAdvSearch() {
        const keywords = this.kwMatching?.value || [];

        this._eventHubService.emit(PlacementEvent.PPCDE_LAUNCH_ADVSEARCH, {
            selected: [
                {
                    cdeId: this.entityId,
                    cliId: this.getFormValue<string>('cliId'),
                    qualif: this.getFormValue<string>('qualif'),
                    keywords
                }]
        });
    }

    public async openRegionsSelection() {
        try {
            const regionsValue = this.getFormValue<string>('region') || '';

            const regList: string[] = regionsValue.split(';')
                .map(e => e.trim());
            const res = await this._modalService.showModal(LookupsSelectionComponent, {
                list: regList,
                lkupname: 'REGION'
            });

            const regionsToSave = res;
            this.setFormValue('region', regionsToSave);

        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    }

    public openScheduleEventComp() {
        this._formHandler.showFormDialog(PlacementForm.ScheduleEvent, {
            height: '250px',
            parentId: this.entityId,
            parentEntity: 'ppcde',
            isAvailability: true,
            formUuid: this.formId
        }, s => ({
            ...s,
            diagTitle: 'schedule_event_ppcde_form_title',
            editMode: 'waitLoading'
        }));
    }

    private buildQuery() {
        const id = this._entiyId;
        if (typeof id === 'string') {
            this.query = [
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'parentId',
                    value: id
                }];
        }
    }

}
