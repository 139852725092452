import {Component, Input, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {EsQueryStatement, RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';

import {SuiviService} from '@app/sam-base/core/services/suivi.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppcdf, Ppsuivi, Ppsuiviview} from '@app/sam-base/models/placement';
import {PrintService} from '@core/print/print.service';
import {TranslateService} from '@ngx-translate/core';
import {DocumentEntityRef} from '@sam-base/models/placement/document-entity-ref';
import {PlacementForm} from '../../../placement.forms';

@Component({
    selector: 'iw-tab-ppcdefixe-doc',
    templateUrl: './tab-ppcdefixedoc.component.html',
    standalone: false
})
export class TabPpcdeFixeDocComponent {

    @Input() public isReadonly = true;

    public entityRef: DocumentEntityRef = DocumentEntityRef.CommandFixe

    @Input()
    public set cdfId(v: string) {
        this._cdfId = v;
    }

    public get cdfId() {
        return this._cdfId;
    }

    private _cdfId = '';

    constructor() {
    }
}
