<form [formGroup]="formGroup"
      class="row space-form">
    <div class="form-host row col-xs-12">

        <iw-enum-dropdown #drpViewgenReportType
                          [(value)]="cmdReportType"
                          [inputEnum]="reportTypeEnum"
                          [label]="'combo_viewgen_report_type' | translate"
                          class="iw-input col-xs-9"></iw-enum-dropdown>

        <ng-container *ngIf="cmdReportType">


            <!--    options -->
            <iw-dropdown #drpLanguage
                         [(value)]="cmdLanguage"
                         [label]="'langue' | translate"
                         [options]="optionsLanguage"
                         class="iw-input col-xs-9"></iw-dropdown>


            <iw-checkbox [(value)]="cmdSpecimen"
                         [label]="'specimen' | translate"
                         class="col-xs-6 padding-top-10"></iw-checkbox>
            <iw-checkbox *ngIf="isTaxAccountingReport"
                         [(value)]="cmdEnglish"
                         [label]="'english' | translate"
                         class="col-xs-6 padding-top-10"></iw-checkbox>

            <iw-dropdown #drpAlignmnet
                         *ngIf="isTaxAccountingReport"
                         [(value)]="cmdAlignment"
                         [label]="'alignment' | translate"
                         [options]="optionsAlignment"
                         class="iw-input col-xs-9"></iw-dropdown>

            <iw-canton-dropdown #drpCode
                                [(value)]="cmdCanton"
                                [labelAlign]="'top'"
                                [label]="'canton' | translate"
                                class="iw-input col-xs-9 padding-top-10"></iw-canton-dropdown>
            <div *ngIf="!cmdCanton && isMonthlyType"
                 class="iw-input col-xs-9">
                <small style="color: #ff0000; font-size:10px"> {{ 'canton_mandatory' | translate }} </small>
            </div>


        </ng-container>

    </div>


    <div class="row col-xs-12">
        <iw-button (press)="onGenerate()"
                   [isDisabled]="!canGenerate"
                   [text]="'generate' | translate"
                   class="col-xs-offset-9 col-xs-3"></iw-button>
    </div>
</form>
