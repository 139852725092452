import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {TranslateService} from '@ngx-translate/core';
import {RestApiService} from '@sam-base/core';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';
import {toFixedNumber} from '@sam-base/helpers';
import {IwGridColumn} from '@sam-base/models';
import {Ppacattest} from '@sam-base/models/placement';
import {Cac2, Cac4} from '@sam-base/models/placement/attestEmp';

@Component({
    selector: 'iw-tab-annes', templateUrl: './tab-ppacattest-annees.component.html',
    standalone: false
})

export class TabPpacattesAnnesComponent extends BasePartialFormComponent<Ppacattest> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public cac2: Cac2[] = [];

    @Input() public cac4?: Cac4;
    public cac2Columns = this.getCac2Columns();
    public empId?: string;
    public salary?: string;

    constructor(_restService: RestApiService, private _translate: TranslateService, protected readonly _rest: RestApiService) {
        super();
    }

    public ngOnInit() {
    }

    // eslint-disable-next-line complexity
    public get textSalary(): string {
        let salary = '';
        if (!this.cac4) {
            return '';
        }

        if (this.cac4.firstSalId || this.cac4.lastSalId) {
            salary += this._translate.instant('firstLastSalary') + `${IwDateHelper
                .dateFormatFromString(IwDateHelper
                    .getMomentSalId(this.cac4.firstSalId), 'MMMM yyyy')}` + ` / ` + `${IwDateHelper
                .dateFormatFromString(IwDateHelper.getMomentSalId(this.cac4.lastSalId), 'MMMM yyyy')}\n\n`;
        }
        if (this.cac4.salavs6) {
            salary += this._translate.instant('salAVS6') + `${toFixedNumber(this.cac4.salavs6, 2)}\n`;
        }
        if (this.cac4.salavs12) {
            salary += this._translate.instant('salAVS12') + `${toFixedNumber(this.cac4.salavs12, 2)}\n`;
        }

        if (this.cac4.salavs15) {
            salary += this._translate.instant('salAVS15') + `${toFixedNumber(this.cac4.salavs15, 2)}\n`;
        }
        if (this.cac4.salavs24) {
            salary += this._translate.instant('salAVS24') + `${toFixedNumber(this.cac4.salavs24, 2)}\n`;
        }
        return salary;
    }

    /* Columns to show in  table */
    private getCac2Columns(): IwGridColumn<Cac2>[] {
        return [{
            prop: 'annee', name: 'annees', index: 0
        }, {
            prop: 'salavs', name: 'salavs', decimals: 2, index: 1
        }, {
            prop: 'gsId', name: 'gs', index: 2
        }, {
            prop: 'firstDay', name: 'start', index: 3
        }, {
            prop: 'lastDay', name: 'datefin', index: 4
        }];
    }
}
