import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {MenuEntry, MenuItem} from '@app/sam-base/models';
import {TranslateService} from '@ngx-translate/core';
import {AgencyHandlerService} from '@sam-base/core';
import {ProfileService} from '@shared/profile/profile.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IwEventHubService} from '../../../core/events';
import {SideMenuStateService} from '../../../core/services/side-menu-state.service';
import {LayoutEvents} from '../../events/events.models';

@Component({
    selector: 'iw-side-layout-menu',
    templateUrl: './menu.component.html',
    standalone: false
})
export class MenuComponent implements OnDestroy, OnChanges {

    public activeMenu?: LayoutEvents;
    public visibleEntries: MenuItem[] = [];
    @Input() menuEntries?: MenuEntry[];
    private _subs = new Subject();

    constructor(private readonly _eventHub: IwEventHubService<string>, public _menuState: SideMenuStateService,
                public readonly _profileService: ProfileService, public readonly _translate: TranslateService,
                private readonly _age: AgencyHandlerService) {
        this._menuState.sideMenuActiveChange
            .pipe(takeUntil(this._subs))
            .subscribe((e: LayoutEvents) => this.activeMenu = e);
    }

    private _open = false;

    public get open(): boolean {
        return this._open;
    }

    @Input()
    public set open(isOpen: boolean) {
        this._open = isOpen;
        // this.updateLabelEntries(isOpen);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['menuEntries']) {
            this.visibleEntries = await this.filterVisibleItems(changes['menuEntries'].currentValue);
            await this.addComandToMenuItems(this.visibleEntries);
        }
    }


    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }


    private async filterVisibleItems(entries: MenuItem[]): Promise<MenuItem[]> {
        const visibleItems: MenuItem[] = [];

        for (const entry of entries) {
            if (!entry.accessRoles && !entry.modules) {
                entry.visible = true;
                if (entry.items) {
                    entry.items = await this.filterVisibleItems(entry.items);  // Correct recursive call with await
                }
                visibleItems.push(entry);
                continue;
            }

            const moduleAccess = entry.modules ? await this._age.checkAgencyModules(entry.modules) : true;
            const userHasRole = this._profileService.checkUserPermission(entry.accessRoles || []);
            if (userHasRole && moduleAccess) {
                entry.visible = true;
                if (entry.items) {
                    entry.items = await this.filterVisibleItems(entry.items);  // Correct recursive call with await
                }
                visibleItems.push(entry);
            } else {
                entry.visible = false;
            }
        }

        return visibleItems;
    }

    private async addComandToMenuItems(items: MenuItem[]): Promise<MenuItem[]> {
        const list: MenuItem[] = [];

        for (const m of items) {
            if (m.items) {
                m.items = await this.addComandToMenuItems(m.items);
            }
            if (m.event) {
                const event = m.event;
                m.command = () => this.emitEvent(event);
            }

            list.push(m);
        }

        return list;
    }

    private emitEvent(event: string) {
        this._eventHub.emit(event);
    }


}
