import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SapaimodeService, SapaimodeSubscrition} from '@app/sam-base/core/services/sapaimode.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {PayType} from '@sam-base/models/salary/payment-type-enum';
import {Sapaimode} from '@sam-base/models/salary/sapaimode';
import {Subject, takeUntil} from 'rxjs';


@Component({
    templateUrl: './sapaimode.component.html',
    standalone: false
})
export class SapaimodeComponent extends BaseFormComponent<Sapaimode> implements OnInit {

    @ViewChild('sapaimodeGrid') public sapaimodeGrid?: IwRestGridComponent<Sapaimode>;
    public type: Type<Sapaimode> = Sapaimode;
    public query: RestQueryParam<Sapaimode, string | string>[] = [];
    public payType = PayType;
    public sapaimode: Sapaimode = new Sapaimode();
    public subscritions = new Subject();
    protected readonly payTypes = PayType;
    private selectedSapaimode: Sapaimode | undefined;

    public constructor(private readonly _store: IwStoreService, private readonly _sapaimodeService: SapaimodeService) {
        super();
    }

    private _isEditing = false;

    public get isEditing(): boolean {
        return this._isEditing;
    }

    private _isNew = false;

    public get isNew(): boolean {
        return this._isNew;
    }

    public get isSelected(): boolean {
        return this.selectedSapaimode !== undefined;
    }

    public ngOnInit(): void {
        this.setQuery();
    }

    public modifySapaimode() {
        this._isEditing = true;
    }

    public newEntry() {
        this.selectedSapaimode = undefined;
        this._isEditing = true;
        this._isNew = true;
        this.formGroup.reset();
    }

    public deleteEntry() {
        this._sapaimodeService.delete(this.getFormValue('mpaId'))
            .subscribe(() => {
                this._sapaimodeService.sapaimodeSub.next({
                    uuid: this.uuid,
                    saved: true
                });
            });
        this._sapaimodeService.sapaimodeSub
            .pipe(takeUntil(this.subscritions))
            .subscribe((data: SapaimodeSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.refreshGrid();
                }
            });
    }

    public saveEntry() {
        this.sapaimode.mpaId = this.getFormValue('mpaId');
        this.sapaimode.titre = this.getFormValue('titre');
        this.sapaimode.compte = this.getFormValue('compte');
        this.sapaimode.compte2 = this.getFormValue('compte2');
        this.sapaimode.compte3 = this.getFormValue('compte3');
        this.sapaimode.payType = this.getFormValue('payType');
        this.sapaimode.dtatype = this.getFormValue('dtatype');
        this.sapaimode.banque = this.getFormValue('banque');
        this.sapaimode.banqueClr = this.getFormValue('banqueClr');
        this.sapaimode.banqueCpt = this.getFormValue('banqueCpt');
        this.sapaimode.banqueSwi = this.getFormValue('banqueSwi');
        this.sapaimode.iban = this.getFormValue('iban');
        this.sapaimode.dtaiban = this.getFormValue('dtaiban');
        this._sapaimodeService.save(this.sapaimode)
            .subscribe(() => {
                this._sapaimodeService.sapaimodeSub.next({
                    uuid: this.uuid,
                    saved: true
                });
            });

        this._sapaimodeService.sapaimodeSub
            .pipe(takeUntil(this.subscritions))
            .subscribe((data: SapaimodeSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.refreshGrid();
                }
            });
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public getSelectedRow(event: Sapaimode[]) {
        this.setSapaimodeInfo(event);
    }

    public cancelEditionMode() {
        this._isEditing = false;
        this._isNew = false;
        this.applySapaimodeToVariables(this.selectedSapaimode);
    }

    protected getFormControlNames(): FormKeys<Sapaimode> {
        return [
            'mpaId',
            'titre',
            'compte',
            'compte2',
            'compte3',
            'payType',
            'dtatype',
            'banque',
            'banqueClr',
            'banqueCpt',
            'banqueSwi',
            'iban',
            'dtaiban'];
    }

    protected getValidationType() {
        return Sapaimode;
    }

    private setSapaimodeInfo(sapaimode: Sapaimode[]) {
        if (sapaimode.length > 0) {
            this.selectedSapaimode = sapaimode[0];
            this.applySapaimodeToVariables(sapaimode[0]);
        } else {
            this.selectedSapaimode = undefined;
        }
    }

    private applySapaimodeToVariables(sapaimode: Sapaimode | undefined) {
        this.fillFormData(sapaimode || {});
    }

    private setQuery() {
        this.query = [
            {
                operation: RestQueryOperation.NotEquals,
                prop: 'mpaId',
                value: '_'
            }];
    }

    private refreshGrid() {
        this._isNew = false;
        this._isEditing = false;
        this.sapaimodeGrid?.refresh();
        this.selectedSapaimode = undefined;
    }


}
