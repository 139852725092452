import {Component, Input} from '@angular/core';

@Component({
    templateUrl: './tax-institution-result-viewer.component.html',
    selector: 'tax-institution-result-viewer',
    standalone: false
})
export class TaxInstitutionResultViewerComponent {

    constructor() {
    }

    private _json?: TaxResult;
    public get json(): TaxResult | undefined {
        return this._json;
    }

    @Input()
    public set json(v: string) {
        if (v) {
            this._json = JSON.parse(v);
        }
    }
}
