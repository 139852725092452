/* eslint-disable complexity */
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {EsQueryStatement, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {GepaicliService} from '@app/sam-base/core/services/gepaicli.service';
import {GepaieleService} from '@app/sam-base/core/services/gepaiele.service';
import {GepaihdrService} from '@app/sam-base/core/services/gepaihdr.service';
import {GepaimodeService} from '@app/sam-base/core/services/gepaimode.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, IwGridColumn} from '@app/sam-base/models';
import {AttribuitionModel} from '@sam-base/models/attribuition.model';
import {Gecliview} from '@app/sam-base/models/invoices/gecliview';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {Gepaimode} from '@app/sam-base/models/invoices/gepaimode';
import {IwRestGridComponent} from '@sam-base/components';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {Gepaiele} from '@sam-base/models/invoices/gepaiele';
import {GepaieleView} from '@sam-base/models/invoices/gepaiele.view';
import {FacToExec, FormValues, OpenInvoice} from './encaissement-attribution.component';

export interface CalcSoldeAndModeDataManyCli {
    formValues: FormValues;
    lMiseEnCompte: boolean;
    gepar: undefined;
    escMaxTaux1: number;
    escMaxTaux2: number;
}

export interface PayeleCliAndIdAndFacId {
    cliId: string;
    payeleId: string;
    facId: string;
}

export interface GepaihdrDataManyCli {
    allCli: boolean;
    cliId: string;
    gepaihdr: Gepaihdr;
    facToExecList: FacToExec[];
    opeMode: string;
    mnt2Attribute: number;
    mntDelta: number;
    tauxDelta: number;
    mntSolde: number;
    tauxSolde: number;
    lFollowPayCli: boolean;
    soldeMpaId: string;
    cptDeb: string;
}

@Component({
    templateUrl: './encaissement-attribution-many-cli.component.html',
    standalone: false
})
export class EncaissementAttributionManyCliComponent extends BaseFormComponent<Gepaihdr> implements OnInit, OnDestroy {

    public comboGepaimode: ComboboxItem<string>[] = [
        {
            name: ' ',
            value: ''
        }];
    public numberOfFacs = 0;
    public listOfFacs: OpenInvoice[] = [];
    public listOfPaySelected: PayeleCliAndIdAndFacId[] = [];
    public forcerMontant = false;
    public sMntPay = 0;
    public mntFacSelected = 0;
    public mntPaySelected = 0;
    public mntSelectedPos = 0;
    public mntSelectedNeg = 0;
    public lCompensate = false;
    public mnt2Attribute = 0;
    public mntSolde = 0;
    public solde = 0;
    public tauxSolde = 0;
    public mntDelta = 0;
    public opeMode = '';
    public payExplanation = '';
    public tauxDelta = 0;
    public lattribSolde = false;
    public soldeMpaId = '';
    public nbFacSelected = 0;
    public options: Gepaimode[] = [];
    public allCli = false;
    public gepaihdr!: Gepaihdr;
    public facToExecList: FacToExec[] = [];
    public cptDeb = '';
    public payCliId: string | undefined;
    public clientType = Gecliview;
    public clientId = '';
    public clientNom = '';
    public lFollowPayCli = true;
    public lMiseEnCompte = false;
    @ViewChild('gepaieleGrid', {static: true}) public gepaieleGrid?: IwRestGridComponent<Gepaiele>;

    public tableColumns: IwGridColumn<AttribuitionModel>[] = [
        {
            prop: 'cliNom',
            name: 'cliNom',
            index: 0
        },
        {
            prop: 'facNo',
            name: 'facNo',
            index: 1
        },
        {
            prop: 'facOrNc',
            name: 'facornc',
            index: 2
        },
        {
            prop: 'dateFac',
            name: 'date',
            type: 'date',
            index: 3
        },
        {
            prop: 'montant',
            name: 'montant',
            index: 4,
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'mntRecu',
            name: 'mntRecu',
            index: 5,
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'solde',
            name: 'solde',
            index: 6,
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'cliId',
            name: 'cliId',
            index: 7
        }];
    public listViewColumns: IwGridColumn<GepaieleView>[] = [
        {
            prop: 'nom',
            name: 'cliNom',
            index: 0
        },
        {
            prop: 'facNo',
            name: 'facNo',
            index: 1
        },
        {
            prop: 'montant',
            name: 'montant',
            index: 2,
            type: 'mnt',
            align: 'right'
        },
        {
            prop: 'compte',
            name: 'compte',
            index: 3
        },
        {
            prop: 'lettrage',
            name: 't',
            index: 4
        },
        {
            prop: 'facId',
            name: 'facId',
            index: 5,
            hidden: true
        },
        {
            prop: 'payeleId',
            name: 'payeleId',
            index: 6,
            hidden: true
        }];
    public query: EsQueryStatement<GepaieleView>[] = [
        EsQueryStatement.fromTerm({payId: '_'}, 'must')];
    protected readonly gepaieleView = GepaieleView;

    constructor(private _gepaihdrService: GepaihdrService, private _gepaieleService: GepaieleService,
                private _gepaimodeService: GepaimodeService, private _gepaicliService: GepaicliService,
                private _eventHub: IwEventHubService<EncaissementEvents>, protected readonly _store: IwStoreService) {
        super();
    }

    public get mpaId(): string {
        return this.getData('mpaId', true) || '';
    }

    public get datePay(): string {
        return this.getData('datePay', true) || '';
    }

    public get montant(): number {
        return this.getData('montant', true) || 0;
    }

    public get cliId(): string {
        return this.getData('cliId', true) || '';
    }

    public get payId(): string {
        return this.getData('payId', true) || '';
    }

    public get cliNom(): string {
        return this.getData('cliNom', true) || '';
    }

    public ngOnInit(): void {
        this._gepaihdrService.getByPayId(this.payId)
            .subscribe((gepaihdr) => {
                this.gepaihdr = gepaihdr;
                this.sMntPay = this.gepaihdr.montant!;
                this.solde = this.gepaihdr.solde!;
            });
        this.loadGepaimodeOptions();
        // if (this.listOfPayEle.length > 0) {
        this._gepaicliService.getGepaicli(this.payId)
            .subscribe((gepaicli) => {
                this.payCliId = gepaicli.payCliId;
                this.clientId = gepaicli.cliId!;
            });
        // }
        this.query = [EsQueryStatement.fromTerm({payId: this.payId}, 'must')];
        this.calcPayData(true);
    }

    public ngOnDestroy(): void {
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public cut() {
        // Broken check with ju
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        this.listOfPaySelected.forEach((fac) => {
            this._gepaihdrService.desattribuerManyCli(this.gepaihdr, fac.facId, this.lFollowPayCli)
                .subscribe(() => {
                    this._eventHub.emit(EncaissementEvents.disociate);
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                    if (this.listOfFacs.length > 0) {
                        this._gepaihdrService.searchOpenFactures(this.clientId)
                            .subscribe((factures) => {
                                this.listOfFacs = factures;
                                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                            });
                    }
                    this.reloadListOfPayEle();
                    this.calcPayData(true);
                    this._gepaihdrService.recalcPaiement(this.gepaihdr);
                });
        });
    }

    public modify() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public saveChanges(): void {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        if (this.facToExecList.length > 0) {
            this.allCli = true;
        } else {
            this.allCli = false;
        }
        const gepaihdrDataManyCli: GepaihdrDataManyCli = {
            allCli: this.allCli,
            cliId: this.cliId,
            gepaihdr: this.gepaihdr,
            facToExecList: this.facToExecList,
            opeMode: this.opeMode,
            mnt2Attribute: this.mnt2Attribute,
            mntDelta: this.mntDelta,
            tauxDelta: this.tauxDelta,
            mntSolde: this.mntSolde,
            tauxSolde: this.tauxSolde,
            soldeMpaId: this.soldeMpaId,
            lFollowPayCli: this.lFollowPayCli,
            cptDeb: this.cptDeb
        };
        this._gepaihdrService.checkPaiement(this.gepaihdr, this.opeMode)
            .subscribe((canSave) => {
                if (canSave) {
                    this._gepaihdrService.execLettrage(gepaihdrDataManyCli)
                        .subscribe(() => {
                            // Broken check with ju
                            this._eventHub.emit(EncaissementEvents.saveAssign);
                            this.solde = this.solde - this.mnt2Attribute;
                            this.reloadListOfPayEle();
                            if (this.solde === 0) {
                                this._store.dispatch(new DestroyForm(this.uuid));
                            } else {
                                this.listOfFacs = [];
                                this.mnt2Attribute = 0;
                                this.nbFacSelected = 0;
                                this.mntFacSelected = 0;
                                this.mntDelta = 0;
                                this.tauxDelta = 0;
                                this.tauxSolde = 0;
                                this.soldeMpaId = '';
                                this.payExplanation = '';
                                this.lattribSolde = false;
                                this.lMiseEnCompte = false;
                                this.numberOfFacs = 0;
                                this.clientId = '';
                                this.clientNom = '';
                                // reset forcerMontant to false in order to not force next assignments by mistake
                                this.forcerMontant = false;
                                this._gepaihdrService.getByPayId(this.payId)
                                    .subscribe((gepaihdr) => {
                                        this.gepaihdr = gepaihdr;
                                        this.sMntPay = this.gepaihdr.montant!;
                                        this.solde = this.gepaihdr.solde!;
                                    });
                            }
                            this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                        });
                }
            });
    }

    public selectedChanged(openInvoice: OpenInvoice[]) {
        if (openInvoice.length) {
            this.mntFacSelected = openInvoice.reduce((partialSum, value) => partialSum + value.solde, 0);
            this.mntFacSelected = Math.round((this.mntFacSelected + Number.EPSILON) * 100) / 100;
            if (!this.forcerMontant) {
                this.mnt2Attribute = Math.min(this.mntFacSelected, this.solde);
            } else {
                this.mnt2Attribute = this.sMntPay;
            }
            this.mntDelta = this.mnt2Attribute - this.mntFacSelected;
            this.nbFacSelected = openInvoice.length;
        } else {
            this.mntFacSelected = 0;
            this.tauxDelta = 0;
            this.payExplanation = '';
            this.opeMode = '';
            this.lattribSolde = false;
            this.lMiseEnCompte = false;
            this.mnt2Attribute = 0;
            this.mntDelta = 0;
            this.tauxDelta = 0;
            this.tauxSolde = 0;
            this.soldeMpaId = '';
            this.clientId = '';
            this.clientNom = '';
        }
        this.facToExecList = openInvoice.map((invoice) => ({
            cliId: invoice.cliId,
            facId: invoice.facId,
            facNo: invoice.facNo,
            solde: invoice.solde,
            facOrNc: invoice.facOrNc,
        }));
        this.countFac();
    }

    public selectedChangedFacTable(openPayEle: GepaieleView[]) {
        this.mntPaySelected = openPayEle.reduce((montantFac, payEle) => (payEle.montant || 0) + montantFac, 0);
        this.mntPaySelected = Math.round((this.mntPaySelected + Number.EPSILON) * 100) / 100;
        this.listOfPaySelected = [];
        openPayEle.forEach((payEle) => this.listOfPaySelected.push({
            cliId: payEle.cliId || '',
            payeleId: payEle.payeleId || '',
            facId: payEle.facId || ''
        }));
        this.countFac();
    }

    public chosenClient(event: Event) {
        this.facToExecList = [];
        this.clientId = event as unknown as string;
        if (this.clientId !== '') {
            this._gepaihdrService.searchOpenFactures(this.clientId)
                .subscribe((listOfFactures) => {
                    this.listOfFacs = listOfFactures;
                    this.numberOfFacs = this.listOfFacs.length;
                });
        }
    }

    public calcPayData(event: any): void {
        // timeout in order to have latest value of forms
        setTimeout(() => {
            // Broken check with ju
            if (!this.forcerMontant) {
                this.mnt2Attribute = this.solde < this.mntFacSelected ? this.solde : this.mntFacSelected;
            }
            if (this.lattribSolde) {
                this.lMiseEnCompte = false;
            } else if (this.lMiseEnCompte) {
                this.lattribSolde = false;
            } else {
                this.lMiseEnCompte = false;
                this.lattribSolde = false;
            }
            if (event === true && this.facToExecList.length !== 0) {
                if (this.sMntPay) {
                    this._gepaihdrService.calcPayData(!this.forcerMontant ? this.solde : this.mnt2Attribute, this.facToExecList.length, this.mntFacSelected, this.lMiseEnCompte)
                        .subscribe((dataObject) => {
                            if (!this.forcerMontant) {
                                this.mnt2Attribute = dataObject.mnt2Attribute;
                            }
                            this.mntSolde = dataObject.mntSolde;
                            this.tauxSolde = dataObject.tauxSolde;
                            this.mntDelta = dataObject.mntDelta;
                            this.opeMode = dataObject.opeMode;
                            this.payExplanation = dataObject.payExplanation;
                            this.tauxDelta = dataObject.tauxDelta;
                            this.lattribSolde = dataObject.lattribSolde;
                            this.soldeMpaId = dataObject.soldeMpaId;
                            this.mntFacSelected = dataObject.mntFacSelected;
                            this.nbFacSelected = dataObject.nbFacSelected;
                        });
                }
            } else {
                if (this.gepaihdr) {
                    this.sMntPay = this.gepaihdr.montant!;
                }
            }

        }, 0);
    }

    public CalcSoldeAndMode(): void {
        if (this.sMntPay) {
            const formValues: FormValues = {
                mnt2Attribute: this.mnt2Attribute,
                mntSolde: this.mntSolde,
                tauxSolde: this.tauxSolde,
                mntDelta: this.mntDelta,
                opeMode: this.opeMode,
                payExplanation: this.payExplanation,
                tauxDelta: this.tauxDelta,
                lattribSolde: this.lattribSolde,
                soldeMpaId: this.soldeMpaId,
                mntFacSelected: this.mntFacSelected,
                nbFacSelected: this.nbFacSelected
            };
            const calcSoldeAndModeDataManyCli: CalcSoldeAndModeDataManyCli = {
                formValues,
                lMiseEnCompte: this.lMiseEnCompte,
                gepar: undefined,
                escMaxTaux1: 0,
                escMaxTaux2: 0
            };
            if (this.facToExecList.length > 0) {
                this._gepaihdrService.calcSoldeAndMode(calcSoldeAndModeDataManyCli)
                    .subscribe();
            }
        }
    }

    private reloadListOfPayEle() {
        this.gepaieleGrid?.refresh();
    }

    private loadGepaimodeOptions() {
        this._gepaimodeService.getGepaimodeByPaytype()
            .subscribe((gepaimodeList) => {
                this.comboGepaimode.push({
                    name: '----',
                    value: ''
                });
                gepaimodeList.forEach((gepaimode) => {
                    this.comboGepaimode.push({
                        name: gepaimode.titre,
                        value: gepaimode.mpaId
                    });
                });
            });
    }

    private async countFac() {
        this._gepaihdrService.countFacSelected(this.facToExecList)
            .subscribe((fac) => {
                this.nbFacSelected = fac?.nbFacSelected!;
                this.mntSelectedNeg = fac?.mntSelectedNeg!;
                this.mntSelectedPos = fac?.mntSelectedPos!;
                this.calcPayData(true);
            });
    }
}
