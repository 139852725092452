import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppcde} from '@app/sam-base/models/placement';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as xml2js from 'xml2js';

interface WebDataForm {
    txtclient?: string;
    txtmission?: string;
    txtprofil?: string;
    txtdivers?: string;
}

@Component({
    selector: 'iw-tab-ppcdetempo-descremarques',
    templateUrl: './tab-ppcdetempo-descremarques.component.html',
    standalone: false
})
export class TabPpcdetempoDescremarquesComponent extends BasePartialFormComponent<Ppcde> implements OnInit, OnDestroy {

    public webDataForm: WebDataForm = {
        txtclient: '',
        txtdivers: '',
        txtprofil: '',
        txtmission: ''
    };
    @Input() public isReadonly = true;
    private webDataSub = new Subscription();
    private subscriptions = new Subject();

    constructor() {
        super();
    }

    public get client() {
        return this.webDataForm.txtclient;
    }

    public get mission() {
        return this.webDataForm.txtmission;
    }

    public get profil() {
        return this.webDataForm.txtprofil;
    }

    public get divers() {
        return this.webDataForm.txtdivers;
    }

    public ngOnInit() {
        this.webDataForm = this.xmlToJSONParse(this.getFormValue('webData') || '');

        if (this.formGroup) {
            this.webDataSub = this.formGroup.controls['webData']
                .valueChanges
                .pipe(takeUntil(this.subscriptions))
                .subscribe((val: string | undefined) => {
                    if (val !== this.jsonToXMLParse(this.webDataForm)) {
                        this.webDataForm = this.xmlToJSONParse(val || '');
                    }
                });
        }
    }

    public ngOnDestroy() {
        this.webDataSub.unsubscribe();
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public updateWebData(type: string, value: string) {
        switch (type) {
            case 'client':
                this.webDataForm.txtclient = value;
                break;
            case 'mission':
                this.webDataForm.txtmission = value;
                break;
            case 'profil':
                this.webDataForm.txtprofil = value;
                break;
            case 'divers':
                this.webDataForm.txtdivers = value;
                break;
        }

        this.setFormValue('webData', this.jsonToXMLParse(this.webDataForm));
    }

    private xmlToJSONParse(xml: string): WebDataForm {
        const parseString = xml2js.parseString;
        const formattedXml = '<root>' + xml + '</root>';

        let jsonResult: WebDataForm = {};

        if (!xml) {
            return jsonResult;
        }

        const options = {
            explicitArray: false,
            explicitRoot: false
        };

        parseString(formattedXml, options, (err: any, result: WebDataForm) => {
            jsonResult = result;
        });
        return jsonResult || {};
    }

    private jsonToXMLParse(json: any): string {
        const builder = new xml2js.Builder({headless: true});
        const xmlString = builder.buildObject(json)
            .replace(/(<root>\s\s)|(\B[ ]\B)|(\s\s+?)|(\n<\/root>)/g, '');

        return xmlString;
    }
}
