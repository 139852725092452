import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sags} from '@app/sam-base/models/salary';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {ParametersService} from '@core/services/parameters.service';

import {CorrModA, CorrModF} from '../genre-salaire.enums';

@Component({
    selector: 'iw-tab-sags-donnes-base', templateUrl: './tab-sags-donnes-base.component.html',
    standalone: false
})
export class TabSagsDonnesBaseComponent extends BasePartialFormComponent<Sags> implements OnInit {

    public modeCorrectionOptions = {};
    public sapar?: Sapar;

    constructor(private _parametersService: ParametersService) {
        super();
    }

    public get showGscType(): boolean {
        return ['A', 'F'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableGsOppDropdown(): boolean {
        return ['1', '2'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get showGsProv(): boolean {
        return ['B'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableBaseBrut(): boolean {
        return ['1', '5', '7', 'C'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableM1(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.mj1Active;
    }

    public get enableM2(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.mj2Active;
    }

    public get enableM3(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.mj3Active;
    }

    public get enableM4(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.mj4Active;
    }

    public get enableAvsAcLaaPgmLppImpots(): boolean {
        return ['1', '5', '7', 'A', 'B', 'C']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableNb(): boolean {
        return ['1'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableLaac(): boolean {
        return ['1', '5', '7', 'A', 'B', 'C']
            .includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.lacActive;
    }

    public get enableD1(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.cu1Active;
    }

    public get enableD2(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.cu2Active;
    }

    public get enableD3(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.cu3Active;
    }

    public get enableD4(): boolean {
        return ['1', '5', '7', 'A'].includes(this.getFormValue('gsCtype') ?? '') && !!this.sapar?.cu4Active;
    }

    public get enableNonSumis(): boolean {
        return ['3'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get showPrintIt(): boolean {
        return ['4', '5', 'A', 'B', 'D', 'F', 'V']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSalBase(): boolean {
        return ['1', '2', '3', '4', '7', '8', '9', 'B']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSalHor(): boolean {
        return ['1', '2', '3', '4', '7', '8', '9']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSalHorMnt(): boolean {
        return ['1', '2', '3', '4', '7', '8', '9', 'B']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSalHorLpp(): boolean {
        return ['A'].includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSalLppmnt(): boolean {
        return ['1', '2', '3', '4', '7', '8', '9', 'A', 'B']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSalJour(): boolean {
        return ['1', '2', '3', '4', '7', '8', '9']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get mj1Label() {
        return !!this.sapar?.mj1Active ? this.sapar?.mj1Shtit : '*****';
    }

    public get mj2Label() {
        return !!this.sapar?.mj2Active ? this.sapar?.mj2Shtit : '*****';
    }

    public get mj3Label() {
        return !!this.sapar?.mj3Active ? this.sapar?.mj3Shtit : '*****';
    }

    public get mj4Label() {
        return !!this.sapar?.mj4Active ? this.sapar?.mj4Shtit : '*****';
    }

    public get cu1Label() {
        return !!this.sapar?.cu1Active ? this.sapar?.cu1Shtit : '*****';
    }

    public get cu2Label() {
        return !!this.sapar?.cu2Active ? this.sapar?.cu2Shtit : '*****';
    }

    public get cu3Label() {
        return !!this.sapar?.cu3Active ? this.sapar?.cu3Shtit : '*****';
    }

    public get cu4Label() {
        return !!this.sapar?.cu4Active ? this.sapar?.cu4Shtit : '*****';
    }

    public ngOnInit() {
        this.correctionModeOptions();
        this._parametersService.getCurrentSapar()
            .subscribe((sapar: Sapar) => this.sapar = sapar);
        this.formGroup?.controls['lppfact']
            .setValidators([Validators.min(0), Validators.max(13)]);
    }

    private correctionModeOptions() {
        this.subscribeValueChange('gsCtype', (type?: string) => {
            if ('A' === type) {
                if (this.getFormValue('corrmode') === 0) {
                    this.setFormValue('corrmode', 1);
                }
                this.modeCorrectionOptions = CorrModA;
            } else {
                if (this.getFormValue('corrmode') === 0) {
                    this.setFormValue('corrmode', 1);
                }
                this.modeCorrectionOptions = CorrModF;
            }
            this.setFormValue('corrmode', 0);
        });
    }

}
