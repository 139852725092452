import {Component, EventEmitter, OnInit} from '@angular/core';
import {ModalComponent} from '@app/sam-base/models';
import {
    ModalOperationDetailOptions
} from '@modules/sam-main/admin/components/operation/operation-detail-modal/operation-modal.options';
import {Operation, OperationDetail} from '@modules/sam-main/admin/models/operation.model';
import {OperationService} from '@modules/sam-main/admin/services/operation-service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from '@sam-base/core/toast';


@Component({
    templateUrl: './modal-operation-detail.component.html',
    standalone: false
})
export class ModalOperationDetailComponent implements ModalComponent<void, ModalOperationDetailOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public operations: Operation[] = [];
    public operationDetail?: OperationDetail;
    public loading = false;
    private _ok?: () => void;
    private _cancel?: () => void;
    private operationId: number | undefined;

    constructor(private _translate: TranslateService,
                private _toastService: ToastService,
                private readonly _operationService: OperationService) {
    }

    ngOnInit(): void {
        this.loadOperation();
    }

    public setData(data: ModalOperationDetailOptions): Promise<void> {
        this.operationId = data?.operationId;
        return Promise.resolve();
    }


    public registerOk(action: (result: void) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onOperationClicked($event: Operation) {
        this._operationService.getOperationDetails($event.id!).subscribe(
            {
                next: (detail) => this.operationDetail = detail,
            }
        )
    }

    public onClose() {
        if (this._ok) {
            this._ok();
        }
    }

    private loadOperation() {
        if (!this.operationId) return;
        this._operationService.getOperationDetails(this.operationId).subscribe((details) => {
            this.operationDetail = details;
        })
    }
}
