import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {Gecliadr} from '@app/sam-base/models/invoices/gecliadr';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import {Gepar} from '@app/sam-base/models/invoices/gepar';
import {ParametersService} from '@core/services/parameters.service';

@Component({
    selector: 'iw-tab-facture-adresse',
    templateUrl: './tab-facture-adresse.component.html',
    standalone: false
})
export class TabFactureAdresseComponent extends BasePartialFormComponent<Gefachdr> implements OnInit {

    public adresseValue = '';

    public factors = false;

    public yellowBill = '';
    private readonly _entityService: RestEntityClient<Gecliadr>;

    constructor(private _restService: RestApiService, private _parameterService: ParametersService) {
        super();
        this._entityService = _restService.getEntityClient(Gecliadr);
    }

    public get isCreditNote(): boolean {
        return this.getFormValue('facOrnc') === 'N';
    }


    public get cliId() {
        return this.getFormValue('cliId') || '';
    }

    public get parent2() {
        return this.getFormValue('parent2') || '';
    }

    public ngOnInit() {
        this._parameterService.getGepar()
            .subscribe((gepar: Gepar) => {
                this.factors = gepar.factors || false;
                this._parameterService.getMemoField('YELLOWBILL')
                    .subscribe((el: string) => {
                        this.yellowBill = el;
                    });
            });
    }

    public getTxtOrigine() {
        const facType = this.getFormValue('facType');
        switch (facType) {
            case 'FA':
                return '';
            case 'PT':
                return 'Tempo';
            case 'PM':
                return 'Tempo/Mois';
            case 'PF':
                return 'Fixe';
            default:
                return '';
        }
    }

    public getTxtOrigineText() {
        const facType = this.getFormValue('facType');
        switch (facType) {
            case 'PT':
                return 'Période: ' + this.getFormValue('parent');
            case 'PM':
                return 'Période: ' + this.getFormValue('parent');
            case 'PF':
                return 'Placement No: ' + this.getFormValue('parent');
            default:
                return '';
        }
    }

    public setAdresse() {
        this._entityService.getById(this.getFormValue('cliAdrId') || '')
            .subscribe((val: Gecliadr) => {
                this.adresseValue = this.buildAddress(val);
            });
    }

    public resultFilters: (e: Gecliadr) => boolean = (e) => e.cliId === this.cliId;

    // eslint-disable-next-line complexity
    private buildAddress(p: Gecliadr) {
        let fullAdresse = '';

        if (p.prefixe) {
            fullAdresse += `${p.prefixe}\n`;
        }
        if (p.nom) {
            fullAdresse += `${p.nom}\n`;
        }
        if (p.adresse1) {
            fullAdresse += `${p.adresse1}\n`;
        }
        if (p.adresse2) {
            fullAdresse += `${p.adresse2}\n`;
        }
        if (p.adresse3) {
            fullAdresse += `${p.adresse3}\n`;
        }
        if (p.npa || p.lieu) {
            fullAdresse += `${p.npa} ${p.lieu}\n`;
        }
        return fullAdresse;
    }

}
