import {Component, OnDestroy, OnInit} from '@angular/core';
import {EntityService} from '@app/modules/sam-main/placement/services/entity.service';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {EmailContractOptions} from '@app/sam-base/components/modal/modal-confirm-dialog/modal-contract-enum';
import {ModalContractComponent} from '@app/sam-base/components/modal/modal-confirm-dialog/modal-contract.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {RestApiService, RestEntityClient, ViewContainerRefService} from '@app/sam-base/core';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {ToastService} from '@app/sam-base/core/toast/toast.service';
import {Ppclimis, Ppmis} from '@app/sam-base/models/placement';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';

import {Actions, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {missionRoles} from '@sam-base/core/auth/access-rules/mission-form';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MissionService} from '../../services/mission.service';
import {MiscpyComponent} from './miscpy/miscpy.component';
import {MiscpyOptions} from './miscpy/miscpy.model';
import {missionOCAlerts} from './mission-enums';

@Component({
    templateUrl: './missions-sidemenu.component.html',
    standalone: false
})
export class MissionsSidemenuComponent extends BaseSideFormComponent<Ppmis> implements OnInit, OnDestroy {

    public missionRoles: string[] = [];
    public ppmis?: Ppmis;
    public entityService: RestEntityClient<Ppmis>;
    private subscriptions = new Subject();
    private readonly openCloseAllowedStatus = [
        1,
        2,
        3,
        4];

    constructor(private readonly store: IwStoreService, private _modalService: ModalService,
                private _entityNavService: EntityNavigationService, private _translate: TranslateService,
                private _entityService: EntityService, private _restService: RestApiService,
                private _toastService: ToastService, private _viewHostRef: ViewContainerRefService,
                private _missionService: MissionService, private _actions: Actions) {
        super();
        this.entityService = _restService.getEntityClient(Ppmis);
    }

    public get isMissionCreating() {
        return this.ppmis?.creating;
    }

    private get isMissionOpened() {
        return this.ppmis?.misstatus && this.ppmis?.misstatus <= 2;
    }

    private get ocDialogTitle() {
        return this.isMissionOpened ? 'close_mission' : 'open_mission';
    }

    private get ocAConfirmMessage(): string {
        if (!this.ppmis || !this.ppmis.misstatus) {
            return '';
        }
        return missionOCAlerts[this.ppmis.misstatus] ?? '';
    }

    private get canOCMission() {
        if (!this.ppmis || !this.ppmis.misstatus) {
            return false;
        }
        return this.openCloseAllowedStatus.includes(this.ppmis.misstatus);
    }

    public ngOnInit() {
        this.store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.ppmis = data;
                this.missionRoles = missionRoles();
            });
        this.subscribeToSaveEffect();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public async showMiscpyModal() {
        const options: MiscpyOptions = {
            title: 'miscpy_form_title',
            ppmis: this.ppmis
        };

        try {
            const newMission: Ppmis = await this._modalService
                .showModal(MiscpyComponent, options);
            if (!newMission || !newMission.misId) {
                return;
            }
            this._entityNavService.navigateToEntityForm(Ppclimis, newMission.misId, undefined, 'edit');
            this.store.dispatch(new globalActions.DestroyForm(this.uuid));
        } catch (error) {
        }
    }

    public async openCloseMissionDialog() {
        if (!this.canOCMission) {
            return;
        }
        const options: ModalMessageOptions = {
            message: [],
            showCancel: true,
            title: this._translate.instant(this.ocDialogTitle),
            confirmMessage: this._translate.instant(this.ocAConfirmMessage),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            if (this.isMissionOpened) {
                this.closeMission(this.ppmis?.misId);
                return;
            }
            this.openMission(this.ppmis?.misId);
        } catch (error) {
            return false;
        }
    }

    public openModalToSendEmail() {
        this._viewHostRef.loadComponent({
            type: ModalContractComponent,
            data: {
                onOk: (radioValue: EmailContractOptions) => {
                    if (radioValue === EmailContractOptions.Employee) {
                        this.showPrintModal(CommunicationType.PPMIS_CM);
                    }
                    if (radioValue === EmailContractOptions.Client) {
                        this.showPrintModal(CommunicationType.PPMIS_CL);
                    }
                },
                title: 'email'
            }
        });
    }

    private showPrintModal(communicationType: CommunicationType) {
        this._modalService.showModal(MailComposerComponent, {
            communicationType,
            entityIds: [this.ppmis?.misId || '']
        });
    }

    private closeMission(misId?: string) {
        if (!misId) {
            return;
        }
        this._entityService.closeMission(misId)
            .subscribe(() => this.store.dispatch(new globalActions.LoadEntity(this.uuid)), error => this._toastService.warning('close_mission_error'));
    }

    private openMission(misId?: string) {
        if (!misId) {
            return;
        }
        this._entityService.openMission(misId)
            .subscribe(() => this.store.dispatch(new globalActions.LoadEntity(this.uuid)), error => this._toastService.warning('open_mission_error'));
    }

    private subscribeToSaveEffect() {
        this._actions
            .pipe(takeUntil(this.subscriptions), ofType(actions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe((e) => {
                const uuid = e['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }
                this.entityService.getById(this.ppmis?.misId ?? '')
                    .subscribe((data: Ppmis) => {
                        this.ppmis = data;
                        this.missionRoles = missionRoles();
                    });
            });
    }
}
