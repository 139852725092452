import {CommonModule, NgIf} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MapsService} from '@app/sam-base/core';
import {IwEventRegisterService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {CommandeService} from '@app/sam-base/core/services/commande.service';
import {EntityUpdateService} from '@modules/sam-main/admin/services/entity-update.service';
import {ADMIN_ENTITIES} from '@sam-base/models/admin/admin-type-group';
import {SharedModule} from '@shared/shared.module';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {MeterGroupModule} from 'primeng/metergroup';

import {ScrollPanelModule} from 'primeng/scrollpanel';
import {StepperModule} from 'primeng/stepper';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {PLACEMENT_FORMS} from './admin-forms-collection';
import {ADMIN_COMPONENT_LIST, ADMIN_FORMS_ENTRY_COMPONENTS} from './admin-forms.list';
import {AdminForm} from './admin.forms';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        FileUploadModule,
        TooltipModule,
        TableModule,
        TabViewModule,
        FieldsetModule,
        ScrollPanelModule,
        MeterGroupModule,
        TabViewModule,
        StepperModule,
        TreeModule,
        NgIf,
    ],
    declarations: [
        ...ADMIN_COMPONENT_LIST,
        ...ADMIN_FORMS_ENTRY_COMPONENTS],
    providers: [
        MapsService,
        CommandeService,
        EntityUpdateService]
})
export class AdminModule {
    constructor(_formHandlerService: FormHandlerService<AdminForm>, _restApiService: RestApiService,
                _eventsHandlers: IwEventRegisterService) {
        _formHandlerService.registerForms(PLACEMENT_FORMS);
        _restApiService.registerEntityTypes(...ADMIN_ENTITIES); // why is it in another module ? not changing now but will check if should not be inside this module
    }
}
