<form [formGroup]="formGroup">

    <div class="row">
        <iw-checkbox #chkLpointeuse
                     [isDisabled]="isReadonly"
                     [label]="'pointeuse' | translate"
                     class="iw-input col-xs-12 no-padding"
                     formControlName="lpointeuse"></iw-checkbox>
    </div>

    <div class="row">
        <iw-enum-dropdown #cboPointType
                          [inputEnum]="pointtype"
                          [isDisabled]="!chkLpointeuse || isReadonly"
                          [labelAlign]="'top'"
                          [label]="'typePointeuse' | translate"
                          class="iw-input col-xs-5 no-padding"
                          formControlName="pointtype"
                          style="margin-right: 50%"></iw-enum-dropdown>
    </div>
    <div class="row">
        <iw-textfield #txtPointclino
                      [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'noClientVolotime' | translate"
                      class="iw-input col-xs-5 no-padding"
                      formControlName="pointclino"
                      style="margin-right: 50%"></iw-textfield>

    </div>
    <div class="row">
        <iw-enum-dropdown #cboPoint_mode
                          [inputEnum]="pointMode"
                          [isDisabled]="!chkLpointeuse || isReadonly"
                          [labelAlign]="'top'"
                          [label]="'modeTraitement' | translate"
                          class="iw-input col-xs-5 no-padding"
                          formControlName="pointMode"
                          style="margin-right: 50%"></iw-enum-dropdown>
    </div>


    <div class="col-xs-12 separator-top no-padding"></div>
    <div class="row">
        <iw-checkbox #chklexpmis2ij
                     [isDisabled]="isReadonly"
                     [label]="'interijob' | translate"
                     class="iw-input col-xs-12 no-padding"
                     formControlName="lexpmis2Ij"></iw-checkbox>

    </div>


    <div class="row">
        <iw-checkbox #chklexpmis2ij
                     [isDisabled]="isReadonly"
                     [label]="'facSendToIJ' | translate"
                     class="iw-input col-xs-12 no-padding"
                     formControlName="lexpfac2ij"></iw-checkbox>

    </div>

    <div class="row">
        <iw-enum-dropdown #cboij_rap_sm
                          [inputEnum]="ijRapSm"
                          [isDisabled]="!chklexpmis2ij || isReadonly"
                          [labelAlign]="'top'"
                          [label]="'modeSaisie' | translate"
                          class="iw-input col-xs-5 no-padding"
                          formControlName="ijRapSm"></iw-enum-dropdown>

        <iw-enum-dropdown #cboicc2ijmod
                          [inputEnum]="icc2ijmod"
                          [isDisabled]="!chklexpmis2ij || isReadonly"
                          [labelAlign]="'top'"
                          [label]="'publicationIjCc' | translate"
                          class="iw-input col-xs-5 col-xs-offset-2 no-padding"
                          formControlName="icc2ijmod"></iw-enum-dropdown>
    </div>


    <div class="row">
        <iw-checkbox #chktimeslotsoptional
                     [isDisabled]="isReadonly"
                     [label]="'timeslots_optional' | translate"
                     class="iw-input col-xs-12 no-padding"
                     formControlName="ijTsReq"></iw-checkbox>

    </div>

    <div class="row">
        <iw-checkbox #chkRap1Month
                     [isDisabled]="isReadonly"
                     [label]="'ijRap1M' | translate"
                     class="iw-input col-xs-12 no-padding"
                     formControlName="ijRap1M"></iw-checkbox>

    </div>

    <div class="row">
        <iw-textarea #edtIJ_data
                     [height]="90"
                     [isDisabled]="true"
                     [labelAlign]="'top'"
                     [label]="'parametres' | translate"
                     class="iw-textarea col-xs-12"
                     formControlName="ijData"></iw-textarea>
    </div>

</form>
