import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    BulkEntitySelectComponent
} from '@modules/sam-main/admin/components/bulk/bulk-entity-select/bulk-entity-select.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {environment} from '@root/environments/environment';
import {EsQueryStatement, Term, Terms} from '@sam-base/core';
import {Saempview} from '@sam-base/models/salary';

@Component({
    selector: 'iw-bulk-entity-select-saemp',
    templateUrl: './bulk-entity-select.component.html',
    standalone: false
})
export class BulkEntitySelectSaempComponent extends BulkEntitySelectComponent<Saempview> implements OnInit, OnDestroy {
    public entity: Type<Saempview> = <any>Saempview;

    @Input()
    public operationType?: OperationType;

    @Input()
    public salId?: string;

    @Input()
    public year?: string;

    constructor(private readonly http: HttpClient) {
        super();
    }


    public ngOnInit() {
        this.buildQueryStatements();
        this.setCustomColumns();
    }

    private buildQueryStatements() {

        if (this.operationType === OperationType.CREATE_SALARY) {
            this.http.get<string[]>(`${environment.backendURL}fichesal/empIdsToGenerateForSalId/${this.salId}`).subscribe(empIds => {
                const list: EsQueryStatement<Saempview>[] = [];
                list.push(EsQueryStatement.fromTerm(<Term<Saempview>>{type: 'saempview'}, 'filter'));
                list.push(EsQueryStatement.fromTerms(<Terms<Saempview>>{empId: empIds}, 'must'));
                if (this.operationType === OperationType.CREATE_SALARY) {
                }
                this.statements = list;
                // Process empIds as needed
            });
        }
        if (this.operationType === OperationType.EMAIL_YEAR_SALARIES) {
            this.http.get<string[]>(`${environment.backendURL}fichesal/fichesalYearByMail/${this.year}`).subscribe(empIds => {
                const list: EsQueryStatement<Saempview>[] = [];
                list.push(EsQueryStatement.fromTerm(<Term<Saempview>>{type: 'saempview'}, 'filter'));
                list.push(EsQueryStatement.fromTerms(<Terms<Saempview>>{empId: empIds}, 'must'));
                list.push(EsQueryStatement.fromTerm(<Term<Saempview>>{bsToEmail: 'true'}, 'must'));
                this.statements = list;
                // Process empIds as needed
            });
        }

    }

    private setCustomColumns() {
        this.columns = [
            {
                prop: 'empId',
                type: 'string'
            },
            {
                prop: 'nom',
                type: 'string'
            },
            {
                prop: 'prenom',
                type: 'string',
            },
            {
                name: 'agence',
                prop: 'categorie1',
                type: 'string',
            },
            {
                name: 'nomsecteur',
                prop: 'categorie2',
                type: 'string',
            },
        ];
    }
}
