import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MessageFormRefreshService} from '@modules/sam-main/subjects/message-service-subject.service';
import {BaseFormComponent} from '@sam-base/base';
import {IwStoreService} from '@sam-base/core';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@sam-base/models';
import {Gefacele} from '@sam-base/models/invoices/gefacele';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';


@Component({
    templateUrl: './gefacele.component.html',
    standalone: false
})
export class GefaceleComponent extends BaseFormComponent<Gefacele> implements OnInit {

    public canTexte = false;
    private gefaceleIndex?: number;
    private gefachdr?: Gefachdr;
    private faceleListFormGroup?: UntypedFormGroup;

    public constructor(private readonly _store: IwStoreService,
                       private readonly refreshFormMessageService: MessageFormRefreshService) {
        super();
    }

    public get elem(): Gefachdr {
        return this.gefachdr!;
    }

    public get tvaInclus(): string {
        return this.elem.tvaInclus ? 'TTC' : 'HT';
    }

    public get isValid(): boolean {
        return this.formGroup.valid;
    }

    public ngOnInit(): void {
        const gefachdr = this.getData<Gefachdr>('gefachdr')!;
        this.gefachdr = gefachdr;
        const gefacele = this.getData<Gefacele>('gefacele')!;
        this.fillFormData(gefacele);
    }

    public saveChanges(): void {
        const gefacele = this.getFormData()!
        const onOKCallback: (facele: Gefacele) => void = this.getData<any>('onOKCallback')!;
        onOKCallback(gefacele);
        this.closeDialog();
    }

    public calcTotal(event: boolean): void {
        const qte = Number(this.getFormValue('nqte'));
        const prix = Number(this.getFormValue('prixUnite'));
        this.setFormValue('montant', this.round((qte * prix), event ? 0.05 : 0.01));
        //this.refreshFormMessageService.sendMessage('facture-nc');
    }

    public round(value: number, step: number) {
        const inv = 1.0 / step;
        return Math.round(value * inv) / inv;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public changeValidators() {
        this.formGroup?.controls['libelle'].setValidators(Validators.required);
    }

    protected getValidationType() {
        return Gefacele;
    }

    protected validateFields(e: Gefacele): boolean {
        return super.validateFields(e);
    }

    protected getFormControlNames(): FormKeys<Gefacele> {
        return [
            'facId',
            [
                'nqte',
                new UntypedFormControl(undefined, Validators.required)],
            'unite',
            [
                'prixUnite',
                new UntypedFormControl(undefined, Validators.required)],
            'montant',
            'custom1',
            'custom2',
            'custom3',
            'custom4',
            'custom5',
            [
                'secId',
                new UntypedFormControl(undefined, Validators.required)],
            [
                'conId',
                new UntypedFormControl(undefined, Validators.required)],
            'conId2',
            'conId2Pc',
            [
                'compte',
                new UntypedFormControl(undefined, Validators.required)],
            'texte',
            [
                'libelle',
                new UntypedFormControl(undefined, Validators.required)]];
    }
}
