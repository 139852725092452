import {Component, OnInit, Type} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {RestQueryParam} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppjf} from '@app/sam-base/models/placement';

@Component({
    templateUrl: './ppjfcalc.component.html',
    standalone: false
})
export class PpjfcalcComponent extends BaseStoreFormComponent<Ppjf> implements OnInit {

    public type: Type<Ppjf> = Ppjf;
    public query: RestQueryParam<Ppjf, string>[] = [];
    public columns = this.getPpjfColumns();

    constructor(store: IwStoreService) {
        super(store);
    }

    public ngOnInit() {
        const dt = this.getData('ppjf');
        if (dt instanceof Ppjf) {
            this.setFormValue('jfId', dt.jfId);
        }
    }

    protected getFormControlNames(): FormKeys<Ppjf> {
        return ['jfId', 'jfdate', 'dateCreat', 'userModif', 'userId', 'canton', 'dateDelet', 'jflibelle', 'dateModif', 'userDelet', 'userCreat', 'cantons', 'griType', 'hmin', 'nbjperiode', 'l2Jsuiv'];
    }

    private getPpjfColumns(): IwGridColumn<Ppjf>[] {
        return [{
            prop: 'jfdate', index: 0
        }, {
            prop: 'jflibelle', name: ' ', index: 1
        }, {
            prop: 'jfId', name: ' ', index: 2
        }];
    }
}
