import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SaempService} from '@app/sam-base/core/services/saemp.service';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {ComboboxItem} from '@app/sam-base/models';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Saempop} from '@app/sam-base/models/salary/saempop';
import {Bank} from '@sam-base/models/common/bank';
import {DtaType, PayType} from '@sam-base/models/salary/payment-type-enum';
import {IwTextfieldBankComponent} from '@shared/widgets/form-components/iw-textfield-bank/iw-textfield-bank.component';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-paiement',
    templateUrl: './tab-paiement.component.html',
    standalone: false
})
export class TabPaiementComponent extends BasePartialFormComponent<Saempc> implements OnInit {

    // Enum with available values in dropdown Paiement
    public paysType = PayType;
    // Enum with available values in dropdown Dta/Opae
    public dtaType = DtaType;

    public type: Type<Saempop> = Saempop;

    public query: RestQueryParam<Saempop, any>[] = [];
    public paiementSalIdOptions: ComboboxItem<string>[] = [];
    @ViewChild('txtBank', {static: false}) public bankTxt?: IwTextfieldBankComponent;
    protected readonly PayType = PayType;

    constructor(private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _saempservice: SaempService, private readonly _saemsService: SaemsService) {
        super();
    }

    public get payadoth(): boolean {
        return this.getFormValue('payadoth') || false;
    }

    public get paypaysoth(): string {
        return this.getFormValue('paypaysoth') || '';
    }

    public get saempId() {
        return this.getFormValue<string>('empId');
    }

    public ngOnInit() {
        this.subscribeValueChange('paytype', () => this.payTypeChanges(), 200);
        this.updateFilters();
        if (this.getFormValue('paytype') === '3') {
            this.getPaiementSalIdOptionsByEmpId(this.saempId || '');
        }
        this.subscribeValueChange('empId', (empId: string | undefined) => {
            this.updateFilters();
            this.updateIbanValidator();
            this.getPaiementSalIdOptionsByEmpId(empId || '');
        });

        this.subscribeValueChange('dtatype', () => this.dtaTypeChanges(), 200);
    }

    public onSelected(e: Bank) {
        // this.setFormValue('banqueAdr', `${e.lieu} ${e.npa}`);
        this.setFormValue('banqueAdr', e.postalAddress);
        this.setFormValue('banqueClr', e.iid);
        this.setFormValue('banqueSwi', e.bic);

    }

    /**
     * Change fields when paytype change
     */
    public payTypeChanges() {
        if (this.isReadonly) {
            return;
        }
        const pay = this.getFormValue<number>('paytype');
        if (pay === PayType.dta) {
            this.setFormValue('dtatype', 5);
        } else {
            this.setFormValue('dtatype', 0);
            this.setFormValue('banque', '');
            this.setFormValue('banqueAdr', '');
            this.setFormValue('banqueClr', '');
            this.setFormValue('banqueCpt', '');
            this.setFormValue('banqueSwi', '');
        }
    }

    /**
     * Change fields when dtatype change
     */
    public dtaTypeChanges() {
        if (this.isReadonly) {
            return;
        }
        const dta = this.getFormValue<number>('dtatype');
        if (dta === DtaType.ccp) {
            this.setFormValue('banque', 'CCP');
            this.setFormValue('banqueAdr', '');
            this.setFormValue('banqueClr', '');
            this.setFormValue('banqueCpt', '');
            this.setFormValue('banqueSwi', '');
        }
        if (dta === DtaType.banque || dta === DtaType.ccp) {
            this.formGroup?.controls['banqueCpt'].setValidators(Validators.required);
            this.formGroup?.controls['banqueCpt'].updateValueAndValidity();
        } else {
            this.formGroup?.controls['banqueCpt'].clearValidators();
            this.formGroup?.controls['banqueCpt'].updateValueAndValidity();
        }
        this.updateIbanValidator();
    }

    /**
     * Check if field dtatype is enabled
     */
    public checkDtaTypeEnabled() {
        const pay = this.getFormValue<number>('paytype');
        return !this.isReadonly && pay === 3;
    }

    /**
     * Check if fields are enabled regarding paytype and dtatype value
     *
     * @param pay value of paytype needed to validate field
     * @param dta value of dtatype needed to validate field
     */
    // eslint-disable-next-line complexity
    public checkFieldsEnabled(pay: number[], dta: number[]): boolean {
        const payValue = this.getFormValue<number>('paytype') || -1;
        const dtaValue = this.getFormValue<number>('dtatype') || -1;
        if (pay && dta) {
            return !this.isReadonly && pay.includes(payValue) && dta.includes(dtaValue);
        }
        if (pay) {
            return !this.isReadonly && pay.includes(payValue);
        }
        if (dta) {
            return !this.isReadonly && dta.includes(dtaValue);
        }
        return false;
    }

    public async clearingsClick() {
        if (!this.bankTxt) {
            return;
        }
        await this.bankTxt.onDoubleClick();
    }

    private updateFilters() {
        const empId = this.getFormValue('empId');
        if (!empId) {
            return;
        }

        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: empId
            }];

    }

    private updateIbanValidator() {
        this._saempservice.checkIbanValidators(this.formGroup, this.getFormValue('paytype'), this.getFormValue('dtatype'));
    }

    private getPaiementSalIdOptionsByEmpId(empId: string) {
        this.paiementSalIdOptions = [];
        this._saemsService.getSalIdsByEmpId(empId)
            .subscribe((salIds: string[]) => {
                if (salIds !== undefined) {
                    if (salIds.length > 0) {
                        this.paiementSalIdOptions.push({
                            name: ' ',
                            value: ''
                        });
                    }
                    salIds.forEach((salId) => {
                        this.paiementSalIdOptions.push({
                            name: salId,
                            value: salId
                        });
                    });
                }
            });
    }
}
