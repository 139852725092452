import {HttpClient} from '@angular/common/http';

import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {AgencyHandlerService, RestEntityClient, TabFormControl} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys} from '@app/sam-base/models';
import {sasx4decTabsFormControls} from '@modules/sam-main/admin/components/swissdec4/sasx4dec-tabs-formcontrols';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';
import {Sasx5institution} from '@sam-base/models/admin/sasx5institution.model';
import {SxType} from '@sam-base/models/admin/sx-type.enum';
import {Subject, takeUntil} from 'rxjs';
import {filter} from 'rxjs/operators';

@Component({
    templateUrl: './swissdec4.component.html',
    standalone: false
})
export class Swissdec4Component extends BaseTabFormComponent<Sasx4dec> implements OnInit, OnDestroy, AfterViewInit {

    public delete = false;
    public isLoading = false;
    public institutions: Sasx5institution[] = [];
    public disabledTabList: string[] = [];
    private subscriptions = new Subject();
    private readonly sasx5decClient: RestEntityClient<Sasx4dec>;

    constructor(store: IwStoreService, private readonly _restService: RestApiService,
                private readonly _http: HttpClient,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _toast: ToastService, protected readonly _store: IwStoreService,
                private readonly _agencyHandlerService: AgencyHandlerService) {
        super(store);
        this.sasx5decClient = this._restService.getEntityClient(Sasx4dec);
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public get isCreateMode(): boolean {
        return !this.getData('row');
    }

    public get id() {
        return this.getFormValue('sxId') || '';
    }

    public get titleInfo(): string {
        const decType = this.getFormValue('sxType') || '';
        const salId = this.getFormValue('salId') || '';
        return `${decType} - ${salId}`;
    }

    public get status(): string {
        return this.getFormValue('decstatus') || '';
    }

    public get sxXml(): string {
        return this.getFormValue('sxXml');
    }

    public ngOnInit() {

        if (this.isCreateMode) {
            this.setFormValue('sxType', this.getData('creationType') as SxType);
        }
        this.subscribeValueChange<string>('sxId', e => {
            if (!e) {
                this.disabledTabList = [];
                return;
            }
            this.refreshFormMessageService.sendMessage('sasx4dec.table');
        });
        const declaration: Sasx4dec | undefined = this.getData('row');
    }

    public ngAfterViewInit(): void {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .pipe(filter(e => !!e))
            .subscribe((data: any) => {
                this.fillFormData(data);
            });
    }

    public ngOnDestroy() {
        this.refreshFormMessageService.sendMessage('sasx4dec.table');
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getTabsValues(): TabFormControl<Sasx4dec>[] {
        return sasx4decTabsFormControls;
    }

    protected getFormControlNames(): FormKeys<Sasx4dec> {
        return [
            'sxId',
            'sxType',
            'decstatus',
            'sxType',
            'annee',
            'salId',
            'sxMode',
            'sxJobkey',
            'sxDeclid',
            'sxXml',
            'declrespid',
            'nbemp'
        ];
    }

    protected getValidationType() {
        return Sasx4dec;
    }

    private setEntityId(v: string) {
        if (v !== this.id) {
            this.setFormValue('sxId', v);
        }
    }
}
