import {Component, OnInit} from '@angular/core';
import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {UtilsService} from '@app/sam-base/core/services/utils.service';
import {Sapar} from '@app/sam-base/models/salary/sapar';

@Component({
    selector: 'iw-tab-sapar-fiduciaire', templateUrl: './tab-sapar-fiduciaire.component.html',
    standalone: false
})

export class TabSaparFiduciaireComponent extends BasePartialFormComponent<Sapar> implements OnInit {


    constructor(private readonly _utilsService: UtilsService) {
        super();
    }

    public get fidpays(): string {
        return this.getFormValue('fidpays') || '';
    }

    public get emptyFidName(): boolean {
        return this.getFormValue<string>('fidname') === '';
    }

    public ngOnInit(): void {
        this.addValidators();
    }

    private noofValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isValid = this._utilsService.calculateNoofValidity(control.value);
            // eslint-disable-next-line no-null/no-null
            return isValid ? null : {invalidNoof: {value: control.value}};
        };
    }

    private addValidators() {
        this.subscribeValueChange('fidname', (name?: string) => {
            if (name) {
                this.formGroup?.controls['fidnpa'].setValidators(Validators.required);
                this.formGroup?.controls['fidlieu'].setValidators(Validators.required);
                this.formGroup?.controls['fidofsnr'].setValidators(Validators.required);
                this.formGroup?.controls['fidofsnr'].addValidators(this.noofValidator());
            } else {
                this.formGroup?.controls['fidnpa'].clearValidators();
                this.formGroup?.controls['fidlieu'].clearValidators();
                this.formGroup?.controls['fidofsnr'].clearValidators();
                this.formGroup?.controls['fidofsnr'].removeValidators(this.noofValidator());
            }
            this.formGroup?.controls['fidnpa'].updateValueAndValidity();
            this.formGroup?.controls['fidlieu'].updateValueAndValidity();
            this.formGroup?.controls['fidofsnr'].updateValueAndValidity();
        });
    }
}
