import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwEventHubService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {FormKeys} from '@app/sam-base/models';
import {Ppper, Pprap} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';
import moment from 'moment';
import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ReportsService} from '../../../services/reports.service';

/**
 * Component created regarding SAM-4582
 */
@Component({
    selector: 'iw-week-navigation', templateUrl: './week-navigation.component.html',
    standalone: false
})
export class WeekNavigationComponent extends BaseFormComponent<Pprap> implements OnInit, OnDestroy {

    @Output() public chosenDate = new EventEmitter<string>();

    // Active period
    public actSalId?: string;

    // Default background color of semaine (week number)
    public bckClrSemaine = '#ffffdf';

    // Default background color of semaine (date input)
    public bckClrCalendar = '#ffffdf';

    // Default background color of textfield periode
    public backgroundPerColor = 'per-color-2';

    // Periode value
    public periodeValue?: string;

    // Format for all fields with
    private _dateFormat = 'YYYY-MM-DD';

    private _per?: Ppper;
    private _salId?: string;
    private _defaultDate = IwDateHelper.getMoment();

    private readonly _entityService: RestEntityClient<Ppper>;

    private _subscripts = new Subject();

    constructor(public readonly restService: RestApiService, private readonly _parameters: ParametersService, private readonly _rapports: ReportsService, private readonly _events: IwEventHubService<string>) {
        super();
        this._entityService = restService.getEntityClient(Ppper);
    }

    public ngOnInit() {
        this.getActSalId()
            .pipe(takeUntil(this._subscripts))
            .subscribe(e => {
                this.actSalId = e;
                this.setFormValue('salId', this.actSalId);
                this.setPeriodeValueAndBackgroundColor();
                this.setBckClrCalendar(this._defaultDate);
            });
        this.setFormValue('datelundi', this._defaultDate);
        this.setCurrentMonday();
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    public getActSalId() {
        return this._parameters.getString('actSalId');
    }

    /**
     * Function to set in datelundi field the monday of previous week
     */
    public prevWeek() {
        this.setMonday('prev');
        this._events.emit('week_change', this.getFormValue('datelundi'));
    }

    /**
     * Function to set in datelundi field the monday of next week
     */
    public nextWeek() {
        this.setMonday('next');
        this._events.emit('week_change', this.getFormValue('datelundi'));
    }

    /**
     * Function to set monday of week
     */
    public setMonday(v: string) {
        const datelundi = this.getFormValue('datelundi');
        const date = IwDateHelper.getMoment(datelundi);
        const week = (v === 'prev' ? IwDateHelper.dateSubtract(date, 7, 'days') : IwDateHelper.addToDate(7, 'days', date));
        const monday = IwDateHelper.startOf('isoWeek', week);
        if (IwDateHelper.isoWeekday(monday) !== 1) {
            this.setMonday(v);
            return;
        }
        this.setBckClrCalendar(monday);
        const finalDate = IwDateHelper.dateFormatFromString(IwDateHelper.dateIso(monday), this._dateFormat);
        this.setFormValue('datelundi', finalDate);
        this.chosenDate.emit(finalDate);
    }

    /**
     * Function to set monday of week regarding a date chosen
     * If event undefined it means that we want today
     *
     * @param event Event | undefined
     */
    public setCurrentMonday(event?: Date) {
        let date = '';
        if (event) {
            date = IwDateHelper.dateIsoString(event);
        } else {
            date = IwDateHelper.dateIsoString();
        }
        const monday = IwDateHelper.startOf('isoWeek', date);
        this.setBckClrCalendar(monday);
        const finalDate = IwDateHelper.dateIsoString(monday);
        this.setFormValue('datelundi', finalDate);
        this.chosenDate.emit(finalDate);
    }

    /**
     * Function to set value of periode and background color
     */
    public setPeriodeValueAndBackgroundColor() {
        const salId = this.getFormValue('salId');
        if (salId && salId !== this.actSalId) {
            this.backgroundPerColor = 'per-color-1';
        } else {
            this.backgroundPerColor = 'per-color-2';
        }
        const v = 'Période active : ' + this.actSalId;
        this.periodeValue = v;
        this._rapports.salId = this.getFormValue('salId');
    }

    /**
     * Function to set background color of input date (calendar)
     */
    // eslint-disable-next-line complexity
    public async setBckClrCalendar(v: moment.Moment) {
        const salId = this.getFormValue('salId');
        if (salId && salId !== this._salId) {
            this._salId = salId;
            this._per = await lastValueFrom(this._entityService.getById(salId));
        }
        const dateFin = (this._per && this._per.datefin) ? IwDateHelper.getMoment(this._per.datefin) : '';
        const dateDebut = (this._per && this._per.datedebut) ? IwDateHelper.getMoment(this._per.datedebut) : '';
        const aux = v;
        if (aux < IwDateHelper.dateSubtract(dateDebut, 7, 'days') || aux > IwDateHelper.addToDate(7, 'days', dateFin)) {
            this.bckClrCalendar = '#ff8080';
        } else if (aux < dateDebut || IwDateHelper.addToDate(6, 'days', aux) > dateFin) {
            this.bckClrCalendar = '#efac52';
        } else {
            this.bckClrCalendar = '#ffffdf';
        }
    }

    protected getFormControlNames(): FormKeys<Pprap> {
        return ['datelundi', 'session', 'salId', 'misId', 'rapId', 'modecreat', 'ijrapId', 'ijAlert', 'empId', 'datasessionid'];
    }
}
