import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {ClipboardService} from '@app/sam-base/core/clipboard/clipboard.service';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';

import {ToastService} from '@app/sam-base/core/toast';
import {Npa} from '@app/sam-base/models/common/npa';
import {Gecli} from '@app/sam-base/models/invoices';
import {PpclicatmsComponent} from '@shared/widgets/modal-components/ppclicatms/ppclicatms.component';
import {lastValueFrom} from 'rxjs';
import {CountriesAlpha2List, Kchkrating} from './tab-client-general-enums';

@Component({
    selector: 'iw-client-tab-general', templateUrl: './tab-client-general.component.html',
    standalone: false
})
export class TabClientGeneralComponent extends BasePartialFormComponent<Gecli> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    @Input() public isNew = false;

    public countriesAlpha2List = CountriesAlpha2List;
    public kchkrating = Kchkrating;
    public categories: String[] = [];

    private readonly _npaRestClient: RestEntityClient<Npa>;

    constructor(private readonly _clipboardService: ClipboardService, private readonly _modalService: ModalService, restService: RestApiService, private readonly _toastService: ToastService) {
        super();
        this._npaRestClient = restService.getEntityClient(Npa);
    }

    public get pays(): string {
        return this.getFormValue('pays') || '';
    }

    public ngOnInit() {
    }

    public addToClipboard(value: string) {
        let copiedText = '';
        if (value === 'email') {
            const email: string | undefined = this.getFormValue('email');
            if (email) {
                copiedText = email;
            } else {
                return;
            }
        } else if (value === 'adresse') {
            copiedText = this.buildAddress();
            if (copiedText === '') {
                return;
            }
        }
        this._clipboardService.addToClipboard(copiedText);
    }

    public async getLieuByNpa() {
        try {
            const npa: string | undefined = this.getFormValue<string>('npa') || '';
            if (this.canGenerateNpa(npa)) {
                const lieu: Npa = await lastValueFrom(this._npaRestClient.getById(npa));
                this.setFormValue('lieu', lieu.lieu);
            }
        } catch (error) {
            this._toastService.error('npa_error');
        }
    }

    public async openCategories() {
        try {
            const catValue: string | undefined = this.getFormValue('categids');
            const catList: string[] = catValue ? catValue.split(';')
                .filter((e) => e !== '') : [];

            const res: any = await this._modalService.showModal(PpclicatmsComponent, catList);


            this.setFormValue('categids', res);
            this.categories = res;
        } catch (_) {
        }
    }

    private canGenerateNpa(npa: string) {
        return npa.length === 4 && !this.getFormValue('lieu') && !this.isReadonly;
    }

    // eslint-disable-next-line complexity
    private buildAddress() {
        let address = '';

        /* eslint-disable max-len */
        if (this.getFormValue('prefixe')) {
            address += this.getFormValue('prefixe') + '\n';
        }
        if (this.getFormValue('nom')) {
            address += this.getFormValue('nom') + '\n';
        }
        if (this.getFormValue('adresse1')) {
            address += this.getFormValue('adresse1') + '\n';
        }
        if (this.getFormValue('adresse2')) {
            address += this.getFormValue('adresse2') + '\n';
        }
        if (this.getFormValue('adresse3')) {
            address += this.getFormValue('adresse3') + '\n';
        }
        if (this.getFormValue('npa')) {
            address += this.getFormValue('npa') + '\n';
        }
        if (this.getFormValue('lieu')) {
            address += this.getFormValue('lieu');
        }

        return address;
    }
}
