import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {EntityDelete, IwStoreService} from '@app/sam-base/core/store';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {Ppcon, Ppsuivi, Ppsuiviview} from '@app/sam-base/models/placement';
import {PrintService} from '@core/print/print.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment.test';
import {SuiviService} from '@sam-base/core/services/suivi.service';
import {SamUserRole} from '@sam-base/models/admin/sam-user';

import {saveAs} from 'file-saver';
import {PlacementForm} from '../../../placement.forms';
import {Isuiper} from './tab-suivi-enum';
import {TabSuivi} from './tab-suivi.model';

import {
    employeeMgtAccessRoles
} from '@app/sam-base/core/auth/access-rules/employee-form';

@Component({
    selector: 'iw-tab-suivi',
    templateUrl: './tab-suivi.component.html',
    styleUrls: ['./tab-suivi.component.scss'],
    standalone: false
})
export class TabSuiviComponent extends BaseFormComponent<TabSuivi> implements OnInit {

    @Input() public isReadonly = true;
    public isuiper = Isuiper;
    public query: RestQueryParam<Ppsuiviview, string | boolean>[] = [
        {
            operation: RestQueryOperation.Equals,
            prop: 'cliId',
            value: '_'
        }];

    public employeeMgtAccessRoles: SamUserRole[] = employeeMgtAccessRoles();

    public columns = this.getCliSuiviColumns();
    public type: Type<Ppsuiviview> = Ppsuiviview;
    public refreshType: Type<Ppsuivi> = Ppsuivi;
    public tableWidth = '1200px';
    public suiviDescription = '';
    private _selected?: Ppsuiviview;
    @ViewChild('olvClisui', {static: true}) private _table?: IwRestGridComponent<Ppsuiviview>;

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _store: IwStoreService, private readonly _printService: PrintService,
                private readonly _restService: RestApiService, private readonly _translate: TranslateService,
                private _suiviService: SuiviService, private http: HttpClient) {
        super();
    }

    public get suiId() {
        return this.getFormValue('suiId') || '';
    }

    private _entityId?: string;

    public get entityId() {
        return this._entityId;
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public ngOnInit() {
        this.getConseillerAgence();
        this.subscribeValueChange('conId', () => {
            this.getConseillerAgence();
        });
    }

    public clearSortDir() {
        if (this._table) {
            this._table.defaultSort = undefined;
            this._table.defaultSortDir = 'asc';
        }
    }

    public addSuiviClient() {
        this._formHandler.showFormDialog(PlacementForm.SuiviNew, {
            cliId: this._entityId,
            suitype: 'C',
            action: 'PPCLIACTION'
        }, s => ({
            ...s,
            diagTitle: 'title_ppclisui'
        }));
    }

    public editSuiviClient() {
        const id = this.suiId;
        if (!id) {
            return;
        }

        this._formHandler.showFormDialog(PlacementForm.SuiviEdit, {
            cliId: this._entityId,
            suitype: 'C',
            action: 'PPCLIACTION'
        }, s => ({
            ...s,
            entityId: id,
            diagTitle: 'title_ppclisui'
        }));
    }

    public deleteSuiviClient() {
        const id = this.suiId;
        if (!id) {
            return;
        }

        this._store.dispatch(new EntityDelete(Ppsuivi, id));
    }

    public printService() {
        if (!this._table) {
            return;
        }
        const profile = this._table.loadGridProfile();

        profile.entity = 'ppsuivi';
        const cliId = this.entityId;
        if (!cliId) {
            return;
        }
        const data = {cliId};

        this.http
            .post(environment.backendURL + 'report/PDF/SUIVIPRINT?dynamicTemplate=false', data, {responseType: 'arraybuffer'})
            .subscribe((file) => {
                saveAs(new Blob([file], {type: 'application/pdf'}), 'suivis');
            });
    }

    public getSelectedRow(event: Ppsuiviview[]) {
        this._selected = (event && event[0]) || undefined;

        if (this._selected) {
            this.setFormValue('suiId', this._selected.suiId);
            this.setFormValue('notes', this._selected.notes);
            this.setSuiviDescription(this._selected);
        } else {
            this.setFormValue('suiId', '');
            this.setFormValue('notes', '');
            this.suiviDescription = '';
        }
    }

    protected getFormControlNames(): FormKeys<TabSuivi> {
        return [
            'suiId',
            'userId',
            'userAgeId',
            'suiOtherAgeIds',
            'notes'];
    }

    // eslint-disable-next-line complexity
    private setSuiviDescription(suivi: Ppsuiviview) {
        this.suiviDescription = this._suiviService.getSuiviDescription(suivi);
    }

    private setEntityId(v: string) {
        if (v !== this._entityId) {
            this._entityId = v;
            this.updateFilters(v);
        }
    }

    private async updateFilters(cliId: string) {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'cliId',
                value: cliId
            }];
    }

    private getConseillerAgence() {
        const conId = this.getFormValue('conId');
        if (!conId) {
            return;
        }
        this._restService
            .getEntityClient(Ppcon)
            .getById(conId)
            .subscribe((ppcon: Ppcon) => this.setFormValue('userAgeId', ppcon.ageId));
    }

    private getCliSuiviColumns(): IwGridColumn<Ppsuiviview>[] {
        return [
            {
                prop: 'dateaction',
                name: 'date',
                type: 'date',
                index: 0
            },
            {
                prop: 'nomconseille',
                name: 'conseiller',
                index: 1
            },
            {
                prop: 'action',
                index: 2
            },
            {
                prop: 'nomemploye',
                name: 'employe',
                index: 3
            },
            {
                prop: 'nomclient',
                name: 'client',
                index: 4
            },
            {
                prop: 'clientcontact',
                name: 'contact',
                index: 5
            }];
    }
}
