import {Component} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {IwStoreService, TabFormControl} from '@app/sam-base/core';
import {FormKeys} from '@app/sam-base/models';
import {Ppoptsal} from '@app/sam-base/models/placement';

import {salaryParametersTabsFormControls} from './salary-parameters-tabs-formcontrols';

@Component({
    templateUrl: './salary-parameters.component.html',
    standalone: false
})
export class SalaryParametersComponent extends BaseTabFormComponent<Ppoptsal> {

    public get tabData(): Ppoptsal | undefined {
        return this.getFormData();
    }

    public set tabData(v: Ppoptsal | undefined) {
        this.mergeEntityChanges(v);
    }

    constructor(store: IwStoreService) {
        super(store);
    }

    public getTabsValues(): TabFormControl<Ppoptsal>[] {
        return salaryParametersTabsFormControls;
    }

    protected getFormControlNames(): FormKeys<Ppoptsal> {
        return this.getTabsFormControls(salaryParametersTabsFormControls);
    }
}
