import {Component, Input} from '@angular/core';
import {DialogStateService} from '@sam-base/core';
import {filter} from 'rxjs/operators';

import {IwEventHubService} from '../../../core/events';

@Component({
    selector: 'iw-slide-panel',
    templateUrl: './slide-panel.component.html',
    standalone: false
})
export class SlidePanelComponent {
    /** True, if panel is visible */
    @Input() public display = false;

    /** True, if is global */
    @Input() public topMost = false;

    @Input() public entrySide: 'left' | 'top' = 'top';

    @Input() public toggleEvent = 'toggle-panel';
    @Input() public allowClose = false;

    constructor(private readonly _eventHub: IwEventHubService<string>,
                private readonly _dialogStateService: DialogStateService) {
        _eventHub.events.pipe(filter(e => this.toggleEvents.indexOf(e.type) !== -1))
            .subscribe(ev => {
                switch (ev.type) {
                    case this.toggleEvent:
                        return this.togglePanel(ev.payload);
                    case this.toggleEventOn:
                        return this.showPanel();
                    case this.toggleEventOff:
                        return this.hidePanel();
                }
            });
    }

    public get toggleEventOn() {
        return this.toggleEvent + 'On';
    }

    public get toggleEventOff() {
        return this.toggleEvent + 'Off';
    }

    public get toggleEvents() {
        return [
            this.toggleEvent,
            this.toggleEventOn,
            this.toggleEventOff];
    }

    public get classValue() {
        return {
            [this.entrySide]: true,
            open: this.display,
            global: this.topMost
        };
    }

    public togglePanel(display?: boolean) {
        // if we want to display the slide panel, that means we do not want dialogs to be focused
        if (display) {
            this._dialogStateService.removeFocus();
        }
        if (typeof display === 'boolean') {
            this.display = display;
            return;
        }

        this.display = !this.display;
    }

    public showPanel() {
        this.display = true;
        this._dialogStateService.removeFocus();
    }

    public hidePanel() {
        this.display = false;
    }

    public onClose() {
        this._eventHub.emit(this.toggleEvent, false);
    }
}
