import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Saems} from '@app/sam-base/models/salary/saems';
import {environment} from '@root/environments/environment';
import {SaemsService} from '@sam-base/core/services/saems.service';

@Component({
    selector: 'iw-tab-saems-analytique',
    templateUrl: './tab-saems-analytique.component.html',
    standalone: false
})
export class TabSaemsAnalytiqueComponent extends BasePartialFormComponent<Saems> implements OnInit {

    public txtRepana?: string;
    private _baseUrl = ' ';

    constructor(private readonly _http: HttpClient, private _saemsService: SaemsService) {
        super();
        this._baseUrl = environment.backendURL;
    }

    public get txtRepAna(): any | string {
        return this.txtRepana;
    }

    public ngOnInit() {
        this.getAnalytique(this.getFormValue('empId') || '', this.getFormValue('salId') || '');
    }

    public copyRepana() {
        return this._saemsService.getPreviousRepartition(this.getFormValue('empId') || '', this.getFormValue('salId') || '')
            .subscribe((note) => {
                this.txtRepana = note.toString();
            });
    }

    private getAnalytique(empId: string, salId: string) {
        return this._saemsService.getAnalytique(empId, salId)
            .subscribe((note) => {
                this.txtRepana = note.toString();
            });

    }
}
