<form [formGroup]="formGroup"
      class="row no-padding">
    <div class="row col-xs-12">
        <div class="col-xs-10">
            <iw-rest-grid #cantonISGrid
                          (selected)="getSelectedRow($event)"
                          [autoFormClick]="false"
                          [customColumns]="columns"
                          [hasFilter]="true"
                          [hasGridColumnMenu]="false"
                          [hasSortIcon]="true"
                          [isDisabled]="!readonly"
                          [queryStatements]="query"
                          [type]="type"></iw-rest-grid>


        </div>

        <div class="col-xs-2 flex flex-v">

            <!-- EDIT ACTION BUTTONS -->
            <ng-container *iwAccessGroups="SALAIRE_MGT_ROLES">
                <iw-button (press)="createNewEntry()"
                           [isDisabled]="!readonly"
                           [text]="'sidemenu_new' | translate"
                           class="margin-bottom-10"
                           name="nouveau"></iw-button>

                <iw-button (press)="modifyEntry()"
                           *ngIf="readonly"
                           [isDisabled]="!readonly || !selectedRow"
                           [text]="'sidemenu_edit' | translate"
                           class="margin-bottom-10"
                           id="modify"></iw-button>

                <iw-button (press)="saveEntry()"
                           *ngIf="!readonly"
                           [isDisabled]="readonly || !(getFormValue('isnordre'))"
                           [text]="'sidemenu_save' | translate"
                           class="margin-bottom-10"
                           id="save"></iw-button>

                <iw-button (press)="removeEntry()"
                           *ngIf="readonly"
                           [isDisabled]="!readonly || !selectedRow"
                           [text]="'sidemenu_delete' | translate"></iw-button>

                <iw-button (press)="cancelEditionMode()"
                           *ngIf="!readonly"
                           [text]="'sidemenu_cancel' | translate"></iw-button>
            </ng-container>
        </div>

        <div class="row col-xs-12 padding-top-15">
            <div class="row col-xs-10">

                <iw-textfield #txtCanton
                              [isDisabled]="!newEntryMode"
                              [labelAlign]="'top'"
                              [label]="'canton' | translate"
                              class="iw-input col-xs-1"
                              formControlName="canton"></iw-textfield>

                <iw-textfield #textNoDpi
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'no_dpi' | translate"
                              class="iw-input col-xs-2"
                              formControlName="isnordre"></iw-textfield>


                <iw-textfield #textSubnum
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'subnum' | translate"
                              class="iw-input col-xs-2"
                              formControlName="subnum"></iw-textfield>


                <iw-textfield #txtIsCommper
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'commission' | translate"
                              [suffix]="'%'"
                              class="iw-input col-xs-2"
                              formControlName="iscommper"></iw-textfield>


                <iw-textfield #txtisnbheures
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'nb_heures' | translate"
                              class="iw-input col-xs-2"
                              formControlName="isnbheures"></iw-textfield>


                <iw-textfield #txtisnbjours
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'nb_jours' | translate"
                              class="iw-input col-xs-1"
                              formControlName="isnbjours"></iw-textfield>

                <iw-textfield #txtisnbjours
                              [isDisabled]="readonly"
                              [labelAlign]="'top'"
                              [label]="'entcpt' | translate"
                              class="iw-input col-xs-2"
                              formControlName="entcpt"></iw-textfield>

                <iw-checkbox #chkIsSdsxml
                             [falseValue]="0"
                             [isDisabled]="readonly"
                             [label]="'is_sdsxml' | translate"
                             [trueValue]="1"
                             class="iw-input col-xs-12 padding-top-10"
                             formControlName="isSdsxml"></iw-checkbox>

                <iw-checkbox #chkIsSdsdis
                             [falseValue]="0"
                             [isDisabled]="readonly"
                             [label]="'is_sdsdis' | translate"
                             [trueValue]="1"
                             class="iw-input col-xs-12"
                             formControlName="isSdsdis"></iw-checkbox>


            </div>

            <div class="row col-xs-2 no-padding">
                <iw-button #Closebutton
                           (press)="closeDialog()"
                           [text]="'fermer' | translate"
                           class="iw-button col-xs-12 flex-end"></iw-button>
            </div>
        </div>
    </div>
</form>
