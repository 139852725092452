<div class="row no-padding">
    <iw-button (press)="openMail()"
               [isDisabled]="nbOfMail === 0"
               [text]="'operation_component.result.invoice.actions.btn_open_mail' | translate"
               class="col-xs-offset-4 col-xs-3"
               iconClass="fas fa-envelope"></iw-button>
    <iw-button (press)="openPrintRequest()"
               [isDisabled]="nbOfPrintRequest === 0"
               [text]="'operation_component.result.invoice.actions.btn_open_print' | translate : {nbDoc: nbOfPrintRequest}"
               class="col-xs-5 no-padding-right"
               iconClass="fas fa-print"></iw-button>

</div>
