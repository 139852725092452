import {Component} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {parametresGenerauxTabsFormControls} from './parametresGeneraux-tabs-formcontrols';

@Component({
    templateUrl: './parametresGeneraux.component.html',
    standalone: false
})
export class ParametresGenerauxComponent extends BaseTabFormComponent<Sapar> {
    constructor(private store: IwStoreService) {
        super(store);
    }

    public get tabData(): Sapar | undefined {
        return this.getFormData();
    }

    public set tabData(v: Sapar | undefined) {
        this.mergeEntityChanges(v);
    }

    public getTabsValues(): TabFormControl<Sapar>[] {
        return parametresGenerauxTabsFormControls;
    }

    protected getFormControlNames(): FormKeys<Sapar> {
        return this.getTabsFormControls(parametresGenerauxTabsFormControls);
    }

    protected getValidationType() {
        return Sapar;
    }

}
