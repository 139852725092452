<form [formGroup]="formGroup"
      class="row">
    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'code' | translate }}
    </div>
    <iw-textfield #txtDebCode
                  [isDisabled]="isReadonly || !isNew"
                  class="iw-input col-xs-2 padding-top-10"
                  formControlName="dedCode"></iw-textfield>
    <iw-textfield #txtDedType
                  [isDisabled]="isReadonly || !isNew"
                  class="iw-input col-xs-1 padding-top-10"
                  formControlName="dedType"></iw-textfield>
    <iw-textfield #txtEdO
                  [isDisabled]="true"
                  [value]="sapardedview?.dedorind"
                  class="iw-input col-xs-2 padding-top-10"></iw-textfield>

    <iw-checkbox #Standardlogic1
                 (valueChange)="changeActive($event)"
                 [isDisabled]="isReadonly"
                 [label]="'active' | translate"
                 class="iw-input col-xs-2"
                 formControlName="dedActive"
                 style="height: 46px; padding-left: 20px"></iw-checkbox>
    <iw-textfield #butTxtAnnee
                  [isDisabled]="true"
                  class="iw-input col-xs-2 padding-top-10"
                  formControlName="annee"></iw-textfield>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'titre' | translate }}
    </div>
    <iw-textfield #txtDebTitre
                  [isDisabled]="isReadonly"
                  class="iw-input col-xs-9 padding-top-10"
                  formControlName="dedTitre"></iw-textfield>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'titre_court' | translate }}
    </div>
    <iw-textfield #txtDebShtit
                  [isDisabled]="isReadonly"
                  class="iw-input col-xs-9 padding-top-10"
                  formControlName="dedShtit"></iw-textfield>

    <div *ngIf="showAssurance()"
         class="iw-single-label col-xs-3 padding-top-10">
        {{ 'assurance' | translate }}
    </div>
    <iw-dropdown *ngIf="showAssurance()"
                 [isDisabled]="isReadonly"
                 [options]="assuranceOptions"
                 class="iw-input col-xs-9 padding-top-10"
                 formControlName="assurance"></iw-dropdown>

    <div class="col-xs-4 padding-top-10 margin-bottom-10"></div>

    <div class="col-xs-12 separator-top"></div>

    <div class="iw-single-label col-xs-3 padding-top-10 margin-bottom-10">
        {{ 'modeBase' | translate }}
    </div>
    <iw-dropdown (valueChange)="setBaseMode($event)"
                 [isDisabled]="isReadonly"
                 [options]="baseModeOptions"
                 class="col-xs-6 padding-top-10 margin-bottom-10"
                 formControlName="basemode"></iw-dropdown>
    <iw-textfield #txtDedBasaut
                  *ngIf="!notShow"
                  [isDisabled]="isReadonly || dedBasaut"
                  class="iw-input col-xs-3 padding-top-10 margin-bottom-10"
                  formControlName="dedBasaut"></iw-textfield>

    <div class="col-xs-12 separator-top"></div>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'gsAutreBase' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly || gsBasaut"
                      [noLabel]="true"
                      [types]="['6']"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="gsBasaut"></iw-sags-dropdown>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'gsBase' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly || gsBas"
                      [noLabel]="true"
                      [types]="['6']"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="gsBas"></iw-sags-dropdown>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'gsSalaire' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly"
                      [noLabel]="true"
                      [types]="['6']"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="gsSal"></iw-sags-dropdown>

    <div class="iw-single-label col-xs-3 padding-top-10 margin-bottom-10">
        {{ 'gsNS' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly"
                      [noLabel]="true"
                      [types]="['6']"
                      class="iw-input col-xs-9 padding-top-10 margin-bottom-10"
                      formControlName="gsNs"></iw-sags-dropdown>

    <div class="col-xs-12 separator-top"></div>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'dedEmploye' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly"
                      [noLabel]="true"
                      [types]="['4']"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="gsCot"></iw-sags-dropdown>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'dedEmployeur' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly"
                      [noLabel]="true"
                      [types]="['8']"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="gsCoe"></iw-sags-dropdown>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'dedFixeEmploye' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly"
                      [noLabel]="true"
                      [types]="['2']"
                      class="iw-input col-xs-9 padding-top-10"
                      formControlName="gsCotFix"></iw-sags-dropdown>

    <div class="iw-single-label col-xs-3 padding-top-10 margin-bottom-10">
        {{ 'dedFixeEmployeur' | translate }}
    </div>
    <iw-sags-dropdown [isDisabled]="isReadonly"
                      [noLabel]="true"
                      [types]="['9']"
                      class="iw-input col-xs-9 padding-top-10 margin-bottom-10"
                      formControlName="gsCoeFix"></iw-sags-dropdown>

    <div class="col-xs-12 separator-top"></div>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'minimumMaximum' | translate }}
    </div>
    <iw-dropdown (valueChange)="setminMax($event)"
                 [isDisabled]="isReadonly"
                 [options]="minMaxOptions"
                 class="col-xs-3 padding-top-10"
                 formControlName="minmaxmode"></iw-dropdown>
    <iw-textfield #txtSalmin
                  [isDisabled]="isReadonly || minMaxDisabled"
                  class="iw-input col-xs-3 padding-top-10 text-align-right"
                  formControlName="salmin"
                  type="number"></iw-textfield>
    <iw-textfield #txtSalmax
                  [isDisabled]="isReadonly || minMaxDisabled"
                  class="iw-input col-xs-3 padding-top-10 text-align-right"
                  formControlName="salmax"
                  type="number"></iw-textfield>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'modeTaux' | translate }}
    </div>
    <div class="iw-form-group iw-form-group-primeng col-xs-3">
        <p-dropdown #combo_modeTaux
                    (onChange)="setmodeTaux($event.value)"
                    [disabled]="isReadonly"
                    [options]="modeTauxOptions"
                    class="iw-input iw-dropdown col-xs-3 flex padding-top-10"
                    formControlName="tauxmode"
                    id="combo_modeTaux"
                    optionLabel="name"
                    optionValue="value"></p-dropdown>
    </div>
    <iw-textfield #txtTxCot
                  [isDisabled]="isReadonly || modeTauxDisabled"
                  class="iw-input col-xs-3 padding-top-10 text-align-right"
                  formControlName="txcot"
                  type="number"></iw-textfield>
    <iw-textfield #txtTxcoe
                  [isDisabled]="isReadonly || modeTauxDisabled"
                  class="iw-input col-xs-3 padding-top-10 text-align-right"
                  formControlName="txcoe"
                  type="number"></iw-textfield>

    <div class="col-xs-3 padding-top-10"></div>
    <iw-button #butTaux
               (press)="openTauxForm()"
               [isDisabled]="!enableTauxBut()"
               [text]="'tauxBut' | translate"
               class="btn-block col-xs-3 padding-top-10"></iw-button>
    <div class="col-xs-6 padding-top-10"></div>

    <div class="iw-single-label col-xs-3 padding-top-10">
        {{ 'modeAuto' | translate }}
    </div>
    <iw-dropdown [isDisabled]="isReadonly"
                 [options]="modeAutoOptions"
                 class="col-xs-3 padding-top-10"
                 formControlName="tauxauto"></iw-dropdown>
    <iw-button #butTaux
               (press)="openCalculForm()"
               [isDisabled]="!enableCalBut"
               [text]="'calcul' | translate"
               class="btn-block col-xs-3 padding-top-10"></iw-button>
    <div class="col-xs-3 padding-top-10"></div>
</form>
