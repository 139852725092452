import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sapar} from '@app/sam-base/models/salary/sapar';

@Component({
    selector: 'iw-tab-sapar-paiment', templateUrl: './tab-sapar-paiment.component.html',
    standalone: false
})
export class TabSaparPaimentComponent extends BasePartialFormComponent<Sapar> {
}
