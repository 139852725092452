// export const ;

export const support = {
    label: 'support_a_distance',
    items: [
        {
            label: 'interiware_support',
            command: () => {
                window.open('https://drive.google.com/file/d/13Rhk7DH5MV116ELEANy3epsKAAYb9ouv/view', '_blank');
            }
        },
    ],
};




