import {Component, EventEmitter, Input, OnDestroy, Output, Type, ViewChild} from '@angular/core';
import {ReportsService} from '@app/modules/sam-main/placement/services/reports.service';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {EntityNavigationService, GridProfile, ModalService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {IwEventHubService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {pprapviewCustomProfile} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {RestApiService, RestEntityClient, RestQueryOperation} from '@app/sam-base/core/rest-api';
import {RestQueryParam} from '@app/sam-base/core/rest-api/models';
import {EntityDeleteService} from '@app/sam-base/core/services/entity-delete.service';
import {EntityCreate, IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {getWeekNumber} from '@app/sam-base/helpers/date';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {FormKeys, IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {Ppclimis, Pplpp, Ppmis, Ppper, Pprap, Pprapview} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {forkJoin, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../../placement.forms';
import {PplppService} from '../../../services/pplpp.service';
import {MissionStatus} from '../../missions/mission-enums';

const DEFAULT_QUERY: RestQueryParam<Pprapview, string> = {
    operation: RestQueryOperation.Equals,
    prop: 'misId',
    value: '0'
};


/**
 * Component created regarding SAM-4581
 */
@Component({
    selector: 'iw-list-rapports',
    templateUrl: './list-rapports.component.html',
    standalone: false
})
export class ListRapportsComponent extends BaseStoreFormComponent<Pprap> implements OnDestroy {
    @Input() public rapportsFormUuid?: string;
    public type: Type<Pprapview> = Pprapview;
    public query: RestQueryParam<Pprapview, string>[] = [DEFAULT_QUERY];
    public refreshType: Type<Pprap> = Pprap;
    public customColumns: IwGridColumn<Pprapview>[] = [
        {
            prop: 'rapNo',
            index: 0
        },
        {
            prop: 'rapId',
            index: 1
        },
        {
            prop: 'nbheutot',
            index: 2,
            decimals: 2
        },
        {
            prop: 'mntindemp',
            index: 3,
            decimals: 2
        },
        {
            prop: 'chantier',
            index: 4
        },
        {
            prop: 'sent2fac',
            name: 'facture',
            type: 'boolean',
            index: 5
        },
        {
            prop: 'sent2sal',
            name: 'salaire',
            type: 'boolean',
            index: 6
        }];
    public labelRap?: string;
    public misIjInfo?: string;
    public backClass?: string;
    public txtStatus?: string;
    public missionInfo?: string;
    public pplppEntity?: Pplpp;
    public backSemStatusColor?: string;
    public tableWidth = '2000px';
    public btnSemStatusEnable = false;
    public activePeriod = '';
    /**
     * Output to close modal on default form
     */
    @Output() public destroy = new EventEmitter<string>();
    @ViewChild('gridReports') public reportsGrid?: IwRestGridComponent<Pprapview>;
    /**
     * Rapport selected
     */
    private _selected?: Pprapview;
    private _lockOwnerUser?: string;
    private readonly _misRestClient: RestEntityClient<Ppmis>;
    private readonly _ppperRestClient: RestEntityClient<Ppper>;
    private readonly _pplppRestClient: RestEntityClient<Pplpp>;
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private readonly _rapports: ReportsService,
                private readonly _modalService: ModalService, private readonly _pplppService: PplppService,
                private readonly _toastService: ToastService, private readonly _translate: TranslateService,
                private readonly _restService: RestApiService, private readonly _parameters: ParametersService,
                private readonly _events: IwEventHubService<string>,
                private readonly _entityDeleteService: EntityDeleteService,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _formHandler: FormHandlerService<PlacementForm>) {
        super(store);
        this.backClass = '';
        this._misRestClient = this._restService.getEntityClient(Ppmis);
        this._ppperRestClient = this._restService.getEntityClient(Ppper);
        this._pplppRestClient = this._restService.getEntityClient(Pplpp);
        this._events.forType<string>('semstatus')
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                this.checkPplpp(this.missionSelected?.empId ?? '', this._date ?? '');
            });
        this._events.forType('hour_grid_close')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                if (!e || !e.payload) {
                    return;
                }
                const pl: any = e.payload;
                this.date = pl.datelundi;
                this.putOnLpp();
            });
        this._parameters.getString('actSalId')
            .subscribe(period => this.activePeriod = period);
    }

    /**
     * Value to enable or disable new rapport button
     */
    public get btnNewRapportEnable() {
        return !!this.checkBtnNewRapportEnable();
    }

    /**
     * Value to enable or disable edit rapport button
     */
    public get btnEditRapportEnable() {
        return !!this.checkBtnEditRapportEnable();
    }

    /**
     * Value to enable or disable delete rapport button
     */
    public get btnDelRapportEnable() {
        return this.checkBtnDelRapportEnable();
    }

    public get lockedStatus() {
        return this._translate.instant('reportLocked') + this._lockOwnerUser;
    }

    /**
     * Mission selected
     */
    private _missionSelected?: Ppclimis;

    public get missionSelected() {
        return this._missionSelected;
    }

    @Input()
    public set missionSelected(v: Ppclimis | undefined) {
        this.btnSemStatusEnable = false;
        this._missionSelected = v;
        if (v && v.empId && this._date) {
            this.btnSemStatusEnable = true;
            this.checkPplpp(v.empId, this._date);
        }
        this.setQuery(v);
        this.labelRap = this.getLabelRap();
        this.missionInfo = this.getMissionInfo();
        this.misIjInfo = this.getMisIjInfo();
    }

    private _date?: string;

    /**
     * Date lundi of date selected
     */
    @Input()
    public set date(d: string | undefined) {
        if (this.missionSelected && this.missionSelected.empId && d) {
            this.checkPplpp(this.missionSelected.empId, d);
        }
        this._date = d;
        this.setQuery(this.missionSelected);
        this.labelRap = this.getLabelRap();
        this.missionInfo = this.getMissionInfo();
        this.misIjInfo = this.getMisIjInfo();
    }

    private _selectedRapportIsReadOnly = false;

    public get selectedRapportIsReadOnly(): boolean {
        return this._selectedRapportIsReadOnly;
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    /**
     * Function to filter rapport grid regarding mission selected
     *
     * @param v Mission selected
     */
    public setQuery(v?: Ppclimis) {
        if (!v) {
            this.query = [DEFAULT_QUERY];
            return;
        }
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'misId',
                value: v.misId || ''
            },
            {
                operation: RestQueryOperation.Equals,
                prop: 'datelundi',
                value: (new Date(this._date || 0).toISOString()).slice(0, 19)
                    .replace('T', ' ')
            }];
    }

    /**
     * Set selected rapport
     *
     * @param event
     */
    // eslint-disable-next-line complexity
    public setSelectedRow(event: Pprapview[]) {
        this._selected = (event && event[0]) || undefined;
        if (this._selected?.invoiceProcessStatus || this._selected?.salaryProcessStatus) {
            this._selectedRapportIsReadOnly = true;
            this._lockOwnerUser = this._selected?.salaryProcessStatus ?? this._selected?.invoiceProcessStatus ?? '';
        } else {
            this._selectedRapportIsReadOnly = false;
        }
    }

    /**
     * Function to emit destroy string to close default form
     */
    public clickClose() {
        this.destroy.emit('destroy');
    }

    /**
     * Function to open <Ppheusais> component
     */
    public clickActivite() {
        if (this._missionSelected) {
            this._formHandler
                .showFormDialog(PlacementForm.Ppheusais, {
                    empNom: `${this._missionSelected.empNom} ${this._missionSelected.empPrenom}`,
                    datelundi: this._date,
                    selected: this.pplppEntity,
                    empId: this._missionSelected.empId
                });
        }
    }

    /**
     * Open modal regarding the creation of new rapport - <Ppheusaid2def>
     * component
     */
    public async newRapport() {
        const pprap = new Pprap();
        pprap.misId = this._missionSelected?.misId;
        const datelundi = this._date;
        this.isOkToCreate(pprap, datelundi || '');
    }

    /**
     * Open modal regarding the edition of one rapport - <Ppheusaid2def> component
     */
    public editRapport() {
        if (this._selected) {
            const datelundi = this._date;
            const pprap: Pprapview = this._selected;
            pprap.misId = this._missionSelected?.misId;
            pprap.rapId = this._selected?.rapId;

            if (!pprap.sent2sal && !pprap.sent2fac) {
                this._formHandler
                    .showFormDialog(PlacementForm.Ppheusaid2def, {
                        pprap,
                        datelundi
                    });
            }
        }
    }

    /**
     * Delete rapport selected
     */
    // eslint-disable-next-line complexity
    public async deleteRapport() {
        this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', true));
        if (this._selected && this._selected.rapId) {
            if (this._selected.sent2sal && this._selected.sent2fac) {
                this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', false));
                await this.openModal('rap_cant_del');
                return;
            }
            if (!(await this._entityDeleteService
                .openEntityDeleteDialog(this._selected))) {
                this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', false));
                return;
            }
            this._rapports.deleteRapport(this._selected.rapId)
                .subscribe((_) => {
                    this.reportsGrid?.refresh();
                    this.checkPplpp(this._selected?.empId || '', this._date || '');
                    this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', false));
                });
        }
    }

    public openRapportForm(event: RowClickEvent<Pprapview>) {
        if (this.canOpenReportForm(event)) {
            const gridProfile: GridProfile<Pprapview> = getDeepCopy(new GridProfile(pprapviewCustomProfile(<string>event.row.salId, <number>event.row.semaine, <string>event.row.misId)));
            this._entityNavService.navigateToEntityForm(Pprapview, <string>event.row.rapId, gridProfile);
        } else {
            this._selected = (event.row);
            this.editRapport();
        }
    }

    protected getFormControlNames(): FormKeys<Pprap> {
        return [
            'datelundi',
            'session',
            'salId',
            'misId',
            'rapId',
            'modecreat',
            'ijrapId',
            'ijAlert',
            'empId',
            'datasessionid'];
    }

    /* Start logic to lOkToCreate */
    private isOkToCreate(pprap: Pprap, datelundi: string): void {
        let lOkToCreate: boolean | undefined = true;
        this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', true));
        this._misRestClient.getById(this._missionSelected?.misId || '')
            .subscribe(async (mission: Ppmis) => {
                lOkToCreate = await this.checklpointeuseAndpointRfidAndpointRfid(mission);
                forkJoin([
                    this._parameters.getDataText('ijData'),
                    this._parameters.getParameterValue('allotarif0'),
                    this._parameters.getParameterValue('allosal0'),
                    this._ppperRestClient.getById(this.activePeriod),
                    this._rapports.getPprapsByEmpIdAndDateLundi(mission.empId || '', this._date || ''),
                    this._parameters.getParameterValue('lnoaco'),
                    this._parameters.getParameterValue('lnoacorap')])
                    .subscribe(// eslint-disable-next-line complexity
                        async (data: any) => {
                            const ijData = data[0];
                            const allotarif0 = (data[1] ?? true) as boolean;
                            const allosal0 = (data[2] ?? true) as boolean;
                            const ppper: Ppper = data[3];
                            const pprapt: Pprap[] = data[4];

                            lOkToCreate = await this.checkIjdata(ijData, mission);
                            lOkToCreate = this.checkMission(mission, allotarif0, allosal0);
                            if (ppper.status !== 0) {
                                this._toastService.warning('rap_period_not_active');
                                lOkToCreate = false;
                            }
                            if (pprapt.length > 0) {
                                const rapt = pprapt[0];
                                lOkToCreate = await this.checkPprapt(rapt);
                            }
                            if (lOkToCreate === undefined) {
                                this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', false));
                                return;
                            }
                            if (lOkToCreate) {
                                this._formHandler
                                    .showFormDialog(PlacementForm.Ppheusaid2def, {
                                        pprap,
                                        datelundi
                                    });
                            } else {
                                this._toastService.warning('rap_cant_create');
                            }
                            this._store.dispatch(new SetLoading(this.rapportsFormUuid ?? '', false));
                        });
            });
    }

    private async checkIjdata(ijData: any, mission: Ppmis): Promise<boolean | undefined> {
        if (ijData.WEBRAP === '1' && mission.lexpmis2Ij && mission.ijRapSm !== 'XX' && this.getFormValue('ijAlert')) {
            return this.openModal('rap_web_msg');
        }
        return false;
    }

    private async checklpointeuseAndpointRfidAndpointRfid(mission: Ppmis): Promise<boolean | undefined> {
        if (mission.lpointeuse && mission.pointRfid && mission.pointRfid !== '') {
            return this.openModal('rap_pointeuse_msg');
        }
        return false;
    }

    private async checkPprapt(rapt: Pprap): Promise<boolean | undefined> {
        if (rapt && rapt.salId) {
            const salId = ((rapt.salId?.split(' ')[0] + '-') + ((Number(rapt.salId?.split(' ')[1]) > 9) ? rapt.salId?.split(' ')[1] : '0' + rapt.salId?.split(' ')[1]));
            const rapexists = `${this._translate.instant('rap_exists_week')}\
        Rap: ${rapt.rapNo}, Sal: ${salId},
        Mis: ${rapt.salId},
      ${this._translate.instant('rap_continue')}`;
            try {
                return await this.openModal(rapexists);
            } catch {
                return undefined;
            }
        }
        return false;
    }

    private checkMission(mission: Ppmis, allotarif0: boolean, allosal0: boolean): boolean {
        if (this.checkMissalmodeAndMisfacmode(mission)) {
            if (this.checkClitariAndSalglobal(mission, allotarif0, allosal0)) {
                return false;
            }
        }
        return mission.missalmode === 1 || mission.misfacmode === 1;
    }

    private checkMissalmodeAndMisfacmode(mission: Ppmis): boolean {
        return (mission.missalmode === 1 && mission.misfacmode === 1);
    }

    private checkClitariAndSalglobal(mission: Ppmis, allotarif0: boolean, allosal0: boolean) {
        return (mission.clitarif === 0 && !allotarif0) || mission.salGlobal === 0 && !allosal0;
    }

    private canOpenReportForm(event: RowClickEvent<Pprapview>) {
        return event.row.rapId && event.row.misId && event.row.salId && event.row.semaine && this.isReportSent2SalFac(event.row);
    }

    private isReportSent2SalFac(rapport?: Pprapview): boolean {
        if (!rapport) {
            return false;
        }
        return (rapport.sent2fac || rapport.sent2sal) ?? false;
    }

    /**
     * Get label for rapport
     */
    private getLabelRap() {
        if (!this._missionSelected) {
            return;
        }
        const mis = this._missionSelected;

        let weekNumber;
        if (this._date) {
            weekNumber = getWeekNumber(this._date)
                .toString();
        }

        return `${this._missionSelected.empNom} ${this._missionSelected.empPrenom}` + ' \\ Mission # ' + mis.misId + ' \\ Semaine ' + weekNumber;
    }

    /**
     * Get detail of mission selected
     */
    private getMissionInfo() {
        if (!this._missionSelected) {
            return;
        }
        const mis = this._missionSelected;

        return mis.qualif + ' \\ ' + (mis.chantier ? mis.chantier + ' \\ ' : '') + mis.lieu1 + ' ' + mis.lieu2 + ' ' + mis.lieu3;
    }

    private getMisIjInfo() {
        if (!this._missionSelected) {
            return;
        }
        const mis = this._missionSelected;

        if (mis.syncIj) {
            this.backClass = 'mis-ij-info-color-true';
            return 'Interijob/' + mis.ijRapSm;
        } else {
            this.backClass = 'mis-ij-info-color-false';
            return 'Local';
        }
    }

    private checkPplpp(empId: string, date: string) {
        this._pplppService.GetPplpp(empId, date)
            .subscribe((elem) => {
                if (elem) {
                    this.pplppEntity = elem;
                    if (elem.semstatus !== undefined) {
                        this.setBackSemStatusColor(elem.semstatus.toString());
                    }
                } else {
                    this.pplppEntity = undefined;
                    this.setBackSemStatusColor('-1');
                }
            });
    }

    // eslint-disable-next-line complexity
    private setBackSemStatusColor(v?: string) {
        switch (v) {
            case '0':
                this.txtStatus = 'I n a c t i f';
                this.backSemStatusColor = 'active-other';
                break;
            case '1':
                this.txtStatus = 'T r a v a i l';
                this.backSemStatusColor = 'active-other';
                break;
            case '2':
                this.txtStatus = 'M a l a d i e';
                this.backSemStatusColor = 'active-234';
                break;
            case '3':
                this.txtStatus = 'A c c i d e n t';
                this.backSemStatusColor = 'active-234';
                break;
            case '4':
                this.txtStatus = 'M i l i t a i r e';
                this.backSemStatusColor = 'active-234';
                break;
            case '5':
                this.txtStatus = 'V a c a n c e s';
                this.backSemStatusColor = 'active-other';
                break;
            default:
                this.txtStatus = '';
                this.backSemStatusColor = 'active-other';
                break;
        }
    }

    /**
     * Logic to check if button to add new rapport is enable
     */
    // eslint-disable-next-line complexity
    private checkBtnNewRapportEnable() {
        if (!this._missionSelected) {
            return false;
        }
        const mis = this._missionSelected;
        const dateFin = mis.datefin ? IwDateHelper.momentToShortMoment(IwDateHelper.getMoment(mis.datefin)) : undefined;
        const datelundi = IwDateHelper.momentToShortMoment(IwDateHelper.getMoment(this._date)) || '';
        const dateDebut = IwDateHelper.momentToShortMoment(IwDateHelper.getMoment(mis.datedebut)) || '';
        const misDateDebut = IwDateHelper.getMoment(mis.datedebut);
        const isNotArchivee = Number(mis.misstatus) !== MissionStatus.archivee;
        const isNotModele = Number(mis.misstatus) !== MissionStatus.modele;
        const isActivePeriod = this._rapports.salId === this.activePeriod;
        const dateDebutSubtracted = dateDebut ? IwDateHelper.momentToShortMoment(misDateDebut.subtract(6, 'd')) : undefined;
        const dateLundiAfterDebutLess6days = dateDebutSubtracted ? datelundi >= dateDebutSubtracted : undefined;
        const dateLundiBeforeDateFinOrNoDateFin = !dateFin || datelundi <= dateFin;
        const dateFinIsValid = dateFin ? IwDateHelper.isValid(dateFin) : false;
        const dateBeforeThisMomment = dateDebut <= IwDateHelper.dateFormat(IwDateHelper.getMoment());
        const result = mis.misId && isNotArchivee && isNotModele && isActivePeriod && ((dateLundiAfterDebutLess6days && dateLundiBeforeDateFinOrNoDateFin) || (!dateFinIsValid && dateBeforeThisMomment));
        return result;
    }

    /**
     * Logic to check if button to edit a rapport is enable
     */
    private checkBtnEditRapportEnable() {
        if (!this._selected) {
            return false;
        }
        const rap = this._selected;

        return rap.rapId && !rap.sent2sal && !rap.sent2fac && this._rapports.salId === this.activePeriod;
    }

    /**
     * Logic to check if button to delete a rapport is enable
     */
    private checkBtnDelRapportEnable() {
        if (!this._selected) {
            return false;
        }
        return this._rapports.salId === this.activePeriod;
    }

    private async openModal(message: string): Promise<boolean | undefined> {
        const options: ModalMessageOptions = {
            message: [],
            showCancel: true,
            title: this._translate.instant('rapport'),
            confirmMessage: this._translate.instant(message),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            return true;
        } catch (error) {
            return undefined;
        }
    }

    private putOnLpp(): void {
        this._pplppService.GetPplpp(this.missionSelected?.empId || '', this._date || '')
            .subscribe(elem => {
                if (elem) {
                    this._pplppService.UpdateStatus(elem.empId || '', this._date || '', 1)
                        .subscribe();
                } else {
                    const formData = {
                        empId: this._missionSelected?.empId,
                        datelundi: this._date,
                        semstatus: 1
                    };

                    const updatedData = this._pplppRestClient
                        .construct({...formData});

                    this._store.dispatch(new EntityCreate(updatedData));
                }
            });
    }
}
