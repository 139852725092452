import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ComboboxItem} from '@app/sam-base/models';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sasd4permis} from '@app/sam-base/models/sasd4permis.model';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';

@Component({
    selector: 'iw-tab-saemp-permis', templateUrl: './tab-saemp-permis.component.html',
    standalone: false
})
export class TabSaempPermisComponent extends BasePartialFormComponent<Saempc> implements OnInit {


    public allPermis: ComboboxItem<string>[] = [];
    protected readonly _baseUrl: string;
    private _rest: RestEntityClient<Sasd4permis>;

    public constructor(private readonly _translate: TranslateService, public readonly restService: RestApiService) {
        super();
        this._baseUrl = environment.backendURL;
        this._rest = restService.getEntityClient(Sasd4permis);
    }

    public get hasPermis() {
        const nation = this.getFormValue('nation');
        if (nation === 'CH') {
            this.resetPermisValues();
        }
        return nation !== 'CH';
    }

    public get hasPermisFilled() {
        const permis = this.getFormValue('permis');
        return !!permis;
    }

    public ngOnInit(): void {
        this.getAllPermis();
        this.subscribeValueChange('permis', permis => {
            this.updatePermisValidators(permis as string);
        });
        this.updatePermisValidators(this.getFormValue('permis') as string);
    }

    private resetPermisValues() {
        const permisFormControls = ['permis', 'permisno', 'permDtde', 'permDta', 'permLieu'] as (keyof Saempc)[];

        permisFormControls.forEach(fc => this.setFormValue(fc, undefined));
    }

    private updatePermisValidators(permis: string) {
        if (permis === 'G') {
            this.getFormControl('permDtde')
                ?.setValidators([Validators.required]);
        } else {
            this.getFormControl('permDtde')
                ?.clearValidators();
        }
        this.getFormControl('permDtde')
            ?.updateValueAndValidity();
    }

    private getAllPermis() {
        this._rest.getRefData()
            .subscribe(options => {
                options.forEach((option) => {
                    this.allPermis.push({name: option.titre || '', value: option.sd4Permis || ''});
                });
            });
    }

}
