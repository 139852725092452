<form [formGroup]="formGroup"
      class="row">
    <div class="flex col-xs-12 margin-bottom-10"
         id="periodForm"
         style="display: flex; flex-direction: column;">
        <!-- Header Section -->
        <div class="flex"
             style="display: flex; align-items: flex-start;">
            <iw-textfield #txtAnnee
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'annee' | translate"
                          [value]="sapar?.annee"
                          class="iw-input col-xs-2"
                          style="text-align-last: center; margin-right: 10px;"></iw-textfield>

            <iw-dropdown #drpPeriod
                         [isDisabled]="isReadonly"
                         [labelAlign]="'top'"
                         [label]="'combo_periode_salaire' | translate"
                         [options]="optionPeriode"
                         class="iw-input col-xs-3"
                         formControlName="salId"
                         style="margin-right: 10px;"></iw-dropdown>

            <iw-textfield #txtStatus
                          [isDisabled]="true"
                          [labelAlign]="'top'"
                          [label]="'statusperiode' | translate"
                          [value]="periodeStatusLibelle"
                          class="iw-input col-xs-2"
                          style="text-align-last: center;"></iw-textfield>
            <!-- Button Section -->
            <div class="button-section"
                 style="display: flex; flex-direction: row; margin-top: 10px; margin-left: 50px;">
                <iw-button #btnBulletins
                           (press)="openFicheSal()"
                           [isDisabled]="false"
                           [text]="'bulletins' | translate"
                           class="iw-button btn-block padding-bottom-30"
                           style="margin-bottom: 5px; margin-left: 5px; margin-top: 13px;"></iw-button>

                <iw-button #btnPaiements
                           (press)="openPaiement()"
                           *iwAccessGroups="SALARY_ACCOUNTING_ROLES"
                           [text]="'btn_periode_paiements' | translate"
                           class="iw-button btn-block padding-bottom-30"
                           style="margin-bottom: 5px; margin-left: 5px; margin-top: 13px;"></iw-button>

                <iw-button #btnJournaux
                           (press)="openJournaux()"
                           [text]="'journaux' | translate"
                           class="iw-button btn-block padding-bottom-30"
                           style="margin-bottom: 5px; margin-left: 5px; margin-top: 13px;"></iw-button>
            </div>
        </div>


    </div>
</form>

<!-- Tabs Section -->
<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList"
        style="margin-top: 20px;">
    <iw-tab-sagen-informations *ngIf="selectedTab === tabList[0]"
                               [formGroup]="formGroup"
                               [isReadonly]="!isWriteMode"></iw-tab-sagen-informations>

    <iw-tab-sagen-notes *ngIf="selectedTab === tabList[1]"
                        [formGroup]="formGroup"
                        [isReadonly]="!isWriteMode"></iw-tab-sagen-notes>

    <iw-tab-sagen-swissdec *ngIf="selectedTab === tabList[2]"
                           [formGroup]="formGroup"
                           [isReadonly]="!isWriteMode"></iw-tab-sagen-swissdec>
</iw-tab>
