import {Component} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {BaseStoreFormComponent} from '@sam-base/base';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {
    EntityNavigationService, IwEventHubService, IwStoreService, ModalService, RestApiService, RestEntityClient
} from '@sam-base/core';
import {StoreEvents} from '@sam-base/core/events/actions/store.actions';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@sam-base/models';
import {Ppacattest, Ppacattestview, Ppemp} from '@sam-base/models/placement';
import {camelCase, mapKeys} from 'lodash';

@Component({
    templateUrl: './ppacattest-new.component.html',
    standalone: false
})
export class PpacattestNewComponent extends BaseStoreFormComponent<Ppacattest> {

    public readonly = false;
    public isLoading = false;
    public ppemp = Ppemp;

    private _restPpacattest: RestEntityClient<Ppacattest>;

    constructor(store: IwStoreService, private _entityNavService: EntityNavigationService, private _translate: TranslateService, private _modalService: ModalService, private _restService: RestApiService, private readonly _events: IwEventHubService<StoreEvents>) {
        super(store);
        this._restPpacattest = this._restService.getEntityClient(Ppacattest);
    }

    public async generate() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('generer') + '?'],
            showCancel: true,
            title: this._translate.instant('ppacattestnew_form_title'),
            width: 200
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.isLoading = true;
            this.createEntity();
        } catch (error) {
            this.isLoading = false;
        }
    }

    public cancel() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Ppacattest> {
        return [['empId', new UntypedFormControl('', [Validators.required])], ['salIdLim', new UntypedFormControl('', [Validators.required])]];
    }

    private createEntity() {
        const ppacattest: Ppacattest | undefined = {
            ...this.getFormData()
        };
        if (!ppacattest) {
            return;
        }
        const toSave = this._restPpacattest.construct(ppacattest);
        this._restPpacattest.create(toSave)
            .subscribe((aca: Ppacattest) => {
                this._store.dispatch(new actions.SetLoading(this.uuid, false));

                const newAca: Ppacattest = mapKeys(aca, (_, key) => camelCase(key));
                if (!newAca || !newAca.acaId) {
                    return;
                }

                this._entityNavService
                    .navigateToEntityForm(Ppacattestview, newAca.acaId || '', this.getData('profile'), 'edit');

                this._events.emit(StoreEvents.EntitySaved, {$entity: 'ppacattest', ...aca});
                this.isLoading = false;
                this.destroyForm();
            }, error => {
                this.isLoading = false;
            });
    }
}
