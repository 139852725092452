import {FormDescription} from '@app/sam-base/core/form-handler/models';
import {buildDefaultState} from '@app/sam-base/core/store';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';
import {Swissdec4TableComponent} from '@modules/sam-main/admin/components/swissdec4/table/swissdec4-table.component';

export const SWISSDEC_4_LIST: FormDescription<Sasx4dec> = {
    form: Swissdec4TableComponent,
    state: {
        ...buildDefaultState(Sasx4dec),
        diagModal: false,
        editMode: 'waiting',
        width: 1600,
    }
};

