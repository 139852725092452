import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppemp} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppemp-langues', templateUrl: './tab-ppemp-langues.component.html',
    standalone: false
})
export class TabPpempLanguesComponent extends BasePartialFormComponent<Ppemp> {

    public readonly MIN_VALUE = 0;
    public readonly MAX_VALUE = 6;

    public get entityId() {
        return this.getFormValue<string>('empId');
    }
}
