import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwEventHubService} from '@app/sam-base/core/events';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {FormKeys} from '@app/sam-base/models';
import {Ppcli, Ppgri, Ppind, Ppmis} from '@app/sam-base/models/placement';
import {lastValueFrom} from 'rxjs';

/**
 * Component created regarding SAM-4567
 */
@Component({
    templateUrl: './ppindemnite.component.html',
    standalone: false
})
export class PpindemniteComponent extends BaseStoreFormComponent<Ppind> implements OnInit {

    public mis?: Ppmis;
    public cli?: Ppcli;
    public griId?: string;
    public fieldsDisabled = true;
    public mntEmpDisabled = true;
    private readonly _clientService: RestEntityClient<Ppcli>;
    private readonly _missionService: RestEntityClient<Ppmis>;

    constructor(store: IwStoreService, private readonly _events: IwEventHubService<string>,
                private readonly _restService: RestApiService) {
        super(store);
        this._clientService = this._restService.getEntityClient(Ppcli);
        this._missionService = this._restService.getEntityClient(Ppmis);
    }

    public get misId(): string {
        return this.getData('misId') || '';
    }

    public get cliId(): string {
        return this.getData('cliId') || '';
    }

    public get index(): number {
        return this.getData('index') || -1;
    }

    public async ngOnInit() {
        const dt: Ppind | undefined = this.getData('ppind');
        this.setDataForm(dt);

        const gri: string = this.getFormValue('griId');
        if (!!gri) {
            this.fieldsDisabled = false;
        }

        this.mis = await lastValueFrom(this._missionService.getById(this.misId));
        this.cli = await lastValueFrom(this._clientService.getById(this.cliId));
    }

    public async setGriId(ev: string) {
        this.griId = ev;
        if (!!this.griId) {
            const gri: Ppgri = await lastValueFrom(this._restService
                .getEntityClient(Ppgri)
                .getById(this.griId));
            this.setFormValue('libelle', gri.griName);
            this.fieldsDisabled = false;
            this.mntEmpDisabled = !(gri.griType === 1 || gri.griType === 2 || gri.griType === 3);
        }
    }

    public getEdtMisDetValue() {
        let value = '';
        if (this.mis && this.cli) {
            value += this.cli.nom + ' \\ ' + this.mis.qualif + '\n';
            value += 'Début           : ' + this.mis.datedebut + '\n';
            value += 'Tarif              : ' + this.mis.clitarif + '\n';
            // eslint-disable-next-line max-len
            value += 'Tarif (CTT)   : ' + (Number(this.mis.clitarifra) + Number(this.mis.clitarif)) + '\n';
            value += 'Sal. base     : ' + this.mis.salBase + '\n';
            value += 'Sal. global   : ' + this.mis.salGlobal + '\n';
        }
        return value;
    }

    public calcTotal() {
        this.calcTotalEmp();
        this.calcTotalCli();
    }

    public calcTotalEmp() {
        const qte = this.getFormValue('qte');
        const mntemp = this.getFormValue('mntemp');
        if (mntemp && qte) {
            const calc = mntemp * qte;
            this.setFormValue('totemp', calc.toFixed(2));
        } else {
            this.setFormValue('totemp', 0);
        }

        // Automatize mntcli amount (helper)
        const mntcli = this.getFormValue('mntcli');
        if (!mntcli && mntcli !== 0) {
            setTimeout(() => {
                this.setFormValue('mntcli', this.getFormValue('mntemp'));
            }, 1000);
        }
    }

    public calcTotalCli() {
        const qte = this.getFormValue('qte');
        const mntcli = this.getFormValue('mntcli');
        if (mntcli && qte) {
            const calc = mntcli * qte;
            this.setFormValue('totcli', calc.toFixed(2));
        } else {
            this.setFormValue('totcli', 0);
        }
    }

    public saveChanges() {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.index === -1 ? this._events.emit('indemnite', [this.formGroup.value]) : this._events.emit('indemnite', [
            this.formGroup.value,
            (this.index - 1)]);
        this.destroyForm();
    }

    public setDefaultValue(key: keyof Ppind) {
        setTimeout(() => {
            if (this.getFormValue(key) === undefined || this.getFormValue(key) === '') {
                this.setFormValue(key, 0);
            }
        });
    }

    protected getFormControlNames(): FormKeys<Ppind> {
        return [
            'qte',
            'griId',
            'unite',
            'rapId',
            'misId',
            'mntemp',
            'totemp',
            'mntcli',
            'totcli',
            'libelle',
            'modecreat',
            'misihId',
            'indType',
            'indId'];
    }

    protected getValidationType() {
        return Ppind;
    }

    private setDataForm(dt?: Ppind) {
        if (dt) {
            this.setFormValue('qte', dt.qte);
            this.setFormValue('griId', dt.griId);
            this.setFormValue('unite', dt.unite);
            this.setFormValue('mntemp', dt.mntemp);
            this.setFormValue('totemp', dt.totemp);
            this.setFormValue('mntcli', dt.mntcli);
            this.setFormValue('totcli', dt.totcli);
            this.setFormValue('libelle', dt.libelle);
            this.setFormValue('modecreat', dt.modecreat);
            this.setFormValue('misihId', dt.misihId);
            this.setFormValue('indType', dt.indType);
            this.setFormValue('indId', dt.indId);
        }
    }
}
