<div class="flex flex-v flex-evenly">
    <div class="row">
        <iw-button (press)="publish()"
                   *iwAccessGroups="FACTURE_PRINT_ROLES"
                   [isDisabled]="!canPublish"
                   [text]="'fiche_sal_button_publish' | translate"
                   class="btn-block"></iw-button>
    </div>
    <div class="row">
        <iw-button (press)="openPrint()"
                   *iwAccessGroups="FACTURE_PRINT_ROLES"
                   [iconClass]="'fas fa-download'"
                   class="btn-block"></iw-button>
    </div>
    <div class="row">
        <iw-button (press)="sendByMail()"
                   *iwAccessGroups="FACTURE_COMMUNICATION_ROLES"
                   [isDisabled]="!canSendByMail"
                   class="btn-block"
                   iconClass="fas fa-envelope"></iw-button>
    </div>
    <div class="row margin-top-25 flex flex-h flex-between">
        <iw-button (press)="copy()"
                   *iwAccessGroups="FACTURE_ADMIN_ROLES"
                   [isDisabled]="gefac?.facType === 'PT'"
                   [tooltip]="'copier_facture' | translate"
                   class="iw-button wide-button"
                   iconClass="fas fa-thin fa-copy"></iw-button>
        <iw-button (press)="createFacOrNc()"
                   *iwAccessGroups="FACTURE_ADMIN_ROLES"
                   [isDisabled]="isEditMode === 'edit' || gefac?.facOrnc === 'N'"
                   class="iw-button no-padding"
                   text="NC"></iw-button>

        <iw-button (press)="createFacOrNc()"
                   *iwAccessGroups="FACTURE_ADMIN_ROLES"
                   [isDisabled]="isEditMode === 'edit' || gefac?.facOrnc === 'F'"
                   class="iw-button no-padding"
                   text="FA"></iw-button>
    </div>
    <div class="row margin-top-10 flex flex-h flex-gap">
        <iw-button (press)="openEncaissements()"
                   *iwAccessGroups="FACTURE_ADMIN_ROLES"
                   [isDisabled]="false"
                   [text]="'encaissements' | translate"
                   class="btn-block padding-top-15"></iw-button>
    </div>
    <div *ngIf="isInvoiceReady"
         class="row margin-top-10 flex flex-h flex-gap">
        <iw-button (press)="markAsSent()"
                   *iwAccessGroups="FACTURE_COMMUNICATION_ROLES"
                   [isDisabled]="false"
                   [text]="'fac_mark_as_sent' | translate"
                   class="btn-block padding-top-15"></iw-button>
    </div>
    <div *ngIf="isInvoiceSent"
         class="row margin-top-10 flex flex-h flex-gap">
        <iw-button (press)="markAsUnsent()"
                   *iwAccessGroups="FACTURE_COMMUNICATION_ROLES"
                   [isDisabled]="false"
                   [text]="'fac_mark_as_unsent' | translate"
                   class="btn-block padding-top-15"></iw-button>
    </div>
</div>
