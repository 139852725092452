import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {
    BulkEntitySelectComponent
} from '@modules/sam-main/admin/components/bulk/bulk-entity-select/bulk-entity-select.component';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {environment} from '@root/environments/environment';
import {EsQueryStatement, Term, Terms} from '@sam-base/core';
import {Saemsview} from '@sam-base/models/salary/saemsview';

@Component({
    selector: 'iw-bulk-entity-select-saems',
    templateUrl: './bulk-entity-select.component.html',
    standalone: false
})
export class BulkEntitySelectSaemsComponent extends BulkEntitySelectComponent<Saemsview> implements OnInit, OnDestroy {
    public entity: Type<Saemsview> = <any>Saemsview;

    @Input()
    public operationType?: OperationType;
    @Input()
    public salId?: string;
    @Input()
    public force: boolean = false;

    constructor(private readonly http: HttpClient) {
        super();
    }

    public ngOnInit() {
        this.buildQueryStatements();
        this.setCustomColumns();
    }

    private buildQueryStatements() {
        const list: EsQueryStatement<Saemsview>[] = [];
        list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{type: 'saemsview'}, 'filter'));
        list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{salId: this.salId}, 'must'));
        if (this.operationType === OperationType.PUBLISH_SALARY) {
            list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{paid: 'true'}, 'must'));
            if (!this.force) {
                list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{published: 'false'}, 'must'));
            }
        } else if (this.operationType === OperationType.CALCULATE_SALARY) {
            // list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{published: 'false'}, 'must'));
            if (!this.force) {
                list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{calculated: 'false'}, 'must'));
            }
        } else if (this.operationType === OperationType.SEND_SALARY) {
            this.http.get<string[]>(`${environment.backendURL}salary/send-candidates/${this.salId}`).subscribe(saemsIds => {
                list.push(EsQueryStatement.fromTerms(<Terms<Saemsview>>{id: saemsIds}, 'must'));
                this.statements = list;
            });
        } else if (this.operationType === OperationType.EMAIL_YEAR_SALARIES) {
            list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{published: 'true'}, 'must'));
            list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{calculated: 'true'}, 'must'));
            list.push(EsQueryStatement.fromTerm(<Term<Saemsview>>{sendByMail: 'true'}, 'must'));
        }
        this.statements = list;
    }

    private setCustomColumns() {
        this.columns = [
            {
                prop: 'id',
                name: 'id',
            },
            {
                prop: 'empId',
                type: 'string'
            },
            {
                prop: 'nom',
                type: 'string'
            },
            {
                prop: 'prenom',
                type: 'string',
            },
            {
                name: 'periode',
                prop: 'salId',
                type: 'salId',
            },
            {
                name: 'salarynet',
                prop: 'salnet',
                type: 'mnt',
                align: 'right'
            },
            {
                name: 'salaryPaid',
                prop: 'salpaid',
                type: 'mnt',
                align: 'right'
            },
            {
                name: 'agence',
                prop: 'categorie1',
                type: 'string',
            },
            {
                name: 'nomsecteur',
                prop: 'categorie2',
                type: 'string',
            },
            {
                name: 'calcule',
                prop: 'calculated',
                type: 'boolean',
            },
            {
                name: 'published',
                prop: 'published',
                type: 'boolean',
            },
            {
                name: 'sendByMail',
                prop: 'sendByMail',
                type: 'boolean'
            },
            {
                name: 'email',
                prop: 'email',
                type: 'string',
            },

        ];
    }
}
