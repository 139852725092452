import {Component, OnInit, Type} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api/models/rest-query.model';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwGridColumn} from '@app/sam-base/models';
import {Js3view} from '@app/sam-base/models/salary/js3view';
import {TranslateService} from '@ngx-translate/core';

@Component({
    templateUrl: './decompte-gs.component.html',
    standalone: false
})
export class DecompteGSComponent extends BaseFormComponent<Js3view> implements OnInit {

    public type: Type<Js3view> = Js3view;
    public js3Columns = this.getJs3Columns();
    public query: RestQueryParam<Js3view, any>[] = [
        {
            operation: RestQueryOperation.Equals,
            prop: 'empId',
            value: '_'
        }];

    constructor(private _store: IwStoreService, private _translate: TranslateService) {
        super();
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get annee(): string {
        return this.getData('annee', true) || '';
    }

    public ngOnInit(): void {
        this.setQuery();
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    private setQuery() {
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'empId',
                value: this.empId
            },
            {
                operation: RestQueryOperation.Equals,
                prop: 'annee',
                value: this.annee
            }];
    }

    private getJs3Columns(): IwGridColumn<Js3view>[] {
        return [
            {
                prop: 'gsId',
                name: 'gs',
                index: 0
            },
            {
                prop: 'titre',
                name: 'titre',
                index: 1
            },
            {
                prop: 'mnt1',
                name: 'january',
                decimals: 2,
                index: 2,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt2',
                name: 'february',
                decimals: 2,
                index: 3,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt3',
                name: 'march',
                decimals: 2,
                index: 4,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt4',
                name: 'april',
                decimals: 2,
                index: 5,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt5',
                name: 'may',
                decimals: 2,
                index: 6,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt6',
                name: 'june',
                decimals: 2,
                index: 7,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt7',
                name: 'july',
                decimals: 2,
                index: 8,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt8',
                name: 'august',
                decimals: 2,
                index: 9,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt9',
                name: 'september',
                decimals: 2,
                index: 10,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt10',
                name: 'october',
                decimals: 2,
                index: 11,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt11',
                name: 'november',
                decimals: 2,
                index: 12,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mnt12',
                name: 'december',
                decimals: 2,
                index: 13,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mntcumul',
                name: 'total',
                decimals: 2,
                index: 14,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'cs06Type',
                name: 'ncs',
                index: 15
            },
            {
                prop: 'sd4Statan',
                name: 'sds_an',
                index: 16
            },
            {
                prop: 'sd4IsType',
                name: 'is_sd4',
                index: 17
            }];
    }

}
