import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {EntityCreate, EntityUpdate, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryParam} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Sadedtxage} from '@app/sam-base/models/salary/sadedtxage';

@Component({
    templateUrl: './sadedtxage.component.html',
    standalone: false
})
export class SadedtxageComponent extends BaseStoreFormComponent<Sadedtxage> implements OnInit {

    public query: RestQueryParam<Sadedtxage, string>[] = [];
    private _restSadedtxageClient: RestEntityClient<Sadedtxage>;

    constructor(store: IwStoreService, private _restService: RestApiService, private readonly _events: IwEventHubService<string>) {
        super(store);

        this._restSadedtxageClient = this._restService.getEntityClient(Sadedtxage);
    }

    public get annee(): string {
        return this.getData('annee', true) || '';
    }

    public get dedCode(): string {
        return this.getData('dedCode', true) || '';
    }

    public get assCode(): string {
        return this.getData('assCode', true) || '';
    }

    public get txCode(): string {
        return this.getData('txCode', true) || '';
    }

    public get entryMode(): 'add' | 'edit' {
        return this.getData('entryMode', true) || 'edit';
    }

    public get sadedtxage(): Sadedtxage | undefined {
        return this.getData('sadedtxage', true) || undefined;
    }

    public ngOnInit() {
        if (this.entryMode === 'edit' && !!this.sadedtxage) {
            this.fillFormData(this.sadedtxage);
        }
    }


    public save() {
        const formData = this.getFormData();
        const updatedData = this._restSadedtxageClient
            .construct({
                ...formData, ...{
                    annee: this.annee, dedCode: this.dedCode, assCode: this.assCode, txCode: this.txCode
                }
            });

        if (this.entryMode === 'add') {
            this._store.dispatch(new EntityCreate(updatedData));
        } else {
            this._store.dispatch(new EntityUpdate(updatedData));
        }

        this._events.emit('sadedtxage_updated');
        this.destroyForm();
    }

    public destroyForm() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Sadedtxage> {
        return ['txageId', 'agemax', 'agemin', 'agemnt', 'annee', 'dedCode', 'assCode', 'txCode'];
    }

}
