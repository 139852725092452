import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {ModalFormComponent} from '@app/sam-base/components';
import {ModalComponent} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';
import {HtmlElementStatus} from '@app/sam-base/models/html-element-status.model';
import {Sagsc} from '@app/sam-base/models/salary/sagsc';

import {SagsService} from '../../../services/sags.service';
import {GscptTableElement, SagscptOptions} from './sagscpt.model';

@Component({
    selector: 'iw-sagscpt',
    templateUrl: './sagscpt.component.html',
    standalone: false
})
export class SagscptComponent implements ModalComponent<void, SagscptOptions> {

    public options?: SagscptOptions;

    @Output() public pressOk: EventEmitter<void> = new EventEmitter();

    @Output() public pressCancel: EventEmitter<void> = new EventEmitter();

    public btnOk: HtmlElementStatus = {
        enabled: false,
        value: 'Ok',
        visible: false
    };

    public btnCancel: HtmlElementStatus = {
        enabled: false,
        value: 'Ok',
        visible: false
    };
    // Component info
    public iElement?: number;
    public selectedElement?: GscptTableElement[];
    public compte?: string;
    public editing = false;
    public loading = false;
    public tableData: GscptTableElement[] = [];
    public tableColumns: IwGridColumn<GscptTableElement>[] = [
        {
            prop: 'elemId',
            name: 'id',
            index: 0
        },
        {
            prop: 'elemTitle',
            name: 'titre',
            index: 1
        },
        {
            prop: 'compte',
            index: 2
        }];
    @ViewChild('modal') public modal?: ModalFormComponent;

    constructor(private _sagsService: SagsService) {
    }

    public get enableCompte(): boolean {
        return [
            '1',
            '2',
            '3',
            '4',
            '7',
            '8',
            '9',
            'B',
            'C',
            'M',
            'N',
            'P',
            'Q']
            .includes(this.options?.gsCtype ?? '');
    }

    public async setData(options: SagscptOptions): Promise<void> {
        this.options = options;
        this.loadTableData();
    }

    public loadTableData() {
        if (!this.options) {
            return;
        }
        this.loading = true;
        this._sagsService.getSagscptTableData(this.options)
            .subscribe({
                next: data => {
                    this.tableData = data.tableData;
                    this.iElement = data.iElement;
                    this.loading = false;
                    this.editing = false;
                    this.selectedElement = undefined;
                },
                error: () => this.loading = false
            });
    }

    public loadCompte(element?: GscptTableElement[]) {
        if (element && element[0]) {
            this.compte = element[0].compte;
        } else {
            this.compte = (this.selectedElement && this.selectedElement[0].compte) ?? '';
        }
    }

    public saveElement() {
        if (!this.selectedElement) {
            return;
        }
        const sagsc: Sagsc = this.selectedElement[0].sagsc;
        // Save sags
        sagsc.compte = this.compte;

        this._sagsService.saveSagsc(sagsc)
            .subscribe({
                next: () => this.loadTableData(),
                error: () => this.loading = false
            });

    }

    public deleteCompte() {
        this.compte = undefined;
        this.saveElement();
    }

    public registerOk() {
        this.pressOk.emit();
    }

    public registerCancel() {
        this.modal?.onPressCancel();
        this.pressCancel.emit();
    }

}
