import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base/base-partial-form.component';
import {Ppcct} from '@app/sam-base/models/placement';

import {HeuresSup} from '../../missions/tab-ppmis-salary-tarif/tab-ppmis-salary-enums';

@Component({
    selector: 'iw-tab-cct-autre', templateUrl: './tab-cct-autre.component.html',
    standalone: false
})
export class TabCctAutreComponent extends BasePartialFormComponent<Ppcct> {

    public heursSupNames = HeuresSup;

    public typedecValue?: string;
    public shcalcmodeValue?: string;

    constructor() {
        super();
    }

    /**
     * Check if #txtTx_pause field is enabled
     */
    public checkTxPauseEnabled() {
        return this.getFormValue<boolean>('heurepause') && !this.isReadonly;
    }

    /**
     * Function to set shcalcmode value
     */
    public setShcalcmodeValue() {
        this.shcalcmodeValue = this.getFormValue<string>('shcalcmode');
    }

    /**
     * Function to set typedec value
     */
    public setTypedecValue() {
        this.typedecValue = this.getFormValue<string>('typedec');
    }
}
