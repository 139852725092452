import {Component, OnInit, Type, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {GepaimodeService, GepaimodeSubscrition} from '@app/sam-base/core/services/gepaimode.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Gepaimode, GePaytype} from '@app/sam-base/models/invoices/gepaimode';
import {Subject, takeUntil} from 'rxjs';


@Component({
    templateUrl: './gepaimode.component.html',
    standalone: false
})
export class GepaimodeComponent extends BaseFormComponent<Gepaimode> implements OnInit {

    @ViewChild('gepaimodeGrid') public gepaimodeGrid?: IwRestGridComponent<Gepaimode>;
    public type: Type<Gepaimode> = Gepaimode;
    public query: RestQueryParam<Gepaimode, string | string>[] = [];
    public payType = GePaytype;
    public gepaimode: Gepaimode = new Gepaimode();
    public subscritions = new Subject();
    private selectedGepaimode: Gepaimode | undefined;

    public constructor(protected readonly _store: IwStoreService, private _gepaimodeService: GepaimodeService) {
        super();
    }

    private _isEditing = false;

    public get isEditing(): boolean {
        return this._isEditing;
    }

    private _isNew = false;

    public get isNew(): boolean {
        return this._isNew;
    }

    public get isSelected(): boolean {
        return this.selectedGepaimode !== undefined;
    }

    public ngOnInit(): void {
        this.setQuery();
    }

    public modifyGepaimode() {
        this._isEditing = true;
    }

    public newEntry() {
        this.selectedGepaimode = undefined;
        this._isEditing = true;
        this._isNew = true;
        this.formGroup.reset();
    }

    public deleteEntry() {
        this._gepaimodeService.delete(this.getFormValue('mpaId'))
            .subscribe(() => {
                this._gepaimodeService.gepaimodeSub.next({
                    uuid: this.uuid,
                    saved: true
                });
            });
        this._gepaimodeService.gepaimodeSub
            .pipe(takeUntil(this.subscritions))
            .subscribe((data: GepaimodeSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.refreshGrid();
                }
            });
    }

    public saveEntry() {
        this.gepaimode.mpaId = this.getFormValue('mpaId');
        this.gepaimode.titre = this.getFormValue('titre');
        this.gepaimode.compte = this.getFormValue('compte');
        this.gepaimode.ltva = this.getFormValue('ltva');
        this.gepaimode.lbvr = this.getFormValue('lbvr');
        this.gepaimode.paytype = this.getFormValue('paytype');
        this.gepaimode.bvrnobnk = this.getFormValue('bvrnobnk');
        this.gepaimode.bvrnocli = this.getFormValue('bvrnocli');
        this.gepaimode.bvrrefdef = this.getFormValue('bvrrefdef');
        this.gepaimode.bvrfileext = this.getFormValue('bvrfileext');
        this.gepaimode.lqr = this.getFormValue('lqr');
        this.gepaimode.qriban = this.getFormValue('qriban');
        this._gepaimodeService.save(this.gepaimode)
            .subscribe(() => {
                this._gepaimodeService.gepaimodeSub.next({
                    uuid: this.uuid,
                    saved: true
                });
            });

        this._gepaimodeService.gepaimodeSub
            .pipe(takeUntil(this.subscritions))
            .subscribe((data: GepaimodeSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.refreshGrid();
                }
            });
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public getSelectedRow(event: Gepaimode[]) {
        this.setGepaimodeInfo(event);
    }

    public cancelEditionMode() {
        this._isEditing = false;
        this._isNew = false;
        this.applyGepaimodeToVariables(this.selectedGepaimode);
    }

    protected getFormControlNames(): FormKeys<Gepaimode> {
        return [
            'mpaId',
            'titre',
            'compte',
            'paytype',
            'lbvr',
            'ltva',
            'bvrnobnk',
            'bvrnocli',
            'bvrfileext',
            'bvrrefdef',
            'lqr',
            'qriban'];
    }

    protected getValidationType() {
        return Gepaimode;
    }

    private setGepaimodeInfo(gepaimode: Gepaimode[]) {
        if (gepaimode.length > 0) {
            this.selectedGepaimode = gepaimode[0];
            this.applyGepaimodeToVariables(gepaimode[0]);
        } else {
            this.selectedGepaimode = undefined;
        }
    }

    private applyGepaimodeToVariables(gepaimode: Gepaimode | undefined) {
        this.fillFormData(gepaimode || {});
    }

    private setQuery() {
        this.query = [
            {
                operation: RestQueryOperation.NotEquals,
                prop: 'mpaId',
                value: '_'
            }];
    }

    private refreshGrid() {
        this._isNew = false;
        this._isEditing = false;
        this.gepaimodeGrid?.refresh();
        this.selectedGepaimode = undefined;
    }
}
