/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import {Component, OnDestroy, OnInit, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService, IwEventHubService} from '@app/sam-base/core';
import {RestQueryParam} from '@app/sam-base/core/rest-api';
import {IwGridColumn} from '@app/sam-base/models';
import {Saemi} from '@app/sam-base/models/salary/saemi';
import {Saems} from '@app/sam-base/models/salary/saems';
import {environment} from '@root/environments/environment';
import {Subject, takeUntil} from 'rxjs';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saems-ema',
    templateUrl: './tab-saems-ema.component.html',
    standalone: false
})
export class TabSaemsEmaComponent extends BasePartialFormComponent<Saems> implements OnInit, OnDestroy {

    public type: Type<Saemi> = Saemi;
    public selectedElem?: Saemi;
    public saemiList: Saemi[] = [];
    public query: RestQueryParam<Saemi, any>[] = [];
    public columns = this.getColumns();
    public subscriptions = new Subject();
    private _baseUrl = ' ';

    constructor(private readonly _formHandler: FormHandlerService<SalaryForm>, private readonly _http: HttpClient,
                private readonly _eventService: IwEventHubService<string>) {
        super();
        this._baseUrl = environment.backendURL;
    }

    public ngOnInit() {
        this.getSaemiList();
        this.subscribeValueChange('empId', () => this.getSaemiList());
        this._eventService
            .forType<any>('saemi_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe(async () => {
                setTimeout(() => {
                    this.getSaemiList();
                }, 2000);
            });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public getSelectedRow(event?: Saemi[]) {
        if (event) {
            this.selectedElem = event[0];
        }
    }

    public addIs(): void {
        this._formHandler.showFormDialog(SalaryForm.SaemiIS, {
            empId: this.getFormValue('empId'),
            salId: this.getFormValue('salId')
        }, (s) => ({
            ...s,
            isNew: true
        }));
    }

    public addAvs(): void {
        this._formHandler.showFormDialog(SalaryForm.SaemiAVS, {
            empId: this.getFormValue('empId'),
            salId: this.getFormValue('salId')
        }, (s) => ({
            ...s,
            isNew: true
        }));
    }

    public addLpp(): void {
        this._formHandler.showFormDialog(SalaryForm.SaemiLPP, {
            empId: this.getFormValue('empId'),
            salId: this.getFormValue('salId')
        }, (s) => ({
            ...s,
            isNew: true
        }));
    }

    public editElem(): void {
        if (this.selectedElem?.emiGenre === 'IMP') {
            this._formHandler.showFormDialog(SalaryForm.SaemiIS, undefined, (s) => ({
                ...s,
                entityId: this.selectedElem?.emiId ?? 0,
                diagModal: true,
                readMode: 'read',
                isNew: false
            }));
        } else if (this.selectedElem?.emiGenre === 'AVS' || this.selectedElem?.emiGenre === 'CAF') {
            this._formHandler.showFormDialog(SalaryForm.SaemiAVS, undefined, (s) => ({
                ...s,
                entityId: this.selectedElem?.emiId ?? 0,
                diagModal: true,
                readMode: 'read',
                isNew: false
            }));
        } else {
            this._formHandler.showFormDialog(SalaryForm.SaemiLPP, undefined, (s) => ({
                ...s,
                entityId: this.selectedElem?.emiId ?? 0,
                diagModal: true,
                readMode: 'read',
                isNew: false
            }));
        }
    }

    public deleteElem(): void {
        this._http
            .delete<void>(this._baseUrl + `saemi/delete/${this.selectedElem?.emiId}`)
            .subscribe(() => this.getSaemiList());
    }

    private getSaemiList(): void {
        this._http
            .get<Saemi[]>(this._baseUrl + `saemi/getSaemiListByEmpIdAndSalId/${this.getFormValue('empId')}/${this.getFormValue('salId')}`)
            .subscribe((list: Saemi[]) => {
                // eslint-disable-next-line complexity
                list.forEach((elem: Saemi) => {
                    elem.cantonOld = `${elem.cantonOld ?? ''} -> ${elem.cantonimp ?? ''}`;
                    elem.cat1Old = `${elem.cat1Old ?? ''} -> ${elem.categorie1 ?? ''}`;
                    elem.barc3Old = `${elem.barc3Old ?? ''} -> ${elem.barc3 ?? ''}`;
                    elem.mntsalOld = Number(elem.mntsalOld) + Number(elem.mntSal);
                    elem.tauxOld = Number(elem.tauxOld) + Number(elem.taux);
                    elem.mntdedOld = Number(elem.mntdedOld) + Number(elem.mntDed);
                });
                this.saemiList = list;
            });
    }

    private getColumns(): IwGridColumn<Saemi>[] {
        return [
            {
                prop: 'emiGenre',
                name: 'genre',
                index: 0
            },
            {
                prop: 'salIdCor',
                name: 'mois',
                index: 1
            },
            {
                prop: 'emiType',
                name: 'type',
                index: 2
            },
            {
                prop: 'cantonOld',
                name: 'canton',
                index: 1
            },
            {
                prop: 'cat1Old',
                name: 'lieu',
                index: 1
            },
            {
                prop: 'barc3Old',
                name: 'bareme',
                index: 1
            },
            {
                prop: 'mntsalOld',
                name: 'salaire',
                index: 1,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'tauxOld',
                name: 'taux',
                index: 1,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'mntdedOld',
                name: 'deduction',
                index: 1,
                type: 'mnt',
                align: 'right'
            },
            {
                prop: 'emaDate',
                name: 'date',
                index: 1
            }];
    }
}
