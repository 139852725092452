import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {FofouService} from '@app/sam-base/core/services/fofou.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Fofou} from '@app/sam-base/models/fornisseurs/fofou';
import {Fofoudp} from '@app/sam-base/models/fornisseurs/fofoudp';
import {Saempop} from '@app/sam-base/models/salary/saempop';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: './saempop.component.html',
    standalone: false
})
export class SaempopComponent extends BaseStoreFormComponent<Saempop> implements OnInit, OnDestroy {
    public entity = Saempop;

    public isSaving = false;
    public query: RestQueryParam<Saempop, any>[] = [];
    public fouId?: string = '';
    public fouNom?: string = '';
    public foudpNom?: string = '';
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private readonly _fofouService: FofouService) {
        super(store);
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public ngOnInit() {
        const empId = this.empId;
        if (typeof empId === 'string') {
            this.query = [{
                operation: RestQueryOperation.Equals, prop: 'empId', value: empId
            }];
        }
        this.isNewChanged
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e: boolean) => this.onIsNewChanged(e));

        this.subscribeValueChange<string>('empopId', e => {
            if (e) {
                this._fofouService.getNomFromFofou(this.getFormValue('fouId') || '')
                    .subscribe((value) => {
                        this.fouNom = value.nom;
                        this.fouId = value.fouId;
                    });

                this._fofouService.getFofoudp(this.getFormValue('foudpId') || '')
                    .subscribe((value) => {
                        this.foudpNom = value.banque;
                    });
            }
        });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public setFou(event: Fofou) {
        this.fouId = event.fouId;
        this.fouNom = event.nom;
        this.setFormValue('fouId', this.fouId);
    }

    public setFoudp(event: Fofoudp) {
        this.foudpNom = event.banque;
    }

    public close(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public fofoudpIsDisabled(): boolean {
        return !this.isWriteMode || !this.getFormValue('fouId');
    }

    protected validateReadonlyStatus(e: Saempop) {
        return !e.empopId && !this.isNew;
    }

    protected onIsNewChanged(isNew: boolean) {
        if (this.empId && isNew) {
            this.setFormValue('empId', this.empId);
            this.fouNom = '';
            this.foudpNom = '';
        }
    }

    protected getFormControlNames(): FormKeys<Saempop> {
        return ['empopId', 'empId', 'gsId', 'fouId', 'foudpId', 'norefbvr', 'refop', 'compte'];
    }
}
