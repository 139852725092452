import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@sam-base/base';
import {EntityNavigationService, IwStoreService} from '@sam-base/core';
import {GecomptaService} from '@sam-base/core/services/gecompta.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {SetLoading} from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {FormKeys, IwGridColumn, RowClickEvent} from '@sam-base/models';
import {Gecompta, JcptaErr, ModeAgence, ModeRegroup} from '@sam-base/models/invoices/gecompta';
import {Gefachdr} from '@sam-base/models/invoices/gefachdr';
import {Gepaihdr} from '@sam-base/models/invoices/gepaihdr';
import _ from 'lodash';

@Component({
    selector: 'iw-facture-compta',
    templateUrl: './facture-compta.component.html',
    standalone: false
})
export class FactureComptaComponent extends BaseFormComponent<Gecompta> implements OnInit {

    public errors: JcptaErr[] = [];
    public columnsError: IwGridColumn<JcptaErr>[] = [
        {
            name: 'id',
            prop: 'entityId',
            index: 0
        },
        {
            name: 'type',
            prop: 'entity',
            type: 'translate',
            index: 1
        },
        {
            name: 'message',
            prop: 'errText',
            index: 2
        }
    ];
    protected readonly ModeRegroup = ModeRegroup;
    protected readonly ModeAgence = ModeAgence;
    private gecompta?: Gecompta;

    constructor(private readonly _gecomptaService: GecomptaService,
                private readonly _navigationService: EntityNavigationService,
                private readonly _toastService: ToastService,
                private readonly _store: IwStoreService) {
        super();
    }

    ngOnInit(): void {
        const salId = this.getData<string>("salId", true)!;
        this._gecomptaService.init(salId)
            .subscribe(gecompta => {
                this.gecompta = gecompta;
                this.fillFormData(gecompta);
            });
    }

    transfer() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        const gecompta = this.getFormData()!;
        gecompta.prepareResult = undefined;
        gecompta.nbEcrFac = undefined;
        gecompta.nbEcrPay = undefined;
        gecompta.nbErreurs = undefined;
        gecompta.prepareResult = undefined;
        this._gecomptaService.transfert(gecompta)
            .subscribe({
                next: (gecompta) => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    this.gecompta = gecompta;
                    this.fillFormData(gecompta);
                    this.errors = gecompta?.prepareResult?.jcptaErrList || [];
                    if (this.errors.length > 0) {
                        this._toastService.warning('toast.facture_compta.warning.comptabiliser');
                    } else {
                        this._toastService.success('toast.facture_compta.success.comptabiliser');
                    }
                },
                error: () => {
                    this._toastService.error('toast.facture_compta.error.comptabiliser');
                    this._store.dispatch(new SetLoading(this.uuid, false))
                }
            });
    }

    decomptabiliser() {
        this._store.dispatch(new SetLoading(this.uuid, true));
        const gecompta = _.assign({}, this.gecompta, this.getFormData()!);
        gecompta.prepareResult = undefined;
        this._gecomptaService.cancel(gecompta)
            .subscribe({
                next: gecompta => {
                    this._store.dispatch(new SetLoading(this.uuid, false));
                    this.gecompta = gecompta;
                    this.fillFormData(gecompta);
                    this.errors = gecompta?.prepareResult?.jcptaErrList || [];
                    if (this.errors.length > 0) {
                        this._toastService.warning('toast.facture_compta.warning.decomptabiliser');
                    } else {
                        this._toastService.success('toast.facture_compta.success.decomptabiliser');
                    }
                },
                error: err => {
                    this._toastService.error('toast.facture_compta.error.decomptabiliser');
                    this._store.dispatch(new SetLoading(this.uuid, false));
                }
            });
    }

    closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    openErrorEntity(event: RowClickEvent<JcptaErr>) {
        const entityType = event.row.entity;
        const entityId = event.row.entityId;
        if (!entityId) return;
        if (entityType === 'gepaihdr') {
            this._navigationService.navigateToEntityForm(Gepaihdr, entityId, undefined, 'read');
        } else if (entityType === 'gefachdr') {
            this._navigationService.navigateToEntityForm(Gefachdr, entityId, undefined, 'read');
        }
    }

    protected getFormControlNames(): FormKeys<Gecompta> {
        return [
            'dateDe',
            'dateA',
            'fac',
            'pay',
            'facZero',
            'force',
            'facDebCre',
            'alwaysSplitEcr',
            'modeRegoup',
            'modeAgence',
            'prvRist',
            'withCompteAux',
            'cptDeb',
            'cptRist',
            'cptPrvRist',
            'cptRist1',
            'cptRab',
            'cptFrais',
            'nbEcrFac',
            'nbEcrPay',
            'nbErreurs',
            'prepareResult'];
    }

    protected getValidationType() {
        return Gecompta;
    }
}
