<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-12 margin-bottom-10">
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="payId"
                      class="iw-input col-xs-2 margin-top-10"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="mpaId"
                      class="iw-input col-xs-2 margin-top-10"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="datePay"
                      class="iw-input col-xs-2 margin-top-10"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="sMntPay"
                      class="iw-input col-xs-2 margin-top-10"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="cliNom"
                      class="iw-input col-xs-2 margin-top-10 bold"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="cliId"
                      class="iw-input col-xs-2 margin-top-10"></iw-textfield>
    </div>
    <div class="row col-xs-12 margin-bottom-10">
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="'' | translate"
                      class="iw-input col-xs-6 margin-top-10"
                      value="Factures ouvertes"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      [value]="numberOfFacs"
                      class="iw-input col-xs-6 margin-top-10"></iw-textfield>
    </div>
    <ng-container>
        <iw-table (selectedChange)="selectedChanged($event)"
                  [columns]="tableColumns"
                  [data]="listOfFacs"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [isSortIconEnable]="false"
                  class="iw-input col-xs-12 margin-bottom-10"
                  selectionMode="checkbox"
                  style="height: 250px"></iw-table>
    </ng-container>
    <div class="row col-xs-12 margin-bottom-10"
         style="align-items: center;">
        <div class="row space-form col-xs-6 separator-right">
            <div class="row col-xs-12 margin-bottom-10"
                 style="align-items: end;">
                <iw-checkbox (valueChange)="calcPayData($event)"
                             [(value)]="forcerMontant"
                             [isDisabled]="sMntPay === 0"
                             [labelAlign]="'right'"
                             [label]="'forcerMontant' | translate"
                             class="iw-input col-xs-6"></iw-checkbox>
                <iw-textfield (controlFocus)="$event ? onFocus() : onBlur();"
                              [(value)]="sMntPay"
                              [isDisabled]="!forcerMontant"
                              [labelAlign]="'top'"
                              [label]="'montant' | translate"
                              class="iw-input col-xs-3 margin-top-10 text-align-right"
                              type="number"></iw-textfield>
                <iw-textfield [(value)]="nbFacSelected"
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="''"
                              class="iw-input col-xs-3 margin-top-10"></iw-textfield>
                <iw-checkbox (valueChange)="calcPayData($event)"
                             [(value)]="lCompensate"
                             [isDisabled]="sMntPay !== 0"
                             [labelAlign]="'right'"
                             [label]="'compensation' | translate"
                             class="iw-input col-xs-12"></iw-checkbox>
                <iw-textfield [(value)]="mntFacSelected"
                              [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'factureMontant' | translate"
                              class="iw-input col-xs-6 margin-top-10 margin-bottom-10 text-align-right"
                              type="number"></iw-textfield>
                <iw-textfield [(value)]="nbFacSelected"
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="''"
                              class="iw-input col-xs-6 margin-top-10 margin-bottom-10"></iw-textfield>
                <div class="col-xs-12 separator-top no-padding"></div>
                <div class="row col-xs-12 margin-bottom-10">
                    <iw-textfield [(value)]="mntDelta"
                                  [isDisabled]="true"
                                  [labelAlign]="'left'"
                                  [label]="'difference' | translate"
                                  class="iw-input col-xs-6 margin-top-10 text-align-right"
                                  type="number"></iw-textfield>
                    <iw-textfield *ngIf="mntDelta <= 0"
                                  [(value)]="tauxDelta"
                                  [isDisabled]="true"
                                  [label]="''"
                                  class="iw-input col-xs-6 margin-top-10 text-align-right"
                                  type="number"></iw-textfield>
                </div>
            </div>
        </div>
        <div class="row col-xs-6 margin-bottom-10">
            <iw-textfield [(value)]="mntSolde"
                          [isDisabled]="true"
                          [label]="''"
                          class="iw-input col-xs-2 margin-top-10 text-align-right"
                          type="number"></iw-textfield>
            <iw-textfield [(value)]="payExplanation"
                          [isDisabled]="true"
                          [label]="''"
                          class="iw-input col-xs-8 margin-top-10"></iw-textfield>
            <iw-textfield [(value)]="opeMode"
                          [isDisabled]="true"
                          [label]="''"
                          class="iw-input col-xs-2 margin-top-10"></iw-textfield>
            <div class="row col-xs-12 padding-top-10">
                <iw-checkbox (valueChange)="CalcSoldeAndMode()"
                             [(value)]="lattribSolde"
                             [isDisabled]="mntDelta === 0 && lattribSolde === true || facToExecList.length === 0"
                             [labelAlign]="'right'"
                             [label]="'attribuerLeSolde' | translate"
                             class="iw-input col-xs-12 padding-left-0"></iw-checkbox>
                <iw-checkbox *ngIf="sMntPay === 0"
                             [isDisabled]="mntDelta === 0 && lattribSolde === true"
                             [labelAlign]="'right'"
                             [label]="'ajusterLePaiement' | translate"
                             class="iw-input col-xs-6 margin-top-10 padding-left-0"></iw-checkbox>
                <iw-dropdown #cboannee
                             [(value)]="soldeMpaId"
                             [labelAlign]="'top'"
                             [label]="''"
                             [options]="comboGepaimode"
                             class="iw-input col-xs-12 padding-left-0"></iw-dropdown>
            </div>
        </div>
    </div>
    <div class="row col-xs-12 margin-bottom-10"></div>
    <div class="col-xs-4 col-xs-offset-8"
         style="align-items: end;display:flex;">
        <iw-button #btnClose
                   (press)="saveChanges()"
                   [isDisabled]="facToExecList.length === 0"
                   [text]="'Enregistrer' | translate"
                   class="iw-button padding-top-15 margin-right-5"></iw-button>
        <iw-button #btnClose
                   (press)="closeDialog()"
                   [text]="'Annuler' | translate"
                   class="iw-button"></iw-button>
    </div>
</form>
