import {Component} from '@angular/core';
import {ModalOperationComponent} from '@modules/sam-main/admin/components/operation/modal-operation.component';
import {ModalOperationOptions} from '@modules/sam-main/admin/components/operation/operation-modal.options';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@sam-base/core';
import {Message, MessageService} from 'primeng/api';
import {debounceTime, filter, groupBy, mergeMap} from 'rxjs/operators';

import {IwEventHubService} from '../events';
import {
    BUSINESS_EXCEPTION_TOAST_KEY,
    CUSTOM_TOAST_KEY,
    DEFAULT_TOAST_KEY,
    isToast,
    OPERATION_TOAST_KEY,
    SYSTEM_TOAST_KEY,
    TOAST_EVENT,
    ToastMessage
} from './toast.model';

@Component({
    selector: 'iw-toast-host',
    styleUrls: ['./toast-host.component.scss'],
    template: `
        <!-- system error -->
        <iw-toast-custom [position]="'top-right'"
                         [toastKey]="SYSTEM_TOAST_KEY"></iw-toast-custom><!-- normal error -->
        <iw-toast-custom [position]="'top-right'"
                         [showSendToSupport]="false"
                         [toastKey]="BUSINESS_EXCEPTION_TOAST_KEY"></iw-toast-custom><!-- normal error -->
        <p-toast position="bottom-center"
                 width="200px"
                 class="custom-toast"
                 [key]="DEFAULT_TOAST_KEY">
            <ng-template let-message
                         pTemplate="message">
                <div style="text-align: justify-all"
                     class="col-xs-11">
                    <div class="row">
                        <div class="col-xs-2">
                            <i class="pi pi-exclamation-triangle"
                               style="font-size: 2em"></i>
                        </div>
                        <div class="col-xs-10">
                            <h3>{{ message.summary }}</h3>
                        </div>
                    </div>
                    <p>{{ message.detail }}</p>
                </div>
            </ng-template>
        </p-toast><!-- custom error -->
        <p-toast position="bottom-center"
                 width="200px"
                 class="custom-toast"
                 [key]="OPERATION_TOAST_KEY">
            <ng-template let-message
                         pTemplate="message">
                <div class="row">
                    <div class="col-xs12">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="row middle-xs">
                                    <div class="col-xs-2">
                                        <i class="pi pi-info-circle"
                                           style="font-size: 2em"></i>
                                    </div>
                                    <div class="col-xs-10">
                                        <h3>{{ message.summary }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                <p>{{ message.detail }}</p>
                            </div>
                        </div>
                        <div class="row end-xs middle-xs">
                            <p class="col-xs-6">{{ 'open_operation_details' | translate }}</p>
                            <button (click)="openOperation(message.id)"
                                    class="p-toast-icon-close p-link col-xs-1">
                                <span class="fas fa-microchip"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-toast>
        <iw-toast-custom [position]="'bottom-center'"
                         [toastKey]="CUSTOM_TOAST_KEY"></iw-toast-custom>
    `,
    standalone: false
})
export class ToastHostComponent {
    public toasts: Message[] = [];

    public SYSTEM_TOAST_KEY = SYSTEM_TOAST_KEY;
    public DEFAULT_TOAST_KEY = DEFAULT_TOAST_KEY;
    public CUSTOM_TOAST_KEY = CUSTOM_TOAST_KEY;
    public OPERATION_TOAST_KEY = OPERATION_TOAST_KEY;
    public BUSINESS_EXCEPTION_TOAST_KEY = BUSINESS_EXCEPTION_TOAST_KEY;

    constructor(eventsService: IwEventHubService<string>, private _messageService: MessageService,
                private readonly _modalService: ModalService,
                private readonly _translate: TranslateService) {
        eventsService.events.pipe(
            filter(e => e.type === TOAST_EVENT),
            groupBy(e => e.payload.severity), // group by toast type in order to debounce by type
            mergeMap(group => group.pipe(
                debounceTime(1000)
            ))
        ).subscribe(e => {
            if (isToast(e.payload)) {
                this.insertToast(e.payload);
            }
        });
    }

    public getToasts() {
        return this.toasts.map(toast => ({
            ...toast,
            key: 'toast'
        } as Message))
            .filter(e => (e.life || 0) >= 0);
    }

    /** Insert a new toast and clear all timeouted toasts */
    public insertToast(toast: ToastMessage) {
        // If toast.closable is undefined, set it to !this.isCustomError(toast) otherwise use the value from toast
        const closable = toast.closable === undefined ? !this.isCustomError(toast) : toast.closable;
        const parsedToast: Message = {
            ...toast,
            key: this.getToastKey(toast),
            closable
            // Summary: this.isCustomError(toast) ? toast.summary : undefined,
        };
        this._messageService.add(parsedToast);
    }

    openOperation(id: number) {
        const options: ModalOperationOptions = {
            showCancel: true,
            title: this._translate.instant('operations_modal_title'),
            width: 1500,
            height: 900,
        };

        this._modalService.showModal(ModalOperationComponent, options);
    }

    /**
     * Checks if the toast has an error message
     *
     * @param toast Toast message to show
     * @returns true if the toast as summary (error message, the message length
     * is bigger than 1 and if the severity is error)
     */
    private isCustomError(toast: ToastMessage): boolean {
        return !!toast.summary && !!toast.summary.length && toast.severity === 'error';
    }

    private getToastKey(toast: ToastMessage): string {
        if (toast.isBusinessException) {
            return this.BUSINESS_EXCEPTION_TOAST_KEY;
        }
        return toast.isSystem ? this.SYSTEM_TOAST_KEY : toast.isOperation ? this.OPERATION_TOAST_KEY : this.getCustomOrDefaultToastKey(toast);
    }

    private getCustomOrDefaultToastKey(toast: ToastMessage): string {
        return this.isCustomError(toast) ? this.CUSTOM_TOAST_KEY : this.DEFAULT_TOAST_KEY;
    }
}
