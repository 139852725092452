import {HttpClient} from '@angular/common/http';
import {Component, Type, ViewChild} from '@angular/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {BulkManagerComponent} from '@modules/sam-main/admin/components/bulk/bulk-manager.component';
import {BulkManagerOptions} from '@modules/sam-main/admin/components/bulk/bulk-manager.options';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {SalaryForm} from '@modules/sam-main/salary/salary.forms';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {BaseFormComponent} from '@sam-base/base';
import {IwSmartGridComponent} from '@sam-base/components';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {FormHandlerService, IwEventHubService, IwStoreService, ModalService} from '@sam-base/core';
import {accountingRoles, salaryPrintRoles} from '@sam-base/core/auth/access-rules/facture-roles';
import {RestQueryOperation, RestQueryParam} from '@sam-base/core/rest-api';
import {ToastService} from '@sam-base/core/toast';
import {IwGridColumn} from '@sam-base/models';
import {Geper} from '@sam-base/models/invoices/geper';
import {isArray} from 'lodash';

@Component({
    templateUrl: 'geper.component.html',
    styleUrls: ['geper.component.scss'],
    standalone: false
})
export class GeperComponent extends BaseFormComponent<Geper> {
    public readonly type: Type<Geper> = Geper;
    public selectedPeriod: Geper = {};
    public columns = this.getGeperColumns();
    public query: RestQueryParam<Geper, string>[] = [
        {
            prop: 'salId',
            operation: RestQueryOperation.NotEquals,
            value: '-'
        }];
    @ViewChild('geperGrid', {static: true}) public geperGrid?: IwSmartGridComponent<Geper>;
    public ACCOUNTING_ROLES: string[] = accountingRoles();
    public FACTURE_PRINT_ROLE: string[] = salaryPrintRoles();

    protected readonly _baseUrl = environment.backendURL + 'geper/';

    constructor(private readonly _translate: TranslateService, private readonly _http: HttpClient,
                private readonly _store: IwStoreService, private readonly _modalService: ModalService,
                private readonly _toast: ToastService, private readonly _events: IwEventHubService<string>,
                private readonly _formHandler: FormHandlerService<SalaryForm>) {
        super();
    }

    public get canCalculatePeriod() {
        return !!this.selectedPeriod.salId && this.selectedPeriod.status === 0;
    }

    public get periodCloseDisabled() {
        return this.selectedPeriod.status !== 0;
    }

    public getSelectedRow(event: Geper[]) {
        if (!event.length) {
            this.selectedPeriod = {};
            return;
        }
        this.selectedPeriod = event[0];
    }

    public createNewPeriod() {
        this._http
            .post(this._baseUrl + 'create', {})
            .subscribe(() => this.refreshGrid());
    }

    public openPeriod() {
        if (this.selectedPeriod) {
            const period = this.selectedPeriod.salId;
            this._http
                .post(this._baseUrl + 'open/' + period, {})
                .subscribe(() => this.refreshGrid());
        }
    }

    public closePeriod() {
        if (this.selectedPeriod) {
            const messages: string[] = [];
            const period = this.selectedPeriod.salId;
            this._store.dispatch(new actions.SetLoading(this.uuid, true));
            this._http
                .post(this._baseUrl + 'close/' + period, {})
                .subscribe({
                    next: (value: Geper | Geper[]) => {
                        if (isArray(<Geper>value)) {
                            for (const element of Object.values(value)) {
                                messages.push(`${element.empId} - ${element.empNom} ->\
                  ${element.texte}`);
                            }
                            this._toast.error('error_cant_close_period');
                            this.showModal(messages);
                        }
                        this.refreshGrid();
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    },
                    error: () => {
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    }
                });
        }
    }

    public async showModal(messages: string[]) {
        const options: ModalMessageOptions = {
            message: messages,
            showCancel: true,
            alertsMessage: this._translate.instant('cant_close_period'),
            width: 700,
            title: this._translate.instant('fail_close_period'),
            okDisabled: false
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
        } catch (error) {
        }
    }

    public async comptabilisation() {
        if (this.selectedPeriod) {
            await this._formHandler.showFormDialog(SalaryForm.FactureCompta, {salId: this.selectedPeriod.salId});
        }
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public publish() {
        this.openBulkManager(OperationType.PUBLISH_INVOICE);
    };

    private openBulkManager(operationType: OperationType) {
        const options: BulkManagerOptions = {
            type: operationType,
            salId: this.selectedPeriod.salId
        };

        this._modalService.showModal(BulkManagerComponent, options);
    }

    private refreshGrid() {
        if (!this.geperGrid) {
            return;
        }
        this.geperGrid.refresh();
    }

    private getGeperColumns(): IwGridColumn<Geper>[] {
        return [
            {
                prop: 'texte',
                name: this._translate.instant('period_column'),
                width: 100,
                type: 'activePer'
            },
            {
                prop: 'status',
                name: 'Statut',
                width: 20,
                type: 'enum',
                enumPrefix: 'geper.status'
            }];
    }
}
