import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormKeys} from '@app/sam-base/models';
import {Ppemp} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppemp-references', templateUrl: './tab-ppemp-references.component.html',
    standalone: false
})
export class TabPpempReferencesComponent extends BasePartialFormComponent<Ppemp> {

    @Input() public entityId?: string;

    constructor() {
        super();
    }

    protected getFormControlNames(): FormKeys<Ppemp> {
        return ['ppempReferences', 'inquiry', 'poursuites', 'casier', 'extrcasier'];
    }
}
