import 'moment/locale/en-gb';
import 'moment/locale/fr-ch';

import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {AgencyHandlerService} from '@app/sam-base/core/auth/services/agency-handler.service';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {SwaggerLoaderService} from '@app/sam-base/core/validations/swagger-loader.service';
import {LayoutEvents} from '@app/sam-base/layout/events/events.models';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '@shared/profile/profile.service';
import {UserProfile} from '@shared/profile/user-profile.model';

import {PrimeNGConfig} from 'primeng/api';
import {lastValueFrom} from 'rxjs';
import {filter, take} from 'rxjs/operators';

@Component({
    selector: 'app-root', templateUrl: 'app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {

    public readonly globalSlidePanelEvent = LayoutEvents.TOGGLE_GLOBAL_SLIDE;
    public languageLoaded = false;

    constructor(private readonly _translate: TranslateService, private readonly _router: Router, private readonly _profile: ProfileService, private readonly _agencyHandler: AgencyHandlerService, private readonly _swagger: SwaggerLoaderService, private config: PrimeNGConfig) {
        this.subcribeRouterChanges();
    }

    public get canLoadApp() {
        return this.languageLoaded;
    }

    public ngOnInit() {
        lastValueFrom(this._swagger.loadApi());
        this._translate.onDefaultLangChange.pipe(take(1))
            .subscribe((val) => {
                this._agencyHandler.loadDefaultAgency()
                    .subscribe({
                        next: () => this.languageLoaded = true, error: () => this.languageLoaded = true
                    });
            });
        this._profile.loadProfile()
            .subscribe((userProfile: UserProfile) => {
                const locale = ProfileService.langParser(userProfile?.lang);
                this._translate.setDefaultLang(locale);
                this._translate.currentLang = locale;
                const lang = ProfileService.langDateParser(userProfile?.lang);
                IwDateHelper.locale = lang;
                this._translate.use(locale);
                this._translate.get('primeng')
                    .subscribe(res => this.config.setTranslation(res));
            });
    }

    private subcribeRouterChanges() {
        const url = window.location.pathname;
        this._router.events
            .pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(() => window.history.pushState('', '', url));
    }
}
