import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MapsService} from '@app/sam-base/core';
import {IwEventRegisterService} from '@app/sam-base/core/events';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {GlcentreService} from '@app/sam-base/core/services/glcentre.service';
import {SaemcService} from '@app/sam-base/core/services/saemc.service';
import {SaemrService} from '@app/sam-base/core/services/saemr.service';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {SaenfService} from '@app/sam-base/core/services/saenf.service';
import {SaesService} from '@app/sam-base/core/services/saes.service';
import {SarepService} from '@app/sam-base/core/services/sarep.service';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import {GedtaService} from '@modules/sam-main/salary/services/gedta.service';
import {SalaryLocalProfileService} from '@modules/sam-main/salary/services/salary-local-profile.service';
import {SalaryPeriodeService} from '@modules/sam-main/salary/services/salary-periode.service';
import {ENTITIES} from '@sam-base/models/salary/type-group';
import {ComponentStore} from '@shared/services/form-dialog-host.service';
import {SharedModule} from '@shared/shared.module';
import {FileUploadModule} from 'primeng/fileupload';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TableModule} from 'primeng/table';
import {SALARY_FORMS} from './salary-forms-collection';
import {SALARY_COMPONENTS, SALARY_COMPONENTS_LIST} from './salary-forms.list';
import {SalaryForm} from './salary.forms';
import {SagsService} from './services/sags.service';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        FileUploadModule,
        TableModule,
        ScrollPanelModule
    ],
    declarations: [...SALARY_COMPONENTS_LIST],
    providers: [
        MapsService,
        SagsService,
        SaemcService,
        SaenfService,
        SaesService,
        SatblService,
        SaemsService,
        SarepService,
        GlcentreService,
        GedtaService,
        SaemrService,
        SalaryPeriodeService]
})
export class SalaryModule {
    constructor(_formHandlerService: FormHandlerService<SalaryForm>, _restApiService: RestApiService,
                _eventsHandlers: IwEventRegisterService, _localProfile: SalaryLocalProfileService) {
        _formHandlerService.registerComponents(<ComponentStore>(<unknown>SALARY_COMPONENTS));
        _formHandlerService.registerForms(SALARY_FORMS);
        _restApiService.registerEntityTypes(...ENTITIES);

        _localProfile.setLocalProfiles();
    }
}
