import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {Ppcdf, Ppcli, Ppcliadr, Ppcliatt, Ppcon, Ppplf, TypeEmploi} from '@app/sam-base/models/placement';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {
    ModalSelectTableOption
} from '../../../../../../shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {PpplfService} from '../../../services/ppplf.service';

@Component({
    selector: 'iw-tab-ppcdefixe-general', templateUrl: './tab-ppcdefixe-general.component.html',
    standalone: false
})
export class TabPpcdefixeGeneralComponent extends BasePartialFormComponent<Ppcdf> implements OnInit {

    public get cliId() {
        return this.getFormValue<string>('refclient') || '';
    }

    public readonly listOption: ModalSelectTableOption<Ppcliadr, string>;

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    public typeEmploi = TypeEmploi;
    public entityClient = Ppcli;
    public fullAddress = '';

    private readonly _entityService: RestEntityClient<Ppcliadr>;

    constructor(private _restService: RestApiService, private readonly _ppplfService: PpplfService) {
        super();
        this._entityService = _restService.getEntityClient(Ppcliadr);
        this.listOption = {
            columns: [{prop: 'adrlib'}],
            map: (e: Ppcliadr) => e.cliadrId || '',
            selection: 'single',
            fetchAction: () => this._restService
                .getEntityClient(Ppcliadr)
                .getRefData()
        };
    }

    /** Filter to use when showing list of ppcliatt */
    public resultFilters: (e: Ppcliadr) => boolean = (e) => e.cliId === this.cliId;


    public ngOnInit() {
        this.subscribeValueChange('conId', () => {
            const conId = this.getFormValue<string>('conId');
            if (!conId) {
                return;
            }
            this._restService
                .getEntityClient(Ppcon)
                .getById(conId)
                .subscribe((ppcon: Ppcon) => this.setFormValue('ageId', ppcon.ageId));
        });
        this.subscribeValueChange('cdfId', () => {
            this._ppplfService.getPlacFixeByCdfId(this.getFormValue('cdfId') ?? '')
                .subscribe((ppplf: Ppplf[]) => {
                    if (ppplf.length) {
                        this.setFormValue('cddtPlace', ppplf[0].ctaCliId);
                    }
                });
        });
    }

    public setClientAddress() {
        const cliadrId = this.getFormValue<string>('cliadrId');
        if (!cliadrId) {
            return '';
        }

        this._entityService.getById(cliadrId)
            .pipe(catchError(() => of(undefined)))
            .subscribe((res?: Ppcliadr) => {
                if (!res) {
                    this.fullAddress = '';
                    return;
                }
                this.fullAddress = this.buildAddress(res);
            });
    }

    public setAtt(cliatt: Ppcliatt) {
        this.setFormValue('cliattId', cliatt.cliattId);
        if (cliatt.tel) {
            this.setFormValue('tel', cliatt.tel);
        }
        if (cliatt.fax) {
            this.setFormValue('fax', cliatt.fax);
        }
        if (cliatt.natel) {
            this.setFormValue('natel', cliatt.natel);
        }
        if (cliatt.email) {
            this.setFormValue('email', cliatt.email);
        }
    }

    // eslint-disable-next-line complexity
    private buildAddress(p: Ppcliadr) {
        let fullAdresse = '';

        if (p.prefixe) {
            fullAdresse += `${p.prefixe}\n`;
        }
        if (p.nom) {
            fullAdresse += `${p.nom}\n`;
        }
        if (p.adresse1) {
            fullAdresse += `${p.adresse1}\n`;
        }
        if (p.adresse2) {
            fullAdresse += `${p.adresse2}\n`;
        }
        if (p.adresse3) {
            fullAdresse += `${p.adresse3}\n`;
        }
        if (p.npa || p.lieu) {
            fullAdresse += `${p.npa} ${p.lieu}\n`;
        }
        return fullAdresse;
    }
}
