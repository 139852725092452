<div class="row">
    <div class="col-xs-10"
         style="height: 200px">
        <iw-rest-grid #ppchaGrid
                      (selected)="getSelectedRow($event)"
                      [hasGridColumnMenu]="false"
                      [queryStatements]="query"
                      [type]="type"></iw-rest-grid>
    </div>
    <div class="col-xs-2 flex flex-v flex-evenly">
        <iw-button (press)="createNewEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_new' | translate"></iw-button>
        <iw-button (press)="modifyEntry()"
                   *ngIf="isReadonly"
                   [text]="'sidemenu_edit' | translate"
                   [tooltip]="'modifier' | translate"></iw-button>
        <iw-button (press)="saveEntry()"
                   *ngIf="!isReadonly"
                   [isDisabled]="formGroup.invalid"
                   [text]="'sidemenu_save' | translate"
                   [tooltip]="'modifier' | translate"></iw-button>
        <iw-button (press)="deleteEntry()"
                   *ngIf="isReadonly"
                   [text]="'sidemenu_delete' | translate"
                   [tooltip]="'supprimerAnuller' | translate"></iw-button>
        <iw-button (press)="cancelEditionMode()"
                   *ngIf="!isReadonly"
                   [text]="'sidemenu_cancel' | translate"
                   [tooltip]="'supprimerAnuller' | translate"></iw-button>
    </div>
</div>

<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtChantier
                  [isDisabled]="isReadonly"
                  [label]="'chantier' | translate"
                  class="iw-input col-xs-8 margin-bottom-5"
                  formControlName="chantier"></iw-textfield>

    <iw-textfield #txtAdresse1
                  [isDisabled]="isReadonly"
                  [label]="'adresse' | translate"
                  class="iw-input col-xs-8 margin-bottom-5"
                  formControlName="adresse1"></iw-textfield>

    <div class="col-xs-4"></div>

    <iw-textfield #txtNpa
                  (valueChange)="getLieuByNpa()"
                  [isDisabled]="isReadonly"
                  class="iw-input col-xs-2"
                  formControlName="npa"></iw-textfield>

    <iw-textfield #txtLieu
                  [isDisabled]="isReadonly"
                  class="iw-input col-xs-6"
                  formControlName="lieu"></iw-textfield>

    <div class="col-xs-4"></div>

    <iw-textfield #txtGeoRad
                  *ngIf="ijOn$ | async"
                  [isDisabled]="!canEditGeoRad()"
                  [label]="'rayon' | translate"
                  class="iw-input col-xs-4"
                  formControlName="geoRad"></iw-textfield>

</form>

<div *ngIf="fromRapportMode"
     class="row margin-bottom-10">
    <iw-button (press)="applyAndCloseDialog()"
               [isDisabled]="!isReadonly || !isEntrySelected()"
               [text]="'from_rapport_btn' | translate"
               class="iw-button col-xs-2 col-xs-offset-10 flex-end"></iw-button>
</div>

<div class="row">
    <iw-button #Closebutton1
               (press)="closeDialog()"
               [text]="'fermer' | translate"
               class="iw-button col-xs-2 col-xs-offset-10 flex-end"></iw-button>
</div>
