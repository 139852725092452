<form [formGroup]="formGroup"
      class="row space-form">
    <iw-textfield #txtDecId
                  [isDisabled]="true"
                  [value]="id"
                  class="iw-input col-xs-4 no-padding"
                  id="id_employee"></iw-textfield>

    <iw-textfield #txt
                  [isDisabled]="true"
                  [value]="titleInfo"
                  class="iw-input col-xs-5 no-padding label-name"></iw-textfield>

    <iw-textfield #txtROProfession
                  [isDisabled]="true"
                  [value]="'enum.sxStatus_v4.' + status | translate"
                  class="iw-input col-xs-3 no-padding"></iw-textfield>
    <div class="row">
        <iw-busy [isBusy]="isLoading"></iw-busy>
        <div class="row col-xs-12 no-padding-right"
             style="padding-left: 10%"></div>
    </div>
</form>

<iw-tab [(selectedTab)]="selectedTab"
        [invalidTabs]="invalidTabList"
        [tabs]="tabList">
    <iw-tab-sasx4dec-general *ngIf="selectedTab?.title === 'general'"
                             [formGroup]="formGroup"
                             [isNew]="isCreateMode"
                             [isReadonly]="!isWriteMode"></iw-tab-sasx4dec-general>
    <iw-tab-sasx4dec-viewgen *ngIf="selectedTab?.title === 'viewgen'"
                             [formGroup]="formGroup"
                             [uuid]="uuid"></iw-tab-sasx4dec-viewgen>
</iw-tab>
