import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {FormHandlerService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, RowClickEvent, sortGridList, TableSortEvent} from '@app/sam-base/models';
import {IwGridColumn} from '@app/sam-base/models/components/grid-column.model';

import {AdminForm} from '@modules/sam-main/admin/admin.forms';
import {Sasx4dec} from '@modules/sam-main/admin/components/swissdec4/swissdec4.model';
import {SwissdecService} from '@modules/sam-main/admin/services/swissdec-service';
import {TranslateService} from '@ngx-translate/core';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Subject} from 'rxjs';

@Component({
    templateUrl: './swissdec4-table.component.html',
    standalone: false
})
export class Swissdec4TableComponent extends BaseStoreFormComponent<Sasx4dec> implements OnInit, OnDestroy {
    public libelle?: string;


    public columns = this.getColumns();
    public isLoading = false;

    public filteredTableData: Sasx4dec[] = [];
    public originalTableData: Sasx4dec[] = [];
    protected readonly SamUserRole = SamUserRole;
    private subscriptions = new Subject();
    private filterService = new FilterService<Sasx4dec>();
    private tableSortEvent?: TableSortEvent<Sasx4dec>;

    constructor(store: IwStoreService, _restService: RestApiService,
                private _translate: TranslateService,
                private _toastService: ToastService,
                private readonly _formHandler: FormHandlerService<AdminForm>,
                private readonly _swissdecService: SwissdecService,
                private readonly messageService: MessageFormRefreshService,
                private readonly swissdecService: SwissdecService) {
        super(store);
    }

    public ngOnInit() {
        this.getSwissdecDeclarations();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public onRowDoubleClick(event: RowClickEvent<Sasx4dec>) {
        this._formHandler.showFormDialog(AdminForm.Swissdec4Declaration, event, s => ({
            ...s,
            editMode: 'read',
            entityId: event.row.sxId || '',
            diagTitle: 'title_declaration_form',
            width: 1600,
            height: 1200,
        }));
    }

    public getSwissdecDeclarations() {
        this.isLoading = true;
        this.swissdecService.getSwissdec4decAvailable()
            .subscribe(
                {
                    next: (data: Sasx4dec[]) => {
                        this.originalTableData = data;
                        this.filteredTableData = data;
                        this._onApplyFilter();
                        this._onSort();
                        this.isLoading = false;
                    },
                    error: (error: any) => {
                        this.isLoading = false;
                    }
                });
    }

    public onApplyFilter($event: IwGridColumn<Sasx4dec>) {
        this.filterService.addFilter($event);
        this._onApplyFilter();
    }

    public onSort($event?: TableSortEvent<Sasx4dec>) {
        this.tableSortEvent = $event;
        this._onSort();
    }

    protected getFormControlNames(): FormKeys<Sasx4dec> {
        return [
            'sxId'
        ];
    }

    private _onApplyFilter() {
        this.filteredTableData = this.filterService.applyFilters(this.originalTableData);
    }

    private _onSort() {
        if (!this.tableSortEvent) return;
        this.filteredTableData = [...sortGridList(this.filteredTableData, this.tableSortEvent)];
    }

    private getColumns(): IwGridColumn<Sasx4dec>[] {
        return [
            {
                prop: 'sxId',
                name: 'id',
                index: 0,
                type: 'string',
                width: 25
            },
            {
                prop: 'salId',
                name: 'salId',
                index: 0,
                type: 'salId',
                width: 25
            },
            {
                prop: 'whatsinxml',
                name: 'institutions',
                type: 'string',
                index: 1
            },
            {
                prop: 'sxType',
                type: 'string',
                name: 'sxType',
                index: 2
            },
            {
                prop: 'sxJobkey',
                name: this._translate.instant('sxJobKey'),
                type: 'keyword',
                index: 6
            },
            {
                prop: 'dateModif',
                name: 'dateModif',
                type: 'dateTime',
                index: 9
            },
        ];
    }
}
