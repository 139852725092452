<iw-dialog #dialog
           (beforeHide)="onBeforeHide()"
           [bodyPadding]="'10'"
           [closable]="true"
           [cls]="'iw-modal'"
           [draggable]="true"
           [height]="'40vh'"
           [maximized]="true"
           [modal]="true"
           [resizable]="true"
           [showHeader]="true"
           [title]="'layout_menu_print_request' | translate"
           [visible]="true"
           [width]="'80vw'">
    <iw-busy [ajustLeft]="5"
             [ajustTop]="5"
             [isBusy]="loading"></iw-busy>

    <div class="row center-xs">
        <p-message *ngIf="origin"
                   [text]="origin"
                   class="col-xs-12"
                   severity="warn"></p-message>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <div class="row">
                <iw-button (press)="downloadDocument()"
                           [isDisabled]="selectedPrintRequest?.length === 0"
                           [text]="'download' | translate"
                           [tooltip]="'download' | translate"
                           class="col-xs-offset-10 col-xs-2"
                           iconClass="fas fa-file"></iw-button>
            </div>
            <div class="row">
                <iw-rest-grid
                    #printRequestGrid
                    (rowDoubleClick)="onRowDoubleClick($event)"
                    (selected)="onSelection($event)"
                    [customColumns]="columnsToShow"
                    [defaultSelected]="true"
                    [hasFilter]="true"
                    [hasGridColumnMenu]="false"
                    [hasSortIcon]="true"
                    [queryStatements]="query"
                    [selectMode]="'checkbox'"
                    [type]="typePrintRequest"
                    class="col-xs-12"
                    style="height: 30vh"></iw-rest-grid>
            </div>
        </div>
    </div>


</iw-dialog>
