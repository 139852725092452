import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SagenService} from '@app/sam-base/core/services/sagen.service';
import {Sagen} from '@app/sam-base/models/salary/sagen';

@Component({
    selector: 'iw-tab-sagen-swissdec', templateUrl: './tab-sagen-swissdec.component.html',
    standalone: false
})
export class TabSagenSwissdecComponent extends BasePartialFormComponent<Sagen> implements OnInit {
    public edtSaemi?: string;

    constructor(private readonly sagenService: SagenService) {
        super();
    }

    public get salId() {
        return this.getFormValue<string>('salId');
    }

    public ngOnInit() {
        if (this.salId) {
            this.getTextSaemi(this.salId);
        }
    }

    public getTextSaemi(salId: string | undefined) {
        if (salId) {
            this.sagenService.getInformations(salId)
                .subscribe((data: string) => {
                    this.edtSaemi = data;
                });
        }
    }

    public openEmaAVS() {
    }

    public openEmaIS() {
    }

    public openAuto() {
    }
}
