<div class="row">
    <div [formGroup]="formGroup"
         class="col-xs-8">
        <div class="row">
            <div class="col-xs-12 padding-bottom-10">
                <label>Options</label>
            </div>
            <div class="row">
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptagrpemp' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptagrpemp"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptagrpgs' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptagrpgs"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptaan' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptaan"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptaanobli' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptaanobli"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptatxfcoe' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptatxfcoe"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptatxfpay' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptatxfpay"></iw-checkbox>
                <iw-checkbox [isDisabled]="true"
                             [label]="'sacompta.cptatxfchq' | translate "
                             class="iw-input col-xs-12"
                             formControlName="cptatxfchq"></iw-checkbox>
            </div>
        </div>
    </div>
    <div class="col-xs-4 col">
        <div class="row margin-bottom-10">
            <iw-button (press)="comptabiliser()"
                       [text]="'comptabiliser' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row margin-bottom-10">
            <iw-button (press)="decomptabiliser()"
                       [text]="'decomptabiliser' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
        <div class="row">
            <iw-button (press)="closeDialog()"
                       [text]="'fermer' | translate"
                       class="iw-button btn-block"></iw-button>
        </div>
    </div>
</div>
<div *ngIf="errors?.length > 0"
     class="row">
    <iw-table (rowDoubleClick)="openErrorEntity($event)"
              [columns]="columnsError"
              [data]="errors"
              [defaultSelected]="false"
              [isDisabled]="false"
              [isFilterEnable]="false"
              [isGridColumnMenuEnable]="false"
              [isSortIconEnable]="false"
              [virtualScroll]="true"
              class="iw-grid col-xs-12 no-padding"
              style="height: 250px"></iw-table>

</div>
