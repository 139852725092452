import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GestionComponent} from '@app/sam-base/components/gestion/gestion.component';
import {BadgeColors} from '@app/sam-base/components/iw-badge/iw-badge.enums';
import {HelpMenuItem} from '@app/sam-base/components/iw-help-menu/help-menu.model';
import {IjTimesheetsComponent} from '@app/sam-base/components/modal/ij-timesheets/ij-timesheets.component';
import {AgencyHandlerService, IwEventHubService, ModalService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {HeaderToolbarService} from '@app/sam-base/core/services/header-toolbar.service';
import {IjTimesheetsService} from '@app/sam-base/core/services/ij-timesheets.service';
import {ViewContainerRefService} from '@app/sam-base/core/services/view-container-ref.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {Ppper} from '@app/sam-base/models/placement';
import {DocumentManagementService} from '@core/document-management/document-management.service';
import {TranslateService} from '@ngx-translate/core';
import * as SideMenuRoles from '@sam-base/core/auth/access-rules/side-menu';
import {PLACEMENT_MODULES} from '@sam-base/core/auth/enums/gestion-modules';
import {ConfigService} from '@sam-base/core/services/config.service';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {ComboboxItem} from '@sam-base/models';
import {Sapar} from '@sam-base/models/salary';
import {Sagen} from '@sam-base/models/salary/sagen';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {support} from './help-menu-items';

@Component({
    selector: 'iw-header-toolbar',
    templateUrl: './header-toolbar.component.html',
    standalone: false
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {

    @Input() public showChangeGestion = false;
    @Input() public showPeriodPlacement = false;
    @Input() public showPeriodSalaire = false;
    @Input() public showPeriodFacture = false;
    @Input() public showIJ = false;
    @Input() public showHelp = false;
    @Input() public showLanguage = false;
    @Input() public showLogout = false;

    @Input() public version?: string;
    public activePer?: Ppper;
    public interijobActive$ = new Observable<boolean>();
    public helpMenuItems: HelpMenuItem[] = [];
    /** Subject that keeps the current number of ij reports to import */
    public ijTimesheetsNr: BehaviorSubject<number> = new BehaviorSubject(0);
    public gestion = '';
    public languageOptions: ComboboxItem<string>[] = [
        {
            value: 'fr',
            name: 'fr'
        },
        {
            value: 'en',
            name: 'en'
        },
        {
            value: 'de',
            name: 'de'
        },
        {
            value: 'it',
            name: 'it'
        },
        {
            value: 'XX',
            name: 'XX'
        }];
    public selectedLang: string = 'fr';
    private documentation = {
        label: 'documentation',
        items: [
            {
                label: 'documentationPlacement',
                command: () => {
                    this._documentService.getPreSignedUrlInCommonByPath('documentation/ultimate-placement.pdf').subscribe(url => {
                        window.open(url, '_blank');
                    });
                },
                accessRoles: SideMenuRoles.placementMenuRoles()
            },
            {
                label: 'documentationFactures',
                command: () => {
                    this._documentService.getPreSignedUrlInCommonByPath('documentation/ultimate-factures.pdf').subscribe(url => {
                        window.open(url, '_blank');
                    });
                },
                accessRoles: SideMenuRoles.factureMenuRoles()
            },
            {
                label: 'documentationSalaires',
                command: () => {
                    this._documentService.getPreSignedUrlInCommonByPath('documentation/ultimate-salaires.pdf').subscribe(url => {
                        window.open(url, '_blank');
                    });
                },
                accessRoles: SideMenuRoles.salariesRoles()
            },
            {
                label: 'gestion_droits_utilisateurs',
                command: () => {
                    this._documentService.getPreSignedUrlInCommonByPath('documentation/ultimate-droitsutilisateurs.pdf').subscribe(url => {
                        window.open(url, '_blank');
                    });
                },
                accessRoles: SideMenuRoles.userAdmRoles()
            }
        ]
    }
    private _subs = new Subject();
    private _activeSapar?: Sapar;
    private _sagen?: Sagen;
    private _sagenOfPpPeriode?: Sagen;
    private currentSagenSalId?: string;
    private customDialogService: any;

    constructor(private readonly _age: AgencyHandlerService, private readonly keycloakService: KeycloakService,
                private readonly configHeaders: ConfigService, private readonly _viewRef: ViewContainerRefService,
                private headerService: HeaderToolbarService, private ijTimesheetsService: IjTimesheetsService,
                private readonly _events: IwEventHubService<string>, private readonly _modalService: ModalService,
                private readonly _sagenService: SagenService,
                private readonly _translationService: TranslateService,
                private readonly _documentService: DocumentManagementService,
                private _store: IwStoreService) {
        this.setActivePeriod();
        this._events.forType('active_period')
            .pipe(takeUntil(this._subs))
            .subscribe((e) => {
                this.setActivePeriod();
            });
        this.ijTimesheetsNr = this.ijTimesheetsService.ijTimesheets2Import;
    }

    private _isGeperActive?: boolean;

    public get isGeperActive(): boolean {
        return this._isGeperActive || false;
    }

    public get sagenOpen(): boolean {
        return this._sagenOfPpPeriode?.status === 1;
    }

    public get selectedSagenOpen(): boolean {
        return this._sagen?.status === 1;
    }

    public get getCurrentDate() {
        if (!this.activePer?.date) {
            return 'P:1970-01';
        }
        return `P:${IwDateHelper.dateFormatFromString(this.activePer?.date?.toString()
            .replace(/,/g, '-'), 'YYYY-M')}`;
    }

    /** Changes the ij badge color based on nr of ij reports to import */
    public get ijBadgeColor() {
        return this.ijTimesheetsNr.getValue() > 0 ? BadgeColors.red : BadgeColors.blue;
    }

    public get getCurrentYearSalaire() {
        if (!this._sagen?.salId) {
            return '';
        }
        // return `S:${this._sagen.salId.trim()}`;
        return `S:${IwDateHelper.dateFormatFromString(this._sagen?.dateDe?.toString()
            .replace(/,/g, '-'), 'YYYY-M')}`;
    }

    // todo ask @jreuse if it's really sapar to check for facture
    public get getCurrentYearFacture() {
        if (!this._activeSapar?.annee) {
            return '';
        }
        return `F:${this._activeSapar.annee.trim()}`;
    }

    public ngOnInit() {
        this._age.loadAgencyId()
            .subscribe(s => this.gestion = s);
        this.getActiveYear();
        this._events.forType('active_exercise')
            .pipe(takeUntil(this._subs))
            .subscribe(() => {
                this.getActiveYear(true);
            });
        this._events.forType('active_salId_sagen')
            .pipe(takeUntil(this._subs))
            .subscribe(() => {
                this.getSelectedSagen();
            });
        this.getSelectedSagen();
        this.interijobActive$ = this._store.getLicenseOption('interijobwebaccess');
        this.addMenuItems();
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public logout() {
        this.keycloakService.logout();
    }


    public selectGestion() {
        this._viewRef.loadComponent(GestionComponent);
    }

    public openIjReports() {
        this._modalService.showModal(IjTimesheetsComponent);
    }

    public changeLanguage(event: any) {
        this.selectedLang = event?.value.value;
        this._translationService.use(this.selectedLang);
    }

    public openAnydeskDialog() {
        this.customDialogService.openAnydeskDialog('ID Anydesk').then((result: any) => {
            console.log('Anydesk ID:', result);
        }).catch((error: any) => {
            console.error(error);
        });
    }

    private setActivePeriod() {
        this._age.checkAgencyModules(PLACEMENT_MODULES).then(res => {
            if (res) {
                this.headerService.getActivePeriod()
                    .subscribe((p: Ppper) => {
                        this.activePer = p;
                        this.updateSalaireAndFacturePeriode(this.activePer?.salId);
                    });
            }
        })
    }

    private addMenuItems() {
        this.configHeaders.getConfig()
            .subscribe(headers => {
                this.helpMenuItems.push(this.documentation);
                this.helpMenuItems.push(support);
            });
    }

    private getActiveYear(updateSagen: boolean = false) {
        this.headerService.getActiveExercise()
            .subscribe(sapar => {
                this._activeSapar = sapar;
                if (updateSagen) {
                    let sagenYear = this._sagen?.salId?.substring(0, 4);
                    if (this._activeSapar.annee !== sagenYear) {
                        this._sagenService.setCurrentSalId(this._activeSapar.annee + ' 1');
                    }
                }
            });
    }

    private refreshSagenBySalId(salId: string | undefined) {
        if (!salId) return;
        this.headerService.getSagen(salId).subscribe(sagen => {
            this._sagenOfPpPeriode = sagen;
        })
        this.headerService.isGeperSalIdActive(salId).subscribe(isActive => {
            this._isGeperActive = isActive;
        })
    }

    private updateSalaireAndFacturePeriode(salId: string | undefined) {
        if (!salId) return;
        return this.refreshSagenBySalId(salId);
    }

    private getSelectedSagen() {
        this._sagenService.getSelectedSagen().subscribe(sagen => this._sagen = sagen);
    }
}
