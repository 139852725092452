import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {IwGridColumn, ModalComponent, ObjectKeyArrayMap} from '@app/sam-base/models';
import {SalaryProcessService} from '@modules/sam-main/placement/services/salary-process.service';
import {TranslateService} from '@ngx-translate/core';
import {IwTableComponent} from '@sam-base/components';
import {Ppemp, SalaryCountRequest, SalaryCountResponse} from '@sam-base/models/placement';
import {Autoaco} from '@sam-base/models/placement/autoaco';
import {EntityTextfieldComponent} from '@shared/widgets/form-components/entity-textfield/entity-textfield.component';
import {ModalSalaryCountOptions} from './modal-salary-count.options';


@Component({
    templateUrl: './modal-salary-count.component.html',
    standalone: false
})
export class ModalSalaryCountComponent implements ModalComponent<string[], ModalSalaryCountOptions>, OnInit {
    @ViewChild('grdSalary', {static: true}) public gridSalary?: IwTableComponent<Autoaco>;
    @ViewChild('cboEmp', {static: true}) public cboEmp?: EntityTextfieldComponent<Autoaco>;

    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public counts: SalaryCountResponse[] = [];
    public columns = this.getColumns();
    public display = true;
    public loading = false;
    public countSelected?: SalaryCountResponse[];
    public _options!: ModalSalaryCountOptions;
    public entityPpemp = Ppemp;
    public empIdInput?: string;
    public filterPpemp?: ObjectKeyArrayMap<Ppemp>;

    private _ok?: (result: string[]) => void;
    private _cancel?: (result?: undefined) => void;

    constructor(private _translate: TranslateService, private readonly _salaryProcessService: SalaryProcessService) {
    }

    public get title() {
        return this._options.title;
    }

    public get width() {
        return this._options.width;
    }

    public get height() {
        if (this._options.counts && this._options.counts.length > 4) {
            return 150 + (this._options.counts.length * 40);
        }
        return this._options.height;
    }

    public get okColor() {
        return this._options.okColor === 'white' ? 'ok' : '';
    }

    // eslint-disable-next-line complexity
    public get atLeastOneSelected(): boolean {
        return this.countSelected?.length! > 0 || this.empIdInput !== undefined;
    }

    public ngOnInit() {
        const salaryCountRequest: SalaryCountRequest = {
            // invoiceProcessId: this._options.invoiceProcessId,
            force: this._options.force!,
            salaryProcessType: this._options.salaryProcessType,
            salId: this._options.salId!,
        }
        this.loading = true;
        this._salaryProcessService.getSalaryCount(salaryCountRequest).subscribe({
            next:
                (data) => {
                    this.counts = data;
                    const empIds = this.counts.flatMap(c => c.empIds);
                    this.filterPpemp = {
                        empId: empIds
                    };

                },
            error: (error) => {
                this.loading = false;
                console.error(error);
            },
            complete: () => {
                this.loading = false;
            }
        });

    }

    public onPressCancel() {
        if (this._ok) {
            this._ok([]);
        }
        this.pressOk.emit();
        this.display = false;
    }

    public onPressOk() {
        if (this._ok) {
            let empIds = this.countSelected
                ?.map(empCountResponse => empCountResponse.empIds || [])
                .flatMap(ids => ids) || [];
            if (!empIds.length) {
                empIds = [this.empIdInput || ''];
            }
            this._ok(empIds);
        }
        this.pressOk.emit();
        this.display = false;
    }

    public selectCountOption(event: SalaryCountResponse[]) {
        this.countSelected = event
        if (event?.length > 0) {
            this.cboEmp?.clearValue();
        }
    }

    public async setData(data: ModalSalaryCountOptions): Promise<void> {
        this._options = data;
    }

    public registerOk(action: (result: string[]) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (result?: string[]) => void): void {
        this._cancel = action;
    }

    onSelectClient($event: string | undefined) {
        this.empIdInput = $event;
        this.gridSalary?.clearSelection();
    }

    private getColumns(): IwGridColumn<SalaryCountResponse>[] {
        return [
            {
                prop: 'ageId',
                name: 'ageId',
                index: 1
            },
            {
                prop: 'secId',
                name: 'secId',
                index: 2
            },
            {
                prop: 'salaryCount',
                name: 'nbOfEmployees',
                index: 3
            }];
    }
}
