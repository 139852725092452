import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {ERROR} from '@app/sam-base/core/logger/logger';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {MissionCalculationsService} from '@app/sam-base/core/services/mission-calculations.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {FieldStatus, ModalComponent} from '@app/sam-base/models';
import {Npa} from '@app/sam-base/models/common';
import {Ppcliadr, Ppcliatt, Ppcon, Ppmis, Ppqua} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';
import {ToastService} from '@sam-base/core/toast';
import {
    IwModalSelectTableComponent
} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.component';
import {ModalSelectTableOption} from '@shared/widgets/modal-components/modal-select-table/modal-select-table.model';
import {lastValueFrom, of, Subject, Subscription} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../../placement.forms';
import {MatchingService} from '../../../services/matching.service';

@Component({
    selector: 'iw-tab-ppmis-general',
    templateUrl: './tab-ppmis-general.component.html',
    styleUrls: ['./tab-ppmis-general.component.scss'],
    standalone: false
})
export class TabPpmisGeneralComponent extends BasePartialFormComponent<Ppmis> implements OnInit, OnDestroy {

    @Input() public employeeAge?: string;
    @Input() public suvaPeActive = false;
    public ppquaEntity = Ppqua;
    @Input() public sent2fac = false;
    @Input() public sent2sal = false;
    @Input() public hasRapport = false;
    @Input() public uuid?: string;
    @Input() public isReadonly = true;
    public txtcoeffValue?: string;
    public coeffStatus: FieldStatus = 'undefined';
    public coeffColor: string;
    public txtMergeHeur = '';
    public ppparQuastrict = false;
    public coeffcalcmode = 1;
    public misAllowHorType = false;
    public readonly listOption: ModalSelectTableOption<Ppcliadr, string>;
    public readonly listOptionQualif: ModalSelectTableOption<Ppqua, Ppqua>;
    public _statsSubs?: Subscription;
    private readonly _npaRestClient: RestEntityClient<Npa>;
    private subscriptions = new Subject();

    constructor(private readonly _restService: RestApiService, private readonly _modalService: ModalService,
                private readonly _calculation: MissionCalculationsService,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _matchingService: MatchingService,
                private readonly _parametersService: ParametersService, private readonly _toastService: ToastService) {
        super();
        this.coeffColor = '';
        this._npaRestClient = _restService.getEntityClient(Npa);

        this.listOption = {
            columns: [{prop: 'adrlib'}],
            map: (e: Ppcliadr) => e.cliadrId || '',
            selection: 'single',
            fetchAction: () => this._restService
                .getEntityClient(Ppcliadr)
                .getRefData()
        };
        this.listOptionQualif = {
            columns: [{prop: 'qualif'}],
            map: (e: Ppqua) => e,
            selection: 'single',
            fetchAction: this.profFetchAction
        };
    }

    public get hasSalFac(): boolean {
        return this.sent2fac || this.sent2sal;
    }

    public get entityId() {
        return this.getFormValue<string>('misId') || '';
    }

    public get cliId() {
        return this.getFormValue<string>('cliId') || '';
    }

    public get empId() {
        return this.getFormValue<string>('empId') || '';
    }

    public get showAlertMotif() {
        return this.getFormValue('datealert')
    }

    public get labelQualif() {
        return this.ppparQuastrict ? ' ' : 'qualification';
    }

    /**
     * Validate if horairetyp exist in mission.
     * If exists, cboHorairetyp is visible
     */
    public get showHorairetyp() {
        return !!this.getFormControl('horairetyp');
    }

    // eslint-disable-next-line complexity
    public get horInfoValue() {
        const horTyp = this.getFormValue('horairetyp');
        const hparj = 1;
        const hpars = 2;
        const hparm = 3;
        let v = '';

        switch (horTyp) {
            case (2):
            case (3):
                v = hparj.toFixed(2) + ' h./j.';
                break;
            case (4):
                v = hpars.toFixed(2) + ' h./s.';
                break;
            case (5):
                v = hparm.toFixed(2) + ' h./m.';
                break;
            default:
                break;
        }
        return v;
    }

    public profFetchAction = () => this._restService.getEntityClient(Ppqua)
        .getRefData()
        .pipe(map((qualifs) => qualifs.filter(ppqua => ppqua.active)
            .sort((a, b) => this.sortQualif(a, b))));

    public ngOnInit() {
        this.subscribeValueChange('misId', () => this.updatemis(), 300);
        this.subscribeValueChange('conId', () => {
            const conId = this.getFormValue<string>('conId');
            if (!conId) {
                return;
            }
            this._restService
                .getEntityClient(Ppcon)
                .getById(conId)
                .subscribe((ppcon: Ppcon) => this.setFormValue('ageId', ppcon.ageId));
        });
        this.subscribeValueChange('datealert', (datealert) => {
            const alertMotifControl = this.getFormControl('alertMotif');
            if (datealert) {
                alertMotifControl?.addValidators(Validators.required);
            } else {
                alertMotifControl?.removeValidators(Validators.required);
            }
            alertMotifControl?.updateValueAndValidity();
        }, 300);
        this.calcCoeff();
        this._matchingService.scheduleEventData
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                if (e.uuid === this.uuid) {
                    this.setFormValue('needSummary', e.data);
                }
            });
        if (this.formGroup) {
            this.formGroup.updateValueAndValidity();
        }

        const profession = this.getFormValue<string>('qualif');
        if (!profession) {
            return;
        }
        this.allowHorTypeRequest();
        if (this.getFormValue('dureetype') === '') {
            this.setFormValue('duree', '');
        }
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public updatemis() {
        this.calcCoeff();
    }

    public forwardDateBy1() {
        this.forwardDateByWeek(1);
    }

    public forwardDateBy4() {
        this.forwardDateByWeek(4);
    }

    public forwardDateBy12() {
        this.forwardDateByWeek(12);
    }

    public allowHorTypeRequest() {
        this._parametersService.getDataText('ppData')
            .subscribe((value: any) => {
                if (value.MISALLOWHORTYPE === 'true') {
                    this.misAllowHorType = true;
                } else {
                    this.misAllowHorType = false;
                }
            });
    }

    /**
     * Function to get address by client
     */
    public async copyAddressByClient() {
        const data = await lastValueFrom(this.getppAdrCli());
        const comp: Type<ModalComponent<string[], Ppcliadr>> = <any>IwModalSelectTableComponent;
        if (data.length > 1) {
            this.listOption.fetchAction = () => of(data);
            const res = await this._modalService
                .showModal<string[], Ppcliadr>(comp, <any>this.listOption);
            this._restService.getEntityClient(Ppcliadr)
                .getById(res[0])
                .subscribe((ppcliadr: Ppcliadr) => {
                    this.setFormValue('lieu1', ppcliadr.nom);
                    this.setFormValue('lieu2', ppcliadr.adresse1);
                    this.setFormValue('lieu3', ppcliadr.adresse2);
                    this.setFormValue('misLieu', ppcliadr.lieu);
                    this.setFormValue('misNpa', ppcliadr.npa);
                });
        } else if (data.length === 1) {
            this.setFormValue('lieu1', data[0].nom);
            this.setFormValue('lieu2', data[0].adresse1);
            this.setFormValue('lieu3', data[0].adresse2);
            this.setFormValue('misLieu', data[0].lieu);
            this.setFormValue('misNpa', data[0].npa);
        }
    }

    public openScheduleEventComp() {
        this._formHandler.showFormDialog(PlacementForm.ScheduleEvent, {
            height: '250px',
            parentId: this.entityId,
            parentEntity: 'ppmis',
            isAvailability: true,
            formUuid: this.uuid
        }, s => ({
            ...s,
            diagTitle: 'schedule_event_ppmis_form_title',
            editMode: 'waitLoading'
        }));
    }

    /**
     * Function to get lieu by npa
     */
    public async getLieuByNpa() {
        if (this.isReadonly) {
            return;
        }

        // eslint-disable-next-line complexity
        setTimeout(async () => {
            if (!this.formGroup) {
                return;
            }
            const mis: Ppmis = this.formGroup.value;

            try {
                const npa: string | undefined = mis.misNpa || '';
                if (this.canGenerateNpa(npa) && !this.isReadonly) {
                    const lieu: Npa = await lastValueFrom(this._npaRestClient.getById(npa));
                    this.setFormValue('misLieu', lieu.lieu);
                }
            } catch (error) {
                this._toastService.error('npa_error');
            }
        }, 500);

    }

    /**
     * Function to calc the coefficient
     */
    public calcCoeff() {
        if (!this.formGroup) {
            return;
        }
        this._statsSubs = this._calculation.calculateStats(this.formGroup.value)
            .pipe(catchError(err => {
                ERROR('Error during salary calculations', err);
                return of(false);
            }))
            .subscribe(e => {
                if (typeof e === 'boolean') {
                    return;
                }

                this.txtcoeffValue = e.txtCoeff;
                this.coeffStatus = e.validCoeff ? 'valid-content' : 'invalid-content';
                this.txtMergeHeur = e.margeHours;
            });
    }

    public async openQualifSelection() {
        if (!this.listOptionQualif) {
            return;
        }
        const comp: Type<ModalComponent<Ppqua[], Ppqua>> = <any>IwModalSelectTableComponent;
        const data = await this._modalService.showModal<Ppqua[], Ppqua>(comp, <any>this.listOptionQualif);
        const ppqua = data[0];
        this.setFormValue('quaId', ppqua.quaId);
        this.setFormValue('qualif', ppqua.qualif);
    }

    public setAtt(cliatt: Ppcliatt) {
        if (this.isReadonly) {
            return;
        }
        if (cliatt.natel) {
            this.setFormValue('tel', cliatt.natel);
        } else if (cliatt.tel) {
            this.setFormValue('tel', cliatt.tel);
        } else {
            this.setFormValue('tel', undefined);
        }
    }

    private canGenerateNpa(npa: string) {
        return npa.length === 4 && !this.getFormValue('lieu') && !this.isReadonly;
    }

    private forwardDateByWeek(numOfWeek: number) {
        let value: string | undefined = this.getFormValue('datealert');
        if (typeof value !== 'string') {
            value = (new Date()).toISOString()
                .substr(0, 10);
        }

        const m = IwDateHelper.getMoment(value);
        if (!m.isValid()) {
            return;
        }

        m.add(numOfWeek, 'weeks');

        this.setFormValue('datealert', m.format('YYYY-MM-DD'));
    }

    private getppAdrCli = () => this._restService
        .getEntityClient(Ppcliadr)
        .getRefData()
        .pipe(map(e => e.filter(v => v.cliId === this.cliId)));

    private sortQualif(a: Ppqua, b: Ppqua) {
        return (a.qualif || '') < (b.qualif || '') ? -1 : 1;
    }
}
