import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Ppemp} from '@app/sam-base/models/placement';
import {SatblService} from '@sam-base/core/services/satbl.service';

@Component({
    selector: 'iw-tab-ppemp-experience',
    templateUrl: './tab-ppemp-experience.component.html',
    standalone: false
})
export class TabPpempExperienceComponent extends BasePartialFormComponent<Ppemp> {

    public optionB: ComboboxItem<string>[] = [];
    public optionD: ComboboxItem<string>[] = [];
    public optionK: ComboboxItem<string>[] = [];

    constructor(private readonly _modalHandler: ModalService, private readonly _satblService: SatblService) {
        super();
        this._satblService.populateComboboxItemForOption('B', this.optionB);
        this._satblService.populateComboboxItemForOption('D', this.optionD);
        this._satblService.populateComboboxItemForOption('K', this.optionK);
    }

    public get entityId() {
        return this.getFormValue<string>('empId');
    }

    /**
     * Function to check if employee is in liste noire
     */
    public checkListNoire() {
        return !!this.getFormValue('listenoir');
    }

    protected getFormControlNames(): FormKeys<Ppemp> {
        return [
            'profFrom',
            'profOther',
            'lastjob',
            'parcours',
            'listenoir',
            'swissdecFormation',
            'swissdecPosition',
            'swissdecConfession'];
    }
}
