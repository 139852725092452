import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppcct} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ctt-remarques',
    templateUrl: 'tab-cct-remarques.component.html',
    standalone: false
})
export class TabCctRemarquesComponent extends BasePartialFormComponent<Ppcct> {

    constructor() {
        super();
    }

}
