import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@sam-base/base';
import {Gepar} from '@sam-base/models/invoices';

@Component({
    selector: 'iw-tab-gepar-format', templateUrl: './tab-gepar-format.component.html',
    standalone: false
})
export class TabGeparFormatComponent extends BasePartialFormComponent<Gepar> {

}
