import {Component, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast/toast.service';
import {FormKeys} from '@app/sam-base/models';
import {Sags} from '@app/sam-base/models/salary';
import {debounceTime} from 'rxjs/operators';

import {genreSalaireTabsFormControls} from './genre-salaire-tabs-formcontrols';

@Component({
    templateUrl: './genre-salaire.component.html',
    standalone: false
})
export class GenreSalaireComponent extends BaseTabFormComponent<Sags> implements OnInit {

    public invalidGsId = false;

    constructor(store: IwStoreService, private _restService: RestApiService, private _toast: ToastService) {
        super(store);
    }

    public get version(): number {
        return this.getData('version') || 0;
    }

    public ngOnInit() {
        this.subscribeValueChange('gsId', () => {
            if (!this.isNew) {
                this.invalidGsId = false;
            } else {
                this.invalidGsId = true;
            }
        });
    }

    public getTabsValues(): TabFormControl<Sags>[] {
        return genreSalaireTabsFormControls;
    }

    public checkGsExists(gsId?: string) {
        if (gsId && this.isNew) {
            this._restService.getEntityClient(Sags)
                .getById(gsId)
                .pipe(debounceTime(500))
                .subscribe({
                    next: () => this.setGsIdValidators(true), error: (error) => this.setGsIdValidators(false)
                });
        }
    }

    protected getValidationType() {
        return Sags;
    }

    protected getFormControlNames(): FormKeys<Sags> {
        return this.getTabsFormControls(genreSalaireTabsFormControls);
    }

    private setGsIdValidators(invalid: boolean) {
        if (invalid) {
            this.formGroup.controls['gsId']
                .setValidators([Validators.required, Validators.minLength(10000)]);
            this._toast.error('gs_id_invalid');
        } else {
            this.formGroup.controls['gsId']
                .setValidators([Validators.required, Validators.maxLength(10)]);
        }
        this.formGroup.controls['gsId'].updateValueAndValidity();
    }
}
