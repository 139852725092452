import {Component, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';

import {formatSalId} from '@app/sam-base/core/helpers/parameters.helper';
import {SaesService} from '@app/sam-base/core/services/saes.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Saemi} from '@app/sam-base/models/salary/saemi';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {AemaCodesAVS, AemaCodesCAF, CorType, EmiGenre} from './saemi-avs-enums';

@Component({
    templateUrl: './saemi-avs.component.html',
    standalone: false
})
export class SaemiAVSComponent extends BaseStoreFormComponent<Saemi> implements OnInit, OnDestroy {


    public corType = CorType;
    public emiGenreType = EmiGenre;
    public aemaCodeAVS = AemaCodesAVS;
    public aemaCodeCAF = AemaCodesCAF;
    public optionSalIdCor: ComboboxItem<string>[] = [];
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private readonly _translate: TranslateService, private readonly _saesService: SaesService) {
        super(store);
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get emiGenre(): string | undefined {
        return this.getFormValue('emiGenre');
    }

    public ngOnInit() {
        if (this.isNew) {
            this.setSaemiValues();
        }
        this.getSalIdCorOptions();
        this.setSaemiValidators();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public fieldDisabled(emiTypes: string): boolean {
        const emiType = this.getFormValue('emiType');
        return (emiTypes.indexOf(emiType) !== -1);
    }

    public saveEntry() {
        this.saveChanges();
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Saemi> {
        return ['emiType', 'corType', 'salId', 'emiId', 'salIdCor', 'emaCode', 'empId', 'emiGenre', 'emaDate', 'modecreat', 'cantonOld', 'dureeCotisationFrom', 'dureeCotisationTo'];
    }

    protected getValidationType() {
        return Saemi;
    }

    private setSaemiValues(): void {
        this.setFormValue('empId', this.empId);
        this.setFormValue('salId', this.getData('salId', true) || '');
        this.setFormValue('salIdCor', this.getData('salId', true) || '');
        this.setFormValue('corType', 1);
        this.setFormValue('emiType', '9');
        this.setFormValue('emaCode', '1');
        this.setFormValue('modecreat', 'M');
    }

    private getSalIdCorOptions() {
        this._saesService.getComboSalIdOptions()
            .subscribe(options => {
                this.optionSalIdCor.push({name: this._translate.instant('combo_no_option'), value: ''});
                options.forEach((option) => {
                    this.optionSalIdCor.push({name: formatSalId(option), value: option});
                });
            });
    }

    private setSaemiValidators() {
        this.getFormControl('corType')
            ?.setValidators([Validators.required]);
        this.getFormControl('emaCode')
            ?.setValidators([Validators.required]);
        this.getFormControl('emaReason')
            ?.setValidators([Validators.required]);
        this.getFormControl('salIdCor')
            ?.setValidators([Validators.required]);
        this.getFormControl('emiGenre')
            ?.setValidators([Validators.required]);
    }

}
