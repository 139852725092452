import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IwGridColumn} from '@app/sam-base/models';
import {SmsTypes} from '@app/sam-base/models/placement';
import {SendSMS} from '@core/sms/sms-call.model';
import {SmsCallService} from '@core/sms/sms-call.service';
import {TranslateService} from '@ngx-translate/core';
import {KeycloakService} from 'keycloak-angular';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'iw-send-sms',
    templateUrl: './iw-send-sms-form.component.html',
    styleUrls: ['./iw-send-sms-form.component.scss'],
    standalone: false
})
export class IwSendSMSComponent implements OnInit {
    @Input() public contacts: SmsTypes[] = [];

    @Output() public cancel = new EventEmitter();

    @Output() public smsSent = new EventEmitter();

    public columns: IwGridColumn<SmsTypes>[] = [{
        prop: 'nom', index: 1
    }, {
        name: 'tel1', prop: 'natel', index: 2
    }];

    public maxMessageSize = 160;
    public smsCredit = 0;
    public clientsToSend = 0; // Number of cli with valid number
    public message = '';
    public console = '';
    public loading = false;
    public status: 'ready' | 'sent' = 'ready';
    public userId = '';

    constructor(private readonly _smsService: SmsCallService, private readonly _translate: TranslateService, private readonly _keycloak: KeycloakService) {
    }

    public get buttonOkText() {
        return this.status === 'ready' ? 'btn_sms_send' : 'ok';
    }

    public get buttonOkIcon() {
        return this.status === 'ready' ? 'fas fa-paper-plane' : '';
    }

    public get buttonEnabled() {
        return (!this.loading && this.clientsToSend > 0 && (this.status === 'sent' || this.message.length > 0) && this.smsCredit > 0);
    }

    public ngOnInit() {
        this.loadCredit();
        this.logEmptyAndNotInternationalNumbers();
        this.userId = this._keycloak.getUsername();
    }

    public onCancel() {
        this.cancel.emit();
    }

    public onOk() {
        this.smsSent.emit();
    }

    public setLoading(val: boolean) {
        setTimeout(() => {
            this.loading = val;
        }, 0);
    }

    public sendSms() {
        if (this.status === 'sent') {
            this.onOk();
            return;
        }
        const sms: SendSMS = {
            listIds: this.getList(),
            listType: this.getListType(),
            message: this.message,
            userId: this.userId,
            cdeId: this.contacts[0].cdeId,
            cliId: this.contacts[0].cliId,
            suiviOpts: {createSuivis: false, daterappel: undefined}
        }

        this._smsService
            .sendSms(sms)
            .subscribe((data) => {
                this.console = this._translate.instant('send_sms_success') + '\n';
                this.status = 'sent';
            }, (error) => {
                this.console = this._translate.instant('send_sms_error') + '\n';
                this.status = 'sent';
            });
    }

    private async loadCredit() {
        const id = this.getId();

        if (id) {
            this.smsCredit = await lastValueFrom(this._smsService.getCredit(id));
        }
    }

    private async logEmptyAndNotInternationalNumbers() {
        const noNumCli = this.contacts.filter((e) => !e.natel);
        const patt = new RegExp(/^\+(?:[0-9]{1,3} ?)[0-9]{6,14}$/);
        const notIntern = this.contacts
            .filter((e) => !patt.test(e.natel))
            .filter((e) => e.natel || e.natel !== '');
        this.clientsToSend = this.contacts.length - noNumCli.length;

        if (noNumCli.length > 0) {
            this.console += this._translate.instant('send_sms_no_num') + ':\n';
            noNumCli
                .filter((e) => !e.natel)
                .forEach((e) => (this.console += '- ' + e.nom + '\n'));
        }
        if (notIntern.length > 0 && notIntern !== noNumCli) {
            this.console += this._translate.instant('send_sms_not_intern') + ':\n';
            notIntern.forEach((e) => (this.console += '- ' + e.nom + '\n'));
        }
    }

    /** Return the first id or undefiend */
    private getId() {
        return this.contacts.map((e) => e.ageId)
            .find((e) => !!e);
    }

    private getList(): string[] {
        return <any>(this.contacts.map((e) => e.id)
            .filter((e) => typeof e === 'string'));
    }

    private getListType() {
        const listType = this.contacts.map((e) => e.type);

        if (listType[0] === undefined) {
            throw new Error('Type does not exist');
        }

        return listType[0];
    }
}
