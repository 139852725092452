import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModesFacturation} from '@app/sam-base/models/parameters-enums';
import {Pppar} from '@app/sam-base/models/placement';

import {calcSalaire} from '../pppar-enums';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-pppar-salaire1', templateUrl: './tab-pppar-salaire1.component.html',
    standalone: false
})
export class TabPpparSalaire1Component extends BasePartialFormComponent<Pppar> {

    public modeFact = ModesFacturation;

    public get entityId() {
        const id = this.getFormValue('ageId');
        if (typeof id === 'string') {
            return id;
        }
        return undefined;
    }

    public get islheunuit() {
        return !!this.getFormValue<boolean>('lheunuit');
    }

    public get is13() {
        return !!this.getFormValue<boolean>('l13H');
    }

    public get isjf() {
        return !!this.getFormValue<boolean>('ljfh');
    }

    public entityService: RestEntityClient<Pppar>;
    public calcSalaire = calcSalaire;

    constructor(_restService: RestApiService) {
        super();
        this.entityService = _restService.getEntityClient(Pppar);
    }

}
