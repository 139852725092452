import {Component, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwGridColumn, Lookups, RowClickEvent} from '@app/sam-base/models';
import {Ppcdf, Ppemp, Ppplf} from '@app/sam-base/models/placement';
import {PpempService} from '@modules/sam-main/placement/services/ppemp.service';
import {IwEventHubService, IwStoreService} from '@sam-base/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../../placement.forms';
import {PpplfService} from '../../../services/ppplf.service';

@Component({
    selector: 'iw-tab-ppcdf-placement',
    templateUrl: './tab-ppcdefixe-placement.component.html',
    standalone: false
})
export class TabPpcdeFixePlacementComponent extends BasePartialFormComponent<Ppcdf> implements OnInit, OnDestroy {

    @Input()
    public set entityId(v: string) {
        this.setEntityId(v);
    }

    @Input() public isReadonly = true;

    @Input() public uuid?: string;

    public type: Type<Ppplf> = Ppplf;

    public placRows = 0;

    public get canCreatePpplf(): boolean {
        return this.placRows >= 1;
    }

    public columns = this.getPlacfColumns();

    public query: RestQueryParam<Ppplf, string>[] = [];
    private subscriptions = new Subject();
    private _entityId?: string;

    constructor(private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _ppplfService: PpplfService,
                private readonly _events: IwEventHubService<string>,
                private readonly _store: IwStoreService,
                private readonly _employeeService: PpempService) {
        super();
    }

    public ngOnInit() {
        this._events.forType('placf_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                setTimeout(() => {
                    this._store.dispatch(new actions.LoadEntity(this.uuid || ''));
                }, 1000);
            });
    }

    public checkPpplf(ppplfs: Ppplf[]) {
        this.placRows = ppplfs.length;
        for (let ppplf of ppplfs) {
            // Fetch the employee name for each Ppplf using EmployeeService
            if (ppplf.ctaEmpId) {

                // Fetch the employee details for each Ppplf using EmployeeService
                this._employeeService.getEmployeById(ppplf.ctaEmpId)
                    .subscribe({
                        next: (data: Ppemp) => {
                            console.log(Ppemp);
                            ppplf.nomemploye = data.nom + " " + data.prenom;
                        }
                    });
            }
        }
    }

    public async setEntityId(entityId: string) {
        if (!entityId) {
            return;
        }
        this.query = [
            {
                prop: 'cdfId',
                operation: RestQueryOperation.Equals,
                value: entityId
            }];
        this._entityId = entityId;
    }

    public onRowDoubleClick(event: RowClickEvent<Ppplf>) {
        const plfId = (event && event.row && event.row.plfId);
        if (!plfId) {
            return;
        }
        this._entityNavService.navigateToEntityForm(Ppplf, plfId);
    }

    public openNewPlacement() {
        this._formHandler
            .showFormDialog(PlacementForm.PpplfNew, {ppcdf: this.formGroup?.value});
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }


    private getPlacfColumns(): IwGridColumn<Ppplf>[] {
        return [
            {
                prop: 'plfId',
                name: 'No',
                index: 0
            },
            {
                prop: 'ctaDate',
                name: 'date',
                type: 'date',
                index: 1
            },
            {
                prop: 'nomemploye',
                name: 'employe',
                index: 2
            },
            {
                prop: 'ctaEmpId',
                name: 'empId',
                index: 3
            },
            {
                prop: 'ctaDebut',
                name: 'datedebut',
                type: 'date',
                index: 4
            },
            {
                prop: 'ctaFct',
                name: 'function',
                index: 5
            }];
    }
}
