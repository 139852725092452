<div class="row">
    <iw-busy [isBusy]="isLoading"></iw-busy>

    <div class="col-xs-4"
         style="height: 75vh">
        <iw-table #grdClients
                  (columnSort)="onUsersSort()"
                  (selectedChange)="onSelectedRow($event)"
                  [columns]="columns"
                  [data]="usersList"
                  [defaultSelected]="true"
                  [isFilterEnable]="false"
                  [isGridColumnMenuEnable]="false"
                  [showFilterGrouping]="false"
                  class="col-xs-12 no-padding user-config-grid"></iw-table>
    </div>

    <div class="col-xs-6">
        <form [formGroup]="formGroup">

            <div class="row">
                <iw-dropdown [isDisabled]="isReadonly"
                             [labelAlign]="'left'"
                             [label]="'conseiller' | translate"
                             [options]="availableMainAdvisorsToLink"
                             [showClear]="true"
                             [size]="'medium'"
                             class="col-xs-10 iw-input"
                             formControlName="conseiller"></iw-dropdown>

                <iw-button (press)="openConseillersForm()"
                           [isDisabled]="isReadonly"
                           [text]="'user_roles_conseillers_btn' | translate"
                           class="col-xs-2"></iw-button>
            </div>

            <div class="row">
                <iw-table [columns]="delegateAssociatedGridColumns"
                          [data]="delegateAssociatedGridData"
                          [isFilterEnable]="false"
                          [isGridColumnMenuEnable]="false"
                          [isSortIconEnable]="false"
                          [rowHeight]="100"
                          class="col-xs-12 padding-top-5 delege-config-grid"
                          style="height: 100px"></iw-table>
            </div>

            <div class="row no-padding padding-top-10">
                <div class="iw-label col-xs-5 flex flex-center">{{ 'conseillerDisponible' | translate }}</div>
                <div class="iw-label col-xs-5 col-xs-offset-2 flex flex-center">{{ 'conseillerAssocies' | translate }}</div>

                <iw-selection-lists (secondListChange)="setSecondaryAdvisors($event)"
                                    [disabled]="isReadonly"
                                    [firstList]="availableAdvisors"
                                    [secondList]="actualAdvisors"
                                    class="col-xs-12 no-padding"></iw-selection-lists>
            </div>

            <div class="row no-padding padding-top-10">
                <div class="iw-label col-xs-5 flex flex-center">{{ 'teams-available' | translate }}</div>
                <div class="iw-label col-xs-5 col-xs-offset-2 flex flex-center">{{ 'teams-linked' | translate }}</div>
                <iw-selection-lists (secondListChange)="onTeamListChange($event)"
                                    [disabled]="isReadonly"
                                    [firstList]="availableTeams"
                                    [secondList]="actualTeams"
                                    class="col-xs-12 no-padding"></iw-selection-lists>
            </div>
        </form>
    </div>

    <div class="col-xs-2 flex flex-v flex-evenly"
         style="height: 140px;">
        <iw-button (press)="modifyEntry()"
                   [isDisabled]="!isReadonly"
                   [text]="'sidemenu_edit' | translate"
                   id="sidemenu_edit"></iw-button>
        <iw-button (press)="saveEntry()"
                   [isDisabled]="isReadonly || !isValid()"
                   [text]="'sidemenu_save' | translate"
                   id="sidemenu_save"></iw-button>
        <iw-button (press)="cancelEditionMode()"
                   [isDisabled]="isReadonly"
                   [text]="'sidemenu_cancel' | translate"
                   id="sidemenu_cancel"></iw-button>
    </div>

</div>

<div class="row flex flex-hr">
    <iw-button #Closebutton1
               (press)="closeDialog()"
               [text]="'fermer' | translate"
               class="iw-button col-xs-2 end-xs"></iw-button>
</div>

