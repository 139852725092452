import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@sam-base/base';
import {EntityNavigationService, IwEventHubService, RestApiService, RestEntityClient} from '@sam-base/core';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';
import {Ppcct, Ppcli, Ppclimis, Ppmis} from '@sam-base/models/placement';

@Component({
    selector: 'iw-tab-derniere-mission',
    templateUrl: './tab-derniere-mission.component.html',
    standalone: false
})
export class TabDerniereMissionComponent extends BasePartialFormComponent<Ppmis> implements OnInit {

    @Input() public isReadonly = true;

    @Input() public mis?: Ppmis;

    public cct: string | undefined = '';
    public qualif: string | undefined = '';
    public cliName: string | undefined = '';

    private readonly _ppcctRest: RestEntityClient<Ppcct>;
    private readonly _ppcliRest: RestEntityClient<Ppcli>;

    constructor(protected readonly _apiService: RestApiService,
                private readonly _entityService: EntityNavigationService,
                private readonly _eventHub: IwEventHubService<string>) {
        super();
        this._ppcctRest = this._apiService.getEntityClient(Ppcct);
        this._ppcliRest = this._apiService.getEntityClient(Ppcli);
    }

    public ngOnInit() {
        if (!this.mis) {
            return;
        }
        this._ppcctRest.getById(this.mis.cctId || '')
            .subscribe((elem: Ppcct) => {
                this.cct = elem.cctLib;
            });
        this._ppcliRest.getById(this.mis.cliId || '')
            .subscribe((elem: Ppcli) => {
                this.cliName = elem.nom;
            });
        this.mis.firstday = IwDateHelper.dateFormatFromString(this.mis.firstday, 'DD.MM.yyyy');
    }

    public openMission() {
        const _ = undefined;
        this._entityService.navigateToEntityForm(Ppclimis, this.mis?.misId || '', _, _, true);
    }
}

