<form [formGroup]="formGroup">
    <p-scrollPanel
            [style]="{width: '100%', height: '30vh'}"
            styleClass="iw-scrollbar">
        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'society_name'"
                      class="iw-input"
                      formControlName="nomSoc1"></iw-textfield>


        <iw-gltvadef-dropdown (selectedChanged)="changeTva($event)"
                              [isDisabled]="isReadonly"
                              [labelAlign]="'top'"
                              [label]="'Code TVA'"
                              [size]="'small'"
                              class="iw-input"
                              formControlName="tvaCode"
                              id="cbo-nation"></iw-gltvadef-dropdown>

        <!--  [value]="(tvaTaux | number : '1.2-2') + '%' "-->
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'top'"
                      [label]="''"
                      class="iw-input"
                      formControlName="tvaTaux"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'enterprise_identification_number_uid'"
                      [mask]="'000.000.000'"
                      [prefix]="'CHE-'"
                      [size]="'small'"
                      class="iw-input col-sm-4"
                      formControlName="tvaNoIde"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'Période TVA'"
                      class="iw-input"
                      formControlName="tvaperiode"></iw-textfield>

        <iw-enum-dropdown [inputEnum]="TvaType"
                          [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'Type TVA'"
                          class="iw-input"
                          formControlName="tvaType"></iw-enum-dropdown>

        <iw-gepaimode-dropdown [isDisabled]="isReadonly"
                               [labelAlign]="'top'"
                               [label]="'Mode de paiement par défaut'"
                               class="iw-input"
                               formControlName="defMpaId"></iw-gepaimode-dropdown>

        <iw-gepaimode-dropdown [isDisabled]="isReadonly"
                               [labelAlign]="'top'"
                               [label]="'Mode de paiement pour l\'escompte'"
                               class="iw-input"
                               formControlName="escMpaId"></iw-gepaimode-dropdown>

        <iw-gepaimode-dropdown [isDisabled]="isReadonly"
                               [labelAlign]="'top'"
                               [label]="'Mode de paiement pour le lettrage'"
                               class="iw-input"
                               formControlName="letMpaId"></iw-gepaimode-dropdown>

        <iw-gepaicond-dropdown [isDisabled]="isReadonly"
                               [labelAlign]="'top'"
                               [label]="'Conditions de paiement'"
                               class="iw-input"
                               formControlName="paicondId"></iw-gepaicond-dropdown>

        <iw-checkbox [isDisabled]="isReadonly"
                     [labelAlign]="'top'"
                     [label]="'Numérotation automatique'"
                     class="iw-input"
                     formControlName="autofacNo"></iw-checkbox>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'Numéro de facture'"
                      class="iw-input"
                      formControlName="facNo"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'Préfixe'"
                      class="iw-input"
                      formControlName="pfxfacno"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'Escompte max 1'"
                      class="iw-input"
                      formControlName="txmaxesc1"
                      type="number"></iw-textfield>

        <iw-textfield [isDisabled]="isReadonly"
                      [labelAlign]="'top'"
                      [label]="'Escompte max 2'"
                      class="iw-input"
                      formControlName="txmaxesc2"
                      type="number"></iw-textfield>
        <hr>
        <div class="row">
            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'rplReminderDays1' | translate"
                          class="iw-input col-xs-12 text-align-right"
                          decimals="0"
                          formControlName="rplReminderDays1"
                          type="number"></iw-textfield>
            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'rplReminderDays2' | translate"
                          class="iw-input col-xs-12 text-align-right"
                          decimals="0"
                          formControlName="rplReminderDays2"
                          type="number"></iw-textfield>
            <iw-textfield [isDisabled]="isReadonly"
                          [labelAlign]="'top'"
                          [label]="'rplReminderDays3' | translate"
                          class="iw-input col-xs-12 text-align-right"
                          decimals="0"
                          formControlName="rplReminderDays3"
                          type="number"></iw-textfield>

        </div>
    </p-scrollPanel>

</form>
