import {Component, Type, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {SortInfo} from '@app/sam-base/components';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {RestApiService, RestEntityClient, RestQueryParam} from '@app/sam-base/core/rest-api';
import {initEntity} from '@app/sam-base/core/rest-api/entity/rest-entity-helpers';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {EntityCreate, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys, IwGridColumn, Sags} from '@app/sam-base/models';
import {Ppgri} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {PpgriService, PpgriSubscrition} from '@sam-base/core/services/ppgri.service';
import {Subject, takeUntil} from 'rxjs';

import {TypeEnum} from './genres-indemnites-enums';

/**
 * Component created regarding SAM-4538
 */
@Component({
    templateUrl: 'genres-indemnites.component.html',
    standalone: false
})
export class GenresIndemnitesComponent extends BaseFormComponent<Ppgri> {

    public typeEnum = TypeEnum;
    public type: Type<Ppgri> = Ppgri;
    public selectedGenre?: Ppgri;
    public subscritions = new Subject();
    public query: RestQueryParam<Ppgri, string>[] = [];
    public columns = this.getPpgriColumns();
    public griId = '';

    public griTypeValue?: string;
    public addMode = false;
    public editMode = false;
    public isSaving = false;
    public sortProp?: keyof Ppgri = 'griId';
    public sortPropDir?: 'asc' | 'desc' = 'asc';
    @ViewChild('griGrid', {static: true}) public griGrid?: IwRestGridComponent<Ppgri>;
    private readonly _restClient: RestEntityClient<Ppgri>;

    constructor(private _store: IwStoreService, private _ppgriService: PpgriService,
                private readonly _translate: TranslateService, private readonly _modalService: ModalService,
                private readonly _rest: RestApiService, private readonly _toastService: ToastService) {
        super();
        this._restClient = this._rest.getEntityClient(Ppgri);
    }

    public get canSave() {
        return this.formGroup.valid;
    }

    public get selectedData() {
        if (!this.selectedGenre) {
            return undefined;
        }
        return {
            name: 'griId',
            value: this.selectedGenre.griId
        };
    }

    /**
     * Method to get data of selected row
     *
     * @param event row selected
     */
    public getSelectedRow(event: Ppgri[]) {

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedGenre = undefined;
            return;
        }
        this.griId = (event[0] && event[0].griId) || '';
        this.selectedGenre = event[0];
        this.fillFormData(<any>this.selectedGenre);
    }

    /**
     * Method to create a new genre d'indemnite
     */
    public createGenre() {
        this.addMode = true;
        this.selectedGenre = undefined;
        const ppgri = new Ppgri();
        ppgri.griType = 1;
        this.fillFormData(<any>ppgri);
    }

    /**
     * Method to edit a genre d'indemnite
     */
    public editGenre() {
        this.editMode = true;
        this.addMode = true;
    }

    /**
     * Method to save a new genre d'indemnite
     */
    // eslint-disable-next-line complexity
    public saveGenre() {
        const ppgri = this.getFormData();

        this.isSaving = true;

        if (ppgri && (ppgri.griId === '' || ppgri.griName === '')) {
            const error = this._translate.instant('code_libelle_required');
            this._toastService.error(error);
        } else {
            if (ppgri && this.editMode && this.addMode) {
                const updatedData = this._restClient
                    .construct({...ppgri});
                this._store.dispatch(new EntityUpdate<Ppgri>(updatedData));
            } else if (ppgri) {
                this.setSortByModif();
                this._store.dispatch(new EntityCreate<Ppgri>(initEntity(Ppgri, ppgri)));
            }
            this.editMode = false;
            this.addMode = false;
        }
    }

    /**
     * Method to delete a genre d'indemnite
     */
    public deleteGenre() {
        if (!this.selectedGenre) {
            return;
        }
        /*const griId = this.selectedGenre.griId || '';
        this._store.dispatch(new EntityDelete(Ppgri, griId));
        this.refreshGrid();*/

        this._ppgriService.delete(this.getFormValue('griId'))
            .subscribe(() => {
                this._ppgriService.ppgriSub.next({
                    uuid: this.uuid,
                    saved: true
                });
            });
        this._ppgriService.ppgriSub
            .pipe(takeUntil(this.subscritions))
            .subscribe((data: PpgriSubscrition) => {
                if (this.uuid === data.uuid && data.saved) {
                    this.refreshGrid();
                }
            });
    }
    

    /**
     * Method do cancel creation or edition of genre
     */
    public cancelGenre() {
        if (this.addMode || this.editMode) {
            this.fillFormData(this.selectedGenre ?? {});
            this.editMode = false;
            this.addMode = false;
        } else {
            this.showModal();
        }
    }

    /**
     * Method to check if genre salaire field is disabled regarding type option
     */
    public checkGenreSalaireDisabled(): boolean {
        const type = this.getFormValue('griType');
        return !this.addMode || !(type !== 4);
    }

    /**
     * Method to check if compte field is disabled regarding type option
     */
    public checkCompteDisabled(): boolean {
        const type = this.getFormValue('griType');
        return !this.addMode || !(type === 1 || type === 4 || type === 5);
    }

    public setGriTypeValue() {
        this.griTypeValue = this.getFormValue('griType');
    }

    /**
     * Close form
     */
    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppgri>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    public changedValue(value: Sags) {
        this.setFormValue('retInd', value.retInd);
    }

    protected getValidationType() {
        return Ppgri;
    }

    protected getFormControlNames(): FormKeys<Ppgri> {
        return [
            [
                'griId',
                new UntypedFormControl(undefined, Validators.maxLength(4))],
            'griUnite',
            'userId',
            'actif',
            'griPuemp',
            'shortlib',
            'griName3',
            'griTxcha',
            'griName',
            'absCode',
            'griPumode',
            'griName5',
            'griGsId',
            'tvaCode',
            'mismode',
            'griName2',
            'griName4',
            'griPucli',
            'griData',
            'griName1',
            'griCompte',
            'griType',
            'modData',
            'modCnt',
            'dateCreat',
            'dateModif',
            'dateDelet',
            'userCreat',
            'userDelet',
            'userModif',
            'exclstat',
            'retInd'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }

    private async showModal() {
        if (!this.selectedGenre) {
            return;
        }
        const options: ModalMessageOptions = {
            message: [this._translate.instant('annulerEnregistrement')],
            showCancel: false,
            title: this._translate.instant('ppgri_form_title')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this.addMode = false;
            this.editMode = false;
            this.fillFormData(this.selectedGenre);
        } catch (error) {
        }
    }

    private refreshGrid() {
        if (!this.griGrid) {
            return;
        }
        this.griGrid.refresh();
    }

    /**
     * Columns to show in grid
     */
    private getPpgriColumns(): IwGridColumn<Ppgri>[] {
        return [
            {
                prop: 'griId',
                name: this._translate.instant('code'),
                width: 100
            },
            {
                prop: 'griName',
                name: this._translate.instant('libelle'),
                width: 100
            },
            {
                prop: 'griGsId',
                name: this._translate.instant('gs'),
                width: 100
            },
            {
                prop: 'griCompte',
                name: this._translate.instant('compte'),
                width: 100
            },
            {
                prop: 'griType',
                name: this._translate.instant('type'),
                width: 100
            }];
    }
}
