import {Component, Input, Type} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormKeys} from '@app/sam-base/models';
import {Ppekw, Ppkw} from '@app/sam-base/models/placement';

import {TabMatching} from './tab-matching.model';

@Component({
    selector: 'iw-tab-matching',
    templateUrl: './tab-matching.component.html',
    styleUrls: ['./tab-matching.component.scss'],
    standalone: false
})
export class TabMatchingComponent extends BasePartialFormComponent<TabMatching> {
    @Input() public isReadonly = true;

    public matchSource: Type<any> = Ppkw;
    public matchTarget: Type<any> = Ppekw;

    @Input() public entityId = '';

    @Input() public formId = '';

    constructor() {
        super();
    }

    public changedKey() {
        this.onFormChange();
    }

    public matchSourceName = (e: Ppkw) => `${e.kw} | ${e.descr || ''}`;
    public matchTargetName = (e: Ppekw) => `${e.kw}`;

    protected getFormControlNames(): FormKeys<TabMatching> {
        return ['aptids'];
    }
}
