import {Component, Input, ViewChild} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AgencyHandlerService} from '@app/sam-base/core/auth/services/agency-handler.service';
import {ProfileService} from '@shared/profile/profile.service';
import {KeycloakService} from 'keycloak-angular';
import {Toast} from 'primeng/toast';
import {lastValueFrom} from 'rxjs';

import {ToastMessage, ToastPosition} from '../toast.model';

@Component({
    selector: 'iw-toast-custom',
    styleUrls: ['../toast-host.component.scss'],
    templateUrl: './toast-custom.component.html',
    standalone: false
})
export class ToastCustomComponent {

    @Input() public position: ToastPosition = 'bottom-center';

    @Input() public toastKey = '';
    @Input() public showSendToSupport = true;

    @ViewChild('toast') public toast?: Toast;

    constructor(private _profileService: ProfileService, private _keycloakService: KeycloakService,
                private readonly _age: AgencyHandlerService, private sanitizer: DomSanitizer) {
    }

    public getSanitizeMessage(message: ToastMessage): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(message?.detail || '');

    }

    public closeToast(message: any) {
        // Hack to close only one custom toast
        const index = this.toast?.messages
            ?.findIndex(msg => msg.summary === message.summary);
        if (index || index === 0) {
            this.toast?.messages?.splice(index, 1);
        }
    }

    public copyErrorClipboard(message: string) {
        document
            .addEventListener('copy', function listener(ev: ClipboardEvent) {
                if (ev.clipboardData) {
                    ev.clipboardData.setData('text/plain', message);
                    ev.preventDefault();
                }
                document.removeEventListener('copy', listener);
            });
        document.execCommand('copy');
    }

    public async mailTo(message: string) {
        const username = this._keycloakService.getUsername()
            .toUpperCase();
        const tenant = (await this._profileService.getTenant()).toUpperCase();
        const gestion = (await lastValueFrom(this._age.loadAgencyId())).toUpperCase();
        const supportEmail = 'support@interiware.ch';
        // eslint-disable-next-line max-len
        const subject = `The user ${username} in tenant ${tenant} and gestion ${gestion} has encountered an error in SAM`;
        const url = `mailto:${supportEmail}?subject=${subject}&body=${message}`;
        window.open(url, '_blank');
    }
}
