import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {IwStoreService} from '@app/sam-base/core';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {SidemenuState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, DynamicComponent, FormKeys} from '@app/sam-base/models';

export interface Ppcliflt {
    lactifonly: string;
}

@Component({
    templateUrl: './ppcliflt.component.html',
    standalone: false
})
export class PpclifltComponent extends BaseFormComponent<Ppcliflt> {

    constructor(private readonly _store: IwStoreService) {
        super();
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Ppcliflt> {
        return ['lactifonly'];
    }

    protected getSideMenuState(): SidemenuState {
        return {};
    }

    protected getSideComponent(): DynamicComponent | undefined {
        return undefined;
    }

    protected getOrderByOptions(): ComboboxItem<keyof Ppcliflt>[] {
        return [];
    }

}
