import {Component, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast/toast.service';
import {FormKeys} from '@app/sam-base/models';
import {Sags} from '@app/sam-base/models/salary';

import {SagsService} from '@modules/sam-main/salary/services/sags.service';
import {genreSalaireTabsFormControls} from './genre-salaire-tabs-formcontrols';

@Component({
    templateUrl: './genre-salaire.component.html',
    standalone: false
})
export class GenreSalaireVersionComponent extends BaseTabFormComponent<Sags> implements OnInit {

    public invalidGsId = false;

    constructor(store: IwStoreService, private _sagsService: SagsService, private _toast: ToastService) {
        super(store);
    }

    public get version(): number {
        return this.getData('version') || 0;
    }

    public get gsId(): string {
        return this.getData('gsId') || '';
    }

    public ngOnInit() {
        this.fetchAndFillSagsData(this.gsId);
        this.subscribeValueChange('gsId', this.handleGsIdChange);
    }

    public getTabsValues(): TabFormControl<Sags>[] {
        return genreSalaireTabsFormControls;
    }

    public checkGsExists(gsId?: string) {
    }

    protected getValidationType() {
        return Sags;
    }

    protected getFormControlNames(): FormKeys<Sags> {
        return this.getTabsFormControls(genreSalaireTabsFormControls);
    }

    private handleGsIdChange = (gsId: string | undefined): void => {
        if (gsId) {
            this.fetchAndFillSagsData(gsId);
        }
    };

    private fetchAndFillSagsData(gsId: string): void {
        this._sagsService.getSagsByVersion(gsId, this.version)
            .subscribe((sags) => {
                this.fillFormData(sags);
            });
    };

}
