import {Component, OnInit} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {FormHandlerService, HeaderToolbarService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {SaisService} from '@app/sam-base/core/services/sais.service';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {Canton} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {SalaryForm} from '../../salary.forms';

@Component({
    templateUrl: './canton-bareme.component.html',
    standalone: false
})
export class CantonBaremeComponent extends BaseStoreFormComponent<Canton> implements OnInit {

    public canton = '';
    public isBareme = '';
    public isnordre = '';
    public iscommper = '';
    public isnbheures = '';
    public activeSapar?: string;

    private readonly _entityCantonService: RestEntityClient<Canton>;

    constructor(protected readonly _store: IwStoreService, private headerService: HeaderToolbarService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                protected readonly _saisService: SaisService, _restService: RestApiService) {
        super(_store);
        this._entityCantonService = _restService.getEntityClient(Canton);
    }

    public ngOnInit(): void {
        this.getActiveYear();
    }


    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    public majCodes(): void {
        this._saisService.majCodes(this.canton, this.activeSapar ?? '')
            .subscribe();
    }

    public openBareme(): void {
        this._formHandler.showFormDialog(SalaryForm.Bareme, {
            baremes: this.isBareme,
            canton: this.canton,
            annee: this.activeSapar
        });
    }

    public cantonChange(canton: string) {
        this.canton = canton;
        this._entityCantonService.getById(canton)
            .subscribe((c: Canton) => {
                this.isBareme = c.isbaremes;
            });
    }

    private getActiveYear() {
        this.headerService.getActiveExercise()
            .subscribe((sapar: Sapar) => this.activeSapar = sapar.annee);
    }
}
