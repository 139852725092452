import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {
    DialogStateService,
    EntityNavigationService,
    FormHandlerService,
    IwEventHubService,
    ModalService
} from '@app/sam-base/core';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Saems} from '@app/sam-base/models/salary/saems';
import {Saemsview} from '@app/sam-base/models/salary/saemsview';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {LayoutSalaryNavigationEvent} from '@sam-base/layout';
import {SalaryForm} from '../../../salary.forms';

import {SagsService} from '../../../services/sags.service';

@Component({
    selector: 'iw-tab-saemp-annee',
    templateUrl: './tab-saemp-annee.component.html',
    standalone: false
})
export class TabSaempAnneeComponent extends BasePartialFormComponent<Saempc> implements OnInit {
    @Input() public sapar?: Sapar;
    @Input() public uuid?: string;
    public months = [
        this._translate.instant('january'),
        this._translate.instant('february'),
        this._translate.instant('march'),
        this._translate.instant('april'),
        this._translate.instant('may'),
        this._translate.instant('june'),
        this._translate.instant('july'),
        this._translate.instant('august'),
        this._translate.instant('september'),
        this._translate.instant('october'),
        this._translate.instant('november'),
        this._translate.instant('december')];
    public checkboxesValues: {
        name: string; value: string;
    }[] = [];
    public saemsObj: {
        value: number; nap: string; mntrep: string;
    }[] = [];
    public tableData: {
        value: number; month: string; din: string; dout: string; nap: string; mntrep: string;
    }[] = [];

    constructor(private _parametersService: ParametersService, private _translate: TranslateService,
                private _saemsService: SaemsService, private readonly _modalService: ModalService,
                private _sagsService: SagsService, private _navigationService: EntityNavigationService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _eventHub: IwEventHubService<string>,
                private readonly _dialogStateService: DialogStateService) {
        super();
    }

    public get empId() {
        return this.getFormValue<string>('empId');
    }

    public ngOnInit() {
        this.initData();
        this.subscribeValueChange('empId', () => this.resetAndInitData());
    }

    public getAnnee() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => (this.sapar = sapar));
    }

    public getView(event: any) {
        const id = this.empId + '_' + this.sapar?.annee + (event.value < 10 ? ' ' + event.value : event.value);
        this._navigationService.navigateToEntityForm(Saemsview, id);
    }

    public getSaempcData() {
        for (let i = 1; i <= 12; i++) {
            const dinValue = `din${i}` as keyof Saempc;
            const doutValue = `dout${i}` as keyof Saempc;
            const napValue = `nap${i}` as keyof Saempc;
            this.tableData.push({
                value: i,
                month: this.months[i - 1],
                din: this.getFormValue(dinValue) || '',
                dout: this.getFormValue(doutValue) || '',
                nap: this.getFormValue(napValue) || '',
                mntrep: ''
            });
        }
    }

    public getAllData() {
        if (this.empId && this.sapar?.annee) {
            this._saemsService
                .getSaemsByEmpIdAndYear(this.empId, this.sapar.annee)
                .subscribe((saems: Saems[]) => {
                    for (const element of saems) {
                        const salId = `${element.salId}`;
                        this.saemsObj.push({
                            value: parseInt(salId.substring(salId.length - 2), 10),
                            nap: `${element.mntnap}`,
                            mntrep: `${element.mntrep}`
                        });
                    }
                    this.mergeArrayObjects();
                    this.getCheckboxesValues();
                });
        }
    }

    public async goToProvisions() {
        if (this.empId) {
            this._sagsService.getProvisions(this.empId)
                .subscribe((data: string) => {
                    const options: ModalMessageOptions = {
                        message: [data],
                        title: this._translate.instant('tabInformations'),
                        okDisabled: false,
                        showCancel: false,
                        width: 450,
                        textarea: true
                    };
                    try {
                        this._modalService.showModal(ModalMessageComponent, options);
                    } catch (error) {
                    }
                });
        }

    }

    public goToDecompte() {
        this._formHandler.showFormDialog(SalaryForm.DecompteGS, {
            empId: this.empId,
            annee: this.sapar?.annee
        });
    }

    public openFicheSal() {

        this._eventHub.emit(LayoutSalaryNavigationEvent.FICHE_SALAIRE, {
            filters: {
                'empId': this.empId

            },
            // TODO make this working when time
            /*wildcards: {
                'salId': "*" + this.sapar?.annee + "*"
            }*/
        });
        this._dialogStateService.removeFocus();
    }

    private initData() {
        this.getAnnee();
        this.getAllData();
    }

    private resetAndInitData() {
        this.saemsObj = [];
        this.tableData = [];
        this.checkboxesValues = [];
        this.initData();
    }

    private mergeArrayObjects() {
        this.getSaempcData();
        this.tableData = this.tableData.map((data) => ({
            ...data, ...this.saemsObj.find((rep) => rep.value === data.value), ...this.saemsObj.find((nap) => nap.nap === data.nap)
        }));
    }

    private getCheckboxesValues() {
        for (let i = 1; i <= 12; i++) {
            const lmfName = `lmf${i}` as keyof Saempc;
            this.checkboxesValues.push({
                name: lmfName,
                value: this.getFormValue(lmfName) || ''
            });
        }
    }
}
