import {Component, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {PpempService} from '@app/modules/sam-main/placement/services/ppemp.service';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {
    EntityNavigationService, IwEventHubService, PpkwService, RestQueryOperation, RestQueryParam
} from '@app/sam-base/core';
import {TabFormControl} from '@app/sam-base/core/form-handler/models';
import {isRestEntity, isRestType, RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import * as entityActions from '@app/sam-base/core/store/actions/entity.actions';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppcde, Ppcli, Ppmis} from '@app/sam-base/models/placement';

import {ScheduleEvent} from '@app/sam-base/models/placement/schedule-event';
import {Actions, ofType} from '@ngrx/effects';
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {EntitiesToUpdateEnum, EntityUpdateService} from '../../services/entity-update.service';
import {MatchingService} from '../../services/matching.service';
import {cdetempoTabsFormControls} from './cdetempo-tabs-formcontrols';

@Component({
    templateUrl: './cdetempo.component.html',
    standalone: false
})
export class CdetempoComponent extends BaseTabFormComponent<Ppcde> implements OnInit, OnDestroy {

    public get cdeId() {
        return this.getFormValue('cdeId') || '';
    }

    public get tabData(): Partial<Ppcde> | undefined {
        return this.getFormData();
    }

    public set tabData(v: Partial<Ppcde> | undefined) {
        this.mergeEntityChanges(v);
    }

    public get commandeProfession() {
        return this._commandeProfession;
    }

    public clientName = '';

    private subscriptions = new Subject();

    private readonly _ppcliRest: RestEntityClient<Ppcli>;
    private entityModifiable = false;
    private _commandeProfession = '';

    constructor(store: IwStoreService, private _restService: RestApiService, private _updates: Actions, private readonly _events: IwEventHubService<string>, private _entityUpdateService: EntityUpdateService, private _actions: Actions, private _pkwService: PpkwService, private _entityNavService: EntityNavigationService, private _ppempService: PpempService, private readonly _matchingService: MatchingService) {
        super(store);
        this._ppcliRest = this._restService.getEntityClient(Ppcli);
        this.setNeedSummaryAndNatelValidators();
    }

    public ngOnInit() {
        if (this.getFormValue('cdeId')) {
            this.canUpdateCde(this.getFormValue('cdeId'));
        }
        this.subscribeCliId();
        this.subscribeCommandeClose();
        this.subscribeDeleteSuccess();
        this.subscribeProfession();
        this.subscribeValueChange<string>('cdeId', e => {
            this.canUpdateCde(e);
            this.updateScheduleEvent(e);
        });
        this.subscribeValueChange<string>('needSummary', e => this.onNeedSummaryChange(e));
        this.subscribeToSaveEffect();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getTabsValues(): TabFormControl<Ppcde>[] {
        return cdetempoTabsFormControls;
    }


    public openClient() {
        this._entityNavService
            .navigateToEntityForm(Ppcli, this.getFormValue('cliId'));
    }

    // TslintcdeStatus:disable-next-line: cyclomatic-complexity
    public getCdeClass() {
        let cdeStatus = this.getFormValue('cdestatus');
        if (cdeStatus === 0) {
            cdeStatus = '0';
        } else if (cdeStatus === 1) {
            cdeStatus = '1';
        } else if (cdeStatus === 2) {
            cdeStatus = '2';
        } else {
            cdeStatus = 'd';
        }
        return 'cde-color-' + cdeStatus;
    }

    protected getValidationType() {
        return Ppcde;
    }

    protected getFormControlNames(): FormKeys<Ppcde> {
        return this.getTabsFormControls(cdetempoTabsFormControls);
    }

    protected validateReadonlyStatus() {
        return this.getFormValue('cdestatus') === 2 || !this.entityModifiable;
    }

    protected onNeedSummaryChange(summary?: string) {
        if (summary) {
            return;
        }
        const cdeId: string | undefined = this.getFormValue('cdeId');
        if (!cdeId) {
            return;
        }
        this.updateScheduleEvent(cdeId);
    }

    protected updateScheduleEvent(entityId?: string) {
        const schedEvQuery: RestQueryParam<ScheduleEvent, any>[] = [{
            operation: RestQueryOperation.Equals, prop: 'parentType', value: 'ppcde'
        }, {
            operation: RestQueryOperation.Equals, prop: 'parentId', value: entityId
        }];
        this._restService.getEntityQuery(ScheduleEvent, ...schedEvQuery)
            .scroll()
            .subscribe((elem) => {
                if (!elem.length) {
                    return;
                }
                this.setFormValue('needSummary', this._matchingService.buildNeedSummary(elem[0]));
            });
    }

    private subscribeProfession() {
        this.getFormControl('qualif')
            ?.valueChanges
            .pipe(debounceTime(200), takeUntil(this.subscriptions))
            .subscribe((quaIds: string | undefined) => {
                if (quaIds) {
                    this.setProfessionName(quaIds);
                } else {
                    this._commandeProfession = '';
                }
            });
    }

    private async setProfessionName(quaIds: string) {

        if (!quaIds) {
            return;
        }

        try {
            this._commandeProfession = await lastValueFrom(this._ppempService.getProfessionName(quaIds)) ?? '';
        } catch {
        }

    }

    private subscribeCommandeClose(): void {
        this._events.forType<string>('commande_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((e) => {
                if (this.cdeId === e.payload) {
                    this._store.dispatch(new globalActions.LoadEntity(this.uuid));
                }
            });
    }

    private subscribeDeleteSuccess(): void {
        this._updates.pipe(ofType(entityActions.ENTITY_DELETE_SUCCESS))
            .pipe(takeUntil(this.subscriptions))
            .subscribe((action: entityActions.EntityDeleteSuccess<Ppmis>) => {
                const instance = isRestType(action.entityType) ? new action.entityType() : action.entityType;
                if (isRestEntity(instance) && instance.$entity === 'ppmis') {
                    if (this.cdeId === instance.cdeId) {
                        this._store.dispatch(new globalActions.LoadEntity(this.uuid));
                    }
                }
            });
    }

    private subscribeCliId(): void {
        if (this.formGroup) {
            this.formGroup.controls['cliId'].valueChanges
                .pipe(takeUntil(this.subscriptions))
                .subscribe((cliId: string | undefined) => {
                    if (!cliId) {
                        return;
                    }
                    this._ppcliRest.getById(cliId)
                        .subscribe((client: Ppcli) => {
                            if (!client.nom) {
                                return;
                            }
                            this.clientName = client.nom;
                        });
                });
        }
    }

    private setNeedSummaryAndNatelValidators() {
        this.formGroup.controls['needSummary'].setValidators(Validators.required);
        this.formGroup.controls['needSummary'].updateValueAndValidity();
        this.formGroup.controls['natel']
            .setValidators(Validators.pattern(/^\+(?:[0-9]{1,3} ?)[0-9]{6,14}$/));
        this.formGroup.controls['natel'].updateValueAndValidity();
    }

    private canUpdateCde(cdeId?: string) {
        if (!cdeId) {
            return;
        }
        this._entityUpdateService.canUpdateEntity(EntitiesToUpdateEnum.PPCDE, this.getFormValue('cdeId'))
            .subscribe((flag: boolean) => {
                this.entityModifiable = flag;
                this.triggerValidation();
            });
    }

    private subscribeToSaveEffect() {
        this._actions.pipe(takeUntil(this.subscriptions), ofType(globalActions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe(e => {
                const uuid = e['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }

                const keywords = this._pkwService.store[uuid] || [];
                this._pkwService.saveKeywords(this.cdeId || '', keywords, 'ppcde')
                    .subscribe();
            });
    }
}
