<form [formGroup]="formGroup"
      class="row">
    <!-- 1st Column -->
    <div class="row col-xs-12 space-form separator-right">
        <div class="form-label col-xs-12 padding-top-10">
            {{ 'permis' | translate }}
        </div>
        <iw-dropdown #cboPermis
                     [isDisabled]="isReadonly || !hasPermis"
                     [labelAlign]="'left'"
                     [label]="'type' | translate"
                     [options]="allPermis"
                     class="iw-input col-xs-9 padding-top-10"
                     formControlName="permis"></iw-dropdown>
        <iw-textfield #txtpermis
                      [isDisabled]="true"
                      [value]="getFormValue('permis')"
                      class="iw-input col-xs-3 padding-top-10"></iw-textfield>

        <iw-textfield #txtPermisno
                      [isDisabled]="isReadonly || !hasPermisFilled"
                      [labelAlign]="'left'"
                      [label]="'number' | translate"
                      class="iw-input col-xs-10 padding-top-10"
                      formControlName="permisno"></iw-textfield>

        <iw-date-picker #txtPerm_dtde
                        [isDisabled]="isReadonly || !hasPermisFilled"
                        [labelAlign]="'left'"
                        [label]="'validiteDuAu' | translate"
                        class="iw-input col-xs-7 padding-top-10"
                        formControlName="permDtde"></iw-date-picker>

        <iw-date-picker #txtPerm_dta
                        [isDisabled]="isReadonly || !hasPermisFilled"
                        [labelAlign]="'left'"
                        class="iw-input col-xs-3 padding-top-10"
                        formControlName="permDta"></iw-date-picker>

        <iw-textfield #txtPermisno
                      [isDisabled]="isReadonly || !hasPermisFilled"
                      [labelAlign]="'left'"
                      [label]="'delivre' | translate"
                      class="iw-input col-xs-10 padding-top-10"
                      formControlName="permLieu"></iw-textfield>
    </div>

    <!-- Notes Permis -->
    <div class="row col-xs-12 space-form separator-right">
        <div class="form-label col-xs-12 padding-top-10 padding-bottom-10">
            {{ 'notesPermis' | translate }}
        </div>
        <iw-textarea #txtnotes
                     [height]="100"
                     [height]="120"
                     [isDisabled]="isReadonly"
                     class="iw-textarea col-xs-12 padding-top-15"
                     formControlName="permNote"></iw-textarea>
    </div>
</form>
