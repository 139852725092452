import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SapardedService} from '@app/sam-base/core/services/saparded.service';
import {Saparded} from '@app/sam-base/models/salary';

@Component({
    selector: 'iw-tab-saparded-informations', templateUrl: './tab-saparded-informations.component.html',
    standalone: false
})
export class TabSapardedInformationsComponent extends BasePartialFormComponent<Saparded> implements OnInit {
    public result: string | undefined;

    constructor(public readonly sapardedService: SapardedService) {
        super();
    }

    public ngOnInit() {
        const dedCode: string | undefined = this.getFormValue('dedCode');
        this.getTextSalary(dedCode);
        this.subscribeValueChange('dedCode', (id: string | undefined) => {
            if (id) {
                this.getTextSalary(id);
            }
        });
    }

    public getTextSalary(dedCode: string | undefined) {
        if (dedCode) {
            this.sapardedService.getInfoText(dedCode)
                .subscribe((data: string) => {
                    this.result = data;
                });
        }
    }
}
