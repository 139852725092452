<form [formGroup]="formGroup"
      autocomplete="no"
      class="row spaceForm">
    <div class="form-host row col-xs-12">
        <iw-checkbox #chkExpFac2Ij
                     [isDisabled]="isReadonly"
                     [label]="'facSendToIJ' | translate"
                     class="iw-input col-xs-12"
                     formControlName="lexpfac2ij"></iw-checkbox>
        <iw-checkbox #chkIj
                     [isDisabled]="isReadonly"
                     [label]="'facValidation' | translate"
                     class="iw-input col-xs-12"
                     formControlName="ijFacMail">
        </iw-checkbox>
    </div>
</form>