import {HttpClient} from '@angular/common/http';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {MessageFormRefreshService} from '@app/sam-base/components/subjects/message-service-subject.service';
import {EntityNavigationService, PpkwService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys} from '@app/sam-base/models';
import {Localetypes} from '@modules/sam-main/admin/components/sam-user-admin/locale-enums';
import {Actions} from '@ngrx/effects';
import {environment} from '@root/environments/environment';
import {SamUser} from '@sam-base/models/admin/sam-user';

@Component({
    templateUrl: './user-reset-password.component.html',
    standalone: false
})
export class UserResetPasswordComponent extends BaseStoreFormComponent<SamUser> implements OnInit, OnDestroy {
    public closeDisabled = false;
    public delete = false;
    public isLoading = false;
    public password?: string;
    public confirmPassword?: string;
    public options = Localetypes;

    constructor(store: IwStoreService, private _restService: RestApiService, private _actions: Actions,
                private _pkwService: PpkwService, private _entityNavService: EntityNavigationService,
                private readonly _http: HttpClient,
                private readonly refreshFormMessageService: MessageFormRefreshService,
                private readonly _toast: ToastService, protected readonly _store: IwStoreService) {
        super(store);
    }

    @Input()
    public set entityId(v: string | undefined) {
        if (v) {
            this.setEntityId(v);
        }
    }

    public get id() {
        return this.getFormValue('id') || '';
    }

    public get isFormFilled(): boolean {
        const body = this.createPayload();
        return this.isPasswordFilled(body);
    }

    public ngOnInit() {
        const samUserData: string | undefined = this.getData('id');
        if (samUserData) {
            this.setFormValue('id', samUserData);
        }
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
    }

    public resetPassword() {
        this.isLoading = true;
        const body = this.createPayload();

        if (body.password !== body.confirmPassword) {
            this._toast.error('passwords_nomatch');
            this.isLoading = false;
            return;
        }
        return this._http.put<SamUser>(environment.backendURL + `sam-user/${body.id}/resetpw`, body)
            .subscribe(resp => {
                this._toast.success('user_pwreset_success');
                this.isLoading = false;
                this.destroyForm();
            }, (err) => {
                this._toast.error('user_pwreset_error');
                this.isLoading = false;
            });
    }

    protected getFormControlNames(): FormKeys<SamUser> {
        return [
            'id',
            [
                'password',
                new UntypedFormControl(undefined, Validators.required)],
            [
                'confirmPassword',
                new UntypedFormControl(undefined, Validators.required)]];
    }

    protected getValidationType() {
        return SamUser;
    }

    private setEntityId(v: string) {
        if (v !== this.id) {
            this.setFormValue('id', v);
        }
    }

    private setFormValues(userDt: SamUser) {
        const userData: SamUser = this.createUserFromData(userDt);
        this.setFormValue('id', userData.id);
    }

    private createUserFromData(userData: SamUser) {
        const body: SamUser = new SamUser();

        body.id = userData.id;
        body.username = userData.username;
        body.userId = userData.userId;
        body.tenant = userData.tenant;
        body.email = userData.email;
        body.firstName = userData.firstName;
        body.lastName = userData.lastName;
        body.locale = userData.locale;
        body.gestions = userData.gestions;
        return body;
    }

    private createPayload() {
        const body: SamUser = new SamUser();

        body.id = this.getFormValue('id');
        body.password = this.getFormValue('password');
        body.confirmPassword = this.getFormValue('confirmPassword');
        return body;
    }

    private isPasswordFilled(body: SamUser) {
        return !!body.password && !!body.confirmPassword;
    }

}
