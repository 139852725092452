import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';


@IwRestEntity('sasx4dec')
export class Sasx4dec {
    @IwColumn({
        index: 0,
        width: 80
    }) @IwRestEntityId() public sxId?: string;
    public decstatus?: string;
    public sxDate?: string;
    public sxTime?: string;
    public xmlStatus?: string;
    public sxType?: string;
    public txfStatus?: string;
    public annee?: string;
    public salId?: string;
    public sxEiv?: number;
    public sxTest?: number;
    public sxSubst?: number;
    public sxMerge?: number;
    public mergedef?: string;
    public whatsinxml?: string;
    public whatsinxm2?: string;
    public nbemp?: number;
    public nberrsam?: string;
    public cerrsam?: string;
    public nbwarnsam?: number;
    public cwarnsam?: string;
    public nberrxml?: number;
    public cerrxml?: string;
    public nbemaavs?: number;
    public cemaavs?: string;
    public nbemais?: number;
    public cemais?: string;
    public xmlfname?: string;
    public xmlsfname?: string;
    public xmlscfname?: string;
    public eivfname?: string;
    public note?: string;
    public sxMode?: string;
    public sxFolder?: string;
    public sxFname?: string;
    public sxXml?: string;
    public sxLsoap?: number;
    public sx_soap?: string;
    public sxRsoap?: string;
    public sxError?: string;
    public sxReqid?: string;
    public sxJobkey?: string;
    public sxDeclid?: string;
    public declresult?: string;
    public declrespid?: string;
    public statresult?: string;
    public statplaus?: string;
    public statrespid?: string;
    public rsltresult?: string;
    public rsltdata?: string;
    public rsltrespid?: string;
    public sxFinish?: number;
    public sxLibere?: number;
    public sxidPrev?: string;
    public sxidNext?: string;
    public sxParlog?: string;
    public sxLog?: string;
    public sxData?: string;
    public sdversion?: number;
    public urlmode?: number;
    public dateCreat?: string;
    public dateModif?: string;
    public dateDelet?: string;
    public dateTrack?: string;
}