<form [formGroup]="formGroup"
      class="row">
    <div class="row col-xs-12 form-label no-padding">
        <div class="col-xs-6 flex flex-center no-padding-right">
            <hr class="header-line">
            <div class="header-line-color">{{ 'recap' | translate }}</div>
            <hr class="header-line">
        </div>
        <div class="col-xs-6 flex flex-center no-padding-left">
            <hr class="header-line">
            <div class="header-line-color">{{ 'acompte_form_title' | translate }}</div>
            <hr class="header-line">
        </div>
    </div>

    <div class="col-xs-12 padding-top-20"></div>

    <div class="row col-xs-6 separator-right no-padding">
        <iw-textfield #txtnbheutot
                      [isDisabled]="true"
                      [value]="getFormValue('nbheutot') | number : '1.2-2'"
                      class="iw-input col-xs-4 no-padding-right"
                      formControlName="nbheutot"></iw-textfield>


        <iw-textfield #txtNbheuequip
                      [isDisabled]="true"
                      [value]="getFormValue('nbheuequip') | number : '1.2-2'"
                      class="iw-input col-xs-4 no-padding"
                      formControlName="nbheuequip"></iw-textfield>


        <iw-textfield #txtnbheuequix
                      [isDisabled]="true"
                      [value]="getFormValue('nbheuequix') | number : '1.2-2'"
                      class="iw-input col-xs-4 no-padding-left"
                      formControlName="nbheuequix"></iw-textfield>

        <div class="col-xs-12 padding-top-10"></div>

        <iw-textfield #Standardfield9
                      [isDisabled]="true"
                      [value]="'employee' | translate"
                      class="iw-input emphasis-disabled col-xs-4 col-xs-offset-4 no-padding"></iw-textfield>


        <iw-textfield #Standardfield10
                      [isDisabled]="true"
                      [value]="'client' | translate"
                      class="iw-input emphasis-disabled col-xs-4 no-padding-left"></iw-textfield>

        <iw-textfield #Standardfield11
                      [isDisabled]="true"
                      [value]="'frs_heure' | translate"
                      class="iw-input emphasis-disabled col-xs-4 no-padding-right"></iw-textfield>
        <iw-textfield #Standardfield1
                      [isDisabled]="true"
                      [value]="mission.salTotal"
                      class="iw-input col-xs-4 no-padding"
                      stepValue="0.01"
                      type="number"></iw-textfield>
        <iw-textfield #Standardfield2
                      [isDisabled]="true"
                      [value]="mission.clitarif"
                      class="iw-input col-xs-4 no-padding-left"
                      stepValue="0.01"
                      type="number"></iw-textfield>

        <iw-textfield #Standardfield12
                      [isDisabled]="true"
                      [value]="'hFrs' | translate"
                      class="iw-input emphasis-disabled col-xs-4 no-padding-right"></iw-textfield>
        <iw-textfield #txtMntheuemp
                      [isDisabled]="true"
                      class="iw-input col-xs-4 no-padding"
                      formControlName="mntheuemp"
                      stepValue="0.01"
                      type="number"></iw-textfield>
        <iw-textfield #txtMntheucli
                      [isDisabled]="true"
                      class="iw-input col-xs-4 no-padding-left"
                      formControlName="mntheucli"
                      stepValue="0.01"
                      type="number"></iw-textfield>

        <iw-textfield #Standardfield14
                      [isDisabled]="true"
                      [value]="'indFrs' | translate"
                      class="iw-input emphasis-disabled col-xs-4 no-padding-right"></iw-textfield>
        <iw-textfield #Standardfield5
                      [isDisabled]="true"
                      class="iw-input col-xs-4 no-padding"
                      formControlName="mntindemp"
                      stepValue="0.01"
                      type="number"></iw-textfield>
        <iw-textfield #Standardfield6
                      [isDisabled]="true"
                      class="iw-input col-xs-4 no-padding-left"
                      formControlName="mntindcli"
                      stepValue="0.01"
                      type="number"></iw-textfield>

        <iw-textfield #Standardfield14
                      [isDisabled]="true"
                      [value]="'totalFrs' | translate"
                      class="iw-input emphasis-disabled col-xs-4 no-padding-right"></iw-textfield>
        <iw-textfield #Standardfield7
                      [isDisabled]="true"
                      [value]="totalMntemp"
                      class="iw-input col-xs-4 no-padding"
                      stepValue="0.01"
                      type="number"></iw-textfield>
        <iw-textfield #Standardfield8
                      [isDisabled]="true"
                      [value]="totalMntcli"
                      class="iw-input col-xs-4 no-padding-left"
                      stepValue="0.01"
                      type="number"></iw-textfield>

        <div class="div col-xs-12 padding-top-20"></div>

        <iw-textfield #txtChantier
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'chantier' | translate"
                      class="iw-input col-xs-10"
                      formControlName="chantier"></iw-textfield>

        <iw-button #btnModCha
                   (press)="setNewChantier()"
                   [isDisabled]="!getFormValue('facId')"
                   [text]="'TEXT' | translate"
                   class="iw-button col-xs-2"></iw-button>

        <iw-checkbox #chkFinMission
                     [isDisabled]="true"
                     [labelAlign]="'right'"
                     [label]="'laMissionEstTerminee' | translate"
                     class="iw-input col-xs-12  padding-top-10"
                     formControlName="finmission"></iw-checkbox>

        <iw-textfield #txtFinMotif
                      [isDisabled]="!getFormValue('finmission')"
                      [labelAlign]="'left'"
                      [label]="'motif' | translate"
                      class="iw-input col-xs-12  padding-top-10"
                      formControlName="finmotif"></iw-textfield>
    </div>


    <div class="row col-xs-6 no-padding">
        <iw-textfield #txtAco_id
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'numero' | translate"
                      [value]="acompte.acoId"
                      class="iw-input col-xs-6"></iw-textfield>

        <iw-checkbox #Standardlogic3
                     [isDisabled]="true"
                     [label]="'pasDacompte' | translate"
                     class="iw-input col-xs-6"
                     formControlName="lnoaco"></iw-checkbox>

        <iw-textfield #txtAcoMontant
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'montant' | translate"
                      [value]="acompte.montant"
                      class="iw-input col-xs-12"></iw-textfield>

        <iw-textfield #txtAcoDate
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'payeLe' | translate"
                      [value]="acompte.acodate"
                      class="iw-input col-xs-12"></iw-textfield>

        <iw-textfield #txtNoCheque
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'noCheque' | translate"
                      [value]="acompte.nocheque"
                      class="iw-input col-xs-12"></iw-textfield>

        <div class="col-xs-12 row separator-top">
            <div class="col-xs-12 padding-top-10"></div>
            <iw-checkbox #Standardlogic1
                         [isDisabled]="true"
                         [label]="'bill' | translate"
                         class="iw-input col-xs-4 no-padding-right"
                         formControlName="sent2Fac"></iw-checkbox>

            <iw-textfield #txtFac_no
                          [isDisabled]="true"
                          [tooltip]="'invoiceNumber' | translate"
                          class="iw-input col-xs-4 no-padding"
                          formControlName="facNo"></iw-textfield>


            <iw-textfield #txtFac_date
                          [isDisabled]="true"
                          class="iw-input col-xs-4 no-padding-left"
                          formControlName="facDate"></iw-textfield>

            <iw-textfield #txtFac_id
                          [isDisabled]="true"
                          [tooltip]="'idFacture' | translate"
                          class="iw-input col-xs-4 col-xs-offset-4 no-padding"
                          formControlName="facId"></iw-textfield>

            <iw-button #btnFac
                       (press)="openInvoicePreview()"
                       *iwAccessGroups="INVOICE_USER_ROLES"
                       [isDisabled]="!canOpenInvoicePreview"
                       [text]="'facture' | translate"
                       class="iw-button col-xs-4 no-padding-left"></iw-button>

            <iw-textarea #edtfaclintxt
                         [height]="22"
                         [isDisabled]="true"
                         class="iw-textarea col-xs-12 padding-top-10"
                         formControlName="faclintxt"></iw-textarea>
        </div>
        <div class="col-xs-12 row separator-top">
            <div class="col-xs-12 padding-top-10"></div>

            <iw-checkbox #Standardlogic2
                         [isDisabled]="true"
                         [label]="'salary' | translate"
                         class="iw-input col-xs-6"
                         formControlName="sent2Sal"></iw-checkbox>

            <iw-button #btnSal
                       (press)="openSalaryPreview()"
                       *iwAccessGroups="SALARY_USER_ROLES"
                       [isDisabled]="!canOpenSalaryPreview"
                       [text]="'salaire' | translate"
                       class="iw-button col-xs-6"></iw-button>

        </div>
    </div>
</form>
