import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {PpempService} from '@app/modules/sam-main/placement/services/ppemp.service';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Bank} from '@app/sam-base/models/common';
import {Ppemp} from '@app/sam-base/models/placement';
import {DtaType, PayType} from '@sam-base/models/salary/payment-type-enum';
import {IwTextfieldBankComponent} from '@shared/widgets/form-components/iw-textfield-bank/iw-textfield-bank.component';


/**
 * Component created regarding SAM-4349
 */
@Component({
    selector: 'iw-salaire-paiement',
    templateUrl: './salaire-paiement.component.html',
    standalone: false
})
export class SalairePaiementComponent extends BasePartialFormComponent<Ppemp> implements OnInit {

    @ViewChild('txtGenretrav', {static: false}) public clearingTxt?: IwTextfieldBankComponent;
    // Enum with available values in dropdown Paiement
    public paysType = PayType;
    // Enum with available values in dropdown Dta/Opae
    public dtaType = DtaType;
    protected readonly PayType = PayType;

    constructor(private _ppempService: PpempService) {
        super();
    }

    @Input()
    public set entityId(v: string) {
        this.updateIbanValidator();
    }

    public ngOnInit() {
        this.updateIbanValidator();
        this.subscribeValueChange('paytype', () => this.payTypeChanges(), 200);
        this.subscribeValueChange('dtatype', () => this.dtaTypeChanges(), 200);
        this.subscribeValueChange('iban', () => this.removeNonIbanCharacters(), 200);
    }

    public onSelected(e: Bank) {
        // this.setFormValue('banqueAdr', `${e.lieu} ${e.npa}`);
        this.setFormValue('banqueAdr', e.postalAddress);
        this.setFormValue('banqueClr', e.iid);
        this.setFormValue('banqueSwi', e.bic);
    }

    /**
     * Change fielda when paytype change
     */
    public payTypeChanges() {
        if (this.isReadonly) {
            return;
        }
        const pay = this.getFormValue<number>('paytype');
        if (pay === PayType.dta) {
            this.setFormValue('dtatype', 5);
        } else {
            this.setFormValue('dtatype', 0);
            this.setFormValue('banque', '');
            this.setFormValue('banqueAdr', '');
            this.setFormValue('banqueClr', '');
            this.setFormValue('banqueCpt', '');
            this.setFormValue('banqueSwi', '');
        }
    }

    /**
     * Change fields when dtatype change
     */
    public dtaTypeChanges() {
        if (this.isReadonly) {
            return;
        }
        const dta = this.getFormValue<number>('dtatype');
        if (dta === DtaType.ccp) {
            this.setFormValue('banque', 'CCP');
            this.setFormValue('banqueAdr', '');
            this.setFormValue('banqueClr', '');
            this.setFormValue('banqueCpt', '');
            this.setFormValue('banqueSwi', '');
        }
        if (dta === DtaType.banque || dta === DtaType.ccp) {
            this.formGroup?.controls['banqueCpt'].setValidators(Validators.required);
            this.formGroup?.controls['banqueCpt'].updateValueAndValidity();
        } else {
            this.formGroup?.controls['banqueCpt'].clearValidators();
            this.formGroup?.controls['banqueCpt'].updateValueAndValidity();
        }
        this._ppempService.checkIbanValidators(this.formGroup, this.getFormValue('paytype'), this.getFormValue('dtatype'));
    }

    /**
     * Check if field dtatype is enabled
     */
    public checkDtaTypeEnabled() {
        const pay = this.getFormValue<number>('paytype');
        return !this.isReadonly && pay === 3;
    }

    /**
     * Check if fields are enabled regarding paytype and dtatype value
     *
     * @param pay value of paytype needed to validate field
     * @param dta value of dtatype needed to validate field
     */
    // eslint-disable-next-line complexity
    public checkFieldsEnabled(pay: number[], dta: number[]): boolean {
        const payValue = this.getFormValue<number>('paytype') || -1;
        const dtaValue = this.getFormValue<number>('dtatype') || -1;
        if (pay && dta) {
            return !this.isReadonly && pay.includes(payValue) && dta.includes(dtaValue);
        }
        if (pay) {
            return !this.isReadonly && pay.includes(payValue);
        }
        if (dta) {
            return !this.isReadonly && dta.includes(dtaValue);
        }
        return false;
    }

    public async clearingsClick() {
        if (!this.clearingTxt) {
            return;
        }
        this.clearingTxt.onDoubleClick();
    }

    private updateIbanValidator() {
        this._ppempService.checkIbanValidators(this.formGroup, this.getFormValue('paytype'), this.getFormValue('dtatype'));
    }

    private removeNonIbanCharacters() {
        if (this.isReadonly) {
            return;
        }
        const iban = this.getFormValue<string>('iban');
        // Removing non-alphanumeric chars + upper case
        // Src : https://github.com/arhs/iban.js/issues/72 lib accepts lower case and / sometimes
        this.setFormValue('iban', iban?.replace(/[^a-zA-Z0-9]/g, '')
            .toUpperCase());
    }
}
