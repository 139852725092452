export class PaymentForm {
    public acoId?: string;
    public empId?: string;
    public cliId?: string;
    public conId?: string;
    public salId?: string;
    public cpyfrommdl?: boolean;
    public createmodel?: boolean;
    public mismdlId?: string;
    public bankId?: string;
    public cpainavisOpt?: string;
    public dateExec?: string;
    public libelle?: string;
    public bank?: string;
    public bankClr?: string;
    public bankCpt?: string;
    public npaytype?: string;
}
