import {formatDate} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {EntityNavigationService, EsQueryStatement, IwEventHubService} from '@app/sam-base/core';
import {GepaicliService} from '@app/sam-base/core/services/gepaicli.service';
import {GepaihdrService} from '@app/sam-base/core/services/gepaihdr.service';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {FormKeys, IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {Gepaiele} from '@app/sam-base/models/invoices/gepaiele';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';
import {NotZeroValidator} from '@sam-base/core/validations/not-zero.validators';
import {Gecliview} from '@sam-base/models/invoices/gecliview';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {GepaieleView} from '@sam-base/models/invoices/gepaiele.view';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';


@Component({
    templateUrl: './encaissement.component.html',
    standalone: false
})
export class EncaissementComponent extends BaseStoreFormComponent<Gepaihdr> implements OnInit, OnDestroy {

    public clientType = Gecliview;

    public subscriptions = new Subject();

    @ViewChild('gepaieleGrid', {static: true}) public gepaieleGrid?: IwRestGridComponent<Gepaiele>;
    public query: EsQueryStatement<GepaieleView>[] = [
        EsQueryStatement.fromTerm({payId: '_'}, 'must')];
    public gepaieleView = GepaieleView;
    columnsGepaieleView: IwGridColumn<GepaieleView>[] = [
        {
            prop: 'nom',
            name: 'client',
            index: 0
        },
        {
            prop: 'facNo',
            name: 'facNo',
            index: 1
        },
        {
            prop: 'montant',
            name: 'montant',
            index: 2,
            align: 'right',
            type: 'mnt'
        },
        {
            prop: 'compte',
            name: 'compte',
            index: 3
        },
        {
            prop: 'lettrage',
            name: 'lettrage',
            index: 4
        },
        {
            prop: 'origine',
            name: 'origine',
            index: 5
        },
    ];
    private entityModifiable = false;

    constructor(store: IwStoreService, private _gepaihdrService: GepaihdrService,
                private _parameterService: ParametersService,
                private _gepaicliService: GepaicliService, private _encaissementService: EncaissementService,
                private _modalService: ModalService, private _translateService: TranslateService,
                private _events: IwEventHubService<EncaissementEvents>,
                private readonly _entityNavService: EntityNavigationService) {
        super(store);
    }

    public get payId(): string {
        return this.getFormValue('payId');
    }

    public get montant(): number {
        return this.getFormValue('montant');
    }

    public get solde(): number {
        return this.getFormValue('solde');
    }

    public get cptaref(): boolean {
        return !!this.getFormValue('cptaRef') && this.isReadonly;
    }

    public get status() {
        return `encaissement-color-${this.getFormValue('payStatus')}`;
    }

    public ngOnInit() {
        if (this.isNew) {
            this.initDataForNewEntry();
        }
        this.applyValidators();
        this.subscribeValueChange('payId', () => {
            this.setQuery();
        });

        this.subscribeCalculateBtn();
        this.subscribeAttribuerBtn();
        this.subscribeDesattribuerBtn();
    }

    public ngOnDestroy(): void {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public async deleteSolde(payId: string | undefined): Promise<void> {
        if (payId !== undefined || payId === '') {
            try {
                await this._modalService.showModal(ModalMessageComponent, this.deleteSoldeModalOptions());
                this._encaissementService.deleteSolde(payId);
                this._gepaihdrService.deleteSolde(payId)
                    .subscribe(() => {
                        const value = this.montant - this.solde;
                        this.setFormValue('montant', value);
                        this.setFormValue('solde', 0);
                    });
            } catch (error) {
            }
        }
    }

    public getValidationType() {
        return Gepaihdr;
    }

    onSelected(rows: Gepaiele[]) {
        this._events.emit(EncaissementEvents.elementSelected, rows[0]);
    }

    openFacture($event: RowClickEvent<GepaieleView>) {
        const facId = $event.row.facId;
        if (!facId) return;
        this._entityNavService
            .navigateToEntityForm(Gefacliview, facId);
    }

    protected getFormControlNames(): FormKeys<Gepaihdr> {
        return [
            'payId',
            'payStatus',
            'mpaId',
            'cliId',
            'datePay',
            'montant',
            'frais',
            'solde',
            'bvrId',
            'cptaRef'
        ];
    }

    private initDataForNewEntry() {
        this.setFormValue('origine', '1');
        this.setFormValue('payStatus', '0');
        this.setFormValue('datePay', formatDate(new Date(), 'yyyy-MM-dd', 'en'));
        this._parameterService.getGepar()
            .subscribe(gepar => {
                this.setFormValue('tvaPeriode', gepar.tvaperiode);
            });
    }

    private setQuery() {
        if (!this.payId) {
            return;
        }

        this._gepaicliService.getGepaicli(this.payId)
            .subscribe((gepaicli) => {
                this.query = [
                    EsQueryStatement.fromTerm({payId: this.payId}, 'must')];
            });
    }

    private subscribeCalculateBtn() {
        this._events.forType(EncaissementEvents.reloadPaiement)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                this._store.dispatch(new actions.LoadEntity(this.uuid));
                this.gepaieleGrid?.refresh();
            });
    }

    private subscribeAttribuerBtn() {
        this._events.forType(EncaissementEvents.saveAssign)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                this._store.dispatch(new actions.LoadEntity(this.uuid));
                this.setQuery();
                this.gepaieleGrid?.refresh();
            });
    }

    private subscribeDesattribuerBtn() {
        this._events.forType(EncaissementEvents.disociate)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(() => {
                this._store.dispatch(new actions.LoadEntity(this.uuid));
                this.setQuery();
                this.gepaieleGrid?.refresh();
            });
    }

    private deleteSoldeModalOptions() {
        return {
            message: [this._translateService.instant('supprimerSolde')],
            showCancel: true,
            title: 'Supprimer',
            width: 400
        };
    }

    private applyValidators() {
        let amountControl = this.getFormControl('montant');
        amountControl?.addValidators(NotZeroValidator());
        amountControl?.updateValueAndValidity();
    }
}
