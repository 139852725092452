import {HttpClient} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {SortInfo} from '@app/sam-base/components';
import {IwRestGridComponent} from '@app/sam-base/components/iw-rest-grid/iw-rest-grid.component';
import {EntityCreateSuccess, EntityUpdateSuccess, ModalService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {PpconService} from '@app/sam-base/core/services/ppcon.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppcon} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {TeamService} from '@sam-base/core/services/team.service';
import {ToastService} from '@sam-base/core/toast';
import {Team} from '@sam-base/models/admin/team.model';
import {PpconData} from '@sam-base/models/placement/ppconData';
import {ListItem} from '@shared/widgets/form-components/list-box/list-item.model';
import {ModalConsChangeComponent} from '@shared/widgets/modal-components/modal-cons-change/modal-cons-change';
import {ModalConsChangeOptions} from '@shared/widgets/modal-components/modal-cons-change/modal-cons-change.options';
import lodash, {Dictionary} from 'lodash';
import {FileUpload} from 'primeng/fileupload';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Cpolitesse} from '../employee/tab-ppemp-general/tab-ppemp-general-enums';
import {SignatureComponent} from '../signature/signature.component';

@Component({
    templateUrl: './ppcon.component.html',
    styleUrls: ['./ppcon.component.scss'],
    standalone: false
})
export class PpconComponent extends BaseFormComponent<Ppcon> implements OnInit {

    public cpolitesse = Cpolitesse;
    public type = Ppcon;
    public isReadonly = true;
    public newEntryMode = false;
    public conId = '';
    public selectedRow?: Ppcon;
    public isSaving = false;
    public isLoading = false;
    public sortProp?: keyof Ppcon;
    public sortPropDir?: 'asc' | 'desc';
    public noSignature = true;
    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppcon, string>[] = [
        {
            prop: 'conId',
            operation: RestQueryOperation.NotEquals,
            value: '_'
        }];
    public canLoadTable = false;
    public allTeams: Team[] = [];
    public teamsById: Dictionary<Team[]> = {};
    public actualTeamIds: number[] = [];
    public availableTeams: ListItem<number>[] = [];
    public actualTeams: ListItem<number>[] = [];
    private readonly _restPpconClient: RestEntityClient<Ppcon>;
    @ViewChild('ppconConfigGrid') private ppconGrid?: IwRestGridComponent<Ppcon>;
    @ViewChild('fileUploader') private fileUploader?: FileUpload;
    @ViewChild('fileUploaderEmail') private fileUploaderEmail?: FileUpload;

    constructor(private rest: RestApiService,
                private _store: IwStoreService,
                private _modalService: ModalService,
                private _translateService: TranslateService,
                private _http: HttpClient,
                private _ppconService: PpconService,
                private readonly _teamService: TeamService,
                private sanitizer: DomSanitizer,
                private _toast: ToastService) {
        super();
        this._restPpconClient = rest.getEntityClient(Ppcon);
        this.removeUnusedUsers();
        this._teamService.getAllTeams()
            .pipe(catchError(() => of([])))
            .subscribe(res => {
                this.allTeams = res.sort((a, b) => ((a.name || '') > (b.name || '')) ? 1 : -1);
                // convert this.allTeams to a map teamId -> Team object
                this.teamsById = lodash.groupBy(this.allTeams, 'teamId');
                // this.isLoading = false;
            });
    }

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {
            name: 'conId',
            value: this.selectedRow.conId
        };
    }

    public get isDeleteDisabled() {
        return !this.isReadonly || this.formGroup.invalid;
    }

    public get emailSignature() {
        return this.getFormValue('emailSignature');
    }

    public ngOnInit() {
        this.subscribeValueChange('conId', conId => {
            this.actualTeams = [];
            this.availableTeams = [];
            if (!conId) {
                return;
            }
            this.showSignature();
            this.fileUploader?.clear();
            this.fileUploaderEmail?.clear();
            this._ppconService.getTeamIdsByConseillers(conId as string).subscribe(teamIds => {
                this.actualTeamIds = teamIds
                this.actualTeams = teamIds.map(teamId => ({
                    label: this.teamsById[teamId][0]?.name || '',
                    value: teamId
                })) || [];
                this.availableTeams = this.allTeams.filter(team => !this.actualTeams.some(userTeam => userTeam.value === team.teamId)).map(val => ({
                    label: val.name || '',
                    value: val.teamId || 0
                }));
            });
        });
    }

    public showSignature() {
        const img = this.getFormValue('signfName');
        if (!img) {
            return '';
        }
        this.noSignature = false;
        return this.sanitizer
            .bypassSecurityTrustUrl('' + img);
    }

    public removeSignature() {
        this.setFormValue('signfName', undefined);
        this.noSignature = true;
    }

    public async addEmailSignature() {
        const sigText = await this._modalService.showModal(SignatureComponent, this.getFormValue('emailSignature'));
        this.setFormValue('emailSignature', sigText);
        this.noSignature = true;
    }

    public removeSignatureEmail() {
        this.setFormValue('emailSignature', undefined);
    }

    public removeUnusedUsers() {
        this.isLoading = true;
        this._ppconService.getRemoveUnlicensedUsers()
            .subscribe(() => {
                this.canLoadTable = true;
                this.isLoading = false;
                this.ppconGrid?.refresh();
            });
    }

    /**
     * Function to get selected row
     *
     * @param event Ppcon[]
     * @returns void
     */
    public getSelectedRow(event: Ppcon[]) {
        this.isReadonly = true;

        if (this.isSaving) {
            // Prevents clearing of the selectedRow
            this.isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this.conId = (event[0] && event[0].conId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    public onTeamListChange(teams: ListItem<number>[]) {
        this.actualTeamIds = teams.map(e => e.value);
    }

    /**
     * Function to create new ppcon entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
        this.fileUploader?.clear();
        this.fileUploaderEmail?.clear();
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public saveEntry() {
        const formData = this.getFormData();

        const ppconData = new PpconData();
        ppconData.teamIds = this.actualTeamIds;
        ppconData.ppcon = this._restPpconClient
            .construct({...this.selectedRow, ...formData});
        delete ppconData.ppcon.ppagence;

        this.isReadonly = true;
        this.clearForm();
        this.isSaving = true;
        if (this.newEntryMode) {
            this.setSortByModif();
            this._ppconService.createConseiller(ppconData).subscribe(savedPpconData => {
                this._store.dispatch(new EntityCreateSuccess(this._restPpconClient.construct(savedPpconData.ppcon!)));
                this.newEntryMode = false;
            });
        } else {
            this._ppconService.updateConseiller(ppconData).subscribe(savedPpconData => {
                this._store.dispatch(new EntityUpdateSuccess(this._restPpconClient.construct(savedPpconData.ppcon!)));
            });
        }
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.conId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
        this.fileUploader?.clear();
        this.fileUploaderEmail?.clear();
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public uploadDocument({files}: { files: File[] }, uploader: FileUpload) {
        if (files && files.length && files[0].name) {
            const file: File = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.setFormValue('signfName', reader.result);
                this.noSignature = false;
                uploader.clear();
            };
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    const img = new Image();
                    img.src = reader.result;
                    if (img.naturalHeight > environment.maxSignatureHeight || img.naturalWidth > environment.maxSignatureWidth) {
                        this.setFormValue('signfName', undefined);
                        this.noSignature = true;
                        this._toast.warning(this._translateService.instant('signature_max_dimensions_exceeded') + environment.maxSignatureWidth + 'x' + environment.maxSignatureHeight + 'px');
                    }
                }
            };
        }
    }

    public async openRemoveConsModal() {
        if (this.selectedRow && this.selectedRow.conId) {
            const options: ModalConsChangeOptions = {
                conId: this.selectedRow.conId,
                title: this._translateService.instant('replaceConseiller')
            };
            try {
                const newConId = await this._modalService.showModal(ModalConsChangeComponent, options);
                this.removeConseiller(this.selectedRow.conId, newConId);
            } catch (error) {
            }

        }
    }

    public setSortInfo(sortInfo: SortInfo<Ppcon>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    public validateFields(e?: Ppcon): boolean {
        return super.validateFields(e);
    }

    protected getValidationType() {
        return Ppcon;
    }

    protected getFormControlNames(): FormKeys<Ppcon> {
        return [
            'conId',
            'cpolitesse',
            'nom',
            'prenom',
            'ageId',
            'initiales',
            'fonction',
            'tel',
            'natel',
            'email',
            'signfName',
            'samuserId',
            'emailSignature'];
    }

    /**
     * Function to remove entry
     *
     * @returns void
     */
    private removeConseiller(conseillerToRemove: string, newConseillerId: string) {
        this.isLoading = true;
        this._http.delete(environment.backendURL + 'ppcon/' + conseillerToRemove + '/' + newConseillerId)
            .subscribe(() => {
                this.ppconGrid?.refresh();
                this.isLoading = false;
            });
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }
}
