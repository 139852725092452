import { HttpClient } from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService} from '@app/sam-base/core';
import {SarepService} from '@app/sam-base/core/services/sarep.service';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import {ComboboxItem, IwGridColumn} from '@app/sam-base/models';
import {Satbl} from '@app/sam-base/models/salary';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sarep} from '@app/sam-base/models/salary/sarep';
import {SarepCentre} from '@app/sam-base/models/salary/sarepCentre';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-saemp-profession', templateUrl: './tab-saemp-profession.html',
    standalone: false
})
export class TabSaempProfessionComponent extends BasePartialFormComponent<Saempc> implements OnInit {

    // Table info
    public iElement?: number;
    public selectedElement?: SarepCentre;
    public tableData: SarepCentre[] = [];
    public tableColumns: IwGridColumn<SarepCentre>[] = [{
        prop: 'centre', name: 'code', index: 0
    }, {
        prop: 'titre', name: 'titre', index: 1
    }, {
        prop: 'valeur', name: 'valeur', index: 2, decimals: 2
    }];

    public selectedRow?: Sarep;
    public readonly = true;
    public isSaving = false;
    public canModify = false;

    // ComboBox Values
    public optionTableMj3: ComboboxItem<number>[] = [{name: ' ', value: 100}];

    constructor(private readonly _http: HttpClient, private readonly _sarepService: SarepService, private readonly _formHandler: FormHandlerService<SalaryForm>, private readonly _satblService: SatblService) {
        super();
    }

    public get empId() {
        return this.getFormValue<string>('empId');
    }

    public get iFactSal() {
        return this.getFormValue<string>('ifactsal');
    }

    public ngOnInit() {
        this.getAllOptions('07', 0, this.optionTableMj3);
        if (this.empId) {
            this.loadTableInformation('A' + this.empId);
        }
        this.subscribeValueChange('empId', (empId) => this.loadTableInformation('A' + empId));
    }

    public onBtnRepa1Click() {
        this._formHandler.showFormDialog(SalaryForm.Repartition, {
            key: 'A' + this.empId, ifactsal: this.iFactSal, centype: '1'
        });
    }

    public onBtnRepa2Click() {
        this._formHandler.showFormDialog(SalaryForm.Repartition, {
            key: 'A' + this.empId, ifactsal: this.iFactSal, centype: '2'
        });
    }

    public onBtnRepa5Click() {
        this._formHandler.showFormDialog(SalaryForm.Repartition, {
            key: 'A' + this.empId, ifactsal: this.iFactSal, centype: '5'
        });
    }


    private loadTableInformation(key: string) {
        return this._sarepService.getSarepCentreInfo(key)
            .subscribe((data: SarepCentre[]) => {
                this.tableData = data;
                this.selectedElement = undefined;
            });
    }

    private getAllOptions(tableNo: string, recType: number, optionType: ComboboxItem<number | string>[]) {
        return this._satblService.getSatblOptions(tableNo, recType)
            .subscribe((res: Satbl[]) => {
                res.forEach(satbl => optionType.push({
                    name: `${satbl.elemId} | ${satbl.titre} | ${satbl.nvalue1} | ${satbl.nvalue2}`,
                    value: satbl.elemId || ''
                }));
            });
    }
}
