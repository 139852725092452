<form [formGroup]="formGroup">
    <div class="row">
        <iw-enum-dropdown
                [(value)]="invoiceProcessTypeValue"
                [inputEnum]="timeModeEnum"
                [isDisabled]="!!invoiceProcessId"
                [label]="'modeFacture' | translate"
                class="col-xs-3"
                labelAlign="left"></iw-enum-dropdown>


        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'owner' | translate"
                      class="iw-input col-xs-offset-3 col-xs-2"
                      formControlName="sessionOwner"></iw-textfield>
        <iw-textfield [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'sessionId' | translate"
                      [value]="invoiceProcessId ? '' + invoiceProcessId : ''"
                      class="col-xs-2"></iw-textfield>

        <iw-button #btnListAllSessions
                   (press)="openInvoiceProcessList()"
                   [iconClass]="'fas fa-chalkboard-user'"
                   [text]="'nb_invoice_processes_active' | translate: {nb: invoiceProcesses?.length}"
                   class="iw-input col-xs-2"></iw-button>

    </div>
    <div class="row">
        <div class="col-xs-10">
            <div class="row col-xs-12 no-padding">
                <iw-textfield #lblClients
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="clientLabel"
                              class="iw-input col-xs-5 no-padding"></iw-textfield>

                <iw-button #chkCliWithEleOnly
                           [iconClass]="'fas fa-filter'"
                           [isDisabled]="!checkCliWithEleOnlyEnabled()"
                           class="iw-input col-xs-1 btn-block no-padding"></iw-button>

                <iw-textfield #txtMntCliSelected
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="mtnClientsCount"
                              class="iw-input col-xs-4 no-padding"></iw-textfield>

                <iw-textfield #txtNbCliSelected
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="nbClientsCount"
                              class="iw-input col-xs-2 no-padding"></iw-textfield>

                <iw-table #tableInvoiceClients
                          (contextMenuClick)="onContextMenu($event)"
                          (rowDoubleClick)="openClient($event)"
                          (selectedChange)="updateClientValues($event)"
                          [columns]="dataToShow"
                          [data]="clients"
                          [defaultSelected]="true"
                          [isDisabled]="false"
                          [isFilterEnable]="false"
                          [isGridColumnMenuEnable]="false"
                          [isSortIconEnable]="false"
                          [selectionMode]="'checkbox'"
                          [virtualScroll]="true"
                          class="iw-grid col-xs-12 no-padding"
                          style="height: 200px"></iw-table>
                <iw-context-menu #menu
                                 [context]="contextMenuData"
                                 [items]="contextMenuItems"
                                 [popup]="true"></iw-context-menu>


                <div class="col-xs-12 margin-top-10"></div>

                <iw-textfield #lblFactures
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="facturesLabel"
                              class="iw-input col-xs-5 no-padding"></iw-textfield>

                <iw-button #btnBrowFacbuf
                           [isDisabled]="true"
                           [text]="'b' | translate"
                           class="iw-button col-xs-1 no-padding"></iw-button>

                <iw-textfield #txtMntFacSelected
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="mntfactures"
                              class="iw-input col-xs-4 no-padding"></iw-textfield>

                <iw-textfield #txtNbFacSelected
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              [value]="facturesCount"
                              class="iw-input col-xs-2 no-padding"></iw-textfield>

                <iw-table (applyFilter)="onApplyFilter($event)"
                          (columnSort)="onSort($event)"
                          (rowClicked)="onInvoiceBillClicked($event)"
                          (rowDoubleClick)="openInvoice($event)"
                          (selectedChange)="onFacturesFixesChange($event)"
                          [(selected)]="selectedFacturesFixes"
                          [columns]="getColumnsFToShow()"
                          [data]="bills"
                          [defaultSelected]="true"
                          [isDisabled]="false"
                          [isFilterEnable]="true"
                          [isGridColumnMenuEnable]="false"
                          [isSortIconEnable]="true"
                          [selectionMode]="'checkbox'"
                          [virtualScrollDelay]="0"
                          [virtualScroll]="true"
                          class="iw-grid col-xs-12 no-padding"
                          style="height: 320px"></iw-table>
            </div>
            <div *ngIf="invoiceBillClicked?.error"
                 class="row col-xs-12">
                <div [innerHTML]="invoiceBillClickedFormattedErrorMessage"
                     class="iw-textarea col-xs-12"></div>
            </div>
        </div>
        <div class="col-xs-2">
            <div class="row">
                <iw-textfield #lblPeriode
                              [isDisabled]="true"
                              [ngClass]="backgroundPerColor"
                              [textAlign]="'center'"
                              [tooltip]="'periode_active' | translate"
                              [value]="periode?.texte"
                              class="iw-input col-xs-12"></iw-textfield>

                <iw-date-picker #txtDate_Factu
                                [labelAlign]="'top'"
                                [label]="'dateFacture' | translate"
                                [minDate]="minDate"
                                class="iw-input col-xs-12 margin-bottom-5"
                                formControlName="dateFactu"></iw-date-picker>

                <iw-date-picker #txtDate_Compta
                                [isDisabled]="true"
                                [labelAlign]="'top'"
                                [label]="'dateComptable' | translate"
                                class="iw-input col-xs-12 margin-bottom-5"
                                formControlName="dateCompta"></iw-date-picker>

                <iw-textfield #txtTva_code
                              [isDisabled]="true"
                              [labelAlign]="'top'"
                              [label]="'tva' | translate"
                              [textAlign]="'center'"
                              class="iw-input col-xs-6 no-padding-right"
                              formControlName="tvaCode"></iw-textfield>

                <iw-textfield #txtTva_taux
                              [isDisabled]="true"
                              [textAlign]="'center'"
                              class="iw-input col-xs-6 no-padding-left no-label"
                              formControlName="tvaTaux"></iw-textfield>

                <iw-textfield #txtTvaperiode
                              [isDisabled]="true"
                              [ngClass]="backgroundTvaPerColor"
                              [textAlign]="'center'"
                              [tooltip]="'periode_tva'"
                              class="iw-input col-xs-12 margin-bottom-5"
                              formControlName="tvaPeriode"></iw-textfield>

                <iw-enum-dropdown #cboFacEleOrd
                                  [inputEnum]="ordreOptions"
                                  [isDisabled]="!checkFacEleOrdFacPrepModeEnabled()"
                                  [labelAlign]="'top'"
                                  [label]="'ordreElements' | translate"
                                  class="iw-input col-xs-12 margin-bottom-5"
                                  formControlName="faceleord"></iw-enum-dropdown>

                <iw-button #btnSetup
                           (press)="openParameters()"
                           [text]="'parametres' | translate"
                           class="iw-button col-xs-12"></iw-button>
                <!-- After ok on parameters form:
                           Thisform.faceleord = Optfact.faceleord
                           Thisform.defchamode = Optfact.defchamode -->

                <div class="col-xs-12 separator-top"></div>


                <iw-checkbox #chklfindemois
                             [label]="'finDeMois' | translate"
                             class="iw-input col-xs-12 margin-bottom-5"
                             formControlName="lfindemois"></iw-checkbox>

                <div class="col-xs-12 separator-top"></div>

                <iw-button #btnPrep
                           (press)="initInvoice()"
                           [text]="'Init' | translate"
                           class="iw-button col-xs-12"></iw-button>

                <div class="col-xs-12 no-label margin-bottom-5 margin-top-5"></div>

                <iw-button #btnPrep
                           (press)="prepareInvoice()"
                           [isDisabled]="!checkFacEleOrdFacPrepModeEnabled()"
                           [text]="'preparer' | translate"
                           class="iw-button col-xs-12"></iw-button>

                <div
                        class="col-xs-12 no-label margin-bottom-5 margin-top-5"></div>

                <iw-button #btnReset
                           (press)="effacer()"
                           [isDisabled]="!canEditSession"
                           [text]="'effacer' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>

                <iw-button #btnCreate
                           (press)="generateInvoices()"
                           [isDisabled]="!checkBtnCreateEnabled"
                           [text]="'generer' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>

                <iw-button #btnSaveAndQuit
                           (press)="saveInvoiceProcessAndQuit()"
                           [isDisabled]="!canEditSession"
                           [text]="'saveAndQuit' | translate"
                           class="iw-button col-xs-12 margin-bottom-5"></iw-button>

                <iw-button #btnClose
                           (press)="closeDialog()"
                           [text]="'quit' | translate"
                           class="iw-button col-xs-12"></iw-button>

            </div>
        </div>

    </div>
</form>
