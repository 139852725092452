import {Component, OnInit, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pppar} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {ToastService} from '@sam-base/core/toast';
import {FileUpload} from 'primeng/fileupload';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-pppar-signature',
    templateUrl: './tab-pppar-signature.component.html',
    styleUrls: ['./tab-pppar-signature.component.scss'],
    standalone: false
})
export class TabPpparSignatureComponent extends BasePartialFormComponent<Pppar> implements OnInit {

    public noSignature = true;

    @ViewChild('fileUploader') private fileUploader?: FileUpload;


    constructor(private sanitizer: DomSanitizer, private _toast: ToastService, private _translate: TranslateService) {
        super();
    }

    public ngOnInit() {
        this.showSignature();
    }

    public showSignature() {
        const img = this.getFormValue('signature');
        if (!img) {
            return '';
        }
        this.noSignature = false;
        return this.sanitizer.bypassSecurityTrustUrl('' + img);
    }

    public uploadDocument({files}: { files: File[] }, uploader: FileUpload) {
        if (files && files.length && files[0].name) {
            const file: File = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    this.setFormValue('signature', reader.result);
                    this.noSignature = false;
                    uploader.clear();
                }
            };
            reader.onloadend = () => {
                if (typeof reader.result === 'string') {
                    const img = new Image();
                    img.src = reader.result;
                    if (img.naturalHeight > environment.maxSignatureHeight || img.naturalWidth > environment.maxSignatureWidth) {
                        this.setFormValue('signature', undefined);
                        this.noSignature = true;
                        this._toast.warning(this._translate.instant('signature_max_dimensions_exceeded') + environment.maxSignatureWidth + 'x' + environment.maxSignatureHeight + 'px');
                    }
                }
            };
        }
    }

    public removeSignature() {
        this.setFormValue('signature', undefined);
        this.noSignature = true;
    }

}
