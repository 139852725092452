import {CommunicationType} from '@core/mail/mail.models';
import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';
import {BusinessExceptionErrors} from '@sam-base/core/auth/interceptors/error.model';

export enum EmailStatus {
    PENDING = 'PENDING',
    SENT = 'SENT',
    FAILED = 'FAILED'
}


@IwRestEntity('email-view', '')
export class EmailView {
    @IwColumn({
        index: 0,
    }) @IwRestEntityId()
    public id?: number;
    public communicationType?: CommunicationType;

    public replyTo?: string;

    public recipients?: string[];

    public ccRecipients?: string[];

    public bccRecipients?: string[];

    public subject?: string;

    public body?: string;

    public attachments?: string[];

    public htmlSignature?: string;

    public entityId?: string;

    public status?: EmailStatus;

    public error?: BusinessExceptionErrors;

    public dateCreat?: string;

    public dateModif?: string;

    public dateDelet?: string;

    public dateTrack?: string;

}