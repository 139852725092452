import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';

import {DEBUG} from '@app/sam-base/core/logger/logger';
import {ModalService} from '@app/sam-base/core/services';
import {Sags} from '@app/sam-base/models/salary';
import {SagsService} from '../../../services/sags.service';
import {BasishMode, DebitSplit} from '../genre-salaire.enums';
import {SagscptComponent} from '../sagscpt/sagscpt.component';


enum DroitCaf {
    aucun = '0', periodique = '1', unique = '2', indemnite = '3',
}

@Component({
    selector: 'iw-tab-sags-autre',
    templateUrl: './tab-sags-autre.component.html',
    standalone: false
})
export class TabSagsAutreComponent extends BasePartialFormComponent<Sags> {

    public droitCaf = DroitCaf;
    public debitCreditSplitOptions = DebitSplit;
    public basishModeOptions = BasishMode;
    @Input() public isReadonly = true;

    constructor(private _sagsService: SagsService, private _modalService: ModalService) {
        super();
    }

    public get enableCsctype(): boolean {
        return ![
            '6',
            '8',
            '9',
            'D',
            'N',
            'Q']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableDebitCredit(): boolean {
        return [
            '1',
            '2',
            '3',
            '4',
            '7',
            '8',
            '9',
            'B',
            'C',
            'M',
            'N',
            'P',
            'Q']
            .includes(this.getFormValue('gsCtype') ?? '');
    }

    public get enableSplitDebit(): boolean {
        return !this.isReadonly && this.enableDebitCredit && (this.getFormValue('debitsplit') as number) > 1;
    }

    public get enableSplitCredit(): boolean {
        return !this.isReadonly && this.enableDebitCredit && (this.getFormValue('creditsplit') as number) > 1;
    }

    public openGscpt(mode: number) {
        const gsId: string | undefined = this.getFormValue('gsId');
        if (!gsId) {
            return;
        }
        this._sagsService.getSagscptOptions(mode, gsId)
            .subscribe(async options => {
                try {
                    await this._modalService.showModal(SagscptComponent, options);
                } catch (error) {
                    DEBUG('Gscpt modal closed');
                }
            });
    }
}
