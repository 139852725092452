import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {
    EntityNavigationService,
    isRestEntity,
    IwReportsService,
    RestApiService,
    RestEntityClient,
    TabFormControl
} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import {ReportOutputType} from '@app/sam-base/core/reports';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Ppacattest, Ppemp, Ppmis} from '@app/sam-base/models/placement';
import {AttestEmp, Cac, Cac2, Cac3, Cac4, Cuhismis} from '@app/sam-base/models/placement/attestEmp';
import {Actions, ofType} from '@ngrx/effects';
import {IwReportOption} from '@sam-base/core/reports/models/report-options.model';
import {forkJoin} from 'rxjs';

import {PpacattestService} from '../../services/ppacattest.service';
import {acattestTabsFormControls} from './ppacattest-tabs-formcontrols';

@Component({
    templateUrl: './ppacattest.component.html',
    standalone: false
})
export class PpacattestComponent extends BaseTabFormComponent<Ppacattest> implements OnInit {

    public empName = '';
    public mois = '';
    public value?: AttestEmp;
    public cac?: Cac;
    public cac2: Cac2[] = [];
    public cac3: Cac3[] = [];
    public cac4?: Cac4;
    public cuhismis: Cuhismis[] = [];
    public lastMis?: Ppmis;
    public firstMis?: Ppmis;

    public missionFormGroup?: UntypedFormGroup;
    private tabValues?: TabFormControl<Ppacattest>[];
    private _ppempEntityClient: RestEntityClient<Ppemp>;
    private _ppmisEntityClient: RestEntityClient<Ppmis>;

    constructor(store: IwStoreService, public readonly _restService: RestApiService,
                public readonly attestEmpService: PpacattestService, private _updates: Actions,
                private _reportsService: IwReportsService,
                private readonly _entityNavService: EntityNavigationService) {
        super(store);
        this._ppempEntityClient = _restService.getEntityClient(Ppemp);
        this._ppmisEntityClient = _restService.getEntityClient(Ppmis);

    }

    public get empId() {
        return this.getFormValue('empId');
    }

    public ngOnInit() {
        this.subscribeValueChange('acaId', () => {
            const empId = this.getFormValue('empId');
            this.subscribeToSaveEffect(this.getFormValue('acaId'));
            if (!empId) {
                return;
            }
            this._ppempEntityClient
                .getById(empId)
                .subscribe((emp: Ppemp) => this.empName = `${emp.nom} ${emp.prenom}`);
            this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
            this.attestEmpService.getCalculation(this.getFormValue('empId'))
                .subscribe((elem: AttestEmp) => {
                    this.cac = elem.cac;
                    this.cac2 = elem.cac2 || [];
                    this.cac3 = elem.cac3 || [];
                    this.cac4 = elem.cac4;
                    this.cuhismis = elem.cuhismis || [];
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                    this.getMissionTabsInfo();
                });
        });
        this.subscribeValueChange('salIdLim', () => {
            this.mois = this.getMonth(this.getFormValue('salIdLim'));
        });
    }

    public printAttestation() {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        const options: IwReportOption<Ppacattest> = {
            outputType: ReportOutputType.Pdf,
            report: 'ATTESTATION_EMPLOYEUR',
            id: this.getFormValue('acaId'),
            params: {empId: this.getFormValue('empId')},
        };
        this._reportsService.downloadReport(options, 'attestation_employeur')
            .subscribe({
                next: () => this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false)),
                error: () => this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false))
            });
    }

    public getTabsValues(): TabFormControl<Ppacattest>[] {
        if (!this.tabValues) {
            return acattestTabsFormControls;
        }
        return this.tabValues;
    }

    public openEmp() {
        this._entityNavService
            .navigateToEntityForm(Ppemp, this.getFormValue('empId'));
    }

    protected getValidationType() {
        return Ppacattest;
    }

    protected getFormControlNames(): FormKeys<Ppacattest> {
        return this.getTabsFormControls(acattestTabsFormControls);
    }

    // Subscribing to save event so we can call calculate again
    private subscribeToSaveEffect(selectedId: string) {
        const sub = this._updates
            .pipe(ofType(globalFormActions.GLOBAL_FORM_ENTITY_SAVE_NEW_SUCCESS))
            .subscribe((action: globalFormActions.SaveNewEntitySuccess<Ppacattest>) => {
                if (this.isDoctypeUpdated(selectedId, action.entity)) {
                    this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
                    this.attestEmpService.getCalculation(this.getFormValue('empId'))
                        .subscribe((elem: AttestEmp) => {
                            this.cac2 = elem.cac2 || [];
                            this.cac3 = elem.cac3 || [];
                            this.cac4 = elem.cac4;
                            this.cuhismis = elem.cuhismis || [];
                            this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                        });
                }
                sub.unsubscribe();
            });
    }

    private isDoctypeUpdated(selectedId: string, aca: Ppacattest): boolean {
        return isRestEntity(aca) && aca.acaId === selectedId;
    }

    private getMonth(salId: string): string {
        const salIdMonth = Number(salId.slice(4)) < 10 ? '0' + Number(salId.slice(4)) : Number(salId.slice(4));
        const date = `${salId
            .slice(0, 4)}-${salIdMonth}-10`;
        this.mois = `${IwDateHelper
            .dateMonthString(date)} ${salId
            .slice(0, 4)}`;
        return this.mois.charAt(0)
            .toUpperCase() + this.mois.slice(1);
    }

    private getMissionTabsInfo(): void {
        forkJoin([
            this._ppmisEntityClient.getById(this.cac?.firstMisId || ''),
            this._ppmisEntityClient.getById(this.cac?.lastMisId || '')])
            .subscribe(res => {
                this.firstMis = res[0];
                this.lastMis = res[1];
            });
    }

}
