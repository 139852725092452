import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseTabFormComponent} from '@app/sam-base/base/base-tab-form.component';
import {TabFormControl} from '@app/sam-base/core/form-handler/models/form-description.model';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {FormKeys} from '@app/sam-base/models';
import {Ppcli} from '@app/sam-base/models/placement';
import {TabItem} from '@app/sam-base/models/tab-item.model';
import {ClientService} from '@modules/sam-main/placement/services/client.service';
import {Actions, ofType} from '@ngrx/effects';
import {EntityNavigationService, PpkwService, showGridDetailsAccessRoles} from '@sam-base/core';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {Gecliview} from '@sam-base/models/invoices/gecliview';
import {ProfileService} from '@shared/profile/profile.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {ppcliTabsFormControls} from './ppcli-tabs-formcontrols';

@Component({
    templateUrl: './ppcli.component.html',
    standalone: false
})
export class PpcliComponent extends BaseTabFormComponent<Ppcli> implements OnInit, OnDestroy {

    public tabs: TabItem[] = [];
    public selectedTab: TabItem | undefined;
    public disabledTabList: string[] = [];
    public interijobActive$ = false;
    public INVOICE_USER_ROLES = [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_FACTURE_RO];
    private tabValues?: TabFormControl<Ppcli>[];
    private subscriptions = new Subject();

    constructor(store: IwStoreService, private _profileService: ProfileService, private _actions: Actions,
                private readonly _entityNavService: EntityNavigationService,
                private _pkwService: PpkwService, private _clientService: ClientService) {
        super(store);
        this.tabValues = getDeepCopy(ppcliTabsFormControls);
    }

    public get cliId() {
        return this.getFormValue('cliId') || '';
    }

    public get cliAttId() {
        return this.getFormValue('cliattId') || '';
    }

    public get ageId() {
        return this.getFormValue('ageId') || '';
    }

    public get nom() {
        return this.entityValue && this.entityValue.nom;
    }

    public ngOnInit() {
        if (this.getFormValue('cliId') === undefined) {
            this.disabledTabList.push('Docs');
        }
        this.subscribeValueChange<string>('cliId', e => {
            if (this.getFormValue('cliId') !== undefined) {
                this.disabledTabList = [];
            }
            const groupsId = showGridDetailsAccessRoles('ppcli');
            if (!this._profileService.checkUserPermission(groupsId)) {
                this.disabledTabList.push('suivi');
            }
        });
        this._store.getLicenseOption('interijobwebaccess')
            .pipe(takeUntil(this.subscriptions))
            .subscribe((active: boolean) => {
                this.interijobActive$ = active;
                this.showHideInterijob(active);
            });

        this.subscribeToSaveEffect();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public getTabsValues(): TabFormControl<Ppcli>[] {
        if (!this.tabValues) {
            return ppcliTabsFormControls;
        }
        return this.tabValues;
    }

    public checkIfNew() {
        return this.isNew;
    }

    // eslint-disable-next-line complexity
    public getCliClass() {
        let cliStatus = this.getFormValue('clistatus');
        if (cliStatus === 0) {
            cliStatus = '0';
        } else if (cliStatus === 1) {
            cliStatus = '1';
        } else if (cliStatus === 2) {
            cliStatus = '2';
        } else if (cliStatus === 3) {
            cliStatus = '3';
        } else {
            cliStatus = 'd';
        }
        return 'cli-color-' + cliStatus;
    }

    public openGecli() {
        if (!this.cliId) {
            return;
        }
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._clientService.updateGecli(this.cliId)
            .subscribe({
                next: () => this._store.dispatch(new actions.SetLoading(this.uuid, false)),
                error: () => {
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                }
            });
        this._entityNavService
            .navigateToEntityForm(Gecliview, this.getFormValue('cliId'));
    }

    public onDefaultContactChanged(cliAttId: string) {
        this.setFormValue('cliattId', cliAttId);
    }

    protected getValidationType() {
        return Ppcli;
    }

    protected validateFields(e: Ppcli): boolean {
        return super.validateFields(e);
    }

    protected getFormControlNames(): FormKeys<Ppcli> {
        return this.getTabsFormControls(ppcliTabsFormControls);
    }

    private showHideInterijob(active: boolean) {
        const controls = this.tabValues ?? getDeepCopy(ppcliTabsFormControls);

        controls.forEach((control: TabFormControl<Ppcli>) => {
            if (control.title === 'pointeuseIJ') {
                control.show = active;
            }
        });

        this.refreshTabList();
    }

    private subscribeToSaveEffect() {
        this._actions.pipe(takeUntil(this.subscriptions), ofType(globalActions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe(e => {
                const uuid = e['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }

                const keywords = this._pkwService.store[uuid] || [];
                this._pkwService.saveKeywords(this.cliId || '', keywords, 'ppcli')
                    .subscribe();
            });
    }
}
