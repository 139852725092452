import {SamUserRole} from '@sam-base/models/admin/sam-user';

/**
 * Defines the roles that have access to the dashboard
 */
export function dashboardRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_RW

    ];
}

/**
 * Defines the roles that have access to the employee
 */
export function employeeRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

/**
 * Defines the roles that have access to the employee
 */
export function salariesRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_PRINT];
}


/**
 * Defines the roles that have access to the client
 */
export function clientRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN,
        SamUserRole.SAM_PLACEMENT_CLIENT_ADMIN];
}

/**
 * Defines the roles that have access to the commandes
 */
export function commandesRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

/**
 * Defines the roles that have access to the mission
 */
export function missionRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

/**
 * Defines the roles that have access to the placementfixe
 */
export function placementfixeRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN];
}

/**
 * Defines the roles that have access to the administration
 */
export function placementMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_PLACEMENT_RO,
        SamUserRole.SAM_PLACEMENT_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_PLACEMENT_ADMIN]
        ;
}

/**
 * Defines the roles that have access to the administration
 */
export function factureMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM,
        SamUserRole.SAM_FACTURE_PRINT,
        SamUserRole.SAM_FACTURE_DELETE];
}

/**
 * Defines the roles that have access to the administration
 */
export function salaireMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_PRINT];
}

export function factureHeaderMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM,
        SamUserRole.SAM_FACTURE_PRINT,
        SamUserRole.SAM_FACTURE_ADMIN_PERIOD,
        SamUserRole.SAM_FACTURE_COMPTA,
        SamUserRole.SAM_FACTURE_EXTRACTIONS
    ];
}

/**
 * Defines the roles that have access to the administration
 */
export function salaireHeaderMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_RO,
        SamUserRole.SAM_SALAIRE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN,
        SamUserRole.SAM_SALAIRE_COM,
        SamUserRole.SAM_SALAIRE_COMPTA,
        SamUserRole.SAM_SALAIRE_ADMIN_PERIOD,
        SamUserRole.SAM_SALAIRE_EXTRACTION
    ];
}

export function paiementsMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_SALAIRE_ADMIN];
}

export function paiementsWriteMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_SALAIRE_COMPTA,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

/**
 * Defines the roles that have access to the administration
 */
export function debitorMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_RO,
        SamUserRole.SAM_FACTURE_RW,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_FACTURE_COM,
        SamUserRole.SAM_FACTURE_PRINT];
}


export function eancaissementsMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_FACTURE_ADMIN,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function swissdecRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_SWISSDEC,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function userAdmRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_USERS,
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function godRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_GOD_MODE];
}

export function adminMenuRoles(): SamUserRole[] {
    return [
        SamUserRole.SAM_ADMIN_SWISSDEC,
        SamUserRole.SAM_ADMIN_GOD_MODE,
        SamUserRole.SAM_ADMIN_USERS];
}
