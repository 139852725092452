import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sags} from '@app/sam-base/models/salary';

@Component({
    selector: 'iw-tab-sags-swissdec', templateUrl: './tab-sags-swissdec.component.html',
    standalone: false
})
export class TabSagsSwissdecComponent extends BasePartialFormComponent<Sags> {

    constructor() {
        super();
    }

}
