import {Component, Input, OnDestroy, Type, ViewChild} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {FormHandlerService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {Lookups} from '@app/sam-base/models/common';
import {Ppekw, Ppemp, Ppkw, ScheduleEvent} from '@app/sam-base/models/placement';
import {IwTimeSlotsComponent} from '@shared/widgets/iw-components/iw-time-slots/iw-time-slots.component';
import {IwWeekdaysPickerComponent} from '@shared/widgets/iw-components/iw-weekday-picker/iw-weekday-picker.component';
import {
    LookupsSelectionComponent
} from '@shared/widgets/modal-components/lookups-selection/lookups-selection.component';
import {Subject} from 'rxjs';

import {PlacementForm} from '../../../placement.forms';
import {PpekwMatchingComponent} from '../../ppekw-matching/ppekw-matching.component';

@Component({
    selector: 'iw-tab-ppemp-matching',
    templateUrl: './tab-ppemp-matching.component.html',
    standalone: false
})
export class TabPpempMatchingComponent extends BasePartialFormComponent<Ppemp> implements OnDestroy {

    @ViewChild('kwMatching', {static: true}) public kwMatching?: PpekwMatchingComponent;

    @ViewChild('weekdayPicker', {static: true}) public weekdayPicker?: IwWeekdaysPickerComponent;

    @ViewChild('timeSlots', {static: true}) public timeSlotsPicker?: IwTimeSlotsComponent;

    public matchSource: Type<any> = Ppkw;
    public matchTarget: Type<any> = Ppekw;


    public regionsToShow = '';
    public regions: Lookups[] = [];

    public dateFrom = '';
    public dateTo = '';
    public weekdays: number[] = [];
    public timeSlot: (1 | 0)[] = [];

    public gridEntityType = ScheduleEvent;
    public query: RestQueryParam<ScheduleEvent, any>[] = [];
    @Input() public formId = '';
    private _entiyId = '';
    private subscriptions = new Subject();

    constructor(private readonly _modalService: ModalService,
                private readonly _formHandler: FormHandlerService<PlacementForm>) {
        super();
    }

    public get entityId(): string {
        return this._entiyId;
    }

    @Input()
    public set entityId(v: string) {
        this._entiyId = v;
        this.buildQuery();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public async openRegionsSelection() {
        try {
            const regionsValue = this.getFormValue<string>('regions') || '';

            const regList: string[] = regionsValue.split(';')
                .map(e => e.trim());
            const res = await this._modalService.showModal(LookupsSelectionComponent, {
                list: regList,
                lkupname: 'REGION'
            });

            const regionsToSave = res;
            this.setFormValue('regions', regionsToSave);

        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    }

    public changedKeyWord() {
        this.onFormChange();
    }

    public absencesClick() {
        if (!this._entiyId) {
            return;
        }
        this._formHandler
            .showFormDialog(PlacementForm.ScheduleEvent, {
                height: '250px',
                parentId: this.entityId,
                parentEntity: 'ppemp',
                isAvailability: false
            });
    }

    private buildQuery() {
        const id = this._entiyId || '0';
        if (typeof id === 'string') {
            this.query = [
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'parentId',
                    value: id,
                },
                {
                    operation: RestQueryOperation.Equals,
                    prop: 'parentType',
                    value: 'ppemp',
                }];
        }
    }
}
