import {Component, Input} from '@angular/core';

@Component({
    templateUrl: './lpp-institution-result-viewer.component.html',
    selector: 'lpp-institution-result-viewer',
    standalone: false
})
export class LppInstitutionResultViewerComponent {

    constructor() {
    }

    private _json?: LppResult;
    public get json(): LppResult | undefined {
        return this._json;
    }

    @Input()
    public set json(v: string) {
        if (v) {
            this._json = JSON.parse(v);
        }
    }
}
