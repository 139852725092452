import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {Pprap} from '@app/sam-base/models/placement';

/**
 * Tab General of parametres Generaux
 */
@Component({
    selector: 'iw-tab-rapport-notes', templateUrl: './tab-rapport-notes.component.html',
    standalone: false
})
export class TabRapportNotesComponent extends BasePartialFormComponent<Pprap> {

    constructor(_restService: RestApiService) {
        super();
    }
}
