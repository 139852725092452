import {Component, OnInit} from '@angular/core';
import {ParametersService} from '@app/core/services/parameters.service';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {formatSalId} from '@app/sam-base/core/helpers/parameters.helper';
import {RestQueryParam} from '@app/sam-base/core/rest-api';
import {RestQueryOperation} from '@app/sam-base/core/rest-api/models/rest-query.model';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {SaesService} from '@app/sam-base/core/services/saes.service';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Sags} from '@app/sam-base/models/salary';
import {SaData} from '@app/sam-base/models/salary/saData';
import {Saemp} from '@app/sam-base/models/salary/saemp';
import {Saes} from '@app/sam-base/models/salary/saes';
import {Sagen} from '@app/sam-base/models/salary/sagen';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';

import {CboCctDedType, CboISBaremeOptions, CboIsCalcmodeOptions, ElemSalaireOutput} from './elementdesalaire.models';

@Component({
    templateUrl: './elementdesalaire.component.html',
    standalone: false
})
export class ElementDeSalaireComponent extends BaseFormComponent<Saes> implements OnInit {

    public gridType = Sags;
    public queryStatements: RestQueryParam<Sags, string>[] = [{
        operation: RestQueryOperation.NotEquals, prop: 'gsId', value: '_'
    }];

    public sagsColumns = [{
        prop: 'gsId', name: 'gs', index: 0
    }, {
        prop: this.getTitreByLanguage(), name: 'titre', index: 1
    }];

    public isLoading = false;
    public cboSalIdOptions: ComboboxItem<string | undefined>[] = [];
    public cboTableElOptions: ComboboxItem<string | undefined>[] = [];
    public cboCctIdDedtypeOptions = CboCctDedType;
    public cboIsBaremeOptions = CboISBaremeOptions;
    public cboIsCalcmodeOptions = CboIsCalcmodeOptions;

    public canmodify = false;
    // Form values not entity related
    public taux?: number;
    public cctId?: string;
    public cctDedtype?: string;
    public isCanton?: string;
    public isBareme?: string;
    public isCalcmode?: number;
    public isTaux?: number;
    public dedCode?: string;
    public tableNo = '41';
    public tableElemId?: string;
    //
    public empId?: string;
    public salId?: string;
    public esId?: string;
    public sags?: Sags;
    public isNew?: boolean = false;
    public bscalcversion?: string;
    private saemp?: Saemp;

    constructor(private _store: IwStoreService, private _translate: TranslateService, private _toast: ToastService, private _rest: RestApiService, private _saesService: SaesService, private _satblService: SatblService, private _saemsService: SaemsService, private _parametersService: ParametersService) {
        super();
    }

    public get isCboCctIdEnabled() {
        return (['M', 'N'].includes(this.sags?.gsCtype ?? '') || (this.sags?.gsCtype === 'F' && this.sags.corrmode === 3));
    }

    public get isCboIsEnabled() {
        return this.sags?.gsCtype === 'F' && this.sags.corrmode === 1;
    }

    public get parentUuid(): string {
        return this.getData('parentUuid') ?? '';
    }

    private _isCboSalIdActive = false;

    public get isCboSalIdActive() {
        return this.sags?.gsCtype === 'F' && [2, 4].includes(this.sags?.corrmode ?? -1) && this._isCboSalIdActive;
    }

    private _isCboTableElActive = false;

    public get isCboTableElActive() {
        return this.sags?.gsCtype === 'F' && [2, 4].includes(this.sags?.corrmode ?? -1) && this._isCboTableElActive;
    }

    public ngOnInit(): void {
        this.empId = this.getData('empId');
        this.salId = this.getData('salId');
        this.esId = this.getData('esId');
        this.isNew = !!this.getData('isNew');
        this.saemp = this.getData('saemp');
        this.sags = this.getData('sags');
        this.getCombosOptions();
        this.subscribeMontantCalculation();
        this.getBscalcversion();
        if (this.isNew) {
            this.initFormAdd();
        } else {
            this.initFormModify();
        }
    }

    public saveEntry(): void {
        // PROCEDURE btnOk.Click line 794
        if (!this.sags || !this.salId) {
            return;
        }
        const data: ElemSalaireOutput = {
            empId: this.getFormValue('empId') ?? this.empId,
            prBase: this.getFormValue('prBase'),
            unite: this.getFormValue('unite'),
            texte: this.getFormValue('texte'),
            prTaux: this.getFormValue('prTaux'),
            base: this.getFormValue('base'),
            montant: this.getFormValue('montant'),
            salIdCor: this.getFormValue('salIdCor'),
            cptdebit: this.getFormValue('cptdebit'),
            cptcredit: this.getFormValue('cptcredit'),
            parent: this.getFormValue('parent'),
            gsId: this.getFormValue('gsId'),
            taux: this.getFormValue('taux'),
            esId: this.getFormValue('esId'),
            cctId: this.cctId,
            cctDedtype: this.cctDedtype,
            isCanton: this.isCanton,
            isBareme: this.isBareme,
            isCalcmode: this.isCalcmode,
            isTaux: this.isTaux,
            dedCode: this.dedCode,
            tableNo: this.tableNo,
            tableElemId: this.tableElemId,
            sags: this.sags,
            salId: this.salId
        };
        this.isLoading = true;
        this._saesService.saveSaes(data)
            .subscribe({
                next: () => {
                    this.isLoading = false;
                    this.closeDialog();
                    this._saemsService.elSalSubject.next({uuid: this.parentUuid, saved: true});
                }, error: () => {
                    this.isLoading = false;
                    this._toast.error(this._translate.instant('error_sal_el'));
                }
            });
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    // eslint-disable-next-line complexity
    public onRowSelect(sagsArray: Sags[]) {
        // Procedure xupdtx line 731
        if (!sagsArray.length) {
            return;
        }
        this.sags = sagsArray[0];
        this.setFormValue('gsId', this.sags.gsId);
        switch (this.sags.gsCtype) {
            case '1':
            case 'C':
                this.setFormValue('taux', this.sags.baseBrut);
                if (this.sags.salHor || this.sags.salHorlpp) {
                    this.setFormValue('unite', 'h.');
                    this.setFormValue('prTaux', this.saemp?.salhoraire !== 0 ? this.saemp?.salhoraire : this.saemp.salhorauto);
                } else if (this.sags.salJour) {
                    this.setFormValue('unite', 'j.');
                    this.setFormValue('prTaux', this.saemp?.saljour);
                } else {
                    this.setFormValue('unite', '');
                    this.setFormValue('prTaux', 0);
                }
                break;
            case '2':
            case '9':
            case 'M':
            case 'N':
                this.setFormValue('taux', -1);
                break;
            case '3':
                this.setFormValue('taux', this.sags.retInd);
                break;
            case 'A':
            case 'F':
            case 'V':
                this.setFormValue('taux', 1);
                if (this.sags.salHor || this.sags.salHorlpp) {
                    this.setFormValue('unite', 'h.');
                } else {
                    this.setFormValue('unite', '');
                }
                this.setFormValue('prTaux', 0);
                if (this.sags && this.sags.gsCtype === 'F') {
                    if (this.sags.corrmode === 2) {
                        this.tableNo = '41';
                    }
                    if (this.sags.corrmode === 4) {
                        this.tableNo = '40';
                    }
                    this.getTableElOptions(this.tableNo);
                }
                break;
            default:
                this._toast.warning(this._translate.instant('gs_ctype_incorrect') + this.sags.gsCtype);
                break;
        }
        this.getCombosOptions();
    }


    // eslint-disable-next-line complexity
    public applyTauxMask(taux?: number) {
        if (!taux) {
            this.taux = 0;
            return;
        }

        const currentTaux: number = this.getFormValue('taux') ?? 0;
        switch (this.sags?.gsCtype) {
            case '1':
            case '3':
            case 'A':
                this.taux = 100 * currentTaux;
                break;
            default:
                this.taux = currentTaux;
                break;
        }
    }

    protected getFormControlNames(): FormKeys<Saes> {
        return ['empId', 'prBase', 'unite', 'texte', 'prTaux', 'base', 'montant', 'salIdCor', 'cptdebit', 'cptcredit', 'parent', 'gsId', 'taux', 'esId'];
    }

    protected validateFields(e: Saes): boolean {
        return super.validateFields(e);
    }

    private getCombosOptions() {
        this.getCboSalIdOptions();
        this.getTableElOptions('41');
    }

    private getCboSalIdOptions() {
        if (this.sags?.gsCtype === 'F' && [2, 4].includes(this.sags?.corrmode ?? -1)) {
            this._saesService.getComboSalIdOptions()
                .subscribe(options => {
                    this.cboSalIdOptions = [{
                        name: this._translate.instant('combo_no_option'), value: undefined
                    }, ...options.map(option => ({name: formatSalId(option), value: option}))];
                    this._isCboSalIdActive = !!options.length;
                });
        } else {
            this._isCboSalIdActive = false;
        }
    }

    private initFormModify() {
        // PROCEDURE INIT 676
        forkJoin([this._rest.getEntityClient(Saes)
            .getById(this.esId ?? ''), this._rest.getEntityClient(Sagen)
            .getById(this.salId ?? ''), this._saemsService.getSaemsByEmpIdAndSalId(this.empId ?? '', this.salId ?? '')
            // eslint-disable-next-line complexity
        ])
            .subscribe(data => {
                const saes = data[0];
                const sagen = data[1];
                const saems = data[2];
                this.fillFormData(saes);
                this.canmodify = !(!!saems.paid || (sagen.status ?? 0) > 1);
                const gsCtype = this.sags?.gsCtype ?? '';
                const corrmode = this.sags?.corrmode ?? 0;
                // Line 694
                this.setCctOptions(gsCtype, corrmode, saes);
                // Line 699
                this.setCorrModeData(saes, this.sags);
            });
    }

    private initFormAdd() {
        this.canmodify = true;
    }

    private getBscalcversion() {
        this._parametersService
            .getSaparDataText<SaData>('saData')
            .subscribe((saData) => {
                this.bscalcversion = saData.bscalcversion;
            });
    }

    // eslint-disable-next-line complexity
    private setCctOptions(gsCtype: string, corrmode: number, saes?: Saes) {
        if (saes && saes.parent && (['M', 'N'].includes(gsCtype) || ('F' === gsCtype && corrmode === 3))) {
            this.cctId = saes.parent.slice(0, saes.parent.indexOf(':'));
            this.cctDedtype = saes.parent.slice(saes.parent.indexOf(':') + 1, saes.parent.length);
        }
    }

    // eslint-disable-next-line complexity
    private setCorrModeData(saes?: Saes, sags?: Sags) {
        if (saes && sags && sags.gsCtype === 'F') {
            switch (sags.corrmode) {
                case 1:
                    this.isCanton = saes.parent?.slice(3, saes.parent.length);
                    this.isBareme = saes.parent?.split(':')[1];
                    const calcmode = Number(saes.parent?.split(':')[2]);
                    this.isCalcmode = calcmode > 1 ? calcmode : 1;
                    this.isTaux = saes.taux2;
                    break;
                case 2:
                    this.dedCode = 'IJM';
                    this.tableNo = '41';
                    this.tableElemId = saes.parent?.split(':')[1];
                    this.getTableElOptions(this.tableNo);
                    break;
                case 4:
                    this.dedCode = 'LAA';
                    this.tableNo = '40';
                    this.tableElemId = saes.parent?.split(':')[1];
                    this.getTableElOptions(this.tableNo);
                    break;
            }
        }
    }

    private getTableElOptions(tableNo: string) {
        if (this.sags?.gsCtype === 'F' && [2, 4].includes(this.sags?.corrmode ?? -1)) {
            this._satblService.getSatblOptions(tableNo, 0)
                .subscribe(options => {
                    this.cboTableElOptions = [{
                        name: this._translate.instant('combo_no_option'), value: undefined
                    }, ...options.map(satbl => ({
                        name: `${satbl.elemId} | ${satbl.titre} | ${satbl.nvalue1} | ${satbl.nvalue2}`,
                        value: satbl.elemId
                    }))];
                    this._isCboTableElActive = !!options.length;
                });
        } else {
            this._isCboTableElActive = false;
        }
    }

    private getTitreByLanguage() {
        const language = this._translate.currentLang;
        switch (language) {
            case 'en':
                return 'titre2';
            case 'de':
                return 'titre3';
            default:
                return 'titre1';
        }
    }

    private subscribeMontantCalculation() {
        this.subscribeValueChange('prBase', (prBase?: number) => this.calcMontants(prBase ?? 0, this.getFormValue('prTaux') ?? 0));
        this.subscribeValueChange('prTaux', (prTaux?: number) => this.calcMontants(this.getFormValue('prBase') ?? 0, prTaux ?? 0));
        this.subscribeValueChange('taux', (taux?: number) => this.applyTauxMask(taux));
    }

    private calcMontants(base: number, taux: number) {
        let tmp = 0;
        let mntBase = 0;
        if (taux === 0) {
            tmp = 0;
        } else {
            tmp = taux;
        }

        mntBase = Math.round((base * tmp + Number.EPSILON) * 100) / 100;

        if (this.sags?.roundIt) {
            this.setFormValue('montant', Math.round((mntBase * this.getFormValue('taux') + Number.EPSILON) * 100) / 100);
        } else {
            this.setFormValue('montant', mntBase * this.getFormValue('taux'));
        }
        this.setFormValue('base', mntBase);
    }
}
