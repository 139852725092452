import {Component, Input, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {RestEntityClient} from '@app/sam-base/core/rest-api/entity/rest-entity-client';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import {Ppcde, Ppcliadr} from '@app/sam-base/models/placement';
import {ParametersService} from '@core/services/parameters.service';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
    selector: 'iw-tab-ppcdetempo-autre', templateUrl: './tab-ppcdetempo-autre.component.html',
    standalone: false
})
export class TabPpcdetempoAutreComponent extends BasePartialFormComponent<Ppcde> implements OnInit {

    public suvaDropdownOptions: { [key: string]: string | undefined } = {};

    public fullAddress = '';

    public get cliId() {
        return this.getFormValue('cliId') || '';
    }

    @Input() public isReadonly = true;

    @Input() public entityId?: string;

    private readonly _entityCliaddrService: RestEntityClient<Ppcliadr>;

    constructor(_restService: RestApiService, private _parametersService: ParametersService) {
        super();
        this._entityCliaddrService = _restService.getEntityClient(Ppcliadr);
    }

    public ngOnInit() {
        this._parametersService.getParameterValue('cnacodes')
            .subscribe((codes) => {
                const codesStr: (string | undefined)[] = (codes as string).split(',');
                const opts: { [key: string]: string | undefined } = {};
                codesStr.forEach((code: any) => {
                    opts[code] = code;
                });
                opts['_'] = undefined;
                this.suvaDropdownOptions = opts;
            });
    }

    public resultFilters: (e: Ppcliadr) => boolean = (e) => e.cliId === this.cliId;

    public setClientAddress() {
        const cliadrId = this.getFormValue<string>('cliadrId');
        if (!cliadrId) {
            return '';
        }

        this._entityCliaddrService.getById(cliadrId)
            .pipe(catchError(() => of(undefined)))
            .subscribe((res?: Ppcliadr) => {
                if (!res) {
                    this.fullAddress = '';
                    return;
                }
                this.fullAddress = this.buildAddress(res);
            });
    }

    public resetLocServ() {
        this.setFormValue('ccfrx', undefined);
    }

    public resetContMis() {
        this.setFormValue('cmfrx', undefined);
    }

    // eslint-disable-next-line complexity
    private buildAddress(p: Ppcliadr) {
        let fullAdresse = '';

        if (p.prefixe) {
            fullAdresse += `${p.prefixe}\n`;
        }
        if (p.nom) {
            fullAdresse += `${p.nom}\n`;
        }
        if (p.adresse1) {
            fullAdresse += `${p.adresse1}\n`;
        }
        if (p.adresse2) {
            fullAdresse += `${p.adresse2}\n`;
        }
        if (p.adresse3) {
            fullAdresse += `${p.adresse3}\n`;
        }
        if (p.npa || p.lieu) {
            fullAdresse += `${p.npa} ${p.lieu}\n`;
        }
        return fullAdresse;
    }

}
