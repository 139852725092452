import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {FormHandlerService, GlobalFormState, IwStoreService, ModalService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Ppagi} from '@app/sam-base/models/placement';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {saveAs} from 'file-saver';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../placement.forms';

@Component({
    templateUrl: './aginter-sidemenu.component.html',
    standalone: false
})
export class AginterSidemenuComponent extends BaseSideFormComponent<Ppagi> implements OnInit, OnDestroy {
    public readonly = true;

    private readonly _baseUrl?: string;
    private _agiId?: string;
    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService, private _formHandler: FormHandlerService<PlacementForm>,
                private _http: HttpClient, private _translate: TranslateService,
                private _modalService: ModalService) {
        super();
        this._baseUrl = environment.backendURL;
    }

    public ngOnInit() {
        this._store.globalForm(this.uuid)
            .state
            .pipe(takeUntil(this.subscription))
            .subscribe((res: GlobalFormState<unknown>) => {
                this.readonly = res.editMode === 'read';
            });

        if (!this.uuid) {
            return;
        }
        this._store.globalForm<Ppagi>(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe(ppagi => this._agiId = ppagi?.agiId);
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    public openAginew() {
        this._formHandler
            .showFormDialog(PlacementForm.AgiCreate);
    }


    public downloadPdf() {
        if (!this._agiId) {
            return;
        }
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        return this._http.get(this._baseUrl + 'aginter/pdf/' + this._agiId, {responseType: 'blob'})
            .subscribe((doc: Blob) => {
                saveAs(doc, 'aginter-' + this._agiId);
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
            }, (err) => {
                this._translate.instant('error_download_aginter');
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
            });
    }

    public sendByMail() {
        const ids: string[] = [];
        ids.push(this._agiId! || '');

        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.AGI_EMAIL,
            entityIds: ids
        });
    }
}
