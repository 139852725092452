import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {EntityDelete, FormHandlerService, IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryParam} from '@app/sam-base/core/rest-api';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys, IwGridColumn} from '@app/sam-base/models';
import {ContactNcs} from '@app/sam-base/models/salary/contact-ncs.model';
import {Saparncs} from '@app/sam-base/models/salary/saparncs';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SalaryForm} from '../../salary.forms';

@Component({
    templateUrl: './saparncs.component.html',
    standalone: false
})
export class SaparncsComponent extends BaseStoreFormComponent<Saparncs> implements OnInit {

    public saparncs: Saparncs = new Saparncs();
    public contactNCS = ContactNcs;
    public selectedContactNCSRow: ContactNcs | undefined;
    public contactNCSColumns = this.getContactNCSColumns();
    public contactNCSQuery: RestQueryParam<ContactNcs, string>[] = [];
    @ViewChild('contactGrid') private contactNCSTable?: IwRestGridComponent<ContactNcs>;
    private readonly _restClient: RestEntityClient<Saparncs>;
    private _subscriptions = new Subject();

    constructor(protected readonly _store: IwStoreService,
                private readonly _formHandlerService: FormHandlerService<SalaryForm>,
                private readonly _translate: TranslateService,
                private readonly _formHandler: FormHandlerService<SalaryForm>, private readonly _rest: RestApiService,
                private readonly _events: IwEventHubService<string>) {
        super(_store);
        this._restClient = _rest.getEntityClient(Saparncs);
    }

    public ngOnInit() {
        this._events.forType('contactNCS_updated')
            .pipe(takeUntil(this._subscriptions))
            .subscribe((e) => {
                this.contactNCSTable?.refresh();
            });

    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public saveEntry() {
        this.saveChanges();
    }

    public async avancee() {
        this._formHandlerService.showFormDialog(SalaryForm.Saparncsmot, {annee: this.getFormValue('annee')}, (s) => ({
            ...s,
            entityId: this.getFormValue('annee') ?? ''
        }));
    }

    public cancelEditionMode() {
        this._store.dispatch(new actions.SetReadMode(this.uuid));
    }

    public editEntry() {
        this._store.dispatch(new actions.SetEditMode(this.uuid));
    }

    public getContactNCSRow(event: ContactNcs[]) {
        if (!event.length) {
            this.selectedContactNCSRow = undefined;
            return;
        }
        this.selectedContactNCSRow = event[0];
    }

    public openNewContactNCSDialog() {
        this._formHandler.showFormDialog(SalaryForm.ContactNCS, {
            entryMode: 'add'
        });
    }

    public editContactNCS() {
        this._formHandler.showFormDialog(SalaryForm.ContactNCS, {
            entryMode: 'edit',
            contactNCS: {...this.selectedContactNCSRow}
        });
    }

    public removeContactNCS() {
        if (this.selectedContactNCSRow?.id && this.uuid) {
            this._store.dispatch(new EntityDelete(ContactNcs, this.selectedContactNCSRow?.id));
        }
        this.selectedContactNCSRow = undefined;
        this.contactNCSTable?.refresh();
    }

    protected getValidationType() {
        return Saparncs;
    }

    protected getFormControlNames(): FormKeys<Saparncs> {
        return [
            'annee',
            'ncsA',
            'ncsB',
            'ncsF',
            'ncsG',
            'ncsT23',
            'ncsT3',
            'ncsT4',
            'ncsT71',
            'ncsT72',
            'ncs1311',
            'ncsT1312',
            'ncsT1323',
            'ncsT141',
            'ncsT142',
            'ncsT15',
            'ncsT151',
            'ncsT152',
            'ncsT153',
            'ncsT154',
            'ncsT155',
            'nomunit',
            'adrunit',
            'npaunit',
            'lieuunit',
            'signature',
            'telephone',
            'ncsPrtnbh',
            'ncsPrtnbj',
            'contactNcsId'];
    }

    private getContactNCSColumns(): IwGridColumn<ContactNcs>[] {
        return [
            {
                prop: 'department',
                type: 'string',
                name: this._translate.instant('department'),
                index: 0
            },
            {
                prop: 'fullName',
                type: 'string',
                name: this._translate.instant('signature'),
                index: 1
            }];
    }
}
