import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwStoreService, SearchNavigationService} from '@app/sam-base/core';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {Sags} from '@app/sam-base/models/salary';
import {Sapar} from '@app/sam-base/models/salary/sapar';

@Component({
    selector: 'iw-tab-sapar-genres-de-salaire', templateUrl: './tab-sapar-genres-de-salaire.component.html',
    standalone: false
})
export class TabSaparGenresDeSalaireComponent extends BasePartialFormComponent<Sapar> {
    @Input() public uuid?: string;

    constructor(private _store: IwStoreService, private readonly searchNavService: SearchNavigationService) {
        super();
    }

    public openGenreSalaire() {
        this.searchNavService.navigate({entity: Sags});
        this._store.dispatch(new actions.DestroyForm(this.uuid || ''));
    }
}
