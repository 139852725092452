import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ParametersService} from '@app/core/services/parameters.service';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {FormHandlerService, IwEventHubService, ModalService} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {SaemsService} from '@app/sam-base/core/services/saems.service';
import {SaesService} from '@app/sam-base/core/services/saes.service';
import {Sapar} from '@app/sam-base/models/salary';
import {SaData} from '@app/sam-base/models/salary/saData';
import {Saemp} from '@app/sam-base/models/salary/saemp';
import {Saems} from '@app/sam-base/models/salary/saems';
import {Sagen} from '@app/sam-base/models/salary/sagen';

import {Sags} from '@app/sam-base/models/salary/sags';
import {SalaryItem} from '@app/sam-base/models/salary/salary-item';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {IwGridColumn} from '@sam-base/models';
import {lastValueFrom, Subject, Subscription, takeUntil} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {SalaryService} from 'src/app/modules/sam-main/salary/services/salary-service';
import {SalaryForm} from '../../../salary.forms';

@Component({
    selector: 'iw-tab-fichesal-fiche',
    templateUrl: './tab-fichesal-fiche.component.html',
    styleUrls: ['./tab-fichesal-fiche.component.scss'],
    standalone: false
})
export class TabFichesalFicheComponent extends BasePartialFormComponent<Saems> implements OnInit, OnDestroy {
    public tableElements: SalaryItem[] = [];
    public columns: IwGridColumn<SalaryItem>[] = [];
    public readonly = true;
    public newEntryMode = false;

    @Input() public Sapar: Sapar | undefined;

    @Input() public saemp?: Saemp;

    @Input() public sagen?: Sagen;

    @Input() public uuid?: string;
    public empId: string | undefined;
    public salId: string | undefined;
    public canton: string | undefined;
    public selectedRow?: SalaryItem;
    public tableLoading = true;
    public subscriptions = new Subject();
    public isJourISDisabled = true;
    private tableSubscription?: Subscription;
    private salcnt = 0;
    private readonly editableGs = [
        '1',
        '2',
        '3',
        '9',
        'A',
        'B',
        'C',
        'F',
        'M',
        'N',
        'V'];
    private _selectedSags?: Sags;
    private dateModif?: Date;

    constructor(private readonly _restApiService: RestApiService, private readonly _modalService: ModalService,
                private readonly _salaryService: SalaryService, private readonly _translate: TranslateService,
                private readonly _parametersService: ParametersService,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _eventService: IwEventHubService<string>, private _rest: RestApiService,
                private _saemsService: SaemsService, private _saesService: SaesService) {
        super();
        this.populateColumns();

        this._parametersService
            .getSaparDataText<SaData>('saData')
            .subscribe((saData) => {
                if (saData.bscalcversion === 'SD4') {
                    this.isJourISDisabled = false;
                }
            });
    }

    public get canAdd(): boolean {
        return (!this.isReadonly && this.sagen?.status === 1 && !this.getFormValue('paid') && !this.salHasFac);
    }

    // eslint-disable-next-line complexity
    public get canEdit(): boolean {
        return (!!this.selectedRow && !this.isReadonly && this.salcnt > 0 && [
            1,
            2,
            3,
            4].includes(this.sagen?.status ?? -1) && !this.getFormValue('paid') && !this.salHasFac && this.gsCanEdit);
    }

    // eslint-disable-next-line complexity
    public get canDelete(): boolean {
        return (!!this.selectedRow && !this.isReadonly && this.salcnt > 0 && 1 === this.sagen?.status && !this.getFormValue('paid') && !this.salHasFac && this.gsCanEdit);
    }

    public get canPaiProv(): boolean {
        return this.canAdd;
    }

    private get salHasFac(): boolean {
        return !!this.getFormValue('facId');
    }

    private get gsCanEdit(): boolean {
        return this.editableGs.includes(this._selectedSags?.gsCtype ?? '');
    }

    public ngOnInit(): void {
        this.subscribeValueChange('dateModif', (dateModif) => {
            if (dateModif !== this.dateModif) {
                this.getTableData(false);
            }
            this.dateModif = dateModif as Date;
        });
        this.checkLoadedControls();
        this._saemsService.elSalSubject
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data) => {
                if (data.uuid === this.uuid && data.saved) {
                    this.getTableData(false);
                }
            });
        this._eventService
            .forType<any>('payerprov_closed')
            .pipe(takeUntil(this.subscriptions))
            .subscribe(async () => {
                await this.getTableData(false);
            });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        this.tableSubscription?.unsubscribe();
    }

    public async getTableData(saises: boolean) {
        if (!this.empId && !this.salId) return;
        this.tableLoading = true;
        this.tableElements = await lastValueFrom(this._restApiService.http.post<SalaryItem[]>(environment.backendURL + 'fichesal/list', {
            empId: this.empId,
            salId: this.salId,
            saises
        }));
        if (this.tableElements.length) {
            this.selectedRow = this.tableElements[0];
            this.getSagen();
        }
        this.tableLoading = false;
    }

    public createNewEntry() {
        this.newEntryMode = true;
        this._formHandler.showFormDialog(SalaryForm.ElementSalaire, {
            empId: this.empId,
            salId: this.salId,
            esId: this.selectedRow?.esId,
            isNew: true,
            saemp: this.saemp,
            sags: this._selectedSags,
            parentUuid: this.uuid
        });
    }

    public modifyEntry(): void {
        this.newEntryMode = false;
        this._formHandler.showFormDialog(SalaryForm.ElementSalaire, {
            empId: this.empId,
            salId: this.salId,
            esId: this.selectedRow?.esId,
            saemp: this.saemp,
            sags: this._selectedSags,
            parentUuid: this.uuid
        });
    }

    public deleteEntry() {
        if (!this.selectedRow) {
            return;
        }
        this._saesService.deleteSaes(this.selectedRow.esId ?? '', this.empId ?? '', this.salId ?? '')
            .subscribe(() => this.getTableData(false));
        return;
    }

    public async openJourIs() {
        this._formHandler.showFormDialog(SalaryForm.JourIS, {
            empId: this.empId,
            salId: this.salId,
            esId: this.selectedRow?.esId,
            canton: this.getFormValue('cantontrav')
        });
    }

    // Deprecacted : SO-728 // to be removed once sure we don't need it anymore
    public async openRepEle() {
        if (this.empId && this.salId && this.selectedRow?.esId) {
            const text = await lastValueFrom(this._restApiService.http.get<string>(environment.backendURL + 'fichesal/repEle', {
                params: {
                    empId: this.empId,
                    salId: this.salId,
                    esId: this.selectedRow?.esId
                }
            }));

            if (text) {
                const options: ModalMessageOptions = {
                    message: [text],
                    showCancel: true,
                    title: 'SAM',
                    width: 200
                };
                this._modalService.showModal(ModalMessageComponent, options);
            }
        }
    }

    public openPayerProv() {
        this._formHandler.showFormDialog(SalaryForm.PayerProv, {
            empId: this.empId,
            salId: this.salId
        });
    }

    public populateTableSaise(event: boolean) {
        this.getTableData(event);
    }

    public checkLoadedControls(): void {
        const empId = this.getFormValue<string>('empId');
        const salId = this.getFormValue<string>('salId');
        if (empId === undefined && salId === undefined) {
            setTimeout(() => {
                this.checkLoadedControls();
            }, 500);
        } else {
            this.empId = empId;
            this.salId = salId;
            this.getTableData(false);
            if (!this.tableSubscription) {
                this.tableSubscription = this.formGroup?.controls['id'].valueChanges
                    .pipe(debounceTime(300))
                    .subscribe(() => {
                        this.checkLoadedControls();
                    });
            }
        }
    }

    public getSelectedRow(items: SalaryItem[]) {
        this.readonly = true;
        this.selectedRow = items[0];
        this.getSagen();
        this._rest
            .getEntityClient(Sags)
            .getById(this.selectedRow?.gsId ?? '')
            .subscribe((sags) => (this._selectedSags = sags));
    }

    public onUnselectedRow() {
        this.readonly = false;
        this.selectedRow = undefined;
        this.salcnt = 0;
        this._selectedSags = undefined;
    }

    /*eslint-disable complexity */
    /**
     * Show values on the texfields ommiting zero values according to legacy
     *
     * @param trype name of the control
     */
    public getValue(type: string): string {
        switch (type) {
            case 'prBase':
                if (this.selectedRow?.base) {
                    return this.selectedRow?.base === '0.00' ? '' : this.selectedRow?.base;
                }
                break;
            case 'ctmpBase':
                if (this.selectedRow?.base !== undefined && this.selectedRow?.montant) {
                    const montant = this.selectedRow?.montant === '0.00' ? '' : this.selectedRow?.montant;
                    const base = this.selectedRow.base === '0.00' ? '' : this.selectedRow.base;
                    return base === '' ? montant : base;
                }
                break;
            case 'prTaux':
                if (this.selectedRow?.taux) {
                    return this.selectedRow?.taux === '0.0000' ? '' : this.selectedRow?.taux;
                }
                break;
            case 'realTaux':
                if (this.selectedRow?.taux) {
                    let taux = this.selectedRow.taux === '0.00%' ? '' : this.selectedRow.taux;
                    taux = taux === '0.0000%' ? '' : taux;
                    return taux;
                }
                break;
            case 'ctmpMnt':
                if (this.selectedRow?.montant) {
                    return this.selectedRow?.montant === '0.00' ? '' : this.selectedRow?.montant;
                }
                break;
        }
        return '';
    }

    public populateColumns() {
        this.columns = [
            {
                index: 0,
                name: this._translate.instant('gs'),
                prop: 'gsId'
            },
            {
                index: 1,
                name: this._translate.instant('combo_texte'),
                prop: 'libelle'
            },
            {
                index: 2,
                name: this._translate.instant('base'),
                type: 'mnt',
                align: 'right',
                prop: 'base'
            },
            {
                index: 3,
                name: 'U.',
                prop: 'unite'
            },
            {
                index: 4,
                name: this._translate.instant('sadedtx_form_title'),
                prop: 'taux',
                type: 'mnt',
                align: 'right'
            },
            {
                index: 5,
                name: this._translate.instant('facteur'),
                prop: 'fact',
                align: 'right'
            },
            {
                index: 6,
                name: this._translate.instant('montant'),
                type: 'mnt',
                align: 'right',
                prop: 'montant'
            },
            {
                index: 7,
                name: 'M',
                prop: 'modified'
            },
            {
                index: 8,
                name: 'O',
                prop: 'origine'
            },
            {
                index: 9,
                name: this._translate.instant('parent'),
                prop: 'parent'
            },
            {
                index: 10,
                name: this._translate.instant('correction'),
                prop: 'salIdCor',
                hidden: true

            },
            {
                index: 11,
                name: this._translate.instant('version'),
                prop: 'gsIdVersion',
                hidden: true
            }];
    }

    private getSagen() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => this._saemsService
                .getSaemsByEmpIdAndYear(this.empId ?? '', sapar.annee ?? '')
                .subscribe((saems) => (this.salcnt = saems.length)));
    }
}
