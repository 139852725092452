import {AfterViewInit, Component, Input, OnInit, Type, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {FormHandlerService} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {ComboboxItem, IwGridColumn} from '@app/sam-base/models';
import {Sadsview, Sapar} from '@app/sam-base/models/salary';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';

import {SalaryForm} from '../../../salary.forms';
import {SalhebdoInput} from './tab-saemp-salaire-vacances-enums';

@Component({
    selector: 'iw-tab-saemp-salaire-vacances', templateUrl: './tab-saemp-salaire-vacances.component.html',
    standalone: false
})
export class TabSaempSalaireVacancesComponent extends BasePartialFormComponent<Saempc> implements OnInit, AfterViewInit {
    @Input() public sapar?: Sapar;
    public sadsview?: Sadsview;
    public saempc?: Saempc;

    public selectedRow?: Sadsview;

    public type: Type<Sadsview> = Sadsview;

    public sadsColumns = this.getSadsColumns();
    public salhebdoInput = SalhebdoInput;

    public query: RestQueryParam<Sadsview, any>[] = [{
        operation: RestQueryOperation.Equals, prop: 'empId', value: '_'
    }];

    public anneeFilter = false;
    public employeFilter = false;

    public sortProp?: keyof Sadsview = 'gsId';
    public sortPropDir?: 'asc' | 'desc' = 'asc';

    // Julien asked to hide 2 checkboxes on SO-205
    public hide = false;

    @ViewChild('gridSads') public grid?: IwRestGridComponent<Sadsview>;

    public modeType: ComboboxItem<number>[] = [{
        name: this._translate.instant('combo_mensuel'), value: 1
    }, {name: this._translate.instant('combo_manuel'), value: 2}, {
        name: this._translate.instant('combo_horaire'), value: 3
    }, {
        name: this._translate.instant('combo_horaiteTempo'), value: 4
    }, {name: this._translate.instant('combo_journalier'), value: 5}];

    public modeVacancesType: ComboboxItem<number>[] = [{
        name: this._translate.instant('aucun'), value: 1
    }, {name: this._translate.instant('annuel'), value: 2}, {name: this._translate.instant('combo_mensuel'), value: 3}];

    constructor(private readonly _translate: TranslateService, private readonly _parametersService: ParametersService, private readonly _formHandler: FormHandlerService<SalaryForm>) {
        super();
    }

    public get saempId() {
        return this.getFormValue<string>('empId');
    }

    public ngOnInit() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => (this.sapar = sapar));
        this.setQueryStart();
        this.subscribeValueChange('salhebdo', (salhebdo) => {
            this.onSalHebdoValueChange(salhebdo as string);
        });
        this.formGroup?.controls['txactioth']
            .setValidators([Validators.min(0), Validators.max(100)]);
        this.formGroup?.controls['txactioth']
            .updateValueAndValidity();
        this.subscribeValueChange('hparS', (hparS) => {
            const txactiviteFormControl = this.getFormControl('txactivite');
            if (this.getFormValue('salhebdo') === 'steady') {
                if (Number(hparS) > 0) {
                    const min = ((this.getFormValue('hparS') as number * 100) / 40) - 5;
                    const max = ((this.getFormValue('hparS') as number * 100) / 40) + 5;
                    txactiviteFormControl?.setValidators([Validators.min(min), Validators.max(max)]);
                    txactiviteFormControl?.updateValueAndValidity();
                } else if (Number(hparS) === 0) {
                    txactiviteFormControl?.setValidators(Validators.max(0));
                } else {
                    txactiviteFormControl?.clearValidators();
                    txactiviteFormControl?.updateValueAndValidity();
                }
            } else {
                txactiviteFormControl?.clearValidators();
                txactiviteFormControl?.updateValueAndValidity();
            }
        });
        // eslint-disable-next-line complexity
        this.subscribeValueChange('txactivite', (txactivite: number | undefined) => {
            if (this.getFormValue('salhebdo') === 'steady' && this.getFormValue('hparS')) {
                const min = ((this.getFormValue('hparS') as number * 100) / 40) - 5;
                const max = ((this.getFormValue('hparS') as number * 100) / 40) + 5;
                this.formGroup?.controls['txactivite']
                    .setValidators([Validators.max(max), Validators.min(min)]);
                this.formGroup?.controls['txactivite']
                    .updateValueAndValidity({onlySelf: false, emitEvent: false});
            }
        });
    }

    public ngAfterViewInit() {
        if (this.grid) {
            this.grid.sortProp = 'gsId';
            this.grid.sortDir = 'asc';
        }
    }

    public openDefSalaire() {
        this._formHandler.showFormDialog(SalaryForm.DefinitionSalaire, {
            empId: this.saempId, ifactsal: this.getFormValue('ifactsal'), ifactsalfu: this.getFormValue('ifactsalfu')
        });
    }

    public setAnneeFilter(event: boolean) {
        this.anneeFilter = event;
        this.employeFilter = false;
        this.setQueryStart();
    }

    public setEmployeFilter(event: boolean) {
        this.employeFilter = event;
        this.anneeFilter = false;
        this.setQueryStart();
    }

    public salhebdoDisabled(): boolean {
        return this.isReadonly;
    }

    private onSalHebdoValueChange(salhebdo: string) {
        const hparS = this.getFormControl('hparS');
        const lparS = this.getFormControl('lparS');
        if (salhebdo === SalhebdoInput.regulierement) {
            hparS?.setValidators(Validators.required);
            lparS?.setValidators(Validators.required);
        } else {
            hparS?.clearValidators();
            lparS?.clearValidators();
        }
        hparS?.updateValueAndValidity();
        lparS?.updateValueAndValidity();
        if (salhebdo === SalhebdoInput.regulierement && this.getFormValue('hparS')) {
            const min = ((this.getFormValue('hparS') as number * 100) / 40) - 5;
            const max = ((this.getFormValue('hparS') as number * 100) / 40) + 5;
            this.formGroup?.controls['txactivite']
                .setValidators([Validators.min(min), Validators.max(max)]);
            this.formGroup?.controls['txactivite']
                .updateValueAndValidity();
        } else {
            this.formGroup?.controls['txactivite']
                .clearValidators();
            this.formGroup?.controls['txactivite']
                .updateValueAndValidity();
        }
    }

    private subscribeEmpId() {
        this.subscribeValueChange('empId', (id?: string) => {
            if (!id) {
                return;
            }
            this.setQuery(id);
        });
    }

    private setQueryStart() {
        this.subscribeEmpId();
        const id: string | undefined = this.getFormValue('empId');
        if (!id) {
            return;
        }
        this.setQuery(id);
    }

    private setQuery(empId: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'empId', value: empId
        }];

        if (this.anneeFilter && this.sapar?.annee) {
            this.query.push({
                operation: RestQueryOperation.GreaterOrEqual, prop: 'fin', value: `${this.sapar?.annee}-01-01 00:00:00`
            }, {
                operation: RestQueryOperation.LessOrEqual, prop: 'debut', value: `${this.sapar?.annee}-01-01 00:00:00`
            });
        }

        if (this.employeFilter) {
            this.query.push({
                operation: RestQueryOperation.NotEquals, prop: 'gsCtype', value: '9'
            });
        }
    }

    /* Columns to show in  table */
    private getSadsColumns(): IwGridColumn<Sadsview>[] {
        return [{
            prop: 'gsId', name: 'gs', index: 0
        }, {
            prop: 'montant', name: 'montant', index: 1, decimals: 2
        }, {
            prop: 'frequence', name: 'frequence', index: 2
        }, {
            prop: 'debut', name: 'debut', index: 3
        }, {
            prop: 'fin', name: 'fin', index: 4
        }];
    }
}
