import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@sam-base/base';
import {CliMode, Gepar} from '@sam-base/models/invoices';

@Component({
    selector: 'iw-tab-gepar-autre', templateUrl: './tab-gepar-autre.component.html',
    standalone: false
})
export class TabGeparAutreComponent extends BasePartialFormComponent<Gepar> {

    // ftrclimode: Sans le pr�fixe,Avec le pr�fixe (10),ID client
    protected readonly CliMode = CliMode;
}
