import {Component, OnDestroy, OnInit, Type, ViewChild} from '@angular/core';
import {Validators} from '@angular/forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwSmartGridComponent} from '@app/sam-base/components/iw-smart-grid/iw-smart-grid.component';
import {IwEventHubService, MapsService} from '@app/sam-base/core';
import {GridProfile} from '@app/sam-base/core/grid-profile/models';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {EntityCreate, EntityDelete, EntityUpdate, IwStoreService} from '@app/sam-base/core/store';
import {DestroyForm} from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {FormKeys} from '@app/sam-base/models';
import {Npa} from '@app/sam-base/models/common/npa';
import {Ppcha} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@root/environments/environment';
import {lastValueFrom, Observable, Subject} from 'rxjs';

@Component({
    templateUrl: './ppclichagrd.component.html',
    standalone: false
})
export class PpclichagrdComponent extends BaseFormComponent<Ppcha> implements OnInit, OnDestroy {
    public type: Type<Ppcha> = Ppcha;

    public selectedRow: Ppcha = {};

    public clichaId = '';

    public gridProfile?: GridProfile<Ppcha>;

    @ViewChild('ppchaGrid', {static: true}) public ppchaGrid?: IwSmartGridComponent<Ppcha>;

    public get entityId(): string {
        return this.getData('entityId') || '';
    }

    public get fromRapportMode(): boolean {
        return this.getData('fromRapportMode') || false;
    }

    public query: RestQueryParam<Ppcha, string>[] = [];
    public isReadonly = true;

    public ijOn$?: Observable<boolean>;

    private readonly _restClient: RestEntityClient<Ppcha>;
    private readonly _npaRestClient: RestEntityClient<Npa>;

    private _subscripts = new Subject();

    constructor(rest: RestApiService, public mapsService: MapsService, private readonly _toast: ToastService, private _store: IwStoreService, private _translate: TranslateService, private readonly _events: IwEventHubService<string>) {
        super();
        this._restClient = rest.getEntityClient(Ppcha);
        this._npaRestClient = rest.getEntityClient(Npa);
        this.mapsService.setApiKey(environment.googleAPI);
        this.isReadonly = true;
        this.ijOn$ = this._store.getLicenseOption('interijobwebaccess');
    }

    public ngOnInit() {
        this.setQuery();
        this.setMandatoryFields();
    }

    public ngOnDestroy() {
        this._subscripts.next(undefined);
        this._subscripts.complete();
        super.ngOnDestroy();
    }

    /**
     * Function to get lieu by npa
     *
     * @returns void
     */

    public async getLieuByNpa() {
        try {
            const npa: string = this.getFormValue('npa');
            if (this.canGenerateNpa(npa)) {
                const lieu: Npa = await lastValueFrom(this._npaRestClient.getById(npa));
                this.setFormValue('lieu', lieu.lieu);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    public setQuery() {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'cliId', value: this.entityId
        }];
    }

    /**
     * Function to get selected row
     *
     * @param event Ppcha[]
     * @returns void
     */
    public getSelectedRow(event: Ppcha[]) {
        if (!event.length) {
            this.selectedRow = {};
            this.clearForm();
            return;
        }

        this.clichaId = (event[0] && event[0].clichaId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);
    }

    /**
     * Function to create new ppcha entry
     *
     * @returns void
     */
    public createNewEntry() {
        this.selectedRow = {};
        this.isReadonly = false;
        this.formGroup.reset();
    }

    /**
     * Function to save changes on existing or new entry
     *
     * @returns void
     */
    public async saveEntry() {
        if (this.getFormValue('adresse1') && this.getFormValue('npa') && this.getFormValue('lieu')) {
            this.setGeoRad();
        } else {
            this.setFormValue('geoRad', 0);
            this.updateToSave();
        }
    }

    /**
     * Delete the selected entry
     *
     * @returns void
     */
    public deleteEntry() {
        if (this.selectedRow && this.selectedRow.clichaId) {
            const id = this.selectedRow.clichaId;
            this._store.dispatch(new EntityDelete(Ppcha, id));
            this.clearForm();
        }
    }

    public updateToSave() {
        const formData = this.getFormData();
        const cliId = {cliId: this.entityId};
        const updatedData = this._restClient
            .construct({...this.selectedRow, ...formData, ...cliId});
        this.isReadonly = true;
        this.clearForm();

        if (!updatedData) {
            return;
        }
        // If clichaId doesn't exist, it's a new entry
        // Otherwise an update
        if (!updatedData.clichaId) {
            updatedData.clichaId = '';
            this._store.dispatch(new EntityCreate(updatedData));
        } else {
            this._store.dispatch(new EntityUpdate(updatedData));
        }
    }

    /**
     * Set fields editable
     *
     * @returns void
     */
    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.clichaId) {
            this.isReadonly = false;
        }
    }

    /**
     * Can edit the geoRad field
     *
     * @returns boolean
     */
    public canEditGeoRad(): boolean {
        // eslint-disable-next-line complexity
        const canEdit = !this.isReadonly && this.getFormValue('adresse1') && this.getFormValue('npa') && this.getFormValue('lieu');

        return canEdit;
    }

    /**
     * Cancel the edition mode
     *
     * @returns void
     */
    public cancelEditionMode() {
        this.fillFormData(this.selectedRow);
        this.isReadonly = true;
    }

    public applyAndCloseDialog() {
        this._events.emit('chantier_closed_and_update', this.selectedRow.chantier);
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    public isEntrySelected(): boolean {
        return this.selectedRow && !!this.selectedRow.clichaId;
    }

    public closeDialog() {
        this._store.dispatch(new DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Ppcha> {
        return ['chantier', 'adresse1', 'npa', 'lieu', 'geoRad', 'geoLon', 'geoLat'];
    }

    private async setGeoRad() {
        const adresse1 = this.getFormValue('adresse1');
        const npa = this.getFormValue('npa');
        const lieu = this.getFormValue('lieu');
        const address = adresse1 + ' ' + npa + ' ' + lieu;
        this.mapsService.getGeolocation(address)
            .subscribe(value => {
                if (value.results.length) {
                    this.setFormValue('geoLat', value.results[0].geometry.location.lat);
                    this.setFormValue('geoLon', value.results[0].geometry.location.lng);
                    const message = this._translate.instant('location_text') + this.getFormValue('geoLat') + ' ' + this.getFormValue('geoLon');
                    this._toast.success(message);
                    this.updateToSave();
                } else {
                    this.setFormValue('geoRad', 0);
                    this._toast.warning(this._translate.instant('no_location'));
                }
            });
    }

    private setMandatoryFields() {
        this.formGroup.controls['chantier'].setValidators(Validators.required);
        this.formGroup.controls['chantier'].updateValueAndValidity();
    }

    private canGenerateNpa(npa?: string) {
        return npa?.length === 4 && !this.getFormValue('lieu') && !this.isReadonly;
    }
}
