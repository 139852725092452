import { HttpClient } from '@angular/common/http';
import {AfterViewInit, Component, OnInit, Type, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwRestGridComponent} from '@app/sam-base/components';
import {IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {SaeioService} from '@app/sam-base/core/services/saeio.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormState} from '@app/sam-base/core/store/reducers/form.reducer';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Saeio} from '@app/sam-base/models/salary/saeio';
import {Satbl} from '@app/sam-base/models/salary/satbl';
import {environment} from '@root/environments/environment';

@Component({
    templateUrl: './saeio.component.html',
    standalone: false
})
export class SaeioComponent extends BaseFormComponent<Saeio> implements OnInit, AfterViewInit {
    public formState: FormState | undefined;
    public saeio?: Saeio;
    public type: Type<Saeio> = Saeio;
    public query: RestQueryParam<Saeio, string | Date | number>[] = [];
    @ViewChild('saeioConfigGrid') public saeioGrid?: IwRestGridComponent<Saeio>;
    public tableWidth = '250px';
    public motifOptions: ComboboxItem<number>[] = [{name: ' ', value: 100}];
    public sortProp?: keyof Saeio = 'dateIn';
    public sortPropDir?: 'asc' | 'desc' = 'desc';
    public selectedRow?: Saeio;
    public readonly = true;
    public newEntryMode = false;
    private _latestDateOut: Date | undefined;
    private _editableSaeioId = 0;
    private _baseUrl = ' ';
    private _isSaving = false;
    private readonly _restSaeioClient: RestEntityClient<Saeio>;

    constructor(private readonly _http: HttpClient, rest: RestApiService, private _store: IwStoreService, private _saeioService: SaeioService) {
        super();
        this._baseUrl = environment.backendURL;
        this._restSaeioClient = rest.getEntityClient(Saeio);
    }

    public get canEdit(): boolean {
        return this.selectedRow?.saeioId === this._editableSaeioId;
    }

    public get isNewButtonEnable(): boolean {
        return this._editableSaeioId ? this._latestDateOut instanceof Date : true;
    }

    public get minDateDateIn(): Date | undefined {
        return this.newEntryMode ? this._latestDateOut : undefined;
    }

    public get minDateDateOut(): Date | undefined {
        return new Date(this.getFormValue('dateIn'));
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public get dateOut() {
        const dateOut = this.getFormValue('dateOut');
        if (dateOut) {
            return true;
        } else {
            return false;
        }
    }

    public ngOnInit() {
        this.setQuery(this.empId);
        this.getFormValue('empId');
        this.getOptions();
        this.getLatestSaeio();
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            if (this.saeioGrid) {
                this.saeioGrid.sortProp = this.sortProp || 'dateIn';
                this.saeioGrid.sortDir = this.sortPropDir || 'desc';
            }
        }, 0);
    }

    public getSelectedRow(event: Saeio[]) {
        if (this._isSaving) {
            this._isSaving = false;
            return;
        }
        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }
        this.selectedRow = event[0];
        this.fillFormData(event[0]);
    }

    public getOptions(tableNo: string = '31', recType: number = 0) {
        return this._http
            .get<Satbl>(this._baseUrl + `satbl/` + tableNo + '/' + recType)
            .subscribe((res: any) => {
                for (const k of Object.keys(res)) {
                    this.motifOptions.push({name: res[k].titre, value: res[k].elemId});
                }
            });
    }

    public createNewEntry() {
        this.selectedRow = {};
        this.readonly = false;
        this.newEntryMode = true;
        this.formGroup.reset();
    }

    public modifyEntry(): void {
        if (this.selectedRow) {
            this.readonly = false;
            this.newEntryMode = false;
        }
    }

    public saveEntry(): void {
        this.setFormValues();
        const formData = this.getFormData();

        const updatedData = this._restSaeioClient.construct({
            ...this.selectedRow, ...formData
        });

        if (this.newEntryMode) {
            this._saeioService.createNewEntry(updatedData)
                .subscribe(() => {
                    this.saeioGrid?.refresh();
                });
            this.newEntryMode = false;
        } else {
            this._saeioService
                .saveByEmpIdAndSaeioId(this.getFormValue('saeioId'), updatedData)
                .subscribe(() => {
                    this.refreshData();
                    this.readonly = true;
                });
        }
    }

    public removeEntry() {
        if (this.selectedRow && this.selectedRow.empId) {
            this._saeioService
                .deleteByEmpIdAndSaeioId(this.getFormValue('saeioId'))
                .subscribe(() => {
                    this.refreshData();
                });
        }
    }

    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.readonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getValidationType() {
        return Saeio;
    }

    protected getFormControlNames(): FormKeys<Saeio> {
        return ['empId', 'saeioId', ['dateIn', new UntypedFormControl(undefined, Validators.required)], 'dateOut', 'motif'];
    }

    protected validateFields(e: Saeio): boolean {
        return super.validateFields(e);
    }

    private getLatestSaeio() {
        this._saeioService.getLatestSaeioByEmpId(this.empId)
            .subscribe((res: Saeio) => {
                this._latestDateOut = undefined;
                this._editableSaeioId = 0;
                if (res?.saeioId) {
                    if (res.dateOut) {
                        this._latestDateOut = new Date(res.dateOut);
                        this._latestDateOut.setDate(this._latestDateOut.getDate() + 1);
                    }
                    this._editableSaeioId = res.saeioId;
                }
            });
    }

    private setQuery(empId: string) {
        this.query = [{
            operation: RestQueryOperation.Equals, prop: 'empId', value: empId
        }];
    }

    private setFormValues() {
        if (this.getFormValue('dateIn') && this.getFormValue('dateIn').length < 11) {
            const dateIn = this.getFormValue('dateIn') + 'T00:00:00.000Z';
            this.setFormValue('dateIn', dateIn);
        }

        if (this.getFormValue('dateOut') && this.getFormValue('dateOut').length < 11) {
            const dateOut = this.getFormValue('dateOut') + 'T00:00:00.000Z';
            this.setFormValue('dateOut', dateOut);
        }

        this.setFormValue('empId', this.empId);
    }

    private refreshData() {
        this.saeioGrid?.refresh();
        this.getLatestSaeio();
    }
}
