import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {ModalService} from '@sam-base/core';

@Component({
    selector: 'iw-tab-saemp-autre',
    templateUrl: './tab-saemp-autre.component.html',
    standalone: false
})
export class TabSaempAutreComponent extends BasePartialFormComponent<Saempc> {
    @Input() public sapar?: Sapar;
    @Input() public empId?: string;


    constructor(private _modalService: ModalService) {
        super();
    }

    public laaEmailBSSend() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.SAEMP_LAA_BS,
            entityIds: [this.empId ?? '']
        });
    }

    public pgmEmailBSSend() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.SAEMP_PGM_BS,
            entityIds: [this.empId ?? '']
        });
    }
}
