import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {IwGridColumn, ModalComponent, RowClickEvent} from '@app/sam-base/models';
import {PrintRequestService} from '@core/print/print-request.service';
import {
    PrintRequestManagerOptions
} from '@modules/sam-main/admin/components/print-request/print-request-manager.options';
import {PrintRequestView} from '@modules/sam-main/admin/models/print-request-view.model';
import {PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {IwRestGridComponent} from '@sam-base/components';
import {EntityStatusMap} from '@sam-base/components/iw-grid-column-config/entity-status-map';
import {EntityNavigationService, RestQueryOperation, RestQueryParam, SearchEntityStatusOperator} from '@sam-base/core';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {GerplhdrView} from '@sam-base/models/invoices/gerplhdrview';
import {Saemsview} from '@sam-base/models/salary/saemsview';

@Component({
    templateUrl: './print-request-manager.component.html',
    standalone: false
})
export class PrintRequestManagerComponent<T> implements ModalComponent<string, PrintRequestManagerOptions>, OnInit {
    public pressOk = new EventEmitter<void>();
    public pressCancel = new EventEmitter<void>();
    public loading = false;
    @ViewChild('printRequestGrid') public grid?: IwRestGridComponent<PrintRequestView>;
    public query: RestQueryParam<PrintRequestView, string>[] = [];
    public columnsToShow: IwGridColumn<PrintRequestView>[] = [
        {
            name: 'id',
            prop: 'id',
            type: 'number',
            hidden: true
        },
        {
            name: 'type',
            prop: 'type',
            enumPrefix: 'print_request.type',
            valueFormat: 'print_request_type',
            type: 'enum'
        },
        {
            name: 'description',
            prop: 'description',
        },

        {
            name: 'ageId',
            prop: 'ageId',
        },
        {
            name: 'client',
            prop: 'recipient',
        },
        {
            name: 'adresse',
            prop: 'address',
        },
        {
            name: 'npa',
            prop: 'postalCode',
        },
        {
            name: 'lieu',
            prop: 'city',
        },
        {
            name: 'pays',
            prop: 'country',
        },
        {
            name: 'approved',
            prop: 'approved',
            type: 'boolean',
            hidden: true,
        },
        {
            name: 'downloadedAt',
            prop: 'downloadedAt',
            type: 'dateTime'
        }
    ];
    public selectedPrintRequest: PrintRequestView[] = [];
    public origin?: string;
    protected readonly typePrintRequest = PrintRequestView;
    private _ok?: (result: string) => void;
    private _cancel?: (result?: undefined) => void;

    constructor(private readonly _printRequestService: PrintRequestService,
                private _navigationService: EntityNavigationService) {
    }

    private _scopeIds?: string[];

    public set scopeIds(ids: string[] | undefined) {
        this._scopeIds = ids;
        if (this._scopeIds === undefined) {
            return;
        }
        let idQuery = this.query.find(q => q.prop === 'id');
        if (!idQuery) {
            idQuery = {
                operation: RestQueryOperation.In,
                prop: 'id',
                value: this._scopeIds
            };
            this.query.push(idQuery);
        } else {
            idQuery.operation = RestQueryOperation.In;
            idQuery.prop = 'id';
            idQuery.value = this._scopeIds;
        }
        // remove approved filter if existing, because we want to show all of them in the table in case we're coming from a scope of ids
        this.query = this.query.filter(q => q.prop !== 'approved');
    }


    private _printRequestType?: PrintRequestType;

    public set printRequestType(value: PrintRequestType | undefined) {
        this._printRequestType = value;
        if (value === undefined) {
            this.query = [];
            return;
        }
        const column = this.columnsToShow.find(e => e.prop === 'type');
        if (column) {
            column.statusQuery = EntityStatusMap.getFilterBooleanConfig('print_request_type', [this._printRequestType]);
            column.filterOperator = SearchEntityStatusOperator.In;
            column.indexed = false;
        }
        this.query = [
            {
                operation: RestQueryOperation.Equals,
                prop: 'approved',
                value: 'false'
            },
            {
                operation: RestQueryOperation.In,
                prop: 'type',
                value: EntityStatusMap.getFilterStringConfig('print_request_type', [this._printRequestType])
            }
        ];
    }

    public ngOnInit(): void {
    }

    public setData(data: PrintRequestManagerOptions): Promise<void> {
        this.printRequestType = data.type;
        this.scopeIds = data.ids;
        this.origin = data.origin
        return Promise.resolve();
    }

    public registerOk(action: (result: string) => void): void {
        this._ok = action;
    }

    public registerCancel(action: (error?: any) => void): void {
        this._cancel = action;
    }

    public onBeforeHide() {
        if (this._ok) {
            this._ok('');
        }
    }

    public downloadDocument() {
        this.loading = true;
        this._printRequestService.download(this.selectedPrintRequest.map(e => e.id!)).subscribe({
            next: () => {
                this.grid?.refresh();
            },
            error: (error) => {
                this.loading = false;
                console.error(error);
            },
            complete: () => {
                this.loading = false;
            }
        });
    }

    public onSelection($event: PrintRequestView[]) {
        this.selectedPrintRequest = $event;
    }

    public onRowDoubleClick($event: RowClickEvent<PrintRequestView>) {
        const printRequest = $event.row;
        switch (printRequest.type) {
            case PrintRequestType.INVOICE:
                this._navigationService.navigateToEntityForm(Gefacliview, printRequest.entityId!, undefined, 'read');
                break;
            case PrintRequestType.INVOICE_REMINDER:
                this._navigationService.navigateToEntityForm(GerplhdrView, printRequest.entityId!, undefined, 'read');
                break;
            case PrintRequestType.SALARY:
                this._navigationService.navigateToEntityForm(Saemsview, printRequest.entityId!, undefined, 'read');
                break;
            default:
                break;
        }
    }
}