import {Component, OnDestroy, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {PpempService} from '@app/modules/sam-main/placement/services/ppemp.service';
import {BaseTabFormComponent} from '@app/sam-base/base';
import {
    EntityNavigationService, PpkwService, RestQueryOperation, RestQueryParam, TabFormControl
} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store';
import * as globalActions from '@app/sam-base/core/store/actions/global-form.actions';
import {Ppcdf, Ppcli, ScheduleEvent} from '@app/sam-base/models/placement';
import {Actions, ofType} from '@ngrx/effects';
import {FormKeys} from '@sam-base/models';
import {lastValueFrom, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {MatchingService} from '../../services/matching.service';
import {cdefixeTabsFormControls} from './cdefixe-tabs-formcontrols';

@Component({
    templateUrl: './cdefixe.component.html',
    standalone: false
})
export class CdefixeComponent extends BaseTabFormComponent<Ppcdf> implements OnInit, OnDestroy {

    public get cdfId() {
        return this.getFormValue('cdfId') || '';
    }

    public get commandeProfession() {
        return this._commandeProfession;
    }

    public clientName = '';
    public disabledTabList: string[] = [];

    private subscriptions = new Subject();
    private readonly _ppcliRest: RestEntityClient<Ppcli>;

    private _commandeProfession = '';

    constructor(store: IwStoreService, private _restService: RestApiService, private _actions: Actions, private _pkwService: PpkwService, private _entityNavService: EntityNavigationService, private _ppempService: PpempService, private readonly _matchingService: MatchingService) {
        super(store);
        this.setNeedSummaryValidators();
        this._ppcliRest = this._restService.getEntityClient(Ppcli);
    }

    public ngOnInit() {
        if (this.getFormValue('cdfId') === undefined) {
            this.disabledTabList.push('layout_menu_placement');
        }
        this.subscribeValueChange<string>('cdfId', (cdfId) => {
            if (cdfId !== undefined) {
                this.disabledTabList = [];
            }
            this.updateScheduleEvent(cdfId);
        });
        this.subscribeCliId();
        this.subscribeToSaveEffect();
        this.subscribeProfession();
        this.subscribeValueChange<string>('needSummary', e => this.onNeedSummaryChange(e));
    }

    // TslintcdeStatus:disable-next-line: cyclomatic-complexity
    public getCdfClass() {
        let cdfStatus = this.getFormValue('cdfstatus');
        if (cdfStatus === 0) {
            cdfStatus = '0';
        } else if (cdfStatus === 1) {
            cdfStatus = '1';
        } else if (cdfStatus === 2) {
            cdfStatus = '2';
        } else {
            cdfStatus = 'd';
        }
        return 'cde-color-' + cdfStatus;
    }

    public getTabsValues(): TabFormControl<Ppcdf>[] {
        return cdefixeTabsFormControls;
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public openClient() {
        this._entityNavService
            .navigateToEntityForm(Ppcli, this.getFormValue('cliId'));
    }

    protected getValidationType() {
        return Ppcdf;
    }

    protected validateReadonlyStatus(e: Ppcdf) {
        const status = e.cdfstatus || 0;
        return (status === 2) && !!e.cdfId;
    }

    protected getFormControlNames(): FormKeys<Ppcdf> {
        return this.getTabsFormControls(cdefixeTabsFormControls);
    }

    protected onNeedSummaryChange(summary?: string) {
        if (summary) {
            return;
        }
        const cdfId: string | undefined = this.getFormValue('cdfId');
        if (!cdfId) {
            return;
        }
        this.updateScheduleEvent(cdfId);
    }

    protected updateScheduleEvent(entityId?: string) {
        const schedEvQuery: RestQueryParam<ScheduleEvent, any>[] = [{
            operation: RestQueryOperation.Equals, prop: 'parentType', value: 'ppcdf'
        }, {
            operation: RestQueryOperation.Equals, prop: 'parentId', value: entityId
        }];
        this._restService.getEntityQuery(ScheduleEvent, ...schedEvQuery)
            .scroll()
            .subscribe((elem) => {
                if (!elem.length) {
                    return;
                }
                this.setFormValue('needSummary', this._matchingService.buildNeedSummary(elem[0]));
            });
    }

    private subscribeProfession() {
        this.getFormControl('qualif')
            ?.valueChanges
            .pipe(debounceTime(200), takeUntil(this.subscriptions))
            .subscribe((quaIds: string | undefined) => {
                if (quaIds) {
                    this.setProfessionName(quaIds);
                } else {
                    this._commandeProfession = '';
                }
            });
    }

    private async setProfessionName(quaIds: string) {

        if (!quaIds) {
            return;
        }

        try {
            this._commandeProfession = await lastValueFrom(this._ppempService.getProfessionName(quaIds)) ?? '';
        } catch {
        }

    }

    private subscribeCliId(): void {
        if (this.formGroup) {
            this.formGroup.controls['cliId'].valueChanges
                .pipe(takeUntil(this.subscriptions))
                .subscribe((cliId: string | undefined) => {
                    if (!cliId) {
                        return;
                    }
                    this._ppcliRest.getById(cliId)
                        .subscribe((client: Ppcli) => {
                            if (!client.nom) {
                                return;
                            }
                            this.clientName = client.nom;
                        });
                });
        }
    }

    private setNeedSummaryValidators() {
        this.formGroup?.controls['needSummary'].setValidators(Validators.required);
        this.formGroup?.controls['needSummary'].updateValueAndValidity();
    }

    private subscribeToSaveEffect() {
        this._actions.pipe(takeUntil(this.subscriptions), ofType(globalActions.GLOBAL_FORM_ENTITY_SAVE_SUCCESS))
            .subscribe(e => {
                const uuid = e['uuid'] as string || '';

                if (uuid !== this.uuid) {
                    return;
                }

                const keywords = this._pkwService.store[uuid] || [];
                this._pkwService.saveKeywords(this.cdfId || '', keywords, 'ppcdf')
                    .subscribe();
            });
    }

}
