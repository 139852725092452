import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseSideFormComponent} from '@app/sam-base/base/base-side-form.component';
import {
    FormHandlerService,
    HeaderToolbarService,
    IwReportsService,
    ModalService,
    ReportOutputType
} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {employeToSmsType} from '@app/sam-base/helpers/sms-map';
import {SaData} from '@app/sam-base/models/salary/saData';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {CommunicationType} from '@core/mail/mail.models';
import {ParametersService} from '@core/services/parameters.service';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {salaryComRoles, salaryPrintRoles, salaryRWtRoles} from '@sam-base/core/auth/access-rules/facture-roles';

import {lastValueFrom, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {IwSendSMSModalComponent} from '../';
import {SalaryForm} from '../../salary.forms';

@Component({
    templateUrl: './employee-side-menu.component.html',
    standalone: false
})
export class EmployeeSideMenuComponent extends BaseSideFormComponent<Saempc> implements OnInit, OnDestroy {
    public saempc?: Saempc;
    public sapar?: Sapar;
    public empdedmode?: number;
    public isLoading = false;
    public cansms = false;
    public disabled = false;
    private subscriptions = new Subject();
    private _activeSapar?: Sapar;
    private readonly _empRest: RestEntityClient<Saempc>;

    public SALAIRE_COMMUNICATION_ROLES: string[] = salaryComRoles();
    public SALAIRE_RW_ROLES: string[] = salaryRWtRoles()
    public SALAIRE_PRINT_ROLES: string[] = salaryPrintRoles()

    constructor(private _store: IwStoreService, private _parametersService: ParametersService,
                private _modalService: ModalService, private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _service: IwReportsService, _restService: RestApiService,
                private _headerService: HeaderToolbarService) {
        super();
        this._empRest = _restService.getEntityClient(Saempc);
    }

    public get ldedValue(): string {
        if (!this.empdedmode) {
            return '';
        }
        return this.empdedmode >= 2 ? 'SD4' : '';
    }

    public ngOnInit() {
        this._parametersService
            .getSaparDataText<SaData>('saData')
            .subscribe((saData) => {
                this.empdedmode = saData.empdedmode ? Number(saData.empdedmode) : 0;
            });
        this._store.globalForm(this.uuid)
            .mode
            .pipe(takeUntil(this.subscriptions))
            .subscribe((mode) => {
                this.disabled = mode !== 'edit';
            });
        this._store
            .globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscriptions))
            .subscribe((data: any) => {
                this.saempc = data;
                if (data) {
                    this.cansms = data.allowsms ?? false;
                }
            });
        this.getActiveYear();
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public printDossier(): void {
        const params = {
            emp_id: this.saempc?.empId,
            annee: this._activeSapar?.annee
        };
        lastValueFrom(this._service.downloadReport({
            report: 'DOSSIEREMPLOYE',
            outputType: ReportOutputType.Pdf,
            id: this.saempc?.empId,
            params,
        }, `${this.saempc?.empId}_${this._activeSapar?.annee}`));
    }

    public openEntriesAndExitsForm() {
        this._formHandler.showFormDialog(SalaryForm.Saeio, {
            empId: this.saempc?.empId
        });
    }

    /**
     * Function to open email form
     */
    public openEmailForm() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPEMP_GENERIC,
            entityIds: [this.saempc?.empId ?? '']
        });
    }

    /**
     * Function to open sms form
     */
    public async openSendSMSForm() {
        if (this.saempc?.empId) {
            const entity = await lastValueFrom(this._empRest.getById(this.saempc.empId))
                .then((e) => employeToSmsType([e]));
            this._modalService.showModal(IwSendSMSModalComponent, entity);
        }
    }

    private getActiveYear() {
        this._headerService.getActiveExercise()
            .subscribe(sapar => this._activeSapar = sapar);
    }
}
