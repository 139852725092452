import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Pppar} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppopttxt-page5', templateUrl: './tab-ppopttxt-page5.component.html',
    standalone: false
})
export class IwTabPpopttxtPage5Component extends BasePartialFormComponent<Pppar> {

    @Input() public isReadonly = true;

    constructor() {
        super();
    }
}
