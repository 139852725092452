import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {
    ContextMenuEvent,
    EntityNavigationService,
    FormHandlerService,
    IwEventHubService,
    IwStoreService,
    ModalService,
    RestApiService,
    RestEntityClient
} from '@app/sam-base/core';
import {IwDateHelper} from '@app/sam-base/core/dates/iw-date-helper';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {
    FormKeys,
    IwGridColumn,
    MenuItem,
    ModesFacturation,
    RowClickEvent,
    sortGridList,
    TableSortEvent
} from '@app/sam-base/models';
import {
    GenerateInvoiceRequest,
    InitInvoiceRequest,
    Invoice,
    InvoiceBill,
    InvoiceClient,
    InvoiceClientEvent,
    InvoicePrepareRequest,
    InvoiceProcess,
    InvoiceProcessDetails,
    InvoiceProcessType,
    Ppcli,
    Ppper,
    PrepareInvoiceClient,
    SaveInvoiceProcessRequest
} from '@app/sam-base/models/placement';
import {PeriodeService} from '@modules/sam-main/placement/services/periode.service';
import {TranslateService} from '@ngx-translate/core';
import {IwContextMenuComponent} from '@sam-base/components';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {ProfileService} from '@shared/profile/profile.service';
import {
    ModalInvoiceCountComponent
} from '@shared/widgets/modal-components/modal-invoice-count/modal-invoice-count.component';
import {
    ModalInvoiceCountOptions
} from '@shared/widgets/modal-components/modal-invoice-count/modal-invoice-count.options';
import {
    ModalInvoiceSessionComponent
} from '@shared/widgets/modal-components/modal-invoice-session/modal-invoice-session.component';
import {
    ModalInvoiceSessionOptions
} from '@shared/widgets/modal-components/modal-invoice-session/modal-invoice-session.options';
import {toLower} from 'lodash';
import {Subject, takeUntil} from 'rxjs';
import {PlacementForm} from '../../placement.forms';
import {InvoiceService} from '../../services/invoice.service';
import {OrdreElems} from './invoices.model';

/**
 * Component created regarding SAM-4844
 * https://samredesign.atlassian.net/browse/SAM-4844
 */
function invoiceBillStatusColorMapper(row: InvoiceBill): string {
    if (row.facId) {
        return "#008000";
    }
    if (row.error) {
        return '#a00101'
    }
    return "#C0C0C0";
}


@Component({
    templateUrl: './invoices.component.html',
    standalone: false
})
export class InvoicesComponent extends BaseFormComponent<Invoice> implements OnInit, OnDestroy {


    public canEditSession = false;
    public clients: InvoiceClient[] = [];
    public selectedClientsTable: InvoiceClient[] = [];
    public bills: InvoiceBill[] = [];
    public selectedFacturesFixes: InvoiceBill[] = [];

    public periode?: Ppper;
    public columnsHourly = this.getColumnsHourly();
    public columnsMonthly = this.getColumnsMonthly();
    public columnsFixe = this.getColumnsFixe();
    public columnsFHourly = this.getColumnsBillHourly();
    public columnsFMonthly = this.getColumnsBillMonthly();
    public columnsFFixe = this.getColumnsBillFixe();
    public originalInvoiceBills: InvoiceBill[] = [];
    public clientLabel = '';
    public ordreOptions = OrdreElems;
    public facturesLabel = '';
    public prepared = false;

    public nbclitotal = 0;
    public nbcliselected = 0;
    public mntcliselected = 0;
    public selectedClients: InvoiceClient[] = [];

    public nbfacselected = 0;
    public mntfacselected = 0;

    public invoiceProcessTypeValue: InvoiceProcessType = InvoiceProcessType.HOURS;

    public readonly timeModeEnum = InvoiceProcessType;
    // Default background color of textfield periode
    public backgroundPerColor = 'per-color-2';
    public backgroundTvaPerColor = 'per-color-3';
    public invoiceRestClient: RestEntityClient<Invoice>;
    public invoiceBillClicked?: InvoiceBill;

    // CONTEXT MENU
    public contextMenuData?: ContextMenuEvent<InvoiceClient>;

    public contextMenuItems: MenuItem[] = this.buildContextMenu()
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public invoiceProcesses: InvoiceProcess[] = [];
    public invoiceProcessId: number | undefined;
    public minDate: Date = new Date();
    // Private
    private _selectedInvoices: InvoiceBill[] | undefined;
    private userId?: string;
    private tableSortEvent?: TableSortEvent<InvoiceBill>;
    private subscriptions = new Subject();
    private filterService = new FilterService<InvoiceBill>();

    constructor(private readonly _store: IwStoreService,
                private readonly _forms: FormHandlerService<PlacementForm>,
                private readonly _profileService: ProfileService,
                private readonly _translate: TranslateService, private readonly _modalService: ModalService,
                private readonly _invoiceService: InvoiceService, private readonly _toastService: ToastService,
                private _rest: RestApiService,
                private readonly _periodeService: PeriodeService,
                private readonly _eventService: IwEventHubService<InvoiceClientEvent>,
                private _navigationService: EntityNavigationService) {
        super();
        this.setActivePeriod();
        this.invoiceRestClient = this._rest.getEntityClient(Invoice);
        this.subscribeToEvent(InvoiceClientEvent.UNE_FACTURE, ModesFacturation.une_facture);
        this.subscribeToEvent(InvoiceClientEvent.EMPLOYE, ModesFacturation.employe);
        this.subscribeToEvent(InvoiceClientEvent.MOIS, ModesFacturation.mois);
        this.subscribeToEvent(InvoiceClientEvent.RAPPORT, ModesFacturation.rapport);
        this.subscribeToEvent(InvoiceClientEvent.CHANTIER, ModesFacturation.chantier);
        this.subscribeToEvent(InvoiceClientEvent.CHANTIER_EMPLOYE, ModesFacturation.chantier_employe);
        this.subscribeToEvent(InvoiceClientEvent.SEMAINE, ModesFacturation.semaine);
        this.subscribeToEvent(InvoiceClientEvent.SEMAINE_CHANTIER, ModesFacturation.semaine_chantier);
        this.subscribeToEvent(InvoiceClientEvent.RAPPORT_FORCE, ModesFacturation.rapport_force);
        this.subscribeToEvent(InvoiceClientEvent.SEMAINE_EMPLOYE, ModesFacturation.semaine_employe);
        this.subscribeToEvent(InvoiceClientEvent.EMPLOYE_MOIS, ModesFacturation.employe_mois);
        this.subscribeToEvent(InvoiceClientEvent.MANUEL, ModesFacturation.manuel);
        this.subscribeToEvent(InvoiceClientEvent.SEMAINE_CHANTIER_EMPLOYE, ModesFacturation.semaine_chantier_employe);
        this.subscribeToEvent(InvoiceClientEvent.MISSION, ModesFacturation.mission);
        this.subscribeToEvent(InvoiceClientEvent.REF_CLIENT, ModesFacturation.ref_client);
        this.subscribeToEvent(InvoiceClientEvent.EMPLOYE_REF, ModesFacturation.employe_ref_client);

    }

    public get facturesCount(): string {
        return `${this._selectedInvoices?.length}/${this.bills.length}`;
    }

    public get dataToShow() {
        return this.invoiceProcessTypeValue === InvoiceProcessType.HOURS ? this.columnsHourly : (this.invoiceProcessTypeValue === InvoiceProcessType.MONTHLY ? this.columnsMonthly : this.columnsFixe);
    }

    /**
     * Check if Preparer button is enabled
     */
    public get checkBtnCreateEnabled() {
        return this.nbfacselected !== 0 && this.canEditSession;
    }

    public get mntfactures(): string {
        const mntTotal = this.calculateAmounts(this.bills, 'amount');
        const mntSelected = this._selectedInvoices ? this.calculateAmounts(this._selectedInvoices, 'amount') : '0';
        return `${mntSelected} / ${mntTotal}`;
    }

    public get mtnClientsCount(): string {
        const mntTotal = this.calculateAmounts(this.clients, 'mntht');
        const mntSelected = this.selectedClients ? this.calculateAmounts(this.selectedClients, 'mntht') : '0';
        return `${mntSelected} / ${mntTotal}`;
    }

    public get nbClientsCount(): string {
        return `${this.selectedClients?.length} / ${this.clients?.length}`;
    }

    public get invoiceBillClickedFormattedErrorMessage(): string {
        return `<ul>${this.invoiceBillClicked?.error?.errors.map(e => `<li>${this._translate.instant(e.code, e.params)}</li>`).join('')}</ul>`
    }

    public async onContextMenu(event: ContextMenuEvent<InvoiceClient>) {

        this.contextMenuData = event;
        await this.showContextMenu(event.event);

    }

    public onApplyFilter($event: IwGridColumn<InvoiceBill>) {
        this.filterService.addFilter($event);
        this.bills = this.filterService.applyFilters(this.originalInvoiceBills);
    }

    public onSort($event?: TableSortEvent<InvoiceBill>) {
        this.tableSortEvent = $event;
        if (!$event) return;
        this.bills = [...sortGridList(this.bills, $event)];
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    /**
     * Function to get the columns to show on the table.
     *
     * @returns Columns to show on table
     */
    public getColumnsFToShow() {
        return this.invoiceProcessTypeValue === InvoiceProcessType.HOURS ? this.columnsFHourly : (this.invoiceProcessTypeValue === InvoiceProcessType.MONTHLY ? this.columnsFMonthly : this.columnsFFixe);
    }

    /**
     * Function to open form regarding Paramètres
     */
    public openParameters() {
        this._forms.showFormDialog(PlacementForm.InvoicesParameters, undefined, s => ({
            ...s,
            entityId: '0'
        }));
    }

    /**
     * Close form
     */
    public async closeDialog() {
        const options: ModalMessageOptions = {
            message: [this._translate.instant('annulerInvoice')],
            showCancel: true,
            title: this._translate.instant('invoices_form_title')
        };
        try {
            if (this.invoiceProcessId && this.canEditSession) {
                await this._modalService
                    .showModal(ModalMessageComponent, options);
                this.effacer();
            }
            this._store.dispatch(new actions.DestroyForm(this.uuid));
        } catch (error) {
        }
    }

    /**
     * Function to change background color of periode field
     */
    public setPeriodeBackgroundColor(salId: string) {
        this.backgroundPerColor = this.periode?.salId === salId || salId === null ? 'per-color-2' : 'per-color-1';
    }

    /**
     * Update clients values when selection change
     *
     * @param event selected clients
     */
    public updateClientValues(event: InvoiceClient[]) {
        this.hideContextMenu();
        this.nbcliselected = event.length;
        this.mntcliselected = Number(event
            .reduce((sum, cli) => sum + (Number(cli.mntht) || 0), 0).toFixed(2));
        this.selectedClients = event;
    }

    public onFacturesFixesChange($event: any[]) {
        this.updateFacturesValues($event);
    }

    /**
     * Update factures values when selection change
     *
     * @param reports selected factures
     */
    public updateFacturesValues(reports: InvoiceBill[]) {
        this.nbfacselected = reports.length;
        this.mntfacselected = Number(reports
            .reduce((sum, rep) => sum + (Number(rep.amount) || 0), 0).toFixed(2));
        this._selectedInvoices = reports;
    }

    public checkCliWithEleOnlyEnabled() {
        return (this.nbcliselected < this.nbclitotal);
    }

    /**
     * Check if Ordre Elements field is enabled
     */
    public checkFacEleOrdFacPrepModeEnabled() {
        return this.nbcliselected !== 0 && !this.prepared;
    }

    public effacer() {
        this.effacerHelper();
        if (this.invoiceProcessId) {
            this._invoiceService.deleteData(this.invoiceProcessId!).subscribe();
            this.prepared = false;
            this.invoiceProcesses = this.deleteInvoiceProcessById(this.invoiceProcesses, this.invoiceProcessId);
        }
    }

    public getInvoiceProcessList(openForUser?: boolean) {
        this._invoiceService.listInvoiceProcess().subscribe(processes => {
            this.invoiceProcesses = processes;
            if (openForUser) {
                // find first invoiceProcess owned by current user, and if present load it
                let invoiceProcesId = this.invoiceProcesses.find(process => process.owner === this.userId)?.id;
                this.getInvoiceProcess(invoiceProcesId)
            }
        });
    }

    public async openInvoiceProcessList() {
        const options: ModalInvoiceSessionOptions = {
            showCancel: false,
            title: this._translate.instant('invoice_process_sessions_title'),
        };
        try {
            let sessionId = await this._modalService.showModal(ModalInvoiceSessionComponent, options);
            // if no client ids, then nothing to do
            if (!sessionId) {
                return;
            }
            this.getInvoiceProcess(sessionId);
        } catch (error) {
        }

    }

    public async initInvoice() {

        const options: ModalInvoiceCountOptions = {
            //invoiceProcessId: this._invoiceProcessId!,
            showCancel: false,
            title: this._translate.instant('facturation'),
            salId: this.periode!.salId!,
            invoiceProcessType: this.invoiceProcessTypeValue
        };

        try {
            let clientIds = await this._modalService.showModal(ModalInvoiceCountComponent, options);
            // if no client ids, then nothing to do
            if (clientIds.length === 0) {
                return;
            }
            this._store.dispatch(new actions.SetLoading(this.uuid, true));
            let initRequest: InitInvoiceRequest = {
                salId: this.periode!.salId!,
                invoiceProcessType: this.invoiceProcessTypeValue,
                cliIds: clientIds
            }
            this._invoiceService.initInvoice(initRequest)
                .subscribe(async (invoice: InvoiceProcessDetails) => {
                    this.applyInvoiceProcessDetails(invoice);
                    this.invoiceProcesses.push(invoice.invoiceProcess);
                    // }
                }, async (err: HttpErrorResponse) => {
                    if (err.status === 423) {
                        this._toastService.warning('system_locked');
                    }
                    const msg = err.status === 423 ? this._translate.instant('error_call_invoices') : this._translate.instant('error_init');
                    const optionsLocked: ModalMessageOptions = {
                        message: [],
                        showCancel: false,
                        title: this._translate.instant('facturation'),
                        confirmMessage: msg,
                        alertsMessage: this._translate.instant('alertsmsg'),
                        okDisabled: false
                    };
                    try {
                        await this._modalService
                            .showModal(ModalMessageComponent, optionsLocked);
                        this._store
                            .dispatch(new actions.DestroyForm(this.uuid));
                    } catch (error) {
                    }
                    this._store
                        .dispatch(new actions.SetLoading(this.uuid, false));
                });


        } catch (error) {
        }
    }

    /**
     * Call generate invoices service from backend
     */
    public async generateInvoices() {
        const finDeMois = this.getFormValue('lfindemois');
        if (!this.periode?.salId) {
            this._toastService.error('no_active_period');
            return;
        }
        const salId = this.periode?.salId;
        const tvaCodeHt = this.getFormValue('tvaCode');
        let ids = this._selectedInvoices?.map((elem: InvoiceBill) => elem.id || 0);
        const params: GenerateInvoiceRequest = {
            invoiceProcessId: this.invoiceProcessId!,
            dateFac: this.getFormValue('dateFactu'),
            dateCompta: IwDateHelper.dateIsoString(),
            salId,
            tvaCodeHt,
            finDeMois,
            invoiceIdsToGenerate: ids,
            faceleord: this.getFormValue('faceleord')
        };
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._invoiceService.generateInvoice(params)
            .subscribe(invoiceProcessDetail => {
                this._store.dispatch(new actions.SetLoading(this.uuid, false));
                this.applyInvoiceProcessDetails(invoiceProcessDetail);
            });
    }

    public ngOnInit(): void {
        this._profileService.loadProfile()
            .subscribe(userProfile => this.userId = userProfile.userId);
        this.getInvoiceProcessList(true);
    }

    /**
     * Save Invoice process
     */
    public async saveInvoiceProcessAndQuit() {
        let saveInvoiceProcessRequest: SaveInvoiceProcessRequest;

        const finDeMois = this.getFormValue('lfindemois');

        saveInvoiceProcessRequest = {
            invoiceProcessId: this.invoiceProcessId!,
            finDeMois,
            prepareInvoiceClients: this.clients.map(element => {
                return {
                    facPrepMode: element.modeFactu!,
                    cliId: element.cliId
                }
            })
        };
        this._store.dispatch(new actions.DestroyForm(this.uuid));

        this._invoiceService.saveInvoiceProcess(saveInvoiceProcessRequest)
            .subscribe({
                    next: (e: InvoiceProcessDetails) => this.applyBills(e),
                    error: err => this._toastService.warning('failedProccess')
                }
            );
    }

    /**
     * Prepare invoice service from backend
     */
    public async prepareInvoice() {
        let prepareRequest: InvoicePrepareRequest;

        const finDeMois = this.getFormValue('lfindemois');
        prepareRequest = this.prepare(finDeMois);
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._invoiceService.prepareInvoice(prepareRequest)
            .subscribe({
                    next: (e: InvoiceProcessDetails) => {
                        this.applyBills(e);
                        this.prepared = true;
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    },
                    error: err => {
                        this._toastService.warning('failedProccess')
                        this._store.dispatch(new actions.SetLoading(this.uuid, false));
                    }
                },
            );
    }

    public openInvoice(event: RowClickEvent<InvoiceBill>) {
        const facId = event.row.facId;
        if (facId) {
            this._navigationService.navigateToEntityForm(Gefacliview, facId, undefined, 'read', true);
        }
    }

    public openClient(event: RowClickEvent<InvoiceClient>) {
        const cliId = event.row.cliId;
        if (cliId) {
            this._navigationService.navigateToEntityForm(Ppcli, cliId, undefined, 'read', true);
        }
    }

    public onInvoiceBillClicked(bill: InvoiceBill) {
        if (this.invoiceBillClicked?.id === bill.id) {
            this.invoiceBillClicked = undefined;
        } else {
            this.invoiceBillClicked = bill;
        }
    }

    protected getFormControlNames(): FormKeys<Invoice> {
        return [
            'invoiceProcessId',
            'salId',
            'actSalId',
            'dateFactu',
            'dateCompta',
            'tvaCode',
            'tvaTaux',
            'tvaPeriode',
            'faceleord',
            'lfindemois',
            'lwithprevsalid',
            'selAgeId',
            'defchamode',
            'sessionOwner'
        ];
    }

    private deleteInvoiceProcessById(invoiceProcesses: InvoiceProcess[], idToDelete: number): InvoiceProcess[] {
        return invoiceProcesses.filter(invoice => invoice.id !== idToDelete);
    }

    private calculateAmounts(items: any[], property: string): string {
        return items.reduce((sum, rep) => sum + (Number(rep[property]) || 0), 0).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    private subscribeToEvent(eventType: InvoiceClientEvent, modeFactuType: ModesFacturation) {
        this._eventService.forType(eventType).pipe(takeUntil(this.subscriptions)).subscribe(e => {
            const payload = e.payload as ContextMenuEvent<InvoiceClient>;
            payload.selected.forEach(e => {
                e.modeFactu = modeFactuType;
            });
        });
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu && this.invoiceProcessTypeValue === InvoiceProcessType.HOURS) {
            await this.menu.show(event);
        }
    }

    private async hideContextMenu() {
        if (this.menu) {
            await this.menu.hide();
        }
    }

    private effacerHelper() {
        this.selectedClients = [];
        this.selectedFacturesFixes = [];
        this.selectedClientsTable = [];
        this.updateClientValues([]);
        this.bills = [];
        this.originalInvoiceBills = [];
        this.clients = [];
        this.resetFacturesFixes();
    }

    private prepare(finDeMois: boolean): InvoicePrepareRequest {
        let prepareInvoiceClients = this.selectedClients.map(element => {
            const invoiceClientRequest: PrepareInvoiceClient = {
                facPrepMode: element.modeFactu!,
                cliId: element.cliId
            }
            return invoiceClientRequest;
        });

        return {
            invoiceProcessId: this.invoiceProcessId!,
            finDeMois,
            prepareInvoiceClients
        };
    }

    private applyBills(e: InvoiceProcessDetails) {
        this.originalInvoiceBills = e.invoiceBills || [];
        this.bills = this.filterService.applyFilters(this.originalInvoiceBills);
        // this.bills.forEach((fac: InvoiceBill) => {
        //     if (this.invoiceProcessTypeValue === InvoiceProcessType.HOURS) {
        //         fac.modeprepaExt = this.translateModeFactu(fac.modePrepa || 0);
        //     } else {
        //         fac.cmodeprepa = this.getTitleByValue(fac.cmodeprepa || '');
        //     }
        // });
    }

    private applyInvoiceProcessDetails(invoiceProcessDetail: InvoiceProcessDetails) {
        this.canEditSession = invoiceProcessDetail.invoiceProcess.owner === this.userId;
        this.invoiceProcessId = invoiceProcessDetail.invoiceProcess.id;
        this.invoiceProcessTypeValue = invoiceProcessDetail.invoiceProcess.type;
        this.filterByTimeMode(invoiceProcessDetail);
        this.fillFormData(invoiceProcessDetail);
        this.setPeriodeBackgroundColor(invoiceProcessDetail.invoiceProcess.salId || '');
        this.clientLabel = this._translate.instant('clients_facturables');
        this.facturesLabel = this._translate.instant('factures_preparees');
        this.setFormValue('dateCompta', IwDateHelper.dateIsoNoTimezone(invoiceProcessDetail.invoiceProcess.dateCompta));
        this.setFormValue('dateFactu', IwDateHelper.dateIsoNoTimezone(invoiceProcessDetail.invoiceProcess.dateFactu));
        this.setFormValue('tvaCode', invoiceProcessDetail.tvaParameters?.tvaCode);
        this.setFormValue('tvaTaux', `${Number(invoiceProcessDetail.tvaParameters?.tvaTaux).toFixed(2)}%`);
        this.setFormValue('tvaPeriode', this.transformPeriod(invoiceProcessDetail.tvaParameters?.tvaPeriode));
        this.setFormValue('lfindemois', false);
        this.setFormValue('sessionOwner', invoiceProcessDetail.invoiceProcess.owner);
        this.resetTableSelection();
        this.applyBills(invoiceProcessDetail);
        this._store.dispatch(new actions.SetLoading(this.uuid, false));

    }

    /**
     * Function to filter the initial list based on time mode
     *
     * @param e Invoice to be filtered
     */
    private filterByTimeMode(e: InvoiceProcessDetails): void {
        if (this.invoiceProcessTypeValue === InvoiceProcessType.HOURS) {
            this.clients = e.invoiceClients?.filter(c => (c.nbRap && c.nbRap > 0)) || [];
        } else {
            this.clients = e.invoiceClients || [];
        }
    }

    private transformPeriod(tvaPeriode: String | undefined): any {
        return `${tvaPeriode?.substring(0, 4)}-t${tvaPeriode?.substring(4)}`;
    }

    /**
     * Set active period
     */
    private setActivePeriod() {
        this._periodeService.getActivePeriod()
            .subscribe((per: Ppper) => {
                this.periode = per;
            });
    }

    /**
     * Columns to show in clients table
     */
    private getColumnsHourly(): IwGridColumn<InvoiceClient>[] {
        return [
            {
                prop: 'cliNom',
                name: 'client',
                index: 0
            },
            {
                prop: 'nbRap',
                name: 'rapports',
                index: 1
            },
            {
                prop: 'mntht',
                name: 'mntht',
                index: 2,
                type: 'mnt',
                align: 'right'

            },
            {
                prop: 'modeFactu',
                name: 'mode',
                type: 'enum',
                index: 4
            }
        ];
    }

    /**
     * Columns to show in clients table
     */
    private getColumnsMonthly(): IwGridColumn<InvoiceClient>[] {
        return [
            {
                prop: 'cliNom',
                name: 'client',
                index: 0
            },
            {
                prop: 'nbMis',
                name: 'nbMissions',
                index: 1
            },
            {
                prop: 'mntht',
                name: 'mntht',
                index: 2,
                type: 'mnt',
                align: 'right'

            }
        ];
    }

    /**
     * Columns to show in clients table
     */
    private getColumnsFixe(): IwGridColumn<InvoiceClient>[] {
        return [
            {
                prop: 'cliNom',
                name: 'client',
                index: 1
            },
            {
                prop: 'nbPlf',
                name: 'nbPlf',
                index: 2
            },
            {
                prop: 'mntht',
                name: 'amount',
                index: 3,
                type: 'mnt',
                align: 'right'
            }
        ];
    }

    /**
     * Columns to show in feactures table
     */
    private getColumnsBillHourly(): IwGridColumn<InvoiceBill>[] {
        return [
            {
                prop: 'id',
                name: 'id',
                colorMapper: (row: InvoiceBill) => {
                    return invoiceBillStatusColorMapper(row);
                },
                type: 'string',
                index: 0
            },
            {
                prop: 'cliNom',
                name: 'client',
                type: 'string',
                index: 1
            },
            {
                prop: 'text',
                name: 'texte',
                type: 'string',
                index: 3
            },
            {
                prop: 'ageId',
                name: 'agence',
                index: 4
            },
            {
                prop: 'nbRap',
                name: 'rapports',
                type: 'number',
                index: 5
            },
            {
                prop: 'facNo',
                name: 'facNo',
                type: 'string',
                index: 5
            },
            {
                prop: 'amount',
                name: 'mntht',
                index: 6,
                type: 'mnt',
                align: 'right',
            }];
    }

    /**
     * Columns to show in feactures table
     */
    private getColumnsBillMonthly(): IwGridColumn<InvoiceBill>[] {
        return [
            {
                prop: 'id',
                name: 'id',
                colorMapper: (row: InvoiceBill) => {
                    return invoiceBillStatusColorMapper(row);
                },
                type: 'string',
                index: 0
            },
            {
                prop: 'cliNom',
                name: 'client',
                type: 'string',
                index: 1
            },
            {
                prop: 'cmodeprepa',
                name: 'mode',
                type: 'string',
                index: 2
            },
            {
                prop: 'text',
                name: 'employe',
                type: 'string',
                index: 3
            },
            {
                prop: 'ageId',
                name: 'agency',
                type: 'string',
                index: 5
            },
            {
                prop: 'facNo',
                name: 'facNo',
                type: 'string',
                index: 5
            },
            {
                prop: 'amount',
                name: 'mntht',
                index: 5,
                align: 'right',
                type: 'mnt'
            }];
    }

    /**
     * Columns to show in feactures table
     */
    private getColumnsBillFixe(): IwGridColumn<InvoiceBill>[] {
        return [
            {
                prop: 'id',
                name: 'id',
                colorMapper: (row: InvoiceBill) => {
                    return invoiceBillStatusColorMapper(row);
                },
                type: 'string',
                index: 0
            },
            {
                prop: 'cliNom',
                name: 'client',
                type: 'string',
                index: 1
            },
            {
                prop: 'cliId',
                name: 'cli_no',
                type: 'string',
                index: 1
            },
            {
                prop: 'mntht',
                name: 'amount',
                index: 4,
                decimals: 2
            },
            {
                prop: 'ageId',
                name: 'a',
                type: 'string',
                index: 5
            }
        ];
    }

    private resetTableSelection() {
        this.selectedClients = [];
        this.updateClientValues([]);
        this.resetFacturesFixes();
    }

    private resetFacturesFixes() {
        this.selectedFacturesFixes = [];
        this.onFacturesFixesChange([]);
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(InvoiceClientEvent).forEach((event) => {
            menu.push(
                {
                    label: toLower(event),
                    event: event,
                    contextMenuVisibleMode: 'all'
                },
            );
        });
        return menu;
    }

    private getInvoiceProcess(sessionId: number | undefined) {
        if (!sessionId) return;
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._invoiceService.getInvoiceProcessDetails(sessionId).subscribe(processDetails => {
            this.applyInvoiceProcessDetails(processDetails)
            this._store.dispatch(new actions.SetLoading(this.uuid, false));
        }, error => {
            this._store.dispatch(new actions.SetLoading(this.uuid, false));
        });
    }
}
