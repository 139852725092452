import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {SortInfo} from '@app/sam-base/components';
import {EntityCreate, EntityDelete, EntityUpdate, RestEntityClient} from '@app/sam-base/core';
import {RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api/models/rest-query.model';
import {RestApiService} from '@app/sam-base/core/rest-api/rest-api.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {IwStoreService} from '@app/sam-base/core/store/iw-store.service';
import {ToastService} from '@app/sam-base/core/toast/toast.service';
import {FormKeys} from '@app/sam-base/models/form-keys.model';
import {Ppsecteur} from '@app/sam-base/models/placement';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Component({
    templateUrl: './secteur-config.component.html',
    standalone: false
})
export class SecteurConfigComponent extends BaseFormComponent<Ppsecteur> {

    public type = Ppsecteur;
    public selectedRow?: Ppsecteur;
    public isReadonly = true;
    public newEntryMode = false;

    public get selectedData() {
        if (!this.selectedRow) {
            return undefined;
        }
        return {name: 'secId', value: this.selectedRow.secId};
    }

    /** Dummy query created to bypass filter that requires some sort of params */
    public query: RestQueryParam<Ppsecteur, string>[] = [{
        prop: 'secId', operation: RestQueryOperation.NotEquals, value: '_'
    }];

    public sortProp?: keyof Ppsecteur;
    public sortPropDir?: 'asc' | 'desc';

    private _isSaving = false;
    private _secId = '';
    private readonly _restPpsecteurClient: RestEntityClient<Ppsecteur>;

    constructor(rest: RestApiService, private _store: IwStoreService, private _toastService: ToastService, private _translate: TranslateService) {
        super();
        this._restPpsecteurClient = rest.getEntityClient(Ppsecteur);
    }

    public getSelectedRow(event: Ppsecteur[]) {
        this.isReadonly = true;

        if (this._isSaving) {
            // Prevents clearing of the selectedRow
            this._isSaving = false;
            return;
        }

        if (!event.length) {
            this.clearForm();
            this.selectedRow = undefined;
            return;
        }

        this._secId = (event[0] && event[0].secId) || '';
        this.selectedRow = event[0];
        this.fillFormData(<any>event[0]);

    }

    public createNewEntry() {
        this.selectedRow = undefined;
        this.isReadonly = false;
        this.formGroup.reset();
        this.newEntryMode = true;
    }

    public modifyEntry() {
        if (this.selectedRow && this.selectedRow.secId) {
            this.isReadonly = false;
            this.newEntryMode = false;
        }
    }

    public saveEntry() {
        const formData = this.getFormData();

        const updatedData = this._restPpsecteurClient
            .construct({...this.selectedRow, ...formData});
        this.isReadonly = true;
        this.clearForm();

        if (!updatedData || !updatedData.secId) {
            this._toastService.error('need_to_fill_id');
            this.newEntryMode = false;
            return;
        }

        this._isSaving = true;

        if (this.newEntryMode) {

            this._restPpsecteurClient.getById(updatedData.secId)
                .pipe(catchError(err => of(err)))
                .subscribe((res) => {
                    if (res['status'] === 404) {
                        this.setSortByModif();
                        this._store.dispatch(new EntityCreate(updatedData));
                    } else {
                        this._toastService
                            .error(this._translate.instant('id_already_exits'));
                    }
                });

            this.newEntryMode = false;

            return;
        }
        this._store.dispatch(new EntityUpdate(updatedData));
        this.newEntryMode = false;
    }

    public removeEntry() {
        if (this.selectedRow && this.selectedRow.secId) {
            this._store.dispatch(new EntityDelete(Ppsecteur, this._secId));
        }
    }

    public cancelEditionMode() {
        this.fillFormData(this.selectedRow ?? {});
        this.isReadonly = true;
        this.newEntryMode = false;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public setSortInfo(sortInfo: SortInfo<Ppsecteur>) {
        this.sortProp = sortInfo.sortProp;
        this.sortPropDir = sortInfo.sortDir;
    }

    protected getValidationType() {
        return Ppsecteur;
    }

    protected getFormControlNames(): FormKeys<Ppsecteur> {
        return ['secId', 'secLib', 'cptTempo', 'cptTequ', 'cptPari', 'cptFixe', 'codecna', 'coeffMin', 'pccharges', 'actif'];
    }

    private setSortByModif() {
        // Force the update of the string reference
        this.sortProp = 'dateTrack';
        this.sortPropDir = 'desc';
    }

}
