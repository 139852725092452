import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    EmployeeDeclarationMenuEvent
} from '@modules/sam-main/admin/components/swissdec/tab-sasx5dec-employees/tab-sasx5dec-employees.component';
import {TranslateService} from '@ngx-translate/core';
import {BaseStoreFormComponent} from '@sam-base/base';
import {IwContextMenuComponent} from '@sam-base/components';
import {ContextMenuEvent, EntityNavigationService, IwEventHubService, IwStoreService} from '@sam-base/core';
import {FormKeys, IwGridColumn, MenuItem, sortGridList, TableSortEvent} from '@sam-base/models';
import {FilterService} from '@sam-base/models/components/filtering.service';
import {Gecliview} from '@sam-base/models/invoices/gecliview';
import {Gefacliview} from '@sam-base/models/invoices/gefacliview';
import {Gerpl} from '@sam-base/models/invoices/gerpl';
import {Gerplhdr} from '@sam-base/models/invoices/gerplhdr';
import {GerplhdrView, invoiceReminderColorMapper} from '@sam-base/models/invoices/gerplhdrview';
import {toLower} from 'lodash';
import {Subject, takeUntil} from 'rxjs';


enum GerplMenuEvent {
    INVOICE = 'INVOICE',
}

@Component({
    selector: 'iw-rappel-facture',
    templateUrl: './rappel-facture.component.html',
    standalone: false
})
export class RappelFactureComponent extends BaseStoreFormComponent<Gerplhdr> implements OnInit, OnDestroy {
    /** table related **/
    public originalGerplList: Gerpl[] = [];
    public filteredGerplList: Gerpl[] = [];
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    public contextMenuData?: ContextMenuEvent<EmployeeDeclarationMenuEvent>;
    public contextMenuItems: MenuItem[] = this.buildContextMenu()
    public rappelGridColumns: IwGridColumn<Gerpl>[] = [
        {
            name: 'facNo',
            prop: 'facNo',
            type: 'number',
            colorMapper: (row: GerplhdrView) => {
                return invoiceReminderColorMapper(row);
            }
        },
        {
            name: 'client',
            prop: 'cliNom',
            type: 'string'
        },
        {
            name: 'dueAt',
            prop: 'dateEch',
            type: 'date',
        },
        {
            name: 'montant',
            prop: 'montant',
            type: 'mnt',
            align: 'right'
        },
        {
            name: 'nbjRetard',
            prop: 'nbjRetard',
            type: 'number'
        }];

    private selectedGerpl?: Gerpl;
    private filterService = new FilterService<Gerpl>();
    private subscriptions = new Subject();
    private tableSortEvent?: TableSortEvent<Gerpl>;

    constructor(public store: IwStoreService,
                private readonly _entityNavService: EntityNavigationService,
                private readonly _translateService: TranslateService,
                private readonly _eventMenu: IwEventHubService<GerplMenuEvent>,
    ) {
        super(store);
        this._eventMenu.forType(GerplMenuEvent.INVOICE)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<Gerpl>;
                payload.selected.forEach(e => {
                    this._entityNavService.navigateToEntityForm(Gefacliview, e.facId!, undefined, 'read');
                });
            })

    }

    public get displaySendingStatus(): string {
        return this._translateService.instant(`enum.gerplhdr.sendingStatus.${this.getFormValue('sendingStatus')}`);
    }

    public ngOnInit(): void {
        this.subscribeValueChange('gerplList', list => {
            this.originalGerplList = list as Gerpl[];
            this.filteredGerplList = this.originalGerplList;
        });
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public print() {

    }

    public getSelectedRow($event: any[]) {
    }

    public onRappelClicked($event: Gerpl) {
        this.selectedGerpl = $event;
    }

    public onApplyFilter($event: IwGridColumn<Gerpl>) {
        this.filterService.addFilter($event);
        this.filteredGerplList = this.filterService.applyFilters(this.originalGerplList);
    }

    public onSort($event?: TableSortEvent<Gerpl>) {
        this.tableSortEvent = $event;
        this._onSort();
    }

    public setSelected($event: Gerpl[]) {
        this.selectedGerpl = $event[0];
    }


    public async onContextMenu(event: ContextMenuEvent<EmployeeDeclarationMenuEvent>) {
        this.contextMenuData = event;
        await this.showContextMenu(event.event);
    }

    public openGecli() {
        this._entityNavService
            .navigateToEntityForm(Gecliview, this.getFormValue('cliId'));
    }

    protected getFormControlNames(): FormKeys<Gerplhdr> {
        return [
            'rplhdrId',
            'cliId',
            'cliNom',
            'rplAdrId',
            'rplAttn',
            'noRappel',
            'solde',
            'dateRpl',
            'datePrint',
            'dateMail',
            'sendingStatus',
            'gerplList'
        ];
    }

    protected getValidationType() {
        return Gerplhdr;
    }

    private _onSort() {
        if (!this.tableSortEvent) return;
        this.filteredGerplList = [...sortGridList(this.filteredGerplList, this.tableSortEvent)];
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(GerplMenuEvent).forEach((event) => {
            menu.push(
                {
                    label: toLower(event),
                    event: event,
                    contextMenuVisibleMode: 'all'
                },
            );
        });
        return menu;
    }
}
