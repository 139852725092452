<form [formGroup]="formGroup"
      class="row">
    <iw-dropdown #cboModeType
                 [isDisabled]="isReadonly"
                 [labelAlign]="'top'"
                 [label]="'type' | translate"
                 [options]="modeType"
                 [size]="'medium'"
                 class="iw-input col-xs-3 padding-top-15"
                 formControlName="freqSal"></iw-dropdown>

    <iw-checkbox #unique
                 (valueChange)="setAnneeFilter($event)"
                 *ngIf="hide"
                 [isDisabled]="!isReadonly"
                 [labelAlign]="'right'"
                 [label]="sapar?.annee + ' ' + 'unique' | translate"
                 [value]="anneeFilter"
                 class="iw-input col-xs-3 padding-top-15 flex-end margin-bottom-5"
                 style="justify-content: flex-start"></iw-checkbox>

    <iw-checkbox #uniqueEmploye
                 (valueChange)="setEmployeFilter($event)"
                 *ngIf="hide"
                 [isDisabled]="!isReadonly"
                 [labelAlign]="'right'"
                 [label]="'uniqueEmploye' | translate"
                 [value]="employeFilter"
                 class="iw-input col-xs-3 padding-top-15 flex-end margin-bottom-5"
                 style="justify-content: flex-start"></iw-checkbox>

    <iw-button #definitionSalaire
               (press)="openDefSalaire()"
               [isDisabled]="!isReadonly"
               [text]="'definitionSalaire' | translate"
               class="btn-block col-xs-3 flex-end padding-top-15"
               style="margin-left: auto"></iw-button>

    <iw-rest-grid #gridSads
                  [autoFormClick]="false"
                  [customColumns]="sadsColumns"
                  [hasGridColumnMenu]="false"
                  [hasSortIcon]="true"
                  [queryStatements]="query"
                  [type]="type"
                  class="col-xs-12 padding-top-15"
                  style="height: 200px"></iw-rest-grid>

    <div class="row col-xs-6"
         style="margin-block-end: auto">
        <iw-textfield #salaireAnnuel
                      [decimals]="2"
                      [labelAlign]="'left'"
                      [label]="'salaireAnnuel' | translate"
                      class="iw-input col-xs-8 padding-top-20 text-align-right"
                      formControlName="salannuel"
                      type="number"></iw-textfield>
        <iw-textfield #salaiteMensuel
                      [decimals]="2"
                      [labelAlign]="'left'"
                      [label]="'salaiteMensuel' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="salmensuel"
                      type="number"></iw-textfield>
        <iw-textfield #salaireJour
                      [decimals]="2"
                      [labelAlign]="'left'"
                      [label]="'salaireJour' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="saljour"
                      type="number"></iw-textfield>
        <iw-textfield #salaireJour30
                      [decimals]="2"
                      [labelAlign]="'left'"
                      [label]="'salaireJour30' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="saljour30"
                      type="number"></iw-textfield>
        <iw-textfield #salaireHoraire
                      [decimals]="2"
                      [isDisabled]="true"
                      [labelAlign]="'left'"
                      [label]="'salaireHoraire' | translate"
                      class="iw-input col-xs-8 padding-top-15 text-align-right"
                      formControlName="salhorauto"
                      type="number"></iw-textfield>
        <iw-textfield #salaireHF
                      [decimals]="2"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'salaireHF' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="salhoraire"
                      type="number"></iw-textfield>
        <iw-enum-dropdown #cboSalhebdo
                          [inputEnum]="salhebdoInput"
                          [isDisabled]="salhebdoDisabled()"
                          [labelAlign]="'left'"
                          [label]="'salhebdo' | translate"
                          class="iw-input col-xs-8"
                          formControlName="salhebdo"></iw-enum-dropdown>
    </div>

    <div class="row col-xs-6 separator-left">
        <iw-textfield #joursAnnee
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'joursAnnee' | translate"
                      class="iw-input col-xs-8 padding-top-20 text-align-right"
                      formControlName="jparAn"
                      type="number"></iw-textfield>
        <iw-textfield #jParAn
                      [decimals]="2"
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="sapar?.jParAn"
                      class="iw-input col-xs-4 padding-top-20 text-align-right"
                      type="number"></iw-textfield>
        <iw-textfield #heuresJour
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'heuresJour' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="hparJ"
                      type="number"></iw-textfield>
        <iw-textfield #hParJ
                      [decimals]="2"
                      [isDisabled]="true"
                      [noLabel]="true"
                      [value]="sapar?.hParJ"
                      class="iw-input col-xs-4 text-align-right"
                      type="number"></iw-textfield>
        <iw-textfield #heuresSem
                      [decimals]="2"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'heuresSem' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="hparS"
                      type="number"></iw-textfield>
        <iw-textfield #heuresSem
                      [decimals]="2"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'l_par_s' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="lparS"
                      type="number"></iw-textfield>

        <div class="col-xs-4"></div>
        <iw-textfield #tauxActivite
                      [decimals]="2"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'tauxActivite' | translate"
                      [warningMessage]="'warn_txactivite'"
                      class="iw-input col-xs-8 padding-top-15 text-align-right"
                      formControlName="txactivite"
                      type="number"></iw-textfield>
        <iw-textfield #txactioth
                      [decimals]="2"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'txactioth' | translate"
                      class="iw-input col-xs-8 padding-top-15 text-align-right"
                      formControlName="txactioth"
                      type="number"></iw-textfield>
        <div class="col-xs-4"></div>

        <div class="row col-xs-12 separator-top"></div>
        <iw-dropdown #cboModetype
                     [isDisabled]="isReadonly"
                     [labelAlign]="'left'"
                     [label]="'modeVacances' | translate"
                     [options]="modeVacancesType"
                     [size]="'medium'"
                     class="iw-input col-xs-10 padding-top-15"
                     formControlName="freqSal"></iw-dropdown>
        <div class="col-xs-2"></div>

        <iw-textfield #jourVacances
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'jourVacances' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="nbjVac"
                      type="number"></iw-textfield>

        <iw-textfield #soldeAnnee
                      [decimals]="2"
                      [isDisabled]="isReadonly"
                      [labelAlign]="'left'"
                      [label]="'soldeAnnee' | translate"
                      class="iw-input col-xs-8 text-align-right"
                      formControlName="nbjVacini"
                      type="number"></iw-textfield>
    </div>
</form>
