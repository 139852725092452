<iw-dialog #dialog
           [bodyPadding]="'10'"
           [closable]="true"
           [cls]="'iw-modal'"
           [draggable]="true"
           [height]="'80vh'"
           [maximized]="true"
           [modal]="false"
           [resizable]="true"
           [showHeader]="true"
           [title]="'layout_menu_operations' | translate"
           [visible]="true"
           [width]="'80vw'">
    <iw-busy [ajustLeft]="0"
             [ajustTop]="0"
             [isBusy]="loading"></iw-busy>
    <div class="row">
        <h3>{{ 'operations_list' | translate }}</h3>
    </div>
    <div class="row">
        <iw-table
                (applyFilter)="onApplyFilter($event)"
                (columnSort)="onSort($event)"
                (rowDoubleClick)="onOperationClicked($event)"
                [columns]="columns"
                [data]="filteredTableData"
                [defaultSelected]="true"
                [isDisabled]="false"
                [isFilterEnable]="false"
                [isGridColumnMenuEnable]="false"
                [isSortIconEnable]="true"
                [selectionMode]="'checkbox'"
                [virtualScroll]="true"
                class="iw-grid col-xs-12 no-padding"
                style="height: 70vh"></iw-table>
    </div>
</iw-dialog>
