import {Component} from '@angular/core';
import {
    parametresGenerauxFacturationTabsFormControls
} from '@modules/sam-main/salary/components/parametres-generaux-facturation/parametres-generaux-facturation-tabs-formcontrols';
import {BaseTabFormComponent} from '@sam-base/base';
import {IwStoreService, TabFormControl} from '@sam-base/core';
import {FormKeys} from '@sam-base/models';
import {Gepar} from '@sam-base/models/invoices';

@Component({
    templateUrl: './parametres-generaux-facturation.component.html',
    standalone: false
})
export class ParametresGenerauxFacturationComponent extends BaseTabFormComponent<Gepar> {

    constructor(private store: IwStoreService) {
        super(store);
    }

    protected getFormControlNames(): FormKeys<Gepar> {
        return this.getTabsFormControls(parametresGenerauxFacturationTabsFormControls);
    }

    protected getTabsValues(): TabFormControl<Gepar>[] {
        return parametresGenerauxFacturationTabsFormControls;
    }

    protected getValidationType() {
        return Gepar;
    }

}
