<form [formGroup]="formGroup"
      class="row">
    <iw-textfield #txtAnnee
                  [isDisabled]="true"
                  [noLabel]="true"
                  [value]="sapar?.annee"
                  class="iw-input col-xs-12 padding-top-15"
                  style="text-align-last: center"></iw-textfield>
    <div class="col-xs-9">
        <p-table [value]="tableData"
                 class="col-xs-12 no-padding user-config-grid padding-top-15 flex saempAnnesTable"
                 responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'mois' | translate }}</th>
                    <th>{{ 'entree' | translate }}</th>
                    <th>{{ 'sortie' | translate }}</th>
                    <th>{{ 'netPayer' | translate }}</th>
                    <th>{{ 'report' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template let-data
                         pTemplate="body">
                <tr (dblclick)="getView(data)"
                    [ngClass]="{'no-entree': (!data.din && !data.dout && !data.nap) }">
                    <td>{{ data.month }}</td>
                    <td>{{ data.din }}</td>
                    <td>{{ data.dout }}</td>
                    <td>{{ data.nap }}</td>
                    <td>{{ data.mntrep }}</td>
                </tr>
            </ng-template>
        </p-table>
        <iw-button #butSearch
                   (press)="openFicheSal()"
                   [isDisabled]="!isReadonly"
                   [text]="'ficheSalCurYear' | translate"
                   class="col-xs-2 margin-left-10"></iw-button>
    </div>
    <div class="col-xs-1">
        <div class="col-xs-12 padding-top-25"
             style="text-align: center; padding-bottom: 22px">
            {{ 'forcer' | translate }}
        </div>
        <iw-checkbox *ngFor="let formvalue of checkboxesValues; let i = index"
                     [isDisabled]="isReadonly"
                     class="iw-input col-xs-2 margin-left-5 flex-end"
                     formControlName="{{ formvalue.name }}"
                     style="justify-content: flex-start; margin-bottom: 10px"></iw-checkbox>
    </div>
    <div class="col-xs-2">
        <iw-textfield #txtIpresence
                      [isDisabled]="true"
                      [noLabel]="true"
                      class="iw-input col-xs-12 padding-top-15"
                      formControlName="ipresence"
                      style="text-align-last: center"></iw-textfield>

        <iw-textfield #txtIsalaires
                      [isDisabled]="true"
                      [noLabel]="true"
                      class="iw-input col-xs-12 padding-top-15"
                      formControlName="isalaires"
                      style="text-align-last: center"></iw-textfield>

        <iw-textfield #txtIforce
                      [isDisabled]="true"
                      [noLabel]="true"
                      class="iw-input col-xs-6 padding-top-15"
                      formControlName="iforce"
                      style="text-align-last: center"></iw-textfield>

        <iw-button #btnNeg
                   [isDisabled]="isReadonly"
                   [text]="'Neg' | translate"
                   class="iw-button col-xs-6 padding-top-15 padding-bottom-15"
                   style="display: inline-flex"></iw-button>

        <iw-button #btnRep
                   [isDisabled]="isReadonly"
                   [text]="'Rep' | translate"
                   class="iw-button col-xs1 padding-top-15"
                   style="display: inline-flex"></iw-button>

        <iw-button #btnProvisions
                   (press)="goToProvisions()"
                   [isDisabled]="!isReadonly"
                   [text]="'provisions' | translate"
                   class="iw-button btn-block col-xs-12 padding-top-15"></iw-button>
        <iw-button #btnDecompte
                   (press)="goToDecompte()"
                   [isDisabled]="!isReadonly"
                   [text]="'decompteGS' | translate"
                   class="iw-button btn-block col-xs-12 padding-top-15"></iw-button>
    </div>
</form>
