<iw-dialog #dialog
           (beforeHide)="onBeforeHide()"
           [bodyPadding]="'10'"
           [closable]="true"
           [cls]="'iw-modal'"
           [draggable]="true"
           [height]="'60vh'"
           [maximized]="true"
           [modal]="false"
           [resizable]="true"
           [showHeader]="true"
           [title]="'layout_menu_email_list' | translate"
           [visible]="true"
           [width]="'80vw'">
    <iw-busy [ajustLeft]="5"
             [ajustTop]="5"
             [isBusy]="loading"></iw-busy>

    <div class="row center-xs">
        <p-message *ngIf="origin"
                   [text]="origin"
                   class="col-xs-12"
                   severity="warn"></p-message>
    </div>

    <div class="row">
        <iw-rest-grid
            #printRequestGrid
            (rowDoubleClick)="onRowDoubleClick($event)"
            (selected)="onSelection($event)"
            [customColumns]="columnsToShow"
            [defaultSelected]="true"
            [hasFilter]="true"
            [hasGridColumnMenu]="false"
            [hasSortIcon]="true"
            [queryStatements]="query"
            [selectMode]="'single'"
            [type]="emailViewType"
            class="col-xs-12"
            style="height: 20vh"></iw-rest-grid>
    </div>


    <!--     DETAIL-->
    <p-scrollPanel
            [style]="{width: '100%', height: '35vh'}"
            styleClass="iw-scrollbar">
        <div *ngIf="currentMail"
             class="row">
            <div class="col-xs-12">
                <div class="row">
                    <iw-textfield [isDisabled]="true"
                                  [labelAlign]="'left'"
                                  [label]="'de' | translate"
                                  [value]="currentMail?.replyTo"
                                  class="iw-input col-xs-12"></iw-textfield>
                </div>
                <div class="row">
                    <iw-multi-select
                            [defaultOptions]="currentMail?.recipients"
                            [isDisabled]="true"
                            [labelAlign]="'left'"
                            [label]="'to' | translate"
                            [value]="currentMail?.recipients"
                            class="iw-input col-xs-12"></iw-multi-select>
                </div>
                <div class="row">
                    <iw-multi-select
                            [defaultOptions]="currentMail?.bccRecipients"
                            [isDisabled]="true"
                            [labelAlign]="'left'"
                            [label]="'bcc' | translate"
                            [value]="currentMail?.bccRecipients"
                            class="iw-input col-xs-12"></iw-multi-select>
                </div>
                <div class="row">
                    <iw-textfield #txtMsg_subject
                                  [isDisabled]="true"
                                  [labelAlign]="'left'"
                                  [label]="'sujet' | translate"
                                  [value]="currentMail?.subject"
                                  class="iw-input col-xs-12"></iw-textfield>

                </div>
                <div class="row">
                    <iw-editor #edtMsg_text
                               [isDisabled]="true"
                               [labelAlign]="'left'"
                               [label]="'message' | translate"
                               [value]="currentMail?.body"
                               class="iw-input col-xs-12"></iw-editor>

                </div>
                <!--            <div class="row">-->
                <!--                <iw-checkbox #chkLgenSuivi-->
                <!--                             [isDisabled]="true"-->

                <!--                             [label]="'genererUnSuivi' | translate"-->
                <!--                             class="iw-input col-xs-2"-->
                <!--                             formControlName="generateSuivi"></iw-checkbox>-->
                <!--                <p-message *ngIf="!suiviAllowed"-->
                <!--                           [text]="'suivis_not_possible_no_conseiller_linked' | translate"-->
                <!--                           class="col-xs"-->
                <!--                           severity="warn"></p-message>-->

                <!--            </div>-->
                <div class="row separator-top">
                    <iw-editor *ngIf="currentMail?.htmlSignature"
                               [isDisabled]="true"
                               [labelAlign]="'left'"
                               [labelHeight]="'130px'"
                               [label]="'signature' | translate"
                               [value]="currentMail?.htmlSignature"
                               class="col-xs-12"></iw-editor>
                </div>

                <div *ngIf="currentMail?.attachments?.length > 0"
                     class="row seperator-top">
                    <div class="col-xs-12">
                        <p class="iw-label">{{ 'attachments' | translate }}</p>
                    </div>
                    <div class="col-xs">
                        <div *ngFor="let att of currentMail?.attachments"
                             class="row">
                            <a (click)="downloadAttachment(att)"
                               class="col-xs-11"
                               href="#"
                            >
                                {{ getFileName(att) }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-scrollPanel>


</iw-dialog>
