import {Component, Input, Type, ViewChild} from '@angular/core';
import {DocumentEntityRef} from '@sam-base/models/placement/document-entity-ref';

@Component({
    selector: 'iw-tab-ppcdetempo-doc', templateUrl: './tab-ppcdetempo-doc.component.html',
    standalone: false
})
export class TabPpcdetempoPageDocComponent {

    @Input() public isReadonly = true;

    public entityRef: DocumentEntityRef = DocumentEntityRef.CommandTempo;

    @Input()
    public set cdeId(v: string) {
        this._cdeId = v;
    }

    public get cdeId() {
        return this._cdeId;
    }

    private _cdeId = '';

    constructor() {
    }
}
