import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base';
import {IwStoreService} from '@app/sam-base/core';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {SaemcService} from '@app/sam-base/core/services/saemc.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, FormKeys} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary';
import {Saemc} from '@app/sam-base/models/salary/saemc';
import {ParametersService} from '@core/services/parameters.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    templateUrl: './paramSD4.component.html',
    standalone: false
})
export class ParamSD4Component extends BaseFormComponent<Saemc> implements OnInit {

    public sapar?: Sapar;
    public title?: string;
    public allData?: string[];
    public value?: number;
    public sd4Data?: string;
    public textAreaData?: string;
    public readonly = true;
    public convertion?: string;
    public dropOptions: ComboboxItem<number>[] = [];
    public sdOptions: {
        value: number; name: string; title: string;
    }[] = [{value: 0, name: ' ', title: ' '}, {
        value: 1, name: this._translate.instant('exclure01'), title: 'NOIS01'
    }, {value: 2, name: this._translate.instant('exclure02'), title: 'NOIS02'}, {
        value: 3, name: this._translate.instant('exclure03'), title: 'NOIS03'
    }, {value: 4, name: this._translate.instant('exclure04'), title: 'NOIS04'}, {
        value: 5, name: this._translate.instant('exclure05'), title: 'NOIS05'
    }, {value: 6, name: this._translate.instant('exclure06'), title: 'NOIS06'}, {
        value: 7, name: this._translate.instant('exclure07'), title: 'NOIS07'
    }, {value: 8, name: this._translate.instant('exclure08'), title: 'NOIS08'}, {
        value: 9, name: this._translate.instant('exclure09'), title: 'NOIS09'
    }, {value: 10, name: this._translate.instant('exclure10'), title: 'NOIS10'}, {
        value: 11, name: this._translate.instant('exclure11'), title: 'NOIS11'
    }, {value: 12, name: this._translate.instant('exclure12'), title: 'NOIS12'}, {
        value: 13, name: this._translate.instant('exclure13'), title: 'NOISALL'
    }, {value: 14, name: this._translate.instant('exclure14'), title: 'NOSDS'}];
    private readonly _restSaemcClient: RestEntityClient<Saemc>;

    constructor(private _store: IwStoreService, private _parametersService: ParametersService, private _translate: TranslateService, private _saemcService: SaemcService, rest: RestApiService) {
        super();
        this._restSaemcClient = rest.getEntityClient(Saemc);
    }

    public get empId(): string {
        return this.getData('empId', true) || '';
    }

    public ngOnInit() {
        this._parametersService
            .getCurrentSapar()
            .subscribe((sapar) => (this.sapar = sapar));

        if (this.empId && this.sapar?.annee) {
            this._saemcService
                .findSaemcByEmpIdAndAnee(this.empId, this.sapar.annee)
                .subscribe((saemc: Saemc) => {
                    this.sd4Data = saemc.sd4Data || '';
                    const splitSd4Data = [...this.sd4Data.matchAll(/<(\w+)>/g)].map((element) => element[1].toUpperCase());
                    this.allData = this.sdOptions
                        .filter(({title}) => splitSd4Data.includes(title))
                        .map(({name}) => name);
                    this.textAreaData = this.allData.join('\n');
                });
        }
        this.getAllOptions();
    }

    public setValues(value: number) {
        this.value = this.sdOptions[value].value;
        this.title = this.sdOptions[value].title;
    }

    public copyData() {
        if (this.value && !this.allData?.includes(this.sdOptions[this.value].name)) {
            this.allData?.push(this.sdOptions[this.value].name);
            this.textAreaData = this.allData?.join('\n');
            this.setFormValue('sd4Data', this.conversionData(this?.allData || []));
        }
    }

    public removeData() {
        if (this.value && this.allData?.includes(this.sdOptions[this.value].name)) {
            const index = this.allData.indexOf(this.sdOptions[this.value].name);
            this.allData.splice(index, 1);
            this.textAreaData = this.allData?.join('\n');
            this.setFormValue('sd4Data', this.conversionData(this?.allData || []));
        }
    }

    public conversionData(allData: string[]) {
        return this.sdOptions
            .filter(({name}) => this.allData?.includes(name))
            .map(({title}) => `<${title.toLowerCase()}>1</${title.toLowerCase()}>`)
            .join('');
    }

    public modifyEntry(): void {
        this.readonly = false;
    }

    public saveEntry(): void {
        this.setFormValue('empId', this.empId);
        this.setFormValue('annee', this.sapar?.annee);
        const formData = this.getFormData();
        const updatedData = this._restSaemcClient.construct({
            ...formData
        });
        this._saemcService
            .saveByEmpIdAndAnnee(this.empId, this.sapar?.annee || '', updatedData)
            .subscribe(() => {
                this.readonly = true;
            });
    }

    public cancelEditionMode() {
        this.readonly = true;
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Saemc> {
        return ['empId', 'sd4Data', 'annee'];
    }

    protected validateFields(e: Saemc): boolean {
        return super.validateFields(e);
    }

    private getAllOptions() {
        this.sdOptions.forEach(({name, value}) => this.dropOptions.push({name, value}));
    }
}
