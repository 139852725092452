<form [formGroup]="formGroup"
      class="row">
    <div class="col-xs-6">
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'agence' | translate"
                              class="iw-input"
                              formControlName="categorie1"></iw-textfield>
            </div>
            <div class="col-xs-6">
                <iw-agency-combo-box [isDisabled]="isReadonly"
                                     [noLabel]="true"
                                     class="iw-combobox"
                                     formControlName="categorie1"></iw-agency-combo-box>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'sector' | translate"
                              class="iw-input"
                              formControlName="categorie2"></iw-textfield>
            </div>
            <div class="col-xs-6">
                <iw-secteur-dropdown [isDisabled]="isReadonly"
                                     [noLabel]="true"
                                     class="iw-combobox"
                                     formControlName="categorie2"></iw-secteur-dropdown>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'categorie3' | translate"
                              class="iw-input"
                              formControlName="categorie3"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="isReadonly"
                              [labelAlign]="'left'"
                              [label]="'cantonTravail' | translate"
                              class="iw-input"
                              formControlName="cantontrav"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'cantonPaysHab' | translate"
                              class="iw-input"
                              formControlName="cantonhab"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-pays-dropdown
                        [isDisabled]="true"
                        [noLabel]="true"
                        [value]="pays"
                        [width]="'100%'"
                        class="iw-input"></iw-pays-dropdown>
            </div>
            <div class="col-xs-2">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              [value]="pays"
                              class="iw-input"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'cantonIS' | translate"
                              class="iw-input"
                              formControlName="cantonimp"></iw-textfield>
            </div>
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              [textAlign]="'center'"
                              [value]="bareme"
                              class="iw-input"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-date-picker [isDisabled]="true"
                                [labelAlign]="'left'"
                                [label]="'periodeIS' | translate"
                                [value]="saeisain"
                                class="iw-input"></iw-date-picker>
            </div>
            <div class="col-xs-4">
                <iw-date-picker [isDisabled]="true"
                                [noLabel]="true"
                                [value]="saeisaout"
                                class="iw-input"></iw-date-picker>
            </div>
            <div class="col-xs-2">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              [value]="saeisanbj"
                              class="iw-input"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="isReadonly"
                              [labelAlign]="'left'"
                              [label]="'nb_jours_de_travail' | translate"
                              [warningMessage]="'nb_jours_de_travail_warning' | translate"
                              class="iw-input text-align-right"
                              decimals="0.0"
                              formControlName="nbjTravTot"
                              type="number"></iw-textfield>
            </div>
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="isReadonly"
                              [labelAlign]="'left'"
                              [label]="'nb_jours_de_travail_CH' | translate"
                              [warningMessage]="'nb_jours_de_travail_warning' | translate"
                              class="iw-input text-align-right"
                              decimals="0.0"
                              formControlName="nbjTravCh"
                              type="number"></iw-textfield>
            </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-date-picker [isDisabled]="true"
                                [labelAlign]="'left'"
                                [label]="'periodeAVS' | translate"
                                class="iw-input"
                                formControlName="dateDe"></iw-date-picker>
            </div>
            <div class="col-xs-3">
                <iw-date-picker [isDisabled]="true"
                                [noLabel]="true"
                                class="iw-input"
                                formControlName="dateA"></iw-date-picker>
            </div>
            <div class="col-xs-3">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              [textAlign]="'center'"
                              [value]="nbjCna"
                              class="iw-input text-align-right"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-date-picker [isDisabled]="true"
                                [labelAlign]="'left'"
                                [label]="'firstlastdaywork' | translate"
                                [value]="firstDay"
                                class="iw-input"></iw-date-picker>
            </div>
            <div class="col-xs-3">
                <iw-date-picker [isDisabled]="true"
                                [noLabel]="true"
                                [value]="lastDay"
                                class="iw-input"></iw-date-picker>
            </div>
            <div class="col-xs-3">
                <iw-date-picker [isDisabled]="true"
                                [noLabel]="true"
                                [textAlign]="'center'"
                                [value]="lastDay2"
                                class="iw-input"></iw-date-picker>
            </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'nbhoursdays' | translate"
                              [value]="nbHours"
                              class="iw-input text-align-right"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              [value]="nbDays"
                              class="iw-input text-align-right"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="isReadonly"
                              [labelAlign]="'left'"
                              [label]="'nbhparmois' | translate"
                              class="iw-input text-align-right"
                              decimals="0.0"
                              formControlName="heuParMois"
                              type="number"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield [isDisabled]="isReadonly"
                              [noLabel]="true"
                              class="iw-input text-align-right"
                              decimals="0.0"
                              formControlName="heuParMoisEnt"
                              type="number"></iw-textfield>
            </div>
        </div>
        <div class="row">
        </div>
        <div class="row">
            <div class="col-xs-6">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'salhormoyen' | translate"
                              [value]="salHor"
                              class="iw-input text-align-right"></iw-textfield>
            </div>
        </div>
    </div>
    <div class="col-xs-6">
        <div class="row">
            <div class="col-xs-8">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'calcule' | translate"
                              [value]="dateCalc | iwDatetime"
                              class="iw-input"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              class="iw-input text-align-right"
                              formControlName="nbofcalc"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-8">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'published' | translate"
                              [value]="datePublished | iwDatetime"
                              class="iw-input"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              class="iw-input text-align-right"
                              formControlName="nbOfPublished"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-8">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'imprime' | translate"
                              [value]="datePrnt | iwDatetime"
                              class="iw-input"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              class="iw-input text-align-right"
                              formControlName="nbofprint"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-8">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'emaile' | translate"
                              [value]="dateMail | iwDatetime"
                              class="iw-input"></iw-textfield>
            </div>
            <div class="col-xs-4">
                <iw-textfield [isDisabled]="true"
                              [noLabel]="true"
                              class="iw-input text-align-right"
                              formControlName="nbofmail"></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">

                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'sendingStatus' | translate"
                              [value]="'enum.gerplhdr.sendingStatus.' + sendingStatus | translate"
                              class="iw-input"
                ></iw-textfield>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-8">
                <iw-textfield [isDisabled]="true"
                              [labelAlign]="'left'"
                              [label]="'datebs' | translate"
                              [value]="dateBs | iwDatetime"
                              class="iw-input"></iw-textfield>
            </div>
        </div>

        <div class="row end-xs">
            <div class="col-xs-6">
                <iw-button (press)="openParams()"
                           [text]="'param_calcul' | translate">
                </iw-button>
            </div>
        </div>
        <div class="row end-xs">
            <div class="col-xs-6">
                <iw-button (press)="openPpParams()"
                           [isDisabled]="isReadonly"
                           [text]="'param_placement' | translate">
                </iw-button>
            </div>
        </div>
        <div class="row end-xs">
            <div class="col-xs-6">
                <iw-button (press)="openDecompteGS()"
                           [isDisabled]="isReadonly"
                           [text]="'decompteGS' | translate">
                </iw-button>
            </div>
        </div>
    </div>
</form>
