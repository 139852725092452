import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params} from '@angular/router';

import {IwDecompteName} from '@app/modules/sam-decompte/models/iw-decompte-names.model';
import {OperationEvents} from '@app/modules/sam-main/salary/events';
import {SalaryForm} from '@app/modules/sam-main/salary/salary.forms';

import {
    AppState,
    ContextMenuEvent,
    DashboardNavigationService,
    EntityDeleteService,
    EntityNavigationService,
    GridFilterEvents,
    HeaderToolbarService,
    IwEvents,
    ModalService,
    ViewContainerRefService
} from '@app/sam-base/core';
import {SubMenuStatisticsNames} from '@app/sam-base/core/enum/sub-menu-statistics.enum';
import {IwEventHandler} from '@app/sam-base/core/events/core/iw-event-handler.decorator';
import {IwEventHubService} from '@app/sam-base/core/events/core/iw-event-hub.service';
import {FormHandlerService} from '@app/sam-base/core/form-handler';
import {
    localSaemsviewStatusProfile,
    saempViewCustomProfile
} from '@app/sam-base/core/grid-profile/models/grid-profile-default';
import {GridProfile} from '@app/sam-base/core/grid-profile/models/grid-profile.model';
import {SearchNavigationService} from '@app/sam-base/core/search-navigation/search-navigation.service';
import {EmployeeDeductionService} from '@app/sam-base/core/services/employee-deduction-service';
import {FactureService} from '@app/sam-base/core/services/facture.service';
import {ToastService} from '@app/sam-base/core/toast';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {LayoutSalaryNavigationEvent} from '@app/sam-base/layout';
import {LayoutEvents, LayoutSubNavigationEvent} from '@app/sam-base/layout/events/events.models';

import {Gecli, Gepar} from '@app/sam-base/models/invoices';
import {Gecliview} from '@app/sam-base/models/invoices/gecliview';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';
import {Gefacliview} from '@app/sam-base/models/invoices/gefacliview';
import {Gepaicliview} from '@app/sam-base/models/invoices/gepaicliview';
import {Sapar} from '@app/sam-base/models/salary';
import {Saempdedview} from '@app/sam-base/models/salary/saempdedview';
import {Saempview} from '@app/sam-base/models/salary/saempview';
import {Saemsview} from '@app/sam-base/models/salary/saemsview';


import {Sags} from '@app/sam-base/models/salary/sags';
import {Sapardedview} from '@app/sam-base/models/salary/sapardedview';
import {CommunicationType} from '@core/mail/mail.models';
import {IwDecompteNavService} from '@core/services/iw-decompte-nav.service';
import {KibanaService} from '@core/services/kibana.service';
import {ParametersService} from '@core/services/parameters.service';
import {BulkManagerComponent} from '@modules/sam-main/admin/components/bulk/bulk-manager.component';
import {BulkManagerOptions} from '@modules/sam-main/admin/components/bulk/bulk-manager.options';
import {
    PrintRequestManagerComponent
} from '@modules/sam-main/admin/components/print-request/print-request-manager.component';
import {
    PrintRequestManagerOptions
} from '@modules/sam-main/admin/components/print-request/print-request-manager.options';
import {OperationType} from '@modules/sam-main/admin/models/operation.model';
import {PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {PlacementForm} from '@modules/sam-main/placement/placement.forms';
import {SalaryService} from '@modules/sam-main/salary/services/salary-service';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';
import {EncaissementService} from '@sam-base/core/services/encaissement.service';
import {GecliService} from '@sam-base/core/services/gecli.service';
import {SaempService} from '@sam-base/core/services/saemp.service';
import {SaemsService} from '@sam-base/core/services/saems.service';
import {SagenService} from '@sam-base/core/services/sagen.service';
import {GedtaView} from '@sam-base/models/invoices/gedtaview';
import {GerplhdrView} from '@sam-base/models/invoices/gerplhdrview';
import {Ppacattestview, Ppagiview} from '@sam-base/models/placement';
import {SaepView} from '@sam-base/models/salary/saepview';
import {lastValueFrom, of} from 'rxjs';
import {catchError} from 'rxjs/operators';


@Injectable()
export class SalaryLayoutEventsHandlers {
    public sapar?: Sapar;
    private _activeSapar?: Sapar;

    constructor(private readonly searchNavService: SearchNavigationService,
                private readonly eventsHub: IwEventHubService<string>,
                private readonly _formHandler: FormHandlerService<SalaryForm>,
                private readonly _formHandlerPlacement: FormHandlerService<PlacementForm>,
                private readonly _parametersService: ParametersService,
                private readonly _saemsService: SaemsService,
                private readonly _decompteNav: IwDecompteNavService,
                private readonly _dashboardNavService: DashboardNavigationService,
                public readonly _kibanaService: KibanaService,
                public readonly _http: HttpClient,
                private readonly _modalService: ModalService,
                private readonly _salaryService: SalaryService,
                private readonly _viewHostRef: ViewContainerRefService,
                private readonly _employeeDeductionService: EmployeeDeductionService,
                private readonly _toastService: ToastService,
                private readonly _factureService: FactureService,
                private readonly _encaissementService: EncaissementService,
                private readonly _navigationService: EntityNavigationService,
                private readonly _eventHub: IwEventHubService<IwEvents>,
                private readonly _sagenService: SagenService,
                private readonly _entityDeleteService: EntityDeleteService,
                private readonly _translate: TranslateService,
                private readonly _saempService: SaempService,
                private readonly _headerService: HeaderToolbarService,
                private readonly _gecliService: GecliService) {
    }

    @IwEventHandler(LayoutSalaryNavigationEvent.EMPLOYEE)
    public employee() {
        const currentYear = this._parametersService.activeYear;

        const gridProfile: GridProfile<Saempview> = getDeepCopy(new GridProfile(saempViewCustomProfile(currentYear)));

        this.searchNavService.navigate({
            entity: Saempview,
            profile: gridProfile
        });
    }

    @IwEventHandler(LayoutSalaryNavigationEvent.DASHBOARD)
    public dashboard(_: AppState, cliId?: string) {
        this._dashboardNavService.navigateToDashboard();
        this._kibanaService.navigateToKibana(SubMenuStatisticsNames.invoiceManagement, cliId);

    }

    @IwEventHandler(LayoutSalaryNavigationEvent.CLIENT)
    public client() {
        this.searchNavService.navigate(Gecliview);
    }

    @IwEventHandler(LayoutSalaryNavigationEvent.FICHE_SALAIRE)
    public ficheSalaire(_: AppState, params: any) {

        const gridProfile: GridProfile<Saemsview> = getDeepCopy(new GridProfile(localSaemsviewStatusProfile));
        if (params?.wildcard) {
            gridProfile.columns.wildcard = params?.wildcard;
        }

        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate({
            entity: Saemsview,
            baseFilters: params?.filters,
            profile: gridProfile,
        });
    }

    @IwEventHandler(LayoutSalaryNavigationEvent.FACTURE)
    public facture() {
        this.searchNavService.navigate(Gefacliview);
    }

    @IwEventHandler(LayoutSalaryNavigationEvent.ENCAISSEMENT)
    public encaissement() {
        this.searchNavService.navigate(Gepaicliview);
    }

    @IwEventHandler(LayoutSalaryNavigationEvent.PAIEMENT)
    public paiement(_: AppState, params: any) {
        this.searchNavService.navigate({
            entity: SaepView,
            baseFilters: params?.filters,
        });

    }

    @IwEventHandler(LayoutSalaryNavigationEvent.ORDRE_PAIEMENT)
    public ordre(_: AppState, params: any) {
        this.searchNavService.navigate({
            entity: GedtaView,
            baseFilters: params?.filters,
        });

    }

    @IwEventHandler(LayoutSubNavigationEvent.INVOICE_REMINDER)
    public reminders() {
        this.searchNavService.navigate(GerplhdrView);
    }

    @IwEventHandler(LayoutSubNavigationEvent.CREATE_INVOICE_REMINDER)
    public createReminder() {
        const options: BulkManagerOptions = {
            type: OperationType.CREATE_INVOICE_REMINDER,
            salId: undefined,
            force: false
        };
        this._modalService.showModal(BulkManagerComponent, options);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SEND_INVOICE_REMINDER)
    public sendReminder() {
        const options: BulkManagerOptions = {
            type: OperationType.SEND_INVOICE_REMINDER,
            salId: undefined,
            force: false
        };
        this._modalService.showModal(BulkManagerComponent, options);
    }

    @IwEventHandler(LayoutSubNavigationEvent.INVOICE_REMINDER_PRINT_REQUEST)
    public reminderPrintRequest() {
        const options: PrintRequestManagerOptions = {
            type: PrintRequestType.INVOICE_REMINDER,
        };
        this._modalService.showModal(PrintRequestManagerComponent, options);
    }


    @IwEventHandler(LayoutSubNavigationEvent.INVOICE_PRINT_REQUEST)
    public printRequest() {
        const options: PrintRequestManagerOptions = {
            type: PrintRequestType.INVOICE,
        };
        this._modalService.showModal(PrintRequestManagerComponent, options);
    }

    @IwEventHandler(LayoutSubNavigationEvent.PERIODE)
    public periode() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Periode);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SELECTION_EXERCISE)
    public exerciseSelection() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.SelectionExercise);
    }

    @IwEventHandler(LayoutSubNavigationEvent.RECALCIO)
    public async recalcio() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        const options: ModalMessageOptions = {
            message: [this._translate.instant('recalculerMessage') + '?'],
            showCancel: true,
            title: this._translate.instant('recalculer'),
            width: 200
        };

        this._headerService.getActiveExercise()
            .subscribe(sapar => this._activeSapar = sapar);

        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            this._saempService.getRecalculation(this._activeSapar?.annee)
                .subscribe((res: any) => {
                    const data = res;
                });
        } catch (error) {
        }
    }

    @IwEventHandler(LayoutSubNavigationEvent.ATTESTATION_QUITANCE)
    public attestationQuitance() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DECOMPTE_ANNUEL)
    public decompteAnnuel() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DECOMPTE_IS)
    public decompteIS() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }


    @IwEventHandler(LayoutSubNavigationEvent.ANNOCE_AVS)
    public annoceAVS() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
    }

    @IwEventHandler(LayoutSubNavigationEvent.ASSURANCES)
    public assurances() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Assurances);
    }

    @IwEventHandler(LayoutSubNavigationEvent.GEPER)
    public periodeFac() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Geper);
    }

    @IwEventHandler(LayoutSubNavigationEvent.BOOKING_FILES_INVOICE)
    public bookingFilesInvoice() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.BookingFileList, {module: 'FA'});
    }

    @IwEventHandler(LayoutSubNavigationEvent.BOOKING_FILES_SALARY)
    public bookingFilesSalary() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.BookingFileList, {module: 'SA'});
    }

    @IwEventHandler(LayoutSubNavigationEvent.GEPAIMODE)
    public gepaimode() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Gepaimode);
    }

    @IwEventHandler(LayoutSubNavigationEvent.GEPAICOND)
    public gepaicond() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Gepaicond);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SALARY_DEDUCTION)
    public salaryDeductionsView() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate({
            entity: Sapardedview,
            baseFilters: {annee: this._parametersService.activeYear}
        });
    }

    @IwEventHandler(LayoutSubNavigationEvent.GENRE_DE_SALAIRE)
    public genreSalaire() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.searchNavService.navigate({
            entity: Sags
        });
    }

    @IwEventHandler(LayoutSubNavigationEvent.SAPAIMODE)
    public modePaiementSalaire() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Sapaimode);
    }

    @IwEventHandler(OperationEvents.GECLI_FACNC)
    public async openGeclifac(_: AppState, event: ContextMenuEvent<Gecli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL);
            this.searchNavService.navigate({
                entity: Gefacliview,
                baseFilters: {cliId: id}
            });
        }
    }

    @IwEventHandler(LayoutSubNavigationEvent.GECLICAT)
    public clientCategory() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.Geclicat);
    }

    @IwEventHandler(OperationEvents.CREATE_NOTE_CREDIT)
    public async createNoteCredit(_: AppState, event: ContextMenuEvent<Gefacliview>) {
        const id = event.selected[0] && event.selected[0].facId;
        if (id) {
            this._factureService.copyFacture(id, -1)
                .subscribe((gefac) => {
                    this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
                    this._navigationService.navigateToEntityForm(Gefachdr, gefac.facId || '', undefined, 'edit');
                });
        }
    }

    @IwEventHandler(OperationEvents.FACTURE_EMAIL)
    public async onEmailFacture(_: AppState, event: ContextMenuEvent<Gefacliview>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            if (sel.published)
                ids.push(sel.facId || '');
        });
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.GEFACHDR_FAC,
            entityIds: ids
        });
    }

    @IwEventHandler(OperationEvents.AGI_EMAIL)
    public async onEmailAGI(_: AppState, event: ContextMenuEvent<Ppagiview>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.agiId || '');
        });
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.AGI_EMAIL,
            entityIds: ids,

        });
    }

    @IwEventHandler(OperationEvents.ACA_EMAIL)
    public async onEmailACA(_: AppState, event: ContextMenuEvent<Ppacattestview>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.acaId || '');
        });
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.ACA_EMAIL,
            entityIds: ids,
        });
    }

    @IwEventHandler(OperationEvents.FACTURE_DELETE)
    public async onDeleteFacture(_: AppState, event: ContextMenuEvent<Gefacliview>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.facId || '');
        });
        if (!(await this._entityDeleteService.openEntitiesDeleteDialog(ids))) {
            return;
        }
        this._factureService.deleteBulk(ids).subscribe(res => {
            this.eventsHub.emit(GridFilterEvents.refreshGridProfile);
        }, error => {
            this.eventsHub.emit(GridFilterEvents.refreshGridProfile);
        });
    }

    @IwEventHandler(OperationEvents.DOWNLOAD_FACTURE)
    public async printFacture(_: AppState, event: ContextMenuEvent<Gefacliview>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.facId || '');
        });
        this._factureService.download(ids || '').subscribe();
    }

    @IwEventHandler(OperationEvents.GECLI_EMAIL)
    public async openGecliemail(_: AppState, event: ContextMenuEvent<Gecli>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.cliId || '');
        });

        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.GECLI_GENERIC,
            entityIds: ids
        });
    }

    @IwEventHandler(OperationEvents.SAEMP_EMAIL)
    public async openSaempemail(_: AppState, event: ContextMenuEvent<Saempview>) {
        const ids: string[] = [];
        event?.selected.forEach(sel => {
            ids.push(sel.empId || '');
        });

        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.SAEMP_GENERIC,
            entityIds: ids
        });
    }

    @IwEventHandler(OperationEvents.SAEMP_BS_CURYEAR)
    public async openSaempBSAllYear(_: AppState, event: ContextMenuEvent<Saempview>) {
        const id = event.selected[0] && event.selected[0].empId;
        if (id) {
            this._modalService.showModal(MailComposerComponent, {
                communicationType: CommunicationType.SAEMP_BS_CURYEAR,
                entityIds: [id]
            });
        }
    }

    @IwEventHandler(OperationEvents.SEND_OPEN_INVOICES)
    public async sendGecliOpenInvoices(_: AppState, event: ContextMenuEvent<Gecli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            const hasOpenInvoices = await lastValueFrom(this._gecliService.hasOpenInvoices(id));
            if (hasOpenInvoices) {
                this._modalService.showModal(MailComposerComponent, {
                    communicationType: CommunicationType.SEND_OPEN_INVOICES,
                    entityIds: [id]
                });
            } else {
                this._toastService.warning('noOpenInvoiceForThatGecli');
            }
        }
    }

    @IwEventHandler(OperationEvents.GECLI_ENCAISSEMENT)
    public async openEncaissementForClient(_: AppState, event: ContextMenuEvent<Gecli>) {
        const id = event.selected[0] && event.selected[0].cliId;
        if (id) {
            this._encaissementService.createEncaissementForClient(id)
                .subscribe((gepaihdr) => {
                    this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
                    this._navigationService.navigateToEntityForm(Gepaicliview, gepaihdr.payId || '', undefined, 'edit');
                });
        }
    }

    @IwEventHandler(OperationEvents.DELETE_DEDUCTION)
    public deleteDocument(_: AppState, event: ContextMenuEvent<Saempdedview>) {
        const allowedDedCodesToDelete = [
            'LAC',
            'IJM',
            'LPP'];

        if (event.value && event.value.edId && allowedDedCodesToDelete.indexOf(event.value.dedCode || '') !== -1) {
            this._employeeDeductionService.deleteDeductionById(event.value.edId)
                .pipe(catchError(() => of(false)))
                .subscribe();
        } else {
            this._toastService.warning('deduction_not_allowed_to_delete_for_dedcode');
        }
    }

    @IwEventHandler(OperationEvents.FICHE_SAL_CALCULATE)
    public async calculateFicheSal(_: AppState, event: ContextMenuEvent<Saemsview>) {
        if (event.selected && event.selected[0]) {
            const saemsView = event.selected[0];
            this._salaryService.calculate(saemsView.id!).subscribe(() => {
                this.eventsHub.emit(GridFilterEvents.refreshGridProfile);
            });
        }
    }

    @IwEventHandler(OperationEvents.FICHE_SAL_PUBLISH)
    public async publishFicheSal(_: AppState, event: ContextMenuEvent<Saemsview>) {

        if (event.selected && event.selected[0]) {
            const saemsView = event.selected[0];
            this._salaryService.publish(saemsView.id!).subscribe(() => {
                this.eventsHub.emit(GridFilterEvents.refreshGridProfile);
            });
        }

        // // check if any of the event.selected.published is true
        // const isAnyPublished = event.selected.some(s => s.published);
        // if (isAnyPublished) {
        //     this._toastService.warning('salary_publish_operation.saems_already_published');
        //     return;
        // }

        // const empIds = event.selected.filter(s => s.empId).map(s => s.empId!);
        // const salId = event.selected[0] && event.selected[0].salId;
        //
        // if (!empIds || !salId) {
        //     return;
        // }
        // this._salaryService.publish(empIds, salId).subscribe((operationDetail) => {
        //     this._toastService.operationFeedback(operationDetail)
        //     this.eventsHub.emit(GridFilterEvents.refreshGridProfile);
        // });
    }


    @IwEventHandler(OperationEvents.FICHE_SAL_DOWNLOAD)
    public async printFicheSal(_: AppState, event: ContextMenuEvent<Saemsview>) {
        const saemsList = event.selected;
        this._salaryService.download(saemsList.map(s => s.id || '')).subscribe();
        // this._reports.getPdfReport('BS', 'saems', id, saemsList[0], 'salary');
    };

    @IwEventHandler(OperationEvents.FICHE_SAL_MAIL)
    public async emailFicheSal(_: AppState, event: ContextMenuEvent<Saemsview>) {
        const saemsList = event.selected;
        const id = saemsList[0] && saemsList[0].id || '';
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.SAEMS_BS,
            entityIds: [id]
        });
    };

    @IwEventHandler(OperationEvents.FICHE_SAL_DELETE)
    public async deleteFicheSal(_: AppState, event: ContextMenuEvent<Saemsview>) {
        const ids: string[] = event.selected?.map(s => s.id!);
        if (!ids) return;
        this._saemsService.deleteSaemsBulk(ids).subscribe({
            next: () => this.eventsHub.emit(GridFilterEvents.refreshGridProfile),
            error: (error) => this.eventsHub.emit(GridFilterEvents.refreshGridProfile)
        });
    }

    public openEmailForm() {

    }

    @IwEventHandler(LayoutSubNavigationEvent.PARAMETRES_GENERAUX_SALARY) @IwEventHandler(LayoutSubNavigationEvent.PARAMETRES_GENERAUX_SALARY)
    public parametresGeneraux() {
        let id: string;
        this._parametersService.getCurrentSapar()
            .subscribe((sapar: Sapar) => {
                id = sapar.annee ?? '1970';
                this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
                this._formHandler.showFormDialog(SalaryForm.ParametresGeneraux, undefined, (s) => ({
                    ...s,
                    entityId: id,
                    diagModal: true
                }));
            });
    }

    @IwEventHandler(LayoutSubNavigationEvent.PARAMETRES_GENERAUX_FACTURATION)
    public parametresGenerauxFacturation() {
        let id: string;
        this._parametersService.getGepar()
            .subscribe((gepar: Gepar) => {
                id = gepar.nomSoc1!;
                this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
                this._formHandler.showFormDialog(SalaryForm.ParametresGenerauxFacturation, undefined, (s) => ({
                    ...s,
                    entityId: id,
                    diagModal: true
                }));
            });
    }

    @IwEventHandler(LayoutSubNavigationEvent.PARAMETRES_NCS)
    public parametresNcs() {
        let id: string;
        this._parametersService.getCurrentSapar()
            .subscribe((sapar: Sapar) => {
                id = sapar.annee ?? '1970';
                this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
                this._formHandler.showFormDialog(SalaryForm.ParametresNcs, undefined, (s) => ({
                    ...s,
                    entityId: id,
                    diagModal: true,
                    readMode: 'read'
                }));
            });
    }

    @IwEventHandler(LayoutSubNavigationEvent.CANTON_IS)
    public cantonIS() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.CantonIS, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.CANTON_BAREME)
    public cantonBareme() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandler.showFormDialog(SalaryForm.CantonBareme, undefined, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.JOURNAUX)
    public newClientDecompte(_: AppState, params: Params) {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Journaux, params);
    }

    @IwEventHandler(OperationEvents.GECLI_RELEVE)
    public gecliReleve(_: AppState, event: ContextMenuEvent<Gecliview>) {
        if (event.selected && event.selected[0]) {
            const cliId = event.selected[0].cliId;
            this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
            this.navigateToDecompte(IwDecompteName.ReleveComptesDebiteurs, {cliId});
        }
    }

    @IwEventHandler(OperationEvents.GECLI_DEBSIT)
    public gecliDebSit(_: AppState, event: ContextMenuEvent<Gecliview>) {
        if (event.selected && event.selected[0]) {
            const cliId = event.selected[0].cliId;
            this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
            this.navigateToDecompte(IwDecompteName.SituationDebiteur, {cliId});
        }
    }

    @IwEventHandler(LayoutSubNavigationEvent.GSRECAPANNUEL)
    public gsrecapannuelDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Gsrecapannuel);
    }

    @IwEventHandler(LayoutSubNavigationEvent.GS_ANNUEL_REVISIONS)
    public gsannuelrevisions() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.gsannuelrevisions);
    }

    @IwEventHandler(LayoutSubNavigationEvent.AFRECAPANNUEL)
    public afrecapannuelDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Afrecapannuel);
    }

    @IwEventHandler(LayoutSubNavigationEvent.PAIEMENTS)
    public paiementsDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Paiements);
    }

    @IwEventHandler(LayoutSubNavigationEvent.PERMIS)
    public permisDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.permis);
    }

    @IwEventHandler(LayoutSubNavigationEvent.LPPNONPAYEE)
    public lppnonpayeeDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.lppnonpayee);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DECOMPTEENTREPRISE)
    public EntrepriseDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.decompteentreprise);
    }

    @IwEventHandler(LayoutSubNavigationEvent.LISTEDOSSIEREMPLOYES)
    public ListeDossierEmploye() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.listedossieremployes);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SALARYDEF)
    public definitionsalaire() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Definitionsalaire);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DECOMPTEGS)
    public decomptegsDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Decomptegs);
    }

    @IwEventHandler(LayoutSubNavigationEvent.DECOMPTECCT)
    public decomptecctDecompte() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.navigateToDecompte(IwDecompteName.Decomptecct);
    }


    @IwEventHandler(LayoutSubNavigationEvent.PUBLISH_SALARY)
    public publishSalary() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.openBulkManager(OperationType.PUBLISH_SALARY)
    }

    @IwEventHandler(LayoutSubNavigationEvent.SEND_SALARY)
    public sendSalary() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.openBulkManager(OperationType.SEND_SALARY)
    }

    @IwEventHandler(LayoutSubNavigationEvent.SALARY_PRINT_REQUEST)
    public salaryPrintRequest() {
        const options: PrintRequestManagerOptions = {
            type: PrintRequestType.SALARY,
        };
        this._modalService.showModal(PrintRequestManagerComponent, options);
    }

    @IwEventHandler(LayoutSubNavigationEvent.SALARY_GENERATION)
    public salaryGeneration() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.openBulkManager(OperationType.CREATE_SALARY)
    }

    @IwEventHandler(LayoutSubNavigationEvent.SALARY_CALCULATION)
    public salaryCalculation() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.openBulkManager(OperationType.CALCULATE_SALARY)
    }

    @IwEventHandler(LayoutSubNavigationEvent.PAY_SALARY)
    public paySalary() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this._formHandlerPlacement.showFormDialog(PlacementForm.Payments, {entityName: 'saepview'}, s => ({
            ...s,
            diagModal: true
        }));
    }

    @IwEventHandler(LayoutSubNavigationEvent.PAY_OTHERS_SALARY)
    public payOthersSalary() {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.openBulkManager(OperationType.VALIDATE_WAITING_PAYMENT)
    }


    private navigateToDecompte(name: string, param?: Params) {
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_OFF);
        this.eventsHub.emit(LayoutEvents.TOGGLE_SLIDE_PANEL_ON);
        this._decompteNav.openPreview(name, param);
    }

    private async openBulkManager(operationType: OperationType, force: boolean = false) {
        const sagen = await this._sagenService.getSelectedSagenSync();
        const options: BulkManagerOptions = {
            type: operationType,
            salId: sagen?.salId,
            force: force
        };

        this._modalService.showModal(BulkManagerComponent, options);
    }

}

