import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BaseStoreFormComponent} from '@app/sam-base/base';
import {IwStoreService, RestEntityClient} from '@app/sam-base/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {SaepService} from '@app/sam-base/core/services/saep.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {SetLoading} from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {Saems} from '@app/sam-base/models/salary/saems';
import {Saep} from '@app/sam-base/models/salary/saep';
import {IwDateHelper} from '@sam-base/core/dates/iw-date-helper';
import * as globalActions from '@sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@sam-base/core/toast';
import {Saemp} from '@sam-base/models/salary';
import {DtaType, PayType} from '@sam-base/models/salary/payment-type-enum';
import {Observable} from 'rxjs';

@Component({
    templateUrl: './fichePaiement.component.html',
    standalone: false
})
export class FichePaiementComponent extends BaseStoreFormComponent<Saep> implements OnInit {

    public payTypes = PayType;

    public dtaTypes = DtaType;
    protected readonly PayType = PayType;
    private readonly _empRest: RestEntityClient<Saemp>;

    constructor(public store: IwStoreService, public rest: RestApiService,
                private readonly _toast: ToastService,
                private readonly _saepService: SaepService,
                private readonly _restService: RestApiService) {
        super(store);
        this._empRest = _restService.getEntityClient(Saemp);
    }

    public get parentUuid(): string {
        return this.getData('parentUuid') ?? '';
    }

    public get isInEditMode(): boolean {
        return !this.isReadonly && this.isWriteMode;
    }

    public get canEditCheckboxPay(): boolean {
        return this.isInEditMode && this.payType !== PayType.dta && !!this.getFormValue('mpaId');
    }

    public get epId(): string {
        return this.getData('epId') ?? '';
    }

    public get empId(): string {
        return this.getData('empId') ?? '';
    }

    public get salId(): string {
        return this.getData('salId') ?? '';
    }

    public get saems(): Saems {
        return this.getData('saems') || this.saems;
    }

    public get isDtaTypeSelected(): boolean {
        return this.getFormValue('payType') === PayType.dta
    }

    public get isChequeTypeSelected(): boolean {
        return this.getFormValue('payType') === PayType.cheque
    }

    public get payType(): PayType {
        return this.getFormValue('payType');
    }

    public get mpaId(): string {
        return this.getFormValue('mpaId');
    }

    public ngOnInit() {
        this.loadSaep();
        this.subscribeValueChange('payType', (payTypeValue?: PayType) => {
            // only in editMode, as we do not want the loading of payType to set the mpaId to undefined when just opening the entity
            if (this.isInEditMode) {
                this.setFormValue('mpaId', undefined);
            }
        });
        this.subscribeValueChange('paid', acopaid => {
            let mpaIdControl = this.getFormControl('mpaId');
            if (acopaid) {
                mpaIdControl?.setValidators(Validators.required);
                if (this.payType === 2) {
                    let noChequeControl = this.getFormControl('noCheque');
                    noChequeControl?.setValidators(Validators.required);
                }
            } else {
                mpaIdControl?.removeValidators(Validators.required);
            }
            mpaIdControl?.updateValueAndValidity();
        })

    }

    public modifyEntry(): void {
        this._store.dispatch(new globalActions.SetEditMode(this.uuid));
    }

    // eslint-disable-next-line complexity
    public saveEntry() {

        const saepData = this.getFormData();
        if (!saepData) return;
        let saepObservable: Observable<Saep>;
        if (!saepData?.epId) {
            saepObservable = this._saepService.createNewEntry(saepData);
        } else {
            saepObservable = this._saepService.modifyEntry(saepData?.epId, saepData);
        }
        this._store.dispatch(new SetLoading(this.uuid, true));
        saepObservable.subscribe({
            next: value => {
                this._store.dispatch(new SetLoading(this.uuid, false));
                this._toast.success('saep_save_success');
                this._saepService.saepSavedSub.next({
                    uuid: this.parentUuid || '',
                    saved: true
                })
                this.closeDialog();
            },
            error: error => {
                this._store.dispatch(new SetLoading(this.uuid, false));
            }
        })
    }

    public cancelEditionMode() {
        this._store.dispatch(new globalActions.SetReadMode(this.uuid))
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    protected getFormControlNames(): FormKeys<Saep> {
        return [
            'epId',
            'payType',
            'dtaType',
            'datePmnt',
            [
                'montant',
                new UntypedFormControl('', [Validators.required])],
            'texte',
            'banque',
            'banqueAdr',
            'banqueClr',
            'banqueCpt',
            'banqueSwi',
            'iban',
            'paid',
            [
                'mpaId',
                new UntypedFormControl('', [Validators.required])],
            'noCheque',
            'empId',
            'salId'];
    }

    protected getValidationType() {
        return Saep;
    }

    protected validateReadonlyStatus(saep: Saep) {
        return ((saep.paid === true && this.payType === PayType.dta) && !this.isInEditMode) || this.isReadonly;
    }

    private loadSaep() {
        if (this.isNew) {
            this.initNewPaiement();
            return;
        }
    }

    private initNewPaiement() {
        this.setFormValue('empId', this.empId)
        this.setFormValue('salId', this.salId);
        this._empRest.getById(this.empId).subscribe({
            next: saemp => {
                this.setFormValue('banque', saemp.banque);
                this.setFormValue('banqueClr', saemp.banqueClr);
                this.setFormValue('banqueCpt', saemp.banqueCpt);
                this.setFormValue('banqueAdr', saemp.banqueAdr);
                this.setFormValue('banqueSwi', saemp.banqueSwi);
                this.setFormValue('payType', saemp.paytype);
                this.setFormValue('dtaType', saemp.dtatype);
                this.setFormValue('iban', saemp.iban);
                if (!this.isDtaTypeSelected) {
                    this.setFormValue('datePmnt', IwDateHelper.getDateNow());
                }
            }
        })

    }
}
