import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Sapar} from '@app/sam-base/models/salary/sapar';

@Component({
    selector: 'iw-tab-sapar-texts', templateUrl: './tab-sapar-texts.component.html',
    standalone: false
})
export class TabSaparTextsComponent extends BasePartialFormComponent<Sapar> {
    constructor() {
        super();
    }
}
