import {Component, OnInit} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import {ComboboxItem} from '@app/sam-base/models';
import {Sapar} from '@app/sam-base/models/salary/sapar';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'iw-tab-sapar-swissdec', templateUrl: './tab-sapar-swissdec.component.html',
    standalone: false
})


export class TabSaparSwissdecComponent extends BasePartialFormComponent<Sapar> implements OnInit {
    public optionQ: ComboboxItem<string>[] = [];

    constructor(private readonly _satblService: SatblService, private readonly _translate: TranslateService) {
        super();
    }

    public ngOnInit() {
        this.getAllOptions('Q', this.optionQ);
    }

    private getAllOptions(option: string, optionType: ComboboxItem<string>[]) {
        return this._satblService.getSasdstblOptions(option)
            .subscribe((res: any) => {
                optionType.push({
                    name: this._translate.instant('combo_no_option'), value: ''
                });
                for (const k of Object.keys(res)) {
                    optionType.push({name: res[k].titre, value: res[k].elemId});
                }
            });
    }

}
