import {Component} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {Ppmis} from '@app/sam-base/models/placement';

@Component({
    selector: 'iw-tab-ppmis-notes', templateUrl: './tab-ppmis-notes.component.html',
    standalone: false
})
export class TabPpmisNotesComponent extends BasePartialFormComponent<Ppmis> {
    constructor() {
        super();
    }
}
