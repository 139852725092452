import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {QRService} from '@app/sam-base/core/services/payInformation.service';
import {ToastService} from '@app/sam-base/core/toast';
import {Gefachdr} from '@app/sam-base/models/invoices/gefachdr';

@Component({
    selector: 'iw-tab-facture-qr',
    templateUrl: './tab-facture-qr.component.html',
    standalone: false
})
export class TabFactureQRComponent extends BasePartialFormComponent<Gefachdr> implements OnInit {

    public hasImage = true;

    private _img?: string;

    constructor(private readonly _qrService: QRService, private sanitizer: DomSanitizer,
                private _toastService: ToastService) {
        super();
    }

    public get facId() {
        return this.getFormValue<string>('facId');
    }

    public ngOnInit() {
        this.createQR();
    }


    public createQR() {
        this._qrService.createQR(this.facId)
            .subscribe((data: any) => {
                this._img = data;
                this.showQR();
            }, err => {
                this._toastService.error('Erreur lors de la définition de la référence');
            });
    }

    public showQR() {
        const img = 'data:image/png;base64,' + this._img;
        if (!img) {
            return '';
        }
        this.hasImage = false;
        return this.sanitizer
            .bypassSecurityTrustUrl('' + img);
    }
}
