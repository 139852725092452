import {Component} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {FormHandlerService, GridProfile} from '@app/sam-base/core';
import {EntityNavigationService} from '@app/sam-base/core/services/entity-navigation.service';
import {IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {FormKeys} from '@app/sam-base/models';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';
import {Sasx5dec} from '@sam-base/models/admin/sasx5dec.model';

import {SxType} from '@sam-base/models/admin/sx-type.enum';
import {AdminForm} from '../../../admin.forms';
import {Sasx5decCreationMode} from './sasx5dec-creation-mode.model';

@Component({
    templateUrl: './sasx5dec-creation-mode.component.html',
    standalone: false
})
export class Sasx5decCreationModeComponent extends BaseFormComponent<Sasx5decCreationMode> {

    public radioListValue?: SxType;
    public declarationTypeOptions: RadioItem<string>[] = this.iniatializeRadioOptions();

    constructor(private _entityNavService: EntityNavigationService, private _store: IwStoreService,
                private _formHandler: FormHandlerService<AdminForm>) {
        super();
    }

    public get profile(): GridProfile<Sasx5dec> | undefined {
        return this.getData('profile');
    }

    public closeDialog(): void {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public openCreationForm() {
        this.closeDialog();
        // If (this.radioListValue === 'monthly') {
        this._entityNavService.navigateToNew(Sasx5dec, undefined, {creationType: this.radioListValue});
        // } else {
        //   This._formHandler.showFormDialog(
        //     PlacementForm.PpempCandidateCreate, {profile: this.profile});
        // }
    }

    protected getFormControlNames(): FormKeys<Sasx5decCreationMode> {
        return [];
    }

    private iniatializeRadioOptions() {
        this.radioListValue = SxType.ANNUALLY;

        return [{
            label: 'sasx5dec_create_yearly',
            value: SxType.ANNUALLY,
            checked: true
        },
            {
                label: 'sasx5dec_create_monthly',
                value: SxType.MONTHLY,
                checked: false
            }];
    }
}
