import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlacementSearchNavigation} from '@app/core/placement-search/placement-search.service';
import {EntityService} from '@app/modules/sam-main/placement/services/entity.service';
import {BaseSideFormComponent} from '@app/sam-base/base';
import {ModalMessageComponent} from '@app/sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@app/sam-base/components/modal/modal-message/modal-message.options';
import {FormHandlerService, IwEventOpenModalPayload} from '@app/sam-base/core';
import {
    clientBockActionAccessRoles,
    clientCommunicationAccessRoles,
    clientOpenMissionCommandRoles,
    clientPrintRoles,
    clientSiteDebiteurRoles,
    clientSituationRoles,
    clientUnblockAccessRoles
} from '@app/sam-base/core/auth/access-rules/client-form';
import {createEntityAccessRoles} from '@app/sam-base/core/auth/access-rules/entity-forms';
import {FormEvents, IwEventHubService} from '@app/sam-base/core/events';
import {RestApiService, RestEntityClient} from '@app/sam-base/core/rest-api';
import {ModalService} from '@app/sam-base/core/services/modal.service';
import {EntityCreate, IwStoreService} from '@app/sam-base/core/store';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import {ToastService} from '@app/sam-base/core/toast';
import {clientToSmsType} from '@app/sam-base/helpers/sms-map';
import {LayoutSubNavigationEvent} from '@app/sam-base/layout';
import {Ppcli, Ppclicdes, Ppclicdfs, Ppclimis, Ppplf, Ppsuivi} from '@app/sam-base/models/placement';
import {CommunicationType} from '@core/mail/mail.models';
import {MailComposerComponent} from '@modules/sam-main/placement/components/mail-composer/mail-composer.component';
import {TranslateService} from '@ngx-translate/core';
import {SamUserRole} from '@sam-base/models/admin/sam-user';
import {KeycloakService} from 'keycloak-angular';
import {lastValueFrom, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';

import {PlacementForm} from '../../placement.forms';
import {ClientService} from '../../services/client.service';
import {IwSendSMSModalComponent} from '../send-sms/iw-send-sms-modal/iw-send-sms-modal.component';

@Component({
    templateUrl: './ppcli-side-menu.component.html',
    standalone: false
})
export class PpcliSideMenuComponent extends BaseSideFormComponent<Ppcli> implements OnInit, OnDestroy {
    public userId?: string;
    public clientBlockRoles: SamUserRole[] = clientBockActionAccessRoles();
    public clientUnblockRoles: SamUserRole[] = clientUnblockAccessRoles();
    public clientOpenMissionCommandRoles: SamUserRole[] = clientOpenMissionCommandRoles();
    public buttonRolesCreateMission: string[] = createEntityAccessRoles('ppcli');
    public clientSituationRoles: SamUserRole[] = clientSituationRoles();
    public clientCommunicationRoles: SamUserRole[] = clientCommunicationAccessRoles();
    public clientSiteDebitRoles: SamUserRole[] = clientSiteDebiteurRoles();

    public ppcli: Ppcli = new Ppcli();
    public readonly clientPrintRoles = clientPrintRoles();
    private readonly _ppcliRest: RestEntityClient<Ppcli>;
    private subscription = new Subject();

    constructor(private readonly _store: IwStoreService,
                private readonly _formHandler: FormHandlerService<PlacementForm>,
                private readonly _gridNavigation: PlacementSearchNavigation,
                private readonly _modalService: ModalService, private _translate: TranslateService,
                private readonly _keycloak: KeycloakService, private readonly _entityService: EntityService,
                _restService: RestApiService, private readonly _eventHub: IwEventHubService<string>,
                private _toastService: ToastService, private _clientService: ClientService) {
        super();
        this._ppcliRest = _restService.getEntityClient(Ppcli);
    }

    public ngOnInit() {
        this._store.globalForm(this.uuid)
            .entity
            .pipe(takeUntil(this.subscription))
            .subscribe((data: any) => {
                this.ppcli = data;
            });
        this.userId = this._keycloak.getUsername();
    }

    public ngOnDestroy() {
        this.subscription.next(undefined);
        this.subscription.complete();
    }

    public openStats() {
        this._formHandler.showFormDialog(PlacementForm.Ppclistats, {
            cliId: this.ppcli.cliId,
            name: this.ppcli.nom
        });
    }

    /**
     * Function to open email form
     */
    public openEmailForm() {
        this._modalService.showModal(MailComposerComponent, {
            communicationType: CommunicationType.PPCLI_GENERIC,
            entityIds: [this.ppcli.cliId || '']
        });
    }

    public async openSendSMSForm() {
        if (this.ppcli.cliId) {
            const entity = await lastValueFrom(this._ppcliRest.getById(this.ppcli.cliId))
                .then(e => clientToSmsType([e]));
            this._modalService.showModal(IwSendSMSModalComponent, entity);
        }
    }

    public openMissions() {
        if (this.ppcli.cliId) {
            this._gridNavigation.navigate({
                entity: Ppclimis,
                baseFilters: {cliId: this.ppcli.cliId}
            });
        }
    }

    public createMission() {
        this._formHandler
            .showFormDialog(PlacementForm.Misnewdef, {cliId: this.ppcli.cliId});
    }

    public changeCliStatus() {
        this.showModal(this.ppcli);
    }

    public async showModal(cli: Ppcli | undefined) {
        if (!cli) {
            return;
        }
        const options: ModalMessageOptions = {
            message: [`${cli.clistatus === 1 ? this._translate.instant('desactivate_client') : this._translate.instant('activate_client')} `],
            showCancel: false,
            title: this._translate.instant('ppcli_form_title')
        };
        try {
            await this._modalService.showModal(ModalMessageComponent, options);
            cli.clistatus = cli.clistatus === 1 ? 2 : 1;
            this.saveCliStatus(cli);
        } catch (error) {
        }
    }

    public saveCliStatus(cli: Ppcli) {
        this._store.dispatch(new actions.UpdateEntity<Ppcli>(this.uuid, cli));
        setTimeout(() => {
            this._store.dispatch(new actions.SaveEntity(this.uuid));
        }, 0);
        this.createSuivi(cli);
    }

    public createSuivi(cli: Ppcli) {
        const suivi: Ppsuivi = {
            suitype: 'C',
            cliId: cli.cliId,
            dateaction: new Date().toISOString(),
            action: cli.clistatus === 1 ? 'ACTIVER' : 'DESACTIVER'
        };
        this._store.dispatch(new EntityCreate<Ppsuivi>(suivi));
    }

    public openDossier() {
        this._eventHub.emit<IwEventOpenModalPayload<Ppcli>>(FormEvents.openPrintModal, {
            report: 'ppclidos',
            entityId: this.ppcli.cliId,
            entityName: 'ppclidos',
            entity: this.ppcli
        });
    }

    public openHeures() {
        this._eventHub.emit(LayoutSubNavigationEvent.KIBANA, this.ppcli.cliId);
    }

    public openSituation() {
        this._eventHub.emit(LayoutSubNavigationEvent.SITDEBITEURCLI, this.ppcli.cliId);
    }

    public openCommandesTempo() {
        if (this.ppcli.cliId) {
            this._gridNavigation.navigate({
                entity: Ppclicdes,
                baseFilters: {cliId: this.ppcli.cliId}
            });
        }
    }

    public openCommandesFixe() {
        if (this.ppcli.cliId) {
            this._gridNavigation.navigate({
                entity: Ppclicdfs,
                baseFilters: {cliId: this.ppcli.cliId}
            });
        }
    }

    public async toggleBlockClient() {
        if (this.ppcli && this.ppcli.cliId) {

            const options: ModalMessageOptions = {
                message: [`${this.ppcli.clistatus === 3 ? this._translate.instant('unblock_client') : this._translate.instant('block_client')} `],
                showCancel: false,
                title: this._translate.instant('ppcli_form_title')
            };

            try {
                await this._modalService.showModal(ModalMessageComponent, options);
                this._entityService.blockClient(this.ppcli.cliId)
                    .pipe(catchError((err: any) => of(false)))
                    .subscribe(() => {
                        this._store
                            .dispatch(new actions.LoadEntity(this.uuid));
                    });
            } catch {
            }
        }
    }

    public openPlacefixGrid() {
        if (this.ppcli.cliId) {
            this._gridNavigation.navigate({
                entity: Ppplf,
                baseFilters: {ctaCliId: this.ppcli.cliId}
            });
        }
    }

    public syncDebiteur() {
        if (!this.ppcli.cliId) {
            return;
        }
        this._store.dispatch(new actions.SetLoading(this.uuid, true));
        this._clientService.updateGecli(this.ppcli.cliId)
            .subscribe({
                next: () => this._store.dispatch(new actions.SetLoading(this.uuid, false)),
                error: () => {
                    this._store.dispatch(new actions.SetLoading(this.uuid, false));
                }
            });
    }
}

