// eslint-disable-next-line max-classes-per-file
import { HttpClient } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {BasePartialFormComponent} from '@app/sam-base/base';
import {SatblService} from '@app/sam-base/core/services/satbl.service';
import {ComboboxItem} from '@app/sam-base/models';
import {Saempc} from '@app/sam-base/models/salary/saempc';
import {TranslateService} from '@ngx-translate/core';
import {Degreeofrel, PayType, SdsI1, SdsI2, SdsI3} from './tab-saemp-swissdec-enums';

enum ResidenceType {
    NonRenseigne = '', Jour = '01', Semaine = '02',
}

class ConfFormPayType {
    public monthly?: Array<keyof Saempc>;
    public hourly?: Array<keyof Saempc>;
    public notimeconstraint?: Array<keyof Saempc>;
}

@Component({
    selector: 'iw-tab-saemp-swissdec',
    templateUrl: './tab-saemp-swissdec.component.html',
    styleUrls: ['./tab-saemp-swissdec.component.scss'],
    standalone: false
})
export class TabSaempSwissdecComponent extends BasePartialFormComponent<Saempc> implements OnInit {
    @Input() public isReadonly = true;

    public hide = false;

    public optionI: unknown;
    public optionB: ComboboxItem<string>[] = [];
    public optionD: ComboboxItem<string>[] = [];
    public optionJ: ComboboxItem<string>[] = [];
    public optionK: ComboboxItem<string>[] = [];
    public optionL: ComboboxItem<string>[] = [];
    public optionM: ComboboxItem<string>[] = [];
    public optionN: ComboboxItem<string>[] = [];
    public optionP: ComboboxItem<string>[] = [];
    public optionC: ComboboxItem<string>[] = [];
    public optionE: ComboboxItem<string>[] = [];
    public optionH: ComboboxItem<string>[] = [];

    public sds1Active = false;
    public sds2Active = false;
    public sds3Active = false;

    public degreeofrel = Degreeofrel;
    public statpaytype = PayType;

    private confFormPayType: ConfFormPayType = {
        monthly: ['monthContSalary', 'monthCont13', 'monthCont14'],
        hourly: ['hourlyContPaidByHour', 'hourlyContPaidByLesson', 'hourlyContVac', 'hourlyContJf', 'hourlyCont13'],
        notimeconstraint: ['noTimeContSalary', 'noTimeContVac', 'noTimeContJf', 'noTimeCont13']
    };

    constructor(public readonly _http: HttpClient, private readonly _translate: TranslateService, private readonly _satblService: SatblService) {
        super();
    }

    public get residenceWeek() {
        return [ResidenceType.Semaine]
            .includes(this.getFormValue('sdsM') as ResidenceType);
    }

    public get residenceDaily() {
        return [ResidenceType.Jour]
            .includes(this.getFormValue('sdsM') as ResidenceType);
    }

    public get paysoth(): string {
        return this.getFormValue('paysoth') || '';
    }

    public ngOnInit() {
        this.getAllOptions('B', this.optionB);
        this.getAllOptions('D', this.optionD);
        this.getAllOptions('J', this.optionJ);
        this.getAllOptions('K', this.optionK);
        this.getAllOptions('L', this.optionL);
        this.getAllOptions('M', this.optionM);
        this.getAllOptions('N', this.optionN);
        this.getAllOptions('P', this.optionP);
        this.getAllOptions('C', this.optionC);
        this.getAllOptions('E', this.optionE);
        this.getAllOptions('H', this.optionH);
        this.updateFormValidationForNumberFields();
    }

    public changeSdsIOptions(value: string) {
        if (value === 'paytype_monthly') {
            this.onSelectPayTypeMonthly();
        } else if (value === 'paytype_hourly') {
            this.onSelectPayTypeHourly();
        } else if (value === 'paytype_notimeconstraint') {
            this.onSelectPayTypeNoTimeConstraint();
        } else {
            this.onSelectPayTypeNone();
        }
    }

    // First, add a helper method to process form controls based on the provided fields
    // eslint-disable-next-line max-len
    private processFormControls(fields: Array<keyof Saempc> | undefined, validatorAction: 'clear' | 'set') {
        fields?.forEach((field) => {
            const control = this.getFormControl(field);
            if (control) {
                if (validatorAction === 'clear') {
                    control.clearValidators();
                } else {
                    control.setValidators([Validators.required]);
                }
                control.updateValueAndValidity();
            }
        });
    }

    // Now, simplify each onSelect method
    private onSelectPayTypeNone() {
        this.setSdsActive(false, false, false);
        this.clearValidatorsForAll();
    }

    private onSelectPayTypeNoTimeConstraint() {
        this.setSdsActive(false, false, true);
        this.optionI = SdsI3;
        this.clearValidatorsForAll();
        this.processFormControls(this.confFormPayType.notimeconstraint, 'set');
    }

    private onSelectPayTypeHourly() {
        this.setSdsActive(false, true, false);
        this.optionI = SdsI2;
        this.clearValidatorsForAll();
        this.processFormControls(this.confFormPayType.hourly, 'set');
    }

    private onSelectPayTypeMonthly() {
        this.setSdsActive(true, false, false);
        this.optionI = SdsI1;
        this.clearValidatorsForAll();
        this.processFormControls(this.confFormPayType.monthly, 'set');
    }

    // Additional helper methods to keep things DRY
    private setSdsActive(sds1: boolean, sds2: boolean, sds3: boolean) {
        this.sds1Active = sds1;
        this.sds2Active = sds2;
        this.sds3Active = sds3;
    }

    private clearValidatorsForAll() {
        this.processFormControls(this.confFormPayType.monthly, 'clear');
        this.processFormControls(this.confFormPayType.hourly, 'clear');
        this.processFormControls(this.confFormPayType.notimeconstraint, 'clear');
    }

    private getAllOptions(option: string, optionType: ComboboxItem<string>[]) {
        return this._satblService.getSasdstblOptions(option)
            .subscribe((res: any) => {
                // Add undefined option
                optionType.push({
                    name: this._translate.instant('combo_no_option'), value: ''
                });
                for (const k of Object.keys(res)) {
                    optionType.push({name: res[k].titre, value: res[k].elemId});
                }
            });
    }

    private updateFormValidationForNumberFields() {
        // Vac, 13 JF XX.XX
        // Others XXXXX.XX
        const vac13JFValidators = [Validators.min(0), Validators.max(99999.99)];
        const others = [Validators.min(0), Validators.max(99999.99)];
        this.getFormControl('monthContSalary')
            ?.addValidators(others);
        this.getFormControl('monthCont13')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('monthCont14')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('hourlyContPaidByHour')
            ?.addValidators(others);
        this.getFormControl('hourlyContPaidByLesson')
            ?.addValidators(others);
        this.getFormControl('hourlyContVac')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('hourlyContJf')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('hourlyCont13')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('noTimeContSalary')
            ?.addValidators(others);
        this.getFormControl('noTimeContVac')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('noTimeContJf')
            ?.addValidators(vac13JFValidators);
        this.getFormControl('noTimeCont13')
            ?.addValidators(vac13JFValidators);
    }
}
