import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseFormComponent} from '@sam-base/base';
import {IwContextMenuComponent} from '@sam-base/components';
import {ContextMenuEvent, IwEventHubService, IwStoreService} from '@sam-base/core';
import {BookingService} from '@sam-base/core/services/booking.service';
import * as actions from '@sam-base/core/store/actions/global-form.actions';
import {IwGridColumn, MenuItem, sortGridList, TableSortEvent} from '@sam-base/models';
import {BookingFile, BookingFileMenuEvent} from '@sam-base/models/accounting/booking-file.model';
import {toLower} from 'lodash';
import {Subject, takeUntil} from 'rxjs';

@Component({
    templateUrl: 'booking-file-list.component.html',
    standalone: false
})
export class BookingFileListComponent extends BaseFormComponent<BookingFile> implements OnInit, OnDestroy {
    public columns = this.getColumns();
    public bookingFiles: BookingFile[] = [];
    public contextMenuData?: ContextMenuEvent<BookingFile>;
    public contextMenuItems: MenuItem[] = this.buildContextMenu()
    @ViewChild('menu', {static: true}) public menu?: IwContextMenuComponent;
    private subscriptions = new Subject();
    private tableSortEvent?: TableSortEvent<BookingFile>;

    constructor(private readonly _bookingService: BookingService,
                private readonly _store: IwStoreService,
                private readonly _eventService: IwEventHubService<BookingFileMenuEvent>) {
        super();
        this._eventService.forType(BookingFileMenuEvent.DOWNLOAD)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(e => {
                const payload = e.payload as ContextMenuEvent<BookingFile>;
                payload.selected.forEach(e => {
                    this._bookingService.getBookingFileUrl(e).subscribe(url => window.open(url, '_blank'));
                });
            })

    }

    public get module(): string {
        return this.getData('module') ?? 'FA';
    }

    public ngOnInit(): void {
        this._bookingService.listBookingFiles(this.module).subscribe((bookingFiles) => this.bookingFiles = bookingFiles);
    }

    public ngOnDestroy() {
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
        super.ngOnDestroy();
    }

    public async onContextMenu(event: ContextMenuEvent<BookingFile>) {
        this.contextMenuData = event;
        await this.showContextMenu(event.event);
    }

    onSort($event?: TableSortEvent<BookingFile>) {
        this.tableSortEvent = $event;
        if (!$event) return;
        this.bookingFiles = [...sortGridList(this.bookingFiles, $event)];
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    private async showContextMenu(event: MouseEvent) {
        if (this.menu) {
            await this.menu.show(event);
        }
    }

    private getColumns(): IwGridColumn<BookingFile>[] {
        return [
            {
                name: 'bkFileId',
                prop: 'bkFileId',
                type: 'number'
            },
            {
                name: 'libelle',
                prop: 'libelle'
            },
            {
                name: 'date',
                prop: 'date',
                type: 'date'
            },
            {
                name: 'dateDe',
                prop: 'dateDe',
                type: 'date'
            },
            {
                name: 'dateA',
                prop: 'dateA',
                type: 'date'
            },
            {
                name: 'module',
                prop: 'module'
            },
            {
                name: 'extourne',
                prop: 'extourne',
                type: 'boolean'
            },
            {
                name: 'nbEcr',
                prop: 'nbEcr',
                type: 'number'
            },
        ];
    }

    private buildContextMenu() {
        const menu: MenuItem[] = [];
        Object.values(BookingFileMenuEvent).forEach((event) => {
            menu.push(
                {
                    label: toLower(event),
                    event: event,
                    contextMenuVisibleMode: 'all'
                },
            );
        });
        return menu;
    }

}
