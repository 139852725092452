import {Component, Input} from '@angular/core';
import {BasePartialFormComponent} from '@app/sam-base/base';

import {TabNotes} from './tab-notes.model';

@Component({
    selector: 'iw-tab-notes', templateUrl: './tab-notes.component.html',
    standalone: false
})
export class TabNotesComponent extends BasePartialFormComponent<TabNotes> {

    @Input() public isReadonly = true;
}
