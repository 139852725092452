import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ModalMessageComponent} from '@sam-base/components/modal/modal-message/modal-message.component';
import {ModalMessageOptions} from '@sam-base/components/modal/modal-message/modal-message.options';

import {ConfigService, IwEventHubService, ModalService} from '@sam-base/core';
import * as AdminRoles from '@sam-base/core/auth/access-rules/administration-menu';
import {PLACEMENT_MODULES} from '@sam-base/core/auth/enums/gestion-modules';
import {ToastService} from '@sam-base/core/toast';
import {MenuEntry, MenuItem} from '@sam-base/models';
import {ProfileService} from '@shared/profile/profile.service';
import {KeycloakService} from 'keycloak-angular';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AgencyHandlerService} from '../../../core/auth/services/agency-handler.service';

@Component({
    selector: 'iw-layout-header',
    templateUrl: './header.component.html',
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public version?: string;
    public visibleEntries: MenuEntry[] = [];
    public username = '';
    public gestion = '';
    public authUrl = '';
    public hasPlacement = false;
    @Input() menuEntries?: MenuEntry[];
    private _subs = new Subject();

    constructor(private readonly _age: AgencyHandlerService, private readonly _keycloak: KeycloakService,
                private readonly _events: IwEventHubService<string>, private readonly _toastService: ToastService,
                private readonly _modalService: ModalService, private _translate: TranslateService,
                private readonly configService: ConfigService, public _profileService: ProfileService,
                private readonly _eventHub: IwEventHubService<string>) {
    }

    public get hasIjAccess(): boolean {
        return this._profileService.checkUserPermission(AdminRoles.saisirRapportheures())
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['menuEntries']) {
            this.visibleEntries = await this.filterVisibleEntries(changes['menuEntries'].currentValue);
        }
    }

    public ngOnInit() {
        this.username = this._keycloak.getUsername()
            .toUpperCase();
        this._age.loadAgencyId()
            .subscribe(s => this.gestion = s);
        this._age.checkAgencyModules(PLACEMENT_MODULES).then(res => {
            this.hasPlacement = res;
        })
        this._events.forType('request_done')
            .pipe(takeUntil(this._subs))
            .subscribe((e) => {
                if (this.gestion && e.payload !== this.gestion) {
                    const options: ModalMessageOptions = {
                        message: [this._translate.instant('not_right_gestion')],
                        showCancel: false,
                        title: this._translate.instant('not_right_gestion_title'),
                        alertsMessage: '',
                        okDisabled: true
                    };

                    this._modalService.showModal(ModalMessageComponent, options);
                }
            });

        this.configService.getConfig()
            .subscribe(config => {
                this.authUrl = config.authUrl;
            });
    }

    public ngOnDestroy() {
        this._subs.next(undefined);
        this._subs.complete();
    }

    public goToAuthURL() {
        if (!this.authUrl) {
            return;
        }
        window.open(this.authUrl + '/realms/SAM/account/totp', '_blank');
    }

    private async filterVisibleEntries(entries: MenuEntry[]): Promise<MenuEntry[]> {
        const visibleEntries: MenuEntry[] = [];

        for (const entry of entries) {
            if (!entry.accessRoles && !entry.modules) {
                visibleEntries.push(entry);
                continue;
            }

            if (entry.items) {
                for (let items of entry.items) {
                    await this.addComandToMenuItems(items);
                    await this.filterVisibleItems(items);
                }
                entry.items = entry.items
                    .filter(items => items.some(item => item.visible))
                    .filter(items => items.length > 0);
            }

            const hasPermission = this._profileService.checkUserPermission(entry.accessRoles || []);
            const hasModuleAccess = entry.modules ? await this._age.checkAgencyModules(entry.modules) : true;

            if (hasPermission && hasModuleAccess) {
                visibleEntries.push(entry);
            }
        }

        return visibleEntries;
    }

    private async filterVisibleItems(entries: MenuItem[]): Promise<MenuItem[]> {
        const visibleItems: MenuItem[] = [];

        for (const entry of entries) {
            if (!entry.accessRoles && !entry.modules) {
                entry.visible = true;
                if (entry.items) {
                    entry.items = await this.filterVisibleItems(entry.items);  // Correct recursive call with await
                }
                visibleItems.push(entry);
                continue;
            }

            const moduleAccess = entry.modules ? await this._age.checkAgencyModules(entry.modules) : true;
            const userHasRole = this._profileService.checkUserPermission(entry.accessRoles || []);
            if (userHasRole && moduleAccess) {
                entry.visible = true;
                if (entry.items) {
                    entry.items = await this.filterVisibleItems(entry.items);  // Correct recursive call with await
                }
                visibleItems.push(entry);
            } else {
                entry.visible = false;
            }
        }

        return visibleItems;
    }

    private async addComandToMenuItems(items: MenuItem[]): Promise<MenuItem[]> {
        const list: MenuItem[] = [];

        for (const m of items) {
            if (m.items) {
                m.items = await this.addComandToMenuItems(m.items);
            }
            if (m.event) {
                const event = m.event;
                m.command = () => this.emitEvent(event);
            }

            list.push(m);
        }

        return list;
    }

    private emitEvent(event: string) {
        this._eventHub.emit(event);
    }


}
