/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseFormComponent} from '@app/sam-base/base/base-form.component';
import {IwEventHubService, IwStoreService} from '@app/sam-base/core';
import {GepaicliService} from '@app/sam-base/core/services/gepaicli.service';
import {GepaihdrService} from '@app/sam-base/core/services/gepaihdr.service';
import {GepaimodeService} from '@app/sam-base/core/services/gepaimode.service';
import * as actions from '@app/sam-base/core/store/actions/global-form.actions';
import * as globalFormActions from '@app/sam-base/core/store/actions/global-form.actions';
import {ComboboxItem, IwGridColumn} from '@app/sam-base/models';
import {AttribuitionModel} from '@sam-base/models/attribuition.model';
import {Gepaihdr} from '@app/sam-base/models/invoices/gepaihdr';
import {Gepaimode} from '@app/sam-base/models/invoices/gepaimode';
import {EncaissementEvents} from '@sam-base/core/events/actions/encaissement.actions';
import {GepaiCalcPayData} from '@sam-base/models/invoices/gepaicalcpaydata.model';


export interface OpenInvoice {
    facNo: string;
    facId: string;
    facOrNc: string;
    dateFac: string;
    cliNom: string;
    cliId: string;
    montant: number;
    mntRecu: number;
    solde: number;
}

export interface GepaihdrDto {
    cliId: string;
    payId: string;
    plRecalcPaiCli: boolean;
}

export interface GepaihdrData {
    allCli: boolean;
    cliId: string;
    gepaihdr: Gepaihdr;
    facToExecList: FacToExec[];
    opeMode: string;
    mnt2Attribute: number;
    mntDelta: number;
    tauxDelta: number;
    mntSolde: number;
    tauxSolde: number;
    soldeMpaId: string;
    mntSelectedNeg: number;
    mntSelectedPos: number;
    cptDeb: string;
    payCliId: string;
}

export interface CalcPayData {
    sMntPay: number;
    numberOfFacSelected: number;
    mntFacSelected: number;
    lAjustPaiement: boolean;
    mntSelectedPos: number;
    mntSelectedNeg: number;
    lCompensate: boolean;
}

export interface GepaimodeType {
    titre: string;
    mpaId: string;
}

export interface FacToExec {
    cliId: string;
    facId: string;
    facNo: string;
    solde: number;
    facOrNc: string;
}

export interface CountFacSelected {
    mntSelectedPos: number;
    mntSelectedNeg: number;
    nbCliSelected: number;
    nbFacSelected: number;
}

export interface FormValues {
    mnt2Attribute: number;
    mntSolde: number;
    tauxSolde: number;
    mntDelta: number;
    opeMode: string;
    payExplanation: string;
    tauxDelta: number;
    lattribSolde: boolean;
    soldeMpaId: string;
    mntFacSelected: number;
    nbFacSelected: number;
}

export interface CalcSoldeAndModeData {
    formValues: FormValues;
    lAjustPaiement: boolean;
    gepar: undefined;
    mntSelectedPos: number;
    mntSelectedNeg: number;
    lCompensate: boolean;
    mntFacSelected: number;
}


@Component({
    templateUrl: './encaissement-attribution.component.html',
    standalone: false
})
export class EncaissementAttributionComponent extends BaseFormComponent<Gepaihdr> implements OnInit, OnDestroy {

    public comboGepaimode: ComboboxItem<string>[] = [
        {
            name: ' ',
            value: ''
        }];
    public numberOfFacs = 0;
    public listOfFacs: OpenInvoice[] = [];
    public forcerMontant = false;
    public sMntPay = 0;
    public numberOfFacSelected = 0;
    public mntFacSelected = 0;
    public lAjustPaiement = false;
    public mntSelectedPos = 0;
    public mntSelectedNeg = 0;
    public lCompensate = false;
    public mnt2Attribute = 0;
    public mntSolde = 0;
    public tauxSolde = 0;
    public mntDelta = 0;
    public opeMode = '';
    public payExplanation = '';
    public tauxDelta = 0;
    public lattribSolde = false;
    public soldeMpaId = '';
    public nbFacSelected = 0;
    public calcPayDataObject = {};
    public options: Gepaimode[] = [];
    public allCli = false;
    public gepaihdr!: Gepaihdr;
    public facToExecList: FacToExec[] = [];
    public cptDeb = '';
    public payCliId: string | undefined;
    public tableColumns: IwGridColumn<AttribuitionModel>[] = [
        {
            prop: 'cliNom',
            name: 'cliNom',
            index: 0
        },
        {
            prop: 'facNo',
            name: 'id',
            index: 1
        },
        {
            prop: 'facOrNc',
            name: 'facornc',
            index: 2
        },
        {
            prop: 'dateFac',
            name: 'date',
            type: 'date',
            index: 3
        },
        {
            prop: 'montant',
            name: 'montant',
            index: 4,
            align: 'right',
            type: 'mnt'
        },
        {
            prop: 'mntRecu',
            name: 'mntRecu',
            index: 5,
            align: 'right',
            type: 'mnt'
        },
        {
            prop: 'solde',
            name: 'solde',
            index: 6,
            align: 'right',
            type: 'mnt'
        },
        {
            prop: 'cliId',
            name: 'cliId',
            index: 7
        }];

    constructor(private _gepaihdrService: GepaihdrService, private _gepaimodeService: GepaimodeService,
                private _gepaicliService: GepaicliService, private _eventHub: IwEventHubService<EncaissementEvents>,
                protected readonly _store: IwStoreService) {
        super();
    }

    public get mpaId(): string {
        return this.getData('mpaId', true) || '';
    }

    public get datePay(): string {
        return this.getData('datePay', true) || '';
    }

    public get montant(): number {
        return this.getData('montant', true) || 0;
    }

    public get cliId(): string {
        return this.getData('cliId', true) || '';
    }

    public get payId(): string {
        return this.getData('payId') || '';
    }

    public get cliNom(): string {
        return this.getData('cliNom', true) || '';
    }

    public ngOnInit(): void {
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        this._gepaihdrService.searchOpenFactures(this.cliId)
            .subscribe((factures) => {
                this.listOfFacs = factures;
                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
            });
        this._gepaihdrService.getByPayId(this.payId)
            .subscribe((gepaihdr) => {
                this.gepaihdr = gepaihdr;
                this.numberOfFacs = this.listOfFacs.length;
                this.sMntPay = this.gepaihdr.solde!;
                this.mntDelta = this.gepaihdr.solde!;
            });
        this.loadGepaimodeOptions();
        this._gepaicliService.getGepaicli(this.payId)
            .subscribe((gepaicli) => this.payCliId = gepaicli.payCliId);
    }

    public ngOnDestroy(): void {
    }

    public closeDialog() {
        this._store.dispatch(new actions.DestroyForm(this.uuid));
    }

    public saveChanges(): void {
        // Checkpaycli1cli
        this._store.dispatch(new globalFormActions.SetLoading(this.uuid, true));
        if (this.facToExecList.length === this.listOfFacs.length) {
            this.allCli = true;
        } else {
            this.allCli = false;
        }
        const gepaihdrData: GepaihdrData = {
            allCli: this.allCli,
            cliId: this.cliId,
            gepaihdr: this.gepaihdr,
            facToExecList: this.facToExecList,
            opeMode: this.opeMode,
            mnt2Attribute: this.mnt2Attribute,
            mntDelta: this.mntDelta,
            tauxDelta: this.tauxDelta,
            mntSolde: this.mntSolde,
            tauxSolde: this.tauxSolde,
            soldeMpaId: this.soldeMpaId,
            mntSelectedNeg: this.mntSelectedNeg,
            mntSelectedPos: this.mntSelectedPos,
            cptDeb: this.cptDeb,
            payCliId: this.payCliId!
        };
        this._gepaihdrService.execLettrage1Cli(gepaihdrData)
            .subscribe(() => {
                this._store.dispatch(new actions.DestroyForm(this.uuid));
                this._store.dispatch(new globalFormActions.SetLoading(this.uuid, false));
                this._eventHub.emit(EncaissementEvents.saveAssign);
            });
    }

    public selectedChanged(openInvoice: OpenInvoice[]) {
        if (openInvoice.length) {
            this.mntFacSelected = openInvoice.reduce((partialSum, value) => partialSum + value.solde, 0);
            this.mntFacSelected = Math.round((this.mntFacSelected + Number.EPSILON) * 100) / 100;

            this.nbFacSelected = openInvoice.length;
        } else {
            this.mntFacSelected = 0;
            this.mntDelta = this.sMntPay;
            this.tauxDelta = 0;
            this.nbFacSelected = 1;
            this.payExplanation = '';
            this.opeMode = '';
        }
        this.facToExecList = openInvoice.map((invoice) => ({
            cliId: invoice.cliId,
            facId: invoice.facId,
            facNo: invoice.facNo,
            solde: invoice.solde,
            facOrNc: invoice.facOrNc,
        }));
        this.countFac();
    }

    public calcPayData(event: any): void {
        if (event === true && this.facToExecList.length !== 0) {
            if (this.sMntPay) {
                let payData = new GepaiCalcPayData();
                payData.sMntPay = this.sMntPay;
                payData.nbFacSelected = this.facToExecList.length;
                payData.mntFacSelected = this.mntFacSelected;
                payData.lAjustPaiement = this.lAjustPaiement;
                payData.mntSelectedPos = this.mntSelectedPos;
                payData.mntSelectedNeg = this.mntSelectedNeg;
                payData.lCompensate = this.lCompensate;

                this._gepaihdrService.calcPayDataSingleCli(payData)
                    .subscribe((dataObject) => {
                        this.mnt2Attribute = dataObject.mnt2Attribute;
                        this.mntSolde = dataObject.mntSolde;
                        this.tauxSolde = dataObject.tauxSolde;
                        this.mntDelta = dataObject.mntDelta;
                        this.opeMode = dataObject.opeMode;
                        this.payExplanation = dataObject.payExplanation;
                        this.tauxDelta = dataObject.tauxDelta;
                        this.lattribSolde = dataObject.lattribSolde;
                        this.soldeMpaId = dataObject.soldeMpaId;
                        this.mntFacSelected = dataObject.mntFacSelected;
                        this.nbFacSelected = dataObject.nbFacSelected;
                    });
            }
        } else {
            this.sMntPay = this.gepaihdr.solde!;
        }
    }

    public CalcSoldeAndMode(): void {
        if (this.sMntPay) {
            const formValues: FormValues = {
                mnt2Attribute: this.mnt2Attribute,
                mntSolde: this.mntSolde,
                tauxSolde: this.tauxSolde,
                mntDelta: this.mntDelta,
                opeMode: this.opeMode,
                payExplanation: this.payExplanation,
                tauxDelta: this.tauxDelta,
                lattribSolde: this.lattribSolde,
                soldeMpaId: this.soldeMpaId,
                mntFacSelected: this.mntFacSelected,
                nbFacSelected: this.nbFacSelected
            };
            const calcSoldeAndModeData: CalcSoldeAndModeData = {
                formValues,
                lAjustPaiement: this.lAjustPaiement,
                gepar: undefined,
                mntSelectedPos: this.mntSelectedPos,
                mntSelectedNeg: this.mntSelectedNeg,
                lCompensate: this.lCompensate,
                mntFacSelected: this.mntFacSelected
            };
            this._gepaihdrService.calcSoldeAndMode1Cli(calcSoldeAndModeData)
                .subscribe();
        }
    }

    public onBlur() {
        this.calcPayData(true);
    }

    public onFocus() {
    }

    private loadGepaimodeOptions() {
        this._gepaimodeService.getGepaimodeByPaytype()
            .subscribe((gepaimodeList) => {
                this.comboGepaimode.push({
                    name: '----',
                    value: ''
                });
                gepaimodeList.forEach((gepaimode) => {
                    this.comboGepaimode.push({
                        name: gepaimode.titre,
                        value: gepaimode.mpaId
                    });
                });
            });
    }

    private async countFac() {
        await this._gepaihdrService.countFacSelected(this.facToExecList)
            .toPromise()
            .then((fac) => {
                this.nbFacSelected = fac?.nbFacSelected!;
                this.mntSelectedNeg = fac?.mntSelectedNeg!;
                this.mntSelectedPos = fac?.mntSelectedPos!;
                this.calcPayData(true);
            });
    }
}
